import React from "react";
import swal from "sweetalert";
import {
  UpdatedUserManagement,
  UpdateProfile,
} from "../../../axios";
import { Button, FormTextInput } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";
import Strings from "../../../translations";

const EditProfile = (props) => {
  const {
    errorMessage,
    setMessage,
    actionType,
    handlemodalclose,
  } = props;

  const
    userNameLabel = Strings["UserManagement.createUserNameLable"],
    userNameplaceholder = Strings["UserManagement.createUserNamePlaceHolder"],
    userNameCustomError =
      Strings["UserManagement.createUserNamecustomlineerror"],
    userEmailLabel = Strings["UserManagement.createUserEmailLable"],
    userEmailplaceholder = Strings["UserManagement.createUserEmailPlaceHolder"],
    userEmailCustomError =
      Strings["UserManagement.createUserEmailcustomlineerror"],
    userPhoneNumberLabel = Strings["UserManagement.createUserPhoneNumberLable"],
    userPhoneNumberPlaceholder =
      Strings["UserManagement.createUserPhoneNumberPlaceHolder"],
    userPhoneNumberCustomError =
      Strings["UserManagement.createUserPhoneNumbercustomlineerror"],
    saveButton = Strings["UserManagement.createUserSaveButton"],
    cancelButton = Strings["UserManagement.createUserCancelButton"],
    updateButton = Strings["UserManagement.createUserUpdateButton"];


  const [userName, setUserName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [phoneNumber, setphoneNumber] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [updateId, setUpdateId] = React.useState();

  const handleChangeUserName = (value) => {
    setUserName(value.value);
  };


  const handleChangeEmailAddress = (value) => {
    setEmailAddress(value.value);
  };

  const handleChangePhoneNumber = (value) => {
    setphoneNumber(value.value);
  };


  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setLoader(true);
    UpdateProfile(user.identity_id)
      .then((res) => {
        setLoader(false);
        setUpdateId(res.data.data.id);
        setUserName(res.data.data.name);
        setEmailAddress(res.data.data.email);
        setphoneNumber(res.data.data.phone);
      })
      .catch((e) => {
        setLoader(false);
        // setMessage(e.response.data.message);
      });
  }, []);

  const handleSaveData = () => {
    setLoader(true);
    const fromData = {
      name: userName,
      email: emailAddress,
      phone: phoneNumber,
    };
    UpdatedUserManagement(fromData, updateId)
      .then((res) => {
        setLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handlemodalclose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };


  return (
    <div className="modalFormContainer">
      {loader ? <LoaderComponent /> : null}
      <h2>{strings["Navigation.SideBarEditProfile"]}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        maxLength="50"
        starProps={true}
        label={userNameLabel}
        placeholder={userNameplaceholder}
        Value={userName}
        onChangeText={handleChangeUserName}
        Validate={true}
        CustomErrorLine={userNameCustomError}
        editable={true}
      />
      <FormTextInput
        type="email"
        starProps={true}
        label={userEmailLabel}
        placeholder={userEmailplaceholder}
        Value={emailAddress}
        onChangeText={handleChangeEmailAddress}
        Validate={true}
        CustomErrorLine={userEmailCustomError}
        editable={false}
        multiline={false}
      />
      <FormTextInput
        type="phone"
        starProps={true}
        label={userPhoneNumberLabel}
        placeholder={userPhoneNumberPlaceholder}
        Value={phoneNumber}
        onChangeText={handleChangePhoneNumber}
        Validate={true}
        CustomErrorLine={userPhoneNumberCustomError}
        editable={true}
        multiline={false}
      />
      {/* {actionType === "add" && (
        <>
          <FormDropDown
            labelStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            Label={userTypeLabel}
            starProps={true}
            Options={userTypeDropdown}
            value={userType}
            onSelect={handleChangeUserType}
            customErrorLine={userTypeCustomError}
            selectText={userTypePlaceholder}
            searchable={false}
            disable={false}
          />
          {userType === "System User" ? (
            <FormDropDown
              labelStyle={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
              Label={companyLabel}
              starProps={true}
              Options={companyTypeDropdown}
              value={company}
              onSelect={handleChangeCompany}
              customErrorLine={companyCustomError}
              selectText={companySelectText}
              searchable={false}
              disable={false}
            />
          ) : null}

          <div style={{ display: "flex", paddingTop: "5px" }}>
            <h4>{selectRoleHeader}</h4>
            <span style={{ color: "red", marginLeft: "4px", marginTop: "7px" }}>
              *
            </span>
          </div>
          <div className={"row"}>
            {roles.map((functionName) => {
              return (
                <div className={"col-12 col-md-3"}>
                  <input
                    type="radio"
                    id={functionName.key}
                    name="role"
                    value={functionName.key}
                    onChange={() => handleChangeCheck(functionName.key)}
                    checked={functionName.checked}
                  />
                  <label htmlFor={functionName.key} className="ms-2">
                    {functionName.functionName}
                  </label>
                </div>
              );
            })}
          </div>
        </>

      )} */}

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : updateButton}
          buttonStyle={{ backgroundColor: colors.primary }}
          // disable={disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handlemodalclose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default EditProfile;
