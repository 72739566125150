/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormCheckBox, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const BudgetItemCategoryForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;
  const [referanceNumber, setReferanceNumber] = React.useState({
    value: initialValue.reference_number ? initialValue.reference_number : "1",
    error: false,
    success: false,
  });

  const [categoryName, setCategoryName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [description, setDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [deliveryImage, setDeliveryImage] = React.useState(
    initialValue.is_delivery_image
      ? initialValue.is_delivery_image === 1
        ? true
        : false
      : false
  );
  const handleChangeReference = (value) => {
    setReferanceNumber(value);
  };

  const handleChangeCategoryName = (value) => {
    setMessage(null);
    setCategoryName(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeDeliveryImage = () => {
    setDeliveryImage(!deliveryImage);
  };

  const checkBoxLabel = deliveryImage
    ? "Delivery Image - Not Applicable"
    : "Delivery Image - Applicable";

  const handleSaveData = () => {
    const formData = {
      // referanceNumber: referanceNumber.value,
      category_name: categoryName.value,
    };
    handleChangeFormData(formData);
  };

  const CreateTitle = Strings["ItemCategory.CreateTitle"],
    EditTitle = Strings["ItemCategory.EditTitle"],
    ReferenceLabel = Strings["ItemCategory.ReferenceLabel"],
    ReferencePlaceholder = Strings["ItemCategory.ReferencePlaceholder"],
    ReferenceCustomError = Strings["ItemCategory.ReferenceCustomError"],
    NameLabel = Strings["ItemCategory.NameLabel"],
    NamePlaceholder = Strings["ItemCategory.NamePlaceholder"],
    NameCustomError = Strings["ItemCategory.NameCustomError"],
    DescriptionLabel = Strings["ItemCategory.DescriptionLabel"],
    DescriptionPlaceholder = Strings["ItemCategory.DescriptionPlaceholder"],
    DescriptionCustomError = Strings["ItemCategory.DescriptionCustomError"],
    SaveBtn = Strings["ItemCategory.SaveBtn"],
    UpdateBtn = Strings["ItemCategory.UpdateBtn"],
    CancelBtn = Strings["ItemCategory.CancelBtn"];

  let isValueChanged = categoryName.value === initialValue.categoryName;
  // description.value === initialValue.description;
  let disable =
    categoryName.error ||
    // description.error ||
    categoryName.value === "" ||
    // description.value === "" ||
    categoryName.success === false ||
    isValueChanged;
  //edit
  let isValueChangededit =
    categoryName.value === initialValue.categoryName;
   
  let editdisable =
    categoryName.error ||
    categoryName.value === "" ||
    (categoryName.success === false) ||
    isValueChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? CreateTitle : EditTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {/* <FormTextInput
        type="number"
        label={ReferenceLabel}
        placeholder={ReferencePlaceholder}
        Value={referanceNumber.value}
        onChangeText={handleChangeReference}
        Validate={true}
        CustomErrorLine={ReferenceCustomError}
        editable={true}
        min={0}
        maxLength={20}
      /> */}
      <FormTextInput
        type="textarea"
        label={NameLabel}
        starProps={true}
        placeholder={NamePlaceholder}
        Value={categoryName.value}
        onChangeText={handleChangeCategoryName}
        Validate={true}
        CustomErrorLine={NameCustomError}
        editable={true}
        maxLength={30}
      />
      {/* <FormTextInput
        type="text"
        label={DescriptionLabel}
        placeholder={DescriptionPlaceholder}
        Value={description.value}
        onChangeText={handleChangeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        CustomErrorLine={DescriptionCustomError}
        multiline={true}
        editable={true}
        maxLength={50}
      /> */}
      <div className="mt-3">
        {/* <FormCheckBox
          Label={checkBoxLabel}
          checked={deliveryImage}
          handleChangeCheck={handleChangeDeliveryImage}
        /> */}
      </div>

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? SaveBtn : UpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : false}
          onClick={handleSaveData}
        />
        <Button
          buttonName={CancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default BudgetItemCategoryForm;
