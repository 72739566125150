/* eslint-disable no-unused-vars */
import { Forward } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  AllDeactiveProject,
  ChangeActiveStatusProject,
  CreateProject,
  CreateProjectSpecificUser,
  GetAllActiveProject,
  GetAllDraftProject,
  GetAllProject,
  GetCompleteProject,
  GetOngoingProject,
  UpdateProject,
  UpdateProjectStatus,
  getProjectCode,
  GetAllActiveProjectById,
  GetAllCompletedProjectById,
  GetAllDeactivatedProjectById,
  GetAllOngoingProjectById,
  GetUserNameByAuthId,
  GetProjectSpecificUsers,
  GetAllSearchPO,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  Chip,
  DownloadIcon,
  IconButton,
  Modal,
  NodataFound,
  UserModal,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import {
  CreateProjectForm,
  ProjectSpecificUserForm,
} from "../../../forms/ProjectAndBudget";
import strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import { CREATE_PROJECT, UPDATE_PROJECT } from "../../../utils/constants";
import { isEncoded } from "../../../utils/crypto";
import "./SearchProjectCss.css";

const Project = (props) => {
  const location = useLocation();
  const setCurrentTab = (location) => {
    switch (location.pathname) {
      case "/allProjects":
        return "allProjects";
        // setCurentRoute('allProjects');
        break;
      case "/draftproject":
        // setCurentRoute('draft')
        return "draft";
        break;
      case "/ongoingproject":
        // setCurentRoute('ongoing')
        return "ongoing";
        break;
      case "/cancelledproject":
        // setCurentRoute('cancel')
        return "cancel";
        break;
      case "/completedproject":
        // setCurentRoute('complete')
        return "complete";
        break;
      default:
        // setCurentRoute('ongoing')
        return "ongoing";
        break;
    }
  };
  const [projectcodes, setProjectCode] = React.useState("");
  const [data, setData] = React.useState([]);
  const [errorMessage, setMessage] = React.useState(null);
  const [cancelProject, searchCancelProject] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(6);
  const [ongoingRowsPerPage] = React.useState(6);
  const [ongoingPage, setOngoingPage] = React.useState(0);
  const [ongoingSerialNo, setOngoingSerialNo] = React.useState(null);
  const [completeRowsPerPage] = React.useState(6);
  const [completePage, setCompletePage] = React.useState(0);
  const [completTotal, setCompletTotal] = React.useState(0);
  const [completeSerial, setCompleteSerial] = React.useState(null);
  const [totalData, setTotalData] = React.useState(null);
  const [cancelData, setCancelData] = React.useState([]);
  const [cancelPage] = React.useState(0);
  const [serialNo, setSerialNo] = React.useState(null);
  const [cancelSerialNo, setCancelSerialNo] = React.useState(null);
  const [projectType, setProjectType] = React.useState();
  const [cancelRowsPerPage] = React.useState(5);
  const [draftRowsPerPage] = React.useState(5);
  const [ongoingProject, setOngoingProject] = React.useState([]);
  const [totalOngoingProj, setTotalOngoingProj] = React.useState("");
  // const [ongoingSerial, setOngoingSerial] = React.useState("");
  const [draftSerial, setDraftSerial] = React.useState("");
  const [completeProject, setCompleteProject] = React.useState([]);
  const [cancelTotalPage, setCancelTotalPage] = React.useState(0);
  const [draftTotalPage, setDraftTotalPage] = React.useState(0);
  const [isAdd, setAdd] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const [draftProject, setDraftProject] = React.useState([]);
  const [draftPage] = React.useState(0);
  const [isUserOpen, setIsUserOpen] = React.useState(false);
  const [errorUserMessage, setUserMessage] = React.useState(null);
  const [projectId, setProjectId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [currentRoute, setCurentRoute] = useState(setCurrentTab(location));
  const [authUserId, setUserAuthId] = useState("");
  const [projectUsers, setProjectUsers] = useState([]);
  const [searchTermPOongoing, setSearchTermPOongoing] = useState("");
  const [searchTermPOCompleted, setSearchTermPOCompleted] = useState("");
  const [searchTermPOCancelled, setSearchTermPOCancelled] = useState("");
  const [searchTermPODraft, setSearchTermPODraft] = useState("");
  const [searchTermPOTotal, setSearchTermPOTotal] = useState("");

  const handleUserModalClose = () => {
    setIsUserOpen(false);
    setUserMessage(null);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const headers = [
    { label: "Project Name", key: "name" },
    { label: "Project Code", key: "project_code" },
    { label: "Project Status", key: "project_status" },
  ];

  const csvReport = {
    data: data,
    headers: headers,
    filename: "Projects_Report.csv",
  };

  React.useEffect(() => {
    if (user != null) {
      const id = user.identity_id;
      GetUserNameByAuthId(id)
        .then((res) => {
          setUserAuthId(res.data.data.id);
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_PROJECT)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  let history = useHistory();

  React.useEffect(() => {
    setCurrentTab(location);
  }, [currentRoute]);

  const paths = [
    {
      name: strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["Projects"],
    },
  ];
  let projectcode = "proj";
  const searchProjectHeader =
      strings["SearchProjecetHeader.searchProjectHeader"],
    AlertTitle = strings["SearchProjecetHeader.AlertTitle"],
    alertMessage = strings["SearchProjecetHeader.alertMessage"],
    Serial = strings["SearchProjecetHeader.Serial"],
    furnishTitle = strings["Title.Furnish"],
    projectTitle = strings["Title.Projects"],
    totalProjectLabel = strings["Dashboard.totalProject"],
    ongoingProjectsLabel = strings["Dashboard.ongoingProject"],
    completedProjectsLabel = strings["Dashboard.completedProject"],
    cancelledprojectLabel = strings["Dashboard.cancelledProject"],
    draftProjectLabel = strings["Dashboard.draftProjects"],
    ongoingLabel = strings["ProjectStatus.ongoing"],
    cancelledLabel = strings["ProjectStatus.cancelled"],
    completedLbel = strings["ProjectStatus.completed"],
    viewAll = strings["Dashboard.ViewAll"];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setMessage(null);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const getOngoingProject = () => {
    setLoading(true);
    GetOngoingProject(ongoingRowsPerPage, ongoingPage)
      .then((res) => {
        setLoading(false);
        setOngoingSerialNo(res.data.si);
        setOngoingProject(res.data.data);
        setTotalOngoingProj(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getOngoingProjectById = () => {
    setLoading(true);
    GetAllOngoingProjectById(ongoingRowsPerPage, ongoingPage, authUserId)
      .then((res) => {
        setLoading(false);
        setOngoingSerialNo(res.data.si);
        setOngoingProject(res.data.data);
        setTotalOngoingProj(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (user.loginType != "client") {
      getOngoingProject();
    }
    if (user.loginType == "client" && authUserId != "") {
      getOngoingProjectById();
    }
  }, [ongoingRowsPerPage, ongoingPage, status, authUserId]);

  const getAllActiveProject = () => {
    setLoading(true);
    GetAllActiveProject(rowsPerPage, page)
      .then((res) => {
        const filterData = [];
        res.data.data.find((ele) => {
          if (ele.project_status === null) {
            filterData.push(ele);
          }
        });
        setLoading(false);
        setSerialNo(res.data.si);
        setData(res.data.data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getAllActiveProjectById = () => {
    setLoading(true);
    GetAllActiveProjectById(rowsPerPage, page, authUserId)
      .then((res) => {
        const filterData = [];
        res.data.data.find((ele) => {
          if (ele.project_status === null) {
            filterData.push(ele);
          }
        });
        setLoading(false);
        setSerialNo(res.data.si);
        setData(res.data.data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (user.loginType != "client") {
      getAllActiveProject();
    }
    if (user.loginType == "client" && authUserId != "") {
      getAllActiveProjectById();
    }
  }, [status, rowsPerPage, page, authUserId]);

  const getAllDraftProject = () => {
    setLoading(true);
    GetAllDraftProject(draftRowsPerPage, draftPage)
      .then((res) => {
        setDraftTotalPage(res.data.total);
        setDraftSerial(res.data.si);
        setDraftProject(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllDraftProject();
  }, [status]);

  const getAllProject = () => {
    setLoading(true);
    GetAllProject()
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllProject();
  }, [status]);

  const getAllDeactiveProject = () => {
    setLoading(true);
    AllDeactiveProject(cancelRowsPerPage, cancelPage)
      .then((res) => {
        setLoading(false);
        setCancelSerialNo(res.data.si);
        setCancelTotalPage(res.data.total);
        setCancelData(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getAllDeactiveProjectById = () => {
    setLoading(true);
    GetAllDeactivatedProjectById(cancelRowsPerPage, cancelPage, authUserId)
      .then((res) => {
        setLoading(false);
        setCancelSerialNo(res.data.si);
        setCancelTotalPage(res.data.total);
        setCancelData(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (user.loginType != "client") {
      getAllDeactiveProject();
    }
    if (user.loginType == "client" && authUserId != "") {
      getAllDeactiveProjectById();
    }
  }, [status, cancelRowsPerPage, cancelPage, authUserId]);

  const getAllCompleteProject = () => {
    setLoading(true);
    GetCompleteProject(completeRowsPerPage, completePage)
      .then((res) => {
        setCompleteSerial(res.data.si);
        setLoading(false);
        setCompleteProject(res.data.data);
        setCompletTotal(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getAllCompleteProjectById = () => {
    setLoading(true);
    GetAllCompletedProjectById(completeRowsPerPage, completePage, authUserId)
      .then((res) => {
        setCompleteSerial(res.data.si);
        setLoading(false);
        setCompleteProject(res.data.data);
        setCompletTotal(res.data.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (user.loginType != "client") {
      getAllCompleteProject();
    }
    if (user.loginType == "client" && authUserId != "") {
      getAllCompleteProjectById();
    }
  }, [status, completeRowsPerPage, completePage, authUserId]);

  const handleChangeSuccess = () => {
    const id = projectType.id;
    const statusId = projectType.is_active;
    const staId = {
      status: statusId === 0 ? 1 : 0,
    };
    setLoading(true);
    ChangeActiveStatusProject(id, staId)
      .then((res) => {
        getAllActiveProject();
        handleModalClose();
        setLoading(false);
        setAlertBox(false);
      })
      .catch((e) => {
        setAlertBox(false);
        setLoading(false);
        handleModalClose();
      });
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  React.useEffect(() => {
    getProjectCode(projectcode)
      .then((res) => {
        setProjectCode(res.data.data);
      })
      .catch(() => {});
  }, []);

  const createProject = (projectData) => {
    setLoading(true);
    CreateProject(projectData)
      .then((res) => {
        setLoading(false);
        swal({
          title: "Success!",
          text: "Created Successfully",
          icon: "success",
        });
        setLoading(false);
        getAllDraftProject();
        getAllActiveProject();
        handleModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const updateProject = (projectData) => {
    const id = projectType.id;
    setLoading(true);
    UpdateProject(projectData, id)
      .then((res) => {
        getAllProject();
        getAllDraftProject();
        getAllActiveProject();
        getAllCompleteProject();
        AllDeactiveProject();
        getOngoingProject();
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Successfully",
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <CreateProjectForm
        projectcodes={projectcodes}
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleProjectData={clickedBtn === "add" ? createProject : updateProject}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentUserType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };
  const handleChangePage = (newPage) => {
    const pageNo = parseInt(newPage.target.innerText);
    setPage(pageNo - 1);
  };

  const ongoingChangePage = (newPage) => {
    const pageNo = parseInt(newPage.target.innerText);
    setOngoingPage(pageNo - 1);
  };

  const completeHandleChangePage = (newPage) => {
    const pageNo = parseInt(newPage.target.innerText);
    setCompletePage(pageNo - 1);
  };

  const cancelhandleChangePage = (newPage) => {
    const pageNo = parseInt(newPage.target.innerText);
    setOngoingPage(pageNo - 1);
  };

  const handleShowAllProjects = () => {
    history.push({
      pathname: "/allprojects",
      state: { projectStatus: "ongoing" },
    });
  };

  const handleChangeOngoingProject = () => {
    history.push({
      pathname: "/ongoingproject",
      state: { projectStatus: "ongoing" },
    });
  };
  const handleChangeDraftProject = () => {
    history.push({
      pathname: "/draftproject",
      state: { projectStatus: null },
    });
  };

  const handleChangeCompletedProject = () => {
    history.push({
      pathname: "/completedproject",
      state: { projectStatus: "completed" },
    });
  };

  const handleChangeCancelProject = () => {
    history.push({
      pathname: "/cancelledproject",
      state: { cancelProject },
    });
  };

  const handleChangeEdit = (body) => {
    setClickedBtn("edit");
    setIsOpen(true);
    setCurrentUserType(body);
    setProjectType(body);
  };

  const handleChangeStatus = (value, id) => {
    setLoading(true);
    const statusValue = {
      status: value,
    };
    UpdateProjectStatus(id, statusValue)
      .then((res) => {
        getAllActiveProject();
        getOngoingProject();
        getAllCompleteProject();
        getAllDeactiveProject();
        getAllDraftProject();
        setLoading(false);
        swal({
          title: "Success!",
          text: "Updated Successfully",
          icon: "success",
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getProjectSpecificUsers = (id) => {
    setLoading(true);
    GetProjectSpecificUsers(id)
      .then((res) => {
        setLoading(false);

        setProjectUsers(res.data[0]["project_specific_users"]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleAdduser = (id1, id2, projectusers) => {
    setIsUserOpen(true);
    setProjectId(id1);
    setClientId(id2);
    getProjectSpecificUsers(id1);
  };
  // const setClient = (id2) => {
  //   console.log("IDDD",id2)
  //   setClientId(id2)
  // }

  const createProjectUser = (formData) => {
    setLoading(true);
    const data = {
      project_id: projectId,
      user_id: formData.user_id,
      role_id: "1",
      is_approver: formData.is_approver,
      action_by: formData.action_by,
    };
    CreateProjectSpecificUser(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          getProjectSpecificUsers(projectId);
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        swal({
          title: "Error!",
          text: e.response.data.message,
          icon: "error",
        });
        // setMessage(e.response.data.message);
      });
  };

  const filterData = (data) => {
    const newArr = [];
    if (data != null) {
      data.forEach((item) => {
        let result = {
          ...item["clientcontacts"],
          is_approver: item["is_approver"] ? "Yes" : "No",
        };
        newArr.push(result);
      });
    }
    return newArr;
  };

  const selectUserModal = () => {
    return (
      <ProjectSpecificUserForm
        setMessage={setUserMessage}
        errorMessage={errorUserMessage}
        handleChangeData={createProjectUser}
        actionType={"add"}
        handleModalClose={handleUserModalClose}
        clientId={clientId}
        userData={filterData(projectUsers)}
      />
    );
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermOngoin, setSearchTermOngoin] = useState("");
  const [searchTermCompleted, setSearchTermCompleted] = useState("");
  const [searchTermCancelled, setSearchTermCancelled] = useState("");
  const [searchTermDraft, setSearchTermDraft] = useState("");
  // ... other states

  // ... other functions

  useEffect(() => {
    if (searchTermOngoin === "") {
      if (user.loginType != "client") {
        getOngoingProject();
      } else {
        getOngoingProjectById();
      }
    } else {
      const filteredData = ongoingProject.filter((projectData) =>
        projectData.name?.toUpperCase().includes(searchTermOngoin.toUpperCase())
      );
      setOngoingProject(filteredData);
    }
  }, [searchTermOngoin]);
  useEffect(() => {
    if (searchTermCompleted === "") {
      if (user.loginType != "client") {
        getAllCompleteProject();
      } else {
        getAllCompleteProjectById();
      }
    } else {
      const filteredData = completeProject.filter((projectData) =>
        projectData.name
          ?.toUpperCase()
          .includes(searchTermCompleted.toUpperCase())
      );
      setCompleteProject(filteredData);
    }
  }, [searchTermCompleted]);
  useEffect(() => {
    if (searchTermCancelled === "") {
      if (user.loginType != "client") {
        getAllDeactiveProject();
      } else {
        getAllDeactiveProjectById();
      }
    } else {
      const filteredData = cancelData.filter((projectData) =>
        projectData.name
          ?.toUpperCase()
          .includes(searchTermCancelled.toUpperCase())
      );
      setCancelData(filteredData);
    }
  }, [searchTermCancelled]);
  useEffect(() => {
    if (searchTermDraft === "") {
      getAllDraftProject();
    } else {
      const filteredData = draftProject.filter((projectData) =>
        projectData.name?.toUpperCase().includes(searchTermDraft.toUpperCase())
      );
      setDraftProject(filteredData);
    }
  }, [searchTermDraft]);
  useEffect(() => {
    if (searchTerm === "") {
      if (user.loginType != "client") {
        getAllActiveProject();
      } else {
        getAllActiveProjectById();
      }
    } else {
      const filteredData = data.filter((projectData) =>
        projectData.name?.toUpperCase().includes(searchTerm.toUpperCase())
      );
      setData(filteredData);
    }
  }, [searchTerm]);

  console.log({ ongoingProject });

  const handleSearch = (searchData) => {
    const data = searchData.trim();
    setSearchTermPOongoing(data);
    if (data === "") {
      getOngoingProject();
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const data = searchTermPOongoing.trim();
    if (data) {
      setLoading(true);
      GetAllSearchPO(data)
        .then((res) => {
          setOngoingProject(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      getOngoingProject();
    }
  };

  const handleCompleteSearch = (searchData) => {
    const data = searchData.trim();
    setSearchTermPOCompleted(data);
    if (data === "") {
      getAllCompleteProject();
    }
  };

  const handleCompleteSearchSubmit = (e) => {
    e.preventDefault();
    const data = searchTermPOCompleted.trim();
    if (data) {
      setLoading(true);
      GetAllSearchPO(data)
        .then((res) => {
          setCompleteProject(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      getAllCompleteProject();
    }
  };

  const handleCancelledSearch = (searchData) => {
    const data = searchData.trim();
    setSearchTermPOCancelled(data);
    if (data === "") {
      getAllDeactiveProject();
    }
  };

  const handleCancelledSearchSubmit = (e) => {
    e.preventDefault();
    const data = searchTermPOCancelled.trim();
    if (data) {
      setLoading(true);
      GetAllSearchPO(data)
        .then((res) => {
          setCancelData(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      getAllDeactiveProject();
    }
  };

  const handleDraftSearch = (searchData) => {
    const data = searchData.trim();
    setSearchTermPODraft(data);
    if (data === "") {
      getAllDraftProject();
    }
  };

  const handleDraftSearchSubmit = (e) => {
    e.preventDefault();
    const data = searchTermPODraft.trim();
    if (data) {
      setLoading(true);
      GetAllSearchPO(data)
        .then((res) => {
          setDraftProject(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      getAllDraftProject();
    }
  };

  const handleTotalSearch = (searchData) => {
    const data = searchData.trim();
    setSearchTermPOTotal(data);
    if (data === "") {
      getAllActiveProject();
    }
  };

  const handleTotalSearchSubmit = (e) => {
    e.preventDefault();
    const data = searchTermPOTotal.trim();
    if (data) {
      setLoading(true);
      GetAllSearchPO(data)
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      getAllActiveProject();
    }
  };

  return (
    <>
      <div className="content-wrapper p-0">
        <Helmet>
          <title>
            {furnishTitle} | {projectTitle}
          </title>
        </Helmet>
        {loading ? <LoaderComponent /> : null}
        <div
          className="row mt-4 align-items-center"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col">
            <h3>
              {searchProjectHeader}({totalData})
            </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          <div className="col-1">
            <span className="downloadButton">
              <CSVLink style={{ color: "white" }} {...csvReport}>
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </CSVLink>
            </span>
          </div>
          {isAdd && (
            <div
              className="col-auto"
              style={{
                marginLeft: "-1.5rem",
              }}
            >
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
        </div>
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>

        {alertBox && (
          <AlertMessage
            title={AlertTitle}
            message={alertMessage}
            onChangeCancel={handleChangeCancel}
            onChangeSucess={handleChangeSuccess}
          />
        )}

        <div className="row">
          <div className="col-md-12">
            <div
              className="projectSection"
              style={{
                marginBottom: "60px",
              }}
            >
              <Tabs
                defaultActiveKey={currentRoute}
                transition={false}
                id="noanim-tab-example"
                className="mb-3 pb-3"
              >
                <Tab
                  eventKey="ongoing"
                  title={`${ongoingProjectsLabel} (${
                    totalOngoingProj ? totalOngoingProj : 0
                  })`}
                >
                  <div className="d-flex justify-content-start mb-3">
                    {/* <Button
                      buttonStyle={{
                        backgroundColor: "#ffffff00",
                        boxShadow: "none",
                      }}
                      textStyle={{ color: colors.primary }}
                      buttonName={viewAll}
                      onClick={handleShowAllProjects}
                      disable={data.length !== 0 ? false : true}
                    /> */}
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchTermOngoin}
                      placeholder="Search Project..."
                      onChange={(e) => setSearchTermOngoin(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* Search PO Form */}
                    <form onSubmit={handleSearchSubmit}>
                      <TextField
                        label="Search PO"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        value={searchTermPOongoing}
                        placeholder="Search Purchase Order..."
                        onChange={(e) => handleSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>

                    {/* <TextField
                      label="Search PO"
                      variant="outlined"
                      size="small"
                      style={{ marginLeft: "5px" }}
                      value={searchTermPO}
                      placeholder="Search Purchase Order..."
                      onChange={handleSearch}
                      onKeyPress={handlePOKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                  </div>
                  {/* <div className="d-flex justify-content-end mb-3">
                    <Button
                      buttonStyle={{
                        backgroundColor: "#ffffff00",
                        boxShadow: "none",
                      }}
                      textStyle={{ color: colors.primary }}
                      buttonName={viewAll}
                      onClick={handleChangeOngoingProject}
                      disable={ongoingProject.length !== 0 ? false : true}
                    />
                  </div> */}
                  <div className="row">
                    {ongoingProject.length !== 0 ? (
                      <>
                        {/* {ongoingProject.map((projectData, index) => {
                          return (
                            <div className="col-12 col-md-4" key={index}>
                              <SearchPageCard>
                                <SearchProjectCard
                                  setStatus={setStatus}
                                  actions={true}
                                  datas={projectData}
                                  userId={projectData.id}
                                  activeStatus={projectData.is_active}
                                  serialLabel={Serial}
                                  serialValue={ongoingSerialNo + index}
                                  projectValue={projectData.name}
                                  projectStatus={"Ongoing"}
                                  switchButtonOnClick={() => {
                                    setAlertBox(true);
                                    setProjectType(projectData);
                                  }}
                                  iconButtonOnClick={() => {
                                    handleChangeEdit(projectData);
                                    // setIsOpen(true);
                                    // setCurrentUserType(projectData);
                                    // setProjectType(projectData);
                                  }}
                                  pro_status={true}
                                  handleStatus={handleChangeStatus}
                                />
                              </SearchPageCard>
                            </div>
                          );
                        })} */}
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Project Name
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Project Scope
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Budget
                                </TableCell>

                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Client Name
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Country
                                </TableCell>
                                {/* <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Project Status
                              </TableCell> */}
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Actions
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ongoingProject.map((projectData, index) => (
                                <TableRow key={index}>
                                  {/* <TableCell>{serialNo + index}</TableCell> */}
                                  <TableCell>
                                    <Link
                                      to={{
                                        pathname: `/projectbudget/${isEncoded(
                                          projectData.id
                                        )}`,
                                        state: {
                                          id: isEncoded(projectData.id),
                                          projectId: projectData,
                                        },
                                      }}
                                    >
                                      {projectData.name?.toUpperCase()}
                                    </Link>
                                  </TableCell>
                                  {/* <TableCell>
                                    {projectData.project_code}
                                  </TableCell> */}
                                  {/* <TableCell>
                                    <p>
                                      <div className="dropdown">
                                        <Chip
                                          label={"Ongoing"}
                                          type={
                                            projectData.project_status !==
                                            "Canceled"
                                              ? "active"
                                              : ""
                                          }
                                        />

                                        <span className="dropdown-content">
                                          <>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Completed",
                                                  projectData.id
                                                )
                                              }
                                              value="Completed"
                                            >
                                              {completedLbel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Cancelled",
                                                  projectData.id
                                                )
                                              }
                                              value="Cancelled"
                                            >
                                              {cancelledLabel}
                                            </a>
                                          </>
                                        </span>
                                      </div>
                                    </p>
                                  </TableCell> */}
                                  <TableCell>
                                    {projectData.project_scope_data.length > 0
                                      ? projectData.project_scope_data.map(
                                          (elem) => {
                                            return `${elem.project_scopes.name}   `;
                                          }
                                        )
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.budget.length > 0
                                      ? projectData.budget.map((elem) => {
                                          return (
                                            <p>
                                              {`${elem.name} : ${
                                                elem?.currency?.name
                                              } ${
                                                elem.value != null
                                                  ? elem.value.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                      }
                                                    )
                                                  : "0.00"
                                              }`.replace(/,\s*$/, ", ")}
                                            </p>
                                          );
                                        })
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.clients.name
                                      ? projectData.clients.name
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.countries.name
                                      ? projectData.countries.name
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {isAuthorizedFunc(UPDATE_PROJECT) && (
                                      <IconButton
                                        onClick={() =>
                                          handleChangeEdit(projectData)
                                        }
                                      >
                                        <Tooltip title="Edit">
                                          <EditIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}

                                    {/* <IconButton
                                    onClick={() =>
                                      handleChangeStatus(
                                        projectData.project_status,
                                        projectData.id
                                      )
                                    }
                                  >
                                    <ChangeCircle />
                                  </IconButton> */}
                                    {user.loginType != "client" && (
                                      <IconButton
                                        onClick={() =>
                                          handleAdduser(
                                            projectData.id,
                                            projectData.clients.id,
                                            projectData.project_specific_users
                                          )
                                        }
                                      >
                                        <Tooltip title="Add User">
                                          <PeopleIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}

                                    <Link
                                      to={{
                                        pathname: `/projectbudget/${isEncoded(
                                          projectData.id
                                        )}`,
                                        state: {
                                          id: isEncoded(projectData.id),
                                          projectId: projectData,
                                          currentTab: "ongoingProject",
                                        },
                                      }}
                                    >
                                      <IconButton
                                      // onClick={() =>
                                      //   handleChangeStatus(
                                      //     projectData.project_status,
                                      //     projectData.id
                                      //   )
                                      // }
                                      >
                                        <Forward />
                                      </IconButton>
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="d-flex justify-content-end mt-3">
                          <Stack spacing={3}>
                            <Pagination
                              count={Math.ceil(totalOngoingProj / 6)}
                              onChange={ongoingChangePage}
                              variant="outlined"
                              shape="rounded"
                            />
                          </Stack>
                        </div>
                      </>
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                        norecord={true}
                      />
                    )}
                  </div>
                </Tab>

                <Tab
                  eventKey="complete"
                  title={`${completedProjectsLabel} (${completTotal})`}
                >
                  <div className="d-flex justify-content-start mb-3">
                    {/* <Button
                      buttonStyle={{
                        backgroundColor: "#ffffff00",
                        boxShadow: "none",
                      }}
                      textStyle={{ color: colors.primary }}
                      buttonName={viewAll}
                      onClick={handleShowAllProjects}
                      disable={data.length !== 0 ? false : true}
                    /> */}
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchTermCompleted}
                      placeholder="Search Project..."
                      onChange={(e) => setSearchTermCompleted(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <form onSubmit={handleCompleteSearchSubmit}>
                      <TextField
                        label="Search PO"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        value={searchTermPOCompleted}
                        placeholder="Search Purchase Order..."
                        onChange={(e) => handleCompleteSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </div>
                  {/* <div className="d-flex justify-content-end mb-3">
                    <Button
                      buttonStyle={{
                        backgroundColor: "#ffffff00",
                        boxShadow: "none",
                      }}
                      textStyle={{ color: colors.primary }}
                      buttonName={viewAll}
                      onClick={handleChangeCompletedProject}
                      disable={completeProject.length !== 0 ? false : true}
                    />
                  </div> */}
                  <div className="row">
                    {completeProject.length !== 0 ? (
                      <>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Project Name
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Project Scope
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Budget
                                </TableCell>

                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Client Name
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Country
                                </TableCell>
                                {/* <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Project Status
                              </TableCell> */}
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Actions
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {completeProject.map((projectData, index) => (
                                <TableRow key={index}>
                                  {/* <TableCell>{serialNo + index}</TableCell> */}
                                  <TableCell>
                                    <Link
                                      to={{
                                        pathname: `/projectbudget/${isEncoded(
                                          projectData.id
                                        )}`,
                                        state: {
                                          id: isEncoded(projectData.id),
                                          projectId: projectData,
                                        },
                                      }}
                                    >
                                      {projectData.name?.toUpperCase()}
                                    </Link>
                                  </TableCell>
                                  {/* <TableCell>
                                    {projectData.project_code}
                                  </TableCell> */}
                                  {/* <TableCell>
                                    <p>
                                      <div className="dropdown">
                                        <Chip
                                          label={"Completed"}
                                          type={
                                            projectData.project_status !==
                                            "Canceled"
                                              ? "active"
                                              : ""
                                          }
                                        />

                                        <span className="dropdown-content">
                                          <>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Ongoing",
                                                  projectData.id
                                                )
                                              }
                                              value="Ongoing"
                                            >
                                              {ongoingLabel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Cancelled",
                                                  projectData.id
                                                )
                                              }
                                              value="Cancelled"
                                            >
                                              {cancelledLabel}
                                            </a>
                                          </>
                                        </span>
                                      </div>
                                    </p>
                                  </TableCell> */}
                                  <TableCell>
                                    {projectData.project_scope_data.length > 0
                                      ? projectData.project_scope_data.map(
                                          (elem) => {
                                            return `${elem.project_scopes.name}   `;
                                          }
                                        )
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.budget.length > 0
                                      ? projectData.budget.map((elem) => {
                                          return (
                                            <p>
                                              {`${elem.name} : ${
                                                elem?.currency?.name
                                              } ${
                                                elem.value != null
                                                  ? elem.value.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                      }
                                                    )
                                                  : "0.00"
                                              }`.replace(/,\s*$/, ", ")}
                                            </p>
                                          );
                                        })
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.clients.name
                                      ? projectData.clients.name
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.countries.name
                                      ? projectData.countries.name
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {isAuthorizedFunc(UPDATE_PROJECT) && (
                                      <IconButton
                                        onClick={() =>
                                          handleChangeEdit(projectData)
                                        }
                                      >
                                        <Tooltip title="Edit">
                                          <EditIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}

                                    {/* <IconButton
                                    onClick={() =>
                                      handleChangeStatus(
                                        projectData.project_status,
                                        projectData.id
                                      )
                                    }
                                  >
                                    <ChangeCircle />
                                  </IconButton> */}
                                    {user.loginType != "client" && (
                                      <IconButton
                                        onClick={() =>
                                          handleAdduser(
                                            projectData.id,
                                            projectData.clients.id,
                                            projectData.project_specific_users
                                          )
                                        }
                                      >
                                        <Tooltip title="Add User">
                                          <PeopleIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}

                                    <Link
                                      to={{
                                        pathname: `/projectbudget/${isEncoded(
                                          projectData.id
                                        )}`,
                                        state: {
                                          id: isEncoded(projectData.id),
                                          projectId: projectData,
                                          currentTab: "completedProjects",
                                        },
                                      }}
                                    >
                                      <IconButton
                                      // onClick={() =>
                                      //   handleChangeStatus(
                                      //     projectData.project_status,
                                      //     projectData.id
                                      //   )
                                      // }
                                      >
                                        <Forward />
                                      </IconButton>
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="d-flex justify-content-end mt-3">
                          <Stack spacing={3}>
                            <Pagination
                              count={Math.ceil(completTotal / 6)}
                              onChange={completeHandleChangePage}
                              variant="outlined"
                              shape="rounded"
                            />
                          </Stack>
                        </div>
                      </>
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                        norecord={false}
                      />
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey="cancel"
                  title={`${cancelledprojectLabel} (${cancelTotalPage})`}
                >
                  <div className="d-flex justify-content-start mb-3">
                    {/* <Button
                      buttonStyle={{
                        backgroundColor: "#ffffff00",
                        boxShadow: "none",
                      }}
                      textStyle={{ color: colors.primary }}
                      buttonName={viewAll}
                      onClick={handleShowAllProjects}
                      disable={data.length !== 0 ? false : true}
                    /> */}
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchTermCancelled}
                      placeholder="Search Project..."
                      onChange={(e) => setSearchTermCancelled(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <form onSubmit={handleCancelledSearchSubmit}>
                      <TextField
                        label="Search PO"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        value={searchTermPOCancelled}
                        placeholder="Search Purchase Order..."
                        onChange={(e) => handleCancelledSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </div>
                  {/* <div className="d-flex justify-content-end mb-3">
                    <Button
                      buttonStyle={{
                        backgroundColor: "#ffffff00",
                        boxShadow: "none",
                      }}
                      textStyle={{ color: colors.primary }}
                      buttonName={viewAll}
                      onClick={handleChangeCancelProject}
                      disable={cancelData.length !== 0 ? false : true}
                    />
                  </div> */}
                  <div className="row">
                    {cancelData.length !== 0 ? (
                      <>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Project Name
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Project Scope
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Budget
                                </TableCell>

                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Client Name
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Country
                                </TableCell>
                                <TableCell
                                  style={{ color: "rgb(1, 150, 136)" }}
                                >
                                  Actions
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cancelData.map((projectData, index) => (
                                <TableRow key={index}>
                                  {/* <TableCell>{serialNo + index}</TableCell> */}
                                  <TableCell>
                                    <Link
                                      to={{
                                        pathname: `/projectbudget/${isEncoded(
                                          projectData.id
                                        )}`,
                                        state: {
                                          id: isEncoded(projectData.id),
                                          projectId: projectData,
                                        },
                                      }}
                                    >
                                      {projectData.name?.toUpperCase()}
                                    </Link>
                                  </TableCell>
                                  {/* <TableCell>
                                    {projectData.project_code}
                                  </TableCell> */}
                                  <TableCell>
                                    {projectData.project_scope_data.length > 0
                                      ? projectData.project_scope_data.map(
                                          (elem) => {
                                            return `${elem.project_scopes.name}   `;
                                          }
                                        )
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.budget.length > 0
                                      ? projectData.budget.map((elem) => {
                                          return (
                                            <p>
                                              {`${elem.name} : ${
                                                elem?.currency?.name
                                              } ${
                                                elem.value != null
                                                  ? elem.value.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                      }
                                                    )
                                                  : "0.00"
                                              }`.replace(/,\s*$/, ", ")}
                                            </p>
                                          );
                                        })
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.clients.name
                                      ? projectData.clients.name
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {projectData.countries.name
                                      ? projectData.countries.name
                                      : "N/A"}
                                  </TableCell>
                                  {/* <TableCell>
                                    <p>
                                      <div className="dropdown">
                                        <Chip
                                          label={"Cancelled"}
                                          type={
                                            projectData.project_status !==
                                            "Cancelled"
                                              ? "isactive"
                                              : ""
                                          }
                                        />

                                        <span className="dropdown-content">
                                          <>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Ongoing",
                                                  projectData.id
                                                )
                                              }
                                              value="Ongoing"
                                            >
                                              {ongoingLabel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Completed",
                                                  projectData.id
                                                )
                                              }
                                              value="Completed"
                                            >
                                              {completedLbel}
                                            </a>
                                          </>
                                        </span>
                                      </div>
                                    </p>
                                  </TableCell> */}
                                  <TableCell>
                                    {isAuthorizedFunc(UPDATE_PROJECT) && (
                                      <IconButton
                                        onClick={() =>
                                          handleChangeEdit(projectData)
                                        }
                                      >
                                        <Tooltip title="Edit">
                                          <EditIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}

                                    {/* <IconButton
                                    onClick={() =>
                                      handleChangeStatus(
                                        projectData.project_status,
                                        projectData.id
                                      )
                                    }
                                  >
                                    <ChangeCircle />
                                  </IconButton> */}
                                    {user.loginType != "client" && (
                                      <IconButton
                                        onClick={() =>
                                          handleAdduser(
                                            projectData.id,
                                            projectData.clients.id,
                                            projectData.project_specific_users
                                          )
                                        }
                                      >
                                        <Tooltip title="Add User">
                                          <PeopleIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}

                                    <Link
                                      to={{
                                        pathname: `/projectbudget/${isEncoded(
                                          projectData.id
                                        )}`,
                                        state: {
                                          id: isEncoded(projectData.id),
                                          projectId: projectData,
                                          currentTab: "canceledProjects",
                                        },
                                      }}
                                    >
                                      <IconButton
                                      // onClick={() =>
                                      //   handleChangeStatus(
                                      //     projectData.project_status,
                                      //     projectData.id
                                      //   )
                                      // }
                                      >
                                        <Forward />
                                      </IconButton>
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="d-flex justify-content-end mt-3">
                          <Stack spacing={3}>
                            <Pagination
                              count={Math.ceil(cancelTotalPage / 6)}
                              onChange={cancelhandleChangePage}
                              variant="outlined"
                              shape="rounded"
                            />
                          </Stack>
                        </div>
                      </>
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                        norecord={false}
                      />
                    )}
                  </div>
                </Tab>
                {user.loginType != "client" && (
                  <Tab
                    eventKey="draft"
                    title={`${draftProjectLabel} (${
                      draftProject ? draftTotalPage : 0
                    })`}
                  >
                    <div className="d-flex justify-content-start mb-3">
                      {/* <Button
                        buttonStyle={{
                          backgroundColor: "#ffffff00",
                          boxShadow: "none",
                        }}
                        textStyle={{ color: colors.primary }}
                        buttonName={viewAll}
                        onClick={handleShowAllProjects}
                        disable={data.length !== 0 ? false : true}
                      /> */}
                      <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchTermDraft}
                        placeholder="Search Project..."
                        onChange={(e) => setSearchTermDraft(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <form onSubmit={handleDraftSearchSubmit}>
                        <TextField
                          label="Search PO"
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: "5px" }}
                          value={searchTermPODraft}
                          placeholder="Search Purchase Order..."
                          onChange={(e) => handleDraftSearch(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </form>
                    </div>
                    {/* <div className="d-flex justify-content-end mb-3">
                      <Button
                        buttonStyle={{
                          backgroundColor: "#ffffff00",
                          boxShadow: "none",
                        }}
                        textStyle={{ color: colors.primary }}
                        buttonName={viewAll}
                        onClick={handleChangeDraftProject}
                        disable={draftProject.length !== 0 ? false : true}
                      />
                    </div> */}
                    <div className="row">
                      {draftProject.length !== 0 ? (
                        <>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ color: "rgb(1, 150, 136)" }}
                                  >
                                    Project Name
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "rgb(1, 150, 136)" }}
                                  >
                                    Project Scope
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "rgb(1, 150, 136)" }}
                                  >
                                    Budget
                                  </TableCell>

                                  <TableCell
                                    style={{ color: "rgb(1, 150, 136)" }}
                                  >
                                    Client Name
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "rgb(1, 150, 136)" }}
                                  >
                                    Country
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "rgb(1, 150, 136)" }}
                                  >
                                    Project Status
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "rgb(1, 150, 136)" }}
                                  >
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {draftProject.map((projectData, index) => (
                                  <TableRow key={index}>
                                    {/* <TableCell>{serialNo + index}</TableCell> */}
                                    <TableCell>
                                      <Link
                                        to={{
                                          pathname: `/projectbudget/${isEncoded(
                                            projectData.id
                                          )}`,
                                          state: {
                                            id: isEncoded(projectData.id),
                                            projectId: projectData,
                                          },
                                        }}
                                      >
                                        {projectData.name?.toUpperCase()}
                                      </Link>
                                    </TableCell>
                                    {/* <TableCell>
                                      {projectData.project_code}
                                    </TableCell> */}
                                    {/* <TableCell>
                                      <p>
                                        <div className="dropdown">
                                          <Chip
                                            label={"Draft"}
                                            type={
                                              projectData.project_status !==
                                              "Canceled"
                                                ? "active"
                                                : ""
                                            }
                                          />
  
                                          <span className="dropdown-content">
                                            <>
                                              <a
                                                onClick={() =>
                                                  handleChangeStatus(
                                                    "Ongoing",
                                                    projectData.id
                                                  )
                                                }
                                                value="Ongoing"
                                              >
                                                {ongoingLabel}
                                              </a>
                                              <a
                                                onClick={() =>
                                                  handleChangeStatus(
                                                    "Completed",
                                                    projectData.id
                                                  )
                                                }
                                                value="Completed"
                                              >
                                                {completedLbel}
                                              </a>
                                              <a
                                                onClick={() =>
                                                  handleChangeStatus(
                                                    "Cancelled",
                                                    projectData.id
                                                  )
                                                }
                                                value="Cancelled"
                                              >
                                                {cancelledLabel}
                                              </a>
                                            </>
                                          </span>
                                        </div>
                                      </p>
                                    </TableCell> */}
                                    <TableCell>
                                      {projectData.project_scope_data.length > 0
                                        ? projectData.project_scope_data.map(
                                            (elem) => {
                                              return `${elem.project_scopes.name}   `;
                                            }
                                          )
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {projectData.budget.length > 0
                                        ? projectData.budget.map((elem) => {
                                            return (
                                              <p>
                                                {`${elem.name} : ${
                                                  elem?.currency?.name
                                                } ${
                                                  elem.value != null
                                                    ? elem.value.toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                        }
                                                      )
                                                    : "0.00"
                                                }`.replace(/,\s*$/, ", ")}
                                              </p>
                                            );
                                          })
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {projectData.clients.name
                                        ? projectData.clients.name
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {projectData.countries.name
                                        ? projectData.countries.name
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {isAuthorizedFunc(UPDATE_PROJECT) && (
                                        <IconButton
                                          onClick={() =>
                                            handleChangeEdit(projectData)
                                          }
                                        >
                                          <Tooltip title="Edit">
                                            <EditIcon />
                                          </Tooltip>
                                        </IconButton>
                                      )}

                                      {/* <IconButton
                                      onClick={() =>
                                        handleChangeStatus(
                                          projectData.project_status,
                                          projectData.id
                                        )
                                      }
                                    >
                                      <ChangeCircle />
                                    </IconButton> */}
                                      {user.loginType != "client" && (
                                        <IconButton
                                          onClick={() =>
                                            handleAdduser(
                                              projectData.id,
                                              projectData.clients.id,
                                              projectData.project_specific_users
                                            )
                                          }
                                        >
                                          <Tooltip title="Add User">
                                            <PeopleIcon />
                                          </Tooltip>
                                        </IconButton>
                                      )}

                                      <Link
                                        to={{
                                          pathname: `/projectbudget/${isEncoded(
                                            projectData.id
                                          )}`,
                                          state: {
                                            id: isEncoded(projectData.id),
                                            projectId: projectData,
                                            currentTab: "draftProjects",
                                          },
                                        }}
                                      >
                                        <IconButton
                                        // onClick={() =>
                                        //   handleChangeStatus(
                                        //     projectData.project_status,
                                        //     projectData.id
                                        //   )
                                        // }
                                        >
                                          <Forward />
                                        </IconButton>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div className="d-flex justify-content-end mt-3">
                            <Stack spacing={3}>
                              <Pagination
                                count={Math.ceil(draftTotalPage / 6)}
                                onChange={handleChangePage}
                                variant="outlined"
                                shape="rounded"
                              />
                            </Stack>
                          </div>
                        </>
                      ) : (
                        <NodataFound
                          onClick={() => {
                            openAddModal();
                            handleSelectBtn("add");
                          }}
                        />
                      )}
                    </div>
                  </Tab>
                )}

                <Tab
                  eventKey="allProjects"
                  title={`${totalProjectLabel} (${totalData ? totalData : 0})`}
                >
                  <div className="d-flex justify-content-start mb-3">
                    {/* <Button
                      buttonStyle={{
                        backgroundColor: "#ffffff00",
                        boxShadow: "none",
                      }}
                      textStyle={{ color: colors.primary }}
                      buttonName={viewAll}
                      onClick={handleShowAllProjects}
                      disable={data.length !== 0 ? false : true}
                    /> */}
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchTerm}
                      placeholder="Search Project..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <form onSubmit={handleTotalSearchSubmit}>
                      <TextField
                        label="Search PO"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        value={searchTermPOTotal}
                        placeholder="Search Purchase Order..."
                        onChange={(e) => handleTotalSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </div>

                  <div className="row">
                    {data.length !== 0 ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/* <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Project Code
                              </TableCell> */}
                              {/* <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Serial
                              </TableCell> */}
                              <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Project Name
                              </TableCell>
                              <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Project Scope
                              </TableCell>
                              <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Budget
                              </TableCell>

                              <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Client Name
                              </TableCell>
                              <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Country
                              </TableCell>
                              <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Project Status
                              </TableCell>
                              <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((projectData, index) => (
                              <TableRow key={index}>
                                {/* <TableCell>{serialNo + index}</TableCell> */}
                                {/* <TableCell>
                                  {projectData.project_code}
                                </TableCell> */}

                                <TableCell>
                                  {/* <Link
                                    to={{
                                      pathname: `/projectbudget/${isEncoded(
                                        projectData.id
                                      )}`,
                                      state: {
                                        id: projectData.id,
                                        projectId: projectData,
                                      },
                                    }}
                                  > */}
                                  <Link
                                    to={{
                                      pathname: `/projectbudget/${isEncoded(
                                        projectData.id
                                      )}`,
                                      state: {
                                        id: isEncoded(projectData.id),
                                        projectId: projectData,
                                      },
                                    }}
                                  >
                                    {projectData.name?.toUpperCase()}
                                  </Link>

                                  {/* </Link> */}
                                </TableCell>
                                <TableCell>
                                  {projectData.project_scope_data.length > 0
                                    ? projectData.project_scope_data.map(
                                        (elem) => {
                                          return `${elem.project_scopes.name}   `;
                                        }
                                      )
                                    : "N/A"}
                                </TableCell>
                                <TableCell>
                                  {projectData.budget.length > 0
                                    ? projectData.budget.map((elem) => {
                                        return (
                                          <p>
                                            {`${elem.name} : ${
                                              elem?.currency?.name
                                            } ${
                                              elem.value != null
                                                ? elem.value.toLocaleString(
                                                    undefined,
                                                    { minimumFractionDigits: 2 }
                                                  )
                                                : "0.00"
                                            }`.replace(/,\s*$/, ", ")}
                                          </p>
                                        );
                                      })
                                    : "N/A"}
                                </TableCell>
                                <TableCell>
                                  {projectData.clients.name
                                    ? projectData.clients.name
                                    : "N/A"}
                                </TableCell>
                                <TableCell>
                                  {projectData.countries.name
                                    ? projectData.countries.name
                                    : "N/A"}
                                </TableCell>
                                <TableCell>
                                  <p>
                                    <div className="dropdown">
                                      <Chip
                                        label={projectData.project_status}
                                        type={
                                          projectData.project_status !==
                                          "Canceled"
                                            ? "active"
                                            : ""
                                        }
                                      />

                                      <span className="dropdown-content">
                                        {projectData.project_status === null ? (
                                          <>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Ongoing",
                                                  projectData.id
                                                )
                                              }
                                              value="Ongoing"
                                            >
                                              {ongoingLabel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Completed",
                                                  projectData.id
                                                )
                                              }
                                              value="Completed"
                                            >
                                              {completedLbel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Cancelled",
                                                  projectData.id
                                                )
                                              }
                                              value="Cancelled"
                                            >
                                              {cancelledLabel}
                                            </a>
                                          </>
                                        ) : null}
                                        {projectData.project_status ===
                                        "Ongoing" ? (
                                          <>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Completed",
                                                  projectData.id
                                                )
                                              }
                                              value="Completed"
                                            >
                                              {completedLbel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Cancelled",
                                                  projectData.id
                                                )
                                              }
                                              value="Cancelled"
                                            >
                                              {cancelledLabel}
                                            </a>
                                          </>
                                        ) : null}
                                        {projectData.project_status ===
                                        "Completed" ? (
                                          <>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Ongoing",
                                                  projectData.id
                                                )
                                              }
                                              value="Ongoing"
                                            >
                                              {ongoingLabel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Cancelled",
                                                  projectData.id
                                                )
                                              }
                                              value="Cancelled"
                                            >
                                              {cancelledLabel}
                                            </a>
                                          </>
                                        ) : null}
                                        {projectData.project_status ===
                                        "Cancelled" ? (
                                          <>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Ongoing",
                                                  projectData.id
                                                )
                                              }
                                              value="Ongoing"
                                            >
                                              {ongoingLabel}
                                            </a>
                                            <a
                                              onClick={() =>
                                                handleChangeStatus(
                                                  "Completed",
                                                  projectData.id
                                                )
                                              }
                                              value="Completed"
                                            >
                                              {completedLbel}
                                            </a>
                                          </>
                                        ) : null}
                                      </span>
                                    </div>
                                  </p>
                                </TableCell>
                                <TableCell>
                                  {isAuthorizedFunc(UPDATE_PROJECT) && (
                                    <IconButton
                                      onClick={() =>
                                        handleChangeEdit(projectData)
                                      }
                                    >
                                      <Tooltip title="Edit">
                                        <EditIcon />
                                      </Tooltip>
                                    </IconButton>
                                  )}

                                  {user.loginType != "client" && (
                                    <IconButton
                                      onClick={() => {
                                        handleAdduser(
                                          projectData.id,
                                          projectData.clients.id,
                                          projectData.project_specific_users
                                        );
                                        // setClient(projectData.clients.id)
                                      }}
                                    >
                                      <Tooltip title="Add User">
                                        <PeopleIcon />
                                      </Tooltip>
                                    </IconButton>
                                  )}

                                  <Link
                                    to={{
                                      pathname: `/projectbudget/${isEncoded(
                                        projectData.id
                                      )}`,
                                      state: {
                                        id: isEncoded(projectData.id),
                                        projectId: projectData,
                                        currentTab: "allProjects",
                                      },
                                    }}
                                  >
                                    <IconButton
                                    // onClick={() =>
                                    //   handleChangeStatus(
                                    //     projectData.project_status,
                                    //     projectData.id
                                    //   )
                                    // }
                                    >
                                      <Forward />
                                    </IconButton>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <>
                        {loading ? (
                          <LoaderComponent />
                        ) : (
                          <NodataFound
                            onClick={() => {
                              openAddModal();
                              handleSelectBtn("add");
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Stack spacing={3}>
                      <Pagination
                        count={Math.ceil(totalData / 6)}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Stack>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <UserModal
        onHandleClose={handleUserModalClose}
        isOpen={isUserOpen}
        contentStyle={{
          width: "75%",
          height: "75%",
        }}
      >
        {selectUserModal()}
      </UserModal>
    </>
  );
};

export default Project;
