/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  AllActiveStatusLocation,
  AllActiveStatusModeOfPayment,
  AllActiveStatusRequiredDocument,
  AllActiveStatusRequiredSubmittals,
  createPODraft,
  GetActiveFinalPaymentAgainst,
  GetActivePaymentProgress,
  GetAllActiveCountry,
  GetAllActiveIncoterm,
  GetAllActivePaymentTerms,
  GetAllActiveTransportation,
  getAllActiveVendor,
  GetRevisionSelectItemByRfqId,
  GetSelectItemsByRfqId,
  UpdateBudgetItem,
  GetAllItemsByVendor,
} from "../../../axios";
import {
  Button,
  FormCheckBox,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import SearchCheckedTables from "../../../components/AppSpecific/SearchTable/SearchCheckedTables";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import { CreateBudgetItemForm } from "../../../forms";
import strings from "../../../translations";
import { Modal } from "rsuite";

const CreatePurchaseOrdersForm = (props) => {
  const {
    setMessage,
    errorMessage,
    rfqId,
    handleOrdersData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    draftStatusFunction,
    draftCreateFunction = () => {},
    poType,
    projectDetails,
    proId,
    budget_id,
  } = props;
  console.log("initialValue", initialValue);
  const advPayTerm = initialValue?.PurchaseOrderPaymentTermDetails?.map(
    (ele) => {
      return ele.purchase_order_payment_term_id;
    }
  );

  const [isLoader, setIsLoader] = React.useState(false);
  const [selectItem, setSelectItem] = React.useState([]);
  const [purchaseOrdersName, setPurchaseOrdersName] = React.useState({
    value: initialValue.purchase_order_name
      ? initialValue.purchase_order_name
      : "",
    error: false,
    success: false,
  });
  const [vendor, setVendor] = React.useState({
    value: initialValue.vendor ? initialValue.vendor.name : "",
    error: false,
    success: false,
  });
  const [billingAddress, setBillingAddress] = React.useState({
    value: initialValue.billing_address
      ? initialValue.billing_address
      : projectDetails.billing_address
      ? projectDetails.billing_address
      : "",
    error: false,
    success: false,
  });
  const [shiptoVendor, setShiptoVendor] = React.useState({
    value: initialValue.ship_to_vendor ? initialValue.ship_to_vendor.id : "",
    error: false,
    success: false,
  });
  const [shippingAddress, setShippingAddress] = React.useState({
    value: initialValue.shipping_address
      ? initialValue.shipping_address
      : projectDetails.shipping_address
      ? projectDetails.shipping_address
      : "",
    error: false,
    success: false,
  });
  const [purchaseStatus, setPurchaseStatue] = React.useState({
    value: initialValue.purchase_order_status
      ? initialValue.purchase_order_status
      : "",
    error: false,
    success: false,
  });
  const [quotationNumber, setquotationNumber] = React.useState({
    value: initialValue.quotationNumber ? initialValue.quotationNumber : "",
    error: false,
    success: false,
  });
  const [incoterms, setIncoterms] = React.useState({
    value: initialValue.incoterms_id ? initialValue.incoterms_id : "",
    error: false,
    success: false,
  });
  const [originType, setOriginType] = React.useState({
    value: initialValue.origin_type_id ? initialValue.origin_type_id : "",
    error: false,
    success: false,
  });
  const [originDetails, setOriginDetails] = React.useState({
    value: initialValue.origin_details ? initialValue.origin_details : "",
    error: false,
    success: false,
  });
  const [modeofTransportation, setModeofTransportation] = React.useState({
    value: initialValue.mode_of_transportation_id
      ? initialValue.mode_of_transportation_id
      : "",
    error: false,
    success: false,
  });
  const [deliveryLocation, setDeliveryLocation] = React.useState({
    value: initialValue.delivery_location_id
      ? initialValue.delivery_location_id
      : 0,
    error: false,
    success: false,
  });
  const [reqrddoc, setReqrddoc] = React.useState([]);
  const [isItemForm, setIsItemForm] = React.useState(false);
  const [lineItems, setLineItems] = React.useState([]);
  const [paymentProgressOption, setPaymentProgressOption] = React.useState([]);
  const [paymentFinalOption, setPaymentFinalOption] = React.useState([]);
  const [budgetPrice, setBudgetPrice] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [userId, setUserId] = React.useState();
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(budgetPrice);
  useEffect(() => {
    if (actionType === "edit") {
      setIsLoader(true);
      const arr = initialValue.PurchaseOrderRequiredDocument;
      const checkArr = { checked: true };
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var requiredDoc = [];
      const resultss = arr.map((ele) =>
        requiredDoc.push(ele.required_document_id)
      );
      AllActiveStatusRequiredDocument()
        .then((res) => {
          const result = res.data.data.map((ele) => ({
            key: ele.id,
            value: ele.id,
            label: ele.name,
            checked: requiredDoc.includes(ele.id) ? true : false,
          }));
          setReqrddoc(result);
        })
        .catch((e) => {
          setIsLoader(false);
        });
    }
  }, [actionType]);

  const [bankChargesBy, setBankChargesBy] = React.useState({
    value: initialValue.bank_charges_by_id
      ? initialValue.bank_charges_by_id
      : "",
    error: false,
    success: false,
  });
  const [modeOfPayment, setModeOfPayment] = React.useState({
    value: initialValue.mode_of_payment_id
      ? initialValue.mode_of_payment_id
      : "",
    error: false,
    success: false,
  });
  const [paymentTerms, setPaymentTerms] = React.useState([]);
  const [showAdvance, setShowAdvance] = React.useState(false);
  const [showBeforeShipment, setShowBeforeShipment] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);
  const [showFinal, setShowFinal] = React.useState(false);
  console.log(paymentTerms);
  const [advPayment, setAdvPayment] = React.useState({
    value: initialValue.advPayment ? initialValue.advPayment : "",
    error: false,
    success: false,
  });
  const [advancePaymentD1, setAdvancePaymentD1] = React.useState({
    value: initialValue.advancePaymentD1 ? initialValue.advancePaymentD1 : "",
    error: false,
    success: false,
  });
  const [advancePaymentD2, setAdvancePaymentD2] = React.useState({
    value: initialValue.advancePaymentD2 ? initialValue.advancePaymentD2 : "",
    error: false,
    success: false,
  });
  const [progressPaymentPercentage, setProgressPaymentPercentage] = useState({
    value: initialValue.progressPayPercentage
      ? initialValue.progressPayPercentage
      : "",
    error: false,
    success: false,
  });
  const [advPayPercentage, setAdvPayPercentage] = React.useState({
    value: initialValue.percentage ? initialValue.percentage : 0,
    error: false,
    success: false,
  });
  const [finalPayPercentage, setFinalPayPercentage] = React.useState({
    value: initialValue.percentage ? initialValue.percentage : 0,
    error: false,
    success: false,
  });

  const [beforeShipmentPayment, setBeforeShipmentPayment] = React.useState({
    value: initialValue.beforeShipmentPayment
      ? initialValue.beforeShipmentPayment
      : "",
    error: false,
    success: false,
  });
  const [beforeShipmentPaymentD1, setBeforeShipmentPaymentD1] = React.useState({
    value: initialValue.beforeShipmentPaymentD1
      ? initialValue.beforeShipmentPaymentD1
      : "",
    error: false,
    success: false,
  });
  const [beforeShipmentPaymentD2, setBeforeShipmentPaymentD2] = React.useState({
    value: initialValue.beforeShipmentPaymentD2
      ? initialValue.beforeShipmentPaymentD2
      : "",
    error: false,
    success: false,
  });
  const [progressPayment, setProgressPayment] = React.useState({
    value: initialValue.progressPayment ? initialValue.progressPayment : "",
    error: false,
    success: false,
  });
  const [progressPaymentD1, setProgressPaymentD1] = React.useState({
    value: initialValue.progressPaymentD1 ? initialValue.progressPaymentD1 : "",
    error: false,
    success: false,
  });
  const [progressPaymentD2, setProgressPaymentD2] = React.useState({
    value: initialValue.progressPaymentD2 ? initialValue.progressPaymentD2 : "",
    error: false,
    success: false,
  });
  const [finalPayment, setFinalPayment] = React.useState({
    value: initialValue.finalPayment ? initialValue.finalPayment : "",
    error: false,
    success: false,
  });
  const [finalPaymentD1, setFinalPaymentD1] = React.useState({
    value: initialValue.finalPaymentD1 ? initialValue.finalPaymentD1 : "",
    error: false,
    success: false,
  });
  const [finalPaymentD2, setFinalPaymentD2] = React.useState({
    value: initialValue.finalPaymentD2 ? initialValue.finalPaymentD2 : "",
    error: false,
    success: false,
  });
  const [shopDrawing, setShopDrawing] = React.useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_shop_drawing
      : "",
    error: false,
    success: false,
  });
  const [finishSample, setFinishSample] = React.useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_finish_sample
      : "",
    error: false,
    success: false,
  });
  const [sample, setSample] = React.useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_sample_prototype
      : "",
    error: false,
    success: false,
  });
  const [washTestSample, setWashTestSample] = React.useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_wash_test_sample
      : "",
    error: false,
    success: false,
  });
  const [production, setProduction] = React.useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_production
      : "",
    error: false,
    success: false,
  });
  const [transit, setTransit] = React.useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_transit
      : "",
    error: false,
    success: false,
  });
  const [productionTriggers, setProductionTriggers] = React.useState([
    { key: 1, functionName: "Submittal Approvals", checked: false },
    { key: 2, functionName: "Advance Payment", checked: false },
  ]);
  const [transitTriggers, settransitTriggers] = React.useState([
    { key: 1, functionName: "Submittal Approvals", checked: false },
    { key: 2, functionName: "Pre Shipment Payment", checked: false },
  ]);
  const [btnDisable, setBtnDisable] = React.useState(true);

  useEffect(() => {
    if (actionType === "edit") {
      let newProductionTriggers = productionTriggers.filter(function (
        objFrom1
      ) {
        return [].find(function (objFrom2) {
          return objFrom1.key === objFrom2.submittals_approvals;
        });
      });

      const transitTri = newProductionTriggers.map((ele) => {
        return {
          key: ele.key,
          functionName: ele.functionName,
          checked: true,
        };
      });
      const newArr = [...transitTri, ...transitTriggers];
      var setObj = new Set();
      var result = newArr.reduce((acc, item) => {
        if (!setObj.has(item.key)) {
          setObj.add(item.key, item);
          acc.push(item);
        }
        return acc;
      }, []);
      setProductionTriggers(result);
    }
  }, [initialValue, actionType]);
  useEffect(() => {
    if (actionType === "edit") {
      let newtransitTriggers = transitTriggers.filter(function (objFrom1) {
        return [].find(function (objFrom2) {
          return objFrom1.key === objFrom2.submittals_approvals;
        });
      });

      const transitTri = newtransitTriggers.map((ele) => {
        return {
          key: ele.key,
          functionName: ele.functionName,
          checked: true,
        };
      });
      const newArr = [...transitTri, ...transitTriggers];
      var setObj = new Set();
      var result = newArr.reduce((acc, item) => {
        if (!setObj.has(item.key)) {
          setObj.add(item.key, item);
          acc.push(item);
        }
        return acc;
      }, []);
      settransitTriggers(result);
    }
  }, [initialValue, actionType]);
  const [rqrdSubmittalsCheck, setrqrdSubmittalsCheck] = React.useState([]);
  useEffect(() => {
    if (actionType === "edit") {
      setIsLoader(true);
      const arr = [];
      const checkArr = { checked: true };
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var requiredSub = [];
      const resultss = arr.map((ele) => requiredSub.push(ele.submittals_id));
      AllActiveStatusRequiredSubmittals()
        .then((res) => {
          const result = res.data.data.map((ele) => ({
            key: ele.id,
            value: ele.id,
            label: ele.name,
            checked: requiredSub.includes(ele.id) ? true : false,
          }));
          setrqrdSubmittalsCheck(result);
        })
        .catch((e) => {
          setIsLoader(false);
        });
    }
  }, [actionType]);

  const [specialNotes, setSpecialNotes] = React.useState({
    value: initialValue.notes ? initialValue.notes : "",
    error: false,
    success: false,
  });

  const [revisionNotes, setRevisionNotes] = React.useState({
    value: initialValue.revision_notes ? initialValue.revision_notes : "",
    error: false,
    success: false,
  });

  const [skip, setSkip] = React.useState(false);

  const functionPaymentResultTrue = (paymentTerms) => {
    const paymentArray = [];
    let objectdata = {};
    console.log(paymentTerms);
    paymentTerms.forEach((func) => {
      if (func.checked === true) {
        objectdata = {
          purchase_order_payment_term_id: func.key.toString(),
          value:
            func.key === 1
              ? advPayment.value
              : func.key === 2
              ? beforeShipmentPayment.value
              : func.key === 3
              ? progressPayment.value
              : finalPayment.value,
          percentage:
            func.key === 1
              ? advPayPercentage.value
              : func.key === 2
              ? beforeShipmentPayment.value
              : func.key === 3
              ? progressPaymentPercentage.value
              : finalPayPercentage.value,
          option_one:
            func.key === 1
              ? advancePaymentD1.value
              : func.key === 2
              ? beforeShipmentPaymentD1.value
              : func.key === 3
              ? progressPaymentD1.value
              : finalPaymentD1.value,
          option_two:
            func.key === 1
              ? advancePaymentD2.value
              : func.key === 2
              ? beforeShipmentPaymentD2.value
              : func.key === 3
              ? progressPaymentD2.value
              : finalPaymentD2.value,
        };
        paymentArray.push(objectdata);
      }
    });
    return paymentArray;
  };
  const functionRequiredDocumentTrue = (reqrddoc) => {
    const requiredDocumentArray = [];
    reqrddoc.forEach((func) => {
      if (func.checked === true) {
        requiredDocumentArray.push(func.key);
      }
    });
    return requiredDocumentArray;
  };
  const functionRequiredSubmittalsTrue = (rqrdSubmittalsCheck) => {
    const requiredSubmittalsArray = [];
    rqrdSubmittalsCheck.forEach((func) => {
      if (func.checked === true) {
        requiredSubmittalsArray.push(func.key);
      }
    });
    return requiredSubmittalsArray;
  };
  const functionProductionTrue = (productionTriggers) => {
    const productionTriggersArray = [];
    productionTriggers.forEach((func) => {
      if (func.checked === true) {
        productionTriggersArray.push(func.key);
      }
    });
    return productionTriggersArray;
  };
  const functionTransitTrue = (transitTriggers) => {
    const transitTriggersArray = [];
    transitTriggers.forEach((func) => {
      if (func.checked === true) {
        transitTriggersArray.push(func.key);
      }
    });
    return transitTriggersArray;
  };
  const [vendorDropDown, SetvendorDropDown] = React.useState([]);
  const [incotermDropDown, SetincotermDropDown] = React.useState([]);
  const [countryDropDown, SetcountryDropDown] = React.useState([]);
  const [MOTDropDown, SetMOTDropDown] = React.useState([]);
  const [locationDropDown, SetlocationDropDown] = React.useState([]);
  const [ModeofpaymentDropDown, SetModeofpaymentDropDown] = React.useState([]);
  const [budgetItems, setBudgetItems] = React.useState([]);

  const getPurchaseItems = () => {
    setIsLoader(true);
    if (actionType === "add") {
      GetSelectItemsByRfqId(rfqId)
        .then((res) => {
          setBudgetItems(res.data.data);
          const result = res.data.data.map((elem) => ({
            id: elem.id,
            vendor_id: elem.vendor_id,
            vendorName: elem.vendor.name,
            budget_item_id: elem.budget_item_id,
            itemSerialNo: elem.budgetitem.control_number,
            itemName: elem.budgetitem.description,
            itemSize: elem.budgetitem.budget_modeldetails.size
              ? elem.budgetitem.budget_modeldetails.size
              : null,
            actual_quantity:
              elem.budgetitem.budgetquantitydetails.budget_quantity,
            unit_price: elem.quotation_item.unit_price,
            total_price: elem.quotation_item.total_price,
            quotation_id: elem.quotation_item.quotation_id,
            actual_uom_id: elem.budgetitem.budgetquantitydetails.budgetuom.id,
            actualUOM: elem.budgetitem.budgetquantitydetails.budgetuom.name,
            actual_price: elem.budgetitem.budgetquantitydetails.actual_price,
          }));
          const budgetPrice = result
            .reduce((acc, curr) => {
              return acc + parseFloat(curr.actual_price);
            }, 0)
            .toFixed(2);
          setBudgetPrice(budgetPrice);
          setVendor({
            value: result[0]?.vendorName,
            error: false,
            success: true,
          });
          setRows(result);
          setIsLoader(false);
        })
        .catch((e) => {
          setIsLoader(false);
        });
    } else if (actionType === "editDraft") {
      GetRevisionSelectItemByRfqId(rfqId)
        .then((res) => {
          setBudgetItems(res.data.data);
          const result = res.data.data.map((elem) => ({
            id: elem.id,
            vendor_id: elem.vendor_id,
            vendorName: elem.vendor.name,
            budget_item_id: elem.budget_item_id,
            itemSerialNo: elem.budgetitem.control_number,
            itemName: elem.budgetitem.name,
            itemSize: elem.budgetitem.budget_modeldetails.size
              ? elem.budgetitem.budget_modeldetails.size
              : null,
            actual_quantity:
              elem.budgetitem.budgetquantitydetails.budget_quantity,
            unit_price: elem.quotation_item.unit_price,
            total_price: elem.quotation_item.total_price,
            quotation_id: elem.quotation_item.quotation_id,
            actual_uom_id: elem.budgetitem.budgetquantitydetails.budgetuom.id,
            actualUOM: elem.budgetitem.budgetquantitydetails.budgetuom.name,
          }));
          setVendor({
            value: result[0]?.vendorName,
            error: false,
            success: true,
          });
          setRows(result);
          setIsLoader(false);
        })
        .catch((e) => {
          setIsLoader(false);
        });
    } else if (actionType === "edit") {
      if (poType) {
        const vendorData = {
          name: initialValue.vendor.name,
          id: initialValue.vendor.id,
        };
        // const budgetPrice = initialValue.item_details
        //   .reduce((acc, curr) => {
        //     return acc + parseFloat(curr.total_price);
        //   }, 0)
        //   .toFixed(2);
        // setBudgetPrice(budgetPrice);
        // const fetchItem = initialValue.item_details.map((ele) => ({
        //   id: ele.id,
        //   vendor_id: vendorData.id,
        //   vendorName: vendorData.name,
        //   budget_item_id: ele.budget_item_id,
        //   itemSerialNo: ele.budgetitem.control_number,
        //   itemName: ele.budgetitem.name,
        //   itemSize: "N/A",
        //   actual_quantity: ele.actual_quantity,
        //   unit_price: ele.unit_price,
        //   total_price: ele.total_price,
        //   quotation_id: 0,
        //   actual_uom_id: ele.actual_uom_id,
        //   actualUOM: ele.actual_uom_Name,
        // }));
        // setRows(fetchItem);
        // setIsLoader(false);
      } else {
        GetRevisionSelectItemByRfqId(rfqId)
          .then((res) => {
            setBudgetItems(res.data.data);
            const result = res.data.data.map((elem) => ({
              id: elem.id,
              vendor_id: elem.vendor_id,
              vendorName: elem.vendor.name,
              budget_item_id: elem.budget_item_id,
              itemSerialNo: elem.budgetitem.control_number,
              itemName: elem.budgetitem.name,
              itemSize: elem.budgetitem.budget_modeldetails.size
                ? elem.budgetitem.budget_modeldetails.size
                : null,
              actual_quantity:
                elem.budgetitem.budgetquantitydetails.budget_quantity,
              unit_price: elem.quotation_item.unit_price,
              total_price: elem.quotation_item.total_price,
              quotation_id: elem.quotation_item.quotation_id,
              actual_uom_id: elem.budgetitem.budgetquantitydetails.budgetuom.id,
              actualUOM: elem.budgetitem.budgetquantitydetails.budgetuom.name,
            }));
            setRows(result);
            setIsLoader(false);
          })
          .catch((e) => {
            setIsLoader(false);
          });
      }
    }
  };

  const GetAllActiveVendor = () => {
    setIsLoader(true);
    getAllActiveVendor()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetvendorDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const getAllActiveIncoterm = () => {
    setIsLoader(true);
    GetAllActiveIncoterm()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetincotermDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const getAllActiveCountry = () => {
    setIsLoader(true);
    GetAllActiveCountry()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        const noneOption = {
          key: "none",
          value: 0,
          label: "None",
        };
        result.unshift(noneOption);
        setIsLoader(false);
        SetcountryDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const getAllActiveMot = () => {
    setIsLoader(true);
    GetAllActiveTransportation()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetMOTDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const getAllActiveLocation = () => {
    setIsLoader(true);
    AllActiveStatusLocation()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        const noneOption = {
          key: "none",
          value: 0,
          label: "None",
        };
        result.unshift(noneOption);
        setIsLoader(false);
        SetlocationDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  React.useEffect(() => {
    if (actionType === "add") {
      setIsLoader(true);
      AllActiveStatusRequiredDocument()
        .then((res) => {
          let data = res.data.data;
          const result = data.map((ele) => ({
            key: ele.id,
            value: ele.id,
            label: ele.name,
          }));
          setIsLoader(false);
          setReqrddoc(result);
        })
        .catch((e) => {
          setIsLoader(false);
        });
    }
  }, [actionType]);
  const getAllActiveMOP = () => {
    setIsLoader(true);
    AllActiveStatusModeOfPayment()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetModeofpaymentDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const getAllActivePaymentTerms = () => {
    setIsLoader(true);
    GetAllActivePaymentTerms()
      .then((res) => {
        console.log(res);
        let data = res.data.data;
        const result = data.map((ele) => {
          return {
            key: ele.id,
            value: ele.id,
            label: ele.name,
            // checked: advPayTerm.includes(ele.id) ? true : false,
          };
        });

        setIsLoader(false);
        setPaymentTerms(result);
        data.forEach((ele) => {
          handleChangePaymentTermsUseEffect(ele.id);
        });
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const getAllActivePaymentProgress = () => {
    setIsLoader(true);
    GetActivePaymentProgress()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          // value: ele.id,
          value: ele.name,
          label: ele.name,
        }));
        setIsLoader(false);
        setPaymentProgressOption(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const getAllActivePaymentFinal = () => {
    setIsLoader(true);
    GetActiveFinalPaymentAgainst()
      .then((res) => {
        let data = res.data.data;
        console.log("data", data);
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.name,
          label: ele.name,
        }));
        setIsLoader(false);
        setPaymentFinalOption(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  React.useEffect(() => {
    getAllActivePaymentProgress();
    getAllActivePaymentFinal();
  }, []);

  React.useEffect(() => {
    if (actionType === "add") {
      setIsLoader(true);
      AllActiveStatusRequiredSubmittals()
        .then((res) => {
          let data = res.data.data;
          const result = data.map((ele) => ({
            key: ele.id,
            value: ele.id,
            label: ele.name,
          }));
          setIsLoader(false);
          setrqrdSubmittalsCheck(result);
        })
        .catch((e) => {
          setIsLoader(false);
        });
    }
  }, [actionType]);
  React.useEffect(() => {
    getItemsByVendor();
    getPurchaseItems();
    GetAllActiveVendor();
    getAllActiveIncoterm();
    getAllActiveCountry();
    getAllActiveMot();
    getAllActiveLocation();
    getAllActiveMOP();
    getAllActivePaymentTerms();
  }, []);

  const getItemsByVendor = () => {
    let data = {
      budget_id: budget_id,
      vendor_id: initialValue.vendor.id,
    };
    setIsLoader(true);
    GetAllItemsByVendor(data, data.vendor_id)
      .then((res) => {
        console.log({ res });
        const budgetItemList = res.data.data?.map((elem) => ({
          id: elem.item_serial_number,
          itemCtrlNo: elem.control_number,
          itemDesc: elem.name,
          budget_quantity:
            elem.budget_quantity !== null ? elem.budget_quantity : 0,
          budget_Price: elem.budget_price !== null ? elem.budget_price : 0,
          actual_quantity:
            elem.actual_quantity !== null ? elem.actual_quantity : 0,
          actualPrice: elem.actual_price !== null ? elem.actual_price : 0,
          actual_uom_id:
            elem?.actual_uom_id !== null
              ? elem.actual_uom_id
              : elem?.budget_uom_id,
          quantity:
            elem?.actual_quantity !== null
              ? elem.actual_quantity
                ? elem.actual_quantity
                : 0
              : 0,
          total_price:
            elem.actual_price !== null
              ? (elem?.actual_price
                  ? parseFloat(elem?.actual_price * elem?.actual_quantity)
                  : 0
                ).toFixed(2)
              : 0,
          // unit_price:
          //   elem.actual_price !== null
          //     ? (elem.actual_price
          //         ? parseFloat(elem.actual_price)
          //         : parseFloat(elem.budget_price)
          //       ).toFixed(2)
          //     : 0,
          unit_price:
            elem.actual_price !== null
              ? (elem.actual_price ? parseFloat(elem.actual_price) : 0).toFixed(
                  2
                )
              : 0,
          quotation_id: null,
          vendorId: initialValue.vendor.id,
        }));
        const budgetPrice = budgetItemList
          .reduce((acc, curr) => {
            return acc + parseFloat(curr.actualPrice);
          }, 0)
          .toFixed(2);
        setBudgetPrice(budgetPrice);
        setIsLoader(false);

        setRows(budgetItemList);
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };

  const handleDraftData = () => {
    const itemDetails = rows;
    let filterItems = [];
    for (let i = 0; i < itemDetails.length; i++) {
      if (itemDetails[i].checked === true) {
        filterItems.push(itemDetails[i]);
      }
    }
    let resItems = filterItems.map(
      ({
        actual_uom_id,
        budget_item_id,
        total_price,
        unit_price,
        actual_quantity,
        quotation_id,
      }) => ({
        actual_uom_id,
        budget_item_id,
        total_price,
        unit_price,
        actual_quantity,
        quotation_id,
      })
    );
    let quoId = [];
    quoId.push(resItems[0].quotation_id);
    let newItem = [];
    const result = resItems.map((ele) => {
      return {
        actual_quantity: ele.actual_quantity,
        actual_uom_id: ele.actual_uom_id.toString(),
        budget_item_id: ele.budget_item_id.toString(),
        quotation_id: ele.quotation_id.toString(),
        total_price: ele.total_price.toString(),
        unit_price: ele.unit_price.toString(),
      };
    });
    newItem.push(result);
    const purchaseOrdersData = {
      item_details: newItem ? newItem : [],
      rfq_id: parseInt(rfqId),
      purchase_order_name: purchaseOrdersName.value
        ? purchaseOrdersName.value
        : "",
      ship_to_vendor_id: shiptoVendor.value ? shiptoVendor.value : "",
      vendor_id: rows[0]?.vendor_id,
      billing_address: billingAddress.value ? billingAddress.value : "",
      shipping_address: shippingAddress.value ? shippingAddress.value : "",
      purchase_order_status: "Draft" ? "Draft" : "",
      quotation_numbers: quoId ? quoId : null,
      incoterms_id: incoterms.value ? incoterms.value : "",
      origin_type_id: originType.value ? originType.value : "",
      origin_details: originDetails.value ? originDetails.value : "",
      mode_of_transportation_id: modeofTransportation.value
        ? modeofTransportation.value
        : "",
      delivery_location_id:
        deliveryLocation.value != 0 ? deliveryLocation.value : 0,
      bank_charges_by_id: bankChargesBy.value ? bankChargesBy.value : "",
      mode_of_payment_id: modeOfPayment.value ? modeOfPayment.value : "",
      purchase_order_required_document: functionRequiredDocumentTrue(reqrddoc),
      notes: specialNotes ? specialNotes.value : null,
      revision_notes: revisionNotes ? revisionNotes.value : null,
      purchase_order_payment_term: functionPaymentResultTrue(paymentTerms),
      lead_time: [
        {
          days_for_shop_drawing: shopDrawing.value.toString(),
          days_for_sample_prototype: sample.value.toString(),
          days_for_finish_sample: finishSample.value.toString(),
          days_for_wash_test_sample: washTestSample.value.toString(),
          days_for_production: production.value.toString(),
          days_for_transit: transit.value.toString(),
        },
      ],
      production_triggers: functionProductionTrue(productionTriggers),
      transit_triggers: functionTransitTrue(transitTriggers),
      required_submittals: functionRequiredSubmittalsTrue(rqrdSubmittalsCheck),
    };
    const user = JSON.parse(localStorage.getItem("user"));
    setIsLoader(true);

    createPODraft(purchaseOrdersData)
      .then((res) => {
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
        draftStatusFunction(res);
        setIsLoader(false);
      })
      .catch((e) => {
        setMessage(e.response.data.message);
        setIsLoader(false);
      });
  };
  const handleSaveData = () => {
    console.log(proId);
    const itemDetails = rows;
    // let filterItems = [];
    // for (let i = 0; i < itemDetails.length; i++) {
    //   if (itemDetails[i].checked === true) {
    //     filterItems.push(itemDetails[i]);
    //   }
    // }

    // let resItems = rows.map(
    //   ({
    //     actual_uom_id,
    //     budget_item_id,
    //     total_price,
    //     unit_price,
    //     actual_quantity,
    //     quotation_id,
    //   }) => ({
    //     actual_uom_id,
    //     budget_item_id,
    //     total_price,
    //     unit_price,
    //     actual_quantity,
    //     quotation_id,
    //   })
    // );
    // console.log("resitem",resItems)

    // let newItem = [];

    // resItems.map((ele) => {
    //   console.log({ ele });
    //   const result = {
    //     actual_quantity: ele.actual_quantity,
    //     actual_uom_id: ele.actual_uom_id?.toString(),
    //     budget_item_id: ele.id?.toString(),
    //     quotation_id: ele.quotation_id?.toString(),
    //     total_price: ele.total_price?.toString(),
    //     unit_price: ele.unit_price?.toString(),
    //   };
    //   newItem.push(result);
    // });
    let quoId = [];
    let newItem = [];
    rows.forEach((ele) => {
      const result = {
        quotation_id: 0,
        budget_item_id: ele.id.toString(),
        actual_uom_id:
          ele.actual_uom_id !== null ? ele.actual_uom_id.toString() : "0",
        actual_quantity: ele.quantity,
        total_price: ele.total_price.toString(),
        unit_price: ele.unit_price.toString(),
      };

      if (ele.checked) {
        newItem.push(result);
      }
    });
    if (newItem.length) {
      quoId.push(newItem[0].quotation_id);
    }

    const purchaseOrdersData = {
      project_id: proId,
      item_details: newItem,
      rfq_id: rfqId ? parseInt(rfqId) : 0,
      purchase_order_name: purchaseOrdersName.value,
      ship_to_vendor_id: shiptoVendor.value,
      // vendor_id: rows[0]?.vendorId,
      vendor_id: initialValue.vendor.id,
      billing_address: billingAddress.value,
      shipping_address: shippingAddress.value,
      purchase_order_status: purchaseStatus.value
        ? purchaseStatus.value
        : "Draft",
      quotation_numbers: quoId[0] == null ? [] : quoId,
      incoterms_id: incoterms.value,
      origin_type_id: originType.value,
      origin_details: originDetails.value,
      mode_of_transportation_id: modeofTransportation.value,
      delivery_location_id:
        deliveryLocation.value != 0 ? deliveryLocation.value.toString() : 0,
      bank_charges_by_id: bankChargesBy.value,
      mode_of_payment_id: modeOfPayment.value,
      purchase_order_required_document: functionRequiredDocumentTrue(reqrddoc),
      notes: specialNotes ? specialNotes.value : null,
      revision_notes: revisionNotes ? revisionNotes.value : null,
      purchase_order_payment_term: functionPaymentResultTrue(paymentTerms),
      lead_time: [
        {
          days_for_shop_drawing: shopDrawing.value?.toString(),
          days_for_sample_prototype: sample.value?.toString(),
          days_for_finish_sample: finishSample.value?.toString(),
          days_for_wash_test_sample: washTestSample.value?.toString(),
          days_for_production: production.value?.toString(),
          days_for_transit: transit.value?.toString(),
        },
      ],
      production_triggers: functionProductionTrue(productionTriggers),
      transit_triggers: functionTransitTrue(transitTriggers),
      required_submittals: functionRequiredSubmittalsTrue(rqrdSubmittalsCheck),
      file_data: null,
      purchase_order_item_size: [{ height: "1", width: "1", length: "1" }],
      action_by: user.id,
    };
    console.log("purchaseOrdersData", purchaseOrdersData);
    handleOrdersData(purchaseOrdersData);
    draftCreateFunction(purchaseOrdersData);
  };
  // const [screen, setScreen] = React.useState(actionType == "edit" ? 2 : 1);
  const [screen, setScreen] = React.useState(1);
  const handleChangeVendor = (value) => {
    setMessage(null);
    setVendor(value);
  };
  const handleChangebillingAddress = (value) => {
    setMessage(null);
    setBillingAddress(value);
  };
  const handleChangeshiptoVendor = (value) => {
    setMessage(null);
    setShiptoVendor(value);
  };
  const handleChangeshippingAddress = (value) => {
    setMessage(null);
    setShippingAddress(value);
  };
  const nextBtnClick = () => {
    setScreen(screen + 1);
  };
  const prevBtnClick = () => {
    setScreen(screen - 1);
  };
  const handleChangeIncoterms = (value) => {
    setMessage(null);
    setIncoterms(value);
  };
  const handleChangeOriginType = (value) => {
    console.log({ value });
    setMessage(null);
    setOriginType(value);
  };
  const handleChangeoriginDetails = (value) => {
    setMessage(null);
    setOriginDetails(value);
  };
  const handleChangemodeofTransportation = (value) => {
    setMessage(null);
    setModeofTransportation(value);
  };
  const handleChangedeliveryLocation = (value) => {
    setMessage(null);
    setDeliveryLocation(value);
  };
  const handleChangeCheck = (key) => {
    const result = reqrddoc.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setReqrddoc(result);
  };
  const handleChangePaymentTerms = (key) => {
    const paymentResult = paymentTerms.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setPaymentTerms(paymentResult);
    if (key === 1) {
      setShowAdvance(!showAdvance);
    }
    if (key === 2) {
      setShowBeforeShipment(!showBeforeShipment);
    }
    if (key === 3) {
      setShowProgress(!showProgress);
    }
    if (key === 4) {
      setShowFinal(!showFinal);
    }
  };
  const handleChangePaymentTermsUseEffect = (key) => {
    if (key === 1) {
      let advanceData = initialValue?.PurchaseOrderPaymentTermDetails.filter(
        (ele) => {
          return ele.purchase_order_payment_term_id == 1;
        }
      );
      console.log("advanceData", advanceData);

      setAdvPayment({
        value: advanceData[0]?.value,
      });
      setAdvPayPercentage({
        value: advanceData[0]?.percentage,
      });
      setAdvancePaymentD1({
        value: advanceData[0]?.option_one,
      });
      setAdvancePaymentD2({
        value: advanceData[0]?.option_two,
      });
      setShowAdvance(!showAdvance);
    }
    if (key === 2) {
      setShowBeforeShipment(!showBeforeShipment);
    }
    if (key === 3) {
      let advanceData = initialValue?.PurchaseOrderPaymentTermDetails.filter(
        (ele) => {
          return ele.purchase_order_payment_term_id == 3;
        }
      );

      setProgressPayment({
        value: advanceData[0]?.value,
        error: false,
        success: false,
      });
      setProgressPaymentD1({
        value: advanceData[0]?.option_one,
        success: true,
        error: false,
      });
      setProgressPaymentD2({
        value: advanceData[0]?.option_two,
        success: true,
        error: false,
      });
      setShowProgress(!showProgress);
    }
    if (key === 4) {
      let advanceData = initialValue?.PurchaseOrderPaymentTermDetails.filter(
        (ele) => {
          return ele.purchase_order_payment_term_id == 4;
        }
      );
      console.log("advanceData", advanceData);
      setFinalPayment({
        value: advanceData[0]?.value,
      });
      setFinalPayPercentage({
        value: advanceData[0]?.percentage,
      });
      setFinalPaymentD1({
        value: advanceData[0]?.option_one,
      });
      setFinalPaymentD2({
        // value: advanceData ? parseInt(advanceData[0].option_two) : null,
        value: advanceData[0]?.option_two,
      });
      setShowFinal(!showFinal);
    }
  };
  const handleCheckBoxChange = () => {
    // if (showAdvance === false) {
    //   setAdvPayment("");
    //   setAdvancePaymentD1("");
    //   setAdvancePaymentD2("");
    // }
    // if (showBeforeShipment === false) {
    //   setBeforeShipmentPayment("");
    //   setBeforeShipmentPaymentD1("");
    //   setBeforeShipmentPaymentD2("");
    // }
    // if (showProgress === false) {
    //   setProgressPayment("");
    //   setProgressPaymentD1("");
    //   setProgressPaymentD2("");
    // }
    // if (showFinal === false) {
    //   setFinalPayment("");
    //   setFinalPaymentD1("");
    //   setFinalPaymentD2("");
    // }
  };
  const handleChangeProductionTriggers = (key) => {
    const resultProductionTriggers = productionTriggers.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setProductionTriggers(resultProductionTriggers);
  };
  const handleChangeTransitTriggers = (key) => {
    const resultTransitTriggers = transitTriggers.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    settransitTriggers(resultTransitTriggers);
  };
  const handleChangebankChargesBy = (value) => {
    setBankChargesBy(value);
  };
  const handleChangemodeOfPayment = (value) => {
    setModeOfPayment(value);
  };
  const handleChangeAdvPayment = (value) => {
    // let paymentValue = value.value !== "" ? parseInt(value.value) : 0;
    // let totalValue = budgetPrice;

    // let PercentageAdvVal = ((paymentValue / totalValue) * 100).toFixed(2);

    // setAdvPayPercentage({
    //   value: PercentageAdvVal,
    //   error: false,
    //   success: false,
    // });
    setAdvPayment(value);
  };

  const handleChangeAdvPayPercentage = (value) => {
    let totalValue = budgetPrice;
    let paymentPercentage = value.value !== "" ? parseInt(value.value) : 0;
    let paymentValue = (paymentPercentage * totalValue) / 100;
    setAdvPayPercentage(value);
    setAdvPayment({
      value: paymentValue,
      error: false,
      success: false,
    });
  };
  const handleChangeFinalPayPercentage = (value) => {
    let totalValue = budgetPrice;
    let paymentPercentage = value.value !== "" ? parseInt(value.value) : 0;
    let paymentValue = (paymentPercentage * totalValue) / 100;
    setFinalPayPercentage(value);
    setFinalPayment({
      value: paymentValue,
      error: false,
      success: false,
    });
  };
  const handleChangeadvancePaymentD1 = (value) => {
    setAdvancePaymentD1(value);
  };
  const handleChangeadvancePaymentD2 = (value) => {
    console.log(value);
    setAdvancePaymentD2(value);
  };
  const handleChangeBeforeShipmentPayment = (value) => {
    setBeforeShipmentPayment(value);
  };
  const handleChangebeforeShipmentPaymentD1 = (value) => {
    setBeforeShipmentPaymentD1(value);
  };
  const handleChangebeforeShipmentPaymentD2 = (value) => {
    setBeforeShipmentPaymentD2(value);
  };
  const handleChangeprogressPayment = (value) => {
    // let totalValue = budgetPrice;
    // let paymentPercentage = value.value !== "" ? parseInt(value.value) : 0;
    // let paymentValue = (paymentPercentage * totalValue) / 100;
    // setProgressPaymentPercentage({
    //   value: paymentValue,
    //   error: false,
    //   success: false,
    // });
    setProgressPayment(value);
  };
  const handleChangeprogressPaymentD1 = (value) => {
    setProgressPaymentD1(value);
  };
  const handleChangeprogressPaymentD2 = (value) => {
    setProgressPaymentD2(value);
  };

  const handleChangefinalPayment = (value) => {
    // let paymentValue = value.value !== "" ? parseInt(value.value) : 0;
    // let totalValue = budgetPrice;
    // let PercentageAdvVal = ((paymentValue / totalValue) * 100).toFixed(2);
    // setFinalPayPercentage({
    //   value: PercentageAdvVal,
    //   error: false,
    //   success: false,
    // });
    setFinalPayment(value);
  };
  const handleChangefinalPaymentD1 = (value) => {
    setFinalPaymentD1(value);
  };
  const handleChangefinalPaymentD2 = (value) => {
    console.log(value);
    setFinalPaymentD2(value);
  };

  const handleChangeshopDrawing = (value) => {
    setShopDrawing(value);
  };
  const handleChangefinishSample = (value) => {
    setFinishSample(value);
  };
  const handleChangeSample = (value) => {
    setSample(value);
  };
  const handleChangeWashTestSample = (value) => {
    setWashTestSample(value);
  };

  const handleChangeTransit = (value) => {
    setTransit(value);
  };

  const handleChangeProduction = (value) => {
    setProduction(value);
  };

  const handleChangeRqrdSubmittalsCheck = (key) => {
    const resultSubmittalCheck = rqrdSubmittalsCheck.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setrqrdSubmittalsCheck(resultSubmittalCheck);
  };

  const handleChangespecialNotes = (value) => {
    setSpecialNotes(value);
  };

  const handleChangeRevisionNotes = (value) => {
    setRevisionNotes(value);
  };

  let isValueNotChanged1 =
    purchaseOrdersName.value === initialValue.purchaseOrdersName &&
    billingAddress.value === initialValue.billingAddress &&
    shiptoVendor.value === initialValue.shiptoVendor &&
    purchaseStatus.value === initialValue.purchaseStatus &&
    shippingAddress.value === initialValue.shippingAddress &&
    quotationNumber.value === initialValue.quotationNumber &&
    incoterms.value === initialValue.incoterms &&
    originType.value === initialValue.originType &&
    originDetails.value === initialValue.originDetails &&
    modeofTransportation.value === initialValue.modeofTransportation &&
    // deliveryLocation.value === initialValue.deliveryLocation &&
    bankChargesBy.value === initialValue.bankChargesBy &&
    modeOfPayment.value === initialValue.modeOfPayment &&
    production.value === initialValue.production;
  // transit.value === initialValue.transit;

  let disable1 =
    billingAddress.error ||
    billingAddress.value === "" ||
    shippingAddress.value === "" ||
    incoterms.error ||
    incoterms.value === "" ||
    // modeofTransportation.error ||
    // modeofTransportation.value === "" ||
    // deliveryLocation.error ||
    // deliveryLocation.value === "" ||
    bankChargesBy.value === "" ||
    modeOfPayment.value === "" ||
    bankChargesBy.error ||
    modeOfPayment.error ||
    production.error ||
    production.value === "" ||
    transit.error ||
    advancePaymentD1.error ||
    advancePaymentD1.value === "" ||
    advancePaymentD2.error ||
    advancePaymentD2.value === "" ||
    progressPaymentD1.error ||
    progressPaymentD1.value === "" ||
    progressPaymentD2.error ||
    progressPaymentD2.value === "" ||
    finalPaymentD1.error ||
    finalPaymentD1.value === "" ||
    finalPaymentD2.error ||
    finalPaymentD2.value === "" ||
    // transit.value === "" ||
    isValueNotChanged1;

  const bankChargesByOption = [
    {
      key: 1,
      value: 1,
      label: "Vendor",
    },
    {
      key: 2,
      value: 2,
      label: "Buyer",
    },
  ];
  const paymentDropdown1 = [
    {
      key: 1,
      value: "Against",
      label: "Against",
    },
    {
      key: 2,
      value: "After",
      label: "After",
    },
    {
      key: 3,
      value: "30 Days after",
      label: "30 Days after",
    },
    {
      key: 4,
      value: "Prior to",
      label: "Prior to",
    },
  ];
  const paymentDropdown2 = [
    {
      key: 1,
      value: "Invoice",
      label: "Invoice",
    },
    {
      key: 2,
      value: "Original Invoice",
      label: "Original Invoice",
    },
    {
      key: 3,
      value: "Original Invoice & Bank Guarantee",
      label: "Original Invoice & Bank Guarantee",
    },
    {
      key: 4,
      value: "Original Invoice & Security Cheque",
      label: "Original Invoice & Security Cheque",
    },
  ];

  const createPurchaseOrdersHeader =
      strings["PurchaseOrders.createPurchaseOrdersHeader"],
    editPurchaseOrdersHeader =
      strings["PurchaseOrders.editPurchaseOrdersHeader"],
    vendorLabel = strings["PurchaseOrders.vendorLabel"],
    vendorErrorLine = strings["PurchaseOrders.vendorErrorLine"],
    billingAddressLabel = strings["PurchaseOrders.billingAddressLabel"],
    billingAddressPlaceholder =
      strings["PurchaseOrders.billingAddressPlaceholder"],
    billingAddressCustomError =
      strings["PurchaseOrders.billingAddressCustomError"],
    shiptoVendorLabel = strings["PurchaseOrders.shiptoVendorLabel"],
    shiptoVendorErrorLine = strings["PurchaseOrders.shiptoVendorErrorLine"],
    shiptoVendorSelectText = strings["PurchaseOrders.shiptoVendorSelectText"],
    shippingAddressLabel = strings["PurchaseOrders.shippingAddressLabel"],
    shippingAddressPlaceholder =
      strings["PurchaseOrders.shippingAddressPlaceholder"],
    shippingAddressCustomError =
      strings["PurchaseOrders.shippingAddressCustomError"],
    incotermsLabel = strings["PurchaseOrders.incotermsLabel"],
    incotermsErrorLine = strings["PurchaseOrders.incotermsErrorLine"],
    incotermsSelectText = strings["PurchaseOrders.incotermsSelectText"],
    originTypeLabel = strings["PurchaseOrders.originTypeLabel"],
    originTypeErrorLine = strings["PurchaseOrders.originTypeErrorLine"],
    originTypeSelectText = strings["PurchaseOrders.originTypeSelectText"],
    originDetailsLabel = strings["PurchaseOrders.originDetailsLabel"],
    originDetailsPlaceholder =
      strings["PurchaseOrders.originDetailsPlaceholder"],
    originDetailsCustomError =
      strings["PurchaseOrders.originDetailsCustomError"],
    modeofTransportationLabel =
      strings["PurchaseOrders.modeofTransportationLabel"],
    modeofTransportationErrorLine =
      strings["PurchaseOrders.modeofTransportationErrorLine"],
    modeofTransportationSelectText =
      strings["PurchaseOrders.modeofTransportationSelectText"],
    deliveryLocationLabel = strings["PurchaseOrders.deliveryLocationLabel"],
    deliveryLocationErrorLine =
      strings["PurchaseOrders.deliveryLocationErrorLine"],
    deliveryLocationSelectText =
      strings["PurchaseOrders.deliveryLocationSelectText"],
    bankChargesByLabel = strings["PurchaseOrders.bankChargesByLabel"],
    bankChargesByErrorLine = strings["PurchaseOrders.bankChargesByErrorLine"],
    bankChargesBySelectText = strings["PurchaseOrders.bankChargesBySelectText"],
    modeOfPaymentLabel = strings["PurchaseOrders.modeOfPaymentLabel"],
    modeOfPaymentErrorLine = strings["PurchaseOrders.modeOfPaymentErrorLine"],
    modeOfPaymentSelectText = strings["PurchaseOrders.modeOfPaymentSelectText"],
    advancePaymentHeader = strings["PurchaseOrders.advancePaymentHeader"],
    paymentValueLabel = strings["PurchaseOrders.paymentValueLabel"],
    advPaymentPlaceholder = strings["PurchaseOrders.advPaymentPlaceholder"],
    advPaymentCustomError = strings["PurchaseOrders.advPaymentCustomError"],
    advancePaymentD1SelectText =
      strings["PurchaseOrders.advancePaymentD1SelectText"],
    advancePaymentD1ErrorLine =
      strings["PurchaseOrders.advancePaymentD1ErrorLine"],
    advancePaymentD2SelectText =
      strings["PurchaseOrders.advancePaymentD2SelectText"],
    advancePaymentD2ErrorLine =
      strings["PurchaseOrders.advancePaymentD2ErrorLine"],
    beforeShipmentPaymentHeader =
      strings["PurchaseOrders.beforeShipmentPaymentHeader"],
    beforeShipmentPaymentPlaceholder =
      strings["PurchaseOrders.beforeShipmentPaymentPlaceholder"],
    beforeShipmentPaymentCustomError =
      strings["PurchaseOrders.beforeShipmentPaymentCustomError"],
    beforeShipmentPaymentD1ErrorLine =
      strings["PurchaseOrders.beforeShipmentPaymentD1ErrorLine"],
    beforeShipmentPaymentD1SelectText =
      strings["PurchaseOrders.beforeShipmentPaymentD1SelectText"],
    beforeShipmentPaymentD2ErrorLine =
      strings["PurchaseOrders.beforeShipmentPaymentD2ErrorLine"],
    beforeShipmentPaymentD2SelectText =
      strings["PurchaseOrders.beforeShipmentPaymentD2SelectText"],
    progressPaymentHeader = strings["PurchaseOrders.progressPaymentHeader"],
    progressPaymentPlaceholder =
      strings["PurchaseOrders.progressPaymentPlaceholder"],
    progressPaymentCustomError =
      strings["PurchaseOrders.progressPaymentCustomError"],
    progressPaymentD1ErrorLine =
      strings["PurchaseOrders.progressPaymentD1ErrorLine"],
    progressPaymentD1SelectText =
      strings["PurchaseOrders.progressPaymentD1SelectText"],
    progressPaymentD2ErrorLine =
      strings["PurchaseOrders.progressPaymentD2ErrorLine"],
    progressPaymentD2SelectText =
      strings["PurchaseOrders.progressPaymentD2SelectText"],
    finalPaymentHeader = strings["PurchaseOrders.finalPaymentHeader"],
    finalPaymentPlaceholder = strings["PurchaseOrders.finalPaymentPlaceholder"],
    finalPaymentCustomError = strings["PurchaseOrders.finalPaymentCustomError"],
    finalPaymentD1ErrorLine = strings["PurchaseOrders.finalPaymentD1ErrorLine"],
    finalPaymentD1SelectText =
      strings["PurchaseOrders.finalPaymentD1SelectText"],
    finalPaymentD2ErrorLine = strings["PurchaseOrders.finalPaymentD2ErrorLine"],
    finalPaymentD2SelectText =
      strings["PurchaseOrders.finalPaymentD2SelectText"],
    shopDrawingLabel = strings["PurchaseOrders.shopDrawingLabel"],
    shopDrawingPlaceholder = strings["PurchaseOrders.shopDrawingPlaceholder"],
    shopDrawingCustomError = strings["PurchaseOrders.shopDrawingCustomError"],
    finishSampleLabel = strings["PurchaseOrders.finishSampleLabel"],
    finishSamplePlaceholder = strings["PurchaseOrders.finishSamplePlaceholder"],
    finishSampleCustomError = strings["PurchaseOrders.finishSampleCustomError"],
    sampleLabel = strings["PurchaseOrders.sampleLabel"],
    samplePlaceholder = strings["PurchaseOrders.samplePlaceholder"],
    sampleCustomError = strings["PurchaseOrders.sampleCustomError"],
    washTestSampleLabel = strings["PurchaseOrders.washTestSampleLabel"],
    washTestSamplePlaceholder =
      strings["PurchaseOrders.washTestSamplePlaceholder"],
    washTestSampleCustomError =
      strings["PurchaseOrders.washTestSampleCustomError"],
    productionLabel = strings["PurchaseOrders.productionLabel"],
    productionPlaceholder = strings["PurchaseOrders.productionPlaceholder"],
    productionCustomError = strings["PurchaseOrders.productionCustomError"],
    transitLabel = strings["PurchaseOrders.transitLabel"],
    transitPlaceholder = strings["PurchaseOrders.transitPlaceholder"],
    transitCustomError = strings["PurchaseOrders.transitCustomError"],
    ProductionTriggersHeader =
      strings["PurchaseOrders.ProductionTriggersHeader"],
    TransitTriggersHeader = strings["PurchaseOrders.TransitTriggersHeader"],
    specialNotesLabel = strings["PurchaseOrders.specialNotesLabel"],
    specialNotesPlaceholder = strings["PurchaseOrders.specialNotesPlaceholder"],
    specialNotesCustomError = strings["PurchaseOrders.specialNotesCustomError"],
    cancelBtn = strings["PurchaseOrders.cancelBtn"],
    tableSerialNo = strings["PurchaseOrders.tableSerialNo"],
    tableItemDesc = strings["PurchaseOrders.tableItemDesc"],
    tableVendorName = strings["PurchaseOrders.tableVendorName"],
    tableItemSize = strings["PurchaseOrders.tableItemSize"],
    tableActualUOM = strings["PurchaseOrders.tableActualUOM"],
    tableActualQuantity = strings["PurchaseOrders.tableActualQuantity"],
    tableUnitPrice = strings["PurchaseOrders.tableUnitPrice"],
    action = strings["ProductAndServices.TableHead.action"],
    tableTotalPrice = strings["PurchaseOrders.tableTotalPrice"];

  const [rows, setRows] = React.useState([]);
  const mapableData = [
    {
      dataKey: "itemCtrlNo",
      title: "Ctrl No.",
    },
    {
      dataKey: "budget_quantity",
      title: "Budget Qty",
    },
    {
      dataKey: "budget_Price",
      title: "Budget Price",
    },
    {
      dataKey: "actual_quantity",
      title: "Actual Qty",
    },
    {
      dataKey: "actualPrice",
      title: "Actual Price",
    },
    {
      dataKey: "total_price",
      title: "Total Qty",
    },
    {
      dataKey: "unit_price",
      title: "Unit Price",
    },
  ];

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const handleSelectData = (id) => {
    const result = rows.map((data) => {
      if (data.id === id) {
        return { ...data, checked: !data.checked };
      } else {
        return { ...data };
      }
    });
    let isDifferent = false;
    let checkedDatas = result.filter((doc) => doc.checked === true);
    checkedDatas.forEach((doc) => {
      for (let i of checkedDatas) {
        if (doc.vendor_id !== i.vendor_id) {
          isDifferent = true;
        }
      }
    });
    setBtnDisable(isDifferent);
    setRows(result);
    setSelectItem(result);
  };

  const handleSelectAllData = () => {
    const allSelected = rows.every((item) => item.checked);
    const updatedItems = rows.map((item) => ({
      ...item,
      checked: !allSelected,
    }));
    setRows(updatedItems);

    const selected = !allSelected ? updatedItems : [];
    console.log(selected);
    setSelectItem(selected);
  };

  const [itemId, setItemId] = React.useState(null);
  const handleView = (data) => {
    const result = budgetItems.find((res) => {
      return res.budget_item_id === data.budget_item_id;
    });
    setItemId(result.budgetitem.id);
    const filterResult = {
      id: result.budgetitem.id,
      budget: result.budgetitem.budget,
      serial_number: result.budgetitem.serial_number,
      name: result.budgetitem.name,
      description: result.budgetitem.description,
      itemgroup: result.budgetitem.itemgroup,
      item_subgroup_id: result.budgetitem.item_subgroup_id,
      itemcategory: result.budgetitem.itemcategory,
      brand: result.budgetitem.brand,
      tags: result.budgetitem.tags,
      budgetquantitydetails: result.budgetitem.budgetquantitydetails,
      budgetShipToVendordetails: result.budgetitem.budget_ship_to_vendordetails,
      budgetModeldetails: result.budgetitem.budget_modeldetails,
    };
    setLineItems(filterResult);
    if (result.budget_item_id > 0) {
      setIsItemForm(!isItemForm);
    }
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleChangeEdit = (data) => {
    const result = budgetItems.find((res) => {
      return res.budget_item_id === data.budget_item_id;
    });
    setCurrentUserType(result);
    setUserId(result.id);
    openAddModal();
    handleSelectBtn("edit");
  };

  const updateBudgetLineItem = (formData) => {
    const id = itemId;
    setIsLoader(true);
    UpdateBudgetItem(formData, id)
      .then((res) => {
        getPurchaseItems();
        setIsLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setIsItemForm(false);
      })
      .catch((e) => {
        setIsLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const handleModalClosed = () => {
    setMessage("");
    setIsItemForm(false);
  };

  const selectModal = () => {
    return (
      <></>
      // <CreateBudgetItemForm
      //   proId={proId}
      //   budget_id={budget_id}
      //   budget_scope={budget_scope}
      //   handleBudgetItemData={updateBudgetLineItem}
      //   actionType={clickedBtn}
      //   errorMessage={errorMessage}
      //   initialValue={currentUserType}
      //   handleModalClose={handleModalClose}
      //   rseArray={rseArray}
      //   budgetDetail={budgetDetail}
      // />
    );
  };

  return (
    <div className="modalFormContainer">
      {isLoader ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {isItemForm ? (
        <CreateBudgetItemForm
          handleBudgetItemData={updateBudgetLineItem}
          actionType={"edit"}
          initialValue={lineItems}
          handleModalClose={handleModalClosed}
        />
      ) : (
        <>
          <h2>
            {actionType === "add"
              ? createPurchaseOrdersHeader
              : editPurchaseOrdersHeader}
          </h2>
          {
            // actionType === "add" &&

            screen === 1 && (
              <>
                <h3 className="my-3">Budget Item List</h3>
                <div className="col-12">
                  <SearchCheckedTables
                    // placeholder={"itemName"}
                    data={rows}
                    mapableData={mapableData}
                    searchProp={["itemCtrlNo"]}
                    handleChangeCheck={handleSelectData}
                    handleView={handleView}
                    handleChangeEdit={handleChangeEdit}
                    showSelectAll={false}
                    handleSelectAll={handleSelectAllData}
                  />
                  {/* <Modal
                  onHandleClose={handleModalClose}
                  isOpen={isOpen}
                  contentStyle={{
                    width: "90vw",
                    height: "90vh",
                  }}
                >
                  {selectModal()}
                </Modal> */}
                </div>
                <div className="row">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "1.5rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      buttonName={"Cancel"}
                      buttonStyle={{ backgroundColor: colors.primary }}
                      onClick={() => {
                        handleModalClose();
                      }}
                    />
                    <Button
                      buttonName={"Next"}
                      buttonStyle={{ backgroundColor: colors.primary }}
                      onClick={nextBtnClick}
                      // disable={btnDisable}
                    />
                  </div>
                </div>
              </>
            )
          }
          {screen === 2 && (
            <>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="textarea"
                      label={vendorLabel}
                      placeholder={"vendor"}
                      Value={vendor.value}
                      onChangeText={handleChangeVendor}
                      editable={false}
                      CustomErrorLine={vendorErrorLine}
                      starProps={true}
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="textarea"
                      label={billingAddressLabel}
                      placeholder={billingAddressPlaceholder}
                      Value={billingAddress.value}
                      onChangeText={handleChangebillingAddress}
                      Validate={true}
                      CustomErrorLine={billingAddressCustomError}
                      starProps={true}
                      maxLength={200}
                      multiline={true}
                      editable
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="textarea"
                      label={shippingAddressLabel}
                      placeholder={shippingAddressPlaceholder}
                      Value={shippingAddress.value}
                      onChangeText={handleChangeshippingAddress}
                      Validate={true}
                      CustomErrorLine={shippingAddressCustomError}
                      starProps={true}
                      maxLength={200}
                      multiline={true}
                      editable
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="textarea"
                      label={originDetailsLabel}
                      placeholder={originDetailsPlaceholder}
                      Value={originDetails.value}
                      onChangeText={handleChangeoriginDetails}
                      Validate={false}
                      CustomErrorLine={originDetailsCustomError}
                      editable={actionType === "add" ? true : false}
                      starProps={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                      Label={shiptoVendorLabel}
                      Options={vendorDropDown}
                      value={shiptoVendor.value}
                      onSelect={handleChangeshiptoVendor}
                      CustomErrorLine={shiptoVendorErrorLine}
                      selectText={shiptoVendorSelectText}
                      searchable={false}
                      starProps={false}
                      isValidate={false}
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "26px",
                        marginBottom: "10px",
                      }}
                      starProps={false}
                      isValidate={false}
                      Label={deliveryLocationLabel}
                      Options={locationDropDown}
                      value={deliveryLocation.value}
                      onSelect={handleChangedeliveryLocation}
                      CustomErrorLine={deliveryLocationErrorLine}
                      selectText={deliveryLocationSelectText}
                      searchable={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                      Label={incotermsLabel}
                      Options={incotermDropDown}
                      value={incoterms.value}
                      onSelect={handleChangeIncoterms}
                      CustomErrorLine={incotermsErrorLine}
                      selectText={incotermsSelectText}
                      searchable={false}
                      starProps={true}
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "15px",
                        marginBottom: "10px",
                      }}
                      Label={originTypeLabel}
                      Options={countryDropDown}
                      value={originType.value}
                      onSelect={handleChangeOriginType}
                      CustomErrorLine={originTypeErrorLine}
                      selectText={originTypeSelectText}
                      searchable={false}
                      starProps={false}
                      isValidate={false}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="row mb-2">
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      Label={modeofTransportationLabel}
                      Options={MOTDropDown}
                      value={modeofTransportation.value}
                      onSelect={handleChangemodeofTransportation}
                      CustomErrorLine={modeofTransportationErrorLine}
                      selectText={modeofTransportationSelectText}
                      searchable={false}
                      // starProps={true}
                      isValidate={true}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <h4>Required Documents</h4>
                  {reqrddoc.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.label}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeCheck(functionName.key)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="row">
                  <h4>Payment Details</h4>
                  <div className="col-12 col-md-6 pe-0 pe-md-2">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      Label={bankChargesByLabel}
                      Options={bankChargesByOption}
                      value={bankChargesBy.value}
                      onSelect={handleChangebankChargesBy}
                      CustomErrorLine={bankChargesByErrorLine}
                      selectText={bankChargesBySelectText}
                      searchable={false}
                      starProps={true}
                      isValidate={true}
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      Label={modeOfPaymentLabel}
                      Options={ModeofpaymentDropDown}
                      value={modeOfPayment.value}
                      onSelect={handleChangemodeOfPayment}
                      CustomErrorLine={modeOfPaymentErrorLine}
                      selectText={modeOfPaymentSelectText}
                      searchable={false}
                      starProps={true}
                      isValidate={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <h4>{"Payment Terms"}</h4>
                  {paymentTerms.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.label}
                          checked={functionName.checked}
                          handleChangeCheck={(val) => {
                            handleCheckBoxChange();
                            return handleChangePaymentTerms(functionName.key);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  {!showAdvance && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{advancePaymentHeader}</h4>
                      <div className="row">
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="text"
                              label={paymentValueLabel}
                              placeholder={advPaymentPlaceholder}
                              Value={advPayment.value}
                              onChangeText={handleChangeAdvPayment}
                              Validate={false}
                              CustomErrorLine={advPaymentCustomError}
                              starProps={false}
                              editable
                              min={0}
                            />
                          </div>
                          {/* <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="number"
                              label={"Percentage"}
                              placeholder={"Enter Advance Percentage"}
                              Value={advPayPercentage.value?.toString()}
                              onChangeText={handleChangeAdvPayPercentage}
                              Validate={advPayPercentage.value ? true : false}
                              CustomErrorLine={"Enter percentage in number"}
                              starProps={false}
                              editable
                            />
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={advancePaymentD1.value}
                              onSelect={handleChangeadvancePaymentD1}
                              CustomErrorLine={advancePaymentD1ErrorLine}
                              selectText={advancePaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                              defaultValue={paymentDropdown1[0]}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentDropdown2}
                              value={advancePaymentD2.value}
                              onSelect={handleChangeadvancePaymentD2}
                              CustomErrorLine={advancePaymentD2ErrorLine}
                              selectText={advancePaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showBeforeShipment && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{beforeShipmentPaymentHeader}</h4>
                      <div className="row">
                        <div className="col-12 col-md-6 pe-0 pe-md-2">
                          <FormTextInput
                            labelTextStyle={{
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                            starProps={true}
                            type="text"
                            label={paymentValueLabel}
                            placeholder={beforeShipmentPaymentPlaceholder}
                            Value={beforeShipmentPayment.value}
                            onChangeText={handleChangeBeforeShipmentPayment}
                            CustomErrorLine={beforeShipmentPaymentCustomError}
                            editable
                            min={0}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={beforeShipmentPaymentD1.value}
                              onSelect={handleChangebeforeShipmentPaymentD1}
                              CustomErrorLine={beforeShipmentPaymentD1ErrorLine}
                              selectText={beforeShipmentPaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-4">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentDropdown2}
                              value={beforeShipmentPaymentD2.value}
                              onSelect={handleChangebeforeShipmentPaymentD2}
                              CustomErrorLine={beforeShipmentPaymentD2ErrorLine}
                              selectText={beforeShipmentPaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showProgress && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{progressPaymentHeader}</h4>
                      <div className="row">
                        <div className="col-12 col-md-6 pe-0 pe-md-2">
                          <FormTextInput
                            labelTextStyle={{
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                            type="text"
                            label={paymentValueLabel}
                            placeholder={progressPaymentPlaceholder}
                            Value={progressPayment.value}
                            onChangeText={handleChangeprogressPayment}
                            CustomErrorLine={progressPaymentCustomError}
                            // starProps={true}
                            editable
                            min={0}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={progressPaymentD1.value}
                              onSelect={handleChangeprogressPaymentD1}
                              CustomErrorLine={progressPaymentD1ErrorLine}
                              selectText={progressPaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-4">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentProgressOption}
                              value={progressPaymentD2.value}
                              onSelect={handleChangeprogressPaymentD2}
                              CustomErrorLine={progressPaymentD2ErrorLine}
                              selectText={progressPaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showFinal && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{finalPaymentHeader}</h4>
                      <div className="row">
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="text"
                              label={paymentValueLabel}
                              placeholder={finalPaymentPlaceholder}
                              Value={finalPayment.value}
                              onChangeText={handleChangefinalPayment}
                              CustomErrorLine={finalPaymentCustomError}
                              starProps={false}
                              editable
                              min={0}
                            />
                          </div>
                          {/* <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="number"
                              label={"Percentage"}
                              placeholder={"Enter Final Percentage"}
                              Value={finalPayPercentage.value?.toString()}
                              onChangeText={handleChangeFinalPayPercentage}
                              Validate={false}
                              CustomErrorLine={"Enter percentage in number"}
                              starProps={false}
                              editable
                            />
                          </div> */}
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={finalPaymentD1.value}
                              onSelect={handleChangefinalPaymentD1}
                              CustomErrorLine={finalPaymentD1ErrorLine}
                              selectText={finalPaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentFinalOption}
                              value={finalPaymentD2.value}
                              onSelect={handleChangefinalPaymentD2}
                              CustomErrorLine={finalPaymentD2ErrorLine}
                              selectText={finalPaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-3">
                <div className="row">
                  <h4>Lead Time</h4>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="number"
                      label={shopDrawingLabel}
                      placeholder={shopDrawingPlaceholder}
                      Value={shopDrawing.value}
                      onChangeText={handleChangeshopDrawing}
                      Validate={shopDrawing.value && true}
                      CustomErrorLine={shopDrawingCustomError}
                      starProps={false}
                      editable
                      min={0}
                    />
                    <FormTextInput
                      type="number"
                      label={finishSampleLabel}
                      placeholder={finishSamplePlaceholder}
                      Value={finishSample.value}
                      onChangeText={handleChangefinishSample}
                      Validate={finishSample.value && true}
                      CustomErrorLine={finishSampleCustomError}
                      starProps={false}
                      editable
                      min={0}
                    />
                    <FormTextInput
                      type="number"
                      label={sampleLabel}
                      placeholder={samplePlaceholder}
                      Value={sample.value}
                      onChangeText={handleChangeSample}
                      Validate={sample.value && true}
                      CustomErrorLine={sampleCustomError}
                      starProps={false}
                      editable
                      min={0}
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="number"
                      label={washTestSampleLabel}
                      placeholder={washTestSamplePlaceholder}
                      Value={washTestSample.value}
                      onChangeText={handleChangeWashTestSample}
                      Validate={washTestSample.value && true}
                      CustomErrorLine={washTestSampleCustomError}
                      starProps={false}
                      editable
                      min={0}
                    />
                    <FormTextInput
                      type="number"
                      label={productionLabel}
                      placeholder={productionPlaceholder}
                      Value={production.value}
                      onChangeText={handleChangeProduction}
                      Validate={true}
                      CustomErrorLine={productionCustomError}
                      starProps={true}
                      editable
                      min={0}
                    />
                    <FormTextInput
                      type="number"
                      label={transitLabel}
                      placeholder={transitPlaceholder}
                      Value={transit.value}
                      onChangeText={handleChangeTransit}
                      Validate={false}
                      CustomErrorLine={transitCustomError}
                      starProps={false}
                      editable
                      min={0}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <h4>{ProductionTriggersHeader}</h4>
                  {productionTriggers.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.functionName}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeProductionTriggers(functionName.key)
                          }
                          starProps={false}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <h4>{TransitTriggersHeader}</h4>
                  {transitTriggers.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.functionName}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeTransitTriggers(functionName.key)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="row mt-3">
                  <h4>{"Required Submittals"}</h4>
                  {rqrdSubmittalsCheck.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          starProps={true}
                          key={functionName.key}
                          Label={functionName.label}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeRqrdSubmittalsCheck(functionName.key)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                      className="col-6 col-md-6 pe-0 pe-md-3 mt-3"
                    >
                      <div style={{ marginTop: "-20px" }}>
                        {!skip && (
                          <FormTextInput
                            starProps={false}
                            type="textarea"
                            label={specialNotesLabel}
                            placeholder={specialNotesPlaceholder}
                            Value={specialNotes.value}
                            onChangeText={handleChangespecialNotes}
                            Validate={specialNotes.value && true}
                            CustomErrorLine={specialNotesCustomError}
                            multiline={true}
                            editable
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {initialValue.purchase_order_status === "Revision" && (
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                        }}
                        className="col-6 col-md-6 pe-0 pe-md-3 mt-3"
                      >
                        <div style={{ marginTop: "-20px" }}>
                          {!skip && (
                            <FormTextInput
                              starProps={false}
                              type="textarea"
                              label="Reason For Revision"
                              placeholder="Enter Reason For Revision"
                              Value={revisionNotes.value}
                              onChangeText={handleChangeRevisionNotes}
                              Validate={revisionNotes.value && true}
                              CustomErrorLine={specialNotesCustomError}
                              multiline={true}
                              editable
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    marginTop: "1.5rem",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Button
                      buttonName={actionType === "add" ? "Back" : "Back"}
                      buttonStyle={{
                        backgroundColor: colors.primary,
                      }}
                      onClick={prevBtnClick}
                    />
                    <Button
                      buttonName={cancelBtn}
                      buttonStyle={{
                        backgroundColor: colors.darkgrey,
                        marginLeft: "4px",
                      }}
                      onClick={() => {
                        handleModalClose();
                      }}
                    />
                  </div>

                  <div>
                    {actionType === "add" || actionType === "editDraft" ? (
                      <Button
                        buttonName={"Save As Draft"}
                        buttonStyle={{ backgroundColor: colors.success }}
                        onClick={() => {
                          handleDraftData();
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <Button
                      buttonName={actionType === "add" ? "Submit" : "Update"}
                      buttonStyle={{
                        backgroundColor: colors.primary,
                        marginLeft: "10px",
                      }}
                      disable={actionType === "add" ? disable1 : disable1}
                      onClick={handleSaveData}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default CreatePurchaseOrdersForm;
