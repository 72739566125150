/* eslint-disable no-unused-vars */
import React from "react";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { colors } from "../../../../configs";
// import { FormTextInput } from "../../../common";
// import { colors } from '../../../../configs';

const RequestForInformationTable = (props) => {
  const { data, mapableData, actionType } = props;

  const EditCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = rowData.status === "EDIT";
    return (
      <Cell {...props} className={editing ? "table-content-editing" : ""}>
        {editing ? (
          <input
            className="rs-input"
            defaultValue={rowData[dataKey]}
            onChange={(event) => {
              onChange && onChange(rowData.id, dataKey, event.target.value);
            }}
          />
        ) : (
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        )}
      </Cell>
    );
  };

  return (
    <div>
      <Table
        style={{ borderRadius: 10 }}
        id="table"
        data={data}
        rowHeight={50}
        headerHeight={50}
      >
        {mapableData.map((item, index) => {
          return (
            <Column minWidth={130} flexGrow={1} key={index}>
              <HeaderCell
                style={{
                  color: colors.primary,
                  fontWeight: 900,
                  fontSize: "larger",
                }}
              >
                {item.title}
              </HeaderCell>
              <Cell dataKey={item.dataKey} />
              {/* {/ <Cell type="text" /> /} */}
              <EditCell />
            </Column>
          );
        })}
      </Table>
    </div>
  );
};

export default RequestForInformationTable;
