/* eslint-disable no-unused-vars */
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import React from "react";

import swal from "sweetalert";
import {
  GetAllPurchaseItem,
  GetDeliveryQtyByPo,
  ImageCreation,
} from "../../../axios";
import { Button, DateAndTimePicker, FormTextInput } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";

const CustomTableCell = ({ row, name, onChange, onToggleEditMode }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onToggleEditMode(row.id);
    }
  };
  return (
    <TableCell align="left" className={classes.tableCell}>
      {/* {isEditMode ? ( */}
      <Input
        value={row[name]}
        name={name}
        onChange={(e) => onChange(e, row)}
        onKeyDown={handleKeyDown}
        className={classes.input}
      />
      {/* ) : ( */}
      {/* row[name] */}
      {/* )} */}
      <FormHelperText>{"Tab: Next Row | Enter: Update Value"}</FormHelperText>
    </TableCell>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 950,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

const createNoteHeader = strings["Delivery.createNoteHeader"],
  deliveryDateLabel = strings["Delivery.dateLabel"],
  deliveryComment = strings["Delivery.commentLabel"],
  deliveryErrorLine = strings["Delivery.dateErrorLine"],
  deliveryNumberLabel = strings["Delivery.number"];

const DeliveryNoteForm = (props) => {
  const {
    errorMessage,
    initialValue,
    handleModalClose,
    po_Id,
    handleInvoiceData,
    actionType,
  } = props;

  console.log("INIT", initialValue);

  const [rows, setRows] = React.useState([]);

  const classes = useStyles();
  const [previous, setPrevious] = React.useState({});
  const [screen, setScreen] = React.useState(1);
  const [loader, setLoader] = React.useState(false);

  const [deliveryDate, setDeliveryDate] = React.useState({
    value: initialValue.deliveryDt ? initialValue.deliveryDt.split(" ")[0] : "",
    error: false,
    success: false,
  });
  const [deliveryNotes, setDeliveryNotes] = React.useState({
    value: initialValue.delivery_note ? initialValue.delivery_note : "",
    error: false,
    success: false,
  });
  const [deliveryNumber, setDeliveryNumber] = React.useState({
    value: initialValue.delivery_number ? initialValue.delivery_number : "",
    error: false,
    success: false,
  });
  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.file ? initialValue.file : null,
    error: false,
    success: false,
  });

  const [filePath, setFilePath] = React.useState({
    value: initialValue.file ? initialValue.file : null,
    error: false,
    success: false,
  });

  let disable =
    deliveryDate.error ||
    deliveryDate.value === "" ||
    deliveryNumber.value === "" ||
    filePath.value === "";

  // const nextBtnClick = () => {
  //   setScreen(screen + 1);
  // };
  const prevBtnClick = () => {
    setScreen(screen - 1);
  };
  const handleClearFile = () => {
    setSelectedFile("");
    setFilePath([]);
  };

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.id === id) {
          if (row.deliverNow > row.beforeTtlRemains) {
            swal(
              "Quantity Limit Exceeded",
              `Delivery quantity cannot surpass the remaining quantity. Please adjust and retry.`,
              "error"
            );
          } else {
            return {
              ...row,
              isEditMode: !row.isEditMode,
              afterTtlDeliver: row.deliverNow,
              afterTtlRemains: row.beforeTtlRemains - parseInt(row.deliverNow),
            };
          }
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };
  const handleChangeDeadline = (data) => {
    setDeliveryDate(data);
  };
  const handleChangeDeliveryNotes = (data) => {
    setDeliveryNotes(data);
  };

  const hnadleChangeDeliveryNumber = (data) => {
    setDeliveryNumber(data);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileChange = async (data) => {
    setLoader(true);
    if (data.target.files.length > 0) {
      const filename = data.target.files[0].name;
      var fileExtension = filename.split(".").pop();
      const files = data.target.files[0];
      const base64 = await convertBase64(files);
      var newbase64 = base64.replace(/^data:application\/[a-z]+;base64,/, "");
      const uploadData = {
        file_data: newbase64,
        file_extension: fileExtension,
      };
      await ImageCreation(uploadData)
        .then((res) => {
          setLoader(false);
          setFilePath([res.data.data.file_path]);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const findDeliverQty = (data, id) => {
    console.log(id);
    data.forEach((ele, index) => {
      if (ele.delivery_notes_id == id) {
        console.log("QQQQQQQQQQQ", ele.quantity);
        return ele.quantity;
      }
    });
  };

  const getRemainDeliverQty = (data) => {
    setLoader(true);
    let tempLine = [];
    data.forEach((ele, index) => {
      let body = {
        purchase_order_id: po_Id,
        budget_item_id: ele.id,
      };
      GetDeliveryQtyByPo(body)
        .then((res) => {
          console.log("RESSSSS", res.data.data);
          tempLine.push({
            id: ele.id,
            ctrlNo: ele.ctrlNo,
            desc: ele.desc,
            uom: ele.uom,
            qty: ele.qty,
            beforeTtlDeliver: res.data.data.totaldelivered
              ? res.data.data.totaldelivered
              : 0,
            beforeTtlRemains: res.data.data.totalremaining
              ? res.data.data.totalremaining
              : ele.qty,
            deliverNow:
              actionType == "edit"
                ? res.data.data.budgetitemdetails
                  ? res.data.data.budgetitemdetails.find((ele) => {
                      return ele.delivery_notes_id == initialValue.id;
                    }).quantity
                  : 0
                : 0,
            afterTtlDeliver: 0,
            afterTtlRemains: res.data.data.totalremaining
              ? res.data.data.totalremaining
              : ele.qty,
          });
          setRows([...tempLine]);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    });
  };

  const getPurchaseOrderItems = () => {
    setLoader(true);
    GetAllPurchaseItem(po_Id)
      .then((res) => {
        console.log("RES", res.data.data);
        const result = res.data.data.map((ele) => ({
          id: ele.budgetitem.id,
          ctrlNo: ele.budgetitem.control_number,
          desc: ele.budgetitem.description,
          uom: ele.uom.name,
          qty: parseInt(ele.actual_quantity),
          beforeTtlDeliver: null,
          beforeTtlRemains: null,
          deliverNow: null,
          afterTtlDeliver: null,
          afterTtlRemains: null,
        }));
        setLoader(false);
        getRemainDeliverQty(result);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  React.useEffect(() => {
    getPurchaseOrderItems();
  }, []);

  const handleSaveData = () => {
    const itemList = rows.map((ele) => ({
      budget_item_id: ele.id,
      quantity: ele.deliverNow,
    }));
    const formData = {
      purchase_order_id: po_Id,
      file: filePath,
      delivery_date: deliveryDate.value,
      comment: deliveryNotes.value,
      budget_item_data: itemList,
      delivery_number: deliveryNumber.value,
    };
    handleInvoiceData(formData);
  };

  return (
    <div
      className="modalFormContainer"
      style={
        {
          // width: "900px",
        }
      }
    >
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {loader ? <LoaderComponent /> : null}
      <h2>{createNoteHeader}</h2>
      {screen === 1 && (
        <>
          <div className="row">
            <div className="col-md-6">
              <FormTextInput
                labelTextStyle={{
                  marginTop: "10px",
                  marginBottom: "8px",
                }}
                type="text"
                multiline={false}
                label={deliveryNumberLabel}
                placeholder={"Enter Delivery Number"}
                Value={deliveryNumber.value}
                onChangeText={hnadleChangeDeliveryNumber}
                CustomErrorLine={"Delivery Number Can Not Be Empty"}
                editable={true}
                starProps={true}
              />
            </div>

            <div className="col-md-6">
              <FormTextInput
                labelTextStyle={{
                  marginTop: "10px",
                  marginBottom: "8px",
                }}
                type="textarea"
                multiline={true}
                label={deliveryComment}
                placeholder={"Enter Delivery Notes"}
                Value={deliveryNotes.value}
                onChangeText={handleChangeDeliveryNotes}
                // Validate={true}
                CustomErrorLine={"Comment Can Not Be Empty"}
                editable={true}
                // starProps={true}
              />
            </div>
            <div className="row">
              <div className="col-md-3">
                <DateAndTimePicker
                  dateAndTimeLabel={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  label={deliveryDateLabel}
                  customErrorLine={deliveryErrorLine}
                  hadleSetDate={handleChangeDeadline}
                  date={deliveryDate.value}
                  starProps={true}
                  disable={false}
                />
              </div>
              <div className="col-md-9">
                <div style={{ display: "flex" }}>
                  <h3 style={{ marginBottom: "-16px" }}>Import From</h3>
                  <span
                    style={{
                      color: "red",
                      marginLeft: "6px",
                      marginTop: "14px",
                      alignItems: "center",
                    }}
                  >
                    *
                  </span>
                </div>

                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {selectedFile.value ? (
                    <>
                      <p>{selectedFile.value}</p>
                      <button className="btn" onClick={handleClearFile}>
                        <CloseIcon />
                      </button>
                    </>
                  ) : (
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept=".pdf"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h3>Order Items</h3>
          <Paper className={classes.root}>
            <Table className={classes.table} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Control No.
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Description
                  </TableCell> */}
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    UOM
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Total Delivered(before)
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Total Remaining(before)
                  </TableCell>
                  {/* <TableCell align="left" /> */}
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Delivering Now
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Total Delivered(after)
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Total Remaining(after)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.ctrlNo}</TableCell>
                    {/* <TableCell>{row.desc}</TableCell> */}
                    <TableCell>{row.uom}</TableCell>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.beforeTtlDeliver}</TableCell>
                    <TableCell>{row.beforeTtlRemains}</TableCell>
                    {/* <TableCell className={classes.selectTableCell}>
                      {row.isEditMode ? (
                        <>
                          <IconButton
                            aria-label="done"
                            onClick={() => onToggleEditMode(row.id)}
                          >
                            <DoneIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          aria-label="delete"
                          onClick={() => onToggleEditMode(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell> */}
                    <CustomTableCell
                      {...{
                        row,
                        name: "deliverNow",
                        onChange,
                        onToggleEditMode,
                      }}
                    />
                    <TableCell>{row.afterTtlDeliver}</TableCell>
                    <TableCell>{row.afterTtlRemains}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <div className="row">
            <div
              style={{
                display: "flex",
                marginTop: "1.5rem",
                justifyContent: "space-between",
              }}
            >
              <div>
                {/* <Button
                  buttonName={"Back"}
                  buttonStyle={{
                    backgroundColor: colors.primary,
                  }}
                  onClick={prevBtnClick}
                /> */}
                <Button
                  buttonName={"Cancel"}
                  buttonStyle={{
                    backgroundColor: colors.darkgrey,
                    marginLeft: "4px",
                  }}
                  onClick={() => {
                    handleModalClose();
                  }}
                />
                <Button
                  buttonName={"submit"}
                  buttonStyle={{
                    backgroundColor: colors.primary,
                    marginLeft: "4px",
                  }}
                  disable={disable}
                  onClick={handleSaveData}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveryNoteForm;
