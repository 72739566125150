import React from "react";
import { GetAllRFQ } from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  Table,
} from "../../../components";
import { RequestForQuotationForm } from "../../../forms";
import strings from "../../../translations";

const SearchRequestForQuotation = (props) => {
  const [itemSubmittalData, setItemSubmittalData] = React.useState([]);

  // const itemSubmittalData = [
  //     {
  //         id: 1,
  //         rfqName: "RFQ1",
  //         country: "india",
  //         incoterm: "incoterm1",
  //         modeOfTransportation: "MOT1",
  //         deadline: "2021-06-09",
  //         importantNotes: "please do it first",
  //         tendorNotes: "tender money payed by client within 20days",
  //         download: "Download",
  //         vendor: [{
  //             key: 1,
  //             value: 'Vendor1',
  //             label: 'Vendor1'
  //         }]
  //     },
  //     {
  //         id: 2,
  //         rfqName: "RFQ2",
  //         country: "india",
  //         incoterm: "incoterm1",
  //         modeOfTransportation: "MOT1",
  //         deadline: "2021-06-09",
  //         importantNotes: "please do it first",
  //         tendorNotes: "tender money payed by client within 20days",
  //         download: "Download",
  //         vendor: [{
  //             key: 1,
  //             value: 'Vendor1',
  //             label: 'Vendor1'
  //         }]
  //     }
  // ]

  const RequestForQuotationTitle =
      strings["RequestForQuotation.RequestForQuotationTitle"],
    // AddButton = strings["RequestForQuotation.addButton"],
    alertMessage = strings["RequestForQuotation.AlertMessage"],
    alertTitle = strings["RequestForQuotation.AlertTitle"],
    RFQName = strings["RequestForQuotation.table.RFQName"],
    Deadline = strings["RequestForQuotation.table.Deadline"],
    Download = strings["RequestForQuotation.table.Download"],
    Actions = strings["RequestForQuotation.table.Actions"];

  const fields = ["id", "rfqName", "deadline", "download"];

  const head = [
    {
      id: "rfqname",
      label: RFQName,
    },
    {
      id: "deadline",
      label: Deadline,
    },
    {
      id: "download",
      label: Download,
    },
    {
      id: "actions",
      label: Actions,
    },
  ];

  const [alertBox, setAlertBox] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});

  const paths = [
    {
      name: strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["SearchForQuotation"],
      path: "/requestforquotation",
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const handleChangeDelete = () => {
    setAlertBox(!alertBox);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setAlertBox(false);
  };

  const onEditClick = (id) => {
    const result = itemSubmittalData.find((res) => {
      return res.id === id;
    });
    setCurrentUserType(result);
    openAddModal();
    handleSelectBtn("edit");
  };

  const getAllRFQ = () => {
    GetAllRFQ()
      .then((res) => {
        const result = res.data.data.map((elm) => ({
          id: elm.id,
          rfqName: elm.name,
          country: elm.countries.name,
          incoterm: elm.incoterm.name,
          modeOfTransportation: "MOT1",
          deadline: "2021-06-09",
          importantNotes: "please do it first",
          tendorNotes: "tender money payed by client within 20days",
          download: "Download",
        }));
        setItemSubmittalData(result);
      })
      .catch(() => {
      });
  };
  React.useEffect(() => {
    getAllRFQ();
  }, []);

  const selectModal = () => {
    return (
      <RequestForQuotationForm
        handleProjectData={(val) => {}}
        actionType={clickedBtn}
        initialValue={clickedBtn === "addrfq" ? {} : currentUserType}
        handleModalClose={handleModalClose}
      />
    );
  };

  return (
    <div className="container">
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <BreadCrumbs pathList={paths} />
      <div className="row mt-3">
        <div className="d-md-flex d-block justify-content-between text-center mb-4">
          <h1>{RequestForQuotationTitle}</h1>
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <IconButton
            onClick={() => {
              openAddModal();
              handleSelectBtn("addrfq");
            }}
          >
            <AddIcon />
          </IconButton>
        </div>

        {/* <IconButton onClick={() => {
                openAddModal()
                handleSelectBtn('edit')

            }}>
                <EditIcon />
            </IconButton> */}
        <Table
          theadData={head}
          tbodyData={itemSubmittalData}
          fields={fields}
          isDelete={true}
          isEdit={true}
          editOnClick={onEditClick}
          deleteOnClick={handleChangeDelete}
        />
      </div>
    </div>
  );
};

export default SearchRequestForQuotation;
