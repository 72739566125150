import React from "react";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  SearchTable,
} from "../../../components";
import { CreateStatusForm } from "../../../forms";
import { default as Strings, default as strings } from "../../../translations";

const myData = [
  {
    id: 1,
    name: "Status 1",
    description: "Description 1",
    activeStatus: true,
  },
  {
    id: 2,
    name: "Status 2",
    description: "Description 2",
    activeStatus: false,
  },
  {
    id: 3,
    name: "Status 3",
    description: "Description 3",
    activeStatus: true,
  },
  {
    id: 4,
    name: "Status 4",
    description: "Description 4",
    activeStatus: false,
  },
];
const SearchStatusPage = () => {
  const searchStatusTitle = Strings["Status.DashboardTitle"],
    alertMessage = Strings["Status.AlertMessage"],
    alertTitle = Strings["Status.AlertTitle"],
    tableName = Strings["Status.TableHead.tableName"],
    description = Strings["Status.TableHead.description"],
    activeStatus = Strings["Status.TableHead.activeStatus"],
    action = Strings["Status.TableHead.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentStatus, setCurrentStatus] = React.useState({});
  const [rows, setRows] = React.useState(myData);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["Status"],
      path: "/master-configuration/SearchStatusPage",
    },
  ];
  const mapableData = [
    {
      dataKey: "name",
      title: tableName,
    },
    {
      dataKey: "description",
      title: description,
    },
    {
      dataKey: "activeStatus",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const selectModal = () => {
    return (
      <CreateStatusForm
        handleChangeFunctionData={(val) => {
        }}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentStatus : {}}
        handleModalClose={handleModalClose}
      />
    );
  };
  const handleChangeActiveStatus = (id) => {
    setStatusChangeId(id);
    setAlertBox(true);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    const result = rows.map((rowData) => {
      if (rowData.id === statusChangeId) {
        return { ...rowData, activeStatus: !rowData.activeStatus };
      } else {
        return { ...rowData };
      }
    });
    setRows(result);
    setAlertBox(false);
  };
  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentStatus(result);
    handleSelectBtn("edit");
  };
  return (
    <div className="container">
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <BreadCrumbs pathList={paths} />
      <div className="row mt-3">
        <div className="d-md-flex d-block justify-content-between text-center mb-4">
          <h2> {searchStatusTitle} </h2>
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}

          <IconButton
            onClick={() => {
              openAddModal();
              handleSelectBtn("add");
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
        <div className="col-12">
          <SearchTable
            data={rows}
            mapableData={mapableData}
            searchProp={["name", "description"]}
            handleChangeActiveStatus={handleChangeActiveStatus}
            handleChangeEdit={handleChangeEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchStatusPage;
