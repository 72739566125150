import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams,useHistory,Redirect} from "react-router-dom";
import { BreadCrumbs, IconButton } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import strings from "../../../translations";
import "./GroupReport.css";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import { colors } from "../../../configs";
import { GetGroupReport } from "../../../axios";
import SearchGroupToSubGroupMapping from "./BudgetGroupSubGroup";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getItemFromStorage } from "../../../utils/storage";


const GroupReport = () => {


    const [isLoader, setisLoader] = React.useState(false);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    const [data, setData] = React.useState([]);
    const location = useLocation();
    const history = useHistory();
   
    // console.log("LOCA",location)
    // React.useEffect(() => {
    //     if (!location.state) {
    //         return <Redirect to="/" />
    //     }
    // }, [location]);
   
    let proId = location?.state
        ? location?.state?.proId
        : location?.query
            ? JSON.parse(location.query.params).proId
            : null;
    // const proId = history.location?.state
    // ? history.location?.state.proId
    // : location.query
    // ? JSON.parse(location.query.params).proId
    // : null;
    const fetchId = useParams();
    
    const paramId = isDecoded(fetchId.id);
    const BudgtId = parseInt(paramId);
    let budget_id = history.location?.state ? history.location?.state.budget_id : BudgtId;
  
    let scope_id = location?.state ? location?.state?.scope_id : location?.query
    ? JSON.parse(location.query.params).scope_id
    : null;
    // const scope_id = history.location?.state?.budgetScope
    // ? history.location.state?.budgetScope.id
    // : history.location?.query ? JSON.parse(location.query.params).budgetScope: null;

   
    let budget_Name = location?.state ? location.state?.budget_name
    : location?.query ? JSON.parse(location.query.params).budget_name
    : null;

    if(scope_id == null || scope_id == undefined && budget_Name == null || budget_Name == undefined && proId == null || proId == undefined && budget_id == null || budget_id == undefined){
        const paramData = JSON.parse(getItemFromStorage("PARAMS"))
        if(paramData){
            budget_id = paramData.budget_id;
            proId = paramData.proId;
            budget_Name = paramData.budget_Name;
            scope_id =  paramData.budget_scope;
        }
      }
      const encodeProId = isEncoded(proId);
      const encodeBudId = isEncoded(budget_id);
//   const budget_Name = history.location?.state?.budget_name
//     ? history.location?.state?.budget_name
//     : history.location?.query? JSON.parse(location.query.params).budget_name :null;
  
    // console.log("UUUUUUUUUUUUU",scope_id,budget_Name,proId,budget_id)
    const paths = [
        {
            name: strings["Projects"],
            path: "project",
        },
        {
            name: strings["Budgets"],
            path: `projectbudget/${encodeProId}`,
        },
        {
            name: strings["Budget Items"],
            path: `budgetitem/${encodeBudId}`,
        },
        {
            name: "Group Report",
        },
    ];

    const getGroupReportByBudget = () => {
        setisLoader(true);
        GetGroupReport(budget_id)
            .then((res) => {
                setisLoader(false);
                const filterData = res.data.data.map((ele) => {
                    const subgroups = ele.subgroups_item_report.filter((item) => {
                        return item.group_calculation_item.length !== 0
                    });
                    return { ...ele, 'subgroups_item_report': subgroups }
                });
                setData(filterData);
            })
            .catch((e) => {
                setisLoader(false);
            })
    }

    React.useEffect(() => {
        getGroupReportByBudget();
    }, [])


    function ccyFormat(num) {
        return `${num.toFixed(2)}`;
    }

    return (
        <>
        <div className="content-wrapper p-0 mt-10">
          <div className="row mt-5">
          <BreadCrumbs
                        pathList={paths}
                        obj={{
                            proId: proId,
                            budget_id: budget_id,
                            budget_name: budget_Name,
                            scope_id: scope_id
                        }}
          />
          </div>
         <div className="row mt-5">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
         <Typography>Group Report</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="content-wrapper p-0">
            <Helmet>
                <title>Furnish | Group Report</title>
            </Helmet>
            {isLoader ? <LoaderComponent /> : null}
            <div
                className="row align-items-center mt-4"
                style={{
                    alignItems: "center",
                }}
            >
                <div className="col">
                    <h3 className="">{"Group Report"}</h3>
                  
                </div>
            </div>
            <TableContainer component={Paper} className='mt-4'>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>

                            <TableCell colSpan={4}>
                                <Typography>
                                    Item Group
                                </Typography>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data.map((ele) => {
                                return (
                                    <>
                                        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                                            <TableCell style={{
                                                backgroundColor: colors.primary,
                                                color: colors.white
                                            }} colSpan={4} component="th" scope="row" align="left">
                                                {ele.group_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={true} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1 }}>
                                                        <Table size="small" aria-label="purchases">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Sub Group</TableCell>
                                                                    <TableCell align="right">Budget Price</TableCell>
                                                                    <TableCell align="right">Actual Price</TableCell>
                                                                    <TableCell align="right">Difference</TableCell>
                                                                    <TableCell align="right">Variance(%)</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    ele.subgroups_item_report && ele.subgroups_item_report.map((elem) => {
                                                                        return (
                                                                            <TableRow>
                                                                                <TableCell component="th" scope="row">
                                                                                    {elem.name}
                                                                                </TableCell>
                                                                                <TableCell align="right"> {ccyFormat(elem.BudgetPrice)}</TableCell>
                                                                                <TableCell align="right">{ccyFormat(elem.ActualPrice)}</TableCell>
                                                                                <TableCell  style={{color: elem.VarianceAmount > 0 ? "green" : "red"}}align="right">{ccyFormat(elem.VarianceAmount)}</TableCell>
                                                                                <TableCell  style={{color: elem.VariancePercentage > 0 ? "green" : "red"}}align="right">{ccyFormat(elem.VariancePercentage)}</TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                }
                                                                {/* <TableRow>
                                                                    <TableCell align="right" colSpan={2}>Total</TableCell>
                                                                    <TableCell align="right">{"100"}</TableCell>
                                                                    <TableCell align="right">{"100"}</TableCell>
                                                                </TableRow> */}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        </AccordionDetails>
        </Accordion>

        <>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Budget Specific Item Group to SubGroup Mapping</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <SearchGroupToSubGroupMapping budget_id={budget_id} scope_id={scope_id}/>
        </AccordionDetails>
        </Accordion>
        
        </>
        </div>
        </div>
        </>
    );
};

export default GroupReport;


