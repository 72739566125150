/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { InventoryOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { UpdateOrderStatus } from "../../../../axios";
import { IconButton, TableRow } from "../../../../components";
import { colors } from "../../../../configs";
import "../SearchPurchaseOrderCard/PurchaseCard.css";
const SearchPurchaseOrderCard = (props) => {
  const {
    allData,
    pdi,
    activeStatus,
    serialLabel,
    serialValue,
    purchaseOrderValue,
    statusLabel,
    statusValue,
    vendorLabel,
    vendorValue,
    actionLabel,
    switchButtonOnClick,
    iconButtonOnClick,
    pState,
    proId,
    budget_id,
    rfqId,
    setStatus,
    isUpdate,
    isStatus,
    reviseData,
    handlecsvUploadItem,
    poNo,
    createdOn,
    handleDownload,
    uid,
    poType,
    viewData,
    viewPoData,
  } = props;

  const [orderStatus] = React.useState([
    { key: 1, value: "Draft", label: "Draft" },
    { key: 2, value: "Send To Client", label: "Send To Client" },
    { key: 3, value: "Signed By Client", label: "Signed By Client" },
    { key: 4, value: "Send To Vendor", label: "Send To Vendor" },
    { key: 5, value: "Signed By Vendor", label: "Signed By Vendor" },
    { key: 6, value: "Cancelled", label: "Cancelled" },
    { key: 7, value: "Revision", label: "Revision" },
  ]);

  const handleChangeStatus = (e) => {
    const statusValue = {
      purchase_order_status: e.target.innerHTML,
    };
    UpdateOrderStatus(pdi, statusValue)
      .then((res) => {
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setStatus(res.data.data.purchase_order_status);
      })
      .catch(() => {});
  };

  return (
    <div>
      <p className="d-flex float-end align-content-center align-items-center">
        <div className="dropdown">
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title={activeStatus ? "ACTIVATED" : "DE-ACTIVATED"}
            className={
              activeStatus ? "cardactive float-end" : "notactive float-end"
            }
          ></span>
          <span className="dropdown-content">
            <>
              {orderStatus.map((item) => {
                return (
                  <a
                    value={item.value}
                    onClick={handleChangeStatus}
                    className="m-0 py-1"
                  >
                    {item.label}
                  </a>
                );
              })}
            </>
          </span>
        </div>
      </p>
      <span className={"text-secondary"}>
        {serialLabel}: {serialValue}
        <hr className="hrBg" />
      </span>
      <p className={"h5 fw-bold text-uppercase"}>{purchaseOrderValue}</p>
      <Tooltip title="View Budget Items">
        <div
          // to={{
          //   pathname: `/invoice/${uid}`,
          //   state: {
          //     pState: pState,
          //     proId: proId,
          //     budget_id: budget_id,
          //     rfqId: rfqId,
          //   },
          // }}
          onClick={viewPoData}
        >
          <table>
            <tbody>
              <TableRow label={"PO Number"} value={poNo} />
              <TableRow label={statusLabel} value={statusValue} />
              <TableRow label={vendorLabel} value={vendorValue} />
              <TableRow label={"Date"} value={createdOn.slice(0, 10)} />
            </tbody>
          </table>
          <hr className="hrBg" />
        </div>
      </Tooltip>

      <span className="d-flex justify-content-between align-items-center">
        <span className="text-secondary">{actionLabel}</span>
        <span className="d-flex justify-content-between align-items-center">
          <Tooltip title="Invoice">
            <Link
              to={{
                pathname: `/invoice/${uid}`,
                state: {
                  pState: pState,
                  proId: proId,
                  budget_id: budget_id,
                  rfqId: rfqId,
                },
              }}
            >
              <IconButton>
                <InventoryOutlined
                  checked={activeStatus}
                  sx={{ color: colors.primary }}
                />
              </IconButton>
            </Link>
          </Tooltip>
          <IconButton>
            <Tooltip title="View">
              <RemoveRedEyeIcon
                checked={activeStatus}
                onClick={viewData}
                sx={{ color: colors.primary }}
              />
            </Tooltip>
          </IconButton>
          <IconButton>
            <Tooltip title="Upload">
              <FileUploadIcon
                style={{
                  fontSize: "23px",
                  margin: "4px 5px ",
                  cursor: "pointer",
                  color: colors.primary,
                }}
                onClick={() => {
                  handlecsvUploadItem("");
                }}
              />
            </Tooltip>
          </IconButton>
          <IconButton>
            <Tooltip title="Revise">
              <PreviewIcon
                checked={activeStatus}
                onClick={reviseData}
                sx={{ color: colors.primary }}
              />
            </Tooltip>
          </IconButton>
          {isUpdate && (
            <IconButton onClick={iconButtonOnClick}>
              <Tooltip title="Edit">
                <EditIcon className={"iconSwitch"} />
              </Tooltip>
            </IconButton>
          )}
          {isStatus && (
            <IconButton>
              <Tooltip title="Delete">
                <DeleteIcon
                  checked={activeStatus}
                  onClick={switchButtonOnClick}
                  sx={{ color: colors.danger }}
                />
              </Tooltip>
            </IconButton>
          )}
          <IconButton>
            <Tooltip title="Download">
              <DownloadIcon
                style={{
                  cursor: "pointer",
                  color: colors.primary,
                }}
                onClick={handleDownload}
              />
            </Tooltip>
          </IconButton>
        </span>
      </span>
    </div>
  );
};

export default SearchPurchaseOrderCard;
