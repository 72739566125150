/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const BudgetForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [description, setDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [scopeCode, setScopeCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    success: false,
  });

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeCode = (value) => {
    setMessage(null);
    setScopeCode(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: name.value,
      description: description.value,
      code: scopeCode.value,
    };
    handleChangeFormData(formData);
  };

  const CreateTitle = Strings["Budget.CreateTitle"],
    EditTitle = Strings["Budget.EditTitle"],
    CodeLabel = Strings["Budget.CodeLabel"],
    CodePlaceholder = Strings["Budget.CodePlaceholder"],
    CodeCustomError = Strings["Budget.CodeCustomError"],
    NameLabel = Strings["Budget.NameLabel"],
    NamePlaceholder = Strings["Budget.NamePlaceholder"],
    NameCustomError = Strings["Budget.NameCustomError"],
    DescriptionLabel = Strings["Budget.DescriptionLabel"],
    DescriptionPlaceholder = Strings["Budget.DescriptionPlaceholder"],
    DescriptionCustomError = Strings["Budget.DescriptionCustomError"],
    SaveBtn = Strings["Budget.SaveBtn"],
    UpdateBtn = Strings["Budget.UpdateBtn"],
    CancelBtn = Strings["Budget.CancelBtn"];

  let isValueChanged =
    name.value === initialValue.name &&
    // description.value === initialValue.description &&
    scopeCode.value === initialValue.scopeCode;
  let disable =
    name.error ||
    // description.error ||
    scopeCode.error ||
    name.value === "" ||
    // description.value === "" ||
    scopeCode.value === "" ||
    (name.success === false &&
      // description.success === false &&
      scopeCode.success === false) ||
    isValueChanged;

  // edit
  let isValueChangededit =
    name.value === initialValue.name &&
    description.value === initialValue.description &&
    scopeCode.value === initialValue.scopeCode;
  let Editdisable =
    name.error ||
    description.error ||
    scopeCode.error ||
    name.value === "" ||
    description.value === "" ||
    scopeCode.value === "" ||
    (name.success === false &&
      description.success === false &&
      scopeCode.success === false) ||
    isValueChangededit;
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? CreateTitle : EditTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        label={NameLabel}
        starProps={true}
        placeholder={NamePlaceholder}
        Value={name.value}
        onChangeText={handleChangeName}
        Validate={true}
        CustomErrorLine={NameCustomError}
        editable
        maxLength={30}
      />
      <FormTextInput
        type="text"
        label={DescriptionLabel}
        placeholder={DescriptionPlaceholder}
        Value={description.value}
        onChangeText={handleChangeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        CustomErrorLine={DescriptionCustomError}
        multiline={true}
        editable
        maxLength={30}
      />
      <FormTextInput
        type="textarea"
        starProps={true}
        label={CodeLabel}
        placeholder={CodePlaceholder}
        Value={scopeCode.value}
        onChangeText={handleChangeCode}
        Validate={true}
        CustomErrorLine={CodeCustomError}
        editable
        maxLength={20}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? SaveBtn : UpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : null}
          onClick={handleSaveData}
        />
        <Button
          buttonName={CancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default BudgetForm;
