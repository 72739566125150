/* eslint-disable no-unused-vars */
import React from "react";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  SearchMappingTable,
} from "../../../components";
import {
  DeleteVendorCompanytoCategoryMapping,
  VendorCompanytoCategoryMapping,
} from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";

const myData = [
  {
    id: 1,
    name: "Company1",
    subCategory: [
      { key: 1, subCategoryName: "Category 1", checked: true },
      { key: 2, subCategoryName: "Category 1", checked: true },
    ],
    activeStatus: true,
  },
  {
    id: 2,
    name: "Company2",
    subCategory: [
      { key: 1, subCategoryName: "Category 2", checked: true },
      { key: 2, subCategoryName: "Category 2", checked: true },
    ],
    activeStatus: false,
  },
  {
    id: 3,
    name: "Company3",
    subCategory: [
      { key: 1, subCategoryName: "Category 3", checked: true },
      { key: 2, subCategoryName: "Category 3", checked: true },
    ],
    activeStatus: false,
  },
  {
    id: 4,
    name: "Company4",
    subCategory: [
      { key: 1, subCategoryName: "Category 4", checked: true },
      { key: 2, subCategoryName: "Category 4", checked: true },
    ],
    activeStatus: false,
  },
];

const converter = (mydata) => {
  let result = "";
  mydata.forEach((data) => {
    if (result.length === 0) {
      result = result + data.subCategoryName;
    } else {
      result = result + "," + data.subCategoryName;
    }
  });
  return result;
};

const SearchVendorCompanytoCategoryMapping = () => {
  const searchSubCategoryTitle =
      Strings["VendorCompanytoCategoryMapping.Dashboard"],
    alertMessage = Strings["VendorCompanytoCategoryMapping.AlertMessage"],
    alertTitle = Strings["VendorCompanytoCategoryMapping.AlertTitle"],
    company = Strings["VendorCompanytoCategoryMapping.TableHead.company"],
    category = Strings["VendorCompanytoCategoryMapping.TableHead.category"],
    activeStatus =
      Strings["VendorCompanytoCategoryMapping.TableHead.activeStatus"],
    action = Strings["VendorCompanytoCategoryMapping.TableHead.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentCatMapping, setCurrentCatMapping] = React.useState({});
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState(myData);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["VendorCompanytoCategoryMapping"],
      path: "searchvendorcompanytocategorymapping",
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const getData = (myData) => {
    const result = myData.map((data) => {
      return {
        ...data,
        subCategory: converter(data.subCategory),
      };
    });
    return result;
  };

  const searchTableData = getData(rows);

  const mapableData = [
    {
      dataKey: "name",
      title: company,
    },
    {
      dataKey: "subCategory",
      title: category,
    },
    {
      dataKey: "activeStatus",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangeActiveStatus = (id) => {
    setStatusChangeId(id);
    setAlertBox(true);
  };
  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    handleSelectBtn("edit");
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    const result = rows.map((rowData) => {
      if (rowData.id === statusChangeId) {
        return { ...rowData, activeStatus: !rowData.activeStatus };
      } else {
        return { ...rowData };
      }
    });
    setRows(result);
    setAlertBox(false);
  };

  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <VendorCompanytoCategoryMapping
          handleResultData={() => {}}
          actionType="add"
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <VendorCompanytoCategoryMapping
          handleResultData={() => {}}
          handleModalClose={handleModalClose}
          initialValue={currentType}
          actionType="edit"
        />
      );
    } else if (clickedBtn === "view") {
      return (
        <DeleteVendorCompanytoCategoryMapping
          deleteChangeFormData={() => {}}
          initialValue={currentCatMapping}
          actionType={"view"}
        />
      );
    }
  };

  return (
    <div className="container">
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <BreadCrumbs pathList={paths} />
      <div className="row mt-3">
        <div className="d-md-flex d-block justify-content-between text-center mb-4">
          <h2> {searchSubCategoryTitle}</h2>
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}

          <IconButton
            onClick={() => {
              openAddModal();
              handleSelectBtn("add");
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
        <SearchMappingTable
          data={searchTableData}
          mapableData={mapableData}
          searchProp={["name"]}
          handleChangeActiveStatus={handleChangeActiveStatus}
          handleChangeEdit={handleChangeEdit}
        />
      </div>
    </div>
  );
};

export default SearchVendorCompanytoCategoryMapping;
