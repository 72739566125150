/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const PaymentTermsForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["PaymentTerm.CreateForm"],
    editTitle = Strings["PaymentTerm.EditForm"],
    PaymentTermsLabel = Strings["PaymentTerm.PaymentTermsNameLabel"],
    PaymentTermsPlaceholder =
      Strings["PaymentTerm.PaymentTermsNamePlaceHolder"],
    PaymentTermsCustomError = Strings["PaymentTerm.PaymentTermsNameError"],
    PaymentTermsDescriptionLabel =
      Strings["PaymentTerm.PaymentTermsDescriptionLabel"],
    PaymentTermsDescriptionPlaceholder =
      Strings["PaymentTerm.PaymentTermsDescriptionPlaceHolder"],
    PaymentTermsDescriptionCustomError =
      Strings["PaymentTerm.PaymentTermsDescriptionError"],
    PaymentTermsSaveBtn = Strings["PaymentTerm.SaveBtn"],
    PaymentTermsUpdateBtn = Strings["PaymentTerm.UpdateBtn"],
    PaymentTermsCancelBtn = Strings["PaymentTerm.CancelBtn"];

  const [paymentTermsName, setPaymentTermName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [paymentDescrition, setPaymentDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleChangePaymentTermName = (value) => {
    setMessage(null);
    setPaymentTermName(value);
  };

  const handleChangePaymentTermDescription = (value) => {
    setPaymentDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: paymentTermsName.value,
      description: paymentDescrition.value,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged = paymentTermsName.value === initialValue.name;
  // paymentDescrition.value === initialValue.description;
  let disable =
    paymentTermsName.error ||
    // paymentDescrition.error ||
    paymentTermsName.value === "" ||
    // paymentDescrition.value === "" ||
    paymentTermsName.success === false ||
    isValueChanged;

  //edit
  let isValueChangededit =
    paymentTermsName.value === initialValue.name &&
    paymentDescrition.value === initialValue.description;
  let Editdisable =
    paymentTermsName.error ||
    paymentDescrition.error ||
    paymentTermsName.value === "" ||
    paymentDescrition.value === "" ||
    (paymentTermsName.success === false &&
      paymentDescrition.success === false) ||
    isValueChangededit;
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="alpha"
        label={PaymentTermsLabel}
        placeholder={PaymentTermsPlaceholder}
        Value={paymentTermsName.value}
        onChangeText={handleChangePaymentTermName}
        Validate={true}
        CustomErrorLine={PaymentTermsCustomError}
        starProps={true}
        editable
      />

      <FormTextInput
        type="text"
        label={PaymentTermsDescriptionLabel}
        placeholder={PaymentTermsDescriptionPlaceholder}
        Value={paymentDescrition.value}
        onChangeText={handleChangePaymentTermDescription}
        Validate={false}
        CustomErrorLine={PaymentTermsDescriptionCustomError}
        multiline={true}
        starProps={false}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? PaymentTermsSaveBtn : PaymentTermsUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={PaymentTermsCancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default PaymentTermsForm;
