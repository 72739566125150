/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ScheduleStepsForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [description, setDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: name.value,
      description: description.value,
    };
    handleChangeFormData(formData);
  };

  const CreateTitle = Strings["BudgetMaster.ScheduleStep.CreateTitle"],
    EditTitle = Strings["BudgetMaster.ScheduleStep.EditTitle"],
    NameLabel = Strings["BudgetMaster.ScheduleStep.NameLabel"],
    NamePlaceholder = Strings["BudgetMaster.ScheduleStep.NamePlaceholder"],
    NameCustomError = Strings["BudgetMaster.ScheduleStep.NameCustomError"],
    DescriptionLabel = Strings["BudgetMaster.ScheduleStep.DescriptionLabel"],
    DescriptionPlaceholder =
      Strings["BudgetMaster.ScheduleStep.DescriptionPlaceholder"],
    DescriptionCustomError =
      Strings["BudgetMaster.ScheduleStep.DescriptionCustomError"],
    SaveBtn = Strings["BudgetMaster.ScheduleStep.SaveBtn"],
    UpdateBtn = Strings["BudgetMaster.ScheduleStep.UpdateBtn"],
    CancelBtn = Strings["BudgetMaster.ScheduleStep.CancelBtn"];

  let isValueChanged = name.value === initialValue.name;
  // description.value === initialValue.description;
  let disable =
    name.error ||
    // description.error ||
    name.value === "" ||
    // description.value === "" ||
    name.success === false ||
    isValueChanged;

  //Edit
  let isValueChangedEdit =
    name.value === initialValue.name &&
    description.value === initialValue.description;
  let Editdisable =
    name.error ||
    description.error ||
    name.value === "" ||
    description.value === "" ||
    (name.success === false && description.success === false) ||
    isValueChangedEdit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? CreateTitle : EditTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="alpha"
        label={NameLabel}
        starProps={true}
        placeholder={NamePlaceholder}
        Value={name.value}
        onChangeText={handleChangeName}
        Validate={true}
        CustomErrorLine={NameCustomError}
        editable
        maxLength={20}
      />
      <FormTextInput
        type="text"
        label={DescriptionLabel}
        placeholder={DescriptionPlaceholder}
        Value={description.value}
        onChangeText={handleChangeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        CustomErrorLine={DescriptionCustomError}
        multiline={true}
        editable
        maxLength={30}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? SaveBtn : UpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={CancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default ScheduleStepsForm;
