/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateProjectSpecificUser,
  DeleteProjectSpecificUser,
  GetProjectSpecificUser,
  UpdateProjectSpecificUser,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  IconButton,
  UserModal,
  NodataFound,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { ProjectSpecificUserForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import Strings from "../../../translations";
import { isDecoded } from "../../../utils/crypto";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "userrole",
    numeric: false,
    disablePadding: false,
    label: "User Role",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SearchProjectSpecificUser = (props) => {
  const ProjectUserTitle = Strings["ProjectSpecificUser.ProjectUserTitle"],
    alertMessage = Strings["ProjectSpecificUser.deleteProjectUserTitle"],
    alertTitle = Strings["ProjectSpecificUser.AlertTitle"];

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [setStatusChangeId] = React.useState(null);

  const projectId = useParams();
  const fetchId = isDecoded(projectId.user);
  const prod_id = parseInt(fetchId);

  const openAddModal = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };

  const getPrjctSpecificUser = () => {
    setLoading(true);
    let id = prod_id;
    GetProjectSpecificUser(id)
      .then((res) => {
        setLoading(false);
        const result = res.data.data;
        setRows(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getPrjctSpecificUser();
  }, []);

  const createPjctUser = (formData) => {
    let id = prod_id;
    const data = {
      project_id: id,
      user_id: formData.user_id,
      role_id: formData.role_id,
    };
    setLoading(true);
    CreateProjectSpecificUser(data)
      .then((res) => {
        if (res.status === 200) {
          getPrjctSpecificUser();
          setLoading(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const updatePrjctUser = (formData) => {
    setLoading(true);
    let id = prod_id;
    const data = {
      project_id: id,
      user_id: formData.user_id,
      role_id: formData.role_id,
    };
    let userId = currentType.id;

    UpdateProjectSpecificUser(userId, data)
      .then((res) => {
        getPrjctSpecificUser();
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setLoading(false);
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const selectModal = () => {
    return (
      <ProjectSpecificUserForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeData={
          clickedBtn === "add" ? createPjctUser : updatePrjctUser
        }
        actionType={clickedBtn}
        initialValue={clickedBtn === "add" ? {} : currentType}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangeActiveStatus = (id) => {
    setStatusChangeId(id);
    setAlertBox(true);
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setLoading(true);
    let uid = currentType.id;
    DeleteProjectSpecificUser(uid)
      .then((res) => {
        getPrjctSpecificUser();
        setLoading(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setAlertBox(false);
      })
      .catch(() => {
        setLoading(false);
        setAlertBox(false);
      });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <div className="container mt-5 mb-5">
        {loading ? <LoaderComponent /> : null}
        <UserModal
          onHandleClose={handleModalClose}
          isOpen={isOpen}
          contentStyle={{
            width: "50%",
            height: "50%",
          }}
        >
          {selectModal()}
        </UserModal>
        <div
          className="row mt-3"
          style={{
            alignItems: "center",
          }}
        >
          <div className="d-md-flex d-block justify-content-between text-center mb-4">
            <h3> {ProjectUserTitle} </h3>
            {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            <span className="addButton">
              <IconButton
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              >
                <AddIcon />
              </IconButton>
            </span>
          </div>
          {rows.length > 0 ? (
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 900 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                      align={"center"}
                    />
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.user.name);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, row.user.name)
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.user.name}
                              selected={isItemSelected}
                              align={"center"}
                            >
                              <TableCell padding="checkbox"></TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {row.user.name}
                              </TableCell>
                              <TableCell align="center">
                                {row.role.name}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    openAddModal();
                                    setCurrentType(row);
                                    handleSelectBtn("edit");
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <EditIcon />
                                  </Tooltip>
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    setAlertBox(true);
                                    setCurrentType(row);
                                    handleSelectBtn("dlt");
                                  }}
                                >
                                  <Tooltip title="Delete">
                                    <DeleteIcon
                                      sx={{
                                        color: "red",
                                      }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Paper>
            </Box>
          ) : (
            <>
              {loading ? (
                <LoaderComponent />
              ) : (
                <NodataFound
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchProjectSpecificUser;
