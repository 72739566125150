/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const PaymentProgressForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["PaymentProgress.CreateForm"],
    editTitle = Strings["PaymentProgress.EditForm"],
    deleteTitle = Strings["PaymentProgress.DeleteForm"],
    PaymentProgressLabel = Strings["PaymentProgress.PaymentProgressNameLabel"],
    PaymentProgressPlaceholder =
      Strings["PaymentProgress.PaymentProgressNamePlaceHolder"],
    PaymentProgressCustomError =
      Strings["PaymentProgress.PaymentProgressNameError"],
    PaymentProgressDescriptionLabel =
      Strings["PaymentProgress.PaymentProgressDescriptionLabel"],
    PaymentProgressDescriptionPlaceholder =
      Strings["PaymentProgress.PaymentProgressDescriptionPlaceHolder"],
    PaymentProgressDescriptionCustomError =
      Strings["PaymentProgress.PaymentProgressDescriptionError"],
    PaymentProgressSaveBtn = Strings["PaymentProgress.SaveBtn"],
    PaymentProgressUpdateBtn = Strings["PaymentProgress.UpdateBtn"],
    PaymentProgressCancelBtn = Strings["PaymentProgress.CancelBtn"];

  const [paymentProgressName, setPaymentProgressName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [paymentDescrition, setPaymentDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleChangePaymentProgressName = (value) => {
    setMessage(null);
    setPaymentProgressName(value);
  };

  const handleChangePaymentProgressDescription = (value) => {
    setPaymentDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: paymentProgressName.value,
      description: paymentDescrition.value,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged = paymentProgressName.value === initialValue.name;
  // paymentDescrition.value === initialValue.description;

  let disable =
    paymentProgressName.error ||
    // paymentDescrition.error ||
    paymentProgressName.value === "" ||
    // paymentDescrition.value === "" ||
    paymentProgressName.success === false ||
    isValueChanged;

  //edit
  let isValueChangededit =
    paymentProgressName.value === initialValue.name &&
    paymentDescrition.value === initialValue.description;

  let Editdisable =
    paymentProgressName.error ||
    paymentDescrition.error ||
    paymentProgressName.value === "" ||
    paymentDescrition.value === "" ||
    (paymentProgressName.success === false &&
      paymentDescrition.success === false) ||
    isValueChangededit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add"
          ? createTitle
          : actionType === "edit"
          ? editTitle
          : deleteTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        label={PaymentProgressLabel}
        placeholder={PaymentProgressPlaceholder}
        Value={paymentProgressName.value}
        onChangeText={handleChangePaymentProgressName}
        Validate={true}
        CustomErrorLine={PaymentProgressCustomError}
        starProps={true}
        editable
      />

      <FormTextInput
        type="textarea"
        label={PaymentProgressDescriptionLabel}
        placeholder={PaymentProgressDescriptionPlaceholder}
        Value={paymentDescrition.value}
        onChangeText={handleChangePaymentProgressDescription}
        Validate={false}
        CustomErrorLine={PaymentProgressDescriptionCustomError}
        multiline={true}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add"
              ? PaymentProgressSaveBtn
              : PaymentProgressUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={PaymentProgressCancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default PaymentProgressForm;
