/* eslint-disable no-unused-vars */
import { Forward } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  ChangeActiveStatusProjectBudget,
  CreateProjectBudget,
  GetProjectBudget,
  UpdateProjectBudget,
  getBudgetCode,
  GetAllSearchBudget,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  Chip,
  IconButton,
  Modal,
  NodataFound,
  SwitchButton,
  FormTextInput,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { CreateProjectBudgetForm } from "../../../forms";
import strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_BUDGET_STATUS_FUNCTION,
  CREATE_BUDGET,
  UPDATE_BUDGET,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import SearchProjectSpecificUser from "../SearchProjectSpecificUser/SearchProjectSpecificUser";
import "./SearchProjectBudget.css";

const SearchProjectBudgetPage = (props) => {
  const [data, setData] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [updateid, setUpdateId] = React.useState(null);
  const [errorMessage, setMessage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [totalData, setTotalData] = React.useState(null);
  const [budgetNo, setBudgetSerialNo] = React.useState("");
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const Currentlocation = useLocation();
  const { currentTab } = Currentlocation.state || {};
  const [searchTerm, setSearchTerm] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_BUDGET)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_BUDGET)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_BUDGET_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const location = useLocation();
  const projectId = useParams();
  // const currentTab = useParams();
  const fetchId = isDecoded(projectId.user);
  const paramId = parseInt(fetchId);

  const proId = location.state
    ? location.state.projectId.id
    : location.query
    ? JSON.parse(location.query.params).proId
    : paramId;
  let budgetSerialNo = "budget";
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const handleChangeActiveStatus = (id) => {
    const result = data.find((elem) => {
      if (elem.id === id) {
        return elem;
      }
    });
    setcurrentStatus(result.is_active);
    setStatusChangeId(id);
    setAlertBox(true);
  };

  const handleChangeSuccess = () => {
    setisLoader(true);
    ChangeActiveStatusProjectBudget(statusChangeId, {
      status: 1 - currentStatus,
    })
      .then((res) => {
        setisLoader(false);
        const result = data.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              is_active: res.data.data.is_active,
            };
          } else {
            return ele;
          }
        });
        setData(result);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const paths = [
    // {
    //   name: strings["Projects"],
    //   path:  "project",
    // },
    {
      name: strings["Projects"],
      path:
        currentTab === "allProjects"
          ? "allProjects"
          : currentTab === "ongoingProject"
          ? "ongoingproject"
          : currentTab === "completedProjects"
          ? "completedproject"
          : currentTab === "canceledProjects"
          ? "cancelledproject"
          : currentTab === "draftProjects"
          ? "draftproject"
          : "project",
    },
    {
      name: strings["Budgets"],
      path: "projectbudget",
    },
  ];

  React.useEffect(() => {
    getBudgetCode(budgetSerialNo)
      .then((res) => {
        setBudgetSerialNo(res.data.data);
      })
      .catch(() => {});
  }, []);

  const getAlldata = () => {
    setisLoader(true);
    console.log("proId", proId);
    GetProjectBudget(proId, rowsPerPage, page)
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setData(data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAlldata();
  }, [page, rowsPerPage]);

  const createProjectBudget = (budgetData) => {
    setisLoader(true);
    CreateProjectBudget(budgetData)
      .then((res) => {
        getAlldata();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setisLoader(false);
        getAlldata();
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateProjectBudget = (budgetData) => {
    setisLoader(true);
    UpdateProjectBudget(budgetData, updateid)
      .then((res) => {
        getAlldata();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <CreateProjectBudgetForm
        budgetNo={budgetNo}
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleBudgetData={
          clickedBtn === "add" ? createProjectBudget : updateProjectBudget
        }
        actionType={clickedBtn}
        proId={proId}
        initialValue={clickedBtn !== "add" ? currentUserType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const SearchProjectBudgetPage =
      strings["ProjectBudget.SearchProjectBudgetPage"],
    furnishTitle = strings["Title.Furnish"],
    budgetTitle = strings["Title.Budget"],
    serialLabel = strings["ProjectBudget.serialLabel"],
    currencyLabel = strings["ProjectBudget.currencyLabel"],
    projectLabel = strings["ProjectBudget.projectLabel"],
    startDateLabel = strings["ProjectBudget.startDateLabel"],
    endDateLabel = strings["ProjectBudget.endDateLabel"],
    actionLabel = strings["ProjectBudget.actionLabel"],
    alertTitle = strings["ProjectBudget.alertTitle"],
    alertMessage = strings["ProjectBudget.alertMessage"];

  console.log({ searchTerm });

  // const handleSearch = (searchData) => {
  //   const data = searchData.value;
  //   setSearchTerm(searchData);
  //   setisLoader(true);
  //   GetAllSearchBudget(data)
  //     .then((res) => {
  //       setisLoader(false);
  //       setData(res.data.data);
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //     });
  // };

  // const handleSearchSubmit = (e) => {
  //   e.preventDefault();
  //   getAlldata();
  // };

  const handleSearch = (searchData) => {
    const data = searchData.value.trim();
    setSearchTerm(searchData);
    if (data === "") {
      getAlldata();
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    const data = searchTerm.value.trim();

    if (data) {
      setisLoader(true);
      GetAllSearchBudget(data)
        .then((res) => {
          setisLoader(false);
          setData(res.data.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      getAlldata();
    }
  };

  return (
    <div className="content-wrapper p-0">
      <Helmet>
        <title>
          {furnishTitle} | {budgetTitle}
        </title>
      </Helmet>
      <div
        className="row mt-4"
        style={{
          alignItems: "center",
        }}
      >
        <div className="col">
          <h3>{SearchProjectBudgetPage}</h3>
          <BreadCrumbs pathList={paths} />
        </div>
        {isAdd && (
          <div className="col-auto">
            <span className="addButton">
              <IconButton
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              >
                <AddIcon />
              </IconButton>
            </span>
          </div>
        )}
      </div>

      {/* Search input for PO Number */}
      <form onSubmit={handleSearchSubmit}>
        <FormTextInput
          type="text"
          className="searchBarUser mb-3"
          placeholder={`Search `}
          Value={searchTerm.value}
          onChangeText={handleSearch}
          inputStyle={{ border: "none" }}
          editable
        />
      </form>

      {isLoader ? (
        <LoaderComponent />
      ) : (
        <>
          <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
            {selectModal()}
          </Modal>
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <Card className="row mt-5">
            {data.length !== 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Serial Number
                      </TableCell> */}
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Budget Name
                      </TableCell>
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Budget Scope
                      </TableCell>
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Currency
                      </TableCell>
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Value
                      </TableCell>
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Starts From
                      </TableCell>
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Ends At
                      </TableCell>
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Status
                      </TableCell>
                      <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((budgetData, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{budgetData.serial_number}</TableCell> */}
                        <TableCell>
                          <Link
                            to={{
                              pathname: `/budgetitem/${isEncoded(
                                budgetData.id
                              )}`,
                              state: {
                                proId: proId,
                                budgetId: isEncoded(budgetData.id),
                                budget_name: budgetData.name,
                                budgetScope: budgetData.budgetscope,
                              },
                            }}
                          >
                            {budgetData.name}
                          </Link>
                        </TableCell>
                        <TableCell>{budgetData.budgetscope.name}</TableCell>
                        <TableCell>
                          {budgetData.currency?.name
                            ? budgetData.currency?.name
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {budgetData.value === null
                            ? "N/A"
                            : budgetData.value?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </TableCell>
                        <TableCell>
                          {budgetData.budget_start_date.split(" ")[0]}
                        </TableCell>
                        <TableCell>
                          {budgetData.budget_end_date.split(" ")[0]}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={
                              budgetData.is_active ? "ACTIVE" : "IN-ACTIVE"
                            }
                            type={budgetData.is_active === 1 ? "active" : ""}
                          />
                        </TableCell>
                        <TableCell>
                          {isAuthorizedFunc(UPDATE_BUDGET) && (
                            <IconButton
                              onClick={() => {
                                openAddModal();
                                handleSelectBtn("edit");
                                setCurrentUserType(budgetData);
                                setUpdateId(budgetData.id);
                              }}
                            >
                              <Tooltip title="Edit">
                                <EditIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                          {user.loginType != "client" && (
                            <SwitchButton
                              checked={budgetData.is_active}
                              onClick={() =>
                                handleChangeActiveStatus(budgetData.id)
                              }
                            />
                          )}

                          <Link
                            to={{
                              pathname: `/budgetitem/${isEncoded(
                                budgetData.id
                              )}`,
                              state: {
                                proId: proId,
                                budgetId: isEncoded(budgetData.id),
                                budget_name: budgetData.name,
                                budgetScope: budgetData.budgetscope,
                              },
                            }}
                          >
                            <IconButton
                            // onClick={() =>
                            //   handleChangeStatus(
                            //     projectData.project_status,
                            //     projectData.id
                            //   )
                            // }
                            >
                              <Forward />
                            </IconButton>
                          </Link>
                        </TableCell>

                        {/* {columns.map((column) => (
                          <TableCell key={column.key}>
                            {column.render
                              ? column.render(budgetData)
                              : budgetData[column.key]}
                          </TableCell>
                        ))} */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NodataFound
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              />
            )}
          </Card>
          <div className="d-flex justify-content-end align-items-end mt-3">
            <Stack spacing={3}>
              <Pagination
                page={page + 1}
                count={Math.ceil(totalData / 6)}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </div>
        </>
      )}
      <hr />
      {/* <div className="row">
        <div className="col-12 mx-auto">
          <SearchProjectSpecificUser />
        </div>
      </div> */}
    </div>
  );
};

export default SearchProjectBudgetPage;
