/* eslint-disable no-unused-vars */
import React from "react";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  RFITable,
} from "../../../components";
import { RequestforInformationPageForm } from "../../../forms/ProjectAndBudget";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";

const SearchRequestforInformationPage = (props) => {
  const FFITitle = Strings["RequestforInformation.RFITitle"],
    alertMessage = Strings["RequestforInformation.AlertMessage"],
    alertTitle = Strings["RequestforInformation.AlertTitle"],
    rfiname = Strings["RequestforInformation.tableHead.tableName"],
    description = Strings["RequestforInformation.tableHead.description"],
    document = Strings["RequestforInformation.tableHead.activeStatus"],
    action = Strings["RequestforInformation.tableHead.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);

  const [rseArray, setRseArray] = React.useState([
    {
      id: 1,
      name: "furnish",
      description: "Description 1",
      selectedFile: "logo1.png",
    },
    {
      id: 2,
      name: "invincix",
      description: "Description 2",
      selectedFile: "logo2.png",
    },
    {
      id: 3,
      name: "cavemendev",
      description: "Description 3",
      selectedFile: "logo3.png",
    },
    {
      id: 4,
      name: "indiet",
      description: "Description 4",
      selectedFile: "logo4.png",
    },
  ]);
  const paths = [
    {
      name: strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["BudgetItem"],
      path: "budgetitem",
    },
    {
      name: strings["RequestForInformation"],
      path: "/requestforinformation",
    },
  ];
  const mapableData = [
    {
      dataKey: "name",
      title: rfiname,
    },
    {
      dataKey: "description",
      title: description,
    },
    {
      dataKey: "selectedFile",
      title: document,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const selectModal = () => {
    if (clickedBtn === "addrfi") {
      return (
        <RequestforInformationPageForm
          handleChangeData={(val) => {}}
          actionType={clickedBtn}
          initialValue={clickedBtn === "addrfi" ? {} : currentType}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <RequestforInformationPageForm
          handleChangeData={(val) => {}}
          actionType={clickedBtn}
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    }
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setAlertBox(false);
  };
  const handleChangeEdit = (id) => {
    const result = rseArray.find((res) => {
      return res.id === id;
    });
    setCurrentType(result);
    openAddModal();
    handleSelectBtn("edit");
  };
  const handleChangeDelete = (data) => {
    setAlertBox(!alertBox);
  };
  return (
    <>
      <div className="container">
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {" "}
          {selectModal()}{" "}
        </Modal>
        <BreadCrumbs pathList={paths} />
        <div className="row mt-3">
          <div className="d-md-flex d-block justify-content-between text-center mb-4">
            <h2> {FFITitle} </h2>
            {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}

            <IconButton
              onClick={() => {
                openAddModal();
                handleSelectBtn("addrfi");
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
          <RFITable
            data={rseArray}
            mapableData={mapableData}
            searchProp={["name"]}
            handleChangeDelete={handleChangeDelete}
            handleChangeEdit={handleChangeEdit}
          />
        </div>
      </div>
    </>
  );
};

export default SearchRequestforInformationPage;
