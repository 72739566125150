import React from "react";
import { NavRouteUnits, RootRoutes } from "./navigation";

export default function App(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  return user === null ? (
    <RootRoutes />
  ) : (
    <>
      <NavRouteUnits />
    </>
  );
}
