import React from "react";
import { Table } from "rsuite";
import { Column, Cell, HeaderCell } from "rsuite-table";
import { Checkbox } from "rsuite";
import { colors } from "../../../../configs";

const CheckCell = ({
  rowData,
  handleChangeCheck,
  checkedKeys,
  actionType,
  dataKey,
  ...props
}) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "42px" }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={() => handleChangeCheck(rowData[dataKey])}
        checked={rowData["checked"]}
        style={{ color: colors.primary }}
      />
    </div>
  </Cell>
);

const RequestForQuotationTable = (props) => {
  const { data, mapableData, actionType, handleChangeCheck } = props;

  return (
    <div>
      <Table
        style={{ borderRadius: 10 }}
        id="table"
        data={data}
        rowHeight={50}
        headerHeight={50}
      >
        <Column width={50} align="center">
          <HeaderCell
            style={{
              color: colors.primary,
              fontWeight: 900,
              fontSize: "larger",
            }}
          ></HeaderCell>
          {actionType === "editRFQ" ? (
            <CheckCell
              dataKey="id"
              actionType={actionType}
              handleChangeCheck={handleChangeCheck}
            />
          ) : actionType === "add" ? (
            <cell dataKey="id" handleChangeCheck={handleChangeCheck} />
          ) : null}
        </Column>
        {mapableData.map((item, index) => {
          return (
            <Column minWidth={130} flexGrow={1} key={index}>
              <HeaderCell
                style={{
                  color: colors.primary,
                  fontWeight: 900,
                  fontSize: "larger",
                }}
              >
                {item.title}
              </HeaderCell>
              <Cell dataKey={item.dataKey} />
            </Column>
          );
        })}
      </Table>
    </div>
  );
};

export default RequestForQuotationTable;
