import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { colors } from "../../configs";
import strings from "../../translations";

const DownloadIconComponent = () => {
  const downloadTooltip = strings["Tooltip.downloadTooltip"]
  return (
    <React.Fragment>
      <Tooltip title={downloadTooltip}>
        <FileDownloadOutlinedIcon
          sx={{
            fontSize: "1.2em",
            color: colors.primary,
            width: "20px",
            height: "20px",
          }}
        />
      </Tooltip>
    </React.Fragment>
  );
};

export default DownloadIconComponent;
