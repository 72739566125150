/* eslint-disable no-unused-vars */
import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import EditIcon from "@mui/icons-material/Edit";
// import DownloadIcon from "@mui/icons-material/Download";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { Chip, FormTextInput, IconButton } from "../../../../components";
import { colors } from "../../../../configs";
import LoaderComponent from "../../../common/Loader";
import NodataFound from "../../NoDataFound/NodataFound";
import strings from "../../../../translations";

const ActionCell = ({
  rowData,
  dataKey,
  handleChangeDelete,
  handleChangeEdit,
  quoted,
  handleVisible,
  handleDownload,
  isUpdate,
  isStatus,
  handleShowReviseData,
  handleShowRfi,
  ...props
}) => {

  const DownloadLabel = strings["Tooltip.downloadTooltip"],
    EditLabel = strings["tooltip.editTooltip"],
    DeletLabel = strings["Tooltip.deletTooltip"],
    Revision = strings["Document.tableHead.Revision"],
    AddQuo = strings["Quo.tableHead.AddQuo"];


  function handleActionEdit() {
    handleChangeEdit(rowData[dataKey]);
  }

  const handleActionDelete = () => {
    handleChangeDelete(rowData[dataKey]);
  };
  const handleViewEditdata = () => {
    handleVisible(rowData[dataKey]);
  };
  const handleViewReData = () => {
    handleShowReviseData(rowData[dataKey]);
  };
  const handleRfiDownloads = () => {
    handleDownload(rowData[dataKey]);
  };
  const handleRfiCreate = () => {
    handleShowRfi(rowData[dataKey]);
  };

  return (
    <Cell {...props} className="link-group">
      <div style={{ margin: -10 }}>
        <IconButton onClick={handleRfiDownloads}>
          <Tooltip title={DownloadLabel}>
            <GetAppIcon sx={{ color: colors.success }} />
          </Tooltip>
        </IconButton>
        {isUpdate && (
          <IconButton onClick={handleActionEdit}>
            <Tooltip title={EditLabel}>
              <EditIcon />
            </Tooltip>
          </IconButton>
        )}
        {isStatus && (
          <IconButton onClick={handleActionDelete}>
            <Tooltip title={DeletLabel}>
              <DeleteIcon sx={{ color: colors.danger }} />
            </Tooltip>
          </IconButton>
        )}
        {props.isview ? (
          <button
            className={"quotationButton me-3"}
            onClick={handleViewEditdata}
          >
            {AddQuo}
          </button>
        ) : null}
        {props.isRfi ? (
          <button
            className={"quotationButton me-3"}
            onClick={handleRfiCreate}
          >
            {"Add Rfi"}
          </button>
        ) : null}
      </div>
    </Cell>
  );
};
const ActionDownload = ({
  rowData,
  dataKey,
  handleChangeDelete,
  handleChangeEdit,
  quoted,
  handleVisible,
  handleDownload,
  ...props
}) => {
  const handleViewDownloaddata = () => {
    handleDownload(rowData[dataKey]);
  };

  return (
    <Cell {...props} className="link-group">
      <div style={{ margin: -10 }}>
        {props.isdownload ? (
          <Tooltip title="Download">
            <DownloadForOfflineIcon
              onClick={handleViewDownloaddata}
              sx={{
                color: colors.success,
                marginLeft: "50px",
                fontSize: "1.8rem",
              }}
            />
          </Tooltip>
        ) : null}
      </div>
    </Cell>
  );
};

const VendorRfqTableDocument = (props) => {
  const {
    data,
    handleChangeDelete,
    handleChangeEdit,
    mapableData,
    searchProp,
    handleVisible,
    handleDownload,
    handleView,
    isUpdate,
    isStatus,
    handleShowReviseData,
    handleShowRfi,
  } = props;

  const serialLabel = strings["Document.tableHead.RFQSerial"],
    searchPlaceholder = strings["Document.RFQ.placeholder"];


  const [allData, setAllData] = React.useState(data);
  const [searchedData, setSearchedData] = React.useState(data);
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  const [tempData] = React.useState(data);

  React.useEffect(() => {
    setAllData(data);
  }, [data]);

  const buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };

  const filterBuilderData = (data, query) => {
    let searchedData = [];
    data.forEach((item) => {
      for (let key in query) {
        if (
          !_.includes(searchedData, item) &&
          item[key].toLowerCase().includes(query[key][0].toLowerCase())
        ) {
          searchedData.push(item);
        }
      }
    });
    return searchedData;
  };

  let filter = {};
  React.useEffect(() => {
    searchProp.forEach((e) => (filter[e] = [searchValue.value]));
    setSearchedData(filterBuilderData(data, buildFilter(filter)));
  }, [searchProp, searchValue]);

  const query = buildFilter(filter);
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (data === "") {
      setAllData(tempData);
    } else {
      setAllData(filterBuilderData(allData, query));
    }
  };

  const filterData = (data) => {
    const result = data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    return result;
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const handleSortColumn = (column, type) => {
    setSortColumn(column);
    setSortType(type);

    const result = _.orderBy(allData, [column], [type]);
    setSearchedData(result);
  };

  const limitOption = (allData) => {
    const res = [];
    const length = allData.length;
    const allDataLength = length + (5 - (length % 5));
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i);
    }
    return res;
  };

  const ActionCells = ({ rowData, dataKey, handleView, ...props }) => {
    const handleViewEditdata = () => {
      handleView(rowData[dataKey]);
    };

    return (
      <Cell {...props} className="link-group">
        <div style={{ marginLeft: "10px" }}>
          <Link title={serialLabel} onClick={() => handleViewEditdata()}>
            {rowData[dataKey]}
          </Link>
        </div>
      </Cell>
    );
  };

  return (
    <div>
      {loading ? <LoaderComponent /> : null}
      <>

        {filterData(searchedData).length > 0 ? (
          <>
            <FormTextInput
              type="text"
              className="searchBarRfq mb-3"
              placeholder={searchPlaceholder}
              Value={searchValue.value}
              onChangeText={handleSearch}
              inputStyle={{ border: "none" }}
            />
            <Table
              style={{ borderRadius: 10 }}
              height={320}
              id="table"
              width={"100%"}
              data={filterData(searchedData)}
              onSortColumn={handleSortColumn}
              sortColumn={sortColumn}
              sortType={sortType}
              rowHeight={50}
              headerHeight={50}
            >
              {mapableData.map((item, index) => {
                return (
                  <Column
                    width={230}
                    flexGrow={1}
                    sortable
                    key={index}
                  >
                    <HeaderCell
                      style={{
                        color: colors.primary,
                        fontWeight: 900,
                        fontSize: "larger",
                        textAlign: "center",
                      }}
                    >
                      {item.title}
                    </HeaderCell>
                    {item.hasOwnProperty("isviewlink") ? (
                      <ActionCells
                        dataKey="serial_no"
                        handleView={handleView}
                      />
                    ) : item.hasOwnProperty("isdownload") ? (
                      <ActionDownload
                        isdownload={item.isdownload}
                        dataKey="id"
                        handleChangeDelete={handleChangeDelete}
                        handleChangeEdit={handleChangeEdit}
                        handleVisible={handleVisible}
                      />
                    ) : item.hasOwnProperty("isAction") ? (
                      <ActionCell
                        isUpdate={isUpdate}
                        isStatus={isStatus}
                        isview={item.isview}
                        isRfi={item.isRfi}
                        dataKey="id"
                        handleChangeDelete={handleChangeDelete}
                        handleChangeEdit={handleChangeEdit}
                        handleVisible={handleVisible}
                        handleShowReviseData={handleShowReviseData}
                        handleDownload={handleDownload}
                        handleShowRfi={handleShowRfi}
                      />
                    ) : (
                      <Cell dataKey={item.dataKey} />
                    )}
                  </Column>
                );
              })}
            </Table>
          </>
        ) : (
          <>
            {loading ? <LoaderComponent /> : <NodataFound norecord={false} />}
          </>
        )}
      </>
    </div>
  );
};

export default VendorRfqTableDocument;
