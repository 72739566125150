import React from 'react'
import { Table, Pagination, } from 'rsuite';
import { Column, Cell, HeaderCell } from 'rsuite-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { colors } from '../../../configs'
import _ from 'lodash';
import { Chip, FormTextInput, IconButton } from '../../../components';

const ActionCell = ({ rowData, dataKey, ...props }) => {

    function handleAction() {
    }

    function handleDeleteAction() {
    }

    return (
        <Cell {...props} className="link-group">
            <div style={{ margin: -10 }}>
                <IconButton onClick={handleAction}><EditIcon /></IconButton>
                <IconButton onClick={handleDeleteAction}><DeleteIcon sx={{ color: colors.danger }} /></IconButton>
            </div>
        </Cell>
    );
};

const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <Chip
                label={rowData[dataKey] ? 'Active' : 'Inactive'}
                type={rowData[dataKey] ? 'active' : 'inactive'}
            />
        </Cell>
    )
}

const fakeData = [
    {
        "id": 1,
        "city": "East Dejuan",
        "email": "Eico_gh_Beer@yahoo.com",
        "firstName": "Margret",
        "lastName": "Heller",
        "companyName": "Corwin, Maggio and Wintheiser",
        "activeStatus": true
    },
    {
        "id": 2,
        "city": "st Dejuan",
        "email": "Eico_Beer@yahoo.com",
        "firstName": "pargret",
        "lastName": "jeller",
        "companyName": "fdforwin, Maggio and Wintheiser",
        "activeStatus": false
    },
    {
        "id": 3,
        "city": "East Dejuan",
        "firstName": "Margret",
        "email": "Eico_hs_Beer@yahoo.com",
        "lastName": "Heller",
        "companyName": "Corwin, Maggio and Wintheiser",
        "activeStatus": true
    },
    {
        "id": 4,
        "city": "shhgt Dejuan",
        "email": "Eico_Beer@yahoo.com",
        "firstName": "pargret",
        "lastName": "jeller",
        "companyName": "fdforwin, Maggio and Wintheiser",
        "activeStatus": true
    },
    {
        "id": 5,
        "city": "shhgt Dejuan",
        "email": "Eico_Beer@yahoo.com",
        "firstName": "pargretaa",
        "lastName": "jellerkkkkkkd",
        "companyName": "fdforwin, Maggio and Wintheiser",
        "activeStatus": true
    },
    {
        "id": 6,
        "city": "shhgt Dejuan",
        "email": "Eico_Beer@yahoo.com",
        "firstName": "pargretaa asssd",
        "lastName": "jellerkkkkkkd sss",
        "companyName": "fdforwin, Maggio and Wintheiser",
        "activeStatus": true
    },
    {
        "id": 7,
        "city": "shhgt Dejuan",
        "email": "Eico_Beer@yahoo.com",
        "firstName": "pargretaa asd",
        "lastName": "jellerkkkkkkd saaw",
        "companyName": "fdforwin, Maggio and Wintheiser",
        "activeStatus": true
    },
]


const Test2 = (props) => {

    const [allData, setAllData] = React.useState(fakeData)
    const [limit, setLimit] = React.useState(5);
    const [page, setPage] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [searchValue, setSearchValue] = React.useState({
        value: "",
        error: false,
        success: false
    })
    const [tempData] = React.useState(fakeData);


    const filterData = (data) => {
        const result = data.filter((v, i) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return i >= start && i < end;
        })
        return result;
    }

    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };

    const handleSortColumn = (column, type) => {

        setSortColumn(column)
        setSortType(type)

        const result = _.orderBy(allData, [column], [type]);
        setAllData(result);

    }

    const handleSearch = (searchData) => {
        const data = searchData.value;
        // const data = e.target.value;
        setSearchValue(searchData);
        const filterData = allData.filter((d) => {
            return d.firstName.toLowerCase().includes(data);
        })
        if (data === '') {
            setAllData(tempData)
        }
        else {
            setAllData(filterData);
        }
    }

    return (
        <div className='container '>
            <div className="row d-flex justify-content-left align-items-center">
                <FormTextInput
                    type="text"
                    className="mb-3"
                    placeholder="Search anything..."
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    style={{ marginTop: '1px' }}
                />
            </div>

            <Table
                height={400}
                width={'100%'}
                id="table"
                data={filterData(allData)}
                onSortColumn={handleSortColumn}
                sortColumn={sortColumn}
                sortType={sortType}
                rowHeight={65}
                headerHeight={60}
            >
                <Column minWidth={130} flexGrow={1} sortable>
                    <HeaderCell
                        style={{ color: colors.primary, fontWeight: 900, fontSize: 'larger' }}>
                        First Name
                    </HeaderCell>
                    <Cell dataKey="firstName" />
                </Column>

                <Column minWidth={130} flexGrow={1} sortable>
                    <HeaderCell
                        style={{ color: colors.primary, fontWeight: 700, fontSize: 'larger' }}>
                        Last Name
                    </HeaderCell>
                    <Cell dataKey="lastName" />
                </Column>
                <Column minWidth={130} flexGrow={1} sortable>
                    <HeaderCell style={{ color: colors.primary, fontWeight: 700, fontSize: 'larger' }}>
                        City
                    </HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column minWidth={130} flexGrow={1} sortable>
                    <HeaderCell style={{ color: colors.primary, fontWeight: 700, fontSize: 'larger' }}>
                        Company Name
                    </HeaderCell>
                    <Cell dataKey="companyName" flexGrow={1} />
                </Column>
                <Column minWidth={130} flexGrow={1} sortable>
                    <HeaderCell style={{ color: colors.primary, fontWeight: 700, fontSize: 'larger' }}>
                        Email
                    </HeaderCell>
                    <Cell dataKey="email" />
                </Column>
                <Column minWidth={130} flexGrow={1} sortable>
                    <HeaderCell style={{ color: colors.primary, fontWeight: 700, fontSize: 'larger' }}>
                        Active Status
                    </HeaderCell>
                    <StatusCell dataKey="activeStatus" />
                </Column>
                <Column minWidth={130} flexGrow={1}>
                    <HeaderCell style={{ color: colors.primary, fontWeight: 700, fontSize: 'larger' }}>
                        Action
                    </HeaderCell>
                    <ActionCell dataKey="id" />
                </Column>
            </Table>
            <div style={{ width: '100%' }}>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                    total={fakeData.length}
                    limitOptions={[5, 10, 20]}
                    limit={limit}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={handleChangeLimit}
                />
            </div>
        </div>
    )
}

export default Test2
