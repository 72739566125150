/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../configs";
import "../../styles/DashboardCard.css";

const DashboardCard = (props) => {
  const { cardtitle, count, percentage, days, color, url, icon,state } = props;
  return (
    <div className="col  stretch-card transparent">
      <Link
        to={{
          pathname: url,
          
        }}
      >
        <div className="card card-tale">
          <div
            className="card-body top-card"
            style={{ backgroundColor: color }}
          >
            <h6
              className="mb-3"
              style={{ fontSize: "14px", lineHeight: "1.3rem" }}
            >
              {cardtitle}
            </h6>
            <div className="d-flex justify-content-around align-items-center">
              <h3 className="mb-2" style={{ fontSize: "40px", color: colors.dasbordtextColor }}>
                {count}
              </h3>
              {props.icon}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DashboardCard;
