/* eslint-disable no-unused-vars */
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import React from "react";
// import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import {
  AllOngoingProject,
  ChangeActiveStatusProject,
  UpdateProject,
} from "../../../axios";
import {
  AlertMessage,
  BreadCrumbs,
  Modal,
  NodataFound,
  SearchPageCard,
  SearchProjectCardDetailedView,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { CreateProjectForm } from "../../../forms";
import strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_PROJECT_STATUS_FUNCTION,
  UPDATE_PROJECT,
} from "../../../utils/constants";

const SearchOngoingProjectPage = (props) => {
  // const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  // const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [projectData, setProjectData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [alertBox, setAlertBox] = React.useState(false);
  const [projectType, setProjectType] = React.useState();
  const [errorMessage, setMessage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(6);
  // const [totalData, setTotalData] = React.useState(null);
  const [serialNo, setSerialNo] = React.useState(null);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_PROJECT)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_PROJECT_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["Projects"],
      path: "ongoingproject",
    },
    {
      name: `Ongoing Projects`,
      path: "ongoingproject",
    },
  ];

  const searchOngoingProjectHeader =
      strings["SearchOngoingProjecetHeader.searchOngoingProjectHeader"],
    projectScope = strings["SearchProjecetHeader.projectScope"],
    clientLabel = strings["SearchProjecetHeader.clientLabel"],
    shippingAddressLabel = strings["SearchProjecetHeader.shippingAddressLabel"],
    actionLabel = strings["SearchProjecetHeader.actionLabel"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const getAllProject = () => {
    setLoading(true);
    AllOngoingProject()
      .then((res) => {
        setLoading(false);
        setSerialNo(res.data.si);
        setProjectData(res.data.data);
        // setTotalData(res.data.total);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAllProject();
  }, []);

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    const statusId = projectType.is_active;
    const sta = {
      status: statusId === 0 ? 1 : 0,
    };
    const id = projectType.id;
    setLoading(true);
    ChangeActiveStatusProject(id, sta)
      .then((res) => {
        getAllProject();
        handleModalClose();
        setLoading(false);
        setAlertBox(false);
      })
      .catch(() => {
        setAlertBox(false);
        setLoading(false);
        handleModalClose();
      });
  };
  const updateProject = (projectData) => {
    const id = projectType.id;
    setLoading(true);
    UpdateProject(projectData, id)
      .then((res) => {
        getAllProject();
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Successfully",
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <CreateProjectForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleProjectData={updateProject}
        // actionType={clickedBtn}
        initialValue={currentUserType}
        handleModalClose={handleModalClose}
      />
    );
  };

  return (
    <div className="content-wrapper p-0">
      <h3>{"Ongoing Projects"}</h3>
      <BreadCrumbs pathList={paths} />
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          <div className="row mt-3">
            <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
              {selectModal()}
            </Modal>

            {alertBox && (
              <AlertMessage
                title={"Confirmation"}
                message={"Do you want to change the  status ?"}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            {projectData.length !== 0 ? (
              projectData.map((ele, index) => {
                return (
                  <div className="col-12 col-md-4 " key={index}>
                    <SearchPageCard>
                      <SearchProjectCardDetailedView
                        actions={true}
                        isUpdate={isUpdate}
                        isStatus={isStatus}
                        proDatas={ele}
                        projID={ele.id}
                        activeStatus={ele.is_active}
                        serialLabel={"Serial"}
                        serialValue={serialNo + index}
                        projectValue={ele.name}
                        projectScopeLabel={projectScope}
                        projectScopeValue={ele.project_scope_data.map(
                          (elem) => {
                            return `${elem.project_scopes.name} ,`.replace(
                              /,\s*$/,
                              ""
                            );
                          }
                        )}
                        clientLabel={clientLabel}
                        clientValue={ele.clients.name}
                        shippingAddressLabel={shippingAddressLabel}
                        shippingAddressValue={
                          ele.shipping_address ? ele.shipping_address : "NA"
                        }
                        actionLabel={actionLabel}
                        switchButtonOnClick={() => {
                          setAlertBox(true);
                          setProjectType(ele);
                        }}
                        iconButtonOnClick={() => {
                          setIsOpen(true);
                          setCurrentUserType(ele);
                          setProjectType(ele);
                        }}
                        projectStatus={"Ongoing"}
                      />
                    </SearchPageCard>
                  </div>
                );
              })
            ) : (
              <NodataFound
                onClick={() => {
                  // openAddModal();
                  // handleSelectBtn("add");
                }}
              />
            )}
          </div>
          {/* <div className="d-flex justify-content-end">
            <Stack spacing={3}>
              <Pagination
                count={Math.ceil(totalData / 6)}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </div> */}
        </>
      )}
    </div>
  );
};

export default SearchOngoingProjectPage;
