import React from "react";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  ChangeActiveStatusBudgetSchedule,
  CreateBudgetSchedule,
  GetAllActiveItemCategory,
  GetBudgetSchedule,
  UpdateBudgetSchedule,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  DateAndTimePicker,
  FormDropDown,
  IconButton,
  Modal,
  Table,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { BudgetScheduleForm } from "../../../forms/ProjectAndBudget";
import strings from "../../../translations";
import "./SearchbudgetStyle.css";

const SearchBudgetSchedulePage = (props) => {

  const budgetScheduleDataLighting = [
    {
      id: 1,
      stage: "RFQ",
      duration: "10",
      startDate: "2021-11-05",
      endDate: "2021-11-15",
      actualEndDate: "2021-11-15",
      difference: "Dealy",
    },
    {
      id: 2,
      stage: "Budgeting",
      duration: "20",
      startDate: "2021-11-05",
      endDate: "2021-11-25",
      actualEndDate: "2021-11-30",
      difference: "Early",
    },
  ];

  const budgetScheduleDataFlooring = [
    {
      id: 1,
      stage: "Budgeting",
      duration: "45",
      startDate: "2021-11-05",
      endDate: "2021-11-25",
      actualEndDate: "2021-21-15",
      difference: "Dealy",
    },
    {
      id: 2,
      stage: "RFQ",
      duration: "20",
      startDate: "2021-11-05",
      endDate: "2021-11-25",
      actualEndDate: "2021-11-30",
      difference: "Early",
    },
  ];

  const fields = [
    "id",
    "stage",
    "startDate",
    "endDate",
    "actualEndDate",
    "duration",
    "difference",
  ];

  const budgetScheduleTitle = strings["BudgetSchedule.budgetScheduleTitle"],
    categoryCustomError = strings["BudgetSchedule.categoryCustomError"],
    budgetStartDateLabel = strings["BudgetSchedule.SearchBudgetStartDateLabel"],
    searchBudgetStartDateCustomErrorLine =
      strings["BudgetSchedule.searchBudgetStartDateCustomErrorLine"],
    searchBudgetEndDateLabel =
      strings["BudgetSchedule.searchBudgetEndDateLabel"],
    searchBudgetEndDateCustomErrorLine =
      strings["BudgetSchedule.searchBudgetEndDateCustomErrorLine"],
    alertTitle = strings["BudgetSchedule.delalertTitle"],
    alertMessage = strings["BudgetSchedule.delalertMessage"],
    Stage = strings["BudgetSchedule.tablehead.Stage"],
    Duration = strings["BudgetSchedule.tablehead.Duration"],
    StartDate = strings["BudgetSchedule.tablehead.StartDate"],
    EndDate = strings["BudgetSchedule.tablehead.EndDate"],
    Actions = strings["BudgetSchedule.tablehead.Actions"];

  const params = useParams();
  const location = useLocation();
  const startDate = location.state[0].budget?.budget_start_date;
  const endDate = location.state[0].budget?.budget_end_date;
  const sDate = startDate.split(" ")[0];
  const eDate = endDate.split(" ")[0];

  const budget_id = location?.budget_id ? location?.budget_id : null;

  const proId = location?.proId
    ? location?.proId
    : location.query
      ? JSON.parse(location.query.params).proId
      : null;
  const head = [
    {
      id: "stage",
      label: Stage,
    },
    {
      id: "Duration",
      label: Duration,
    },
    {
      id: "StartDate",
      label: StartDate,
    },
    {
      id: "EndDate",
      label: EndDate,
    },
    {
      id: "Actions",
      label: Actions,
    },
  ];


  const [alertBox, setAlertBox] = React.useState(false);
  const [budgetScheduleData, setBudgetScheduleData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [ setBudgetId] = React.useState(null);
  const [budgetSchdlId, setBudgetSchdlId] = React.useState(null);
  const [categoryOption, setCategoryOption] = React.useState([]);
  const [uid, setUid] = React.useState(null);

  const [budgetStartDate, setBudgetStartDate] = React.useState({
    value: sDate,
    error: false,
    success: false,
  });

  const [budgetEndDate] = React.useState({
    value: eDate,
    error: false,
    success: false,
  });

  const [category, setCategory] = React.useState({
    value: "",
    error: false,
    success: false,
  });

  const paths = [
    {
      name: strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["Budgets"],
      path: "projectbudget" + "/" + proId,
    },
    {
      name: strings["Budget Items"],
      path: "budgetitem" + "/" + budget_id,
    },
    {
      name: strings["BudgetSchedule"],
      path: "/budgetschedule",
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const handleChangeStartDate = (data) => {
    setBudgetStartDate(data);
  };

  const handleChangeEndDate = (data) => {
  };

  const handleChangeDelete = (id) => {
    setAlertBox(!alertBox);
    setUid(id);
  };
  const handleEdit = (id) => {
    openAddModal();
    const result = budgetScheduleData.find((row) => {
      return row.id === id;
    });
    setCurrentUserType(result);
    handleSelectBtn("edit");
    setBudgetSchdlId(id);
    setBudgetId(result.budget_id);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setLoader(true);
    setAlertBox(false);
    ChangeActiveStatusBudgetSchedule(uid)
      .then((res) => {
        setLoader(false);
        const updateData = budgetScheduleData.filter((elem) => {
          return elem.id !== res.data.data.id;
        });
        setBudgetScheduleData(updateData);
        setAlertBox(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const getAllCategory = () => {
    GetAllActiveItemCategory()
      .then((res) => {
        const result = res.data.data.map((elm) => ({
          key: elm.id,
          label: elm.name,
          value: elm.id,
        }));
        setCategoryOption(result);
      })
      .catch(() => {
      });
  };

  const getAllBudget = () => {
    setLoader(true);
    GetBudgetSchedule()
      .then((res) => {
        setLoader(false);
        const result = res.data.data.map((elm) => ({
          id: elm.id,
          budget_id: elm.budget.id,
          stage: elm.stage.name,
          stageData: elm.stage,
          duration: elm.duration,
          startDate: elm.budget_start_date.split(" ")[0],
          endDate: elm.budget_end_date.split(" ")[0],
        }));
        setBudgetScheduleData(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getAllBudget();
    getAllCategory();
  }, []);

  const handleChangeCategory = (data) => {
    if (data.value === "FLOORING") {
      setBudgetScheduleData(budgetScheduleDataFlooring);
    }
    if (data.value === "LIGHTING") {
      setBudgetScheduleData(budgetScheduleDataLighting);
    }
    setCategory(data);
  };
  const createBudgetSchedule = (formData) => {
    setLoader(true);
    CreateBudgetSchedule(formData)
      .then((res) => {
        setLoader(false);
        getAllBudget();
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Created Successfully",
            icon: "success",
          });
        }
        setBudgetScheduleData([res.data.data, ...budgetScheduleData]);
        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateBudgetSchedule = (formData) => {
    setLoader(true);
    UpdateBudgetSchedule(formData, budgetSchdlId)
      .then((res) => {
        getAllBudget();
        setLoader(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Successfully",
            icon: "success",
          });
        }
        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <BudgetScheduleForm
        sDate={sDate}
        eDate={eDate}
        budget_id={params.id}
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add" ? createBudgetSchedule : updateBudgetSchedule
        }
        actionType={clickedBtn}
        initialValue={clickedBtn === "add" ? {} : currentUserType}
        handleModalClose={handleModalClose}
      />
    );
  };

  return (
    <div className="container">
      {loader ? <LoaderComponent /> : null}
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <div className="row mt-4">
        <div className="col mb-3">
          <h3 className="mb-1" style={{ marginBottom: "-20px" }}>
            {budgetScheduleTitle}
          </h3>
          <BreadCrumbs
            pathList={paths}
            obj={{
              proId: proId,
              budget_id: budget_id,
            }}
          />
        </div>
        <div className="col-auto mt-4">
          <span className="addButton mb-3">
            <IconButton
              onClick={() => {
                openAddModal();
                handleSelectBtn("add");
              }}
            >
              <AddIcon />
            </IconButton>
          </span>
        </div>

        {alertBox && (
          <AlertMessage
            title={alertTitle}
            message={alertMessage}
            onChangeCancel={handleChangeCancel}
            onChangeSucess={handleChangeSuccess}
          />
        )}
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-md-3">
            <DateAndTimePicker
              label={budgetStartDateLabel}
              customErrorLine={searchBudgetStartDateCustomErrorLine}
              hadleSetDate={handleChangeStartDate}
              date={budgetStartDate.value}
              disable={true}
            />
          </div>
          <div className="col-md-3">
            <DateAndTimePicker
              label={searchBudgetEndDateLabel}
              customErrorLine={searchBudgetEndDateCustomErrorLine}
              hadleSetDate={handleChangeEndDate}
              date={budgetEndDate.value}
              disable={true}
            />
          </div>
          <div style={{ width: "18%" }} className="col-md-4 mt-3">
            <p
              style={{ marginBottom: "-17px", fontWeight: 400, color: "black" }}
            >
              Category
            </p>
            <FormDropDown
              className="dropdownStyle"
              isMulti={false}
              value={category.value}
              Options={categoryOption}
              onSelect={handleChangeCategory}
              CustomErrorLine={categoryCustomError}
              disable={false}
            />
          </div>
        </div>

        <div className="budgetTable" style={{ marginTop: "10px" }}>
          <Table
            theadData={head}
            tbodyData={budgetScheduleData}
            fields={fields}
            isDelete={true}
            deleteOnClick={handleChangeDelete}
            isEdit={true}
            editOnClick={handleEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBudgetSchedulePage;
