import React from "react";
import { FormTextInput, Button, FormCheckBox } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const DeleteUserRoleMappingForm = (props) => {
  const { deleteChangeFormData, initialValue = {}, actionType } = props;

  const deleteUserRoleMappingTitle =
    Strings["UserRoleMapping.deleteUserRoleMappingTitle"],
    viewUserRoleMappingTitle =
      Strings["UserRoleMapping.ViewUserRoleMappingTitle"],
    deleteUserRoleMappingUserNameLabel =
      Strings["UserRoleMapping.deleteUserRoleMappingUserNameLabel"],
    deleteUserRoleMappingUserNamePlaceholder =
      Strings["UserRoleMapping.deleteRoleMappingUserNamePlaceholder"],
    deleteUserRoleMappingUserNameCustomError =
      Strings["UserRoleMapping.deleteRoleMappingUserNameCustomError"],
    deleteUserRoleMappingRoleNameLabel =
      Strings["UserRoleMapping.deleteRoleMappingRoleNameLabel"],
    deleteUserRoleMappingRoleNamePlaceholder =
      Strings["UserRoleMapping.deleteRoleMappingRoleNamePlaceholder"],
    deleteUserRoleMappingRoleNameCustomError =
      Strings["UserRoleMapping.deleteRoleMappingRoleNameCustomError"],
    deleteUserRoleMappingActiveStatusLabel =
      Strings["UserRoleMapping.deleteFunctionActiveStatusLabel"],
    deleteBtn = Strings["UserRoleMapping.deleteUserRoleMappingSaveButton"],
    cancel = Strings["UserRoleMapping.deleteUserRoleMappingCancelButton"];

  const getAllFunctions = (subCat) => {
    let str = "";
    subCat.forEach((i, index) => {
      if (index === 0) {
        str = i.functionName;
      } else {
        str = str + " , " + i.functionName;
      }
    });

    return str;
  };

  const [userRole, setuserRole] = React.useState({
    value: initialValue.user ? initialValue.user : "",
    error: false,
    success: false,
  });

  const [roles, setFunctions] = React.useState({
    value:
      initialValue.roles.length > 0 ? getAllFunctions(initialValue.roles) : "",
    error: false,
    success: false,
  });

  const [activeStatus, setActiveStatus] = React.useState(
    initialValue.activeStatus
  );

  const handleChangeUserName = (value) => {
    setuserRole(value);
  };

  const handleChangeUserRole = (value) => {
    setFunctions(value);
  };

  const handleChangeCheck = () => {
    setActiveStatus(!activeStatus);
  };

  const handleSaveData = () => {
    const formData = {
      userName: userRole.value,
      userRole: initialValue.roles,
    };
    deleteChangeFormData(formData);
  };

  let disable =
    userRole.error ||
    roles.error ||
    roles.value === "" ||
    userRole.value === "" ||
    roles.success === false ||
    userRole.success === false;

  return (
    <div className="modalFormContainer">
      <h1 className="mb-3">
        {actionType !== "delete"
          ? viewUserRoleMappingTitle
          : deleteUserRoleMappingTitle}
      </h1>

      <FormTextInput
        type="text"
        label={deleteUserRoleMappingUserNameLabel}
        placeholder={deleteUserRoleMappingUserNamePlaceholder}
        Value={userRole.value}
        onChangeText={handleChangeUserName}
        Validate={true}
        CustomErrorLine={deleteUserRoleMappingUserNameCustomError}
        editable={false}
      />

      <FormTextInput
        type="text"
        label={deleteUserRoleMappingRoleNameLabel}
        placeholder={deleteUserRoleMappingRoleNamePlaceholder}
        Value={roles.value}
        onChangeText={handleChangeUserRole}
        Validate={true}
        CustomErrorLine={deleteUserRoleMappingRoleNameCustomError}
        editable={false}
        multiline={false}
      />

      <FormCheckBox
        Label={deleteUserRoleMappingActiveStatusLabel}
        checked={activeStatus}
        handleChangeCheck={handleChangeCheck}
        disable={true}
      />

      {actionType === "delete" ? (
        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <Button
            buttonName={deleteBtn}
            buttonStyle={{
              marginRight: "10px",
              backgroundColor: colors.danger,
            }}
            disable={disable}
            onClick={handleSaveData}
          />
          <Button buttonName={cancel} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DeleteUserRoleMappingForm;
