/* eslint-disable no-unused-vars */
import React from "react";
import swal from "sweetalert";
import {
  CreateItemSubmittals,
  GetAllItemSubmittals,
  UpdateItemSubmittals,
  UpdateStatusItemSubmittals,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  SearchItemSubmittalCard,
  SearchPageCard,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { CreateItemSubmittalForm } from "../../../forms/ProjectAndBudget";
import strings from "../../../translations";

const Test = (props) => {
  // const allItemSubmittalData = [
  //   {
  //     id: 1,
  //     slNo: 1,
  //     submittalType: "Submittal Type 1",
  //     description: "Description 1",
  //     upload: "logo.png",
  //     activeStatus: false,
  //   },
  //   {
  //     id: 2,
  //     slNo: 2,
  //     submittalType: "Submittal Type 2",
  //     description: "Description 2",
  //     upload: "logo.png",
  //     activeStatus: true,
  //   },
  //   {
  //     id: 3,
  //     slNo: 3,
  //     submittalType: "Submittal Type 3",
  //     description: "Description 3",
  //     upload: "logo.png",
  //     activeStatus: true,
  //   },
  //   {
  //     id: 4,
  //     slNo: 4,
  //     submittalType: "Submittal Type 4",
  //     description: "Description 4",
  //     upload: "logo.png",
  //     activeStatus: true,
  //   },
  // ];

  const [data, setData] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [userId, setUserId] = React.useState(null);

  const searchItemSubmittalHeading =
      strings["ItemSubmittal.searchItemSubmittalHeading"],
    AlertTitle = strings["ItemSubmittal.AlertTitle"],
    alertMessage = strings["ItemSubmittal.alertMessage"],
    // ItemSubmittalLabel = strings["ItemSubmittal.ItemSubmittalLabe"],
    DescriptionLabel = strings["ItemSubmittal.DescriptionLabel"],
    uploadLabel = strings["ItemSubmittal.uploadLabel"],
    // itemSubmittal = strings["ItemSubmittal.itemSubmittal"],
    serialLabel = strings["ItemSubmittal.serialLabel"],
    itemTypeLabel = strings["ItemSubmittal.itemTypeLabel"],
    actionLabel = strings["ItemSubmittal.actionLabel"],
    addButton = strings["ItemSubmittal.addButton"];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };
  const handleChangeActiveStatus = (submitalData) => {
    setStatusChangeId(submitalData.is_active);
    setUserId(submitalData.id);
    setAlertBox(true);
  };

  const handleChangeSuccess = () => {
    setLoader(true);
    UpdateStatusItemSubmittals({ status: 1 - statusChangeId }, userId)
      .then((res) => {
        getItemSubmittals();
        setLoader(false);
        setAlertBox(false);
      })
      .catch(() => {
        setAlertBox(false);
        setLoader(false);
      });
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const getItemSubmittals = () => {
    setLoader(true);
    GetAllItemSubmittals()
      .then((res) => {
        setLoader(false);
        setData(res.data.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getItemSubmittals();
  }, []);

  const createIymSub = (itemSubmittalData) => {
    setLoader(true);
    CreateItemSubmittals(itemSubmittalData)
      .then((res) => {
        getItemSubmittals();
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
        }
        setLoader(false);
        handleModalClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateIymSub = (itemSubmittalData) => {
    const uid = currentUserType.id;
    setLoader(true);
    UpdateItemSubmittals(uid, itemSubmittalData)
      .then((res) => {
        if (res.status === 200) {
          getItemSubmittals();
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
        }
        setLoader(false);
        handleModalClose();
      })
      .catch((e) => {
        setMessage(e.response.data.message);
        setLoader(false);
      });
  };

  const selectModal = () => {
    return (
      <CreateItemSubmittalForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleSubmittalData={clickedBtn === "add" ? createIymSub : updateIymSub}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentUserType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  // breadcumbs path
  const paths = [
    {
      name: strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["ItemSubmittals"],
      path: "itemsubmittal",
    },
  ];

  return (
    <div className="container">
      {loader ? <LoaderComponent /> : null}
      <BreadCrumbs pathList={paths} />
      <div className="row">
        <div className="mb-3 d-flex justify-content-between mb-4">
          <h2>{searchItemSubmittalHeading}</h2>s
          <IconButton
            onClick={() => {
              openAddModal();
              handleSelectBtn("add");
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>

      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>

      {alertBox && (
        <AlertMessage
          title={AlertTitle}
          message={alertMessage}
          onChangeCancel={handleChangeCancel}
          onChangeSucess={handleChangeSuccess}
        />
      )}

      <div className="row">
        {data.map((submitalData, index) => {
          return (
            <div className="col-12 col-md-4" key={index}>
              <SearchPageCard>
                <SearchItemSubmittalCard
                  activeStatus={submitalData.is_active}
                  serialLabel={serialLabel}
                  serialValue={index + 1}
                  itemTypeLabel={itemTypeLabel}
                  itemTypeValue={
                    Object.values(submitalData.RequiredSubmittals)[1]
                  }
                  DescriptionLabel={DescriptionLabel}
                  DescriptionValue={submitalData.description}
                  uploadLabel={uploadLabel}
                  uploadValue={submitalData.files[0].file}
                  actionLabel={actionLabel}
                  switchButtonOnClick={() => {
                    handleChangeActiveStatus(submitalData);
                  }}
                  iconButtonOnClick={() => {
                    openAddModal();
                    handleSelectBtn("edit");
                    setCurrentUserType(submitalData);
                  }}
                />
              </SearchPageCard>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Test;

///////////////////////////////////////////

// import React from "react";
// import {
//   AlertMessage,
//   Button,
//   Modal,
//   BreadCrumbs,
//   SearchPageCard,
//   SearchItemSubmittalCard,
//   IconButton,
//   AddIcon,
//   Loader,
// } from "../../../components";
// import { CreateItemSubmittalForm } from "../../../forms/ProjectAndBudget";
// import strings from "../../../translations";

// const Test = (props) => {
//   const allItemSubmittalData = [
//     {
//       id: 1,
//       slNo: 1,
//       submittalType: "Submittal Type 1",
//       description: "Description 1",
//       upload: "logo.png",
//       activeStatus: false,
//     },
//     {
//       id: 2,
//       slNo: 2,
//       submittalType: "Submittal Type 2",
//       description: "Description 2",
//       upload: "logo.png",
//       activeStatus: true,
//     },
//     {
//       id: 3,
//       slNo: 3,
//       submittalType: "Submittal Type 3",
//       description: "Description 3",
//       upload: "logo.png",
//       activeStatus: true,
//     },

//   ];

//   const [data, setData] = React.useState(allItemSubmittalData);
//   const [isOpen, setIsOpen] = React.useState(false);
//   const [clickedBtn, setClickedBtn] = React.useState("");
//   const [currentUserType, setCurrentUserType] = React.useState({});
//   const [alertBox, setAlertBox] = React.useState(false);
//   const [statusChangeId, setStatusChangeId] = React.useState(null);

//   const searchItemSubmittalHeading =
//       strings["ItemSubmittal.searchItemSubmittalHeading"],
//     AlertTitle = strings["ItemSubmittal.AlertTitle"],
//     alertMessage = strings["ItemSubmittal.alertMessage"],
//     // ItemSubmittalLabel = strings["ItemSubmittal.ItemSubmittalLabe"],
//     DescriptionLabel = strings["ItemSubmittal.DescriptionLabel"],
//     uploadLabel = strings["ItemSubmittal.uploadLabel"],
//     // itemSubmittal = strings["ItemSubmittal.itemSubmittal"],
//     serialLabel = strings["ItemSubmittal.serialLabel"],
//     itemTypeLabel = strings["ItemSubmittal.itemTypeLabel"],
//     actionLabel = strings["ItemSubmittal.actionLabel"],
//     addButton = strings["ItemSubmittal.addButton"];

//   const openAddModal = () => {
//     setIsOpen(true);
//   };
//   const handleModalClose = () => {
//     setIsOpen(false);
//   };
//   const handleSelectBtn = (btn) => {
//     setClickedBtn(btn);
//   };
//   const handleChangeActiveStatus = (id) => {
//     setStatusChangeId(id);
//     setAlertBox(true);
//   };

//   const handleChangeSuccess = () => {
//     const result = data.map((rowData) => {
//       if (rowData.id === statusChangeId) {
//         return { ...rowData, activeStatus: !rowData.activeStatus };
//       } else {
//         return { ...rowData };
//       }
//     });
//     setData(result);
//     setAlertBox(false);
//   };

//   const handleChangeCancel = () => {
//     setAlertBox(false);
//   };

//   const selectModal = () => {
//     return (
//       <CreateItemSubmittalForm
//         handleProjectData={(val) => {}}
//         actionType={clickedBtn}
//         initialValue={currentUserType}
//         handleModalClose={handleModalClose}
//       />
//     );
//   };

//   // breadcumbs path
//   const paths = [
//     {
//       name: strings["DashBoardText"],
//       path: "",
//     },
//     {
//       name: strings["ItemSubmittals"],
//       path: "itemsubmittal",
//     },
//   ];

//   return (
//     <div className="container">
//       <BreadCrumbs pathList={paths} />
//       <div className="row">
//         <div className="mb-3 d-flex justify-content-between mb-4">
//           <h2>{searchItemSubmittalHeading}</h2>

//           <IconButton
//             onClick={() => {
//               openAddModal();
//               handleSelectBtn("add");
//             }}
//           >
//             <AddIcon />
//           </IconButton>
//         </div>
//       </div>

//       <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
//         {selectModal()}
//       </Modal>

//       {alertBox && (
//         <AlertMessage
//           title={AlertTitle}
//           message={alertMessage}
//           onChangeCancel={handleChangeCancel}
//           onChangeSucess={handleChangeSuccess}
//         />
//       )}

//       <div className="row">
//         {data.map((submitalData, index) => {
//           return (
//             <div className="col-12 col-md-4" key={index}>
//               <SearchPageCard>
//                 <SearchItemSubmittalCard
//                   activeStatus={submitalData.activeStatus}
//                   serialLabel={serialLabel}
//                   serialValue={index + 1}
//                   itemTypeLabel={itemTypeLabel}
//                   itemTypeValue={submitalData.submittalType}
//                   DescriptionLabel={DescriptionLabel}
//                   DescriptionValue={submitalData.description}
//                   uploadLabel={uploadLabel}
//                   uploadValue={submitalData.upload}
//                   actionLabel={actionLabel}
//                   switchButtonOnClick={() => {
//                     handleChangeActiveStatus(submitalData.id);
//                   }}
//                   iconButtonOnClick={() => {
//                     openAddModal();
//                     handleSelectBtn("edit");
//                     setCurrentUserType(submitalData);
//                   }}
//                 />
//               </SearchPageCard>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default Test;
