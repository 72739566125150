import React from "react";
import { Button, FormTextInput } from "../../components";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

const CreateMaster = (props) => {
  const [masterType, setMasterType] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  const [iconClass, setIconClass] = React.useState({
    value: "",
    error: false,
    success: false,
  });

  const handleChangeMasterType = (data) => {
    setMasterType(data);
  };

  const handleChangeIconClass = (data) => {
    setIconClass(data);
  };

  const convertSlug = (data) => {
    let resStr = "";
    for (let i = 0; i < data.length; i++) {
      if (data[i] === " ") {
        resStr += "_";
      } else {
        resStr += data[i].toUpperCase();
      }
    }
    return resStr;
  };

  const history = useHistory();

  const handleChangeCreateMaster = () => {
    const masterTypeId = uuidv4();
    const resultMasterType = {
      id: masterTypeId,
      masterType: masterType.value,
      slug: convertSlug(masterType.value),
      meta: {
        icon: iconClass.value,
      },
      i8n: {
        en: masterType.value,
      },
    };

    history.push({
      pathname: `/master/fields/create/${masterTypeId}`,
      state: { masterData: resultMasterType },
    });
  };

  return (
    <div className="container">
      <FormTextInput
        label="Enter the Master Type"
        type="text"
        placeholder="Enter the master"
        onChangeText={handleChangeMasterType}
        Value={masterType.value}
      />
      <FormTextInput
        label="Enter the Icon class"
        type="text"
        placeholder="Enter the icon class"
        onChangeText={handleChangeIconClass}
        Value={iconClass.value}
      />
      <Button buttonName="Create Master" onClick={handleChangeCreateMaster} />
    </div>
  );
};

export default CreateMaster;
