/* eslint-disable no-unused-vars */
import { TablePagination } from "@mui/material";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateOperator,
  GetAllCountryList,
  GetAllOperator,
  UpdateOperator,
  UpdateOperatorActiveStatus,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { CreateCompanyForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CREATE_ALL_OPERATOR,
  UPDATE_ALL_OPERATOR,
} from "../../../utils/constants";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

export default function SearchOparatorPage() {
  const searchTitle = Strings["Operator.SearchOperatorHeader"],
    alertMessage = Strings["Operator.AlertMessage"],
    alertTitle = Strings["Operator.AlertTitle"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [editId, setEditId] = React.useState(null);
  const [alertBox, setAlertBox] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [currentid, setcurrentid] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [allCountry, setAllCountry] = React.useState([]);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [totalData, setTotalData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_ALL_OPERATOR)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_ALL_OPERATOR)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_ALL_OPERATOR)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["OperatorText"],
      path: "master-configuration/searchoperator",
    },
  ];
  const mapableData = [
    {
      dataKey: "name",
      title: "Operator",
    },
    {
      dataKey: "description",
      title: "Description",
    },
    {
      dataKey: "is_active",
      title: "Status",
      isStatus: true,
    },
    {
      dataKey: "id",
      title: "Action",
      isAction: true,
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };

  const createOperator = (formData) => {
    setisLoader(true);
    CreateOperator(formData)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows([res.data.data, ...rows]);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateOperator = (formData) => {
    setisLoader(true);
    UpdateOperator(formData, editId)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const result = rows.map((elem) => {
            if (elem.id === res.data.data.id) {
              return {
                ...elem,
                name: res.data.data.name,
                code: res.data.data.code,
                tag: res.data.data.tag,
              };
            } else {
              return elem;
            }
          });
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const getAlldata = () => {
    setisLoader(true);
    GetAllOperator(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setTotalData(res.data.total);

        setRows(data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getAlldata();
  }, [rowsPerPage, page]);

  React.useEffect(() => {
    setisLoader(true);
    GetAllCountryList()
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setAllCountry(data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  }, []);
  const selectModal = () => {
    return (
      <CreateCompanyForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeData={
          clickedBtn === "add" ? createOperator : updateOperator
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };
  //update active status
  const handleChangeActiveStatus = (id, statuss) => {
    setAlertBox(true);
    setcurrentStatus(statuss);
    setcurrentid(id);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateOperatorActiveStatus({ status: 1 - currentStatus }, currentid)
      .then((res) => {
        setisLoader(false);
        const result = rows.map((elem) => {
          if (elem.id === res.data.data.id) {
            return { ...elem, is_active: res.data.data.is_active };
          } else {
            return elem;
          }
        });
        setRows(result);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    setEditId(result.id);
    handleSelectBtn("edit");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetAllOperator(rowsPerPage, page, searchData.value)
        .then((res) => {
          setisLoader(false);
          const data = res.data.data;
          setTotalData(res.data.total);

          setRows(data);
        })
        .catch((e) => {
          setisLoader(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAlldata();
  };

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <div className="content-wrapper p-0">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div>
            <div className="row">
              {alertBox && (
                <AlertMessage
                  title={alertTitle}
                  message={alertMessage}
                  onChangeCancel={handleChangeCancel}
                  onChangeSucess={handleChangeSuccess}
                />
              )}
              <div className="col-12">
                <div className="UserbudgetTable">
                  <form onSubmit={handleSearchSubmit}>
                    <FormTextInput
                      type="text"
                      className="searchBarUser mb-3"
                      placeholder={`Search `}
                      Value={searchValue.value}
                      onChangeText={handleSearch}
                      inputStyle={{ border: "none" }}
                      editable
                    />
                  </form>
                  {rows.length > 0 ? (
                    <>
                      <SearchTable
                        isUpdate={isUpdate}
                        isStatus={isStatus}
                        allList={allCountry}
                        placeholder={"name"}
                        data={rows}
                        mapableData={mapableData}
                        searchProp={["name"]}
                        handleChangeActiveStatus={handleChangeActiveStatus}
                        handleChangeEdit={handleChangeEdit}
                      />
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={totalData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={CustomTablePaginationActions}
                      />
                    </>
                  ) : (
                    <>
                      {isLoader ? (
                        <LoaderComponent />
                      ) : (
                        <NodataFound
                          onClick={() => {
                            openAddModal();
                            handleSelectBtn("add");
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
