/* eslint-disable jsx-a11y/anchor-is-valid */
const Footer = () => {
  return (
    <>
      <footer
        className="footer"
      >
        <div className="container">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                    Support
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                    Help Center
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                    Privacy
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 text-end">
              <p className="mb-0">
                © 2022 -{"23"}{" "}
                <a href="" className="text-muted">
                  furnish
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
