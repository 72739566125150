import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Link } from "react-router-dom";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { StatusChip, IconButton } from "../../../../components";
import { colors } from "../../../../configs";
import LoaderComponent from "../../../common/Loader";
import NodataFound from "../../NoDataFound/NodataFound";
import strings from "../../../../translations";

const ActionCell = ({
    rowData,
    dataKey,
    handleChangeDelete,
    handleChangeEdit,
    quoted,
    handleVisible,
    handleDownload,
    isUpdate,
    isStatus,
    handleShowReviseData,
    ...props
}) => {

    const DownloadLabel = strings["Tooltip.downloadTooltip"],
        EditLabel = strings["tooltip.editTooltip"],
        DeletLabel = strings["Tooltip.deletTooltip"],
        Revision = strings["Document.tableHead.Revision"],
        AddQuo = strings["Quo.tableHead.AddQuo"];


    function handleActionEdit() {
        handleChangeEdit(rowData[dataKey]);
    }

    const handleActionDelete = () => {
        handleChangeDelete(rowData[dataKey]);
    };
    const handleViewEditdata = () => {
        handleVisible(rowData[dataKey]);
    };
    const handleViewReData = () => {
        handleShowReviseData(rowData[dataKey]);
    };
    const handleRfiDownloads = () => {
        handleDownload(rowData[dataKey]);
    };

    return (
        <Cell {...props} className="link-group">
            <div style={{ margin: -10 }}>
                <IconButton onClick={handleRfiDownloads}>
                    <Tooltip title={DownloadLabel}>
                        <GetAppIcon sx={{ color: colors.success }} />
                    </Tooltip>
                </IconButton>
                <IconButton onClick={handleViewReData}>
                    <Tooltip title={Revision}>
                        <Visibility />
                    </Tooltip>
                </IconButton>

                {isUpdate && (
                    <IconButton onClick={handleActionEdit}>
                        <Tooltip title={EditLabel}>
                            <EditIcon />
                        </Tooltip>
                    </IconButton>
                )}
                {isStatus && (
                    <IconButton onClick={handleActionDelete}>
                        <Tooltip title={DeletLabel}>
                            <DeleteIcon sx={{ color: colors.danger }} />
                        </Tooltip>
                    </IconButton>
                )}
                {props.isview ? (
                    <button
                        className={"quotationButton me-3"}
                        onClick={handleViewEditdata}
                    >
                        {AddQuo}
                    </button>
                ) : null}
            </div>
        </Cell>
    );
};

const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <StatusChip
                label={rowData[dataKey] ? "Completed" : "Inprogress"}
                color={
                    rowData[dataKey] ? colors.success
                        : colors.pending
                }
            />
        </Cell>
    );
};
const StatusTable = (props) => {
    const {
        data,
        handleChangeDelete,
        handleChangeEdit,
        mapableData,
        handleVisible,
        handleDownload,
        handleView,
        isUpdate,
        isStatus,
        handleShowReviseData,
    } = props;

    const serialLabel = strings["Document.tableHead.RFQSerial"];


    const [allData, setAllData] = React.useState(data);
    const [loading] = React.useState(false);

    React.useEffect(() => {
        setAllData(data);
    }, [data]);



    const ActionCells = ({ rowData, dataKey, handleView, ...props }) => {
        const handleViewEditdata = () => {
            handleView(rowData[dataKey]);
        };

        return (
            <Cell {...props} className="link-group">
                <div style={{ marginLeft: "10px" }}>
                    <Link title={serialLabel} onClick={() => handleViewEditdata()}>
                        {rowData[dataKey]}
                    </Link>
                </div>
            </Cell>
        );
    };

    return (
        <div>
            {loading ? <LoaderComponent /> : null}
            {allData.length > 0 ? (
                <>
                    <Table
                        style={{ borderRadius: 10 }}
                        height={320}
                        id="table"
                        width={"100%"}
                        data={allData}
                        rowHeight={50}
                        headerHeight={50}
                    >
                        {mapableData.map((item, index) => {
                            return (
                                <Column
                                    width={230}
                                    flexGrow={1}
                                    key={index}
                                >
                                    <HeaderCell
                                        style={{
                                            color: colors.primary,
                                            fontWeight: 900,
                                            fontSize: "larger",
                                            textAlign: "center",
                                        }}
                                    >
                                        {item.title}
                                    </HeaderCell>
                                    {item.hasOwnProperty("isviewlink") ? (
                                        <ActionCells
                                            dataKey="serial_no"
                                            handleView={handleView}
                                        />
                                    ) : item.hasOwnProperty("isStatus") ? (
                                        <StatusCell dataKey="is_active" />
                                    ) : item.hasOwnProperty("isAction") ? (
                                        <ActionCell
                                            isUpdate={isUpdate}
                                            isStatus={isStatus}
                                            isview={item.isview}
                                            dataKey="id"
                                            handleChangeDelete={handleChangeDelete}
                                            handleChangeEdit={handleChangeEdit}
                                            handleVisible={handleVisible}
                                            handleShowReviseData={handleShowReviseData}
                                            handleDownload={handleDownload}
                                        />
                                    ) : (
                                        <Cell dataKey={item.dataKey} />
                                    )}
                                </Column>
                            );
                        })}
                    </Table>
                </>
            ) : (
                <>
                    {loading ? <LoaderComponent /> : <NodataFound norecord={false} />}
                </>
            )}
        </div>
    );
};

export default StatusTable;
