/* eslint-disable no-unused-vars */
import { Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import { IconButton, SwitchButton, TableRow } from "../../../../components";
import strings from "../../../../translations";

const SearchProjectBudgetCard = (props) => {
  const {
    activeStatus,
    serialLabel,
    serialValue,
    budgetValue,
    projectScopeLabel,
    projectScopeValue,
    currencyLabel,
    currencyValue,
    projectLabel,
    projectValue,
    startDateLabel,
    endDateLabel,
    startDateValue,
    endDateValue,
    actionLabel,
    switchButtonOnClick,
    iconButtonOnClick,
    uid,
    projectId,
    budget_id,
    isUpdate,
    isStatus,
    budget_name,
    budgetScopeData
  } = props;

  const editLabel = strings["tooltip.editTooltip"];

  return (
    <div>
      <Link
        to={{
          pathname: `/budgetitem/${uid}`,
          state: {
            proId: projectId,
            budgetId: budget_id,
            budget_name: budget_name,
            budgetScope: budgetScopeData
          },
        }}
      >
        <p className="d-flex float-end align-content-center align-items-center">
          {/* <span
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title={"LOCKED"}
            className="float-end me-3"
          >
            <FaLock color="rgba(138, 138, 138, 0.527)" /> 
          </span>*/}

          <span
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title={activeStatus ? "ACTIVE" : "IN-ACTIVE"}
            className={
              activeStatus ? "cardOngoing float-end" : "cardCancelled float-end"
            }
          ></span>
        </p>
        <span className={"text-secondary"}>
          {serialLabel}: {serialValue}
          <hr className="hrBg" />
        </span>

        <p className={"fw-bold text-uppercase mb-2"}>{budgetValue}</p>
        <table>
          <tbody>
            <TableRow label={projectScopeLabel} value={projectScopeValue} />
            <TableRow label={currencyLabel} value={currencyValue} />
            <CurrencyFormat value={projectValue} displayType={'text'} thousandSeparator={true} renderText={value => <TableRow label={projectLabel} value={value} />} />
            {/* <TableRow label={projectLabel} value={projectValue} /> */}
            <TableRow label={startDateLabel} value={startDateValue} />
            <TableRow label={endDateLabel} value={endDateValue} />
          </tbody>
        </table>
        <hr className="hrBg" />
      </Link>
      <span className="d-flex justify-content-between align-items-center">
        <span className="text-secondary">{actionLabel}</span>
        <span className="d-flex align-items-center">
          {isStatus && (
            <SwitchButton
              checked={activeStatus}
              onClick={switchButtonOnClick}
            />
          )}
          {isUpdate && (
            <IconButton onClick={iconButtonOnClick}>
              <Tooltip title={editLabel}>
                <EditIcon className="iconSwitch" />
              </Tooltip>
            </IconButton>
          )}
        </span>
      </span>
    </div>
  );
};

export default SearchProjectBudgetCard;
