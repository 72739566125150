import React from "react";
import { changepassword, logoRect } from "../../../assets";
import { ChangePasswordForm } from "../../../forms";
import Strings from "../../../translations";

const ChangePasswordPage = () => {
  const [formData, setFormData] = React.useState(null);
  const [error] = React.useState(null);

  const id = 1;

  const handleFormData = (value) => {
    setFormData(value);
  };
  const changePasswordLabel = Strings["Authentication.changePasswordTitle"];

  return (
    <>
      <div className="logincard">
        <div className="login_image">
          <img src={changepassword} alt="loginScreen" />
        </div>
        <div className="loginareaScreen">
          <div className="logo-section">
            <img src={logoRect} alt="furnish" />
          </div>

          <div className="container">
            <div className="row">
              <div className="login_form">
                <p> {error ? error : ""} </p>
                <h3> {changePasswordLabel} </h3>
                <ChangePasswordForm handleFormData={handleFormData} id={id} />
                {formData ? formData.newPassword + "/ -->" + formData.id : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPage;
