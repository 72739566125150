/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { GetAllActiveModules } from "../../../axios";
import { Button, FormDropDown, FormTextInput } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const FunctionForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createFunctionTitle = Strings["FunctionManagement.createFunctionTitle"],
    editFunctionTitle = Strings["FunctionManagement.editFunctionTitle"],
    roleNameLabel = Strings["FunctionManagement.createFunctionNameLabel"],
    roleNamePlaceHolder =
      Strings["FunctionManagement.createFunctionNamePlaceholder"],
    roleNameError = Strings["FunctionManagement.createFunctionNameCustomError"],
    roleDescriptionTitle =
      Strings["FunctionManagement.createFunctionDescriptionLabel"],
    roleDescriptionPlaceHolder =
      Strings["FunctionManagement.createFunctionDescriptionPlaceHolder"],
    roleDescriptionError =
      Strings["FunctionManagement.createFunctionDescriptionCustomError"],
    roleSaveButton = Strings["FunctionManagement.craeteFunctionSaveButton"],
    moduleName = Strings["Module.TableHead.createModuleTitle"],
    roleUpdateButton = Strings["FunctionManagement.seacrhFunctionUpdateButton"],
    SelectRolefunctionmappingcutonerror =
      Strings["Module.createModuleNameCustomError"],
    roleCancelButton = Strings["FunctionManagement.craeteFunctionCancelButton"];
  const [loading, setLoading] = React.useState(false);

  const [functionName, setFunctionName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [functionCode, setFunctionCode] = useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    success: false,
  });

  const [uiCode, setuiCode] = useState({
    value: initialValue.ui_function_code ? initialValue.ui_function_code : "",
    error: false,
    success: false,
  });

  const [functionDescription, setFunctionDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [module, setModule] = React.useState({
    value: initialValue.modules ? initialValue.modules.id : "",
    error: false,
    success: false,
  });
  const [options, setOptions] = React.useState([]);

  const handleSelectDropDown = (value) => {
    setMessage(null);
    setModule(value);
  };

  const handleChangeRole = (value) => {
    setMessage(null);
    setFunctionName(value);
  };
  const handleChangeCode = (value) => {
    setMessage(null);
    setFunctionCode(value);
  };
  const handleChangeUiCode = (value) => {
    setuiCode(value);
  };

  const handleChangeDescription = (value) => {
    setMessage(null);
    setFunctionDescription(value);
  };

  const getAllModule = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setLoading(true);
    GetAllActiveModules()
      .then((res) => {
        setLoading(false);
        const allData = res.data.data;
        const result = allData.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setOptions(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllModule();
  }, []);

  const handleSaveData = () => {
    const formData = {
      module_id: module.value,
      name: functionName.value,
      code: functionCode.value,
      ui_code: uiCode.value,
      description: functionDescription.value,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged =
    functionName.value === initialValue.name &&
    module.value === initialValue.modules.name &&
    functionDescription === initialValue.description;
  // functionDescription.value === initialValue.roleDescription;

  let disable =
    functionName.error ||
    module.error ||
    functionName.value === "" ||
    module.value === "" ||
    functionName.success === false ||
    module.success === false ||
    isValueNOTChanged;

  let isValueNOTChangededit =
    functionName.value === initialValue.name &&
    functionDescription.value === initialValue.modules.name &&
    functionDescription.value === initialValue.description;

  let Editdisable =
    functionName.error ||
    functionDescription.error ||
    functionName.value === "" ||
    functionDescription.value === "" ||
    (functionName.success === false && functionDescription.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? createFunctionTitle : editFunctionTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormDropDown
        Label={moduleName}
        Options={options}
        onSelect={handleSelectDropDown}
        customErrorLine={SelectRolefunctionmappingcutonerror}
        searchable={false}
        value={module.value}
        disable={actionType !== "add" ? true : false}
        starProps={true}
        isValidate={true}
      />
      <FormTextInput
        type="text"
        label={roleNameLabel}
        placeholder={roleNamePlaceHolder}
        Value={functionName.value}
        onChangeText={handleChangeRole}
        Validate={true}
        CustomErrorLine={roleNameError}
        starProps={true}
        editable
      />
      <FormTextInput
        type="text"
        label={"Code"}
        placeholder={"Enter code"}
        Value={functionCode.value}
        onChangeText={handleChangeCode}
        // Validate={true}
        CustomErrorLine={"Enter Code"}
        starProps={false}
        editable
      />
      <FormTextInput
        type="textarea"
        label={"UI Code"}
        placeholder={"Enter ui code"}
        Value={uiCode.value}
        onChangeText={handleChangeUiCode}
        // Validate={true}
        CustomErrorLine={"Enter Ui Code"}
        starProps={false}
        editable
      />
      <FormTextInput
        type="textarea"
        label={roleDescriptionTitle}
        placeholder={roleDescriptionPlaceHolder}
        Value={functionDescription.value}
        onChangeText={handleChangeDescription}
        Validate={false}
        multiline={true}
        CustomErrorLine={roleDescriptionError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? roleSaveButton : roleUpdateButton}
          buttonStyle={{ backgroundColor: colors.primary }}
          // disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={roleCancelButton}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default FunctionForm;
