/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ModeOfShipmentForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["ModeShippment.CreateForm"],
    editTitle = Strings["ModeShippment.EditForm"],
    ShipmentLabel = Strings["ModeShippment.ShippmentModeLabel"],
    ShipmentPlaceholder = Strings["ModeShippment.ShippmentModePlaceHolder"],
    ShipmentCustomError = Strings["ModeShippment.ShippmentModeError"],
    ShipmentDescriptionLabel =
      Strings["ModeShippment.ShippmentModeDescriptionLabel"],
    ShipmentDescriptionPlaceholder =
      Strings["ModeShippment.ShippmentModeDescriptionPlaceHolder"],
    ShipmentDescriptionCustomError =
      Strings["ModeShippment.ShippmentModeDescriptionError"],
    ShipmentTagLabel = Strings["ModeShippment.ShippmentModeTagLabel"],
    ShipmentTagPlaceholder =
      Strings["ModeShippment.ShippmentModeTagPlaceHolder"],
    ShipmentTagCustomError = Strings["ModeShippment.ShippmentModeTagError"],
    ShipmentSaveBtn = Strings["ModeShippment.SaveBtn"],
    ShipmentUpdateBtn = Strings["ModeShippment.UpdateBtn"],
    ShipmentCancelBtn = Strings["ModeShippment.CancelBtn"];

  const [shipmentName, setShipmentName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [shipmentDescription, setShipmentDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [shipmentTag, setShipmentTag] = React.useState({
    value: initialValue.tag ? initialValue.tag : "",
    error: false,
    success: false,
  });

  const handleChangeShipmentName = (value) => {
    setMessage(null);
    setShipmentName(value);
  };

  const handleChangeShipmentDescription = (value) => {
    setShipmentDescription(value);
  };

  const handleChangeShipmentTag = (value) => {
    setMessage(null);
    setShipmentTag(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: shipmentName.value,
      description: shipmentDescription.value,
      tag: shipmentTag.value,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged =
    shipmentName.value === initialValue.name &&
    // shipmentDescription.value === initialValue.description &&
    shipmentTag.value === initialValue.tag;
  let disable =
    shipmentName.error ||
    // shipmentDescription.error ||
    shipmentTag.error ||
    shipmentName.value === "" ||
    // shipmentDescription.value === "" ||
    shipmentTag.value === "" ||
    (shipmentName.success === false &&
      // shipmentDescription.success === false &&
      shipmentTag.success === false) ||
    isValueChanged;

  //edit
  let isValueChangededit =
    shipmentName.value === initialValue.name &&
    shipmentDescription.value === initialValue.description &&
    shipmentTag.value === initialValue.tag;
  let Editdisable =
    shipmentName.error ||
    shipmentDescription.error ||
    shipmentTag.error ||
    shipmentName.value === "" ||
    shipmentDescription.value === "" ||
    shipmentTag.value === "" ||
    (shipmentName.success === false &&
      shipmentDescription.success === false &&
      shipmentTag.success === false) ||
    isValueChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        label={ShipmentLabel}
        placeholder={ShipmentPlaceholder}
        Value={shipmentName.value}
        onChangeText={handleChangeShipmentName}
        Validate={true}
        CustomErrorLine={ShipmentCustomError}
        starProps={true}
        maxLength={50}
        editable
      />

      <FormTextInput
        type="alpha"
        label={ShipmentDescriptionLabel}
        placeholder={ShipmentDescriptionPlaceholder}
        Value={shipmentDescription.value}
        onChangeText={handleChangeShipmentDescription}
        Validate={false}
        CustomErrorLine={ShipmentDescriptionCustomError}
        multiline={true}
        starProps={false}
        maxLength={100}
        editable
      />

      <FormTextInput
        type="alpha"
        label={ShipmentTagLabel}
        placeholder={ShipmentTagPlaceholder}
        Value={shipmentTag.value}
        onChangeText={handleChangeShipmentTag}
        Validate={true}
        CustomErrorLine={ShipmentTagCustomError}
        starProps={true}
        maxLength={50}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? ShipmentSaveBtn : ShipmentUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={ShipmentCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default ModeOfShipmentForm;
