/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Navbar, Sidebar } from "../components";
import Footer from "../components/common/Footer";
import LoaderComponent from "../components/common/Loader";
import { Routes } from "./../navigation";


export default function NavRouteUnits(props) {
  const scrollRef = React.useRef(null);
  const { isLogin } = props;
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(true);
  const [loader] = React.useState(false);

  const handleToggleSidebar = (value) => {
    setCollapsed(value);
    setToggled(value);
  };
  React.useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
      {loader ? <LoaderComponent /> : null}
      <Navbar handleToggleSidebar={handleToggleSidebar} collapsed={collapsed} />
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          height: "calc(100vh - 65px)",
        }}
      >
        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        />
        <main
          ref={scrollRef}
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
            backgroundColor: "#f7f8f9",
          }}
        >
          <Routes isLogin={isLogin} />
          <Footer />
        </main>
        {
          pathname === "/project"
          
        }
      </section>
    </BrowserRouter>
    </>
  );
}

