import React from "react";
import {
  AllActiveStatusCompanyType,
  GetActiveRole,
  GetAllActiveCountry,
  GetAllActiveProducts,
  GetAllActiveVendorCategory,
  GetAllActiveVendorSubCategory,
} from "../../../axios";
import { Button, FormDropDown, FormTextInput } from "../../../components";
import FormPhoneInput from "../../../components/common/FormPhoneInput";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import "./VendorForm.css";

const VendorForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [emailAddress, setEmailAddress] = React.useState({
    value: initialValue.email ? initialValue.email : "",
    error: false,
    success: true,
  });

  const [companyType, setCompanyType] = React.useState({
    value: initialValue.company_type ? initialValue.company_type.id : "",
    error: false,
    success: false,
  });

  var categoryList = [];
  if (initialValue.vendor_category) {
    categoryList = initialValue.vendor_category.map((ele) => {
      return {
        key: ele.id,
        label: ele.category,
        value: ele.id,
      };
    });
  }

  const [categories, setCategories] = React.useState({
    value: categoryList,
    error: false,
    success: false,
  });

  var subCategoryList = [];
  if (initialValue.vendor_sub_category) {
    subCategoryList = initialValue.vendor_sub_category.map((ele) => {
      return {
        key: ele.id,
        label: ele.sub_category,
        value: ele.id,
      };
    });
  }
  const [subCategories, setSubCategories] = React.useState({
    value: subCategoryList,
    error: false,
    success: false,
  });

  var productList = [];
  if (initialValue.product_services) {
    productList = initialValue.product_services.map((ele) => {
      return {
        key: ele.id,
        label: ele.name,
        value: ele.id,
      };
    });
  }

  const [productAndServices, setProductAndServices] = React.useState({
    value: productList,
    error: false,
    success: false,
  });

  const [website, setWebsite] = React.useState({
    value: initialValue.website ? initialValue.website : "",
    error: false,
    success: true,
  });

  const [address, setAddress] = React.useState({
    value: initialValue.address ? initialValue.address : "",
    error: false,
    success: false,
  });

  const [city, setCity] = React.useState({
    value: initialValue.city ? initialValue.city : "",
    error: false,
    success: false,
  });

  const [postcode, setPostCode] = React.useState({
    value: initialValue.postcode ? initialValue.postcode : "",
    error: false,
    success: false,
  });

  const [state, setState] = React.useState({
    value: initialValue.state ? initialValue.state : "",
    error: false,
    success: false,
  });

  const [country, setCountry] = React.useState({
    value: initialValue.countries ? initialValue.countries.id : "",
    error: false,
    success: false,
  });

  const [tel, setTel] = React.useState({
    value: initialValue.phone ? initialValue.phone : "",
    error: false,
    success: true,
  });

  const [fax, setFax] = React.useState({
    value: initialValue.fax ? initialValue.fax : "",
    error: false,
    success: false,
  });

  const [optionsCountry, setOptionsCountry] = React.useState([]);
  const [optionsCompanyType, setOptionsCompanyType] = React.useState([]);
  const [categoriesMultipleOptions, setCategoriesMultipleOptions] =
    React.useState([]);
  const [subCategoriesMultipleOptions, setSubCategoriesMultipleOptions] =
    React.useState([]);
  const [productAndServiceOptions, setProductAndServiceOptions] =
    React.useState([]);
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    setLoading(true);
    GetActiveRole()
      .then((res) => {
        setLoading(false);
        const allData = res.data.data;
        const result = allData.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name.toLowerCase(),
        }));
        const filterRole = result.filter((option) => option.label == "vendor");
        setOptions(filterRole);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };
  const handleChangeEmailAddress = (value) => {
    setMessage(null);
    setEmailAddress(value);
  };

  const handleChangeCompanyType = (value) => {
    setMessage(null);
    setCompanyType(value);
  };

  const handleChangeCategories = (value) => {
    setMessage(null);
    setCategories(value);
  };

  const handleChangeSubCategories = (value) => {
    setMessage(null);
    setSubCategories(value);
  };

  const handleSearchSubCategories = (value) => {
    setMessage(null);
    setSubCategories(value);
  };

  const handleChangeProductAndService = (value) => {
    setMessage(null);
    setProductAndServices(value);
  };

  const handleServiceProductAndService = (value) => {
    setMessage(null);
    setProductAndServices(value);
  };

  const handleChangeWebsite = (value) => {
    setMessage(null);
    setWebsite(value);
  };

  const handleChangeAddress = (value) => {
    setMessage(null);
    setAddress(value);
  };

  const handleChangeCity = (value) => {
    setMessage(null);
    setCity(value);
  };

  const handleChangePostCode = (value) => {
    setMessage(null);
    setPostCode(value);
  };

  const handleChangeState = (value) => {
    setMessage(null);
    setState(value);
  };

  const handleChangeCountry = (value) => {
    setMessage(null);
    setCountry(value);
  };

  const handleChangeTel = (value) => {
    setMessage(null);
    setTel(value);
  };

  const handleChangeFax = (value) => {
    setMessage(null);
    setFax(value);
  };

  const getAllActiveCountry = () => {
    setLoading(true);
    GetAllActiveCountry()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setLoading(false);
        setOptionsCountry(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getAllActiveCompanyType = () => {
    setLoading(true);
    AllActiveStatusCompanyType()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.company_type,
        }));
        setOptionsCompanyType(result);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getAllActiveCategories = () => {
    setLoading(true);
    GetAllActiveVendorCategory()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.category,
        }));
        setCategoriesMultipleOptions(result);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getAllActiveSubCategories = () => {
    setLoading(true);
    GetAllActiveVendorSubCategory()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.sub_category,
        }));
        setSubCategoriesMultipleOptions(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getAllActiveProductService = () => {
    setLoading(true);
    GetAllActiveProducts()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setProductAndServiceOptions(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAllActiveCountry();
    getAllActiveCompanyType();
    getAllActiveCategories();
    getAllActiveSubCategories();
    getAllActiveProductService();
  }, []);

  React.useEffect(() => {}, [initialValue.profile_picture]);
  const functionCategories = () => {
    const resultArray = [];
    categories.value.forEach((item) => {
      if (item.key !== null) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };

  const functionSubCategories = () => {
    const resultArray = [];
    subCategories.value.forEach((item) => {
      if (item.key !== null) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };
  const functionProductServices = () => {
    const resultArray = [];
    productAndServices.value.forEach((item) => {
      if (item.key !== null) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };

  const functionResultTrue = (roles) => {
    const resultArray = [];
    roles.forEach((func) => {
      resultArray.push(func.key);
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      name: name.value,
      company_type_id: companyType.value.toString(),
      website: website.value,
      fax: fax.value,
      twitter: null,
      rating: null,
      address: address.value,
      city: city.value,
      postcode: postcode.value,
      state: state.value,
      country_id: country.value.toString(),
      phone: tel.value,
      commodity_id: [],
      product_services_id: functionProductServices(productAndServices),
      sub_category_id: functionSubCategories(subCategories),
      category_id: functionCategories(categories),
      profile_picture: null,
      email: emailAddress.value,
      role_id: functionResultTrue(options),
    };
    handleChangeFormData(formData);
  };

  const createVenderManagementTitle =
      Strings["VenderManagement.CreateVenderManagementTitle"],
    editVenderManagementTitle =
      Strings["VenderManagement.EditVenderManagementTitle"],
    NameLabel = Strings["VenderManagement.NameLabel"],
    NamePlaceholder = Strings["VenderManagement.NamePlaceholder"],
    NameCustomError = Strings["VenderManagement.NameCustomError"],
    CompanyTypeDropDownCustomError =
      Strings["VenderManagement.CompanyTypeDropDownCustomError"],
    CompanyTypeLabel = Strings["VenderManagement.CompanyTypeLabel"],
    WebsiteLabel = Strings["VenderManagement.WebsiteLabel"],
    WebsiteCustomError = Strings["VenderManagement.WebsiteCustomError"],
    WebsitePlaceholder = Strings["VenderManagement.WebsitePlaceholder"],
    userEmailLabel = Strings["UserManagement.createUserEmailLable"],
    userEmailplaceholder = Strings["UserManagement.createUserEmailPlaceHolder"],
    userEmailCustomError =
      Strings["UserManagement.createUserEmailcustomlineerror"],
    AddressLabel = Strings["VenderManagement.AddressLabel"],
    AddressCustomError = Strings["VenderManagement.AddressCustomError"],
    AddressPlaceholder = Strings["VenderManagement.AddressPlaceholder"],
    CityLabel = Strings["VenderManagement.CityLabel"],
    CityCustomError = Strings["VenderManagement.CityCustomError"],
    CityPlaceholder = Strings["VenderManagement.CityPlaceholder"],
    PostCodeLabel = Strings["VenderManagement.PostCodeLabel"],
    PostCodeCustomError = Strings["VenderManagement.PostCodeCustomError"],
    PostCodePlaceholder = Strings["VenderManagement.PostCodePlaceholder"],
    StateLabel = Strings["VenderManagement.StateLabel"],
    StateCustomError = Strings["VenderManagement.StateCustomError"],
    StatePlaceholder = Strings["VenderManagement.StatePlaceholder"],
    CountryLabel = Strings["VenderManagement.CountryLabel"],
    CountryCustomError = Strings["VenderManagement.CountryCustomError"],
    CountrySelectText = Strings["VenderManagement.CountrySelectText"],
    TelLabel = Strings["VenderManagement.TelLabel"],
    TelCustomError = Strings["VenderManagement.TelCustomError"],
    TelPlaceholder = Strings["VenderManagement.TelPlaceholder"],
    FaxLabel = Strings["VenderManagement.FaxLabel"],
    FaxPlaceholder = Strings["VenderManagement.FaxPlaceholder"],
    ProductAndServiceLabel = Strings["VenderManagement.ProductAndServiceLabel"],
    subCategoriesLabel = Strings["VenderManagement.subCategoriesLabel"],
    CategoriesLabel = Strings["VenderManagement.CategoriesLabel"],
    CategoriesCustomError = Strings["VenderManagement.CategoriesCustomError"],
    subCategoriesCustomError =
      Strings["VenderManagement.SubCategoriesCustomError"],
    ProductAndServiceCustomError =
      Strings["VenderManagement.ProductAndServiceCustomError"],
    vendorSaveBtn = Strings["VenderManagement.vendorSaveBtn"],
    vendorUpdateBtn = Strings["VenderManagement.vendorUpdateBtn"],
    VendorCancelBtn = Strings["VenderManagement.VendorCancelBtn"];

  let isValueChanged =
    name.value === initialValue.name &&
    website.value === initialValue.website &&
    companyType.value === initialValue.type &&
    tel.value === initialValue.tel &&
    categories.value === initialValue.categories &&
    subCategories.value === initialValue.subCategories &&
    productAndServices.value === initialValue.productAndServices;

  let disable =
    name.error ||
    emailAddress.error ||
    companyType.error ||
    categories.error ||
    // subCategories.error ||
    // productAndServices.error ||
    // tel.error ||
    name.value === "" ||
    // emailAddress.value === "" ||
    companyType.value === "" ||
    categories.value.length === 0 ||
    // subCategories.value.length === 0 ||
    // productAndServices.value.length === 0 ||
    // tel.value === "" ||
    (name.success === false &&
      emailAddress.success === false &&
      companyType.success === false &&
      // tel.success === false &&
      // tel.error === true &&
      categories.success === false )
      // subCategories.success === false &&
      // productAndServices.success === false);
  // isValueChanged;

  let isEditValueChanged =
    name.value === initialValue.name &&
    companyType.value === initialValue.type &&
    website.value === initialValue.website &&
    address.value === initialValue.address &&
    city.value === initialValue.city &&
    postcode.value === initialValue.postcode &&
    state.value === initialValue.state &&
    country.value === initialValue.country &&
    tel.value === initialValue.tel &&
    fax.value === initialValue.fax &&
    categories.value === initialValue.categories &&
    subCategories.value === initialValue.subCategories &&
    productAndServices.value === initialValue.productAndServices;

  let editDisable =
    name.error ||
    emailAddress.error ||
    companyType.error ||
    website.error ||
    address.error ||
    city.error ||
    state.error ||
    country.error ||
    // fax.error ||
    categories.error ||
    subCategories.error ||
    productAndServices.error ||
    // tel.error === "" ||
    name.value === "" ||
    tel.value.length <= 10 ||
    companyType.value === "" ||
    categories.value.length === 0 ||
    subCategories.value.length === 0 ||
    productAndServices.value.length === 0 ||
    // tel.value === "" ||
    (name.success === false &&
      emailAddress.success === false &&
      companyType.success === false &&
      // website.success === false &&
      address.success === false &&
      city.success === false &&
      postcode.success === false &&
      state.success === false &&
      country.success === false &&
      // tel.success === false &&
      // fax.success === false &&
      categories.success === false &&
      subCategories.success === false &&
      productAndServices.success === false) ||
    isEditValueChanged;
  console.log(tel.value.length);
  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>
        {actionType === "add"
          ? createVenderManagementTitle
          : editVenderManagementTitle}
      </h2>

      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={NameLabel}
            starProps={true}
            placeholder={NamePlaceholder}
            Value={name.value}
            onChangeText={handleChangeName}
            Validate={true}
            CustomErrorLine={NameCustomError}
            editable
            maxLength={100}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          <FormTextInput
            starProps={false}
            type="email"
            label={userEmailLabel}
            placeholder={userEmailplaceholder}
            Value={emailAddress.value}
            onChangeText={handleChangeEmailAddress}
            Validate={emailAddress.value && true}
            CustomErrorLine={userEmailCustomError}
            editable={true}
            multiline={false}
          />
        </div>
        <div
          className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3"
          id="select-style"
        >
          <FormTextInput
            type="website"
            label={WebsiteLabel}
            placeholder={WebsitePlaceholder}
            Value={website.value}
            onChangeText={handleChangeWebsite}
            Validate={website.value && true}
            CustomErrorLine={WebsiteCustomError}
            starProps={false}
            editable
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3"
          id="select-style"
        >
          <FormDropDown
            isMulti={false}
            defaultValue={companyType.value}
            value={companyType.value}
            starProps={true}
            Label={CompanyTypeLabel}
            Options={optionsCompanyType}
            onSelect={handleChangeCompanyType}
            CustomErrorLine={CompanyTypeDropDownCustomError}
            isValidate={true}
            editable
          />
        </div>
        <div
          className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3"
          id="select-style"
        >
          <FormDropDown
            isMulti={true}
            defaultValue={categories.value}
            Label={CategoriesLabel}
            starProps={true}
            Options={categoriesMultipleOptions}
            CustomErrorLine={CategoriesCustomError}
            selectionLimit={3}
            onSelect={handleChangeCategories}
            isValidate={true}
            editable
          />
        </div>
        <div
          className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3"
          id="select-style"
        >
          <FormDropDown
            isValidate={true}
            isMulti={true}
            defaultValue={subCategories.value}
            Label={subCategoriesLabel}
            starProps={false}
            CustomErrorLine={subCategoriesCustomError}
            Options={subCategoriesMultipleOptions}
            selectionLimit={3}
            onSelect={handleChangeSubCategories}
            handleSearch={handleSearchSubCategories}
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3"
          id="select-style"
        >
          <FormDropDown
            isValidate={true}
            defaultValue={productAndServices.value}
            isMulti={true}
            Label={ProductAndServiceLabel}
            starProps={false}
            CustomErrorLine={ProductAndServiceCustomError}
            Options={productAndServiceOptions}
            selectionLimit={3}
            onSelect={handleChangeProductAndService}
            handleSearch={handleServiceProductAndService}
          />
        </div>
        <div
          className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3"
          id="select-style"
        >
          <FormDropDown
            isMulti={false}
            value={country.value}
            selectText={CountrySelectText}
            Label={CountryLabel}
            Options={optionsCountry}
            onSelect={handleChangeCountry}
            CustomErrorLine={CountryCustomError}
            searchable={false}
            isValidate={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={StateLabel}
            placeholder={StatePlaceholder}
            Value={state.value}
            onChangeText={handleChangeState}
            Validate={state.value && true}
            CustomErrorLine={StateCustomError}
            editable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={CityLabel}
            placeholder={CityPlaceholder}
            Value={city.value}
            onChangeText={handleChangeCity}
            Validate={city.value && true}
            CustomErrorLine={CityCustomError}
            editable
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          {/* <FormTextInput
            type="phone"
            label={TelLabel}
            starProps={true}
            placeholder={TelPlaceholder}
            Value={tel.value}
            onChangeText={handleChangeTel}
            Validate={true}
            CustomErrorLine={TelCustomError}
          /> */}
          <FormPhoneInput
            type="phone"
            label={TelLabel}
            starProps={false}
            placeholder={TelPlaceholder}
            Value={tel.value}
            onChangePhone={handleChangeTel}
            Required={false}
            CustomErrorLine={TelCustomError}
            editable
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          <FormTextInput
            type="phone"
            label={FaxLabel}
            placeholder={FaxPlaceholder}
            Value={fax.value}
            onChangeText={handleChangeFax}
            Validate={fax.value && true}
            CustomErrorLine={"Enter A Valid Fax Number"}
            editable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          <FormTextInput
            type="alpha"
            label={PostCodeLabel}
            placeholder={PostCodePlaceholder}
            Value={postcode.value}
            onChangeText={handleChangePostCode}
            Validate={postcode.value && true}
            CustomErrorLine={PostCodeCustomError}
            editable
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={AddressLabel}
            placeholder={AddressPlaceholder}
            Value={address.value}
            onChangeText={handleChangeAddress}
            Validate={address.value && true}
            CustomErrorLine={AddressCustomError}
            multiline={true}
            maxLength={150}
            editable
          />
        </div>
      </div>
      <div className="row">
        <div style={{ display: "flex", marginTop: "1.5rem" }}></div>
      </div>

      <div
        className="col-12 d-flex justify-content-end"
        style={{
          marginTop: "-100px",
        }}
      >
        <Button
          buttonName={actionType === "add" ? vendorSaveBtn : vendorUpdateBtn}
          disable={actionType === "add" ? disable : false}
          onClick={handleSaveData}
          buttonStyle={{ backgroundColor: colors.primary }}
        />
        <Button
          buttonName={VendorCancelBtn}
          buttonStyle={{
            backgroundColor: colors.darkgrey,
            marginLeft: "10px",
          }}
          onClick={() => {
            handleModalClose();
          }}
        />
      </div>
    </div>
  );
};

export default VendorForm;
