/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const PaymentAgainstForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["PaymentAgainst.CreateForm"],
    editTitle = Strings["PaymentAgainst.EditForm"],
    PaymentAgainstLabel = Strings["PaymentAgainst.PaymentAgainstNameLabel"],
    PaymentAgainstPlaceholder =
      Strings["PaymentAgainst.PaymentAgainstNamePlaceHolder"],
    PaymentAgainstCustomError =
      Strings["PaymentAgainst.PaymentAgainstNameError"],
    PaymentAgainstDescriptionLabel =
      Strings["PaymentAgainst.PaymentAgainstDescriptionLabel"],
    PaymentAgainstDescriptionPlaceholder =
      Strings["PaymentAgainst.PaymentAgainstDescriptionPlaceHolder"],
    PaymentAgainstDescriptionCustomError =
      Strings["PaymentAgainst.PaymentAgainstDescriptionError"],
    PaymentAgainstSaveBtn = Strings["PaymentAgainst.SaveBtn"],
    PaymentAgainstUpdateBtn = Strings["PaymentAgainst.UpdateBtn"],
    PaymentAgainstCancelBtn = Strings["PaymentAgainst.CancelBtn"];

  const [paymentAgainstName, setPaymentAgainstName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [paymentDescrition, setPaymentDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleChangePaymentAgainstName = (value) => {
    setMessage(null);
    setPaymentAgainstName(value);
  };

  const handleChangePaymentAgainstDescription = (value) => {
    setPaymentDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: paymentAgainstName.value,
      description: paymentDescrition.value,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged =
    paymentAgainstName.value === initialValue.paymentAgainstName;
  // paymentDescrition.value === initialValue.paymentDescrition;

  let disable =
    paymentAgainstName.error ||
    // paymentDescrition.error ||
    paymentAgainstName.value === "" ||
    // paymentDescrition.value === "" ||
    paymentAgainstName.success === false ||
    isValueChanged;

  //edit
  let isValueChangededit =
    paymentAgainstName.value === initialValue.paymentAgainstName &&
    paymentDescrition.value === initialValue.paymentDescrition;

  let Editdisable =
    paymentAgainstName.error ||
    paymentDescrition.error ||
    paymentAgainstName.value === "" ||
    paymentDescrition.value === "" ||
    (paymentAgainstName.success === false &&
      paymentDescrition.success === false) ||
    isValueChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        label={PaymentAgainstLabel}
        placeholder={PaymentAgainstPlaceholder}
        Value={paymentAgainstName.value}
        onChangeText={handleChangePaymentAgainstName}
        Validate={true}
        CustomErrorLine={PaymentAgainstCustomError}
        starProps={true}
        editable
      />

      <FormTextInput
        type="textarea"
        label={PaymentAgainstDescriptionLabel}
        placeholder={PaymentAgainstDescriptionPlaceholder}
        Value={paymentDescrition.value}
        onChangeText={handleChangePaymentAgainstDescription}
        Validate={false}
        CustomErrorLine={PaymentAgainstDescriptionCustomError}
        multiline={true}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add"
              ? PaymentAgainstSaveBtn
              : PaymentAgainstUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={PaymentAgainstCancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default PaymentAgainstForm;
