import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { PropagateLoader } from "react-spinners";
import { logoRect } from "../../../assets";
import moment from "moment";
//   import { GetDeliveryReport } from "../../../axios";

const Poreconcilation = ({ reconcilationReport }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      height: "50%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-750px",
      fontSize: "8px",
    },
  });

  // const { id } = ;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState(reconcilationReport);

  React.useEffect(() => {
    //   GetDeliveryReport(id)
    //     .then((res) => {
    //       setReportData(res.data.data);
    //       setShows(true);
    //     })
    //     .catch((err) => {
    //       setShows(false);
    //     })
  }, []);

  return (
    <>
      {shows === false ? (
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  <Image style={styles.image} src={logoRect} />
                  <Text style={{ fontSize: "12px", display: "flex" }}>
                    Delivery Reconciliation Report - {reportData.project_name}
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Project Name
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData.project_name}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderBottom: "1px solid black",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Budget Number
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.budget_number}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Vendor Number
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.vendor_number}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Issuing Date
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {moment(reportData.issuing_data).format("DD-MM-YYYY")}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        // borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "33%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Delivery Comments
                      </Text>
                      <Text
                        style={{
                          width: "67%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.delivery_comment}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      backgroundColor: "#f2f2f2",
                      padding: "0px 3px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "8px",
                      }}
                    >
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          CTRL No.
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Sub Group
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Description
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          UOM
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Quantity
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          DN
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Delivered Quantity
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          // borderRight: "1px solid black",
                          padding: "3px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <Text
                          style={{
                            margin: "auto",
                            padding: "5px",
                          }}
                        >
                          Remaining Quantity
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      padding: "0px 3px",
                    }}
                  >
                    {reportData.delievery_reconcilitation.map((ele) => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            fontSize: "7px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.control_number}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.subgroup}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.description}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.uom}
                            </Text>
                          </View>

                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.quantity}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.delivery_note}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.delivery_quantity}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              height: "100%",
                              // borderRight: "1px solid black",
                              padding: "3px",
                            }}
                          >
                            <Text
                              style={{
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              {ele.remaining_quantity
                                ? ele.remaining_quantity
                                : "N/A"}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};

export default Poreconcilation;
