/* eslint-disable no-unused-vars */
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import { Collapse } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import {
  CreateUserManagement,
  CreateUserRoleMap,
  GetUserManagement,
  GetUserManagementById,
  GetUsers,
  UpdatedUserManagement,
  UpdateUserManagementActiveStatus,
  UpdateUserRoleMapping,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  DownloadIcon,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import { UserForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import Strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_USER_MANAGEMENT_STATUS_FUNCTION,
  CREATE_USER_MANAGEMENT_FUNCTION,
  UPDATE_USER_MANAGEMENT_FUNCTION,
} from "../../../utils/constants";
import "./SearchUserPage.css";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

export default function SearchUserPage() {
  const searchRoleTitle = Strings["UserManagement.SearchUserTypeHeader"],
    alertTitle = Strings["UserManagement.alertTitle"],
    alertMessage = Strings["UserManagement.alertMessage"],
    companyLabel = Strings["UserManagement.companyLabel"],
    username = Strings["UserManagement.TableHead.username"],
    emailAddress = Strings["UserManagement.TableHead.emailAddress"],
    phoneNumber = Strings["UserManagement.TableHead.phoneNumber"],
    userType = Strings["UserManagement.TableHead.userType"],
    activeStatus = Strings["UserManagement.TableHead.activeStatus"],
    action = Strings["UserManagement.TableHead.action"],
    searchTooltip = Strings["Tooltip.searchTooltip"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [editId, setEditId] = React.useState(null);
  const [alertBox, setAlertBox] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [errorMessage, setMessage] = React.useState(null);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [currentid, setcurrentid] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [userTypeOption, setUserTypeOption] = React.useState([]);
  const [optionDropdown, setOptionDropdown] = React.useState();
  const [optionCompany, setOptionCompany] = React.useState([]);
  const [companyTypeDropdown, setCompanyTypeDropdown] = React.useState();
  const [authId, setAuthId] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState();
  const [searchdropdown, setSearchdropdown] = React.useState();
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_USER_MANAGEMENT_FUNCTION)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_USER_MANAGEMENT_FUNCTION)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_USER_MANAGEMENT_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: Strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: Strings["User"],
      path: "master-configuration/searchuser",
    },
  ];

  const mapableData = [
    {
      dataKey: "name",
      title: username,
    },
    {
      dataKey: "email",
      title: emailAddress,
    },
    {
      dataKey: "phone",
      title: phoneNumber,
    },
    {
      dataKey: "clients.name",
      title: companyLabel,
    },
    {
      dataKey: "usertype.user_type",
      title: userType,
    },
    {
      dataKey: "activeStatus",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangeActiveStatus = (id, statuss) => {
    setAlertBox(true);
    setcurrentStatus(statuss);
    setcurrentid(id);
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateUserManagementActiveStatus({ status: 1 - currentStatus }, currentid)
      .then((res) => {
        setisLoader(false);
        // const result = rows.map((elem) => {
        //   if (elem.id === res.data.data.id) {
        //     return { ...elem, is_active: res.data.data.is_active };
        //   } else {
        //     return elem;
        //   }
        // });
        getAlldata();
        // setRows(result);
        setAlertBox(false);
      })
      .catch((e) => {
        setAlertBox(false);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const getAlldata = () => {
    setisLoader(true);
    const value =
      searchdropdown === "user_type" ? optionDropdown : companyTypeDropdown;
    const body = {
      [searchdropdown]: value ? value.toString() : null,
    };
    const searchList = open === true ? body : null;

    GetUserManagement(rowsPerPage, page, searchValue.value, searchList)
      .then((res) => {
        setisLoader(false);
        const data = res.data.data;
        setRows(data);
        setTotalData(res.data.total);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    GetUsers()
      .then((res) => {
        let tempData = [];
        let userTypeData = [];
        res.data.data.map((ele) => {
          userTypeData.push(ele.usertype);
        });

        res.data.data.map((ele) => {
          tempData.push(ele.clients);
        });
        const resultUser = userTypeData.filter(function (el) {
          return el != null;
        });

        const filteredUser = resultUser.reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        const result = tempData.filter(function (el) {
          return el != null;
        });
        const filteredArr = result.reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setUserTypeOption(filteredUser);
        setOptionCompany(filteredArr);
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    getAlldata();
  }, [rowsPerPage, page, open]);

  // let disableSearch =
  //   searchdropdown !== undefined &&
  //   (optionDropdown === undefined || companyTypeDropdown === undefined);

  const createUserManagement = (formData) => {
    setisLoader(true);

    CreateUserManagement(formData)
      .then((res) => {
        const roleData = {
          identity_id: res.data.data.userdata.authdata.id,
          role_id: formData.role_id,
        };

        CreateUserRoleMap(roleData)
          .then((res) => {
            setisLoader(false);
            if (res.status === 200) {
              swal({
                title: "Success!",
                text: "Created Succesfully",
                icon: "success",
              });
            }
          })
          .catch((e) => {
            setisLoader(false);
            setMessage(e.response.data.message);
          });
        setisLoader(false);
        getAlldata();
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateUserManagement = (formData) => {
    setisLoader(true);
    UpdatedUserManagement(formData, editId)
      .then((res) => {
        setisLoader(false);
        const roleData = {
          identity_id: authId,
          role_id: formData.role_id,
        };

        UpdateUserRoleMapping(roleData)
          .then((res) => {
            getAlldata();
            setisLoader(false);
            if (res.status === 200) {
              swal({
                title: "Success!",
                text: "Updated Sucessfully",
                icon: "success",
              });
              handleModalClose();
            }
          })
          .catch((e) => {
            setisLoader(false);
            setMessage(e.response.message);
          });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <UserForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeRoleData={
          clickedBtn === "add" ? createUserManagement : updateUserManagement
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleChangeEdit = (id) => {
    setisLoader(true);
    GetUserManagementById(id)
      .then((res) => {
        setisLoader(false);
        setAuthId(res.data.data.authdata.id);
        setCurrentType(res.data.data);
        openAddModal();
      })
      .catch((e) => {
        setisLoader(false);
      });

    const result = rows.find((row) => {
      return row.id === id;
    });
    setEditId(result.id);
    handleSelectBtn("edit");
  };

  const headers = [
    { label: "User Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone No.", key: "phone" },
    { label: "Company", key: "clients.name" },
    { label: "User Type", key: "usertype.user_type" },
    { label: "Status", key: "status" },
  ];
  const modifiedRows = rows.map((user) => ({
    ...user,
    status: user.is_active === 1 ? "Active" : "Inactive",
  }));

  console.log(rows);
  const csvReport = {
    data: modifiedRows,
    headers: headers,
    filename: "Users_Report.csv",
  };
  const handleClose = () => {
    setSearchdropdown("");
    setOptionDropdown("");
    setOpen(!open);
    // getAlldata();
    // window.location.reload();
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetUserManagement(rowsPerPage, page, searchData.value)
        .then((res) => {
          setisLoader(false);
          const data = res.data.data;
          setRows(data);
          setTotalData(res.data.total);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAlldata();
  };

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Furnish | Users</title>
      </Helmet>
      <div className="content-wrapper p-0">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div
          className="row mt-4 "
          style={{
            alignItems: "center",
          }}
        >
          <div className="col">
            <h3> {searchRoleTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          <div className="col-8">
            <div
              className="row"
              style={{
                alignItems: "center",
              }}
            >
              <div className="col-auto ms-auto text-end">
                <span
                  style={{
                    marginRight: "-10px",
                  }}
                >
                  <span
                    className="addButton"
                    style={{
                      marginRight: "0px",
                      display: open === true ? "none" : "inline",
                    }}
                  >
                    <IconButton>
                      <Tooltip title={searchTooltip}>
                        <SearchIcon
                          onClick={() => setOpen(!open)}
                          style={{
                            cursor: "pointer",
                            fill: colors.primary,
                            width: "20px",
                            height: "20px",
                            display: open === true ? "none" : "inline",
                          }}
                        />
                      </Tooltip>
                      {/* {open ? null : "Search"} */}
                      {/* </SearchIcon> */}
                    </IconButton>
                  </span>
                  {/* </div> */}
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      {/* <br /> */}
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="dropdown">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setSearchdropdown(e.target.value)
                              }
                              value={searchdropdown}
                            >
                              <option selected>Select</option>
                              <option value="user_type">User</option>
                              <option value="client">Company</option>
                            </select>
                          </div>
                        </div>
                        {searchdropdown === "user_type" ? (
                          <div className="col-sm-4">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setOptionDropdown(e.target.value)
                              }
                              value={optionDropdown}
                            >
                              <option selected>Select</option>
                              {userTypeOption.map((ele) => {
                                return (
                                  <option value={ele.id}>
                                    {ele.user_type}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : null}
                        {searchdropdown === "client" ? (
                          <div className="col-sm-4">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setCompanyTypeDropdown(e.target.value)
                              }
                              value={companyTypeDropdown}
                            >
                              <option selected>Select</option>
                              {optionCompany.map((ele) => {
                                return (
                                  <option value={ele.id}>{ele.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        ) : null}
                        <div className="col-sm-1 me-5">
                          <button
                            type="button"
                            // disabled={disableSearch}
                            className="btn btn-success"
                            onClick={getAlldata}
                          >
                            Search
                          </button>
                        </div>
                        <div className="col-sm-1">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </span>
              </div>

              <div className="col-auto">
                {isAdd && (
                  <span className="addButton" style={{ marginRight: "22px" }}>
                    <IconButton
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                )}
                <span className="downloadButton">
                  <CSVLink style={{ color: "white" }} {...csvReport}>
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </CSVLink>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}

          <div className="col-12">
            <div className="UserbudgetTable mb-5">
              <form onSubmit={handleSearchSubmit}>
                <FormTextInput
                  type="text"
                  className="searchBarUser mb-3"
                  placeholder={`Search `}
                  Value={searchValue.value}
                  onChangeText={handleSearch}
                  inputStyle={{ border: "none" }}
                  editable
                />
              </form>
              {rows.length !== 0 ? (
                <>
                  <SearchTable
                    isUpdate={isUpdate}
                    isStatus={isStatus}
                    placeholder={username}
                    data={rows}
                    mapableData={mapableData}
                    searchProp={["name"]}
                    handleChangeActiveStatus={handleChangeActiveStatus}
                    handleChangeEdit={handleChangeEdit}
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={CustomTablePaginationActions}
                  />
                </>
              ) : (
                <>
                  {isLoader ? (
                    <LoaderComponent />
                  ) : (
                    <NodataFound
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
