/* eslint-disable no-unused-vars */
import {
  Document,
  Link,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  pdf,
  PDFDownloadLink,
  renderToFile,
  usePDF,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { saveAs } from "file-saver";
import { GetAllActiveCountry, GetCurrency } from "../../../axios";
import { TermsAndCondition } from "./TermsandCondition";

const Download = () => {
  <div>
    <PDFDownloadLink fileName="somename.pdf">
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  </div>;
};

const PurchaseOrderReport = (props) => {
  const { poData } = props;
  const [countryDropDown, setCountryDropDown] = React.useState([]);
  const [countryName, setCountryName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      paddingBottom: "10px",
    },
    tcpage: {
      paddingTop: 40,
      paddingBottom: 80,
      paddingHorizontal: 40,
      fontSize: 10,
      lineHeight: 1.5,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "200px",
      height: "50px",
    },
    section: {
      margin: 10,
      padding: 10,
      alignItems: "flex-end",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    textLine: {
      fontSize: "6px",
      paddingBottom: "3px",
    },
    textLineBold: {
      fontSize: "6px",
      fontWeight: "900",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-500px",
      fontSize: "8px",
    },
    textSummery: {
      marginLeft: "25px",
      marginRight: "25px",
      fontSize: "8px",
      marginTop: "15px",
      textAlign: "justify",
    },
    textSummeryBold: {
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "5px",
      textAlign: "center",
      fontWeight: "900",
    },
    textSummeryBold0: {
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "5px",
      textAlign: "center",
      fontWeight: "900",
      textDecoration: "underline",
    },
    textSummeryBold1: {
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "5px",
      textAlign: "center",
      fontWeight: "900",
      borderBottom: "1px solid black",
    },
    pageNumbers: {
      position: "absolute",
      bottom: 5,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: "6",
      paddingTop: "20px",
      marginTop: "10px",
    },
    poNumbers: {
      position: "absolute",
      bottom: 5,
      left: 0,
      fontSize: "6",
      paddingTop: "20px",
     
      marginTop: "10px",
      marginLeft: "25px",
    },
    tcfooter: {
      position: "absolute",
      bottom: 10,
      left: 40,
      right: 40,
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 10,
    },
  });

  function calculateAdvancePaymentPercentage(data) {
    if (data.length === 0) {
      return 0;
    }

    const filteredItems = data.filter(
      (item) => item.purchase_order_payment_term_id === 1
    );
    if (filteredItems.length === 0) {
      return 0;
    }
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );

    // const totalPercentage = (filteredItems[0]?.value / totalValue) * 100;
    if (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0") {
      return 0;
    }

    return `${filteredItems[0]?.value} ${
      filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
    } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`;
  }
  function calculateProgressPaymentPercentage(data) {
    if (data.length === 0) {
      return 0;
    }
    const filteredItems = data?.filter(
      (item) => item.purchase_order_payment_term_id === 3
    );
    if (filteredItems.length === 0) {
      return "0";
    }

    // Sum up the 'value' for filtered items
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );

    // Calculate total percentage
    // const totalPercentage = (filteredItems[0]?.value / totalValue) * 100;

    if (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0") {
      return 0;
    }
    return `${filteredItems[0]?.value} ${
      filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
    } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`;
  }

  function calculateFinalPaymentPercentage(data) {
    if (data.length === 0) {
      return 0;
    }
    const filteredItems = data?.filter(
      (item) => item.purchase_order_payment_term_id === 4
    );
    console.log(filteredItems);
    if (filteredItems.length === 0) {
      return "0";
    }
    // Sum up the 'value' for filtered items
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );
    // console.log(totalValue);
    // Calculate total percentage
    // const totalPercentage =
    //   (parseInt(filteredItems[0]?.value) / parseInt(totalValue)) * 100;
    // console.log(totalPercentage);
    if (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0") {
      return 0;
    }
    return `${filteredItems[0]?.value} ${
      filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
    } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`;
  }

  function getCurrencyName(poData) {
    if (poData != undefined) {
      const value = poData?.item_details.filter((val) => {
        if (val.budgetitem?.budgetquantitydetails !== null) {
          return `${val.budgetitem?.budgetquantitydetails?.actual_currency?.name}`;
        }
      });
      return value[0].budgetitem?.budgetquantitydetails?.actual_currency?.code;
    }
  }
  const currencyName = getCurrencyName(poData);

  // const getAllActiveCountry = () => {
  //   setIsLoading(true);
  //   GetAllActiveCountry()
  //     .then((res) => {
  //       const data = res.data.data;
  //       const result = data.map((ele) => ({
  //         key: ele.id,
  //         value: ele.id,
  //         label: ele.name,
  //       }));
  //       setCountryDropDown(result);

  //       const matchedCountry = result.find(
  //         (country) => country.value === poData?.origin_type_id
  //       );
  //       if (matchedCountry) {
  //         setCountryName(matchedCountry.label);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setIsLoading(false);
  //     });
  // };

  const checkPOItemcode = (data) => {
    let flag = false;
    if (data != null) {
      data.map((ele, index) => {
        if (ele.budgetitem.item_code == null) {
          flag = false;
        } else {
          flag = true;
        }
      });
      return flag;
    }
  };

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`${poData.purchase_order_serial_number}`}>
        <Page size="A4" style={styles.page} wrap>
          <View>
            <View style={styles.section}>
              {/* <Image style={styles.image} src={logoRect} /> */}
              <Text style={{ fontSize: "15px", textAlign: "right" }}>
                {poData.purchase_order_status == "Cancelled"
                  ? "CANCELLED PURCHASE ORDER"
                  : "PURCHASE ORDER"}
              </Text>
            </View>
          </View>
          <View fixed={true}>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "20px",
                marginBottom: "15px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Vendor Name
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "6px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.vendor.name ? poData.vendor.name : ""}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Project Name
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "6px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.item_details[0].budgetitem.budget.project.name}
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Purchase order #
                </Text>
                <Text style={{ width: "10%", fontSize: "6px", padding: "5px" }}>
                  {poData.purchase_order_serial_number
                    ? poData.purchase_order_serial_number
                    : "N/A"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Vendor Address
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "6px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {`Fax: ${poData.vendor?.fax != null ? poData.vendor.fax : ""}
                   Phone: ${
                     poData.vendor?.phone != null ? poData.vendor.phone : ""
                   } 
                   City: ${
                     poData.vendor?.city != null ? poData.vendor.city : ""
                   },${
                    poData.vendor?.postcode != null
                      ? poData.vendor.postcode
                      : ""
                  },${poData.vendor?.state != null ? poData.vendor.state : ""}
                   Address: ${
                     poData.vendor.address ? poData.vendor.address : ""
                   }
                   `}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Budget Number
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "6px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.item_details[0].budgetitem.budget.serial_number}
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Issuing Date
                </Text>
                <Text style={{ width: "10%", fontSize: "6px", padding: "5px" }}>
                  {/* {moment(
                    poData.item_details[0].budgetitem.budget.budget_start_date.split(
                      " "
                    )[0]
                  ).format("Do MMM YYYY")} */}
                  {/* {
                    poData.item_details[0].budgetitem.budget.budget_start_date.split(
                      " "
                    )[0]
                  } */}
                  {moment(poData.created_on).format("Do MMM YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Shipping address
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "6px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.shipping_address ? poData.shipping_address : "N/A"}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Billing address
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "6px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.billing_address ? poData.billing_address : "N/A"}
                </Text>
                <View
                  style={{
                    width: "20%",
                    backgroundColor: "rgb(188, 204, 204)",
                    borderRight: "1px solid black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "8px",
                      borderBottom: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    Order Currency
                  </Text>

                  <Text
                    style={{
                      fontSize: "8px",
                      padding: "5px",
                    }}
                  >
                    Total (
                    {
                      // poData?.item_details[0]?.budgetitem?.budgetquantitydetails
                      //   ?.actual_currency?.name
                      currencyName
                    }
                    )
                  </Text>
                </View>

                <View
                  style={{
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "6px",
                      padding: "5px 5px 6.5px 5px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {
                      // poData?.item_details[1]?.budgetitem?.budgetquantitydetails
                      //   ?.actual_currency?.name
                      currencyName
                    }
                  </Text>
                  <CurrencyFormat
                    value={
                      poData.item_details &&
                      poData.item_details
                        .reduce((acc, curr) => {
                          return acc + parseFloat(curr.total_price);
                        }, 0)
                        .toFixed(2)
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => (
                      <Text
                        style={{
                          fontSize: "6px",
                          padding: "5px",
                        }}
                      >
                        {value}
                      </Text>
                    )}
                  />
                </View>
              </View>
            </View>
            {/* {poData.purchase_order_status == "Cancelled" &&
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Purchase Order Status
                </Text>
                <Text
                  style={{
                    width: "85%",
                    fontSize: "6px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.purchase_order_status ? poData.purchase_order_status : "N/A"}
                </Text>
               </View>
               </View>
          } */}
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  borderBottom: "1px solid black",
                  padding: "5px",
                }}
              >
                <Text style={{ marginLeft: "40%" }}>Special Instructions</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text>GENERAL</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text style={styles.textLine}>
                  This purchase order is in &nbsp;
                  <Text style={styles.textLineBold}>
                    {
                      poData?.item_details[0]?.budgetitem?.budgetquantitydetails
                        ?.actual_currency?.name
                    }
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  Freight/Delivery Terms:&nbsp;
                  <Text style={styles.textLineBold}>
                    {/* CIF - COST INSURANCE & FREIGHT. */}
                    {poData?.incoterm?.name}&nbsp; {poData?.origin_type_name}
                    {/* {countryName} */}
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  Original invoices for any advance payments are to be submitted
                  immediately, matching the line items & conditions of
                  this&nbsp;
                  <Text style={styles.textLineBold}>PO</Text>.
                </Text>
                {(calculateAdvancePaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 ||
                  calculateProgressPaymentPercentage(
                    poData.PurchaseOrderPaymentTermDetails
                  ) != 0 ||
                  calculateFinalPaymentPercentage(
                    poData.PurchaseOrderPaymentTermDetails
                  ) != 0) && (
                  <Text
                    style={{
                      fontSize: "6px",
                      textDecoration: "underline",
                      marginBottom: "3px",
                      marginTop: "3px",
                    }}
                  >
                    Payment Terms:
                  </Text>
                )}
                {calculateAdvancePaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 && (
                  <Text style={styles.textLine}>
                    ADVANCE PAYMENT: &nbsp;
                    <Text style={styles.textLineBold}>
                      {calculateAdvancePaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== 0 ||
                      calculateAdvancePaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== "null"
                        ? calculateAdvancePaymentPercentage(
                            poData.PurchaseOrderPaymentTermDetails
                          )
                        : ""}
                    </Text>
                  </Text>
                )}
                {calculateProgressPaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 && (
                  <Text style={styles.textLine}>
                    PROGRESS PAYMENT:&nbsp;
                    <Text style={styles.textLineBold}>
                      {calculateProgressPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== 0 ||
                      calculateProgressPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== null
                        ? `${calculateProgressPaymentPercentage(
                            poData.PurchaseOrderPaymentTermDetails
                          )} `
                        : ""}
                    </Text>
                  </Text>
                )}

                {calculateFinalPaymentPercentage(
                  poData.PurchaseOrderPaymentTermDetails
                ) != 0 && (
                  <Text style={styles.textLine}>
                    FINAL PAYMENT:&nbsp;
                    <Text style={styles.textLineBold}>
                      {calculateFinalPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== 0 ||
                      calculateFinalPaymentPercentage(
                        poData.PurchaseOrderPaymentTermDetails
                      ) !== null
                        ? `${calculateFinalPaymentPercentage(
                            poData.PurchaseOrderPaymentTermDetails
                          )} `
                        : ""}
                    </Text>
                  </Text>
                )}

                <Text style={styles.textLine}>
                  Mode of payment:
                  <Text style={styles.textLineBold}>
                    {poData?.mode_of_payment?.name}
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  This PO is to be read together with Vendor Special Conditions.
                </Text>
                <Text style={styles.textLine}>Go to </Text>
                <Link src="https://proqware.furnish-me.com/static/media/special-condition-to-the-purchase-order.pdf">
                  Special Conditions to the Purchase Order
                </Link>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text>SCHEDULING - From Receipt of this PO</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "8px",
                  padding: "5px",
                }}
              >
                {poData.PurchaseOrderLeadTime[0].days_for_finish_sample ? (
                  <>
                    <Text style={styles.textLine}>
                      Finish Sample:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                      ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_finish_sample
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_shop_drawing ? (
                  <>
                    <Text style={styles.textLine}>
                      Shop Drawing:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                      ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_shop_drawing
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_wash_test_sample ? (
                  <>
                    <Text style={styles.textLine}>
                      Wash Test Sample:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                      ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_wash_test_sample
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_sample_prototype ? (
                  <>
                    <Text style={styles.textLine}>
                      Sample Prototype:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                      ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                        {
                          poData?.PurchaseOrderLeadTime[0]
                            ?.days_for_sample_prototype
                        }
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_transit ? (
                  <>
                    <Text style={styles.textLine}>
                      Production Lead Time:&nbsp;
                      <Text style={styles.textLineBold}>
                        {/* {poData.item_details[0].quotation !== null
                      ? poData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                        {poData?.PurchaseOrderLeadTime[0]?.days_for_production}
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {poData.PurchaseOrderLeadTime[0].days_for_transit ? (
                  <>
                    <Text style={styles.textLine}>
                      Transit Lead Time:&nbsp;
                      <Text style={styles.textLineBold}>
                        {poData.PurchaseOrderLeadTime[0].days_for_transit}{" "}
                        day(s)
                      </Text>
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {/* <Text style={styles.textLine}>
                  Expected Arrival at&nbsp;
                  <Text style={styles.textLineBold}>PORT AT DESTINATION</Text>
                </Text> */}
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "8px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                <Text>DOCUMENTATION & LOGISTICS</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "8px",
                  padding: "5px",
                }}
              >
                <Text style={styles.textLine}>
                  Vendor should provide originals of:{" "}
                  {poData.PurchaseOrderRequiredDocument.map(
                    (item) => item.required_document[0].name
                  ).join(", ")}
                </Text>
                {/* <Text style={styles.textLineBold}>{poData.Location.name}</Text> */}
                <Text style={styles.textLine}>
                  All goods and packaging to be clearly labelled with control
                  numbers and descriptions with corresponding control numbers on
                  all documentation.
                </Text>
                <Text style={styles.textLine}>
                  Any packaging charges or other surcharges not quoted for
                  cannot be charged.
                </Text>
                {/* <Text
                  style={{
                    fontSize: "6px",
                    fontWeight: "900",
                    paddingTop: "5px",
                  }}
                >
                  DELIVERY WITHIN 10 WEEKS FROM THE DATE OF ORDER CONFIRMATION
                  AND ADVANCE PAYMENT.
                </Text> */}
                {/* <Text
                  style={{
                    fontSize: "6px",
                    fontWeight: "900",
                    paddingTop: "5px",
                  }}
                >
                  WIRE TRANSFER FEES TO BE COVERED BY BUYER.
                </Text> */}
                {poData.notes !== "" ? (
                  <>
                    <Text
                      style={{
                        fontSize: "6px",
                        fontWeight: "900",
                        // paddingTop: "5px",
                      }}
                    >
                      {/* PORT OF DESTINATION: JEDDAH */}

                      {`*${poData.notes}*`}
                    </Text>
                  </>
                ) : (
                  <></>
                )}
              </View>
              {poData.revision_notes != "" && (
                <View style={{ width: "100%" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: "rgb(188, 204, 204)",
                      fontSize: "8px",
                      padding: "5px",
                      borderBottom: "1px solid black",
                      borderTop: "1px solid black",
                    }}
                  >
                    <Text>REASONS FOR REVISION</Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontSize: "8px",
                      padding: "5px",
                    }}
                  >
                    {/* <Text style={styles.textLineBold}>{poData.Location.name}</Text> */}
                    <Text style={styles.textLine}>{poData.revision_notes}</Text>
                  </View>
                </View>
              )}
            </View>
          </View>

          <View style={{ marginVertical: "20px" }}>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                // border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                }}
                fixed
              >
                <Text
                  style={{
                    width: "6%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    borderLeft: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  S. No.
                </Text>
                <Text
                  style={{
                    width: "12%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Ctrl No.
                </Text>
                <Text
                  style={{
                    width: "10%",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Sub-Group
                </Text>
                {checkPOItemcode(poData.item_details) && (
                  <Text
                    style={{
                      width: "13%",
                      fontSize: "8px",
                      padding: "5px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    Item Code
                  </Text>
                )}

                <Text
                  style={{
                    width: `${
                      checkPOItemcode(poData.item_details) ? "25%" : "38%"
                    }`,
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Description
                </Text>
                <Text
                  style={{
                    width: "6%",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  UOM
                </Text>
                <Text
                  style={{
                    width: "8%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Quantity
                </Text>
                <Text
                  style={{
                    width: "10%",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Unit Price
                </Text>
                <Text
                  style={{
                    width: "10%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  Total
                </Text>
              </View>
              {poData.item_details &&
                poData.item_details.map((ele, index) => {
                  return (
                    <>
                      <View
                        style={{
                          fontSize: "8px",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          borderBottom: "1px solid black",
                          marginTop: "0px",
                        }}
                      >
                        <View
                          break
                          style={{
                            width: "6%",
                            height: "100%",
                            borderRight: "1px solid black",
                            borderLeft: "1px solid black",
                          }}
                        >
                          <Text
                            break
                            style={{
                              margin: "auto",
                              padding: "5px",
                              fontSize: "8px",
                            }}
                          >
                            {index + 1}
                          </Text>
                        </View>
                        <View
                          break
                          style={{
                            width: "12%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            break
                            style={{
                              margin: "auto",
                              padding: "5px",
                              fontSize: "8px",
                            }}
                          >
                            {ele.budgetitem.control_number}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            break
                            style={{
                              margin: "auto",
                              padding: "5px",
                              fontSize: "8px",
                            }}
                          >
                            {ele.budgetitem.itemsubgroup.name}
                          </Text>
                        </View>
                        {checkPOItemcode(poData.item_details) === true && (
                          <View
                            style={{
                              width: "13%",
                              height: "100%",
                              borderRight: "1px solid black",
                            }}
                          >
                            <Text
                              break
                              style={{
                                margin: "auto",
                                padding: "5px",
                                fontSize: "8px",
                              }}
                            >
                              {ele.budgetitem.item_code != null
                                ? ele.budgetitem.item_code
                                : "NA"}
                            </Text>
                          </View>
                        )}

                        <View
                          style={{
                            width: `${
                              checkPOItemcode(poData.item_details)
                                ? "25%"
                                : "38%"
                            }`,
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            break
                            style={{
                              margin: "auto",
                              padding: "5px",
                              fontSize: "8px",
                              textAlign: "left",
                            }}
                             breakInside="auto"
                          >
                            {`
    ${ele.budgetitem.name != null ? ele.budgetitem.name : ""}
    ${
      ele.budgetitem.short_description != null
        ? ele.budgetitem.short_description
        : ""
    }
    ${ele.budgetitem.description != null ? ele.budgetitem.description : ""}
    ${
      ele.budgetitem.budget_modeldetails?.size != null
        ? ele.budgetitem.budget_modeldetails.size
        : ""
    }
    ${
      ele.budgetitem.budget_modeldetails?.brand != null
        ? ele.budgetitem.budget_modeldetails.brand
        : ""
    }
  `}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "6%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            break
                            style={{ margin: "auto", fontSize: "8px" }}
                          >
                            {ele.actual_uom_Name}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "8%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            break
                            style={{ margin: "auto", fontSize: "8px" }}
                          >
                            {ele.actual_quantity}
                          </Text>
                        </View>
                        {/* <CurrencyFormat
                          value={ele.unit_price ? ele.unit_price : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <Text
                              style={{
                                width: "15%",
                                fontSize: "6px",
                                padding: "5px",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {value}
                            </Text>
                          )}
                        /> */}
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <CurrencyFormat
                            value={ele.unit_price ? ele.unit_price : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <Text style={{ margin: "auto", fontSize: "8px" }}>
                                {value}
                              </Text>
                            )}
                          />
                          {/* <Text
                            style={{ margin: "auto" }}
                          >
                            {ele.total_price}
                          </Text> */}
                        </View>
                        {/* <Text
                          style={{
                            width: "15%",
                            fontSize: "6px",
                            padding: "5px",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {ele.unit_price}
                        </Text> */}
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <CurrencyFormat
                            value={ele.total_price ? ele.total_price : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <Text style={{ margin: "auto", fontSize: "8px" }}>
                                {value}
                              </Text>
                            )}
                          />
                          {/* <Text
                            style={{ margin: "auto" }}
                          >
                            {ele.total_price}
                          </Text> */}
                        </View>
                      </View>
                    </>
                  );
                })}
            </View>

            <View
            break
              style={{
                display: "flex",
                flexDirection: "row",
                border: "1px solid black",
                marginTop: "8px",
                marginLeft: "25px",
                marginRight: "25px",
                borderRadius: "5px",
              }}
            >
              <Text
                style={{
                  width: "85%",
                  fontSize: "6px",
                  borderRight: "1px solid black",
                  padding: "5px",
                  textAlign: "right",
                }}
              >
                Total (
                {
                  // poData?.item_details[0]?.budgetitem?.budgetquantitydetails
                  //   ?.actual_currency?.name
                  currencyName
                }
                )
              </Text>
              <CurrencyFormat
                value={
                  poData.item_details &&
                  poData.item_details
                    .reduce((acc, curr) => {
                      return acc + parseFloat(curr.total_price);
                    }, 0)
                    .toFixed(2)
                }
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                  <Text
                    style={{
                      width: "15%",
                      fontSize: "6px",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    {value}
                  </Text>
                )}
              />
              {/* <Text
                style={{
                  width: "15%",
                  fontSize: "6px",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                {poData.item_details &&
                  poData.item_details
                    .reduce((acc, curr) => {
                      return acc + parseInt(curr.total_price);
                    }, 0)
                    .toFixed(2)}
              </Text> */}
            </View>

            <Text break style={styles.textSummery}>
              This Purchase Order ("PO") is prepared by FURNISH Hospitality
              General Trading LLC ("Agent") on behalf of the Client, the details
              of which are set out above ("Project Name"/"Billing Address") and
              as agent only for the Client. It consists of this cover sheet and
              the attached special conditions, the content of which the Vendor
              acknowledges and agrees to by signing this PO. All purchases are
              being made solely for the account of the Client. The Client shall
              bear the sole responsibility for all payments. All correspondence
              and other business pertaining to acceptance of this PO and the
              terms set herein, including, without limitation, credit approval,
              terms, and payment shall be between the Vendor and the Client and
              the Agent assumes no liability therefor, and makes no
              representations to the Vendor or the Client express or implied.
            </Text>

            <View
              break
              style={{
                flexDirection: "column",
                flexWrap: "wrap",
                border: "1px solid black",
                marginVertical: "20px",
                borderRadius: "5px",
                marginRight: "25px",
                marginLeft: "25px",
                position: "relative",
              }}
            >
              <Text
                style={{
                  fontSize: "8px", // You can adjust the font size as needed
                  textAlign: "right",
                  position: "absolute", // Use absolute positioning
                  top: "-15px", // Adjust top position as needed
                  left: "0px", // Adjust left position as needed
                }}
              >
                CHECKED BY FURNISH HOSPITALITY
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "30px",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "40%",
                    height: "30px",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "30%",
                    height: "30px",
                    fontSize: "8px",
                    padding: "5px",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "column",
                flexWrap: "wrap",
                border: "1px solid black",
                marginVertical: "20px",
                borderRadius: "5px",
                marginRight: "25px",
                marginLeft: "25px",
                position: "relative",
              }}
            >
              <Text
                style={{
                  fontSize: "10px", // You can adjust the font size as needed
                  textAlign: "left",
                  position: "absolute", // Use absolute positioning
                  top: "-15px", // Adjust top position as needed
                  left: "0px", // Adjust left position as needed
                }}
              >
                SIGNED ON BEHALF OF CLIENT
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "30px",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  {poData.purchase_order_status === "Signed By Client" &&
                    (poData.PurchaseOrderApprovals.length > 0 &&
                    poData.PurchaseOrderApprovals[0].action_taken_on !== null &&
                    poData.PurchaseOrderApprovals[0].clientcontacts
                      ? poData.PurchaseOrderApprovals[0].clientcontacts.name
                      : "")}
                </Text>
                <Text
                  style={{
                    width: "40%",
                    height: "30px",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "30%",
                    height: "30px",
                    fontSize: "8px",
                    padding: "5px",
                  }}
                >
                  {poData.purchase_order_status === "Signed By Client" &&
                    (poData.PurchaseOrderApprovals.length > 0 &&
                    poData.PurchaseOrderApprovals[0].action_taken_on !== null
                      ? moment(
                          poData.PurchaseOrderApprovals[0].action_taken_on
                        ).format("Do MMM YYYY")
                      : "")}
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "column",
                flexWrap: "wrap",
                border: "1px solid black",
                marginVertical: "20px",
                borderRadius: "5px",
                marginRight: "25px",
                marginLeft: "25px",
                position: "relative",
              }}
            >
              <Text
                style={{
                  fontSize: "10px", // You can adjust the font size as needed
                  textAlign: "left",
                  position: "absolute", // Use absolute positioning
                  top: "-15px", // Adjust top position as needed
                  left: "0px", // Adjust left position as needed
                }}
              >
                SIGNED ON BEHALF OF VENDOR
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "8px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "30px",
                    fontSize: "8px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "40%",
                    height: "30px",
                    fontSize: "8px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "30%",
                    height: "30px",
                    fontSize: "8px",
                    padding: "5px",
                  }}
                ></Text>
              </View>
            </View>
            {poData.purchase_order_status === "Signed By Client" && (
              <View
                break
                style={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  border: "1px solid black",
                  marginVertical: "20px",
                  borderRadius: "5px",
                  marginRight: "25px",
                  marginLeft: "25px",
                  position: "relative",
                }}
              >
                <Text style={styles.textSummeryBold0}>
                  {`Approved on behalf of ${
                    poData.PurchaseOrderApprovals.length > 0 &&
                    poData.PurchaseOrderApprovals[0].action_taken_on !== null &&
                    poData.PurchaseOrderApprovals[0].clientcontacts
                      ? poData.PurchaseOrderApprovals[0].clientcontacts
                          .client_id?.name
                      : ""
                  }`}
                </Text>
                <Text style={styles.textSummeryBold1}>
                  *** Please quote the above order number on all delivery notes
                  and invoices pertaining to this Purchase Order ***
                </Text>
                <Text style={styles.textSummeryBold}>
                  This is a system-generated Purchase Order, hence does not
                  require any signature.
                </Text>
              </View>
            )}
          </View>

          {/* supplier Inials signature */}
          <Text style={styles.poNumbers} fixed>
            {`Purchase order #: ${
              poData.purchase_order_serial_number
                ? poData.purchase_order_serial_number
                : "N/A"
            }`}
          </Text>
         

          <Text
            style={styles.pageNumbers}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
           <View
            fixed
            style={{
              position: "absolute",
              right: 0,
              bottom: "5",
              marginLeft: "25px",
              marginRight: "25px",
              paddingTop: "20px",
              marginTop: "10px",
              
            }}
          >
            <Text
              style={{
                fontSize: "5px",
                textAlign: "right",
                borderTop: "1px solid black",
                marginTop: "5px",
                paddingHorizontal: "10px",
                paddingTop: "2px",
              }}
            >
              Supplier Initials
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.tcpage} wrap>
          <TermsAndCondition />

          {/* supplier Inials signature */}
          <View
            fixed
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: 0,
              bottom: "30",
              marginLeft: "40px",
              marginRight: "40px",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                textAlign: "right",
                borderTop: "1px solid black",
                marginTop: "5px",
                paddingHorizontal: "10px",
                paddingTop: "2px",
              }}
            >
              Supplier Initials
            </Text>
          </View>

          <View style={styles.tcfooter} fixed>
            <Text>FURNISH Hospitality General Trading LLC</Text>
            <Text>Special Conditions to the Purchase Order </Text>
          </View>

          <Text
            style={styles.pageNumbers}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PurchaseOrderReport;
