import React from "react";
import { Helmet } from "react-helmet";
import {
    GetAllPurchaseOrderByvendor,
} from "../../../axios";
import {
    BreadCrumbs, StatusTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import "../SearchBudgetItem/BudgetItem.css";

const DeliveryStatus = () => {
    const
        Furnish = Strings["Title.Furnish"],
        RFQLabel = Strings["Title.RFQ"];

    const [isLoader, setisLoader] = React.useState(false);
    const [allData, setAllData] = React.useState([]);

    const mapableDataRFQ = [
        {
            dataKey: "poSerialNo",
            title: "PO Serial No",
        },
        {
            dataKey: "purchaseQty",
            title: "Total Qty",
        },
        {
            dataKey: "deliveryQty",
            title: "Delivered Qty",
        },
        {
            dataKey: "is_active",
            title: "Status",
            isStatus: true,
        },
    ];

    const paths = [
        {
            name: strings["DashBoardText"],
            path: "",
        },
        {
            name: "DeliveryStatus",
        },
    ];


    const paymentStatus = () => {
        const vendorId = JSON.parse(localStorage.getItem("vendor"));
        setisLoader(true);
        GetAllPurchaseOrderByvendor(vendorId)
            .then((res) => {
                setisLoader(false);
                const result = res.data.data.DeliveryCount.map((ele) => ({
                    poSerialNo: ele.purchase_order_seril_number,
                    purchaseQty: ele.purchaseorderquantity,
                    deliveryQty: ele.totaldelivered,
                    is_active: ele.totalremaining > 0 ? 0 : 1
                }))
                setAllData(result);
            })
            .catch(() => {
                setisLoader(false);
            });
    };

    React.useEffect(() => {
        paymentStatus();
    }, []);

    return (
        <div className="container mt-4">
            <Helmet>
                <title>{Furnish} | {RFQLabel} </title>
            </Helmet>
            {isLoader ? <LoaderComponent /> : null}

            <div className="mb-2">
                <h3 className=""> {"Delivery Status"} </h3>
                <BreadCrumbs pathList={paths} />
            </div>
            <div
                className="RfqbudgetTable"
                style={{
                    marginBottom: "40px",
                }}
            >
                <StatusTable
                    data={allData}
                    mapableData={mapableDataRFQ}
                />
            </div>
        </div>
    );
};

export default DeliveryStatus;