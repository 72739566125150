/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const UserTypeForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;
  const createUserTypeTitle = Strings["UserType.CreateUserTypeHeader"],
    editUserTypeTitle = Strings["UserType.EditUserTypeHeader"],
    userTypeNameLabel = Strings["UserType.CreateUserTypeNameLable"],
    userTypeNamePlaceHolder = Strings["UserType.CreateUserTypeNamePlaceholder"],
    userTypeNameError = Strings["UserType.CreateUserTypeNamecustomlineerror"],
    userTypeDescriptionTitle =
      Strings["UserType.CreateUserTypeDescriptionLable"],
    userTypeDescriptionPlaceHolder =
      Strings["UserType.CreateUserTypeDescriptionPlaceholder"],
    userTypeDescriptionError =
      Strings["UserType.CreateUserTypeDescriptionCustomLineError"],
    userTypeSaveButton = Strings["UserType.EditUsersaveButton"],
    userTypeUpdateButton = Strings["UserType.EditUserUpdateButton"],
    userTypeCancelButton = Strings["UserType.EditUserCancelButton"];

  const [userType, setUserType] = useState({
    value: initialValue.user_type ? initialValue.user_type : "",
    error: false,
    success: false,
  });

  const [userTypeDescription, setUserTypeDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleChangeRole = (value) => {
    setMessage(null);
    setUserType(value);
  };

  const handleChangeDescription = (value) => {
    setUserTypeDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      user_type: userType.value,
      description: userTypeDescription.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged = userType.value === initialValue.user_type;
  // userTypeDescription.value === initialValue.description;
  let disable =
    userType.error ||
    // userTypeDescription.error ||
    userType.value === "" ||
    // userTypeDescription.value === "" ||
    userType.success === false ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangedEdit =
    userType.value === initialValue.user_type &&
    userTypeDescription.value === initialValue.description;
  let editdisable =
    userType.error ||
    userTypeDescription.error ||
    userType.value === "" ||
    userTypeDescription.value === "" ||
    (userType.success === false && userTypeDescription.success === false) ||
    isValueNOTChangedEdit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createUserTypeTitle : editUserTypeTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        label={userTypeNameLabel}
        starProps={true}
        placeholder={userTypeNamePlaceHolder}
        Value={userType.value}
        onChangeText={handleChangeRole}
        Validate={true}
        CustomErrorLine={userTypeNameError}
        editable
        maxLength={50}
      />
      <FormTextInput
        type="alpha"
        label={userTypeDescriptionTitle}
        placeholder={userTypeDescriptionPlaceHolder}
        Value={userTypeDescription.value}
        onChangeText={handleChangeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        multiline={true}
        CustomErrorLine={userTypeDescriptionError}
        editable
        maxLength={50}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? userTypeSaveButton : userTypeUpdateButton
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : false}
          onClick={handleSaveData}
        />
        <Button
          buttonName={userTypeCancelButton}
          onClick={() => {
            setMessage(null);
            handleModalClose();
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default UserTypeForm;
