/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Card
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateClientContact,
  GetClientContact,
  UpdateClientContactActiveStatus,
  UpdatedClientContact,
} from "../../../axios";
import {
  AlertMessage,
  BreadCrumbs,
  Button,
  IconButton,
  Modal,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { ClientContactForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: "profile",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Profile Pic",
  // },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "Active Status",
    numeric: false,
    disablePadding: false,
    label: "Active Status",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function SearchClientContactPage() {
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const location = useLocation();

  const userData = location?.state
    ? location?.state
    : location.query
      ? JSON.parse(location.query.params)
      : null;

  
  let history = useHistory();
  const fetchId = useParams();
  
  const paramsId = isDecoded(fetchId.client);
  const clientid = parseInt(paramsId);

  const encodeClientId = isEncoded(clientid);

  // const handleView = (id) => {
  //   const result = rows.find((row) => {
  //     return row.id === id;
  //   });
  // };
  const addButton = Strings["FunctionManagement.seacrhFunctionAddButton"],
    alertMessage = Strings["ItemGroup.AlertMessage"],
    alertTitle = Strings["ItemGroup.AlertTitle"];
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentClientContact, setCurrentClientContact] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [branchOptions, setBranchOptions] = React.useState([]);
  const [statusCode, setStatusCode] = React.useState();

  const paths = [
    {
      name: Strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: "Clients",
      path: "master-configuration/searchclient",
    },
    {
      name: strings["ClientProfile"],
      path: "",
    },
  ];

  const handleChangeActiveStatus = (id) => {
    const result = rows.find((elem) => {
      return elem.id === id;
    });

    setStatusCode(result.is_active);
    setStatusChangeId(id);
    setAlertBox(true);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateClientContactActiveStatus({ status: 1 - statusCode }, statusChangeId)
      .then((res) => {
        setisLoader(false);
        const activestatusresult = rows.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              is_active: res.data.data.is_active,
            };
          } else {
            return ele;
          }
        });
        setRows(activestatusresult);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const createClientContactForm = (formData) => {
    setisLoader(true);
    CreateClientContact(formData)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          getAllClient();
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  const updateClientContactForm = (formData) => {
    let id = currentClientContact.id;
    setisLoader(true);
    UpdatedClientContact(formData, id)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const results = rows.map((ele) => {
            if (ele.id === res.data.data.id) {
              return {
                ...ele,
                name: res.data.data.name,
                client_id: res.data.data.client_id,
                designation: res.data.data.designation,
                telephone: res.data.data.telephone,
                mobile: res.data.data.mobile,
                email: res.data.data.email,
                profile_picture: res.data.data.profile_picture,
              };
            } else {
              return ele;
            }
          });

          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(results);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const getAllClient = () => {
    setisLoader(true);
    GetClientContact()
      .then((res) => {
        setisLoader(false);
        setRows(res.data.data);
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response?.data.message);
      });
  };
  // React.useEffect(() => {
  //   GetAllActiveClientContact()
  //     .then((res) => {
  //       const allBranch = res.data.data;
  //       const result = allBranch.map((ele) => ({
  //         key: ele.id,
  //         value: ele.id,
  //         label: ele.name,
  //       }));
  //       setBranchOptions(result);
  //     })
  //     .catch((e) => {
  //       // setisLoader(false);
  //       // setMessage(e.response.data.message);
  //     });
  // }, []);
  React.useEffect(() => {
    getAllClient();
  }, []);

  const selectModal = () => {
    return (
      <ClientContactForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add"
            ? createClientContactForm
            : updateClientContactForm
        }
        initialValue={clickedBtn === "add" ? {} : currentClientContact}
        actionType={clickedBtn}
        handleModalClose={handleModalClose}
      // client={client}
      />
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      {isLoader ? (
        <LoaderComponent />
      ) : (
        <div className="container">
          <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
            {selectModal()}
          </Modal>
          <div
            className="row "
            style={{
              alignItems: "center",
            }}
          >
            {/* <div className="col-2"></div> */}
            <div className="col mt-3">
              <h3> {"Client Profile"} </h3>
              <BreadCrumbs pathList={paths} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                marginBottom: 20,
                marginLeft: "85%",
                float: "right",
              }}
            >
              {alertBox && (
                <AlertMessage
                  title={alertTitle}
                  message={alertMessage}
                  onChangeCancel={handleChangeCancel}
                  onChangeSucess={handleChangeSuccess}
                />
              )}
              <Button
                buttonName={"Contacts"}
                onClick={() => {
                  history.push({
                    pathname: `clientcontact/${encodeClientId}`,
                    state: userData,
                  });
                }}
                icon={<RiAccountCircleFill style={{ fontSize: "1.2rem" }} />}
              />
            </div>
            <div className="row d-flex flex-row justify-content-center mt-5">
              <div className="col-12">
              {rows ? (             <Card>
                                    <>
                                      <div style={{"margin": "10px", "padding": "10px"}}>
                                        <div className="row">
                                          <div className="col-12">
                                      <h6
                                        className="profiledataname mb-1"
                                      >
                                        {userData.name
                                          ? userData?.name.slice(0, 30)
                                          : "NA"}
                                      </h6>
                                      </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-4">
                                      <h6 className="mb-3">
                                        {userData.website
                                          ? userData?.website
                                          : "NA"}
                                      </h6>
                                      </div>
                                      <div className="col-4">
                                      <h6  className="mb-3">
                                        {userData.phone
                                          ? `Phone: ${userData?.phone}`
                                          : "NA"}
                                      </h6>
                                      </div>
                                      <div className="col-4">
                                      <h6  className="mb-3">
                                        {userData.fax ? `Fax: ${userData?.fax}` : "NA"}
                                      </h6>
                                      </div>
                                      <div className="row">
                                      <div className="col-8">
                                      <h6>
                                        {userData?.address ? `Address: ${userData?.address
                                          .slice(0, 20)
                                          .concat("...")}`: "NA"}
                                        
                                      </h6>
                                      </div>
                                      <div className="col-4">
                                        <h6>
                                      {userData?.city ? `City: ${userData.city}` : "NA"}
                                      </h6>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                                    </>

                                    </Card>
                                  ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SearchClientContactPage;
