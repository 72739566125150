/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ForgetPassword } from "../../../axios";
import { Button, FormTextInput } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ForgotPasswordForm = (props) => {
  const { setMessage, errorMessage, userEmail } = props;
  const [loader, setLoader] = React.useState(false);

  const [emailAddress, setEmailAddress] = useState({
    value: userEmail ? userEmail : "",
    error: false,
    success: false,
  });
  const [token, setToken] = useState();
  const history = useHistory();
  const handleChangeEmail = (value) => {
    setMessage(null);
    setEmailAddress(value);
  };
  const handleVerifyOtp = () => {
    setLoader(true);
    let val = {
      email: emailAddress.value,
    };
    ForgetPassword(val)
      .then((res) => {
        setLoader(false);
        setToken(res.data.data.token);
        history.push("/confirmforgotpassword");
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const handleChangeCancel = () => {
    setMessage(null);
    history.push("/");
  };

  let disable =
    emailAddress.error ||
    emailAddress.value === "" ||
    emailAddress.success === false;

  const EmailAddresLabel =
      Strings["Authentication.forgotpasswordEmailAddressLabel"],
    EmailAddressPlaceHolder =
      Strings["Authentication.forgotpasswordEmailplaceHolder"],
    EmailCustomErrorLine =
      Strings["Authentication.forgotopasswordemailCustomError"],
    AlreadyHaveotp = Strings["Authentication.forgotpasswordAlreadyotpButton"],
    cancelButton = Strings["Authentication.forgotpasswordCancelButton"],
    sendOtpButton = Strings["Authentication.forgotpasswordSendotpButton"];
  return (
    <>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {loader ? <LoaderComponent /> : null}
      <FormTextInput
        type="email"
        label={EmailAddresLabel}
        placeholder={EmailAddressPlaceHolder}
        Value={emailAddress.value}
        onChangeText={handleChangeEmail}
        Validate={true}
        CustomErrorLine={EmailCustomErrorLine}
        editable={true}
      />
      <div className="d-flex justify-content-end my-3">
        <Link to="/confirmforgotpassword" className="link__forgotpassword">
          {AlreadyHaveotp}
        </Link>
      </div>

      <div
        style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}
      >
        <Button
          buttonName={sendOtpButton}
          onClick={handleVerifyOtp}
          disable={disable}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ marginLeft: "20px", backgroundColor: colors.darkgrey }}
          onClick={handleChangeCancel}
        />
      </div>
    </>
  );
};

export default ForgotPasswordForm;
