import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  AssetRegister,
  ClientContactPage,
  ClientProfilePage,
  ComingSoonPage,
  Dashboard,
  DeliveryStatus,
  Documents,
  ItemGroupPage,
  MasterConfigurationPage,
  MasterDetails,
  PaymentRequestSummery,
  PaymentStatus,
  ProductAndServicesPage,
  PurchaseOrderStatus,
  SearchAccessAndConsideration,
  SearchAllProjects,
  SearchBudgetItem,
  SearchBudgetSchedulePage,
  SearchBudgetScopePage,
  SearchBudgetScopeToItemCategoryMapping,
  SearchCancelledProjectPage,
  SearchCategory,
  SearchClientContactPage,
  SearchClientPage,
  SearchClientTypePage,
  SearchCommoditiesPage,
  SearchCompletedProjectPage,
  SearchCostAnalysis,
  SearchCountryPage,
  SearchCurrencyPage,
  SearchDeliveryNote,
  SearchDocument,
  SearchDoumentRfi,
  SearchDraftProject,
  SearchEstabishmentToItemCategoryMappingPage,
  SearchEstablishmentPage,
  SearchFunctionPage,
  SearchIncotermPage,
  SearchInvoice,
  SearchInvoicePayment,
  SearchItemCategoryPage,
  SearchItemCategorySubCategoryMapping,
  SearchItemGroupPage,
  SearchItemSchedulePage,
  SearchItemSubCategory,
  SearchItemSubGroup,
  SearchItemSubmittalPage,
  SearchLocationPage,
  SearchMappingRoleFunctionPage,
  SearchModeOfPaymentPage,
  SearchModeOfShipmentPage,
  SearchModeOfTransportationPage,
  SearchModulePage,
  SearchOngoingProjectPage,
  SearchOparatorPage,
  SearchPaymentAgainstPage,
  SearchPaymentProgressPage,
  SearchPaymentTermsPage,
  SearchProjectBudgetPage,
  SearchProjectPage,
  SearchProjectRole,
  SearchProjectScopePage,
  SearchProjectSpecificUser,
  SearchPublicAreaPage,
  SearchPurchaseOrdersPage,
  SearchQuotation,
  SearchReportPage,
  SearchRequestForInformationPage,
  SearchRequestForQuotation,
  SearchRequestforInformationPage,
  SearchRequiredDocumentPage,
  SearchRolePage,
  SearchRoomTypePage,
  SearchScheduleStepsPage,
  SearchStagePage,
  SearchStatusPage,
  SearchSubMitalPage,
  SearchUOMPage,
  SearchUserPage,
  SearchUserRoleMappingPage,
  SearchUserTypePage,
  SearchVendorAccessPage,
  SearchVendorBranchPage,
  SearchVendorCategoryPage,
  SearchVendorCategoryToProductMapping,
  SearchVendorCategoryToSubCategoryMappingPage,
  SearchVendorCompanyTypePage,
  SearchVendorCompanytoCategoryMapping,
  SearchVendorContactPage,
  SearchVendorPage,
  SearchVendorSubCategoryPage,
  Test,
  Test2,
  UserProfilePage,
  VendorProfilePage,
  VendorQuotation,
  VendorRfi,
  VendorRfq,
} from "../containers";
import AllMastersPage from "../containers/Developer/AllMasterPage";
import SearchGroupToSubGroupMapping from "../containers/Masters/SearchGroupToSubGroupMappingPage/SearchGroupToSubGroupMapping";
import SearchScopeToItemGroupMapping from "../containers/Masters/SearchScopeToGroupMappingPage/SearchScopeToItemGroupMapping";
import SearchPage from "../containers/Masters/Test/SearchPage";
import GroupReport from "../containers/ProjectAndBudget/GroupReport/GroupReport";
import CategoryReport from "../containers/ProjectAndBudget/CategoryReport/CategoryReport"
import PaymentSummary from "../containers/ProjectAndBudget/PaymentSummary/PaymentSummary";
import SearchRfq from "../containers/ProjectAndBudget/SearchDocument/SearchRfq";
import { ChangePasswordForm, CreateMaster } from "../forms";
import { isAuthorizedFunc } from "../utils";
import {
  CANCEL_PROJECT,
  COMPLETED_PROJECT,
  CREATE_COST_ANALYSIS,
  GET_ACCESS_CONSIDERATION,
  GET_ALL_OPERATOR,
  GET_BUDGET,
  GET_BUDGET_ITEM,
  GET_BUDGET_ITEM_CATEGORY_MAPPING,
  GET_BUDGET_SCOPE,
  GET_CATEGORY_PRODUCT_MAPPING_FUNCTION,
  GET_CLIENT,
  GET_CLIENT_TYPE_FUNCTION,
  GET_COMMODITY_FUNCTION,
  GET_COUNTRY_FUNCTION,
  GET_CURRENCY_FUNCTION,
  GET_ESTABLISHMENT_ITEM_CATEGORY,
  GET_ESTABLISHMENT_SCOPE,
  GET_FUNCTIONALITY,
  GET_INCOTERM,
  GET_INVOICE,
  GET_ITEM_CATEGORY,
  GET_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING,
  GET_ITEM_GROUP,
  GET_ITEM_SUB_CATEGORY,
  GET_ITEM_SUB_GROUP,
  GET_LOCATION,
  GET_MODE_OF_SHIPMENT,
  GET_MODE_OF_TRANSPORTATION,
  GET_MODULE,
  GET_MOP_FUNCTION,
  GET_PAYMENT_AGAINST,
  GET_PAYMENT_PROGRESS,
  GET_PAYMENT_TERMS,
  GET_PRODUCT_AND_SERVICES,
  GET_PROJECT,
  GET_PROJECT_ROLE,
  GET_PROJECT_SCOPE,
  GET_PUBLIC_AREA,
  GET_PURCHASE_ORDER,
  GET_QUOTATION,
  GET_REQUIRED_DOCUMENT,
  GET_REQUIRED_SUBMITTALS,
  GET_RFI,
  GET_RFQ,
  GET_ROLE,
  GET_ROLE_FUNCTIONALITY_MAP,
  GET_ROOM_TYPE,
  GET_SCHEDULE_STEPS,
  GET_STAGE,
  GET_UOM,
  GET_USER_MANAGEMENT_FUNCTION,
  GET_USER_ROLE_MAPPING,
  GET_USER_TYPE,
  GET_VENDOR,
  GET_VENDOR_CATEGORY_FUNCTION,
  GET_VENDOR_CATEGORY_TO_SUB_CATEGORY,
  GET_VENDOR_COMPANY_TYPE,
  GET_VENDOR_SUB_CATEGORY,
  ONGOING_PROJECT,
  SHOW_VENDOR_PAYMENT,
  SHOW_VENDOR_PO,
  SHOW_VENDOR_QUOTATION,
  SHOW_VENDOR_RFQ,
} from "../utils/constants";

export default function Routes(props) {
  return (
    <Switch>
      {isAuthorizedFunc(GET_ROLE) && (
        <Route exact path="/master-configuration/searchrole">
          <SearchRolePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ROLE) && (
        <Route exact path="/master-configuration/vendorloginaccess">
          <SearchVendorAccessPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_PROJECT_ROLE) && (
        <Route exact path="/master-configuration/searchprojectrole">
          <SearchProjectRole />
        </Route>
      )}
      {isAuthorizedFunc(GET_FUNCTIONALITY) && (
        <Route exact path="/master-configuration/searchfunction">
          <SearchFunctionPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_MODULE) && (
        <Route exact path="/master-configuration/searchmodule">
          <SearchModulePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ROLE_FUNCTIONALITY_MAP) && (
        <Route exact path="/master-configuration/searchmapingrolefunction">
          <SearchMappingRoleFunctionPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_USER_ROLE_MAPPING) && (
        <Route exact path="/master-configuration/searchuserrolemapping">
          <SearchUserRoleMappingPage />
        </Route>
      )}

      {isAuthorizedFunc(GET_USER_TYPE) && (
        <Route exact path="/master-configuration/searchusertype">
          <SearchUserTypePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_CLIENT_TYPE_FUNCTION) && (
        <Route exact path="/master-configuration/searchclienttype">
          <SearchClientTypePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_COUNTRY_FUNCTION) && (
        <Route exact path="/master-configuration/searchcountry">
          <SearchCountryPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ALL_OPERATOR) && (
        <Route exact path="/master-configuration/searchoperator">
          <SearchOparatorPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ESTABLISHMENT_SCOPE) && (
        <Route exact path="/master-configuration/searchestablishment">
          <SearchEstablishmentPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ITEM_CATEGORY) && (
        <Route exact path="/master-configuration/itemcategory">
          <SearchItemCategoryPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ITEM_SUB_CATEGORY) && (
        <Route exact path="/master-configuration/itemsubcategory">
          <SearchItemSubCategory />
        </Route>
      )}
      {isAuthorizedFunc(GET_ESTABLISHMENT_ITEM_CATEGORY) && (
        <Route
          exact
          path="/master-configuration/searchestablishmenttoitemcategorymapping"
        >
          <SearchEstabishmentToItemCategoryMappingPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_SCHEDULE_STEPS) && (
        <Route exact path="/master-configuration/schedulestep">
          <SearchScheduleStepsPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_REQUIRED_DOCUMENT) && (
        <Route exact path="/master-configuration/requireddocument">
          <SearchRequiredDocumentPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_MOP_FUNCTION) && (
        <Route exact path="/master-configuration/modeofpayment">
          <SearchModeOfPaymentPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_STAGE) && (
        <Route exact path="/master-configuration/stage">
          <SearchStagePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_VENDOR) && (
        <Route exact path="/master-configuration/vendor">
          <SearchVendorPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_USER_MANAGEMENT_FUNCTION) && (
        <Route exact path="/master-configuration/searchuser">
          <SearchUserPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_PROJECT_SCOPE) && (
        <Route exact path="/master-configuration/searchprojectscope">
          <SearchProjectScopePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_CLIENT) && (
        <Route exact path="/master-configuration/searchclient">
          <SearchClientPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_VENDOR_CATEGORY_FUNCTION) && (
        <Route exact path="/master-configuration/searchvendorcategory">
          <SearchVendorCategoryPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_VENDOR_COMPANY_TYPE) && (
        <Route exact path="/master-configuration/vendorcompanytype">
          <SearchVendorCompanyTypePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_PRODUCT_AND_SERVICES) && (
        <Route exact path="/master-configuration/productandservice">
          <ProductAndServicesPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_COMMODITY_FUNCTION) && (
        <Route exact path="/master-configuration/commodities">
          <SearchCommoditiesPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_VENDOR_SUB_CATEGORY) && (
        <Route exact path="/master-configuration/searchvendordubcategory">
          <SearchVendorSubCategoryPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_VENDOR_CATEGORY_TO_SUB_CATEGORY) && (
        <Route
          exact
          path="/master-configuration/searchvendorcategorytosubcategorymapping"
        >
          <SearchVendorCategoryToSubCategoryMappingPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_CATEGORY_PRODUCT_MAPPING_FUNCTION) && (
        <Route
          exact
          path="/master-configuration/searchvendorcategorytoproductmapping"
        >
          <SearchVendorCategoryToProductMapping />
        </Route>
      )}
      {isAuthorizedFunc(GET_ROOM_TYPE) && (
        <Route exact path="/master-configuration/searchroomtype">
          <SearchRoomTypePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_PUBLIC_AREA) && (
        <Route exact path="/master-configuration/searchpublicarea">
          <SearchPublicAreaPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_CURRENCY_FUNCTION) && (
        <Route exact path="/master-configuration/searchcurrency">
          <SearchCurrencyPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_UOM) && (
        <Route exact path="/master-configuration/searchuompage">
          <SearchUOMPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_REQUIRED_SUBMITTALS) && (
        <Route exact path="/master-configuration/searchsubmitals">
          <SearchSubMitalPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_MODE_OF_SHIPMENT) && (
        <Route exact path="/master-configuration/searchmodeofshipment">
          <SearchModeOfShipmentPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_LOCATION) && (
        <Route exact path="/master-configuration/searchlocation">
          <SearchLocationPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_INCOTERM) && (
        <Route exact path="/master-configuration/searchincoterm">
          <SearchIncotermPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ACCESS_CONSIDERATION) && (
        <Route exact path="/master-configuration/searchacessandconsideration">
          <SearchAccessAndConsideration />
        </Route>
      )}
      {isAuthorizedFunc(GET_MODE_OF_TRANSPORTATION) && (
        <Route exact path="/master-configuration/modeoftransportation">
          <SearchModeOfTransportationPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_BUDGET_ITEM_CATEGORY_MAPPING) && (
        <Route exact path="/master-configuration/budgetitemcategorymapping">
          <SearchBudgetScopeToItemCategoryMapping />
        </Route>
      )}
      {isAuthorizedFunc(GET_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING) && (
        <Route
          exact
          path="/master-configuration/itemcategorysubcategorymapping"
        >
          <SearchItemCategorySubCategoryMapping />
        </Route>
      )}
      {isAuthorizedFunc(GET_PAYMENT_TERMS) && (
        <Route exact path="/master-configuration/searchpaymentterms">
          <SearchPaymentTermsPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_PAYMENT_AGAINST) && (
        <Route exact path="/master-configuration/searchpaymentagainst">
          <SearchPaymentAgainstPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_PAYMENT_PROGRESS) && (
        <Route exact path="/master-configuration/searchpaymentprogress">
          <SearchPaymentProgressPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/userprofile">
          <UserProfilePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ITEM_GROUP) && (
        <Route exact path="/master-configuration/itemgroup">
          <SearchItemGroupPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/vendorprofile">
          <VendorProfilePage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/clientprofilepage">
          <ClientProfilePage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route
          exact
          path="/master-configuration/searchvendorcompanytocategorymapping"
        >
          <SearchVendorCompanytoCategoryMapping />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/clientcontactpage/:client">
          <SearchClientContactPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_CLIENT) && (
        <Route
          exact
          path="/master-configuration/clientcontactpage/clientcontact/:clientid"
        >
          <ClientContactPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/vendorbranches/:name">
          <SearchVendorBranchPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/vendorcontact/:vendor">
          <SearchVendorContactPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_BUDGET_SCOPE) && (
        <Route exact path="/master-configuration/budgetscope">
          <SearchBudgetScopePage />
        </Route>
      )}
      {isAuthorizedFunc(GET_ITEM_SUB_GROUP) && (
        <Route exact path="/master-configuration/searchitemsubgroup">
          <SearchItemSubGroup />
        </Route>
      )}

      {isAuthorizedFunc(GET_ITEM_SUB_GROUP) && (
        <Route exact path="/master-configuration/searchscopegroupmap">
          <SearchScopeToItemGroupMapping />
        </Route>
      )}

      {isAuthorizedFunc(GET_ITEM_SUB_GROUP) && (
        <Route exact path="/master-configuration/searchgroupsubgroupmap">
          <SearchGroupToSubGroupMapping />
        </Route>
      )}

      {isAuthorizedFunc && (
        <Route exact path="/allprojects">
          {/* <SearchAllProjects /> */}
          <SearchProjectPage />
        </Route>
      )}
      {isAuthorizedFunc(COMPLETED_PROJECT) && (
        <Route exact path="/completedproject">
          {/* <SearchCompletedProjectPage /> */}
          <SearchProjectPage />
        </Route>
      )}
      {isAuthorizedFunc(CANCEL_PROJECT) && (
        <Route exact path="/cancelledproject">
          {/* <SearchCancelledProjectPage /> */}
          <SearchProjectPage />
        </Route>
      )}
      {isAuthorizedFunc(ONGOING_PROJECT) && (
        <Route exact path="/ongoingproject">
          {/* <SearchOngoingProjectPage /> */}
          <SearchProjectPage />
        </Route>
      )}
      {isAuthorizedFunc(ONGOING_PROJECT) && (
        <Route exact path="/draftproject">
          {/* <SearchDraftProject /> */}
          <SearchProjectPage />
        </Route>
      )}

      {
        <Route exact path="/master-configuration">
          <MasterConfigurationPage />
        </Route>
      }
      {isAuthorizedFunc && (
        <Route exact path="/budgetschedule/:id">
          <SearchBudgetSchedulePage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/itemgrouppage">
          <ItemGroupPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_RFI) && (
        <Route exact path="/requestforinfo/:roi">
          <SearchRequestForInformationPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_PROJECT) && (
        <Route exact path="/project/">
          <SearchProjectPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route path="/changepassword">
          <ChangePasswordForm />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/itemsubmittal">
          <SearchItemSubmittalPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/searchprojectspecificuser">
          <SearchProjectSpecificUser />
        </Route>
      )}
      {isAuthorizedFunc(GET_BUDGET) && (
        <Route exact path="/projectbudget/:user">
          <SearchProjectBudgetPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_RFQ) && (
        <Route exact path="/requestforquotation">
          <SearchRequestForQuotation />
        </Route>
      )}
      {isAuthorizedFunc(GET_BUDGET_ITEM) && (
        <Route exact path="/budgetitem/:id">
          <SearchBudgetItem />
        </Route>
      )}
      {isAuthorizedFunc(GET_PURCHASE_ORDER) && (
        <Route exact path="/purchaseorders/:id">
          <SearchPurchaseOrdersPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/test">
          <Test />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/test2">
          <Test2 />
        </Route>
      )}
      {isAuthorizedFunc(GET_RFI) && (
        <Route exact path="/requestforinformation">
          <SearchRequestforInformationPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_QUOTATION) && (
        <Route exact path="/quotation/:id">
          <SearchQuotation />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/invoicepayment/:id">
          <SearchInvoicePayment />
        </Route>
      )}
      {isAuthorizedFunc(GET_INVOICE) && (
        <Route exact path="/deliverynote/:id">
          <SearchDeliveryNote />
        </Route>
      )}
      {isAuthorizedFunc(GET_INVOICE) && (
        <Route exact path="/invoice/:id">
          <SearchInvoice />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/categories">
          <SearchCategory />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/paymentrequest/:id">
          <PaymentRequestSummery />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/assetregister/:id">
          <AssetRegister />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/groups/:id">
          <GroupReport />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/categories/:id">
          <CategoryReport />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/paymentsummary/:id">
          <PaymentSummary />
        </Route>
      )}
      {isAuthorizedFunc(CREATE_COST_ANALYSIS) && (
        <Route exact path="/costanalysis/:id">
          <SearchCostAnalysis />
        </Route>
      )}
      {isAuthorizedFunc(CREATE_COST_ANALYSIS) && (
        <Route exact path="/searchcostreport/:id">
          <SearchReportPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/itemschedule/:id">
          <SearchItemSchedulePage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/rfq/:budgetID">
          <SearchDocument />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/document/:id">
          <Documents />
        </Route>
      )}
      {isAuthorizedFunc(GET_RFI) && (
        <Route exact path="/searchdocumentRfi/:budgetID">
          <SearchDoumentRfi />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/master-configuration/SearchStatusPage">
          <SearchStatusPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/">
          <Dashboard />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/allmaster">
          <AllMastersPage />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/searchpage">
          <SearchPage />
        </Route>
      )}
      {isAuthorizedFunc(GET_RFQ) && (
        <Route exact path="/rfqvendor/:id">
          <SearchRfq />
        </Route>
      )}

      {isAuthorizedFunc && (
        <Route exact path="/create-master">
          <CreateMaster />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route exact path="/masterdetails">
          <MasterDetails />
        </Route>
      )}
      {isAuthorizedFunc(SHOW_VENDOR_RFQ) && (
        <Route exact path="/vendorrfq">
          <VendorRfq />
        </Route>
      )}
      {isAuthorizedFunc(SHOW_VENDOR_QUOTATION) && (
        <Route exact path="/vendorquotation/:id">
          <VendorQuotation />
        </Route>
      )}
      <Route exact path="/vendorrfi/:id">
        <VendorRfi />
      </Route>
      {isAuthorizedFunc(SHOW_VENDOR_PO) && (
        <Route exact path="/vendorpo">
          <PurchaseOrderStatus />
        </Route>
      )}
      {isAuthorizedFunc(SHOW_VENDOR_PAYMENT) && (
        <Route exact path="/vendorpayment">
          <PaymentStatus />
        </Route>
      )}
      {isAuthorizedFunc(SHOW_VENDOR_PAYMENT) && (
        <Route exact path="/vendordelivery">
          <DeliveryStatus />
        </Route>
      )}
      {isAuthorizedFunc && (
        <Route path="/coming_soon">
          <ComingSoonPage />
        </Route>
      )}
    </Switch>
  );
}
