/* eslint-disable no-unused-vars */
import React from "react";
import { GetAllActiveStage } from "../../../axios";
import { Button, DateAndTimePicker, FormDropDown } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateItemScheduleForm = (props) => {
  const {
    budgetItemId,
    setMessage,
    errorMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;
  const [loader, setLoader] = React.useState(false);

  const [plannedEndDate, setPlannedEndtDate] = React.useState({
    value: initialValue.plannedEndDate ? initialValue.plannedEndDate : "",
    error: false,
    success: false,
  });

  const [forecast, setForecast] = React.useState({
    value: initialValue.forecast ? initialValue.forecast : "",
    error: false,
    success: false,
  });

  const [actualEndDate, setActualEndDate] = React.useState({
    value: initialValue.actualEndDate ? initialValue.actualEndDate : "",
    error: false,
    success: false,
  });

  const [stage, setStage] = React.useState({
    value: initialValue.stage_id ? initialValue.stage_id.id : "",
    error: false,
    success: false,
  });

  const [duration, setDuration] = React.useState({
    value: initialValue.duration ? initialValue.duration : "",
    error: false,
    success: false,
  });

  const [stageOption, setStageOption] = React.useState([]);

  const handleChangePDate = (date) => {
    setPlannedEndtDate(date);
  };

  const handleChangeForecast = (date) => {
    setForecast(date);
  };
  const handleChangeActualEndDate = (date) => {
    setActualEndDate(date);
    setDuration(10);
  };

  const handleChangeStage = (data) => {
    setMessage(null);
    setStage(data);
  };

  const handleChangeDuration = (data) => {
    setDuration(data.value);
  };

  const createItemScheduleTitle =
      Strings["ItemSchedule.createItemScheduleTitle"],
    editItemScheduleTitle = Strings["ItemSchedule.editItemScheduleTitle"],
    budgetStartDateLabel = Strings["ItemSchedule.ItemStartDateLabel"],
    budgetStartDateCustomErrorLine =
      Strings["ItemSchedule.budgetStartDateCustomErrorLine"],
    budgetEndDateLabel = Strings["ItemSchedule.itemEndDateLabel"],
    itemActualEndDateLabel = Strings["ItemSchedule.itemActualEndDateLabel"],
    budgetEndDateCustomErrorLine =
      Strings["ItemSchedule.budgetEndDateCustomErrorLine"],
    stageLabel = Strings["ItemSchedule.stageLabel"],
    stageCustomError = Strings["ItemSchedule.stageCustomError"],
    durationLabel = Strings["ItemSchedule.durationLabel"],
    durationPlaceholder = Strings["ItemSchedule.durationPlaceholder"],
    durationCustomError = Strings["ItemSchedule.durationCustomError"],
    saveBtn = Strings["ItemSchedule.saveBtn"],
    updateBtn = Strings["ItemSchedule.updateBtn"],
    cancelBtn = Strings["ItemSchedule.cancelBtn"];

  React.useEffect(() => {
    setLoader(true);
    GetAllActiveStage()
      .then((res) => {
        setLoader(false);
        const result = res.data.data.map((elm) => ({
          value: elm.id,
          label: elm.name,
        }));
        setStageOption(result);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  let isValueNOTChange =
    stage.value === initialValue.stage &&
    plannedEndDate.value === initialValue.plannedEndDate &&
    forecast.value === initialValue.forecast &&
    actualEndDate.value === initialValue.actualEndDate &&
    duration.value === initialValue.duration;

  let disable =
    stage.error ||
    duration.error ||
    plannedEndDate.error ||
    forecast.error ||
    actualEndDate.error ||
    stage.value === "" ||
    duration.value === "" ||
    plannedEndDate.value === "" ||
    forecast.value === "" ||
    actualEndDate.value === "" ||
    (stage.success === false &&
      duration.success === false &&
      plannedEndDate.success === false &&
      forecast.success === false &&
      actualEndDate.success === false) ||
    isValueNOTChange;

  const handleSaveData = () => {
    var today = plannedEndDate.value.toString();
    var date2 = actualEndDate.value.toString();
    today = new Date(
      today.split("-")[0],
      today.split("-")[1] - 1,
      today.split("-")[2]
    );
    date2 = new Date(
      date2.split("-")[0],
      date2.split("-")[1] - 1,
      date2.split("-")[2]
    );
    var timeDiff = Math.abs(date2.getTime() - today.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const formData = {
      stage_id: stage.value,
      budget_item_id: budgetItemId,
      budget_start_date: plannedEndDate.value,
      forecast: forecast.value,
      budget_end_date: actualEndDate.value,
      duration: diffDays.toString(),
    };
    handleChangeFormData(formData);
  };

  return (
    <div className="modalFormContainer">
      {loader ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>
        {actionType === "add" ? createItemScheduleTitle : editItemScheduleTitle}
      </h2>
      <FormDropDown
        isMulti={false}
        value={stage.value}
        Label={stageLabel}
        Options={stageOption}
        onSelect={handleChangeStage}
        CustomErrorLine={stageCustomError}
        disable={false}
      />
      <DateAndTimePicker
        label={budgetStartDateLabel}
        customErrorLine={budgetStartDateCustomErrorLine}
        hadleSetDate={handleChangePDate}
        date={plannedEndDate.value}
        disable={false}
      />
      <DateAndTimePicker
        label={budgetEndDateLabel}
        customErrorLine={budgetEndDateCustomErrorLine}
        hadleSetDate={handleChangeForecast}
        date={forecast.value}
        disable={false}
      />

      <DateAndTimePicker
        label={itemActualEndDateLabel}
        customErrorLine={budgetEndDateCustomErrorLine}
        hadleSetDate={handleChangeActualEndDate}
        date={actualEndDate.value}
        disable={false}
      />
      {/* <FormTextInput
        type="text"
        label={durationLabel}
        placeholder={durationPlaceholder}
        Value={duration}
        onChangeText={handleChangeDuration}
        Validate={true}
        CustomErrorLine={durationCustomError}
        editable={false}
      /> */}
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          disable={actionType === "add" ? disable : ""}
          onClick={handleSaveData}
          buttonStyle={{ backgroundColor: colors.primary }}
        />
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default CreateItemScheduleForm;
