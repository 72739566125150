import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateUserType,
  GetAllUserType,
  UpdateActiveStatus,
  UpdateUserType,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { UserTypeForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_USER_TYPE_STATUS_FUNCTION,
  CREATE_USER_TYPE,
  UPDATE_USER_TYPE,
} from "../../../utils/constants";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

export default function SearchUserTypePage() {
  const Title = Strings["UserType.SearchUserTypeHeader"],
    alertMessage = Strings["UserType.AlertMessage"],
    alertTitle = Strings["UserType.AlertTitle"],
    userType = Strings["UserType.TableHead.userType"],
    userDescription = Strings["UserType.TableHead.userDescription"],
    activeStatus = Strings["UserType.TableHead.activeStatus"],
    actions = Strings["UserType.TableHead.actions"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  // const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [editId, setEditId] = React.useState(null);
  // const [statusData, setStatusData] = React.useState();
  // const [statusDataUpdated, setStatusDataUpdated] = React.useState();
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [currentid, setcurrentid] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);

  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_USER_TYPE)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_USER_TYPE)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_USER_TYPE_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["UserType"],
    },
  ];
  const mapableData = [
    {
      dataKey: "user_type",
      title: userType,
    },
    {
      dataKey: "description",
      title: userDescription,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: actions,
      isAction: true,
    },
  ];
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const handleChangeActiveStatus = (id, status) => {
    setAlertBox(true);
    setcurrentStatus(status);
    setcurrentid(id);
  };

  const getAlldata = () => {
    setisLoader(true);
    GetAllUserType(rowsPerPage, page, searchValue.value)
      .then((res) => {
        setRows(res.data.data);
        setTotalData(res.data.total);
        setisLoader(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAlldata();
  }, [rowsPerPage, page]);

  const createUserTypes = (formData) => {
    setisLoader(true);
    CreateUserType(formData)
      .then((res) => {
        getAlldata();
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          // setRows([res.data.data, ...rows]);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateUserType = (formData) => {
    setisLoader(true);
    UpdateUserType(formData, editId)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const result = rows.map((ele) => {
            if (ele.id === res.data.data.id) {
              return {
                ...ele,
                user_type: res.data.data.user_type,
                description: res.data.data.description,
              };
            } else {
              return ele;
            }
          });
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <UserTypeForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add" ? createUserTypes : updateUserType
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };
  const handleChangeCancel = () => {
    setMessage(null);
    setAlertBox(false);
  };

  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateActiveStatus({ status: !currentStatus }, currentid)
      .then((res) => {
        setisLoader(false);
        const result = rows.map((elem) => {
          if (elem.id === res.data.data.id) {
            return { ...elem, is_active: res.data.data.is_active };
          } else {
            return elem;
          }
        });
        setRows(result);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    setEditId(result.id);
    handleSelectBtn("edit");
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetAllUserType(rowsPerPage, page, searchData.value)
        .then((res) => {
          setRows(res.data.data);
          setTotalData(res.data.total);
          setisLoader(false);
        })
        .catch((e) => {
          setisLoader(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAlldata();
  };

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {Title} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
        </div>

        <div className="row">
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <div className="col-12">
            <div className="UserbudgetTable">
              <form onSubmit={handleSearchSubmit}>
                <FormTextInput
                  type="text"
                  className="searchBarUser mb-3"
                  placeholder={`Search `}
                  Value={searchValue.value}
                  onChangeText={handleSearch}
                  inputStyle={{ border: "none" }}
                  editable
                />
              </form>
              {rows.length > 0 ? (
                <>
                  <SearchTable
                    isUpdate={isUpdate}
                    isStatus={isStatus}
                    placeholder={"user type"}
                    data={rows}
                    mapableData={mapableData}
                    searchProp={["user_type"]}
                    handleChangeActiveStatus={handleChangeActiveStatus}
                    handleChangeEdit={handleChangeEdit}
                  />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={CustomTablePaginationActions}
                  />
                </>
              ) : (
                <>
                  {isLoader ? (
                    <LoaderComponent />
                  ) : (
                    <NodataFound
                      onClick={() => {
                        openAddModal();
                        handleSelectBtn("add");
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
