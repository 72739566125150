import React from 'react'
import { useLocation } from 'react-router-dom'
import { SearchTable } from '../../../components';

const SearchPage = (props) => {

    const location = useLocation();

    const stateData = location.state;

    const { data, mapableData, searchProp } = stateData;

    return (
        <div className='container'>
            <SearchTable data={data} mapableData={mapableData} searchProp={searchProp} />
        </div>
    )
}

export default SearchPage
