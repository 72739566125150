/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateCompanyForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeData = () => { },
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createCountryTitle = Strings["Country.CreateCountryHeader"],
    editCountrytitle = Strings["Country.EditCountryHeader"],
    countryNameLabel = Strings["Country.CreateCountryName"],
    countryNamePlaceholder = Strings["Country.CreateCountryNamePlaceholder"],
    countryNameCustomError = Strings["Operator.CreateCountryNameCustomError"],
    countryCodeLabel = Strings["Country.CreateCountryCodeLabel"],
    countryCodePlaceholder = Strings["Country.CreateCountryCodePlaceholder"],
    countryCodeCustomError = Strings["Operator.CreateCountryCodeCustomError"],
    countryTagLabel = Strings["Country.CreateCountryTagLabel"],
    countryTagPlaceholder = Strings["Country.CreateCountryTagPlaceholder"],
    countryTagCustomError = Strings["Country.CreateCountryTagCustomError"],
    cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];

  const [countryName, setcountryName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    sucess: false,
  });

  const [countryCode, setCountryCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    sucess: false,
  });

  const handleChangeCountry = (value) => {
    setMessage(null);
    setcountryName(value);
  };

  const handleChangeCountryCode = (value) => {
    setMessage(null);
    setCountryCode(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: countryName.value,
      description: countryCode.value,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged =
    countryName.value === initialValue.name &&
    countryCode.value === initialValue.code;
  let disable =
    countryName.error ||
    countryCode.error ||
    countryName.value === "" ||
    countryCode.value === "" ||
    // (countryName.success === false && countryCode.success === false) ||
    isValueNOTChanged;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? "Create Operator" : "Edit Operator"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="textarea"
        label={"Name"}
        placeholder={"Operator Name"}
        Value={countryName.value}
        onChangeText={handleChangeCountry}
        Validate={true}
        CustomErrorLine={countryNameCustomError}
        editable={true}
      />
      <FormTextInput
        type="textarea"
        label={"description"}
        placeholder={"description"}
        Value={countryCode.value}
        onChangeText={handleChangeCountryCode}
        // Validate={true}
        // CustomErrorLine={countryCodeCustomError}
        multiline={true}
        editable={true}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          // disable={actionType === "add" ? disable : null}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default CreateCompanyForm;
