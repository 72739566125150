/* eslint-disable no-unused-vars */
import React from "react";
import { ImageCreation } from "../../../axios";
import { Button } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const PurchaseOrderFileUpload = (props) => {
  const {
    errorMessage,
    handleChangeData = () => { },
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.selectedFile ? initialValue.selectedFile : "",
    error: false,
    success: false,
  });
  const [isLoader, setisLoader] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState({
    value: initialValue.selectedFileName ? initialValue.files[0].filename : "",
    error: false,
    success: false,
  });


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChangeFile = async (data) => {
    setisLoader(true);
    if (data.target.files.length > 0) {
      const filename = data.target.files[0].name;
      var fileExtension = filename.split(".").pop();
      const files = data.target.files[0];
      const base64 = await convertBase64(files);
      var indexOfbase64 = base64.indexOf("base64");
      const newbase64 = base64.slice(indexOfbase64 + 7, base64.length);
      const uploadData = {
        file_data: newbase64,
        file_extension: fileExtension,
      };
      await ImageCreation(uploadData)
        .then((res) => {
          setisLoader(false);
          setSelectedFileName(filename)
          setSelectedFile([res.data.data.file_path, filename]);
        })
        .catch(() => {
          setisLoader(false);
        });
    }
  };

  const handleSaveData = () => {
    const formData = {
      file_data: [selectedFile[0]],
      filename: [selectedFile[1]],
    };
    handleChangeData(formData);
  };

  let disable =
    selectedFileName.error ||
    selectedFileName.value === "" ||
    selectedFileName.success === false;


  return (
    <div className="modalFormContainer">
      {isLoader ? <LoaderComponent /> : null}
      <h2 className="mb-3">{"Upload File"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row d-flex justify-content-between align-items-center"
        style={{ marginTop: "1.5rem", }}
      >
        <div className="col-sm-8">
          <h4>{selectedFileName.value}</h4>
          <input
            type="file"
            onChange={handleChangeFile}
            accept=".csv, .xlsx, .pdf"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey }}
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          onClick={handleSaveData}
          disable={disable}
        />
      </div>
    </div>
  );
};

export default PurchaseOrderFileUpload;
