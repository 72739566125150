import React from "react";
import { NoDataFoundNew } from "../../../assets";
import { Button } from "../../common";
import "./NodataFound.css";

const NodataFound = (props) => {
  const { onClick, norecord = true } = props;
  return (
    <div className="nodatImage">
      <div className="nodatImageInner">
        <img src={NoDataFoundNew} alt="" />
      </div>
      <div className="buttomNoDataIamge">
        <h2 className="norecText">No Record Found</h2>
        {norecord && <Button buttonName="Add data" onClick={onClick} />}
      </div>
    </div>
  );
};

export default NodataFound;
