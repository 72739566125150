/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ProjectRoleForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createRoleTypeTitle = Strings["ProjectRole.CreateRoleHeader"],
    editRoleTypeTitle = Strings["ProjectRole.editRoleTitle"],
    roleNameLabel = Strings["ProjectRole.editRoleNameLabel"],
    roleNamePlaceHolder = Strings["ProjectRole.editRoleNamePlaceholder"],
    roleNameError = Strings["ProjectRole.editRoleNameCustomError"],
    roleDescriptionTitle = Strings["ProjectRole.editRoleDescriptionLabel"],
    roleDescriptionPlaceHolder =
      Strings["ProjectRole.editRoleDescriptionPlaceholder"],
    roleDescriptionError =
      Strings["RoleManagement.editRoleDescriptionCustomError"],
    roleSaveButton = Strings["RoleManagement.CreateRoleSaveButton"],
    roleUpdateButton = Strings["RoleManagement.EditRoleCancelButton"],
    roleCancelButton = Strings["RoleManagement.CreateRoleCancelButton"];

  const [roleName, setRoleName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [roleDescription, setRoleDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  // const [activeStatus] = React.useState(
  //   initialValue.is_active && initialValue.is_active
  // );

  const handleChangeRole = (value) => {
    setMessage(null);
    setRoleName(value);
  };

  const handleChangeDescription = (value) => {
    setMessage(null);
    setRoleDescription(value);
  };

  const handleSaveData = (value) => {
    const formData = {
      name: roleName.value,
      description: roleDescription.value,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged = roleName.value === initialValue.name;
  // roleDescription.value === initialValue.roleDescription;

  let disable =
    roleName.error ||
    // roleDescription.error ||
    roleName.value === "" ||
    // roleDescription.value === "" ||
    roleName.success === false ||
    //  roleDescription.success === false
    isValueNOTChanged;

  let isValueNOTChangededit =
    roleName.value === initialValue.name &&
    roleDescription.value === initialValue.description;

  let Editdisable =
    roleName.error ||
    roleDescription.error ||
    roleName.value === "" ||
    roleDescription.value === "" ||
    (roleName.success === false && roleDescription.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createRoleTypeTitle : editRoleTypeTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        label={roleNameLabel}
        placeholder={roleNamePlaceHolder}
        Value={roleName.value}
        onChangeText={handleChangeRole}
        Validate={true}
        CustomErrorLine={roleNameError}
        starProps={true}
        editable
      />
      <FormTextInput
        type="text"
        label={roleDescriptionTitle}
        placeholder={roleDescriptionPlaceHolder}
        Value={roleDescription.value}
        onChangeText={handleChangeDescription}
        // Validate={true}
        CustomErrorLine={roleDescriptionError}
        multiline={true}
        editable
      />
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? roleSaveButton : roleUpdateButton}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={roleCancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
          }}
        />
      </div>
    </div>
  );
};

export default ProjectRoleForm;
