/* eslint-disable no-unused-vars */
import React from "react";
import { CreatePaymentRequestSummery } from "../../../axios";
import { Button } from "../../../components";
import SearchCheckTable from "../../../components/AppSpecific/SearchTable/SearchCheckTable";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";

const CreatePaymentSummery = (props) => {
  const {
    getPaymentRequestdata,
    actionType,
    handleModalClose,
    rows,
  } = props;

  const [dataList, setDataList] = React.useState(rows.length > 0 ? rows : []);
  const [finalData, setFinalData] = React.useState([]);
  const [message, setMessage] = React.useState([]);
  const [isLoader, setisLoader] = React.useState(false);

  const handleSaveData = () => {
    const formdata = {
      invoice_id: finalData,
    };
    setisLoader(true);
    CreatePaymentRequestSummery(formdata)
      .then((elem) => {
        setisLoader(false);
        handleModalClose();
        getPaymentRequestdata();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const createPaymentSummeryHeader =
    strings["PaymentSummery.createPaymentSummeryHeader"],
    editPaymentSummeryHeader =
      strings["PaymentSummery.editPaymentSummeryHeader"],
    cancelBtn = strings["PaymentSummery.cancelBtn"],
    tableSerialNo = strings["PaymentSummery.tableSerialNo"],
    tableItemName = strings["PaymentSummery.tableInvoiceNo"],
    submitBtn = strings["PaymentSummery.submitBtn"];

  const mapableData = [
    {
      dataKey: "invoiceNo",
      title: tableItemName,
    },
    {
      dataKey: "Status",
      title: "Status",
    },
    {
      dataKey: "creationDate",
      title: "Created Date",
    },
    {
      dataKey: "poNo",
      title: tableSerialNo,
    },
    {
      dataKey: "convertValue",
      title: "Total value",
    },
  ];
  
  const handleSelectData = (id) => {
    const result = dataList.map((data) => {
      if (data.id === id) {
        return { ...data, checked: !data["checked"] };
      } else {
        return { ...data };
      }
    });
    const arr = result.map((elem) => {
      if (elem.checked === true) {
        return elem.id;
      }
    })
      .filter((elem) => {
        return elem !== undefined;
      });
    setFinalData(arr);
    setDataList(result);
  };

  return (
    <div className="modalFormContainer">
      {isLoader ? <LoaderComponent /> : null}
      <h4 style={{ color: "red" }}>{message}</h4>

      <h2 className="mb-4">
        {actionType === "add"
          ? createPaymentSummeryHeader
          : editPaymentSummeryHeader}
      </h2>
      <div className="col-12">
        <SearchCheckTable
          data={rows}
          mapableData={mapableData}
          searchProp={["poNo"]}
          handleChangeCheck={handleSelectData}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey }}
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          buttonName={submitBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          onClick={handleSaveData}
        />
      </div>
    </div>
  );
};
export default CreatePaymentSummery;
