import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import React, { useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import {
  // GetAllActiveCountry,
  GetAllActiveIncoterm,
  // GetAllActiveTransportation,
  GetAllActiveVendor,
} from "../../../axios";
import {
  Button,
  DateAndTimePicker,
  FormDropDown,
  FormTextInput,
  // RequestForInformationTable,
  RequestForQuotationTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import { isDecoded } from "../../../utils/crypto";
import "./RFIForm.css";

const RequestForQuotationForm = (props) => {
  const {
    budget_id,
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    selectedItem,
    handleModalClose,
  } = props;

  const [loader, setLoader] = React.useState(false);
  // const [rfqName, setRfqName] = React.useState({
  //   value: initialValue.rfqName ? initialValue.rfqName : "",
  //   error: false,
  //   success: false,
  // });
  // const [rfqNo] = React.useState({
  //   value: initialValue.serial_no ? initialValue.serial_no : "",
  //   error: false,
  //   success: false,
  // });
  const [incoterm, setIncoterm] = React.useState({
    value: initialValue.incoterm_id ? initialValue.incoterm_id.id : "",
    error: false,
    success: false,
  });
  let modList = [];
  if (initialValue.submission) {
    modList = initialValue.submission.map((ele) => {
      return {
        label: ele.mode_of_submission,
        value: ele.mode_of_submission,
        id: ele.id,
      };
    });
  }
  const [modeOfSubmission, setModeOfsubmission] = React.useState({
    value: modList,
    error: false,
    success: false,
  });
  const [deadline, setDeadline] = React.useState({
    value: initialValue.deadline ? initialValue.deadline : "",
    error: false,
    success: false,
  });
  const [queryDeadline, setQueryDeadlineLabel] = React.useState({
    value: initialValue.queryDeadline ? initialValue.queryDeadline : "",
    error: false,
    success: false,
  });
  const [importantNotes, setImportantNotes] = React.useState({
    value: initialValue.importantNotes ? initialValue.importantNotes : "",
    error: false,
    success: false,
  });
  const [tendorNotes, setTendorNotes] = React.useState({
    value: initialValue.tendorNotes ? initialValue.tendorNotes : "",
    error: false,
    success: false,
  });
  const [rfqReferance, setRfqReferance] = React.useState({
    value: initialValue.rfqReferenceField ? initialValue.rfqReferenceField : "",
    error: false,
    success: false,
  });
  const [rfqPackage, setRfqPackage] = React.useState({
    value: initialValue.rfqPackage ? initialValue.rfqPackage : "",
    error: false,
    success: false,
  });
  const [minDates, setMinDates] = React.useState();
  const [emailAddress, setEmailAddress] = React.useState([]);
  const [emailCcAddress, setEmailCcAddress] = React.useState([]);

  let vendorList = [];
  if (initialValue.vendors) {
    vendorList = initialValue.vendors.map((ele) => {
      return {
        label: ele.vendor.name,
        value: ele.vendor.id,
        key: ele.vendor.id,
      };
    });
  }

  const [vendor, setVendor] = React.useState({
    value: vendorList,
    error: false,
    success: false,
  });

  const [optionsIncoterm, setOptionsIncoterm] = React.useState([]);

  const optionModSubmission = [
    {
      id: 1,
      value: "Sealed Envelope to client office",
      label: "Sealed Envelope to client office",
    },
    {
      id: 2,
      value: "Sealed envelop to Furnish Office",
      label: "Sealed envelop to Furnish Office",
    },
    { id: 3, value: "Submit Via Email", label: "Submit Via Email" },
  ];

  const [optionsVendor, setOptionsVendor] = React.useState([]);

  let budget_id_arr = [];
  selectedItem.map((ele) => budget_id_arr.push(ele.id));

  const handleChangeRfqRefNo = (data) => {
    setMessage(null);
    setRfqReferance(data);
  };

  const handleChangeRfqPackage = (data) => {
    setMessage(null);
    setRfqPackage(data);
  };

  // const handleChangeRfqName = (data) => {
  //   setMessage(null);
  //   setRfqName(data);
  // };

  const handleChangeIncoterm = (data) => {
    setIncoterm(data);
  };

  const handleChangeModeOfSubmission = (data) => {
    setModeOfsubmission(data);
  };

  const handleChangeDeadline = (data) => {
    setDeadline(data);
  };

  const handleChangeQueryDeadline = (data) => {
    setQueryDeadlineLabel(data);
  };

  const handleChangeImportantNotes = (data) => {
    setImportantNotes(data);
  };

  const handleChangeTendorNotes = (data) => {
    setTendorNotes(data);
  };

  const hadleChangeVendor = (data) => {
    setVendor(data);
  };
  // const handleChangeEmailAddress = (value) => {
  //   setEmailAddress(value);
  // };

  const minDate = () => {
    var dateToday = new Date();
    setMinDates(moment(dateToday).format("YYYY-MM-DD"));
  };

  React.useEffect(() => {
    minDate();
  }, []);

  const handleChangeSelectedData = (id, index) => {
    tempData.forEach((item, index) => {
      if (item.id === id) {
        filterData[index]["checked"] = !item.checked;
      } else {
        filterData[index]["checked"] = item.checked;
      }
    });
    setTempData(filterData);
  };

  const getIncoterm = () => {
    setLoader(true);
    GetAllActiveIncoterm()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptionsIncoterm(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const getvendor = () => {
    setLoader(true);
    GetAllActiveVendor()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        const noneOption = {
          key: "none",
          value: 0,
          label: "None",
        };
        result.unshift(noneOption);
        setOptionsVendor(result);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getIncoterm();
    getvendor();
  }, []);

  const createRequestForQuotationTitle =
      Strings["RequestForQuotation.createRequestForQuotationTitle"],
    editRequestForQuotationTitle =
      Strings["RequestForQuotation.editRequestForQuotationTitle"],
    rfqNameLabel = Strings["RequestForQuotation.rfqNameLabel"],
    rfqSerialLabel = Strings["RequestForQuotation.rfqSerialLabel"],
    rfqNamePlaceholder = Strings["RequestForQuotation.rfqNamePlaceholder"],
    rfqNameCustomError = Strings["RequestForQuotation.rfqNameCustomError"],
    IncotermLabel = Strings["RequestForQuotation.IncotermLabel"],
    IncotermCustomError = Strings["RequestForQuotation.IncotermCustomError"],
    modeOfSubmissionLabel = Strings["RequestForQuotation.modeOfSubmission"],
    // modeOfSubmissionCustomError = [
    //   "RequestForQuotation.modeOfSubmissionCustomError",
    // ],
    deadlineLabel = Strings["RequestForQuotation.deadlineLabel"],
    queryDeadlineLabel = Strings["RequestForQuotation.QueryDeadlineLabel"],
    deadlineCustomErrorLine =
      Strings["RequestForQuotation.deadlineCustomErrorLine"],
    queryDeadlineCustomErrorLine =
      Strings["RequestForQuotation.QueryDeadlineCustomErrorLine"],
    importantNotesLabel = Strings["RequestForQuotation.importantNotesLabel"],
    importantNotesPlaceholder =
      Strings["RequestForQuotation.importantNotesPlaceholder"],
    importantNotesCustomError =
      Strings["RequestForQuotation.importantNotesCustomError"],
    tenderNotesLabel = Strings["RequestForQuotation.tenderNotesLabel"],
    tenderNotesPlaceholder =
      Strings["RequestForQuotation.tenderNotesPlaceholder"],
    tenderNotesCustomError =
      Strings["RequestForQuotation.tenderNotesCustomError"],
    vendorLabel = Strings["RequestForQuotation.vendorLabel"],
    vendorCustomError = Strings["RequestForQuotation.vendorCustomError"],
    saveBtn = Strings["RequestForQuotation.saveBtn"],
    updateBtn = Strings["RequestForQuotation.updateBtn"],
    cancelBtn = Strings["RequestForQuotation.cancelBtn"],
    itemName = Strings["RequestForQuotation.TableHead.itemName"],
    quantity = Strings["RequestForQuotation.TableHead.quantity"],
    uom = Strings["RequestForQuotation.TableHead.uom"];

  const isValueChanged =
    // rfqName.value === initialValue.rfqName &&
    incoterm.value === initialValue.incoterm &&
    modeOfSubmission.value === initialValue.modeOfSubmission &&
    deadline.value === initialValue.deadline &&
    importantNotes.value === initialValue.importantNotes &&
    tendorNotes.value === initialValue.tendorNotes &&
    vendor.value === initialValue.vendor;

  const disable =
    // rfqName.error ||
    deadline.error ||
    queryDeadline.error ||
    vendor.error ||
    modeOfSubmission.error ||
    incoterm.error ||
    rfqPackage.error ||
    // rfqName.value === "" ||
    deadline.value === "" ||
    queryDeadline.value === "" ||
    // vendor.value.length === 0 ||
    modeOfSubmission.value === "" ||
    incoterm.success === "" ||
    rfqPackage.value === "" ||
    // rfqReferance.error ||
    // importantNotes.error ||
    // tendorNotes.error ||
    // rfqReferance.value === "" ||
    // importantNotes.value === "" ||
    // tendorNotes.value === "" ||
    // rfqName.success === false &&
    // rfqReferance.success === false &&
    (rfqPackage.success === false &&
      incoterm.success === false &&
      modeOfSubmission.success === false &&
      deadline.success === false &&
      queryDeadline.success === false) ||
    // vendor.success === false) ||
    // importantNotes.success === false &&
    // tendorNotes.success === false) ||
    isValueChanged;

  const handleSubmit = () => {
    const filtered = tempData.filter((resut) => {
      return resut.checked === true;
    });
    const budgetRevised_item = [];
    filtered.map((elem) => {
      return budgetRevised_item.push(elem.id);
    });
    let vendorId = [];
    vendor.value.map((ele) => vendorId.push(ele.key));

    let modSubmissinIds = [];
    modeOfSubmission.value.map((ele) => modSubmissinIds.push(ele.value));

    // let budget_Ids;
    // if (actionType === "add") {
    //   return budget_id;
    // } else {
    //   return budget_id.budgetID;
    // }
    const formData = {
      name: rfqPackage.value,
      valid_till: deadline.value,
      query_deadline: queryDeadline.value,
      mode_of_submission: modSubmissinIds,
      incoterm_id: incoterm.value,
      important_notes: importantNotes.value,
      tendor_notes: tendorNotes.value,
      email: emailAddress,
      cc_email: emailCcAddress,
      budget_id:
        actionType === "add" ? budget_id : isDecoded(budget_id.budgetID),
      budget_item_id: actionType === "add" ? budget_id_arr : budgetRevised_item,
      vendor_id: vendorId,
      rfq_reference_field: null,
      rfq_package: rfqPackage.value,
    };
    handleChangeData(formData);
  };

  const mapableData = [
    {
      dataKey: "serialNo",
      title: "Control Number",
    },
    // {
    //   dataKey: "itemName",
    //   title: itemName,
    // },
    {
      dataKey: "budgetQuantity",
      title: quantity,
    },
    {
      dataKey: "budgetUOM",
      title: uom,
    },
  ];

  let filterData;
  if (actionType === "add") {
    filterData = selectedItem.map((elm) => ({
      id: elm.id,
      serialNo: elm.control_number,
      itemName: elm.name,
      vendor: elm.budget_vendordetails?.vendor.name,
      budgetQuantity:
        elm.budgetquantitydetails.budget_quantity !== null
          ? elm.budgetquantitydetails.budget_quantity
          : 0,
      budgetUOM:
        elm.budgetquantitydetails.budgetuom !== null
          ? elm.budgetquantitydetails.budgetuom.name
          : "N/A",
    }));
  } else if (actionType === "editRFQ") {
    filterData = selectedItem.map((elm) => ({
      id: elm.budgetitem.id,
      serialNo: elm.budgetitem.control_number,
      itemName: elm.budgetitem.name,
      checked: true,
      budgetQuantity:
        elm.budgetitem.budgetquantitydetails.budget_quantity !== null
          ? elm.budgetitem.budgetquantitydetails.budget_quantity
          : 0,
      budgetUOM:
        elm.budgetitem.budgetquantitydetails.budgetuom !== null
          ? elm.budgetitem.budgetquantitydetails.budgetuom.name
          : "N/A",
    }));
  }

  const [tempData, setTempData] = useState(filterData);
  return (
    <div className="modalFormContainer">
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {loader ? <LoaderComponent /> : null}
      <h2>
        {actionType === "add"
          ? createRequestForQuotationTitle
          : editRequestForQuotationTitle}
      </h2>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          {/* <FormTextInput
            type="textarea"
            label={"Reference no."}
            placeholder={"Enter Reference no."}
            Value={rfqName.value}
            onChangeText={handleChangeRfqName}
            Validate={true}
            CustomErrorLine={rfqNameCustomError}
            starProps={true}
            editable
          /> */}
          <FormTextInput
            type="textarea"
            label={"Package Reference"}
            placeholder={"Enter Package Reference"}
            Value={rfqPackage.value}
            onChangeText={handleChangeRfqPackage}
            Validate={true}
            CustomErrorLine={"Enter Package Reference"}
            starProps={true}
            editable
          />
        </div>
        {actionType === "editRFQ" && (
          <></>
          // <div className="col-12 col-md-6 pe-0 pe-md-3">
          //   <FormTextInput
          //     type="textarea"
          //     label={rfqSerialLabel}
          //     placeholder={rfqNamePlaceholder}
          //     Value={rfqPackage.value}
          //     // onChangeText={handleChangeRfqName}
          //     Validate={true}
          //     CustomErrorLine={rfqNameCustomError}
          //     starProps={true}
          //     editable={false}
          //   />
          // </div>
        )}

        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <DateAndTimePicker
            minDate={minDates}
            label={deadlineLabel}
            customErrorLine={deadlineCustomErrorLine}
            hadleSetDate={handleChangeDeadline}
            date={deadline.value}
            disable={false}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <DateAndTimePicker
            minDate={minDates}
            label={queryDeadlineLabel}
            customErrorLine={queryDeadlineCustomErrorLine}
            hadleSetDate={handleChangeQueryDeadline}
            date={queryDeadline.value}
            disable={false}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormDropDown
            isMulti={true}
            defaultValue={vendor.value}
            Label={vendorLabel}
            Options={optionsVendor}
            onSelect={hadleChangeVendor}
            CustomErrorLine={vendorCustomError}
            searchable={false}
            disable={false}
            starProps={false}
            isValidate={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormDropDown
            isMulti={true}
            defaultValue={modeOfSubmission.value}
            Label={modeOfSubmissionLabel}
            Options={optionModSubmission}
            onSelect={handleChangeModeOfSubmission}
            // CustomErrorLine={modeOfSubmissionCustomError}
            searchable={false}
            disable={false}
            starProps={false}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormDropDown
            isMulti={false}
            value={incoterm.value}
            Label={IncotermLabel}
            Options={optionsIncoterm}
            onSelect={handleChangeIncoterm}
            CustomErrorLine={IncotermCustomError}
            searchable={false}
            disable={false}
            starProps={true}
            isValidate={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormTextInput
            type="textarea"
            label={importantNotesLabel}
            placeholder={importantNotesPlaceholder}
            Value={importantNotes.value}
            onChangeText={handleChangeImportantNotes}
            Validate={false}
            CustomErrorLine={importantNotesCustomError}
            multiline={true}
            starProps={false}
            editable
          />
        </div>
        {/* <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormTextInput
            type="textarea"
            label={tenderNotesLabel}
            placeholder={tenderNotesPlaceholder}
            Value={tendorNotes.value}
            onChangeText={handleChangeTendorNotes}
            Validate={false}
            CustomErrorLine={tenderNotesCustomError}
            multiline={true}
            starProps={false}
          />
        </div> */}
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "1px",
              marginTop: 15,
            }}
          >
            Email Id
            <Tooltip
              title="You Can Enter Multiple Email id"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailAddress}
              onChange={(emails) => {
                setEmailAddress(emails);
              }}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "1px",
              marginTop: 15,
            }}
          >
            CC To
            <Tooltip
              title="You Can Enter Multiple CC"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailCcAddress}
              onChange={(emails) => {
                setEmailCcAddress(emails);
              }}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
          {/* multi level email end */}
        </div>
      </div>
      <div className="row">
        {/* <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={"RFQ Reference"}
            placeholder={"Enter RFQ Reference"}
            Value={rfqReferance.value}
            onChangeText={handleChangeRfqRefNo}
            Validate={true}
            CustomErrorLine={"Enter RFQ Reference"}
            starProps={true}
          />
        </div> */}
        {/* <div className="col-12 col-md-6 pe-0 pe-md-3">
          
        </div> */}
      </div>

      <div className="mb-4" style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          disable={actionType === "add" ? disable : false}
          onClick={handleSubmit}
          buttonStyle={{ backgroundColor: colors.primary }}
        />
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
      {/* {actionType === "add" ? <h2 className="my-3">Budget Items</h2> : null} */}
      {filterData && (
        <RequestForQuotationTable
          data={tempData}
          mapableData={mapableData}
          actionType={actionType}
          handleChangeCheck={handleChangeSelectedData}
        />
      )}
    </div>
  );
};

export default RequestForQuotationForm;
