import React, { useEffect } from "react";
import {
  Table,
  FormDropDown,
  FormTextInput,
  Button,
  FormCheckBox,
} from "../../../components";
import Accordion from "../../../components/common/Accordion";
import { colors } from "../../../configs";
import strings from "../../../translations";

const AssetRegisterForm = (props) => {
  const {
    handleBudgetItemData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const Data = [
    {
      rfi: "rfi data",
      rfq: "rfq data",
      po: "po data",
      invoice: "invoice 1",
      deliverynotes: "delivery notes",
      deliveryReconcilation: " delivery reconcilation",
    },
  ];
  const fields = [
    "id",
    "rfi",
    "rfq",
    "po",
    "invoice",
    "deliverynotes",
    "deliveryReconcilation",
  ];
  const head = [
    {
      id: "rfi",
      label: "RFI",
    },
    {
      id: "rfq",
      label: "RFQ",
    },
    {
      id: "po",
      label: "PO",
    },
    {
      id: "invoice",
      label: "INVOICE",
    },
    {
      id: "deliverynotes",
      label: "Delivery Notes",
    },
    {
      id: "deliveryReconcilation",
      label: "Delivery Reconcilation",
    },
  ];
  const [itemSerialNo, setItemSerialNo] = React.useState(
    initialValue.itemSerialNo ? initialValue.itemSerialNo : 1
  );
  const [itemName, setItemName] = React.useState({
    value: initialValue.itemName ? initialValue.itemName : "",
    error: false,
    success: false,
  });
  const [itemDescription, setItemDescription] = React.useState({
    value: initialValue.itemDescription ? initialValue.itemDescription : "",
    error: false,
    success: false,
  });
  const [itemGroup, setItemGroup] = React.useState({
    value: initialValue.itemGroup ? initialValue.itemGroup : "",
    error: false,
    success: false,
  });
  const [itemSubGroup, setItemSubGroup] = React.useState({
    value: initialValue.itemSubGroup ? initialValue.itemSubGroup : "",
    error: false,
    success: false,
  });
  const [itemCategory, setItemCategory] = React.useState({
    value: initialValue.itemCategory ? initialValue.itemCategory : "",
    error: false,
    success: false,
  });
  const [itemBrand, setItemBrand] = React.useState({
    value: initialValue.itemBrand ? initialValue.itemBrand : "",
    error: false,
    success: false,
  });
  const [tags, setTags] = React.useState({
    value: initialValue.tags ? initialValue.tags : "",
    error: false,
    success: false,
  });

  const [numberOFUnits, setNumberOFUnits] = React.useState({
    value: initialValue.numberOFUnits ? initialValue.numberOFUnits : "",
    error: false,
    success: false,
  });
  const [budgetUOM, setBudgetUOM] = React.useState({
    value: initialValue.budgetUOM ? initialValue.budgetUOM : "",
    error: false,
    success: false,
  });
  const [budgetQuantity, setBudgetQuantity] = React.useState({
    value: initialValue.budgetQuantity ? initialValue.budgetQuantity : "",
    error: false,
    success: false,
  });
  const [budgetCurrency, setBudgetCurrency] = React.useState({
    value: initialValue.budgetCurrency ? initialValue.budgetCurrency : "",
    error: false,
    success: false,
  });
  const [budgetPrice, setBudgetPrice] = React.useState({
    value: initialValue.budgetPrice ? initialValue.budgetPrice : "",
    error: false,
    success: false,
  });
  const [actualUOM, setActualUOM] = React.useState({
    value: initialValue.actualUOM ? initialValue.actualUOM : "",
    error: false,
    success: false,
  });
  const [actualQuantity, setActualQuantity] = React.useState({
    value: initialValue.actualQuantity ? initialValue.actualQuantity : "",
    error: false,
    success: false,
  });
  const [actualCurrency, setActualCurrency] = React.useState({
    value: initialValue.actualCurrency ? initialValue.actualCurrency : "",
    error: false,
    success: false,
  });
  const [actualPrice, setActualPrice] = React.useState({
    value: initialValue.actualPrice ? initialValue.actualPrice : "",
    error: false,
    success: false,
  });
  const [sellingPrice, setSellingPrice] = React.useState({
    value: initialValue.sellingPrice ? initialValue.sellingPrice : "",
    error: false,
    success: false,
  });
  const [totalBudgetQuantity, setTotalBudgetQuantity] = React.useState(1);
  const [totalActualQuantity, settotalActualQuantity] = React.useState(1);

  const [specifiedVendor, setSpecifiedVendor] = React.useState({
    value: initialValue.specifiedVendor ? initialValue.specifiedVendor : "",
    error: false,
    success: false,
  });
  const [vendor, setVendor] = React.useState({
    value: initialValue.vendor ? initialValue.vendor : "",
    error: false,
    success: false,
  });
  const [shiptoVendor, setShiptoVendor] = React.useState({
    value: initialValue.shiptoVendor ? initialValue.shiptoVendor : "",
    error: false,
    success: false,
  });

  const [modelNumber, setModelNumber] = React.useState({
    value: initialValue.modelNumber ? initialValue.modelNumber : "",
    error: false,
    success: false,
  });
  const [size, setSize] = React.useState({
    value: initialValue.size ? initialValue.size : "",
    error: false,
    success: false,
  });
  const [notes, setNotes] = React.useState({
    value: initialValue.notes ? initialValue.notes : "",
    error: false,
    success: false,
  });
  const [roles, setRoles] = React.useState([
    { key: 1, functionName: "Submittal Type 1", checked: false },
    { key: 2, functionName: "Submittal Type 2", checked: false },
    { key: 3, functionName: "Submittal Type 3", checked: false },
  ]);

  const [accordion1, setAccordion1] = React.useState(true);
  const [accordion2, setAccordion2] = React.useState(false);
  const [accordion3, setAccordion3] = React.useState(false);
  const [accordion4, setAccordion4] = React.useState(false);
  useEffect(() => {
    if (actionType !== "add") {
      let newRoles = roles.filter(function (objFromA) {
        return !initialValue.roles.find(function (objFromB) {
          return objFromA.key === objFromB.key;
        });
      });
      setRoles([...initialValue.roles, ...newRoles]);
    }
  }, [initialValue]);
  const handleSaveData = () => {
    const budgetItemData = {
      itemSerialNo: itemSerialNo,
      itemName: itemName.value,
      itemDescription: itemDescription.value,
      itemGroup: itemGroup.value,
      itemSubGroup: itemSubGroup.value,
      itemCategory: itemCategory.value,
      itemBrand: itemBrand.value,
      tags: tags.value,
      numberOFUnits: numberOFUnits.value,
      budgetUOM: budgetUOM.value,
      budgetQuantity: budgetQuantity.value,
      budgetCurrency: budgetCurrency.value,
      budgetPrice: budgetPrice.value,
      actualUOM: actualUOM.value,
      actualQuantity: actualQuantity.value,
      actualCurrency: actualCurrency.value,
      actualPrice: actualPrice.value,
      sellingPrice: sellingPrice.value,
      specifiedVendor: specifiedVendor.value,
      vendor: vendor.value,
      shiptoVendor: shiptoVendor.value,
      modelNumber: modelNumber.value,
      size: size.value,
      notes: notes.value,
      roles: functionResultTrue(roles),
    };
    handleBudgetItemData(budgetItemData);
  };
  const functionResultTrue = (roles) => {
    const resultArray = [];
    roles.forEach((func) => {
      if (func.checked === true) {
        resultArray.push(func.functionName);
      }
    });
    return resultArray;
  };

  const itemGroupOption = [
    {
      key: 1,
      value: "itemGroup 1",
      label: "itemGroup 1",
    },
    {
      key: 2,
      value: "itemGroup 2",
      label: "itemGroup 2",
    },
    {
      key: 3,
      value: "itemGroup 3",
      label: "itemGroup 3",
    },
  ];
  const itemSubGroupOption = [
    {
      key: 1,
      value: "itemSubGroup 1",
      label: "itemSubGroup 1",
    },
    {
      key: 2,
      value: "itemSubGroup 2",
      label: "itemSubGroup 2",
    },
    {
      key: 3,
      value: "itemSubGroup 3",
      label: "itemSubGroup 3",
    },
  ];
  const itemCategoryOption = [
    {
      key: 1,
      value: "itemCategory 1",
      label: "itemCategory 1",
    },
    {
      key: 2,
      value: "itemCategory 2",
      label: "itemCategory 2",
    },
    {
      key: 3,
      value: "itemCategory 3",
      label: "itemCategory 3",
    },
  ];
  const budgetUOMOption = [
    {
      key: 1,
      value: "meter",
      label: "meter",
    },
    {
      key: 2,
      value: "inch",
      label: "inch",
    },
    {
      key: 3,
      value: "feet",
      label: "feet",
    },
  ];
  const budgetCurrencyOption = [
    {
      key: 1,
      value: "INR",
      label: "INR",
    },
    {
      key: 2,
      value: "AED",
      label: "AED",
    },
    {
      key: 3,
      value: "Dollar",
      label: "Dollar",
    },
  ];
  const actualUOMOption = [
    {
      key: 1,
      value: "meter",
      label: "meter",
    },
    {
      key: 2,
      value: "inch",
      label: "inch",
    },
    {
      key: 3,
      value: "feet",
      label: "feet",
    },
  ];
  const actualCurrencyOption = [
    {
      key: 1,
      value: "INR",
      label: "INR",
    },
    {
      key: 2,
      value: "AED",
      label: "AED",
    },
    {
      key: 3,
      value: "Dollar",
      label: "Dollar",
    },
  ];
  const specifiedVendorOption = [
    {
      key: 1,
      value: "Admin",
      label: "Admin",
    },
    {
      key: 2,
      value: "Client",
      label: "Client",
    },
    {
      key: 3,
      value: "Vendor",
      label: "Vendor",
    },
  ];
  const vendorOption = [
    {
      key: 1,
      value: "Admin",
      label: "Admin",
    },
    {
      key: 2,
      value: "Client",
      label: "Client",
    },
    {
      key: 3,
      value: "Vendor",
      label: "Vendor",
    },
  ];
  const shiptoVendorOption = [
    {
      key: 1,
      value: "Admin",
      label: "Admin",
    },
    {
      key: 2,
      value: "Client",
      label: "Client",
    },
    {
      key: 3,
      value: "Vendor",
      label: "Vendor",
    },
  ];
  const handleChangeSerial = (value) => {
    setItemSerialNo(value);
  };
  const handleChangeItemName = (value) => {
    setItemName(value);
  };
  const handleChangeitemDescription = (value) => {
    setItemDescription(value);
  };
  const handleChangeitemGroup = (value) => {
    setItemGroup(value);
  };
  const handleChangeitemSubGroup = (value) => {
    setItemSubGroup(value);
  };
  const handleChangeitemCategory = (value) => {
    setItemCategory(value);
  };
  const handleChangeitemBrand = (value) => {
    setItemBrand(value);
  };
  const handleChangetags = (value) => {
    setTags(value);
  };
  const handleChangeModelNumber = (value) => {
    setModelNumber(value);
  };
  const handleChangenumberOFUnits = (value) => {
    let bugetT = budgetQuantity.value ? budgetQuantity.value : 0;
    let actual = actualQuantity.value ? actualQuantity.value : 0;
    setNumberOFUnits(value);
    if (value && bugetT) {
      setTotalBudgetQuantity(parseInt(value.value) * parseInt(bugetT));
    }
    if (value && actual) {
      settotalActualQuantity(parseInt(value.value) * parseInt(actual));
    }
  };
  const handleChangeBudgetUOM = (value) => {
    setBudgetUOM(value);
  };
  const handleChangeBudgetQuantity = (value) => {
    let numT = numberOFUnits.value ? numberOFUnits.value : 0;
    setBudgetQuantity(value);
    if (value && numT) {
      setTotalBudgetQuantity(parseInt(value.value) * parseInt(numT));
    }
  };
  const handleChangeBudgetCurrency = (value) => {
    setBudgetCurrency(value);
  };
  const handleChangebudgetPrice = (value) => {
    setBudgetPrice(value);
  };
  const handleChangeactualUOM = (value) => {
    setActualUOM(value);
  };
  const handleChangeActualQuantity = (value) => {
    setActualQuantity(value);
    let actual = numberOFUnits.value ? numberOFUnits.value : 0;

    if (value && actual) {
      settotalActualQuantity(parseInt(value.value) * parseInt(actual));
    }
  };
  const handleChangeTotalActualQuantity = () => {};
  const handleChangeactualCurrency = (value) => {
    setActualCurrency(value);
  };
  const handleChangeactualPrice = (value) => {
    setActualPrice(value);
  };
  const handleChangesellingPrice = (value) => {
    setSellingPrice(value);
  };
  const handleChangeSpecifiedVendor = (value) => {
    setSpecifiedVendor(value);
  };
  const handleChangeVendor = (value) => {
    setVendor(value);
  };
  const handleChangeShiptoVendor = (value) => {
    setShiptoVendor(value);
  };
  const handleChangeSize = (value) => {
    setSize(value);
  };
  const handleChangeNotes = (value) => {
    setNotes(value);
  };
  const handleChangeCheck = (key) => {
    const result = roles.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setRoles(result);
  };

  let isValueNotChanged =
    itemName.value === initialValue.itemName &&
    itemDescription.value === initialValue.itemDescription &&
    itemGroup.value === initialValue.itemGroup &&
    itemSubGroup.value === initialValue.itemSubGroup &&
    itemCategory.value === initialValue.itemCategory &&
    itemBrand.value === initialValue.itemBrand &&
    tags.value === initialValue.tags &&
    numberOFUnits.value === initialValue.numberOFUnits &&
    budgetUOM.value === initialValue.budgetUOM &&
    budgetQuantity.value === initialValue.budgetQuantity &&
    budgetCurrency.value === initialValue.budgetCurrency &&
    budgetPrice.value === initialValue.budgetPrice &&
    actualUOM.value === initialValue.actualUOM &&
    actualQuantity.value === initialValue.actualQuantity &&
    actualCurrency.value === initialValue.actualCurrency &&
    actualPrice.value === initialValue.actualPrice &&
    sellingPrice.value === initialValue.sellingPrice &&
    specifiedVendor.value === initialValue.specifiedVendor &&
    vendor.value === initialValue.vendor &&
    shiptoVendor.value === initialValue.shiptoVendor &&
    modelNumber.value === initialValue.modelNumber &&
    size.value === initialValue.size &&
    notes.value === initialValue.notes;

  let disable =
    itemName.error ||
    itemName.value === "" ||
    itemDescription.error ||
    itemDescription.value === "" ||
    itemGroup.error ||
    itemGroup.value === "" ||
    itemSubGroup.value === "" ||
    itemSubGroup.error ||
    itemCategory.value === "" ||
    itemCategory.error ||
    itemBrand.value === "" ||
    itemBrand.error ||
    tags.value === "" ||
    tags.error ||
    numberOFUnits.error ||
    numberOFUnits.value === "" ||
    budgetUOM.error ||
    budgetUOM.value === "" ||
    budgetQuantity.error ||
    budgetQuantity.value === "" ||
    budgetCurrency.value === "" ||
    budgetCurrency.error ||
    budgetPrice.value === "" ||
    budgetPrice.error ||
    actualUOM.value === "" ||
    actualUOM.error ||
    actualQuantity.value === "" ||
    actualQuantity.error ||
    actualCurrency.value === "" ||
    actualCurrency.error ||
    actualPrice.value === "" ||
    actualPrice.error ||
    sellingPrice.value === "" ||
    sellingPrice.error ||
    modelNumber.error ||
    modelNumber.value === "" ||
    size.error ||
    size.value === "" ||
    notes.error ||
    notes.value === "" ||
    (itemName.success === false &&
      itemDescription.success === false &&
      itemGroup.success === false &&
      itemSubGroup.success === false &&
      itemCategory.success === false &&
      itemBrand.success === false &&
      tags.success === false &&
      numberOFUnits.success === false &&
      budgetUOM.success === false &&
      budgetQuantity.success === false &&
      budgetCurrency.success === false &&
      budgetPrice.success === false &&
      actualUOM.success === false &&
      actualQuantity.success === false &&
      actualCurrency.success === false &&
      actualPrice.success === false &&
      sellingPrice.success === false &&
      specifiedVendor.success === false &&
      vendor.success === false &&
      shiptoVendor.success === false &&
      modelNumber.success === false &&
      size.success === false &&
      notes.success === false) ||
    isValueNotChanged ||
    roles.every((functionName) => {
      return !functionName.checked;
    });

  const createBudgetItemHeader = strings["BudgetItem.createBudgetItemHeader"],
    editBudgetItemHeader = strings["BudgetItem.editBudgetItemHeader"],
    itemSerialLabel = strings["BudgetItem.itemSerialLabel"],
    itemNameLAbel = strings["BudgetItem.itemNameLAbel"],
    itemNamePlaceholder = strings["BudgetItem.itemNamePlaceholder"],
    itemNameError = strings["BudgetItem.itemNameError"],
    itemDescriptionLabel = strings["BudgetItem.itemDescriptionLabel"],
    itemDescriptionPlaceholder =
      strings["BudgetItem.itemDescriptionPlaceholder"],
    itemDescriptionError = strings["BudgetItem.itemDescriptionError"],
    itemGroupLabel = strings["BudgetItem.itemGroupLabel"],
    itemGroupErrorLine = strings["BudgetItem.itemGroupErrorLine"],
    itemGroupSelectText = strings["BudgetItem.itemGroupSelectText"],
    itemSubGroupLabel = strings["BudgetItem.itemSubGroupLabel"],
    itemSubGroupErrorLine = strings["BudgetItem.itemSubGroupErrorLine"],
    itemSubGroupSelectText = strings["BudgetItem.itemSubGroupSelectText"],
    itemCategoryLabel = strings["BudgetItem.itemCategoryLabel"],
    itemCategoryErrorLine = strings["BudgetItem.itemCategoryErrorLine"],
    itemCategorySelectText = strings["BudgetItem.itemCategorySelectText"],
    itemBrandLabel = strings["BudgetItem.itemBrandLabel"],
    itemBrandPlaceholder = strings["BudgetItem.itemBrandPlaceholder"],
    itemBrandError = strings["BudgetItem.itemBrandError"],
    tagsLabel = strings["BudgetItem.tagsLabel"],
    tagsPlaceholder = strings["BudgetItem.tagsPlaceholder"],
    tagsError = strings["BudgetItem.tagsError"],
    numberOFUnitsLabel = strings["BudgetItem.numberOFUnitsLabel"],
    numberOFUnitsPlaceholder = strings["BudgetItem.numberOFUnitsPlaceholder"],
    numberOFUnitsError = strings["BudgetItem.numberOFUnitsError"],
    budgetUOMLabel = strings["BudgetItem.budgetUOMLabel"],
    budgetUOMErrorLine = strings["BudgetItem.budgetUOMErrorLine"],
    budgetUOMSelectText = strings["BudgetItem.budgetUOMSelectText"],
    budgetQuantityLabel = strings["BudgetItem.budgetQuantityLabel"],
    budgetQuantityPlaceholder = strings["BudgetItem.budgetQuantityPlaceholder"],
    budgetQuantityError = strings["BudgetItem.budgetQuantityError"],
    totalBudgetQuantityLabel = strings["BudgetItem.totalBudgetQuantityLabel"],
    totalBudgetQuantityPlaceholder =
      strings["BudgetItem.totalBudgetQuantityPlaceholder"],
    totalBudgetQuantityError = strings["BudgetItem.totalBudgetQuantityError"],
    budgetCurrencyLabel = strings["BudgetItem.budgetCurrencyLabel"],
    budgetCurrencyErrorLine = strings["BudgetItem.budgetCurrencyErrorLine"],
    budgetCurrencySelectText = strings["BudgetItem.budgetCurrencySelectText"],
    budgetPriceLabel = strings["BudgetItem.budgetPriceLabel"],
    budgetPricePlaceholder = strings["BudgetItem.budgetPricePlaceholder"],
    budgetPriceError = strings["BudgetItem.budgetPriceError"],
    actualtUOMLabel = strings["BudgetItem.actualtUOMLabel"],
    actualUOMErrorLine = strings["BudgetItem.actualUOMErrorLine"],
    actualUOMSelectText = strings["BudgetItem.actualUOMSelectText"],
    actualQuantityLabel = strings["BudgetItem.actualQuantityLabel"],
    actualQuantityPlaceholder = strings["BudgetItem.actualQuantityPlaceholder"],
    actualQuantityError = strings["BudgetItem.actualQuantityError"],
    totalActualQuantityLabel = strings["BudgetItem.totalActualQuantityLabel"],
    totalActualQuantityPlaceholder =
      strings["BudgetItem.totalActualQuantityPlaceholder"],
    totalActualQuantityError = strings["BudgetItem.totalActualQuantityError"],
    actualCurrencyLabel = strings["BudgetItem.actualCurrencyLabel"],
    actualCurrencyErrorLine = strings["BudgetItem.actualCurrencyErrorLine"],
    actualCurrencySelectText = strings["BudgetItem.actualCurrencySelectText"],
    actualPriceLabel = strings["BudgetItem.actualPriceLabel"],
    actualPricePlaceholder = strings["BudgetItem.actualPricePlaceholder"],
    actualPriceError = strings["BudgetItem.actualPriceError"],
    sellingPriceLabel = strings["BudgetItem.sellingPriceLabel"],
    sellingPricePlaceholder = strings["BudgetItem.sellingPricePlaceholder"],
    sellingPriceError = strings["BudgetItem.sellingPriceError"],
    specifiedVendorLabel = strings["BudgetItem.specifiedVendorLabel"],
    specifiedVendorErrorLine = strings["BudgetItem.specifiedVendorErrorLine"],
    specifiedVendorSelectText = strings["BudgetItem.specifiedVendorSelectText"],
    vendorLabel = strings["BudgetItem.vendorLabel"],
    vendorErrorLine = strings["BudgetItem.vendorErrorLine"],
    vendorSelectText = strings["BudgetItem.vendorSelectText"],
    shiptoVendorLabel = strings["BudgetItem.shiptoVendorLabel"],
    shiptoVendorErrorLine = strings["BudgetItem.shiptoVendorErrorLine"],
    shiptoVendorSelectText = strings["BudgetItem.shiptoVendorSelectText"],
    modelNumberLabel = strings["BudgetItem.modelNumberLabel"],
    modelNumberPlaceholder = strings["BudgetItem.modelNumberPlaceholder"],
    modelNumberError = strings["BudgetItem.modelNumberError"],
    sizeLabel = strings["BudgetItem.sizeLabel"],
    sizePlaceholder = strings["BudgetItem.sizePlaceholder"],
    sizeError = strings["BudgetItem.sizeError"],
    notesLabel = strings["BudgetItem.notesLabel"],
    notesPlaceholder = strings["BudgetItem.notesPlaceholder"],
    notesError = strings["BudgetItem.notesError"],
    saveBtn = strings["BudgetItem.saveBtn"],
    updateBtn = strings["BudgetItem.updateBtn"],
    cancelBtn = strings["BudgetItem.cancelBtn"],
    itemSubmittalHeader = strings["BudgetItem.itemSubmittalHeader"];
  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add" ? createBudgetItemHeader : editBudgetItemHeader}
      </h2>
      <div className="row">
        <h3>Item List</h3>
        {/ Accordion 1 /}
        <div>
          <Accordion title={"Item List"} defaultExpanded={accordion1}>
            <div className="row">
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormTextInput
                  type="number"
                  label={itemSerialLabel}
                  Value={itemSerialNo}
                  onChangeText={handleChangeSerial}
                  Validate={true}
                  CustomErrorLine={""}
                  editable={false}
                />
                <FormTextInput
                  type="text"
                  label={itemNameLAbel}
                  placeholder={itemNamePlaceholder}
                  Value={itemName.value}
                  onChangeText={handleChangeItemName}
                  Validate={true}
                  CustomErrorLine={itemNameError}
                  editable={true}
                />
                <FormTextInput
                  type="alpha"
                  label={itemDescriptionLabel}
                  placeholder={itemDescriptionPlaceholder}
                  Value={itemDescription.value}
                  onChangeText={handleChangeitemDescription}
                  Validate={true}
                  CustomErrorLine={itemDescriptionError}
                  editable={true}
                  multiline={true}
                />
                <FormDropDown
                  Label={itemGroupLabel}
                  Options={itemGroupOption}
                  value={itemGroup.value}
                  onSelect={handleChangeitemGroup}
                  customErrorLine={itemGroupErrorLine}
                  selectText={itemGroupSelectText}
                  searchable={false}
                />
              </div>
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormDropDown
                  Label={itemSubGroupLabel}
                  Options={itemSubGroupOption}
                  value={itemSubGroup.value}
                  onSelect={handleChangeitemSubGroup}
                  customErrorLine={itemSubGroupErrorLine}
                  selectText={itemSubGroupSelectText}
                  searchable={false}
                />
                <FormDropDown
                  Label={itemCategoryLabel}
                  Options={itemCategoryOption}
                  value={itemCategory.value}
                  onSelect={handleChangeitemCategory}
                  customErrorLine={itemCategoryErrorLine}
                  selectText={itemCategorySelectText}
                  searchable={false}
                />
                <FormTextInput
                  type="alpha"
                  label={itemBrandLabel}
                  placeholder={itemBrandPlaceholder}
                  Value={itemBrand.value}
                  onChangeText={handleChangeitemBrand}
                  Validate={true}
                  CustomErrorLine={itemBrandError}
                  editable={true}
                />
                <FormTextInput
                  type="alpha"
                  label={tagsLabel}
                  placeholder={tagsPlaceholder}
                  Value={tags.value}
                  onChangeText={handleChangetags}
                  Validate={true}
                  CustomErrorLine={tagsError}
                  editable={true}
                  onKeyDown={(ev) => {
                    if (ev.key === "Tab") {
                      ev.preventDefault();
                      setAccordion2(true);
                      setAccordion1(false);
                    }
                  }}
                />
              </div>
            </div>
          </Accordion>
        </div>
        {/ Accordion 2 /}
        <div>
          <Accordion title={"Quantity List"} defaultExpanded={accordion2}>
            <div className="row">
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormTextInput
                  type="number"
                  label={numberOFUnitsLabel}
                  placeholder={numberOFUnitsPlaceholder}
                  Value={numberOFUnits.value}
                  onChangeText={handleChangenumberOFUnits}
                  Validate={true}
                  CustomErrorLine={numberOFUnitsError}
                  editable={true}
                />
                <FormDropDown
                  Label={budgetUOMLabel}
                  Options={budgetUOMOption}
                  value={budgetUOM.value}
                  onSelect={handleChangeBudgetUOM}
                  customErrorLine={budgetUOMErrorLine}
                  selectText={budgetUOMSelectText}
                  searchable={false}
                />
                <FormTextInput
                  type="number"
                  label={budgetQuantityLabel}
                  placeholder={budgetQuantityPlaceholder}
                  Value={budgetQuantity.value}
                  onChangeText={handleChangeBudgetQuantity}
                  Validate={true}
                  CustomErrorLine={budgetQuantityError}
                  editable={true}
                />
                <FormTextInput
                  type="number"
                  label={totalBudgetQuantityLabel}
                  placeholder={totalBudgetQuantityPlaceholder}
                  Value={totalBudgetQuantity}
                  // onChangeText={handleChangeTotalBudgetQuantity}
                  Validate={true}
                  CustomErrorLine={totalBudgetQuantityError}
                  editable={false}
                />
                <FormDropDown
                  Label={budgetCurrencyLabel}
                  Options={budgetCurrencyOption}
                  value={budgetCurrency.value}
                  onSelect={handleChangeBudgetCurrency}
                  customErrorLine={budgetCurrencyErrorLine}
                  selectText={budgetCurrencySelectText}
                  searchable={false}
                />
                <FormTextInput
                  type="number"
                  label={budgetPriceLabel}
                  placeholder={budgetPricePlaceholder}
                  Value={budgetPrice.value}
                  onChangeText={handleChangebudgetPrice}
                  Validate={true}
                  CustomErrorLine={budgetPriceError}
                  editable={true}
                />
              </div>
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormDropDown
                  Label={actualtUOMLabel}
                  Options={actualUOMOption}
                  value={actualUOM.value}
                  onSelect={handleChangeactualUOM}
                  customErrorLine={actualUOMErrorLine}
                  selectText={actualUOMSelectText}
                  searchable={false}
                />
                <FormTextInput
                  type="number"
                  label={actualQuantityLabel}
                  placeholder={actualQuantityPlaceholder}
                  Value={actualQuantity.value}
                  onChangeText={handleChangeActualQuantity}
                  Validate={true}
                  CustomErrorLine={actualQuantityError}
                  editable={true}
                />
                <FormTextInput
                  type="number"
                  label={totalActualQuantityLabel}
                  placeholder={totalActualQuantityPlaceholder}
                  Value={totalActualQuantity}
                  onChangeText={handleChangeTotalActualQuantity}
                  Validate={true}
                  CustomErrorLine={totalActualQuantityError}
                  editable={false}
                />
                <FormDropDown
                  Label={actualCurrencyLabel}
                  Options={actualCurrencyOption}
                  value={actualCurrency.value}
                  onSelect={handleChangeactualCurrency}
                  customErrorLine={actualCurrencyErrorLine}
                  selectText={actualCurrencySelectText}
                  searchable={false}
                />
                <FormTextInput
                  type="number"
                  label={actualPriceLabel}
                  placeholder={actualPricePlaceholder}
                  Value={actualPrice.value}
                  onChangeText={handleChangeactualPrice}
                  Validate={true}
                  CustomErrorLine={actualPriceError}
                  editable={true}
                />
                <FormTextInput
                  type="number"
                  label={sellingPriceLabel}
                  placeholder={sellingPricePlaceholder}
                  Value={sellingPrice.value}
                  onChangeText={handleChangesellingPrice}
                  Validate={true}
                  CustomErrorLine={sellingPriceError}
                  editable={true}
                  onKeyDown={(ev) => {
                    if (ev.key === "Tab") {
                      ev.preventDefault();
                      setAccordion3(true);
                      setAccordion2(false);
                    }
                  }}
                />
              </div>
            </div>
          </Accordion>
        </div>
        {/ Accordion 3 /}
        <div>
          <Accordion title={"Vendor Details"} defaultExpanded={accordion3}>
            <div className="row">
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormDropDown
                  Label={specifiedVendorLabel}
                  Options={specifiedVendorOption}
                  value={specifiedVendor.value}
                  onSelect={handleChangeSpecifiedVendor}
                  customErrorLine={specifiedVendorErrorLine}
                  selectText={specifiedVendorSelectText}
                  searchable={false}
                />
                <FormDropDown
                  Label={vendorLabel}
                  Options={vendorOption}
                  value={vendor.value}
                  onSelect={handleChangeVendor}
                  customErrorLine={vendorErrorLine}
                  selectText={vendorSelectText}
                  searchable={false}
                />
              </div>
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormDropDown
                  Label={shiptoVendorLabel}
                  Options={shiptoVendorOption}
                  value={shiptoVendor.value}
                  onSelect={handleChangeShiptoVendor}
                  customErrorLine={shiptoVendorErrorLine}
                  selectText={shiptoVendorSelectText}
                  searchable={false}
                  onKeyDown={(ev) => {
                    if (ev.key === "Tab") {
                      ev.preventDefault();
                      setAccordion4(true);
                      setAccordion3(false);
                    }
                  }}
                />
              </div>
            </div>
          </Accordion>
        </div>
        <div>
          <Accordion title={"Model Details"} defaultExpanded={accordion4}>
            <div className="row">
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormTextInput
                  type="alpha"
                  label={modelNumberLabel}
                  placeholder={modelNumberPlaceholder}
                  Value={modelNumber.value}
                  onChangeText={handleChangeModelNumber}
                  Validate={true}
                  CustomErrorLine={modelNumberError}
                  editable={true}
                />
                <FormTextInput
                  type="alpha"
                  label={sizeLabel}
                  placeholder={sizePlaceholder}
                  Value={size.value}
                  onChangeText={handleChangeSize}
                  Validate={true}
                  CustomErrorLine={sizeError}
                  editable={true}
                  multiline={true}
                />
              </div>
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <FormTextInput
                  type="alpha"
                  label={notesLabel}
                  placeholder={notesPlaceholder}
                  Value={notes.value}
                  onChangeText={handleChangeNotes}
                  Validate={true}
                  CustomErrorLine={notesError}
                  editable={true}
                  multiline={true}
                />
              </div>
            </div>
            <div className="row">
              <h4>{itemSubmittalHeader}</h4>
              {roles.map((functionName) => {
                return (
                  <div className={"col-12 col-md-3"}>
                    <FormCheckBox
                      key={functionName.key}
                      Label={functionName.functionName}
                      checked={functionName.checked}
                      handleChangeCheck={() =>
                        handleChangeCheck(functionName.key)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Accordion>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}></div>
      <h5>Documents</h5>
      <Table
        theadData={head}
        tbodyData={Data}
        fields={fields}
        isDelete={false}
        isEdit={false}
      />
      <div className="row">
        <div
          style={{
            display: "flex",
            marginTop: "1.5rem",
            justifyContent: "space-between",
          }}
        >
          <Button
            buttonName={cancelBtn}
            buttonStyle={{ backgroundColor: colors.darkgrey }}
            onClick={() => {
              handleModalClose();
            }}
          />
          <Button
            buttonName={actionType === "add" ? saveBtn : updateBtn}
            buttonStyle={{ backgroundColor: colors.primary }}
            disable={disable}
            onClick={handleSaveData}
          />
        </div>
      </div>
    </div>
  );
};

export default AssetRegisterForm;
