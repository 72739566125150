import React, { useState, useEffect } from 'react';


const setItemToStorage = (key,data) => {
    localStorage.setItem(key,JSON.stringify(data));
}

const getItemFromStorage = (key) => {
    return localStorage.getItem(key);
}

export {setItemToStorage,getItemFromStorage}

