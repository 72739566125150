/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ModuleForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createModuleTitle = Strings["Module.CreateModuleHeader"],
    editModuletitle = Strings["Module.EditModuleHeader"],
    moduleNameLabel = Strings["Module.CreateModuleName"],
    moduleNamePlaceholder = Strings["Module.CreateModuleNamePlaceholder"],
    moduleNameCustomError = Strings["Module.CreateModuleNameCustomError"],
    moduleDescriptionLabel = Strings["Module.CreateModuleDescriptionLabel"],
    moduleDescriptionPlaceholder =
      Strings["Module.CreateModuleDescriptionPlaceholder"],
    moduleDescriptionCustomError =
      Strings["Module.CreateModuleDescriptionCustomError"],
    cancelButton = Strings["Module.CreateModuleCancelButton"],
    saveButton = Strings["Module.CreateModuleSaveButton"],
    updateBtn = Strings["Module.EditModuleSaveButton"];

  const [moduleName, setModuleName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    sucess: false,
  });

  const [moduleDescription, setModuleDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    sucess: false,
  });

  const handleChangeModule = (value) => {
    setMessage(null);
    setModuleName(value);
  };

  const handleChangeModuleDescription = (value) => {
    setMessage(null);
    setModuleDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: moduleName.value,
      description: moduleDescription.value,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged = moduleName.value === initialValue.name;
  // moduleDescription.value === initialValue.description;

  let disable =
    moduleName.error ||
    // moduleDescription.error ||
    moduleName.value === "" ||
    // moduleDescription.value === "" ||
    moduleName.sucess === false ||
    // moduleDescription.sucess === false
    isValueNOTChanged;

  let isValueNOTChangededit =
    moduleName.value === initialValue.moduleName &&
    moduleDescription.value === initialValue.moduleDescription;

  // let Editdisable =
  //   moduleName.error ||
  //   moduleDescription.error ||
  //   moduleName.value === "" ||
  //   moduleDescription.value === "" ||
  //   (moduleName.sucess === false && moduleDescription.sucess === false) ||
  //   isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createModuleTitle : editModuletitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        label={moduleNameLabel}
        placeholder={moduleNamePlaceholder}
        Value={moduleName.value}
        onChangeText={handleChangeModule}
        Validate={true}
        CustomErrorLine={moduleNameCustomError}
        editable={true}
        starProps={true}
      />

      <FormTextInput
        type="text"
        label={moduleDescriptionLabel}
        placeholder={moduleDescriptionPlaceholder}
        Value={moduleDescription.value}
        onChangeText={handleChangeModuleDescription}
        Validate={false}
        CustomErrorLine={moduleDescriptionCustomError}
        multiline={false}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default ModuleForm;
