import * as React from "react";
import swal from "sweetalert";
import {
  CreateUserRoleMap,
  GetUserRoleMap,
  UpdateUserRoleMapping,
} from "../../../axios";
import {
  AddIcon,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchMappingTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import {
  CreateUserRoleMappingForm,
  DeleteUserRoleMappingForm,
} from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CREATE_USER_ROLE_MAPPING,
  UPDATE_USER_ROLE_MAPPING,
} from "../../../utils/constants";

const converter = (rows) => {
  let result = "";
  rows.forEach((data) => {
    if (result.length === 0) {
      result = result + data.name;
    } else {
      result = result + "," + data.name;
    }
  });
  return result;
};

export default function SearchUserRoleMappingPage() {
  const searchTitle = Strings["UserRoleMapping.SearchUserRoleMappingHeader"],
    userName = Strings["UserRoleMapping.TableHead.userName"],
    roleName = Strings["UserRoleMapping.TableHead.roleName"],
    action = Strings["UserRoleMapping.TableHead.action"],
    emailPlaceholder = Strings["UserRoleMappin.Placeholder.email"];

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserMapping] = React.useState({});
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_USER_ROLE_MAPPING)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_USER_ROLE_MAPPING)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["UserRoleMapping"],
      path: "searchuserrolemapping",
    },
  ];
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const getAllUserRole = () => {
    setLoading(true);

    GetUserRoleMap(null, null, searchValue.value)
      .then((res) => {
        setRows(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllUserRole();
  }, []);

  const createUserRoleMap = (formData) => {
    setLoading(true);
    CreateUserRoleMap(formData)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Created Succesfully",
            icon: "success",
          });
          getAllUserRole();
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const updateUserRoleMap = (formData) => {
    setLoading(true);
    UpdateUserRoleMapping(formData)
      .then((res) => {
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Sucessfully",
            icon: "success",
          });
          getAllUserRole();
          setLoading(false);
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.message);
      });
  };

  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <CreateUserRoleMappingForm
          errorMessage={errorMessage}
          setMessage={setMessage}
          handleResultData={createUserRoleMap}
          actionType={"add"}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <CreateUserRoleMappingForm
          errorMessage={errorMessage}
          setMessage={setMessage}
          handleResultData={updateUserRoleMap}
          initialValue={currentType}
          actionType={"edit"}
          handleModalClose={handleModalClose}
        />
      );
    } else {
      return (
        <DeleteUserRoleMappingForm
          deleteChangeFormData={() => {}}
          initialValue={currentUserMapping}
          actionType={clickedBtn}
        />
      );
    }
  };

  const getData = (rows) => {
    const result = rows.map((data) => {
      return {
        ...data,
        roles: converter(data.roles),
      };
    });
    return result;
  };

  const searchTableData = getData(rows);

  const mapableData = [
    {
      dataKey: "email",
      title: userName,
    },
    {
      dataKey: "roles",
      title: roleName,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];
  const handleChangeEdit = (id) => {
    const result = rows.find((row) => {
      return row.id === id.id;
    });

    setCurrentType(result);
    handleSelectBtn("edit");
    openAddModal();
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setLoading(true);
      GetUserRoleMap(null, null, searchData.value)
        .then((res) => {
          setRows(res.data.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllUserRole();
  };
  return (
    <>
      <div className="container">
        {loading ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="UserbudgetTable">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length > 0 ? (
                  <>
                    <SearchMappingTable
                      isUpdate={isUpdate}
                      placeholder={emailPlaceholder}
                      data={searchTableData}
                      mapableData={mapableData}
                      searchProp={["email"]}
                      handleChangeEdit={handleChangeEdit}
                      pagination={true}
                    />
                  </>
                ) : (
                  <>
                    {loading ? (
                      <LoaderComponent />
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
