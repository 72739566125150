/* eslint-disable no-unused-vars */
import React from "react";
import { Cell, HeaderCell } from "rsuite-table";
import { colors } from "../../../configs";

const TableColumn = (props) => {
  const {
    width = 100,
    headerName,
    dataKey,
    isSortable = false,
    rowData,
    // dataKey
  } = props;

  return (
    <>
      <HeaderCell
        style={{ color: colors.primary, fontWeight: 900, fontSize: "larger" }}
      >
        {headerName}
      </HeaderCell>
      <Cell dataKey={dataKey} />
    </>
  );
};

export default TableColumn;
