import React from "react";
import { GetAllActiveStage, GetAllStageActiveStatus } from "../../../axios";
import {
  Button,
  DateAndTimePicker,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const BudgetScheduleForm = (props) => {
  const {
    sDate,
    eDate,
    budget_id,
    setMessage,
    errorMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  //   const stageOption = [
  //     {
  //       value: "Budgeting",
  //       label: "Budgeting",
  //     },
  //     {
  //       value: "RFQ",
  //       label: "RFQ",
  //     },
  //   ];

  const getStage = () => {
    setLoader(true);
    GetAllActiveStage()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((ele) => ({
          value: ele.id,
          label: ele.name,
        }));
        setStageOption(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  React.useEffect(() => {
    getStage();
  }, []);
  const [loader, setLoader] = React.useState(false);
  const [startDate, setStartDate] = React.useState({
    // value: initialValue.startDate ? initialValue.startDate : "",
    value: sDate,
    error: false,
    success: false,
  });

  const [stageOption, setStageOption] = React.useState([]);

  const [endDate, setEndDate] = React.useState({
    // value: initialValue.endDate ? initialValue.endDate : "",
    value: eDate,
    error: false,
    success: false,
  });

  const [stage, setStage] = React.useState({
    value: initialValue.stageData ? initialValue.stageData.id : "",
    error: false,
    success: false,
  });

  const [duration, setDuration] = React.useState({
    value: initialValue.duration ? initialValue.duration : "",
    error: false,
    success: false,
  });

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };

  const handleChangeStage = (data) => {
    setMessage(null);
    setStage(data);
  };

  const handleChangeDuration = (data) => {
    setDuration(data);
  };

  const getAllActiveStage = () => {
    GetAllStageActiveStatus()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setStageOption(result);
      })
      .catch(() => {
      });
  };

  React.useEffect(() => {
    getAllActiveStage();
  }, []);

  const handleSaveData = () => {
    const formData = {
      budget_id: budget_id,
      budget_start_date: startDate.value,
      budget_end_date: endDate.value,
      stage_id: stage.value,
      duration: duration.value,
    };
    handleChangeFormData(formData);
  };

  const createBudgetScheduleTitle =
      Strings["BudgetSchedule.createBudgetScheduleTitle"],
    editBudgetScheduleTitle = Strings["BudgetSchedule.editBudgetScheduleTitle"],
    budgetStartDateLabel = Strings["BudgetSchedule.budgetStartDateLabel"],
    budgetStartDateCustomErrorLine =
      Strings["BudgetSchedule.budgetStartDateCustomErrorLine"],
    budgetEndDateLabel = Strings["BudgetSchedule.budgetEndDateLabel"],
    budgetEndDateCustomErrorLine =
      Strings["BudgetSchedule.budgetEndDateCustomErrorLine"],
    stageLabel = Strings["BudgetSchedule.stageLabel"],
    stageCustomError = Strings["BudgetSchedule.stageCustomError"],
    durationLabel = Strings["BudgetSchedule.durationLabel"],
    durationPlaceholder = Strings["BudgetSchedule.durationPlaceholder"],
    durationCustomError = Strings["BudgetSchedule.durationCustomError"],
    saveBtn = Strings["BudgetSchedule.saveBtn"],
    updateBtn = Strings["BudgetSchedule.updateBtn"],
    cancelBtn = Strings["BudgetSchedule.cancelBtn"];

  let isValueChange =
    stage.value === initialValue.stage &&
    duration.value === initialValue.duration &&
    startDate.value === initialValue.startDate &&
    endDate.value === initialValue.endDate;

  let disable =
    stage.error ||
    duration.error ||
    stage.value === "" ||
    duration.value === "" ||
    startDate.value === "" ||
    endDate.value === "" ||
    (stage.success === false && duration.success === false) ||
    isValueChange;

  let EditDisabel =
    stage.value === "" ||
    duration.value === "" ||
    startDate.value === "" ||
    endDate.value === "";

  return (
    <div className="modalFormContainer">
      {loader ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>
        {actionType === "add"
          ? createBudgetScheduleTitle
          : editBudgetScheduleTitle}
      </h2>

      <DateAndTimePicker
        label={budgetStartDateLabel}
        customErrorLine={budgetStartDateCustomErrorLine}
        hadleSetDate={handleChangeStartDate}
        date={startDate.value}
        disable={true}
      />

      <DateAndTimePicker
        label={budgetEndDateLabel}
        customErrorLine={budgetEndDateCustomErrorLine}
        hadleSetDate={handleChangeEndDate}
        date={endDate.value}
        disable={true}
      />

      <FormDropDown
        Label={stageLabel}
        Options={stageOption}
        value={stage.value}
        isMulti={false}
        onSelect={handleChangeStage}
        CustomErrorLine={stageCustomError}
        disable={false}
      />

      <FormTextInput
        type="number"
        label={durationLabel}
        placeholder={durationPlaceholder}
        Value={duration.value}
        onChangeText={handleChangeDuration}
        Validate={true}
        CustomErrorLine={durationCustomError}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          disable={actionType === "add" ? disable : EditDisabel}
          onClick={handleSaveData}
          buttonStyle={{ backgroundColor: colors.primary }}
        />

        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default BudgetScheduleForm;
