import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
    Link
} from "@react-pdf/renderer";
import { logoRect } from "../../../assets";

const PrsDownload = () => {
    const styles = StyleSheet.create({
        page: {
            flexDirection: "column",
            backgroundColor: "white",
            color: "black",
            width: "100%",
            flex: 1
        },
        viewer: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        image: {
            width: "90px",
            height: "30px",
        },
        section: {
            margin: 10,
            padding: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid black",
            marginRight: "25px",
            marginLeft: "25px"
        },
        footer: {
            // position: "absolute",
            fontSize: 12,
            alignContent: "flex-end",
            marginLeft: "25px",
            marginRight: "25px",
            color: "black",
            display: "flex",
            flexDirection: "row"
        },
        pageNo: {
            // top: "0",
            right: "-370px",
            fontSize: "8px"
        },
    });


    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View fixed>
                        <View style={styles.section} fixed>
                            <Image style={styles.image} src={logoRect} />
                            <Text style={{ fontSize: "12px", display: "flex" }}>
                                Payment Request Summary - 949290852-PRS20170907-1
                            </Text>
                        </View>
                    </View>
                    <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                        <View>
                            <View
                                style={{
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    border: "1px solid black",
                                    marginTop: "10px",
                                    borderRadius: "5px",
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "13px",
                                        borderBottom: "1px solid black",
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "60%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                            backgroundColor: "#f2f2f2"
                                        }}
                                    >
                                        Project Name
                                    </Text>
                                    <Text style={{ width: "40%", fontSize: "11px", padding: "5px" }}>BAY LA SUN - KSA</Text>
                                </View>
                                <View
                                    style={{
                                        borderBottom: "1px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "60%",
                                            fontSize: "10px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                            backgroundColor: "#f2f2f2"
                                        }}
                                    >
                                        Budget Number
                                    </Text>
                                    <Text style={{ width: "40%", fontSize: "10px", padding: "5px" }}>
                                        949290852 - Bay La Sun - OS&E
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "60%",
                                            fontSize: "10px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                            backgroundColor: "#f2f2f2"
                                        }}
                                    >
                                        Issuing Date
                                    </Text>
                                    <Text style={{ width: "40%", fontSize: "10px", padding: "5px" }}>
                                        20 June 22
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View>
                            <View
                                style={{
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    border: "1px solid black",
                                    marginTop: "10px",
                                    borderRadius: "5px",
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "10px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                            backgroundColor: "#f2f2f2"
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", backgroundColor: "#f2f2f2", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}>PO #</Text>
                                    <Text style={{ width: "13%", backgroundColor: "#f2f2f2", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}>PO Value</Text>
                                    <Text style={{ width: "13%", backgroundColor: "#f2f2f2", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}>INV #</Text>
                                    <Text style={{ width: "13%", backgroundColor: "#f2f2f2", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}>INV Value</Text>
                                    <Text style={{ width: "13%", backgroundColor: "#f2f2f2", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}>Currency</Text>
                                    <Text style={{ width: "13%", backgroundColor: "#f2f2f2", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}>Rate</Text>
                                    <Text style={{ width: "9%", fontSize: "10px", backgroundColor: "#f2f2f2", padding: "5px" }}>Value</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                        borderTop: "1px solid black"
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>INV001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>AED</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>1.0</Text>
                                    <Text style={{ width: "9%", fontSize: "11px", padding: "5px" }}>500</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                        borderTop: "1px solid black"
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>INV001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>AED</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>1.0</Text>
                                    <Text style={{ width: "9%", fontSize: "11px", padding: "5px" }}>500</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                        borderTop: "1px solid black"
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>INV001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>AED</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>1.0</Text>
                                    <Text style={{ width: "9%", fontSize: "11px", padding: "5px" }}>500</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                        borderTop: "1px solid black"
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>INV001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>AED</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>1.0</Text>
                                    <Text style={{ width: "9%", fontSize: "11px", padding: "5px" }}>500</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                        borderTop: "1px solid black"
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>INV001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>AED</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>1.0</Text>
                                    <Text style={{ width: "9%", fontSize: "11px", padding: "5px" }}>500</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                        borderTop: "1px solid black"
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>INV001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>AED</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>1.0</Text>
                                    <Text style={{ width: "9%", fontSize: "11px", padding: "5px" }}>500</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "11px",
                                        borderTop: "1px solid black"
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "13%",
                                            fontSize: "11px",
                                            borderRight: "1px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        Vendor One
                                    </Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>INV001</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>500</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>AED</Text>
                                    <Text style={{ width: "13%", fontSize: "11px", padding: "5px", borderRight: "1px solid black" }}>1.0</Text>
                                    <Text style={{ width: "9%", fontSize: "11px", padding: "5px" }}>500</Text>
                                </View>
                            </View>




                            <View>
                                <Text style={{ marginTop: "5px", fontSize: "8px", marginLeft: "25px", marginRight: "25px" }}>Checked By Furnish</Text>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        border: "1px solid black",
                                        marginTop: "10px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "10px",
                                            borderBottom: "1px solid black"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                width: "30%",
                                                fontSize: "10px",
                                                borderRight: "1px solid black",
                                                padding: "5px",
                                                textAlign: "center"
                                            }}
                                        >
                                            Name
                                        </Text>
                                        <Text style={{ width: "40%", fontSize: "10px", padding: "5px", borderRight: "1px solid black", textAlign: "center" }}>Signature</Text>
                                        <Text style={{ width: "30%", fontSize: "10px", padding: "5px", textAlign: "center" }}>Date</Text>
                                    </View>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "10px",
                                            textAlign: "center"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                width: "30%",
                                                height: "50px",
                                                fontSize: "10px",
                                                borderRight: "1px solid black",
                                                padding: "5px",
                                            }}
                                        >

                                        </Text>
                                        <Text style={{ width: "40%", height: "50px", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}></Text>
                                        <Text style={{ width: "30%", height: "50px", fontSize: "10px", padding: "5px" }}></Text>

                                    </View>
                                </View>
                                <Text style={{ marginTop: "5px", fontSize: "8px", marginLeft: "25px", marginRight: "25px" }}>Authorized By Furnish</Text>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        border: "1px solid black",
                                        marginTop: "10px",
                                        borderRadius: "5px",
                                    }}
                                >

                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "10px",
                                            borderBottom: "1px solid black"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                width: "30%",
                                                fontSize: "10px",
                                                borderRight: "1px solid black",
                                                padding: "5px",
                                                textAlign: "center"
                                            }}
                                        >
                                            Name
                                        </Text>
                                        <Text style={{ width: "40%", fontSize: "10px", padding: "5px", borderRight: "1px solid black", textAlign: "center" }}>Signature</Text>
                                        <Text style={{ width: "30%", fontSize: "10px", padding: "5px", textAlign: "center" }}>Date</Text>
                                    </View>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "10px",
                                            textAlign: "center"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                width: "30%",
                                                height: "50px",
                                                fontSize: "10px",
                                                borderRight: "1px solid black",
                                                padding: "5px",
                                            }}
                                        >

                                        </Text>
                                        <Text style={{ width: "40%", height: "50px", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}></Text>
                                        <Text style={{ width: "30%", height: "50px", fontSize: "10px", padding: "5px" }}></Text>

                                    </View>
                                </View>
                                <Text style={{ marginTop: "5px", fontSize: "8px", marginLeft: "25px", marginRight: "25px" }}>Received By Client</Text>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        border: "1px solid black",
                                        marginTop: "10px",
                                        borderRadius: "5px",
                                    }}
                                >

                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "10px",
                                            borderBottom: "1px solid black"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                width: "30%",
                                                fontSize: "10px",
                                                borderRight: "1px solid black",
                                                padding: "5px",
                                                textAlign: "center"
                                            }}
                                        >
                                            Name
                                        </Text>
                                        <Text style={{ width: "40%", fontSize: "10px", padding: "5px", borderRight: "1px solid black", textAlign: "center" }}>Signature</Text>
                                        <Text style={{ width: "30%", fontSize: "10px", padding: "5px", textAlign: "center" }}>Date</Text>
                                    </View>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "10px",
                                            textAlign: "center"
                                        }}
                                    >
                                        <Text
                                            style={{
                                                width: "30%",
                                                height: "50px",
                                                fontSize: "10px",
                                                borderRight: "1px solid black",
                                                padding: "5px",
                                            }}
                                        >

                                        </Text>
                                        <Text style={{ width: "40%", height: "50px", fontSize: "10px", padding: "5px", borderRight: "1px solid black" }}></Text>
                                        <Text style={{ width: "30%", height: "50px", fontSize: "10px", padding: "5px" }}></Text>

                                    </View>
                                </View>
                            </View>





                        </View>
                    </View>

                    <View style={styles.footer} fixed>
                        <View>
                            <Text style={{ fontSize: "8px" }}>
                                PO BOX 282247, Dubai, UAE
                            </Text>
                            <Text style={{ fontSize: "8px" }}>
                                tel +971 4 3307725
                            </Text>
                            <Text style={{ fontSize: "8px" }}>
                                <Link style={{ color: "black" }} src="mailto:invitation@furnish-marketplace.com">
                                    invitation@furnish-marketplace.com
                                </Link>
                            </Text>
                            <Text style={{ fontSize: "8px" }}>
                                <Link style={{ color: "black" }} src="http://furnish-marketplace.com">
                                    furnish-marketplace.com
                                </Link>
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.pageNo} render={({ pageNumber, totalPages }) => (
                                `Page # ${pageNumber} / ${totalPages}`
                            )} fixed />
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer >
    )
}

export default PrsDownload;
