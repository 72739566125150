import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import React, { useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { GetVendorRFQById } from "../../../axios";
import {
  Button,
  DateAndTimePicker,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import "./VendorRequestforInformationForm.css";

export const EditCell = ({ rowData, dataKey, onChange, ...props }) => {
  const editing = rowData.status === "EDIT";
  return (
    <Cell {...props} className={editing ? "table-content-editing" : ""}>
      {editing ? (
        <input
          className="rs-input"
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            onChange &&
              onChange(rowData.budget_item_id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

const VendorRequestforInformationForm = ({ ...props }) => {
  const {
    rfqId,
    setMessage,
    errorMessage,
    handleChangeData = () => { },
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const vendorId = JSON.parse(localStorage.getItem("vendor"));

  const createRFITitle = Strings["RequestforInformation.createRFI"],
    editRFITitle = Strings["RequestforInformation.editRFITitle"],
    RFINameLabel = Strings["RequestforInformation.RFINameLabel"],
    RFINameLabelError = Strings["RequestforInformation.RFINameLabelError"],
    RfiOperatorLabel = Strings["RequestforInformation.RFIOperatorLabel"],
    RFINamePlaceholder = Strings["RequestforInformation.RFINamePlaceholder"],
    RFIDescriptionError = Strings["RequestforInformation.RFIDescriptionError"],
    SaveButton = Strings["RequestforInformation.saveButton"],
    CancelButton = Strings["RequestforInformation.CancelButton"],
    UpdateButton = Strings["RequestforInformation.UpdateButton"],
    EmailErrorLabel = Strings["Vendor.RFI.EmailError"];


  const [loading, setLoading] = React.useState(false);
  const [RFIName, setRFIName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });
  const [designerName, setDesignerName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });
  const [description] = useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });
  const [note, setNote] = useState({
    value: initialValue.note ? initialValue.note : "",
    error: false,
    success: false,
  });

  const [deadline, setDeadline] = React.useState({
    value: initialValue.deadline ? initialValue.deadline : "",
    error: false,
    success: false,
  });

  const [selectedItem, setSelectedItems] = React.useState([]);
  const [emailAddress, setEmailAddress] = React.useState([]);
  const [emailCcAddress, setEmailCcAddress] = React.useState([]);
  const [array] = useState([]);
  const [minDates, setMinDates] = React.useState();

  const [DesignerDropdown] = React.useState([
    { key: 1, value: "Operator", label: "Operator" },
    { key: 2, value: "Designer", label: "Designer" },
    { key: 3, value: "Client", label: "Client" },
  ]);

  const [emailError, setEmailError] = React.useState(false);
  const [ccEmailError, setCCEmailError] = React.useState(false);

  React.useEffect(() => {
    setLoading(true)
    GetVendorRFQById(rfqId)
      .then((res) => {
        const result = res.data.data.budget_item.map((elm) => ({
          budget_id: elm.budgetitem.budget_id,
          budget_item_id: elm.budget_item_id,
          serial_no: elm.budgetitem.control_number,
          itemName: elm.budgetitem.name,
          budgetQuantity: elm.budgetitem.budgetquantitydetails.budget_quantity,
          budgetUOM: elm.budgetitem.budgetquantitydetails.budgetuom.name,
          description: null,
        }))
        setSelectedItems(result)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])

  const minDate = () => {
    var dateToday = new Date();
    setMinDates(moment(dateToday).format("YYYY-MM-DD"));
  };

  React.useEffect(() => {
    minDate();
  }, []);

  const handleRFIName = (value) => {
    setMessage(null);
    setRFIName(value);
  };
  const handleDesignerName = (value) => {
    setMessage(null);
    setDesignerName(value);
  };

  const handlenotes = (value) => {
    setNote(value);
  };
  const handleChangeDeadline = (data) => {
    setDeadline(data);
  };

  let disable =
    RFIName.error ||
    designerName.error ||
    deadline.error ||
    note.error ||
    RFIName.value === "" ||
    designerName.value.length === 0 ||
    deadline.value === "" ||
    note.value === "" ||
    (RFIName.success === false &&
      designerName.success === false &&
      deadline.success === false &&
      note.success === false);

  const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <button
          style={{
            height: "30px",
            backgroundColor: "rgb(71, 71, 161)",
            color: "white",
            padding: "5px 20px",
            borderRadius: "5px",
          }}
          appearance="link"
          onClick={() => {
            onClick && onClick(rowData.budget_item_id);
          }}
        >
          {rowData.status === "EDIT" ? "Save" : "Edit"}
        </button>
      </Cell>
    );
  };

  const handleChange = (budget_item_id, key, value) => {
    const nextData = Object.assign([], selectedItem);
    nextData.find((item) => item.budget_item_id === budget_item_id)[key] =
      value;
    setSelectedItems(nextData);
  };
  const handleEditState = (budget_item_id) => {
    const nextData = Object.assign([], selectedItem);
    const activeItem = nextData.find(
      (item) => item.budget_item_id === budget_item_id
    );
    activeItem.status = activeItem.status ? null : "EDIT";
    setSelectedItems(nextData);
  };

  const handleSaveData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const vendor_email = user.email;
    let newFileObj = Array.from(new Set(array)).slice(1);
    let path = [];
    newFileObj.map((files) => {
      path.push(files.name);
    });
    const resultArr = [];
    designerName.value.map((elem) => {
      resultArr.push(elem.value);
    });
    const budget_item_list = selectedItem.map(({ budget_item_id, description }) => ({
      budget_item_id,
      description,
    }));

    const formData = {
      name: RFIName.value,
      description: description.value,
      notes: note.value,
      rfi_send_to: resultArr,
      deadline: deadline.value,
      email: emailAddress,
      cc_email: emailCcAddress,
      budget_id: "",
      budget_item_id: budget_item_list,
      filepath: [],
      rfq_id: rfqId.toString(),
      vendor_id: vendorId.toString(),
      from_email: vendor_email
    };
    handleChangeData(formData);
  };

  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>{actionType === "add" ? createRFITitle : editRFITitle}</h2>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={RFINameLabel}
            placeholder={RFINamePlaceholder}
            Value={RFIName.value}
            onChangeText={handleRFIName}
            Validate={true}
            CustomErrorLine={RFINameLabelError}
            editable={true}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <p
            style={{
              marginTop: "20px",
              marginBottom: "-33px",
            }}
          >
            RFI Send To <span style={{ color: "red" }}>*</span>
          </p>
          <FormDropDown
            isMulti={true}
            defaultValue={designerName.value}
            label={RfiOperatorLabel}
            Options={DesignerDropdown}
            onSelect={handleDesignerName}
            CustomErrorLine={"Select At Least One RFI Operator"}
            starProps={true}
            isValidate={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3 pb-3">
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "10px",
              marginTop: "10px"
            }}
          >
            Email Id
            <Tooltip
              title="You Can Enter Multiple Email id"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailAddress}
              onChange={(emails) => {
                setEmailAddress(emails);
              }}
              validateEmail={(email) => {
                if (isEmail(email) == true) {
                  setEmailError(false)
                  return isEmail(email)
                } else if (isEmail(email) == false) {
                  setEmailError(true)
                }
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => {
                      setEmailError(false)
                      removeEmail(index)
                    }}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
          {emailError && <div className="clearFix">
            <div
              className="float-right"
              style={{
                color: colors.danger
              }}
            >
              {EmailErrorLabel}
            </div>
          </div>}
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "10px",
              marginTop: "10px"
            }}
          >
            CC To
            <Tooltip
              title="You Can Enter Multiple CC"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailCcAddress}
              onChange={(emails) => {
                setEmailCcAddress(emails);
              }}
              validateEmail={(email) => {
                if (isEmail(email) == true) {
                  setCCEmailError(false)
                  return isEmail(email)
                } else if (isEmail(email) == false) {
                  setCCEmailError(true)
                }
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => {
                      setCCEmailError(false)
                      removeEmail(index)
                    }}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
          {ccEmailError && <div className="clearFix">
            <div
              className="float-right"
              style={{
                color: colors.danger
              }}
            >
              {EmailErrorLabel}
            </div>
          </div>}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <DateAndTimePicker
            dateAndTimeLabel={{
              marginBottom: "10px",
            }}
            label={"Deadline To Answer"}
            customErrorLine={"Select Deadline To Answer"}
            minDate={minDates}
            hadleSetDate={handleChangeDeadline}
            date={deadline.value}
            disable={false}
            starProps={true}
          />
        </div>
        <div
          className="col-12 col-md-6 pe-0 pe-md-3"
          id="select-style-date"
          style={{
            marginTop: "-25px",
          }}
        >
          <FormTextInput
            type="textarea"
            label={"Note"}
            placeholder={"Enter Note"}
            Value={note.value}
            onChangeText={handlenotes}
            Validate={true}
            CustomErrorLine={RFIDescriptionError}
            editable={true}
            multiline={true}
            starProps={true}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <Button
            buttonName={actionType === "add" ? SaveButton : UpdateButton}
            buttonStyle={{ backgroundColor: colors.primary }}
            disable={disable}
            onClick={handleSaveData}
          />
          <Button
            buttonName={CancelButton}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
          />
        </div>
      </div>
      <h2 className="my-3">Budget Items</h2>
      <div style={{ padding: "20px" }}>
        <Table height={200} data={selectedItem}>
          <Column width={125} height={200}>
            <HeaderCell>Control No</HeaderCell>
            <Cell dataKey="serial_no" />
          </Column>
          <Column width={125} height={200}>
            <HeaderCell>Item Name</HeaderCell>
            <Cell dataKey="itemName" />
          </Column>
          <Column width={125} height={200}>
            <HeaderCell>Qty</HeaderCell>
            <Cell dataKey="budgetQuantity" />
          </Column>
          <Column width={125} height={200}>
            <HeaderCell>UOM</HeaderCell>
            <Cell dataKey="budgetUOM" />
          </Column>
          <Column width={125} height={200}>
            <HeaderCell>Query</HeaderCell>
            <EditCell dataKey="description" onChange={handleChange} />
          </Column>
          <Column width={125} height={125}>
            <HeaderCell>Action</HeaderCell>
            <ActionCell dataKey="budget_item_id" onClick={handleEditState} />
          </Column>
        </Table>
      </div>
    </div>
  );
};

export default VendorRequestforInformationForm;
