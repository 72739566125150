/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const RoomTypeForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    handleChangeFormDatas = () => {},
  } = props;

  const createRoomTypeTitle = Strings["RoomType.CreateRoomForm"],
    editRoomTypeTitle = Strings["RoomType.EditRoomForm"],
    roomTypeLabel = Strings["RoomType.RoomTypeLabel"],
    roomTypePlaceholder = Strings["RoomType.RoomTypePlaceHolder"],
    roomTypeCustomError = Strings["RoomType.RoomTypeError"],
    roomTypeNoteLabel = Strings["RoomType.RoomTypeNoteLabel"],
    roomTypeNotePlaceholder = Strings["RoomType.RoomTypeNotePlaceHolder"],
    roomTypeNoteCustomError = Strings["RoomType.RoomTypeNoteError"],
    roomSaveBtn = Strings["RoomType.SaveBtn"],
    roomUpdateBtn = Strings["RoomType.UpdateBtn"],
    roomCancelBtn = Strings["RoomType.CancelBtn"];

  const [roomType, setRoomType] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [typeNote, setTypeNote] = React.useState({
    value: initialValue.notes ? initialValue.notes : "",
    error: false,
    success: false,
  });

  const handleChangeRoomType = (value) => {
    setMessage(null);
    setRoomType(value);
  };

  const handleChangeTypeNote = (value) => {
    setTypeNote(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: roomType.value,
      notes: typeNote.value,
    };
    handleChangeFormData(formData);
    handleChangeFormDatas(formData);
  };

  let isValueNOTChanged =
    roomType.value === initialValue.name &&
    typeNote.value === initialValue.notes;

  let disable =
    roomType.error ||
    // typeNote.error ||
    roomType.value === "" ||
    // typeNote.value === "" ||
    roomType.success === false ||
    isValueNOTChanged;

  let isValueNOTChangededit =
    roomType.value === initialValue.name &&
    typeNote.value === initialValue.notes;

  let Editdisable =
    roomType.error ||
    // typeNote.error ||
    roomType.value === "" ||
    // typeNote.value === "" ||
    roomType.success === false ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createRoomTypeTitle : editRoomTypeTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        label={roomTypeLabel}
        placeholder={roomTypePlaceholder}
        Value={roomType.value}
        onChangeText={handleChangeRoomType}
        Validate={true}
        CustomErrorLine={roomTypeCustomError}
        starProps={true}
        editable
      />

      <FormTextInput
        type="alpha"
        label={roomTypeNoteLabel}
        placeholder={roomTypeNotePlaceholder}
        Value={typeNote.value}
        onChangeText={handleChangeTypeNote}
        Validate={true}
        CustomErrorLine={roomTypeNoteCustomError}
        starProps={false}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? roomSaveBtn : roomUpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={roomCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default RoomTypeForm;
