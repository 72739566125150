/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  GetAllActiveCountry,
  GetAllActiveIncoterm,
  GetAllActiveTransportation,
  GetAllActiveVendor,
} from "../../../axios";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import {
  Button,
  DateAndTimePicker,
  FormDropDown,
  FormTextInput,
  RequestForInformationTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import "./RFIForm.css";

const RfqForm = (props) => {
  const {
    starProps,
    budget_id,
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    selectedItem,
    handleModalClose,
  } = props;

  const [loader, setLoader] = React.useState(false);
  const [rfqName, setRfqName] = React.useState({
    value: initialValue.rfqName ? initialValue.rfqName : "",
    error: false,
    success: false,
  });

  const [incoterm, setIncoterm] = React.useState({
    value: initialValue.incoterm ? initialValue.incoterm : "",
    error: false,
    success: false,
  });

  const [modeOfTransportation, setModeOfTransportation] = React.useState({
    value: initialValue.mode_of_transportation
      ? initialValue.mode_of_transportation.id
      : "",
    error: false,
    success: false,
  });

  const [deadline, setDeadline] = React.useState({
    value: initialValue.deadline ? initialValue.deadline : "",
    error: false,
    success: false,
  });

  const [importantNotes, setImportantNotes] = React.useState({
    value: initialValue.importantNotes ? initialValue.importantNotes : "",
    error: false,
    success: false,
  });

  const [tendorNotes, setTendorNotes] = React.useState({
    value: initialValue.tendorNotes ? initialValue.tendorNotes : "",
    error: false,
    success: false,
  });

  const [emailAddress, setEmailAddress] = React.useState([]);
  const [emailCcAddress, setEmailCcAddress] = React.useState([]);

  let vendorList = [];
  if (initialValue.vendors) {
    vendorList = initialValue.vendors.map((ele) => {
      return {
        label: ele.vendor.name,
        value: ele.vendor.id,
        key: ele.vendor.id,
      };
    });
  }

  // if (initialValue.vendorId) {
  //   vendorList.push({
  //     key: initialValue.vendorId,
  //     value: initialValue.vendorId,
  //     label: initialValue.vendor,
  //   });
  // }
  const [vendor, setVendor] = React.useState({
    value: vendorList,
    error: false,
    success: false,
  });
  const [optionsCountry, setOptionsCountry] = React.useState([]);
  const [optionsIncoterm, setOptionsIncoterm] = React.useState([]);
  const [optionsModeOfTransportation, setOptionsModeOfTransportation] =
    React.useState([]);

  const [optionsVendor, setOptionsVendor] = React.useState([]);

  let budget_id_arr = [];
  const result = selectedItem.map((ele) => {
    budget_id_arr.push(ele.id);
  });

  const handleChangeRfqName = (data) => {
    setMessage(null);
    setRfqName(data);
  };

  // const handleChangeCountry = (data) => {
  //   setCountry(data);
  // };

  const handleChangeIncoterm = (data) => {
    setIncoterm(data);
  };

  const handleChangeModeOfTransportation = (data) => {
    setModeOfTransportation(data);
  };

  const handleChangeDeadline = (data) => {
    setDeadline(data);
  };

  const handleChangeImportantNotes = (data) => {
    setImportantNotes(data);
  };

  const handleChangeTendorNotes = (data) => {
    setTendorNotes(data);
  };

  // const hadleChangeVendor = (data) => {
  //   setVendor(data);
  // };
  // const handleChangeEmailAddress = (value) => {
  //   setEmailAddress(value);
  // };

  const getCountry = () => {
    setLoader(true);
    GetAllActiveCountry()
      .then((res) => {
        let data = res.data.data;
        setLoader(false);
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptionsCountry(result);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const getIncoterm = () => {
    setLoader(true);
    GetAllActiveIncoterm()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptionsIncoterm(result);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getTransport = () => {
    setLoader(true);
    GetAllActiveTransportation()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptionsModeOfTransportation(result);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getvendor = () => {
    setLoader(true);
    GetAllActiveVendor()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptionsVendor(result);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getCountry();
    getIncoterm();
    getTransport();
    getvendor();
  }, []);

  const createRequestForQuotationTitle =
      Strings["RequestForQuotation.createRequestForQuotationTitle"],
    editRequestForQuotationTitle =
      Strings["RequestForQuotation.editRequestForQuotationTitle"],
    rfqNameLabel = Strings["RequestForQuotation.rfqNameLabel"],
    rfqNamePlaceholder = Strings["RequestForQuotation.rfqNamePlaceholder"],
    rfqNameCustomError = Strings["RequestForQuotation.rfqNameCustomError"],
    // CountryLabel = Strings["RequestForQuotation.CountryLabel"],
    // CountryCustomError = Strings["RequestForQuotation.CountryCustomError"],
    IncotermLabel = Strings["RequestForQuotation.IncotermLabel"],
    IncotermCustomError = Strings["RequestForQuotation.IncotermCustomError"],
    modeOfTransportationLabel =
      Strings["RequestForQuotation.modeOfTransportationLabel"],
    modeOfTransportationCustomError =
      Strings["RequestForQuotation.modeOfTransportationCustomError"],
    deadlineLabel = Strings["RequestForQuotation.deadlineLabel"],
    deadlineCustomErrorLine =
      Strings["RequestForQuotation.deadlineCustomErrorLine"],
    importantNotesLabel = Strings["RequestForQuotation.importantNotesLabel"],
    importantNotesPlaceholder =
      Strings["RequestForQuotation.importantNotesPlaceholder"],
    importantNotesCustomError =
      Strings["RequestForQuotation.importantNotesCustomError"],
    tenderNotesLabel = Strings["RequestForQuotation.tenderNotesLabel"],
    tenderNotesPlaceholder =
      Strings["RequestForQuotation.tenderNotesPlaceholder"],
    tenderNotesCustomError =
      Strings["RequestForQuotation.tenderNotesCustomError"],
    vendorLabel = Strings["RequestForQuotation.vendorLabel"],
    vendorCustomError = Strings["RequestForQuotation.vendorCustomError"],
    saveBtn = Strings["RequestForQuotation.saveBtn"],
    updateBtn = Strings["RequestForQuotation.updateBtn"],
    cancelBtn = Strings["RequestForQuotation.cancelBtn"],
    userId = Strings["RequestForQuotation.TableHead.userId"],
    itemName = Strings["RequestForQuotation.TableHead.itemName"],
    vendorHead = Strings["RequestForQuotation.TableHead.vendor"],
    quantity = Strings["RequestForQuotation.TableHead.quantity"],
    uom = Strings["RequestForQuotation.TableHead.uom"];

  const isValueChanged =
    rfqName.value === initialValue.rfqName &&
    // country.value === initialValue.country &&
    incoterm.value === initialValue.incoterm &&
    modeOfTransportation.value === initialValue.modeOfTransportation &&
    deadline.value === initialValue.deadline &&
    importantNotes.value === initialValue.importantNotes &&
    tendorNotes.value === initialValue.tendorNotes &&
    vendor.value === initialValue.vendor;

  const disable =
    rfqName.error ||
    // country.error ||
    incoterm.error ||
    modeOfTransportation.error ||
    deadline.error ||
    vendor.error ||
    rfqName.value === "" ||
    // country.value === "" ||
    incoterm.value === "" ||
    modeOfTransportation.value === "" ||
    deadline.value === "" ||
    vendor.value.length === 0 ||
    (rfqName.success === false &&
      // country.success === false &&
      incoterm.success === false &&
      modeOfTransportation.success === false &&
      deadline.success === false &&
      vendor.success === false) ||
    isValueChanged;

  const handleSubmit = () => {
    let vendorId = [];
    vendor.value.map((ele) => {
      vendorId.push(ele.key);
    });

    // let budget_Ids;
    // if (actionType === "add") {
    //   return budget_id;
    // } else {
    //   return budget_id.budgetID;
    // }

    const formData = {
      name: rfqName.value,
      valid_till: deadline.value,
      mode_of_transportation_id: modeOfTransportation.value,
      incoterm_id: incoterm.value,
      important_notes: importantNotes.value,
      tendor_notes: tendorNotes.value,
      email: emailAddress,
      cc_email: emailCcAddress,
      // budget_id: actionType === "add" ? budget_id : budget_id.budgetID,
      budget_id: budget_id,
      budget_item_id: budget_id_arr,
    };
    handleChangeData(formData);
  };

  const mapableData = [
    {
      dataKey: "serialNo",
      title: "Control Number",
    },
    {
      dataKey: "itemName",
      title: itemName,
    },
    // {
    //   dataKey: "vendor",
    //   title: vendorHead,
    // },
    {
      dataKey: "budgetQuantity",
      title: quantity,
    },
    {
      dataKey: "budgetUOM",
      title: uom,
    },
  ];
  // const filterData = selectedItem.map((elm) => ({
  //   id: elm.id || elm.budgetitem.id,
  //   serialNo: elm.serial_number || elm.budgetitem.serial_number,
  //   itemName: elm.name || elm.budgetitem.name,
  //   vendor:
  //     elm.budgetitem.budget_vendordetails.vendor.name ||
  //     elm.budget_vendordetails.vendor,
  //   budgetQuantity:
  //     elm.budgetitem.budgetquantitydetails.budget_quantity ||
  //     elm.budgetquantitydetails.budget_quantity,
  //   budgetUOM:
  //     elm.budgetitem.budgetquantitydetails.budgetuom.name ||
  //     elm.budgetquantitydetails.budgetuom.name,
  // }));
  let filterData;
  if (actionType === "add") {
    filterData = selectedItem.map((elm) => ({
      id: elm.id,
      serialNo: elm.serial_number,
      itemName: elm.name,
      vendor: elm.budget_vendordetails.vendor.name,
      budgetQuantity: elm.budgetquantitydetails.budget_quantity,
      budgetUOM: elm.budgetquantitydetails.budgetuom.name,
    }));
  } else {
    // filterData = selectedItem.map((elm) => ({
    //   id: elm.budgetitem.id,
    //   serialNo: elm.budgetitem.serial_number,
    //   itemName: elm.budgetitem.name,
    //   vendor: elm.budgetitem.budget_vendordetails.vendor.name,
    //   budgetQuantity: elm.budgetitem.budgetquantitydetails.budget_quantity,
    //   budgetUOM: elm.budgetitem.budgetquantitydetails.budgetuom.name,
    // }));
  }

  return (
    <div className="modalFormContainer">
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {loader ? <LoaderComponent /> : null}
      <h2>
        {actionType === "add"
          ? createRequestForQuotationTitle
          : editRequestForQuotationTitle}
      </h2>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={rfqNameLabel}
            placeholder={rfqNamePlaceholder}
            Value={rfqName.value}
            onChangeText={handleChangeRfqName}
            Validate={true}
            CustomErrorLine={rfqNameCustomError}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <DateAndTimePicker
            label={deadlineLabel}
            customErrorLine={deadlineCustomErrorLine}
            hadleSetDate={handleChangeDeadline}
            date={deadline.value}
            disable={false}
            starProps={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormDropDown
            isMulti={false}
            value={modeOfTransportation.value}
            Label={modeOfTransportationLabel}
            Options={optionsModeOfTransportation}
            onSelect={handleChangeModeOfTransportation}
            CustomErrorLine={modeOfTransportationCustomError}
            searchable={false}
            disable={false}
            starProps={false}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormDropDown
            isMulti={false}
            value={incoterm.value}
            Label={IncotermLabel}
            Options={optionsIncoterm}
            onSelect={handleChangeIncoterm}
            CustomErrorLine={IncotermCustomError}
            searchable={false}
            disable={false}
            starProps={true}
            isValidate={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormTextInput
            type="textarea"
            label={importantNotesLabel}
            placeholder={importantNotesPlaceholder}
            Value={importantNotes.value}
            onChangeText={handleChangeImportantNotes}
            Validate={true}
            CustomErrorLine={importantNotesCustomError}
            multiline={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormTextInput
            type="textarea"
            label={tenderNotesLabel}
            placeholder={tenderNotesPlaceholder}
            Value={tendorNotes.value}
            onChangeText={handleChangeTendorNotes}
            Validate={true}
            CustomErrorLine={tenderNotesCustomError}
            multiline={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          {/* multi level email start */}
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "1px",
              marginTop: 15,
            }}
          >
            Email Id<span style={{ color: "red", paddingLeft: "4px" }}>*</span>
            <Tooltip
              title="Multiple Email Can be Added"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailAddress}
              onChange={(emails) => {
                setEmailAddress(emails);
              }}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
          {/* multi level email end */}
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          {/* multi level email start */}
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "1px",
              marginTop: 15,
            }}
          >
            CC To
            <Tooltip
              title="Multiple CC Can be Added"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailCcAddress}
              onChange={(emails) => {
                setEmailCcAddress(emails);
              }}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
          {/* multi level email end */}
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          // disable={disable}
          onClick={handleSubmit}
          buttonStyle={{ backgroundColor: colors.primary }}
        />
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
      {filterData && (
        <RequestForInformationTable
          data={filterData}
          mapableData={mapableData}
        />
      )}
    </div>
  );
};

export default RfqForm;
