/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const AccessAndConsiderationForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["AcessAndConsideration.CreateForm"],
    editTitle = Strings["AcessAndConsideration.EditForm"],
    AcessAndConsiderationLabel =
      Strings["AcessAndConsideration.AccessConsiderationModeLabel"],
    AcessAndConsiderationPlaceholder =
      Strings["AcessAndConsideration.AccessConsiderationModePlaceHolder"],
    AcessAndConsiderationCustomError =
      Strings["AcessAndConsideration.AccessConsiderationModeError"],
    AcessAndConsiderationDescriptionLabel =
      Strings["AcessAndConsideration.AccessConsiderationModeDescriptionLabel"],
    AcessAndConsiderationDescriptionPlaceholder =
      Strings[
        "AcessAndConsideration.AccessConsiderationModeDescriptionPlaceHolder"
      ],
    AcessAndConsiderationDescriptionCustomError =
      Strings["AcessAndConsideration.AccessConsiderationModeDescriptionError"],
    AcessAndConsiderationSaveBtn = Strings["AcessAndConsideration.SaveBtn"],
    AcessAndConsiderationUpdateBtn = Strings["AcessAndConsideration.UpdateBtn"],
    AcessAndConsiderationCancelBtn = Strings["AcessAndConsideration.CancelBtn"];

  const [accessName, setAcessName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [acessDescripotion, setAcessDescripton] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handlenAcessName = (value) => {
    setMessage(null);
    setAcessName(value);
  };

  const handleChangeAcessDescription = (value) => {
    setAcessDescripton(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: accessName.value,
      description: acessDescripotion.value,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged = accessName.value === initialValue.name;
  // acessDescripotion.value === initialValue.description;
  let disable =
    accessName.error ||
    // acessDescripotion.error ||
    accessName.value === "" ||
    // acessDescripotion.value === "" ||
    accessName.success === false ||
    isValueChanged;

  //edit
  let isValueChangededit =
    accessName.value === initialValue.name &&
    acessDescripotion.value === initialValue.description;
  let Editdisable =
    accessName.error ||
    acessDescripotion.error ||
    accessName.value === "" ||
    acessDescripotion.value === "" ||
    (accessName.success === false && acessDescripotion.success === false) ||
    isValueChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        label={AcessAndConsiderationLabel}
        placeholder={AcessAndConsiderationPlaceholder}
        Value={accessName.value}
        onChangeText={handlenAcessName}
        Validate={true}
        CustomErrorLine={AcessAndConsiderationCustomError}
        starProps={true}
        maxLength={100}
        editable
      />
      <FormTextInput
        type="textarea"
        label={AcessAndConsiderationDescriptionLabel}
        placeholder={AcessAndConsiderationDescriptionPlaceholder}
        Value={acessDescripotion.value}
        onChangeText={handleChangeAcessDescription}
        Validate={false}
        CustomErrorLine={AcessAndConsiderationDescriptionCustomError}
        multiline={true}
        starProps={false}
        maxLength={100}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add"
              ? AcessAndConsiderationSaveBtn
              : AcessAndConsiderationUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={AcessAndConsiderationCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default AccessAndConsiderationForm;
