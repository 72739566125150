import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import React from "react";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { FormTextInput, IconButton } from "../../../../components";
import { colors } from "../../../../configs";
import LoaderComponent from "../../../common/Loader";
import NodataFound from "../../NoDataFound/NodataFound";
import strings from "../../../../translations";


const DownloadLabel = strings["Tooltip.downloadTooltip"];

const ActionCell = ({
    rowData,
    dataKey,
    handleDownload,
    ...props
}) => {
    const handleViewDownloaddata = () => {
        handleDownload(rowData);
    };

    return (
        <Cell {...props} className="link-group">
            <div style={{ paddingLeft: "2px", marginTop: "-10px" }}>
                <IconButton onClick={handleViewDownloaddata}>
                    <Tooltip title={DownloadLabel}>
                        <GetAppIcon sx={{ color: colors.success }} />
                    </Tooltip>
                </IconButton>
            </div>
        </Cell>
    );
};

const PurchaseOrderTable = (props) => {
    const {
        data,
        mapableData,
        searchProp,
        handleDownload
    } = props;

    const
        searchPlaceholder = strings["Document.PO.placeholder"];

    const [allData, setAllData] = React.useState(data);
    const [searchedData, setSearchedData] = React.useState(data);
    const [limit] = React.useState(5);
    const [page] = React.useState(1);
    const [loading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState({
        value: "",
        error: false,
        success: false,
    });
    const [tempData] = React.useState(data);
    

    React.useEffect(() => {
        setAllData(data);
    }, [data]);

    const buildFilter = (filter) => {
        let query = {};
        for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
                query[keys] = filter[keys];
            }
        }
        return query;
    };

    const filterBuilderData = (data, query) => {
        let searchedData = [];
        data.forEach((item) => {
            for (let key in query) {
                if (
                    !_.includes(searchedData, item) &&
                    item[key].toLowerCase().includes(query[key][0].toLowerCase())
                ) {
                    searchedData.push(item);
                }
            }
        });
        return searchedData;
    };

    let filter = {};
    React.useEffect(() => {
        searchProp.forEach((e) => (filter[e] = [searchValue.value]));
        setSearchedData(filterBuilderData(data, buildFilter(filter)));
    }, [searchProp, searchValue]);

    const query = buildFilter(filter);
    const handleSearch = (searchData) => {
        const data = searchData.value;
        setSearchValue(searchData);
        if (data === "") {
            setAllData(tempData);
        } else {
            setAllData(filterBuilderData(allData, query));
        }
    };

    const filterData = (data) => {
        const result = data.filter((v, i) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return i >= start && i < end;
        });
        return result;
    };


    return (
        <div>
            {loading ? <LoaderComponent /> : null}
            <>
                {filterData(searchedData).length > 0 ? (
                    <>
                        <FormTextInput
                            type="text"
                            className="searchBarRfq mb-3"
                            placeholder={searchPlaceholder}
                            Value={searchValue.value}
                            onChangeText={handleSearch}
                            inputStyle={{ border: "none" }}
                        />
                        <Table
                            style={{ borderRadius: 10 }}
                            height={320}
                            id="table"
                            width={"100%"}
                            data={filterData(searchedData)}
                            rowHeight={50}
                            headerHeight={50}
                        >
                            {mapableData.map((item, index) => {
                                return (
                                    <Column
                                        width={200}
                                        flexGrow={1}
                                        key={index}
                                    >
                                        <HeaderCell
                                            style={{
                                                color: colors.primary,
                                                fontWeight: 900,
                                                fontSize: "larger",
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.title}
                                        </HeaderCell>

                                        {item.hasOwnProperty("isAction") ? (
                                            <ActionCell
                                                isdownload={item.isdownload}
                                                dataKey="id"
                                                handleDownload={handleDownload}
                                            />
                                        ) : (
                                            <Cell dataKey={item.dataKey} />
                                        )}
                                    </Column>
                                );
                            })}
                        </Table>
                    </>
                ) : (
                    <>
                        {loading ? <LoaderComponent /> : <NodataFound norecord={false} />}
                    </>
                )}
            </>
        </div>
    );
};

export default PurchaseOrderTable;
