/* eslint-disable no-unused-vars */
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockResetIcon from "@mui/icons-material/LockReset";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
// import { AiOutlineAlignLeft } from "react-icons/ai";
import { Tooltip } from "@mui/material";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { useHistory, useLocation } from "react-router";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { logoRect, logosm } from "../../../assets";
import {
  ChangePassword,
  GetUserNameByAuthId,
  GetVendorById,
  Logout,
  UpdateVendorManagement,
} from "../../../axios";
import { FormDropDown, Modal } from "../../../components";
import { EditProfile, EditProfileForm, VendorForm } from "../../../forms";
import "../../../styles/searchbar.css";
import Strings from "../../../translations";
import LoaderComponent from "../../common/Loader";

export default function Navbar({ handleToggleSidebar, collapsed }) {
  const userRole = Strings["Navbar.userRole"],
    functions = Strings["Navbar.functions"],
    roleFunctionMapping = Strings["Navbar.roleFunctionMapping"],
    userType = Strings["Navbar.userType"],
    userRoleMapping = Strings["Navbar.userRoleMapping"],
    clientType = Strings["Navbar.clientType"],
    country = Strings["Navbar.country"],
    establishment = Strings["Navbar.establishment"],
    itemCategory = Strings["Navbar.itemCategory"],
    budgetScope = Strings["Navbar.budgetScope"],
    scheduleSteps = Strings["Navbar.scheduleSteps"],
    requiredDocument = Strings["Navbar.requiredDocument"],
    modeofPayment = Strings["Navbar.modeofPayment"],
    stage = Strings["Navbar.stage"],
    vendor = Strings["Navbar.vendor"],
    user = Strings["Navbar.user"],
    projectScope = Strings["Navbar.projectScope"],
    client = Strings["Navbar.client"],
    vendorCategory = Strings["Navbar.vendorCategory"],
    vendorCompanyType = Strings["Navbar.vendorCompanyType"],
    productAndServices = Strings["Navbar.productAndServices"],
    commodityList = Strings["Navbar.commodityList"],
    vendorSubCategory = Strings["Navbar.vendorSubCategory"],
    vendorCategorytoSubCategoryMapping =
      Strings["Navbar.vendorCategorytoSubCategoryMapping"],
    vendorCategorytoProductMapping =
      Strings["Navbar.vendorCategorytoProductMapping"],
    roomTypes = Strings["Navbar.roomTypes"],
    publicArea = Strings["Navbar.publicArea"],
    currency = Strings["Navbar.currency"],
    unitofMeasurement = Strings["Navbar.unitofMeasurement"],
    requiredSubmittals = Strings["Navbar.requiredSubmittals"],
    modeofShipment = Strings["Navbar.modeofShipment"],
    locations = Strings["Navbar.locations"],
    incoterm = Strings["Navbar.incoterm"],
    accessedandConsideration = Strings["Navbar.accessedandConsideration"],
    modeOfTransportation = Strings["Navbar.modeOfTransportation"],
    budgetScopetoItemCategoryMapping =
      Strings["Navbar.budgetScopetoItemCategoryMapping"],
    paymentTerms = Strings["Navbar.paymentTerms"],
    finalPaymentAgainst = Strings["Navbar.finalPaymentAgainst"],
    paymentProgress = Strings["Navbar.paymentProgress"],
    itemGroup = Strings["Navbar.itemGroup"],
    vendorCompanytoCategoryMapping =
      Strings["Navbar.vendorCompanytoCategoryMapping"],
    itemsubcategorylabel = Strings["Navbar.itemsubcategorylabel"],
    establishmentItemCategoryMapping =
      Strings["Navbar.establishmentItemCategoryMapping"],
    logoutTitle = Strings["Navbar.logoutTitle"],
    logoutText = Strings["Navbar.logoutText"],
    vendorLoginAcess = Strings["MasterConfiguration.vendorAccess"];

  const [loader, setLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [masterData, setMasterData] = React.useState("");
  const [data, setData] = React.useState("");
  const history = useHistory();
  const location = useLocation();
  const [userName, setUserName] = React.useState("Furnish");

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user != null || user != undefined){
      if (user.loginType == "vendor") {
        const vendor_Id = JSON.parse(localStorage.getItem("vendor"));
        GetVendorById(vendor_Id)
          .then((res) => {
            const resultData = {
              id: res.data.data.id,
              name: res.data.data.name,
              email: user.email,
              website: res.data.data.website,
              company_type: {
                code: res.data.data.companyType.code,
                company_type: res.data.data.companyType.company_type,
                id: res.data.data.companyType.id,
              },
              vendor_category: res.data.data.vendorCategory.map((ele) => {
                return {
                  id: ele.id,
                  category: ele.category,
                };
              }),
              vendor_sub_category: res.data.data.vendorSubCategory.map((ele) => {
                return {
                  id: ele.id,
                  sub_category: ele.sub_category,
                };
              }),
              product_services: res.data.data.productServices.map((ele) => {
                return {
                  id: ele.id,
                  name: ele.name,
                };
              }),
              countries: res.data.data.countries,
              state: res.data.data.state,
              city: res.data.data.city,
              phone: res.data.data.phone,
              fax: res.data.data.fax,
              postcode: res.data.data.postcode,
              address: res.data.data.address,
              company_type_id: res.data.data.company_type_id,
            };
            setUserName(resultData.name);
            setData(resultData);
          })
          .catch(() => {});
      }
    }

    if (user != null) {
      const id = user.identity_id;
      GetUserNameByAuthId(id)
        .then((res) => {
          setUserName(res.data.data.name);
        })
        .catch((e) => {
          console.log("Error",e)
        });
    }
  }, []);

  // React.useEffect(() => {
  //   if (user.loginType == "user") {
  //     const id = user.identity_id;
  //     console.log("User Id", user)
  //     GetUserNameByAuthId(id)
  //       .then((res) => {
  //         setUserName(res.data.data.name);
  //         console.log("res",res)
  //       })
  //       .catch((e) => {
  //         console.log("Error",e)
  //       });
  //   }
  // }, []);

  const handleChangeMasterData = (path) => {
    if (path.value !== "") {
      setMasterData(path.value);
      history.push(`${path.value}`);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpens, setIsOpens] = React.useState(false);
  const [initialValue] = React.useState({
    email: "",
    password: "",
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const formDropDownData = [
    {
      key: 1,
      label: userRole,
      value: "/master-configuration/searchrole",
    },
    {
      key: 2,
      label: functions,
      value: "/master-configuration/searchfunction",
    },
    {
      key: 3,
      label: roleFunctionMapping,
      value: "/master-configuration/searchmapingrolefunction",
    },
    {
      key: 4,
      label: userType,
      value: "/master-configuration/searchusertype",
    },
    {
      key: 5,
      label: userRoleMapping,
      value: "/master-configuration/searchuserrolemapping",
    },
    {
      key: 6,
      label: clientType,
      value: "/master-configuration/searchclienttype",
    },
    {
      key: 7,
      label: country,
      value: "/master-configuration/searchcountry",
    },
    {
      key: 8,
      label: establishment,
      value: "/master-configuration/searchestablishment",
    },
    {
      key: 9,
      label: itemCategory,
      value: "/master-configuration/itemcategory",
    },
    {
      key: 10,
      label: budgetScope,
      value: "/master-configuration/budgetscope",
    },
    {
      key: 11,
      label: scheduleSteps,
      value: "/master-configuration/schedulestep",
    },
    {
      key: 12,
      label: requiredDocument,
      value: "/master-configuration/requireddocument",
    },
    {
      key: 13,
      label: modeofPayment,
      value: "/master-configuration/modeofpayment",
    },
    {
      key: 14,
      label: stage,
      value: "/master-configuration/stage",
    },
    {
      key: 15,
      label: vendor,
      value: "/master-configuration/vendor",
    },
    {
      key: 16,
      label: user,
      value: "/master-configuration/searchuser",
    },
    {
      key: 17,
      label: projectScope,
      value: "/master-configuration/searchprojectscope",
    },
    {
      key: 18,
      label: client,
      value: "/master-configuration/searchclient",
    },
    {
      key: 19,
      label: vendorCategory,
      value: "/master-configuration/searchvendorcategory",
    },
    {
      key: 20,
      label: vendorCompanyType,
      value: "/master-configuration/vendorcompanytype",
    },
    {
      key: 21,
      label: productAndServices,
      value: "/master-configuration/productandservice",
    },
    {
      key: 22,
      label: commodityList,
      value: "/master-configuration/commodities",
    },
    {
      key: 23,
      label: vendorSubCategory,
      value: "/master-configuration/searchvendordubcategory",
    },
    {
      key: 24,
      label: vendorCategorytoSubCategoryMapping,
      value: "/master-configuration/searchvendorcategorytosubcategorymapping",
    },
    {
      key: 25,
      label: vendorCategorytoProductMapping,
      value: "/master-configuration/searchvendorcategorytoproductmapping",
    },
    {
      key: 26,
      label: roomTypes,
      value: "/master-configuration/searchroomtype",
    },
    {
      key: 27,
      label: publicArea,
      value: "/master-configuration/searchpublicarea",
    },
    {
      key: 28,
      label: currency,
      value: "/master-configuration/searchcurrency",
    },
    {
      key: 29,
      label: unitofMeasurement,
      value: "/master-configuration/searchuompage",
    },
    {
      key: 30,
      label: requiredSubmittals,
      value: "/master-configuration/searchsubmitals",
    },
    {
      key: 31,
      label: modeofShipment,
      value: "/master-configuration/searchmodeofshipment",
    },
    {
      key: 32,
      label: locations,
      value: "/master-configuration/searchlocation",
    },
    {
      key: 33,
      label: incoterm,
      value: "/master-configuration/searchincoterm",
    },
    {
      key: 34,
      label: accessedandConsideration,
      value: "/master-configuration/searchacessandconsideration",
    },
    {
      key: 35,
      label: modeOfTransportation,
      value: "/master-configuration/modeoftransportation",
    },
    {
      key: 36,
      label: budgetScopetoItemCategoryMapping,
      value: "/master-configuration/budgetitemcategorymapping",
    },
    {
      key: 37,
      label: paymentTerms,
      value: "/master-configuration/searchpaymentterms",
    },
    {
      key: 38,
      label: finalPaymentAgainst,
      value: "/master-configuration/searchpaymentagainst",
    },
    {
      key: 39,
      label: paymentProgress,
      value: "/master-configuration/searchpaymentprogress",
    },
    {
      key: 40,
      label: itemGroup,
      value: "/master-configuration/itemgroup",
    },
    {
      key: 41,
      label: vendorCompanytoCategoryMapping,
      value: "/master-configuration/searchvendorcompanytocategorymapping",
    },
    // {
    //   key: 42,
    //   label: itemsubcategorylabel,
    //   value: "/master-configuration/itemsubcategory",
    // },
    {
      key: 43,
      label: establishmentItemCategoryMapping,
      value: "/master-configuration/searchestablishmenttoitemcategorymapping",
    },
    {
      key: 44,
      label: "Budget Scope To Item Group Mapping",
      value: "/master-configuration/searchscopegroupmap",
    },
    {
      key: 45,
      label: "Item Group To Sub Group Mapping",
      value: "/master-configuration/searchgroupsubgroupmap",
    },
    {
      key: 46,
      label: "Operator",
      value: "/master-configuration/searchoperator",
    },
    // {
    //   key: 47,
    //   label: "Item Category Sub Category Mapping",
    //   value: "/master-configuration/itemcategorysubcategorymapping",
    // },
    {
      key: 48,
      label: vendorLoginAcess,
      value: "/master-configuration/vendorloginaccess",
    },
    {
      key: 49,
      label: "Item Sub Group",
      value: "/master-configuration/searchitemsubgroup",
    },
  ];

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const openAddModals = () => {
    setIsOpens(true);
  };
  const handleModalsClose = () => {
    setMessage(null);
    setIsOpens(false);
  };

  const logOut = () => {
    handleClose();
    Swal.fire({
      title: logoutTitle,
      text: logoutText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        Logout()
          .then((res) => {
            setLoader(false);
            localStorage.clear();
            history.replace("/");
            window.location.reload();
          })
          .catch((e) => {
            localStorage.clear();
            window.location.href = process.env.REACT_APP_BASE_NAME_LOCATION;
            setLoader(false);
          });
      }
    });
  };
  const [uid, setUid] = React.useState(null);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUid(user.identity_id);
  }, []);

  const handleSetValue = (data) => {
    setLoader(true);
    ChangePassword(uid, data)
      .then((res) => {
        setLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const updateVendor = (formData) => {
    setLoader(true);
    const vendor_Id = JSON.parse(localStorage.getItem("vendor"));
    UpdateVendorManagement(formData, vendor_Id)
      .then((res) => {
        setUserName(res.data.data.name);
        setLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalsClose();
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <EditProfileForm
        setValue={handleSetValue}
        initialValue={initialValue}
        handlemodalclose={handleModalClose}
      />
    );
  };

  const selectModals = () => {
    const users = JSON.parse(localStorage.getItem("user"));
    if (users.loginType == "vendor") {
      return (
        <VendorForm
          handleChangeFormData={updateVendor}
          initialValue={data}
          handleModalClose={handleModalsClose}
          errorMessage={errorMessage}
          setMessage={setMessage}
        />
      );
    } else {
      return (
        <EditProfile
          errorMessage={errorMessage}
          setMessage={setMessage}
          initialValue={initialValue}
          actionType="edit"
          handlemodalclose={handleModalsClose}
        />
      );
    }
  };

  const editPassword = () => {
    openAddModal();
    handleClose();
  };
  const editProfile = () => {
    openAddModals();
    handleClose();
  };

  return (
    <>
      {loader ? <LoaderComponent /> : null}
      <Modal
        onHandleClose={handleModalClose}
        isOpen={isOpen}
        contentStyle={{
          width: "30%",
        }}
      >
        {selectModal()}
      </Modal>
      <Modal onHandleClose={handleModalsClose} isOpen={isOpens}>
        {selectModals()}
      </Modal>
      <nav style={{ zIndex: 4 }} className="navbar navbarMainContainer">
        <div className="container-fluid navbarContainer">
          <div className="d-flex align-items-center">
            <div
              className="light"
              onClick={() => handleToggleSidebar(!collapsed)}
            >
              {collapsed === true ? (
                <BsArrowRightCircle
                  style={{ fontSize: "1.5em", color: "rgb(110, 187, 31)" }}
                />
              ) : (
                <BsArrowLeftCircle
                  style={{ fontSize: "1.5em", color: "rgb(110, 187, 31)" }}
                />
              )}
              {/* <AiOutlineAlignLeft style={{ fontSize: "1.5em" }} /> */}
            </div>
            <Link to={"/"} className="nav-link d-none d-md-block">
              <img
                src={logoRect}
                alt="furnish"
                height="30em"
                className="d-inline-block align-text-top"
              ></img>
            </Link>
            <Link to={"/"} className="nav-link d-block d-md-none">
              <img
                src={logosm}
                alt="furnish"
                height="30em"
                className="d-inline-block align-text-top"
              ></img>
            </Link>
          </div>
          {location.pathname.includes("/master-configuration") && (
            <div className="flex-grow-1">
              <div className="serchSelect">
                <FormDropDown
                  placeholder="select master configuration"
                  mainContainerStyle={{ marginBottom: 8 }}
                  value={masterData}
                  Options={_.orderBy(formDropDownData, ["label"], ["asc"])}
                  onSelect={handleChangeMasterData}
                  isValidate={false}
                />
              </div>
            </div>
          )}

          <div className="nav-link active">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Tooltip title={userName}>
                <Avatar
                  alt={userName}
                  src=""
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#019688",
                  }}
                >
                  {/* {userName.charAt(0)} */}
                 {userName.match(/\b(\w)/g).join('').toUpperCase()}
                </Avatar>
              </Tooltip>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={editProfile}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit Profile
              </MenuItem>
              <MenuItem onClick={editPassword}>
                <ListItemIcon>
                  <LockResetIcon fontSize="small" />
                </ListItemIcon>
                Change Password
              </MenuItem>
              <Divider />

              <MenuItem onClick={logOut}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </nav>
    </>
  );
}
