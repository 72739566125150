/* eslint-disable no-unused-vars */
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, { useEffect, useState } from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { GetAllClient, UpdateClient } from "../../../axios";
import {
  BreadCrumbs,
  Button,
  ChangeImageModal,
  Modal,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { ClientForm } from "../../../forms";
import "../../../styles/ProfilePage.css";
import { default as Strings, default as strings } from "../../../translations";

function ClientProfilePage() {
  let history = useHistory();
  const contact = Strings["ClientProfile.contact"];

  const location = useLocation();
  const datas = location.state;

  const [clickedBtn, setClickedBtn] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [rows, setRows] = useState(null);
  const [ids, setIds] = useState();

  useEffect(() => {
    if (datas === null) {
      setIds(null);
      getAllClient(null);
    } else {
      setIds(datas);
      getAllClient(datas.id);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["clientText"],
      path: "master-configuration/searchclient",
    },
    {
      name: strings["ClientProfile"],
      path: "master-configuration/searchclient/clientprofilepage",
    },
  ];

  const handleModalClose = () => {
    setIsOpen(false);
  };
  const getAllClient = (id) => {
    setisLoader(true);
    GetAllClient()
      .then((res) => {
        setisLoader(false);
        res.data.data.map((elem) => {
          if (elem.id === id) {
            setRows(elem);
          }
        });
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const editContact = (formData) => {
    setisLoader(true);
    UpdateClient(formData, ids)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const result = () => {
            if (rows.id === res.data.data.id) {
              return {
                ...rows,
                name: res.data.data.name,
                address: res.data.data.address,
                city: res.data.data.city,
                client_type_id: res.data.data.client_type_id.id,
                country_id: res.data.data.country_id.id,
                email: res.data.data.email,
                fax: res.data.data.fax,
                website: res.data.data.website,
              };
            } else {
              return rows;
            }
          };
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const sendDataToParent = (value) => {
  };

  const selectModal = () => {
    if (clickedBtn === "edit") {
      return (
        <ClientForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          actionType={"edit"}
          handleChangeFormData={(formData) => {
            editContact(formData);
          }}
          initialValue={rows}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "image") {
      return <ChangeImageModal sendDataToParent={sendDataToParent} />;
    }
  };

  const openAddModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="container mb-3">
        <BreadCrumbs pathList={paths} />
        <div className="col-12">
        </div>
      </div>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <div className="profilebgdiv">
          <img
            className="profilebgimage"
            src="https://picsum.photos/1000/300"
            alt="profileimage"
          />
        </div>
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="profileimgdiv">
          <div className="profilediv1">
            <div
              className="profileimg"
              onClick={() => {
                setClickedBtn("image");
                openAddModal();
              }}
              style={{
                width: 210,
                height: 210,
                objectFit: "cover",
                borderRadius: "100%",
                border: "solid 5px #4747A1",
                backgroundColor: "#fff",
                backgroundImage: "url(https://picsum.photos/1000/300)",
              }}
            >
              <label className="changepiclabel">
                <span>
                  <CameraAltIcon fontSize="large" />
                </span>
                <span>Change Image</span>
              </label>
            </div>
            <div className="profiledatadiv">
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div
                  className=" order-2 order-md-1"
                  style={{ paddingLeft: 200 }}
                >
                  {rows ? (
                    <>
                      <h3 className="profiledataname">{rows.name}</h3>
                      <h6>{rows.website}</h6>
                      <h6>{rows.phone}</h6>
                      <h6>{rows.fax}</h6>
                      <h6>
                        {rows.address}, {rows.city}
                      </h6>
                    </>
                  ) : null}
                </div>
                <div className="order-1 order-md-2 text-center my-3 my-md-0">
                  <Button
                    className="me-3"
                    buttonName={contact}
                    onClick={() => {
                      history.push({
                        pathname: `clientcontactpage/${rows.id}`,
                        state: rows,
                      });
                    }}
                    icon={
                      <RiAccountCircleFill style={{ fontSize: "1.2rem" }} />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientProfilePage;
