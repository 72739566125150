import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { GetAllClientStatus } from "../../axios";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const BarStyle = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    zoom: {
      pan: {
        enabled: true
      },
      zoom: {
        enabled: true
      }
    }
  }
};



const ChartHorizontal = () => {

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Dataset 1",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(12, 84, 166)",
      },
      {
        label: "Dataset 2",
        data: [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgb(0, 204, 136)",
      },
      {
        label: "Dataset 3",
        data: [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgb(92, 89, 198)",
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const clientList = [];
      const projectList = [];
      const budgetVal = [];
      const actVal = [];
      const saveVali = [];
      GetAllClientStatus()
        .then((res) => {
          for (const val of res.data) {
            clientList.push(val.name);
            projectList.push(val.project_name);
            budgetVal.push(val.budget_value);
            actVal.push(val.leftprice);
            saveVali.push(val.budget_value - val.leftprice);
          }
          setData({
            labels: clientList,
            datasets: [
              {

                label: "Budget Value",
                data: budgetVal,
                borderColor: "rgba(12, 84, 166)",
                backgroundColor: "rgb(12, 84, 166, .7)",
              },
              {
                label: "Actual Value",
                data: actVal,
                borderColor: "rgba(0, 204, 136)",
                backgroundColor: "rgb(0, 204, 136, .7)",
              },
              {
                label: "Savings Value",
                data: saveVali,
                borderColor: "rgba(76, 232, 14)",
                backgroundColor: "rgb(76, 232, 14)",
              },
            ],
          });
        })
        .catch(() => {
        });
    };

    fetchData();
  }, []);

  return (
    <div style={{ width: "100vw", overflow: "scroll !important" }}>
      <Bar data={data} options={BarStyle} height={100} />
    </div>
  );
};
export default ChartHorizontal;
