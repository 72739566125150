import _ from "lodash";
import React, { useEffect } from "react";
import {
  GetAllActiveProducts,
  GetAllActiveVendorCategory,
  GetAllActiveVendorSubCategory,
} from "../../../axios";
import { Button, FormCheckBox, FormDropDown } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateVendorCategoryToProductMapping = (props) => {
  const {
    errorMessage,
    handleResultData,
    actionType,
    initialValue = {},
    handleModalClose,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [subCategoryOptions, setsubCategoryOptions] = React.useState([]);

  const [initialProducts] = React
    .useState
    ();

  const [selectCategoryData, setSelectCategoryData] = React.useState({
    value: initialValue.id ? initialValue.id : "",
    key: initialValue.id ? initialValue.id : "",
    error: false,
    success: false,
  });

  const [selectSubCategoryData, setSelectSubCategoryData] = React.useState({
    value: initialValue.id ? initialValue.id : "",
    key: initialValue.id ? initialValue.id : "",
    error: false,
    success: false,
  });
  const [products, setProducts] = React.useState([]);

  const getAllActiveVendorCatagory = () => {
    setLoading(true);
    GetAllActiveVendorCategory()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.category,
        }));
        setCategoryOptions(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getAllActiveVendorSubCategory = () => {
    setLoading(true);
    GetAllActiveVendorSubCategory()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.sub_category,
        }));
        setsubCategoryOptions(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (actionType === "add") {
      GetAllActiveProducts()
        .then((res) => {
          let allData = res.data.data;
          const result = allData.map((elm) => ({
            key: elm.id,
            productName: elm.name,
            checked: false,
          }));
          setProducts(result);
        })
        .catch(() => {
        });
    }
  }, [actionType]);

  React.useEffect(() => {
    if (actionType === "edit") {
      setLoading(true);
      const arr = initialValue.products;
      const checkArr = { checked: true };
      for (let i = 0; i < arr?.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var pdct_id_list = [];
      arr?.map((ele) => pdct_id_list.push(ele.id));
      GetAllActiveProducts()
        .then((res) => {
          let allData = res.data.data;
          setLoading(false);
          const fetchData = allData.map((elm) => ({
            key: elm.id,
            productName: elm.name,
            checked: pdct_id_list.includes(elm.id) ? true : false,
          }));
          setProducts(fetchData);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    getAllActiveVendorCatagory();
    getAllActiveVendorSubCategory();
  }, []);

  const handleChangeCheck = (key) => {
    const result = products.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setProducts(result);
  };

  const handleSelectCategoryDropDown = (value) => {
    setSelectCategoryData(value);
  };

  const handleSelectSubCategoryDropDown = (value) => {
    setSelectSubCategoryData(value);
  };
  const functionResultTrue = (roles) => {
    const resultArray = [];
    products.forEach((item) => {
      if (item.checked === true) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      category_id: selectCategoryData,
      sub_category_id: selectSubCategoryData,
      product_ids: functionResultTrue(products),
    };
    handleResultData(formData);
  };

  const pageHeader = Strings["VendorProductMapping.CreateTitle"],
    editpageHeader = Strings["VendorProductMapping.EditTitle"],
    dropdownCategoryLabel = Strings["VendorProductMapping.CategoryDropDown"],
    dropdownCategoryCustomError =
      Strings["VendorProductMapping.CategoryCustomError"],
    dropdownSubCategoryLabel =
      Strings["VendorProductMapping.SubCategoryDropDown"],
    dropdownSubCategoryCustomError =
      Strings["VendorProductMapping.SubCategoryCustomError"],
    selectCategoryText = Strings["VendorProductMapping.CategoryPlaceHolder"],
    selectSubCategoryText =
      Strings["VendorProductMapping.SubCategoryPlaceHolder"],
    selectProductHeader = Strings["VendorProductMapping.SelectProduct"],
    SaveButton = Strings["VendorCategoryMapping.SaveBtn"],
    cancelButton = Strings["VendorCategoryMapping.CancelBtn"];

  const disable =
    selectCategoryData.value === "" ||
    selectCategoryData.error ||
    selectCategoryData.success === false ||
    selectSubCategoryData.value === "" ||
    selectSubCategoryData.error ||
    selectSubCategoryText.success === false ||
    products.every((functionName) => {
      return !functionName.checked;
    });

  const editDisable =
    initialValue.name === selectCategoryData.value &&
    initialValue.subCategory === selectSubCategoryData.value &&
    _.isEqual(products, initialProducts);

  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      <h2 className="pb-3">
        {actionType === "add" ? pageHeader : editpageHeader}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormDropDown
        labelStyle={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        selectText={selectCategoryText}
        Label={dropdownCategoryLabel}
        Options={categoryOptions}
        onSelect={handleSelectCategoryDropDown}
        customErrorLine={dropdownCategoryCustomError}
        searchable={false}
        value={selectCategoryData.value}
        disable={actionType !== "add" ? true : false}
        starProps={true}
        isValidate={true}
      />
      <FormDropDown
        labelStyle={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        selectText={selectSubCategoryText}
        Label={dropdownSubCategoryLabel}
        Options={subCategoryOptions}
        onSelect={handleSelectSubCategoryDropDown}
        customErrorLine={dropdownSubCategoryCustomError}
        searchable={false}
        value={selectSubCategoryData.value}
        disable={actionType !== "add" ? true : false}
        starProps={true}
        isValidate={true}
      />
      <br />
      <h4>
        {selectProductHeader}
        <span className="text-danger">*</span>
      </h4>
      <div className={"row"}>
        {products.map((functionName) => {
          return (
            <div className={"col-12 col-md-6"}>
              <FormCheckBox
                starProps={true}
                key={functionName.key}
                Label={functionName.productName}
                checked={functionName.checked}
                handleChangeCheck={() => handleChangeCheck(functionName.key)}
              />
            </div>
          );
        })}
      </div>

      <div className="row deleteFunction__buttongroup">
        <div style={{ display: "flex", marginTop: "30px" }}>
          <Button
            buttonName={SaveButton}
            disable={actionType === "add" ? disable : editDisable}
            onClick={handleSaveData}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
          <Button
            buttonName={cancelButton}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateVendorCategoryToProductMapping;
