import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ChartJsImage from "chartjs-to-image";
import React from "react";
import { logoRect } from "../../../assets";
import { GetDynamicReport } from "../../../axios";

import PropagateLoader from "react-spinners/PropagateLoader";

const DynamicReport = (props) => {
  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);

  React.useEffect(() => {
    GetDynamicReport(id)
      .then((res) => {
        setReportData(res.data.data);
        setShows(true);
      })
      .catch(() => {});
  }, []);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      margin: 0,
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      // top: "0",
      right: "-500px",
      fontSize: "8px",
    },
    canvas: {
      backgroundColor: "black",
      height: 500,
      width: 500,
    },
  });

  const [orderValue, setOrderValue] = React.useState("");
  const [orderLimit, setOrderLimit] = React.useState("");

  React.useEffect(() => {
    const myChart = new ChartJsImage();
    myChart.setConfig({
      type: "pie",
      data: {
        labels: ["Order Value", "Unorder Value"],
        datasets: [
          {
            data: [
              reportData.length !== 0
                ? reportData.Ordered_price.total_price
                : 0,
              reportData.length !== 0
                ? reportData.Un_ordered_price.total_unordered_price
                : 0,
            ],
          },
        ],
      },
      backgroundColor: ["#FFFF00", "#00CC88"],
    });
    setOrderValue(myChart.getUrl());
  }, [reportData]);

  React.useEffect(() => {
    const myChart = new ChartJsImage();
    myChart.setConfig({
      type: "pie",
      data: {
        labels: ["Order Limit", "Unorder Limit"],
        datasets: [
          {
            data: [
              reportData.length !== 0
                ? reportData.getall[0].budgetitemordered_count
                : 0,
              reportData.length !== 0
                ? reportData.getall[0].budgetitem_count -
                  reportData.getall[0].budgetitemordered_count
                : 0,
            ],
          },
        ],
      },
      backgroundColor: ["#FFFF00", "#00CC88"],
    });
    setOrderLimit(myChart.getUrl());
  }, [reportData]);

  return (
    <>
      {
        shows === true ? (
          <PDFViewer style={styles.viewer}>
            <Document>
              <Page size="A4" style={styles.page} wrap>
                <View fixed>
                  <View style={styles.section} fixed>
                    <Image style={styles.image} src={logoRect} />
                    <Text style={{ fontSize: "12px", display: "flex" }}>
                      Dynamic Summary Report
                    </Text>
                  </View>
                </View>
                <View style={{ marginLeft: "25px", marginRight: "25px" }} fixed>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "30%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Project Name
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData.getall[0].project.name}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderBottom: "1px solid black",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "30%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Budget Number
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.getall[0].serial_number +
                          " - " +
                          reportData.getall[0].name}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "30%",
                          fontSize: "10px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Issuing Date
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {reportData.getall[0].budget_start_date.split(" ")[0]}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                      width: "60%",
                      margin: "10px auto",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total POs Issued
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData.getall[0].budget_item_purchase_order.length}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total Line Items
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData.getall[0].budgetitem_count}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total Line Ordered
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        {reportData.getall[0].budgetitemordered_count}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total Actual Value
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        AED {reportData.price.Final_actual_price}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total Budget Value
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        AED {reportData.price.Final_budget_price}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "13px",
                      }}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "11px",
                          borderRight: "1px solid black",
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Difference
                      </Text>
                      <Text
                        style={{
                          width: "60%",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        AED{" "}
                        {reportData.price.Final_actual_price -
                          reportData.price.Final_budget_price}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    marginLeft: "25px",
                    marginRight: "25px",
                    marginTop: "10px",
                  }}
                  break
                >
                  <View style={{ alignItems: "center" }}>
                    <Text style={{ fontSize: "12px" }}>Dynamic Budget</Text>
                    <View
                      style={{
                        width: "100%",
                        height: "50%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Image src={orderValue} />
                      <Image src={orderLimit} />
                    </View>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "25px",
                        marginRight: "25px",
                        width: "50%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                          border: "1px solid black",
                          marginTop: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <Text
                            style={{
                              width: "40%",
                              fontSize: "9px",
                              borderRight: "1px solid black",
                              padding: "5px",
                              backgroundColor: "#f2f2f2",
                            }}
                          >
                            Ordered Value
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: "9px",
                              padding: "5px",
                            }}
                          >
                            {" "}
                            AED {reportData.Ordered_price.total_price}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderBottom: "1px solid black",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              width: "40%",
                              fontSize: "9px",
                              borderRight: "1px solid black",
                              padding: "5px",
                              backgroundColor: "#f2f2f2",
                            }}
                          >
                            Unordered Value
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: "9px",
                              padding: "5px",
                            }}
                          >
                            AED{" "}
                            {reportData.Un_ordered_price.total_unordered_price}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              width: "40%",
                              fontSize: "9px",
                              borderRight: "1px solid black",
                              padding: "5px",
                              backgroundColor: "#f2f2f2",
                            }}
                          >
                            Total Value
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: "9px",
                              padding: "5px",
                            }}
                          >
                            AED{" "}
                            {reportData.Ordered_price.total_price +
                              reportData.Un_ordered_price.total_unordered_price}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginLeft: "25px",
                        marginRight: "25px",
                        width: "50%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                          border: "1px solid black",
                          marginTop: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <Text
                            style={{
                              width: "40%",
                              fontSize: "9px",
                              borderRight: "1px solid black",
                              padding: "5px",
                              backgroundColor: "#f2f2f2",
                            }}
                          >
                            Ordered Items
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: "9px",
                              padding: "5px",
                            }}
                          >
                            {" "}
                            {reportData.getall[0].budgetitemordered_count}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderBottom: "1px solid black",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              width: "40%",
                              fontSize: "9px",
                              borderRight: "1px solid black",
                              padding: "5px",
                              backgroundColor: "#f2f2f2",
                            }}
                          >
                            Unordered Items
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: "9px",
                              padding: "5px",
                            }}
                          >
                            {reportData.getall[0].budgetitem_count -
                              reportData.getall[0].budgetitemordered_count}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              width: "40%",
                              fontSize: "9px",
                              borderRight: "1px solid black",
                              padding: "5px",
                              backgroundColor: "#f2f2f2",
                            }}
                          >
                            Total Items
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: "9px",
                              padding: "5px",
                            }}
                          >
                            {reportData.getall[0].budgetitem_count}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.footer} fixed>
                  <View
                    style={{
                      borderTop: "1px solid black",
                      marginRight: "50px",
                    }}
                  ></View>
                  <View>
                    <Text style={{ fontSize: "8px" }}>
                      PO BOX 282247, Dubai, UAE
                    </Text>
                    {/* <Text style={{ fontSize: "8px" }}>
                                            tel +971 4 3307725
                                        </Text>
                                        <Text style={{ fontSize: "8px" }}>
                                            <Link style={{ color: "black" }} src="mailto:invitation@furnish-marketplace.com">
                                                invitation@furnish-marketplace.com
                                            </Link>
                                        </Text>
                                        <Text style={{ fontSize: "8px" }}>
                                            <Link style={{ color: "black" }} src="http://furnish-marketplace.com">
                                                furnish-marketplace.com
                                            </Link>
                                        </Text> */}
                  </View>
                  <View>
                    <Text
                      style={styles.pageNo}
                      render={({ pageNumber, totalPages }) =>
                        `Page # ${pageNumber} / ${totalPages}`
                      }
                      fixed
                    />
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        ) : (
          <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
            <PropagateLoader color="blue" />
          </View>
        )

        // <Text style={{ color: "red", margin: "auto" }}>Please Wait...</Text>
      }
    </>
  );
};

export default DynamicReport;
