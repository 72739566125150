import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { colors } from "../../configs";
import strings from "../../translations";

const AddIconComponent = () => {
  const addTooltip = strings["Tooltip.addTooltip"];
  return (
    <React.Fragment>
      <Tooltip title={addTooltip}>
        <AddIcon
          sx={{ fontSize: "1.2em", color: colors.primary }}
          style={{ width: "20px", height: "20px" }}
        />
      </Tooltip>
    </React.Fragment>
  );
};

export default AddIconComponent;
