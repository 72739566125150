import React from "react";
import { Button, FormDropDown, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const VendorContactForm = (props) => {
  const {
    setMessage,
    errorMessage,
    branchOptions,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    vendorId,
  } = props;

  const createvendorheader = Strings["VendorContact.CreateForm"],
    editvendorheader = Strings["VendorContact.EditForm"],
    vendorContactBranchLabel = Strings["VendorContact.BranchNameLabel"],
    vendorContactBranchError = Strings["VendorContact.BranchNameError"],
    vendorContactNameCustomError = Strings["VendorContact.ContactNameError"],
    vendorContactDesignationLabel =
      Strings["VendorContact.ContactDesignationLabel"],
    vendorContactDesignationPlaceHolder =
      Strings["VendorContact.ContactDesignationPlaceHolder"],
    vendorContactDesignationError =
      Strings["VendorContact.ContactDesignationError"],
    vendorContactTelephoneLabel =
      Strings["VendorContact.ContactTelephoneLabel"],
    vendorContactTelephonePlaceHolder =
      Strings["VendorContact.ContactTelephonePlaceHolder"],
    vendorContactTelephoneError =
      Strings["VendorContact.ContactTelephoneError"],
    vendorContactMobileLabel = Strings["VendorContact.ContactMobileLabel"],
    vendorContactMobilePlaceHolder =
      Strings["VendorContact.ContactMobilePlaceHolder"],
    vendorContactMobileError = Strings["VendorContact.ContactMobileError"],
    vendorContactEmailLabel = Strings["VendorContact.ContactEmailLabel"],
    vendorContactEmailPlaceHolder =
      Strings["VendorContact.ContactEmailPlaceHolder"],
    vendorContactEmailError = Strings["VendorContact.ContactEmailError"],
    vendorContactSaveBtn = Strings["VendorContact.SaveBtn"],
    vendorContactUpdateBtn = Strings["VendorContact.UpdateBtn"],
    vendorContactCancelBtn = Strings["VendorContact.CancelBtn"];

  const [branch, setBranch] = React.useState({
    value: initialValue.id ? initialValue.id : "",
    error: false,
    sucess: false,
  });
  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    sucess: false,
  });
  const [designation, setDesignation] = React.useState({
    value: initialValue.designation ? initialValue.designation : "",
    error: false,
    sucess: false,
  });
  const [telephone, setTelephone] = React.useState({
    value: initialValue.telephone ? initialValue.telephone : "",
    error: false,
    sucess: false,
  });
  const [mobile, setMobile] = React.useState({
    value: initialValue.mobile ? initialValue.mobile : "",
    error: false,
    sucess: false,
  });
  const [email, setEmail] = React.useState({
    value: initialValue.email ? initialValue.email : "",
    error: false,
    sucess: false,
  });

  const [image] = React.useState({
    value: initialValue.image ? initialValue.image : "",
    error: false,
    sucess: false,
  });

  const handleChangeBranch = (value) => {
    setBranch(value);
  };

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };
  const handleChangeDesignation = (value) => {
    setDesignation(value);
  };
  const handleChangeTelephone = (value) => {
    setTelephone(value);
  };
  const handleChangeMobile = (value) => {
    setMobile(value);
  };
  const handleChangeEmail = (value) => {
    setEmail(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: name.value,
      branch_id: branch.value.toString(),
      designation: designation.value,
      telephone: telephone.value,
      mobile: mobile.value,
      email: email.value,
      image: image.value,
      vendor_id: vendorId.toString(),
    };
    handleChangeFormData(formData);
  };
  let isValueChanged =
    name.value === initialValue.name &&
    designation === initialValue.designation &&
    telephone.value === initialValue.telephone &&
    mobile.value === initialValue.mobile &&
    branch.value === initialValue.id &&
    email.value === initialValue.email;

  let disable =
    name.error ||
    designation.error ||
    telephone.error ||
    mobile.error ||
    email.error ||
    name.value === "" ||
    designation.value === "" ||
    telephone.value === "" ||
    mobile.value === "" ||
    email.value === "" ||
    branch.value === "" ||
    (name.sucess === false &&
      designation.sucess === false &&
      telephone.sucess === false &&
      mobile.sucess === false &&
      email.sucess === false) ||
    isValueChanged;
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createvendorheader : editvendorheader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row mb-3">
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            type="textarea"
            starProps={true}
            label={"Vendor Contacts Name"}
            placeholder={"Enter Vendor Contacts Name"}
            Value={name.value}
            onChangeText={handleChangeName}
            Validate={true}
            CustomErrorLine={vendorContactNameCustomError}
            editable={true}
            maxLength={100}
          />
        </div>
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            type="textarea"
            maxLength={30}
            starProps={true}
            label={vendorContactDesignationLabel}
            placeholder={vendorContactDesignationPlaceHolder}
            Value={designation.value}
            onChangeText={handleChangeDesignation}
            Validate={true}
            CustomErrorLine={vendorContactDesignationError}
            editable={true}
          />
        </div>
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            type="phone"
            starProps={true}
            label={vendorContactTelephoneLabel}
            placeholder={vendorContactTelephonePlaceHolder}
            Value={telephone.value}
            onChangeText={handleChangeTelephone}
            Validate={true}
            CustomErrorLine={vendorContactTelephoneError}
            editable={true}
            maxLength={12}
          />
        </div>
        <div className="col-md-6 col-sm-12  m-auto">
          <FormDropDown
            labelStyle={{
              marginTop: "22px",
              marginBottom: "10px",
            }}
            value={branch.value}
            isMulti={false}
            starProps={true}
            isValidate={true}
            Label={vendorContactBranchLabel}
            CustomErrorLine={vendorContactBranchError}
            Options={branchOptions}
            selectionLimit={3}
            onSelect={handleChangeBranch}
          />
        </div>
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            type="phone"
            starProps={true}
            label={vendorContactMobileLabel}
            placeholder={vendorContactMobilePlaceHolder}
            Value={mobile.value}
            onChangeText={handleChangeMobile}
            Validate={true}
            CustomErrorLine={vendorContactMobileError}
            editable={true}
            maxLength={12}
          />
        </div>
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            type="email"
            starProps={true}
            label={vendorContactEmailLabel}
            placeholder={vendorContactEmailPlaceHolder}
            Value={email.value}
            onChangeText={handleChangeEmail}
            Validate={true}
            CustomErrorLine={vendorContactEmailError}
            editable={true}
          />
        </div>
        <div className="col-md-5 col-sm-12  m-auto"></div>
      </div>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? vendorContactSaveBtn : vendorContactUpdateBtn
          }
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
          buttonStyle={{ backgroundColor: colors.primary }}
        />
        <Button
          buttonName={vendorContactCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage();
          }}
        />
      </div>
    </div>
  );
};

export default VendorContactForm;
