/* eslint-disable no-unused-vars */
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import React from "react";
import { Checkbox, Pagination, Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { colors } from "../../../configs";

import { Chip, IconButton, SwitchButton,Button } from "../../../components";

const ActionCell = ({
  rowData,
  dataKey,
  handleChangeActiveStatus,
  handleChangeEdit,
  handleView,
  ...props
}) => {
  function handleActionEdit() {
    handleChangeEdit(rowData[dataKey]);
  }
  const handleChangeStatus = () => {
    let status = rowData[dataKey];
    handleChangeActiveStatus(status, rowData.is_active);
  };

  const handleViewEditdata = () => {
    handleView(rowData[dataKey]);
  };

  return (
    <Cell {...props}>
      <div style={{ marginTop: -10 }}>
        <IconButton onClick={handleActionEdit}>
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
        {/* <Tooltip title="status">
          <SwitchButton
            checked={rowData.is_active}
            onClick={() => {
              handleChangeStatus();
            }}
          />
        </Tooltip> */}
        {/* <IconButton onClick={handleActionEdit}>
          
        </IconButton> */}
        {props.isview ? (
          <Tooltip
            title="View"
            onClick={handleViewEditdata}
            style={{ paddingRight: 5 }}
          >
            <Visibility />
          </Tooltip>
        ) : null}
      </div>
    </Cell>
  );
};

const ActionCells = ({ rowData, dataKey, handleView, ...props }) => {
  const handleViewEditdata = () => {
    handleView(rowData);
  };

  return (
    <Cell {...props} className="link-group">
      <div style={{ marginLeft: "10px" }}>
        <b
          title={"itemSerialNo"}
          onClick={() => handleViewEditdata(rowData)}
          style={{ cursor: "pointer" }}
        >
          {rowData[dataKey]}
        </b>
      </div>
    </Cell>
  );
};

const SearchCheckTable = (props) => {
  const {
    data,
    handleChangeCheck,
    handleChangeEdit,
    mapableData,
    handleView,
    searchProp,
    handleSelectAll,
    showSelectAll
  } = props;

  const [allData, setAllData] = React.useState(data);
  const [searchedData, setSearchedData] = React.useState(data);
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });

  const [tempData] = React.useState(data);
  const [selectAll, setSelectAll] = React.useState(false);

  React.useEffect(() => {
    setAllData(data);
  }, [data]);

  const buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };

  const filterBuilderData = (data, query) => {
    let searchedData = [];
    if(data.length){
      data.forEach((item) => {
        for (let key in query) {
          if (
            !_.includes(searchedData, item) &&
            item[key].toLowerCase().includes(query[key][0].toLowerCase())
          ) {
            searchedData.push(item);
          }
        }
      });
    }
   
    return searchedData;
  };

  const CheckCell = ({
    rowData,
    handleChangeCheck,
    checkedKeys,
    dataKey,
    ...props
  }) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "42px" }}>
        <Checkbox
          value={rowData[dataKey]}
          inline
          onChange={() => handleChangeCheck(rowData[dataKey])}
          checked={rowData["checked"]}
          style={{ color: colors.primary }}
        />
      </div>
    </Cell>
  );

  let filter = {};
  React.useEffect(() => {
    searchProp.forEach((e) => (filter[e] = [searchValue.value]));
    setSearchedData(filterBuilderData(data, buildFilter(filter)));
  }, [searchProp, searchValue]);

  const query = buildFilter(filter);


  const filterData = (data) => {
    const result = data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    return result;
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const handleSortColumn = (column, type) => {
    setSortColumn(column);
    setSortType(type);
    const result = _.orderBy(data, [column], [type]);
    setSearchedData(result);
  };

  const limitOption = (allData) => {
    const res = [];
    const length = allData.length;
    const allDataLength = length + (5 - (length % 5));
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i);
    }
    return res;
  };

  return (
    <React.Fragment>
      <Table
        style={{ borderRadius: 10 }}
        height={320}
        id="table"
        data={filterData(searchedData)}
        onSortColumn={handleSortColumn}
        sortColumn={sortColumn}
        sortType={sortType}
        rowHeight={50}
        headerHeight={50}
      >
        <Column width={50} align="center">
          <HeaderCell
            style={{
              color: colors.primary,
              fontWeight: 900,
              fontSize: "larger",
            }}
          >
             {showSelectAll && <Checkbox inline onChange={handleSelectAll}/>}
            {/* <Checkbox inline onChange={handleSelectAll}/> */}
          </HeaderCell>
          <CheckCell dataKey="id" handleChangeCheck={handleChangeCheck} />
        </Column>
        {mapableData.map((item, index) => {
          return (
            <Column minWidth={130} flexGrow={1} sortable key={index}>
              <HeaderCell
                style={{
                  color: colors.primary,
                  fontWeight: 900,
                  fontSize: "larger",
                }}
              >
                {item.title}
              </HeaderCell>
              {item.hasOwnProperty("isviewlink") ? (
                <ActionCells dataKey="itemSerialNo" handleView={handleView} />
              ) : item.hasOwnProperty("isAction") ? (
                <ActionCell
                  dataKey="id"
                    // handleChangeDelete={handleChangeDelete}
                  handleChangeEdit={handleChangeEdit}
                />
              ) : (
                <Cell dataKey={item.dataKey} />
              )}
            </Column>
          );
        })}
      </Table>
      {data.length > 5 && (
        <div style={{ width: "100%" }}>
          <Pagination
            style={{ marginTop: 20 }}
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={data.length}
            limitOptions={limitOption(data)}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchCheckTable;
