import _ from "lodash";
import React from "react";
import {
  GetAllActiveItemSubGroup,
  GetAllBudgetScope,
  GetItemGroupByScopeId,
} from "../../../axios";
import { Button, FormCheckBox, FormDropDown } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateMappingGroupSubGroupForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleResultData,
    actionType,
    initialValue = {},
    handleModalClose,
  } = props;

  const [loading, setLoading] = React.useState(false);
  console.log(initialValue);
  const [initialItemGroup, setInitialSubCategory] = React.useState(
    initialValue.subgroups ? [...initialValue.subgroups] : []
  );
  const [itemGroup, setItemGroup] = React.useState([]);
  const [selectData, setSelectData] = React.useState({
    value: initialValue.group_name ? initialValue.item_group_id : "",
    error: false,
    success: false,
  });
  const [selectScopeData, setScopeSelectData] = React.useState({
    value: initialValue.scope_name ? initialValue.scope_id : "",
    error: false,
    success: false,
  });
  const [options, setOptions] = React.useState([]);
  const [scopeOptions, setScopeOptions] = React.useState([]);

  React.useEffect(() => {
    if (actionType === "add") {
      setLoading(true);
      GetAllActiveItemSubGroup()
        .then((res) => {
          let allData = res.data.data;
          const result = allData.map((elm) => ({
            key: elm.id,
            name: elm.name,
            checked: false,
          }));
          setLoading(false);
          setItemGroup(result);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);
  React.useEffect(() => {
    getAllBudgetScope();
  }, []);

  const getAllBudgetScope = () => {
    setLoading(true);
    GetAllBudgetScope()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setScopeOptions(result);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (actionType === "edit") {
      setLoading(true);
      const arrJoin = initialValue.subgroupids?.split(",");
      const checkArr = { checked: true };
      for (let i = 0; i < arrJoin?.length; i++) {
        arrJoin[i] = Object.assign(arrJoin[i], checkArr);
      }
      var sub_id_list = [];
      const result = arrJoin?.map((ele) => sub_id_list.push(parseInt(ele)));
      GetAllActiveItemSubGroup()
        .then((res) => {
          setLoading(false);
          const fetchData = res.data.data.map((elm) => ({
            key: elm.id,
            name: elm.name,
            checked: sub_id_list.includes(elm.id) ? true : false,
          }));
          setItemGroup(fetchData);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const getAllActiveBudgetScope = (scope_id) => {
    setLoading(true);
    // AllActiveStatusItemGroup()
    GetItemGroupByScopeId(scope_id)
      .then((res) => {
        // let allData = res.data.data;
        // const result = allData.map((elm) => ({
        //   key: elm.id,
        //   value: elm.id,
        //   label: elm.group_name,
        // }));
        let data = res.data.data.item_group;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.group_name,
        }));
        setOptions(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAllActiveBudgetScope(1);
  }, []);

  const handleChangeCheck = (key) => {
    const result = itemGroup.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setItemGroup(result);
  };

  const handleSelectDropDown = (value) => {
    setMessage(null);
    setSelectData(value);
  };
  const handleSelectScopeDropDown = (value) => {
    setMessage(null);
    console.log(value);
    setScopeSelectData(value);
    getAllActiveBudgetScope(value.value);
  };
  const functionResultTrue = (roles) => {
    const resultArray = [];
    itemGroup.forEach((item) => {
      if (item.checked === true) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      scope_id: selectScopeData.value,
      item_group_id: selectData.value,
      item_sub_group_ids: functionResultTrue(itemGroup),
    };
    console.log(formData);
    handleResultData(formData);
  };

  const disable =
    selectData.value === "" ||
    selectData.error ||
    selectData.success === false ||
    itemGroup.every((functionName) => {
      return !functionName.checked;
    });

  const editDisable =
    initialValue.name === selectData.value &&
    _.isEqual(itemGroup, initialItemGroup);

  const pageHeader = Strings["ItemGroupSubGroup.CreateMapping"],
    editpageHeader = Strings["ItemGroupSubGroup.EditMapping"],
    dropdownLabel = Strings["ItemGroupSubGroup.SelectItemGroup"],
    dropdownCustomError =
      Strings["VendorCompanytoCategoryMapping.CategoryCustomError"],
    selectText = Strings["ItemGroupSubGroup.SelectPlaceHolder"],
    selctRoleHeader = Strings["ItemGroupSubGroup.SelectItemSubGroup"],
    SaveButton = Strings["VendorCompanytoCategoryMapping.SaveBtn"],
    cancelButton = Strings["VendorCompanytoCategoryMapping.CancelBtn"];
  console.log(itemGroup);
  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? pageHeader : editpageHeader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormDropDown
        labelStyle={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        selectText={selectText}
        Label={"Select Budget Scope"}
        Options={scopeOptions}
        onSelect={handleSelectScopeDropDown}
        customErrorLine={dropdownCustomError}
        searchable={false}
        value={selectScopeData.value}
        disable={actionType !== "add" ? true : false}
        isValidate={true}
      />
      <FormDropDown
        labelStyle={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        selectText={selectText}
        Label={dropdownLabel}
        Options={options}
        onSelect={handleSelectDropDown}
        customErrorLine={dropdownCustomError}
        searchable={false}
        value={selectData.value}
        disable={actionType !== "add" ? true : false}
        isValidate={true}
      />
      <br />
      <h4>{selctRoleHeader}</h4>
      <div className={"row"}>
        {itemGroup.map((functionName) => {
          return (
            <div className={"col-12 col-md-4"}>
              <FormCheckBox
                key={functionName.key}
                Label={functionName.name}
                checked={functionName.checked}
                handleChangeCheck={() => handleChangeCheck(functionName.key)}
              />
            </div>
          );
        })}
      </div>

      <div className="row deleteFunction__buttongroup">
        <div style={{ display: "flex", marginTop: "30px" }}>
          <Button
            buttonName={SaveButton}
            disable={actionType === "add" ? disable : editDisable}
            onClick={handleSaveData}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
          <Button
            buttonName={cancelButton}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateMappingGroupSubGroupForm;
