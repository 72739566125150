/* eslint-disable no-unused-vars */
import moment from "moment";
import * as React from "react";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import {
  CreatePaymentInvoice,
  DeleteInvoicePayment,
  GetPaymentByInvoiceId,
  UpdatedInvoicePayment,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  NodataFound,
} from "../../../components";
import SearchPaymentTable from "../../../components/AppSpecific/SearchTable/SearchPaymentTable";
import LoaderComponent from "../../../components/common/Loader";
import { InvoicePaymentForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_INVOICE_STATUS_FUNCTION_PAYMENT,
  CREATE_INVOICE_PAYMENT,
  UPDATE_INVOICE_PAYMENT,
} from "../../../utils/constants";
import { isEncoded } from "../../../utils/crypto";

export default function SearchCountryPage() {
  const searchTitle = Strings["Payment.SearchPaymentHeader"],
    alertMessage = Strings["Payment.AlertMessage"],
    alertTitle = Strings["Payment.AlertTitle"],
    name = Strings["Payment.TableHead.name"],
    code = Strings["Payment.TableHead.code"],
    tag = Strings["Payment.TableHead.tag"],
    action = Strings["Country.TableHead.action"];
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [editId, setEditId] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState();
  const [alertBox, setAlertBox] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);


  const poDataType = localStorage.getItem("directPO");

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_INVOICE_PAYMENT)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_INVOICE_PAYMENT)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_INVOICE_STATUS_FUNCTION_PAYMENT)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);
  const location = useLocation();
  const proId = location.state
    ? location.state.proId
    : location.query
      ? JSON.parse(location.query.params).proId
      : null;
  const budget_id = location.state
    ? location.state.budget_id
    : location.query
      ? JSON.parse(location.query.params).budget_id
      : null;
  const rfqId = location.state
    ? location.state.rfqId
    : location.query
      ? JSON.parse(location.query.params).rfqId
      : null;
  const purchase_id = location.state
    ? location.state.purchase_id
    : location.query
      ? JSON.parse(location.query.params).purchase_id
      : null;


  const invoice_id = location.state;
  const invoiceID = invoice_id.id;

  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  const encodedRfqId = isEncoded(rfqId);
  const encodedPurchase = isEncoded(purchase_id);

  var paths = [];
  if (poDataType == "true") {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodedRfqId}`,
      },
      {
        name: strings["Invoice"],
        path: `invoice/${encodedPurchase}`,
      },
      {
        name: "Invoice Payment",
        path: "",
      },
    ]
  } else {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["Rfq"],
        path: `rfq/${encodeBudId}`,
      },
      {
        name: strings["Quotation"],
        path: `quotation/${encodedRfqId}`,
      },
      {
        name: strings["costanlysisText"],
        path: `costanalysis/${encodedRfqId}`,
      },
      {
        name: "Cost Analysis Report",
        path: `searchcostreport/${encodedRfqId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodedRfqId}`,
      },
      {
        name: strings["Invoice"],
        path: `invoice/${encodedPurchase}`,
      },
      {
        name: "Invoice Payment",
        path: "",
      },
    ]
  }

  const mapableData = [
    {
      dataKey: "paid_date",
      title: name,
    },
    {
      dataKey: "currency.name",
      title: code,
    },
    {
      dataKey: "amount",
      title: tag,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };

  const createInvoicePayment = (formData) => {
    setisLoader(true);
    CreatePaymentInvoice(formData)
      .then((res) => {
        setisLoader(false);
        getAlldata();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
        setRows([res.data.data, ...rows]);
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updatePayment = (formData) => {
    setisLoader(true);
    UpdatedInvoicePayment(formData, editId)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const result = rows.map((elem) => {
            if (elem.id === res.data.data.id) {
              return {
                ...elem,
                paid_at: res.data.data.paid_at,
                amount: res.data.data.amount,
                currency_id: res.data.data.currency_id,
                remarks: res.data.data.remarks,
              };
            } else {
              return elem;
            }
          });
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const getAlldata = () => {
    setisLoader(true);
    GetPaymentByInvoiceId(invoiceID, rowsPerPage, page)
      .then((res) => {
        setisLoader(false);
        const result = res.data.data.map((ele) => ({
          amount: ele.amount,
          created_on: ele.created_on.split(" ")[0],
          currency: ele.currency,
          currency_id: ele.currency_id,
          id: ele.id,
          invoice_id: ele.invoice_id,
          paid_at: ele.paid_at.split(" ")[0],
          paid_date: moment(ele.paid_at.split(" ")[0]).format('DD MMM YYYY'),
          remarks: ele.remarks,
          serial_number: ele.serial_number
        }))
        setRows(result);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getAlldata();
  }, [page, rowsPerPage]);

  const selectModal = () => {
    return (
      <InvoicePaymentForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeData={
          clickedBtn === "add" ? createInvoicePayment : updatePayment
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
        invoiceID={invoiceID}
      />
    );
  };
  const handleChangeDelete = (id) => {
    setDeleteId(id);
    setAlertBox(!alertBox);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    DeleteInvoicePayment(deleteId)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentType(result);
    setEditId(result.id);
    handleSelectBtn("edit");
  };

  return (
    <div className="container">
      {isLoader ? <LoaderComponent /> : null}
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <div
        className="row mt-3"
        style={{
          alignItems: "center",
        }}
      >
        <div className="d-md-flex d-column justify-content-between  mt-3">
          {alertBox && (
            <AlertMessage
              title={alertTitle}
              message={alertMessage}
              onChangeCancel={handleChangeCancel}
              onChangeSucess={handleChangeSuccess}
            />
          )}
          <div className="col">
            <h3> {searchTitle} </h3>
            <BreadCrumbs
              pathList={paths}
              obj={{
                proId: proId,
                budget_id: budget_id,
                rfqId: rfqId,
              }}
            />
          </div>
          {/* <Button
            buttonName={"Payment Summery"}
            onClick={() => {
              history.push(`/paymentRequest/${rfqId}`);
            }}
            buttonStyle={{
              backgroundColor: colors.darkTransparent,
              marginRight: "-400px",
            }}
          /> */}
          {isAdd && (
            <span className="addButton pt-1">
              <IconButton
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              >
                <AddIcon />
              </IconButton>
            </span>
          )}
        </div>
        <div className="col-12">
          <div className="budgetTable">
            {rows.length > 0 ? (
              <SearchPaymentTable
                isUpdate={isUpdate}
                isStatus={isStatus}
                placeholder={"name"}
                data={rows}
                mapableData={mapableData}
                searchProp={["paid_at"]}
                handleChangeDelete={handleChangeDelete}
                handleChangeEdit={handleChangeEdit}
              />
            ) : (
              <>
                {isLoader ? (
                  <LoaderComponent />
                ) : (
                  <NodataFound
                    onClick={() => {
                      openAddModal();
                      handleSelectBtn("add");
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
