import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  ChangePasswordPage,
  ComingSoonPage,
  ConfirmForgotPasswordPage,
  ForgotPasswordPage,
  LoginPage,
} from "../containers";

export default function Routes(props) {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
      <Switch>
        {/* <Route exact path="/login">
          <LoginPage />
        </Route> */}
        <Route exact path="/changepassword">
          <ChangePasswordPage />{" "}
        </Route>
        <Route exact path="/forgotpassword">
          {" "}
          <ForgotPasswordPage />{" "}
        </Route>
        <Route exact path="/confirmforgotpassword">
          {" "}
          <ConfirmForgotPasswordPage />{" "}
        </Route>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route path="/coming_soon">
          <ComingSoonPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
