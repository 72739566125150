import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  PDFViewer
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { logoRect } from "../../../assets";

const PaymentSummeryDownload = (props) => {
  const { projectDetails, allData } = props;

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      height: "50%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-750px",
      fontSize: "8px",
    },
  });
  let totalValue = 0; // Initialize the total sum variable

  return (
    <PDFViewer style={styles.viewer}>
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape" wrap>
        <View fixed>
          <View style={styles.section} fixed>
            <Image style={styles.image} src={logoRect} />
            <Text style={{ fontSize: "12px", display: "flex" }}>
              Payment Request Summary - {projectDetails?.budget_slno}
            </Text>
          </View>
        </View>
        <View style={{ marginLeft: "25px", marginRight: "25px" }}>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "13px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "33%",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Project Name
                </Text>
                <Text
                  style={{
                    width: "67%",
                    fontSize: "11px",
                    padding: "5px",
                  }}
                >
                  {projectDetails?.project_name}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "33%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Budget Number
                </Text>
                <Text
                  style={{
                    width: "67%",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                >
                  {projectDetails?.budget_slno}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "33%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Issuing Date
                </Text>
                <Text
                  style={{
                    width: "67%",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                >
                  {moment(projectDetails?.issue_date).format("DD-MM-YYYY")}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                backgroundColor: "#f2f2f2",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                }}
              >
                <View
                  style={{
                    width: "20%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    Vendor
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    PO #
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    PO Value
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    INV #
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    INV Value
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    Currency
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    Rate
                  </Text>
                </View>
                <View
                  style={{
                    width: "20%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    Value
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            marginLeft: "25px",
            marginRight: "25px",
            marginTop: "-10px",
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              {allData.length > 0
                ? allData.map((ele) => {
                    totalValue +=
                      parseFloat(ele.values ? ele.values : 0) *
                      parseFloat(ele.rate ? ele.rate : 1);
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: "8px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "20%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            {ele?.vendor}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            {ele?.poNumber}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            AED{" "}
                            {ele?.poValue
                              ? parseFloat(ele?.poValue).toFixed(2)
                              : "0.00"}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            {ele?.InvoiceNumber}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            AED{" "}
                            {ele?.values
                              ? parseFloat(ele?.values).toFixed(2)
                              : "0.00"}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            {ele?.currencys?.name}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            {ele?.rate
                              ? parseFloat(ele?.rate).toFixed(2)
                              : ele?.rate}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "20%",
                            height: "100%",
                            borderRight: "1px solid black",
                            padding: "3px",
                          }}
                        >
                          <Text
                            style={{
                              margin: "auto",
                              padding: "5px",
                            }}
                          >
                            AED{" "}
                            {(
                              parseFloat(ele.values ? ele.values : 0) *
                              parseFloat(ele.rate ? ele.rate : 1)
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    );
                  })
                : null}
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                backgroundColor: "#f2f2f2",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "8px",
                  borderBottom: "1px solid black",
                }}
              >
                <View
                  style={{
                    width: "80%",
                    height: "100%",
                    borderRight: "1px solid black",
                    padding: "3px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                      fontWeight: "bold", // Make the total value bold
                    }}
                  >
                    Total
                  </Text>
                </View>
                <View
                  style={{
                    width: "20%",
                    height: "100%",
                    padding: "3px",
                  }}
                >
                  <Text
                    style={{
                      margin: "auto",
                      padding: "5px",
                      fontWeight: "bold", // Make the total value bold
                    }}
                  >
                    AED {totalValue.toFixed(2)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          break
          style={{
            flexDirection: "column",
            flexWrap: "wrap",
            border: "1px solid black",
            marginVertical: "20px",
            borderRadius: "5px",
            marginRight: "25px",
            marginLeft: "25px",
            position: "relative",
          }}
        >
          <Text
            style={{
              fontSize: "10px", // You can adjust the font size as needed
              textAlign: "left",
              position: "absolute", // Use absolute positioning
              top: "-15px", // Adjust top position as needed
              left: "0px", // Adjust left position as needed
            }}
          >
            Checked By Furnish{" "}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              fontSize: "8px",
              borderBottom: "1px solid black",
            }}
          >
            <Text
              style={{
                width: "30%",
                fontSize: "8px",
                borderRight: "1px solid black",
                padding: "5px",
                textAlign: "center",
              }}
            >
              Name
            </Text>
            <Text
              style={{
                width: "40%",
                fontSize: "8px",
                padding: "5px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Signature
            </Text>
            <Text
              style={{
                width: "30%",
                fontSize: "8px",
                padding: "5px",
                textAlign: "center",
              }}
            >
              Date
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              fontSize: "8px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                width: "30%",
                height: "30px",
                fontSize: "8px",
                borderRight: "1px solid black",
                padding: "5px",
              }}
            ></Text>
            <Text
              style={{
                width: "40%",
                height: "30px",
                fontSize: "8px",
                padding: "5px",
                borderRight: "1px solid black",
              }}
            ></Text>
            <Text
              style={{
                width: "30%",
                height: "30px",
                fontSize: "8px",
                padding: "5px",
              }}
            ></Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "column",
            flexWrap: "wrap",
            border: "1px solid black",
            marginVertical: "20px",
            borderRadius: "5px",
            marginRight: "25px",
            marginLeft: "25px",
            position: "relative",
          }}
        >
          <Text
            style={{
              fontSize: "10px", // You can adjust the font size as needed
              textAlign: "left",
              position: "absolute", // Use absolute positioning
              top: "-15px", // Adjust top position as needed
              left: "0px", // Adjust left position as needed
            }}
          >
            Authorized By Furnish{" "}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              fontSize: "8px",
              borderBottom: "1px solid black",
            }}
          >
            <Text
              style={{
                width: "30%",
                fontSize: "8px",
                borderRight: "1px solid black",
                padding: "5px",
                textAlign: "center",
              }}
            >
              Name
            </Text>
            <Text
              style={{
                width: "40%",
                fontSize: "8px",
                padding: "5px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Signature
            </Text>
            <Text
              style={{
                width: "30%",
                fontSize: "8px",
                padding: "5px",
                textAlign: "center",
              }}
            >
              Date
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              fontSize: "8px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                width: "30%",
                height: "30px",
                fontSize: "8px",
                borderRight: "1px solid black",
                padding: "5px",
              }}
            ></Text>
            <Text
              style={{
                width: "40%",
                height: "30px",
                fontSize: "8px",
                padding: "5px",
                borderRight: "1px solid black",
              }}
            ></Text>
            <Text
              style={{
                width: "30%",
                height: "30px",
                fontSize: "8px",
                padding: "5px",
              }}
            ></Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "column",
            flexWrap: "wrap",
            border: "1px solid black",
            marginVertical: "20px",
            borderRadius: "5px",
            marginRight: "25px",
            marginLeft: "25px",
            position: "relative",
          }}
        >
          <Text
            style={{
              fontSize: "10px", // You can adjust the font size as needed
              textAlign: "left",
              position: "absolute", // Use absolute positioning
              top: "-15px", // Adjust top position as needed
              left: "0px", // Adjust left position as needed
            }}
          >
            Received By Client{" "}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              fontSize: "8px",
              borderBottom: "1px solid black",
            }}
          >
            <Text
              style={{
                width: "30%",
                fontSize: "8px",
                borderRight: "1px solid black",
                padding: "5px",
                textAlign: "center",
              }}
            >
              Name
            </Text>
            <Text
              style={{
                width: "40%",
                fontSize: "8px",
                padding: "5px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Signature
            </Text>
            <Text
              style={{
                width: "30%",
                fontSize: "8px",
                padding: "5px",
                textAlign: "center",
              }}
            >
              Date
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              fontSize: "8px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                width: "30%",
                height: "30px",
                fontSize: "8px",
                borderRight: "1px solid black",
                padding: "5px",
              }}
            ></Text>
            <Text
              style={{
                width: "40%",
                height: "30px",
                fontSize: "8px",
                padding: "5px",
                borderRight: "1px solid black",
              }}
            ></Text>
            <Text
              style={{
                width: "30%",
                height: "30px",
                fontSize: "8px",
                padding: "5px",
              }}
            ></Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View
            style={{ borderTop: "1px solid black", marginRight: "50px" }}
          ></View>
          <View>
            <Text style={{ fontSize: "8px" }}>PO BOX 282247, Dubai, UAE</Text>
            <Text style={{ fontSize: "8px" }}>t +971 4 3307725</Text>
            <Text style={{ fontSize: "8px" }}>f +971 4 3307735</Text>
            <Text style={{ fontSize: "8px" }}>
              invitation@furnish-marketplace.com
            </Text>
            <Text style={{ fontSize: "8px" }}>furnish-marketplace.com</Text>
          </View>
          <View>
            <Text
              style={styles.pageNo}
              render={({ pageNumber, totalPages }) =>
                `Page # ${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
    </PDFViewer>
  );
};

export default PaymentSummeryDownload;
