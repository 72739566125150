/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ModeOfTransportationForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["MOT.CreateForm"],
    editTitle = Strings["MOT.EditForm"],
    MOTLabel = Strings["MOT.MOTLabel"],
    MOTPlaceholder = Strings["MOT.MOTPlaceHolder"],
    MOTCustomError = Strings["MOT.MOTError"],
    MOTDescriptionLabel = Strings["MOT.MOTDescriptionLabel"],
    MOTDescriptionPlaceholder = Strings["MOT.MOTDescriptionPlaceHolder"],
    MOTDescriptionCustomError = Strings["MOT.MOTDescriptionError"],
    MOTSaveBtn = Strings["MOT.SaveBtn"],
    MOTUpdateBtn = Strings["MOT.UpdateBtn"],
    MOTCancelBtn = Strings["MOT.CancelBtn"];

  const [motName, setMotName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [motDescription, setMotDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleMOTName = (value) => {
    setMessage(null);
    setMotName(value);
  };

  const handleChangeMOTDescription = (value) => {
    setMotDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: motName.value,
      description: motDescription.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged = motName.value === initialValue.name;
  // motDescription.value === initialValue.description;
  let disable =
    motName.error ||
    // motDescription.error ||
    motName.value === "" ||
    // motDescription.value === "" ||
    motName.success === false ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    motName.value === initialValue.name &&
    motDescription.value === initialValue.description;
  let Editdisable =
    motName.error ||
    motDescription.error ||
    motName.value === "" ||
    motDescription.value === "" ||
    (motName.success === false && motDescription.success === false) ||
    isValueNOTChangededit;
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="alpha"
        label={MOTLabel}
        placeholder={MOTPlaceholder}
        Value={motName.value}
        onChangeText={handleMOTName}
        Validate={true}
        CustomErrorLine={MOTCustomError}
        starProps={true}
        maxLength={20}
        editable
      />

      <FormTextInput
        type="text"
        label={MOTDescriptionLabel}
        placeholder={MOTDescriptionPlaceholder}
        Value={motDescription.value}
        onChangeText={handleChangeMOTDescription}
        Validate={false}
        CustomErrorLine={MOTDescriptionCustomError}
        multiline={true}
        maxLength={100}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? MOTSaveBtn : MOTUpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={MOTCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default ModeOfTransportationForm;
