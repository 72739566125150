import React from "react";
import swal from "sweetalert";
import { BudgetFileUpload, ImageCreation } from "../../../axios";
import { Button } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const UploadFile = (props) => {
  const {
    errorMessage,
    initialValue = {},
    actionType,
    handleModalClose,
    budgetId,
  } = props;

  const cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.selectedFile ? initialValue.selectedFile : "",
    error: false,
    success: false,
  });

  const [searchdropdown, setSearchdropdown] = React.useState();

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChangeFile = async (data) => {
    if (data.target.files.length > 0) {
      const filename = data.target.files[0].name;
      var fileExtension = filename.split(".").pop();
      const files = data.target.files[0];
      const base64 = await convertBase64(files);
      var indexOfbase64 = base64.indexOf("base64");
      const newbase64 = base64.slice(indexOfbase64 + 7, base64.length);
      const uploadData = {
        file_data: newbase64,
        file_extension: fileExtension,
      };
      await ImageCreation(uploadData)
        .then((res) => {
          setSelectedFile([res.data.data.file_path, filename]);
        })
        .catch(() => {
        });
    }
  };


  const handleSaveData = () => {
    const formData = {
      budget_id: Number(budgetId),
      name: searchdropdown,
      file: [selectedFile[0]],
      filename: [selectedFile[1]],
    };


    BudgetFileUpload(formData)
      .then((res) => {
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch(() => {
      });
  };

  // let isValueNOTChanged = selectedFile.value === initialValue.selectedFile;

  // let disable =
  //   selectedFile.error ||
  //   selectedFile.value === "" ||
  //   selectedFile.success === false ||
  //   isValueNOTChanged;

  return (
    <div className="modalFormContainer">
      <h2 className="mb-3">{"Upload File"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-sm-4">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setSearchdropdown(e.target.value)}
            value={searchdropdown}
          >
            <option selected>Select </option>
            <option value="Specification">Specification</option>
            <option value="Recommendation- Signed by Client">
              Recommendation- Signed by Client
            </option>
            <option value="PO- Signed by Client">PO- Signed by Client</option>
            <option value="PO- Signed by Vendor">PO- Signed by Vendor</option>
            <option value="POR- Signed by Client">POR- Signed by Client</option>
            <option value="POR- Signed by Vendor">POR- Signed by Vendor</option>
            <option value="Official Correspondence">
              Official Correspondence
            </option>
            <option value="Approved Budget">Approved Budget</option>
          </select>
        </div>
        <div className="col-sm-8">
          <input
            type="file"
            value={selectedFile.value}
            onChange={handleChangeFile}
            accept=".csv, .xlsx,.pdf"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey }}
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          // disable={actionType === "add" ? disable : null}
          onClick={handleSaveData}
        />
      </div>
    </div>
  );
};

export default UploadFile;
