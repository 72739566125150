/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const LocationForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["Location.CreateForm"],
    editTitle = Strings["Location.EditForm"],
    LocationLabel = Strings["Location.LocationModeLabel"],
    LocationPlaceholder = Strings["Location.LocationModePlaceHolder"],
    LocationCustomError = Strings["Location.LocationModeError"],
    LocationDescriptionLabel = Strings["Location.LocationModeDescriptionLabel"],
    LocationDescriptionPlaceholder =
      Strings["Location.LocationModeDescriptionPlaceHolder"],
    LocationDescriptionCustomError =
      Strings["Location.LocationModeDescriptionError"],
    LocationTagLabel = Strings["Location.LocationModeTagLabel"],
    LocationTagPlaceholder = Strings["Location.LocationModeTagPlaceHolder"],
    LocationTagCustomError = Strings["Location.LocationModeTagError"],
    LocationSaveBtn = Strings["Location.SaveBtn"],
    LocationUpdateBtn = Strings["Location.UpdateBtn"],
    LocationCancelBtn = Strings["Location.CancelBtn"];

  const [locationName, setLocationName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [loactionDescription, setLocationDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [locatonTag, setLocationTag] = React.useState({
    value: initialValue.tag ? initialValue.tag : "",
    error: false,
    success: false,
  });

  const handleChangeLocationName = (value) => {
    setMessage(null);
    setLocationName(value);
  };

  const handleChangeLocationDescription = (value) => {
    setLocationDescription(value);
  };

  const handleChangeLocationTag = (value) => {
    setMessage(null);
    setLocationTag(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: locationName.value,
      description: loactionDescription.value,
      tag: locatonTag.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged =
    locationName.value === initialValue.name &&
    // loactionDescription.value === initialValue.description &&
    locatonTag.value === initialValue.tag;
  let disable =
    locationName.error ||
    // loactionDescription.error ||
    locatonTag.error ||
    locationName.value === "" ||
    // loactionDescription.value === "" ||
    locatonTag.value === "" ||
    (locationName.success === false &&
      // loactionDescription.success === false &&
      locatonTag.success === false) ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    locationName.value === initialValue.name &&
    // loactionDescription.value === initialValue.description &&
    locatonTag.value === initialValue.tag;
  let Editdisable =
    locationName.error ||
    // loactionDescription.error ||
    locatonTag.error ||
    locationName.value === "" ||
    // loactionDescription.value === "" ||
    locatonTag.value === "" ||
    (locationName.success === false &&
      // loactionDescription.success === false &&
      locatonTag.success === false) ||
    isValueNOTChangededit;
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        label={LocationLabel}
        placeholder={LocationPlaceholder}
        Value={locationName.value}
        onChangeText={handleChangeLocationName}
        Validate={true}
        CustomErrorLine={LocationCustomError}
        starProps={true}
        maxLength={50}
        editable
      />

      <FormTextInput
        type="alpha"
        label={LocationDescriptionLabel}
        placeholder={LocationDescriptionPlaceholder}
        Value={loactionDescription.value}
        onChangeText={handleChangeLocationDescription}
        Validate={false}
        CustomErrorLine={LocationDescriptionCustomError}
        multiline={true}
        starProps={false}
        maxLength={100}
        editable
      />

      <FormTextInput
        type="alpha"
        label={LocationTagLabel}
        placeholder={LocationTagPlaceholder}
        Value={locatonTag.value}
        onChangeText={handleChangeLocationTag}
        Validate={true}
        CustomErrorLine={LocationTagCustomError}
        starProps={true}
        maxLength={50}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? LocationSaveBtn : LocationUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={LocationCancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default LocationForm;
