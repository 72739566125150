import jsPDF from "jspdf";
import React from "react";

const QuotationDownload = (props) => {
  const datass = props.data;
  const vendor = datass.vendor.map((e) => {
    return e.vendor.name;
  });
  const vendorAdd = datass.vendor.map((e) => {
    return e.vendor.address;
  });
  const [isShow, setIsShow] = React.useState(true);

  const data = {
    id: datass.id,
    VendorName: vendor,
    ProjectName: "BAY LA SUN - KSA",
    Rfq: datass.rfqName,
    Address: vendorAdd,
    BudgetNo: datass.budgetNo,
    IssuingDt: datass.IssuingDt,
    itemDetails: datass.budget_item,
    TENDERNOTES: datass.tendorNotes,
    IMPORTANTNOTES: datass.importantNotes,
    DEADLINE: datass.deadline,
    INCOTERM: datass.incoterm.name,
    Country: datass.country,
  };

  const handleDownload = () => {
    var doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
    });
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        pdf.save("rfq.pdf");
      },
    });
    setIsShow(true);
  };

  React.useEffect(() => {
    handleDownload();
    setIsShow(false);
  }, [data]);

  return (
    <>
      {isShow === true ? (
        <table
          style={{
            width: "400px",
            marginLeft: "10px",
            color: "black",
          }}
          id="content"
        >
          <div
            style={{
              width: "400px",
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid black",
            }}
          >
            <div>
              <h4>Furnish Logo</h4>
            </div>
            <div>
              <h4>Request For Quotation</h4>
            </div>
          </div>
          <table
            style={{
              fontSize: "7px",
              marginTop: "10px",
              border: "1px solid",
            }}
          >
            <tr style={{ padding: "8px" }}>
              <td
                style={{
                  borderRight: "0.5px solid black",
                  padding: "8px",
                  backgroundColor: "greenyellow",
                }}
              >
                Vendor Name
              </td>
              <td style={{ borderRight: "1px solid", padding: "8px" }}>
                {data.VendorName}
              </td>
              <td
                style={{
                  borderRight: "1px solid",
                  padding: "8px",
                  backgroundColor: "greenyellow",
                }}
              >
                Project Name
              </td>
              <td style={{ borderRight: "1px solid", padding: "8px" }}>
                {data.ProjectName}
              </td>
              <td
                style={{
                  borderRight: "1px solid",
                  padding: "8px",
                  backgroundColor: "greenyellow",
                }}
              >
                Rfq #
              </td>
              <td>{data.Rfq}</td>
            </tr>
            <tr style={{ borderTop: "1px solid black", padding: "8px" }}>
              <td
                style={{
                  borderRight: "1px solid black",
                  padding: "8px",
                  backgroundColor: "greenyellow",
                }}
              >
                Vendor Address
              </td>
              <td style={{ borderRight: "1px solid", padding: "8px" }}>
                {data.Address}
              </td>
              <td
                style={{
                  borderRight: "1px solid",
                  padding: "8px",
                  backgroundColor: "greenyellow",
                }}
              >
                Budget Number
              </td>
              <td style={{ borderRight: "1px solid", padding: "8px" }}>
                {data.BudgetNo}
              </td>
              <td
                style={{
                  borderRight: "1px solid",
                  padding: "8px",
                  backgroundColor: "greenyellow",
                }}
              >
                Issuing Date
              </td>
              <td style={{ padding: "8px" }}>{data.IssuingDt}</td>
            </tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              marginTop: "10px",
              border: "1px solid",
            }}
          >
            <tr style={{ borderBottom: "1px solid" }}>
              <td align="center" style={{ borderRight: "1px solid" }}>
                CTRL No.
              </td>
              <td align="center" style={{ borderRight: "1px solid" }}>
                Reference
              </td>
              <td align="center" style={{ borderRight: "1px solid" }}>
                Description
              </td>
              <td align="center" style={{ borderRight: "1px solid" }}>
                UOM
              </td>
              <td align="center">Quantity</td>
            </tr>
            {data.itemDetails.map((e) => {
              return (
                <tr style={{ borderBottom: "1px solid" }}>
                  <td style={{ borderRight: "1px solid" }} align="center">
                    {e.serial_number}
                  </td>
                  <td style={{ borderRight: "1px solid" }} align="center">
                    {e.Reference}
                  </td>
                  <td style={{ borderRight: "1px solid" }} align="center">
                    {e.description}
                  </td>
                  <td style={{ borderRight: "1px solid" }} align="center">
                    {e.UOM}
                  </td>
                  <td align="center">{e.Quantity}</td>
                </tr>
              );
            })}
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              border: "1px solid black",
              marginTop: "10px",
            }}
          >
            <tr
              style={{
                backgroundColor: "#ada0a0",
                borderBottom: "1px solid black",
              }}
            >
              TENDER NOTE
            </tr>
            <tr>{data.TENDERNOTES}</tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              border: "1px solid black",
              marginTop: "10px",
            }}
          >
            <tr
              style={{
                backgroundColor: "#ada0a0",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              IMPORTANT NOTES
            </tr>
            <tr>{data.IMPORTANTNOTES}</tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              border: "1px solid black",
              marginTop: "10px",
            }}
          >
            <tr style={{ width: "100%" }}>
              <td
                style={{
                  backgroundColor: "#ada0a0",
                  width: "50%",
                  borderRight: "1px solid black",
                }}
              >
                DEADLINE
              </td>
              <td style={{ width: "100%", paddingLeft: "10px" }}>
                {data.DEADLINE}
              </td>
            </tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              border: "1px solid black",
              marginTop: "10px",
            }}
          >
            <tr style={{ width: "100%" }}>
              <td
                style={{
                  backgroundColor: "#ada0a0",
                  width: "50%",
                  borderRight: "1px solid black",
                }}
              >
                INCOTERM
              </td>
              <td style={{ width: "100%", paddingLeft: "10px" }}>
                {data.INCOTERM}
              </td>
            </tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              border: "1px solid black",
              marginTop: "10px",
            }}
          >
            <tr style={{ width: "100%" }}>
              <td
                style={{
                  backgroundColor: "#ada0a0",
                  width: "50%",
                  borderRight: "1px solid black",
                }}
              >
                Country of Origin
              </td>
              <td style={{ width: "100%", paddingLeft: "10px" }}>
                {data.Country}
              </td>
            </tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              border: "1px solid black",
              marginTop: "10px",
            }}
          >
            <tr
              style={{
                backgroundColor: "#ada0a0",
                borderBottom: "1px solid black",
              }}
            >
              PLEASE NOTE :{" "}
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Please review the specs carefully and quote accordingly.
            </tr>
            <tr>
              - All quotes must be submitted on a company letterhead, signed and
              stamped.
            </tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              border: "1px solid black",
              marginTop: "10px",
            }}
          >
            <tr
              style={{
                backgroundColor: "#ada0a0",
                borderBottom: "1px solid black",
                paddingLeft: "5px",
              }}
            >
              PLEASE BE SURE TO INCLUDE THE FOLLOWING ON YOUR QUOTATION:
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>- Project name</tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Item description (including sizes and finishes) as per the BOQ &
              spec sheet
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>- Project name</tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Item description (including sizes and finishes) as per the BOQ &
              spec sheet
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Total cost per unit (including all inbound bank charges, packing
              & handling charges)
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Currency (UAE Dirhams or US Dollars)
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Currency (UAE Dirhams or US Dollars)
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Lead time (from receipt of PO)
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Validity of Quotation
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>- Payment terms</tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Contact details
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>- Warranty</tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              - Country of Origin
            </tr>
            <tr>- Technical Specifications</tr>
          </table>
          <table
            style={{
              width: "400px",
              fontSize: "7px",
              marginTop: "10px",
            }}
          >
            <tr>Generated by:</tr>
            <tr>Generated on:</tr>
            <tr>Signature:</tr>
          </table>
        </table>
      ) : (
        false
      )}
    </>
  );
};

export default QuotationDownload;