/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const VendorCompanyTypeForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createCompanyTypeTitle =
      Strings["VendorCompanyType.CreateCompanyTypeForm"],
    editCompanyTypeTitle = Strings["VendorCompanyType.EditCompanyTypeForm"],
    companyTypeLabel = Strings["VendorCompanyType.CompanyTypeLabel"],
    companyTypePlaceholder =
      Strings["VendorCompanyType.CompanyTypePlaceHolder"],
    companyTypeCustomError = Strings["VendorCompanyType.CompanyTypeError"],
    companyDescriptionLabel =
      Strings["VendorCompanyType.CompanyDescriptionLabel"],
    companyDescriptionPlaceholder =
      Strings["VendorCompanyType.CompanyDescriptionPlaceHolder"],
    companyDescriptionCustomError =
      Strings["VendorCompanyType.CompanyDescriptionError"],
    companyCodeLabel = Strings["VendorCompanyType.CompanyCodeLabel"],
    companyCodePlaceholder =
      Strings["VendorCompanyType.CompanyCodePlaceHolder"],
    companyCodeCustomError = Strings["VendorCompanyType.CompanyCodeError"],
    companySaveBtn = Strings["VendorCompanyType.SaveBtn"],
    companyUpdateBtn = Strings["VendorCompanyType.UpdateBtn"],
    companyCancelBtn = Strings["VendorCompanyType.CancelBtn"];

  const [vendorCompanyType, setVendorCompanyType] = React.useState({
    value: initialValue.company_type ? initialValue.company_type : "",
    error: false,
    sucess: false,
  });

  const [vendorCompanyDescription, setVendorCompanyDescription] =
    React.useState({
      value: initialValue.description ? initialValue.description : "",
      error: false,
      sucess: false,
    });

  const [vendorCompanyCode, setVendorCompanyCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    sucess: false,
  });

  const handleChangeVendorType = (value) => {
    setMessage(null);
    setVendorCompanyType(value);
  };

  const handleChangeVendorDescription = (value) => {
    setVendorCompanyDescription(value);
  };

  const handleChangeVendorCode = (value) => {
    setMessage(null);
    setVendorCompanyCode(value);
  };

  const handleSaveData = () => {
    const formData = {
      company_type: vendorCompanyType.value,
      description: vendorCompanyDescription.value,
      code: vendorCompanyCode.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged =
    vendorCompanyType.value === initialValue.company_type &&
    vendorCompanyDescription.value === initialValue.description &&
    vendorCompanyCode.value === initialValue.code;

  let disable =
    vendorCompanyType.error ||
    vendorCompanyCode.error ||
    vendorCompanyType.value === "" ||
    vendorCompanyCode.value === "" ||
    (vendorCompanyType.sucess === false &&
      vendorCompanyCode.sucess === false) ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    vendorCompanyType.value === initialValue.company_type &&
    vendorCompanyDescription.value === initialValue.description &&
    vendorCompanyCode.value === initialValue.code;

  let Editdisable =
    vendorCompanyType.error ||
    vendorCompanyDescription.error ||
    vendorCompanyCode.error ||
    vendorCompanyType.value === "" ||
    vendorCompanyDescription.value === "" ||
    vendorCompanyCode.value === "" ||
    (vendorCompanyType.sucess === false &&
      vendorCompanyDescription.sucess === false &&
      vendorCompanyCode.sucess === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add" ? createCompanyTypeTitle : editCompanyTypeTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}

      <FormTextInput
        type="text"
        starProps={true}
        maxLength={30}
        label={companyTypeLabel}
        placeholder={companyTypePlaceholder}
        Value={vendorCompanyType.value}
        onChangeText={handleChangeVendorType}
        Validate={true}
        CustomErrorLine={companyTypeCustomError}
        editable
      />

      <FormTextInput
        type="textarea"
        label={companyDescriptionLabel}
        placeholder={companyDescriptionPlaceholder}
        Value={vendorCompanyDescription.value}
        onChangeText={handleChangeVendorDescription}
        Validate={false}
        CustomErrorLine={companyDescriptionCustomError}
        multiline={true}
        editable
        maxLength={50}
      />

      <FormTextInput
        type="alpha"
        starProps={true}
        maxLength={20}
        label={companyCodeLabel}
        placeholder={companyCodePlaceholder}
        Value={vendorCompanyCode.value}
        onChangeText={handleChangeVendorCode}
        Validate={true}
        CustomErrorLine={companyCodeCustomError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? companySaveBtn : companyUpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : Editdisable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={companyCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default VendorCompanyTypeForm;
