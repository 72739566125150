import AddIcon from "@mui/icons-material/Add";
import React, { useEffect } from "react";
import { PublicAreaForm, RoomTypeForm } from "..";
import {
  AllActiveStatusItemGroup,
  AllActiveStatusPublicArea,
  AllActiveStatusRoomType,
  CreatePublicArea,
  CreateRoomType,
} from "../../../axios";
import {
  Button,
  FormDropDown,
  FormTextInput,
  Modal,
} from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ItemGroupForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const options = [
    {
      key: 1,
      value: "Others",
      label: "Others",
    },
    {
      key: 2,
      value: "Room Type",
      label: "Room Type",
    },
    {
      key: 3,
      value: "Public Area",
      label: "Public Area",
    },
  ];

  const CreateTitle = Strings["ItemGroup.CreateTitle"],
    EditTitle = Strings["ItemGroup.EditTitle"],
    selectLabel = Strings["ItemGroup.selectLabel"],
    selectCustomError = Strings["ItemGroup.selectCustomError"],
    ReferanceLabel = Strings["ItemGroup.ReferanceLabel"],
    ReferancePlaceholder = Strings["ItemGroup.ReferancePlaceholder"],
    ReferanceCustomError = Strings["ItemGroup.ReferanceCustomError"],
    GroupNameLabel = Strings["ItemGroup.GroupNameLabel"],
    GroupNamePlaceholder = Strings["ItemGroup.GroupNamePlaceholder"],
    GroupNameCustomError = Strings["ItemGroup.GroupNameCustomError"],
    parentGroupSelectLabel = Strings["ItemGroup.parentGroupSelectLabel"],
    parentGroupselectCustomError =
      Strings["ItemGroup.parentGroupselectCustomError"],
    UnitLabel = Strings["ItemGroup.UnitLabel"],
    UnitPlaceholder = Strings["ItemGroup.UnitPlaceholder"],
    UnitCustomError = Strings["ItemGroup.UnitCustomError"],
    OpRefLabel = Strings["ItemGroup.OpRefLabel"],
    OpRefPlaceholder = Strings["ItemGroup.OpRefPlaceholder"],
    OpRefCustomError = Strings["ItemGroup.OpRefCustomError"],
    SaveBtn = Strings["ItemGroup.SaveBtn"],
    UpdateBtn = Strings["ItemGroup.UpdateBtn"],
    CancelBtn = Strings["ItemGroup.CancelBtn"],
    RoomTypeSelectLabel = Strings["ItemGroup.RoomTypeSelectLabel"],
    RoomTypeselectCustomError = Strings["ItemGroup.RoomTypeselectCustomError"];

  const [groupType, setGroupType] = React.useState({
    // value: initialValue.group_type_name ? initialValue.group_type_name : "",
    // error: false,
    // success: false,
  });
  const [referance, setReferance] = React.useState({
    // value: initialValue.reference_number
    //   ? initialValue.reference_number
    //   : 1000000,
    // error: false,
    // success: false,
  });
  const [groupName, setGroupName] = React.useState({
    value: initialValue.group_name ? initialValue.group_name : "",
    error: false,
    success: false,
  });
  const [unit, setUnit] = React.useState({
    // value: initialValue.unit ? initialValue.unit : "",
    // error: false,
    // success: false,
  });
  const [operatorRef, setOperatorRef] = React.useState({
    // value: initialValue.operator_reference
    //   ? initialValue.operator_reference
    //   : "",
    // error: false,
    // success: false,
  });
  const [roomType, setRoomType] = React.useState({
    // value: initialValue.name ? initialValue.name : "",
    // error: false,
    // success: false,
  });

  const [parentGroup, setparentGroup] = React.useState({
    // value: initialValue.parent_group ? initialValue.parent_group : null,
    // error: false,
    // success: false,
  });

  const [modalOpen, setModalOpen] = React.useState(false);

  const [publicAreaDropDown, SetpublicAreaDropDown] = React.useState([]);
  const [roomTypeDropDown, SetroomTypeDropDown] = React.useState([]);
  const [itemGroupDropDown, SetitemGroupDropDown] = React.useState([]);

  const handleSelectGroupType = (value) => {
    setGroupType(value);
  };

  const handleChangeReferance = (value) => {
    setReferance(value);
  };

  const handleChangeGroupName = (value) => {
    setMessage(null);
    setGroupName(value);
  };

  const handleSelectParentGroup = (value) => {
    setparentGroup(value);
  };

  const handleChangeUnit = (value) => {
    setUnit(value);
  };

  const handleChangeOpratorRef = (value) => {
    setOperatorRef(value);
  };

  const handleSelectRoomType = (value) => {
    setRoomType(value);
  };

  const handleSelectModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const AllActiveRoomType = () => {
    AllActiveStatusRoomType()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        SetroomTypeDropDown(result);
      })
      .catch(() => {});
  };
  const AllActivePublicArea = () => {
    AllActiveStatusPublicArea()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        SetpublicAreaDropDown(result);
      })
      .catch(() => {});
  };
  const AllActiveItemGroup = () => {
    AllActiveStatusItemGroup()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.id,
        }));
        SetitemGroupDropDown(result);
      })
      .catch(() => {});
  };
  useEffect(() => {
    // AllActiveRoomType();
    // AllActivePublicArea();
    // AllActiveItemGroup();
  }, []);

  const selectModal = () => {
    if (groupType.value === "Room Type") {
      return (
        <RoomTypeForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          actionType="add"
          handleChangeFormDatas={(val) => {
            CreateRoomType(val)
              .then((res) => {
                handleModalClose();
              })
              .catch((e) => {
                setMessage(e.response.data.message);
              });
          }}
        />
      );
    } else {
      return (
        <PublicAreaForm
          setMessage={setMessage}
          errorMessage={errorMessage}
          actionType="add"
          handleChangeFormDataes={(val) => {
            CreatePublicArea(val)
              .then((res) => {
                handleModalClose();
              })
              .catch((e) => {
                setMessage(e.response.data.message);
              });
          }}
        />
      );
    }
  };

  let isValueChangedOtherType =
    groupName.value === initialValue.group_name;
    // parentGroup.value === initialValue.parent_group;

  let isValueChangedNotOtherType =
    groupName.value === initialValue.group_name;
    // unit.value === initialValue.unit &&
    // operatorRef.value === initialValue.operator_reference &&
    // roomType.value === initialValue.group_type_id &&
    // parentGroup.value === initialValue.parent_group;

  let disableOtherType =
    groupName.error ||
    // parentGroup.error ||
    // groupName.value === "" ||
    // parentGroup.value === "" ||
    groupName.success === false ||
    isValueChangedOtherType;

  let disableNotOtherType =
    groupName.error ||
    // unit.error ||
    // operatorRef.error ||
    // parentGroup.error ||
    // roomType.error ||
    groupName.value === "" ||
    // unit.value === "" ||
    // operatorRef.value === "" ||
    // parentGroup.value === "" ||
    // roomType.value === "" ||
    (groupName.success === false ) ||
      // unit.success === false &&
      // operatorRef.success === false &&
      // parentGroup.success === false &&
      // roomType.success === false) ||
    isValueChangedNotOtherType;

  const disable =
    groupType.value === "Others" ? disableOtherType : disableNotOtherType;

  const handleSaveData = () => {
    const otherTypeFormData = {
      group_type_name: "Others",
      group_name: groupName.value,
      reference_number: "",
      parent_group: "",
    };
    const notOtherTypeFormData = {
      group_type_id: roomType.value,
      group_type_name: groupType.value,
      unit: unit.value,
      reference_number: referance.value,
      operator_reference: operatorRef.value,
      group_name: groupName.value,
      parent_group: parentGroup.value,
    };
    const formData = otherTypeFormData 

    handleChangeFormData(formData);
  };

  return (
    <div className="conatiner overflow-hidden">
      <div className="row">
        <div
          className="col-12 col-md-10 mx-auto overflow-hidden card-configuration"
          style={{ width: "100.33%" }}
        >
          <div className="modalFormContainer container itemGroup">
            <h2>{actionType === "add" ? CreateTitle : EditTitle}</h2>
            {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
            <div className="row itemgroupmaindiv">
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                {/* <div className="row">
                  <div className="col-sm-10">
                    <FormDropDown
                      value={groupType.value}
                      Label={selectLabel}
                      Options={options}
                      onSelect={handleSelectGroupType}
                      customErrorLine={selectCustomError}
                      searchable={false}
                      disable={false}
                      placeholder="Search"
                      isValidate={true}
                    />
                  </div>
                </div> */}

                {/* <div className="row">
                  <div className="col-sm-10">
                    <FormTextInput
                      type="text"
                      label={ReferanceLabel}
                      placeholder={ReferancePlaceholder}
                      Value={referance.value}
                      onChangeText={handleChangeReferance}
                      Validate={true}
                      CustomErrorLine={ReferanceCustomError}
                      editable={false}
                      multiline={false}
                    />
                  </div>
                </div> */}

                {/* <div className="row">
                  <div className="col-sm-10">
                    {groupType.value !== "Others" ? (
                      <FormTextInput
                        type="number"
                        starProps={true}
                        label={UnitLabel}
                        placeholder={UnitPlaceholder}
                        Value={unit.value}
                        onChangeText={handleChangeUnit}
                        Validate={true}
                        CustomErrorLine={UnitCustomError}
                        editable={true}
                        multiline={false}
                      />
                    ) : (
                      <></>
                    )}
                  </div> */}
                </div>

                {/* <div className="row">
                  <div className="col-sm-10">
                    {groupType.value !== "Others" ? (
                      <FormTextInput
                        type="text"
                        starProps={true}
                        label={OpRefLabel}
                        placeholder={OpRefPlaceholder}
                        Value={operatorRef.value}
                        onChangeText={handleChangeOpratorRef}
                        Validate={true}
                        CustomErrorLine={OpRefCustomError}
                        editable={true}
                        multiline={false}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div> */}
              </div>
              <div className="col-12 col-md-6 pe-0 pe-md-3">
                <div className="row">
                  <Modal onHandleClose={handleCloseModal} isOpen={modalOpen}>
                    {selectModal()}
                  </Modal>

                  {/* <div className="col-sm-10">
                    {groupType.value !== "Others" ? (
                      <FormDropDown
                        starProps={true}
                        isValidate={true}
                        value={roomType.value}
                        Label={RoomTypeSelectLabel}
                        Options={
                          groupType.value !== "Room Type"
                            ? publicAreaDropDown
                            : roomTypeDropDown
                        }
                        onSelect={handleSelectRoomType}
                        customErrorLine={RoomTypeselectCustomError}
                        searchable={false}
                        disable={false}
                        placeholder="Search"
                      />
                    ) : null}
                  </div> */}
                  {/* <div className="col-2 col-sm-2">
                    {groupType.value !== "Others" && (
                      <Button icon={<AddIcon />} onClick={handleSelectModal} />
                    )}
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-sm-10">
                    <FormTextInput
                      type="textarea"
                      starProps={true}
                      label={GroupNameLabel}
                      placeholder={GroupNamePlaceholder}
                      Value={groupName.value}
                      onChangeText={handleChangeGroupName}
                      Validate={true}
                      CustomErrorLine={GroupNameCustomError}
                      multiline={false}
                      editable
                    />
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-sm-10">
                    <FormDropDown
                      isValidate={true}
                      starProps={true}
                      value={parentGroup.value}
                      Label={parentGroupSelectLabel}
                      Options={itemGroupDropDown}
                      onSelect={handleSelectParentGroup}
                      customErrorLine={parentGroupselectCustomError}
                      searchable={false}
                      disable={false}
                      placeholder="Search"
                    />
                  </div>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    marginTop: "1.5rem",
                  }}
                >
                  <Button
                    buttonName={actionType === "add" ? SaveBtn : UpdateBtn}
                    buttonStyle={{ backgroundColor: colors.primary }}
                    disable={actionType === "add" ? disable : disable}
                    onClick={handleSaveData}
                  />
                  <Button
                    buttonName={CancelBtn}
                    buttonStyle={{
                      backgroundColor: colors.darkgrey,
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      handleModalClose();
                      setMessage(null);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default ItemGroupForm;
