/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ModeOfPaymentForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [description, setDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: name.value,
      description: description.value,
    };
    handleChangeFormData(formData);
  };

  const CreateTitle = Strings["ModeofPayment.CreateTitle"],
    EditTitle = Strings["ModeofPayment.EditTitle"],
    NameLabel = Strings["ModeofPayment.NameLabel"],
    NamePlaceholder = Strings["ModeofPayment.NamePlaceholder"],
    NameCustomError = Strings["ModeofPayment.NameCustomError"],
    DescriptionLabel = Strings["ModeofPayment.DescriptionLabel"],
    DescriptionPlaceholder = Strings["ModeofPayment.DescriptionPlaceholder"],
    DescriptionCustomError = Strings["ModeofPayment.DescriptionCustomError"],
    SaveBtn = Strings["ModeofPayment.SaveBtn"],
    UpdateBtn = Strings["ModeofPayment.UpdateBtn"],
    CancelBtn = Strings["ModeofPayment.CancelBtn"];

  let isValueNOTChanged = name.value === initialValue.name;
  // description.value === initialValue.description;
  let disable =
    name.error ||
    // description.error ||
    name.value === "" ||
    // description.value === '' ||
    name.success === false ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    name.value === initialValue.name &&
    description.value === initialValue.description;
  let Editdisable =
    name.error ||
    description.error ||
    name.value === "" ||
    description.value === "" ||
    (name.success === false && description.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? CreateTitle : EditTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        label={NameLabel}
        starProps={true}
        placeholder={NamePlaceholder}
        Value={name.value}
        onChangeText={handleChangeName}
        Validate={true}
        CustomErrorLine={NameCustomError}
        editable
        maxLength={30}
      />
      <FormTextInput
        type="text"
        label={DescriptionLabel}
        placeholder={DescriptionPlaceholder}
        Value={description.value}
        onChangeText={handleChangeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        CustomErrorLine={DescriptionCustomError}
        multiline={true}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? SaveBtn : UpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={CancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default ModeOfPaymentForm;
