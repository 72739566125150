/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const UOMForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createUOMTitle = Strings["UOM.CreateUOMForm"],
    editUOMTitle = Strings["UOM.EditUOMForm"],
    UOMLabel = Strings["UOM.UOMLabel"],
    UOMPlaceholder = Strings["UOM.UOMPlaceHolder"],
    UOMCustomError = Strings["UOM.UOMError"],
    UomDescriptionLabel = Strings["UOM.UOMDescriptionLabel"],
    UomDescriptionPlaceholder = Strings["UOM.UOMDescriptionPlaceHolder"],
    UomDescriptionCustomError = Strings["UOM.UOMDescriptionError"],
    UomTagLabel = Strings["UOM.UOMTagLabel"],
    UomTagPlaceholder = Strings["UOM.UOMTagPlaceHolder"],
    UomTagCustomError = Strings["UOM.UOMTagError"],
    UOMSaveBtn = Strings["UOM.SaveBtn"],
    UOMUpdateBtn = Strings["UOM.UpdateBtn"],
    UOMCancelBtn = Strings["UOM.CancelBtn"];

  const [uomName, setUomName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [uomDescription, setUomDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [uomTag, setUomTag] = React.useState({
    value: initialValue.tag ? initialValue.tag : "",
    error: false,
    success: false,
  });

  const handleChangeUomName = (value) => {
    setMessage(null);
    setUomName(value);
  };

  const handleChangeUomDescription = (value) => {
    setUomDescription(value);
  };

  const handleChangeUomTag = (value) => {
    setMessage(null);
    setUomTag(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: uomName.value,
      description: uomDescription.value,
      tag: uomTag.value,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged =
    uomName.value === initialValue.name &&
    // uomDescription.value === initialValue.description &&
    uomTag.value === initialValue.tag;
  let disable =
    uomName.error ||
    // uomDescription.error ||
    uomTag.error ||
    uomName.value === "" ||
    // uomDescription.value === "" ||
    uomTag.value === "" ||
    (uomName.success === false &&
      // uomDescription.success === false &&
      uomTag.success === false) ||
    isValueChanged;

  //edit
  let isValueChangededit =
    uomName.value === initialValue.name &&
    uomDescription.value === initialValue.description &&
    uomTag.value === initialValue.tag;
  let Editdisable =
    uomName.error ||
    uomDescription.error ||
    uomTag.error ||
    uomName.value === "" ||
    uomDescription.value === "" ||
    uomTag.value === "" ||
    (uomName.success === false &&
      uomDescription.success === false &&
      uomTag.success === false) ||
    isValueChangededit;
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createUOMTitle : editUOMTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="textarea"
        label={UOMLabel}
        placeholder={UOMPlaceholder}
        Value={uomName.value}
        onChangeText={handleChangeUomName}
        Validate={true}
        CustomErrorLine={UOMCustomError}
        editable
      />

      <FormTextInput
        type="textarea"
        label={UomDescriptionLabel}
        placeholder={UomDescriptionPlaceholder}
        Value={uomDescription.value}
        onChangeText={handleChangeUomDescription}
        Validate={false}
        CustomErrorLine={UomDescriptionCustomError}
        multiline={true}
        editable
      />

      <FormTextInput
        starProps={true}
        type="textarea"
        label={UomTagLabel}
        placeholder={UomTagPlaceholder}
        Value={uomTag.value}
        onChangeText={handleChangeUomTag}
        Validate={true}
        CustomErrorLine={UomTagCustomError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? UOMSaveBtn : UOMUpdateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={UOMCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default UOMForm;
