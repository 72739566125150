import React, { useState } from "react";
import { Button, FormTextInput } from "../../../components";
import Strings from "../../../translations";

const ChangePasswordForm = (props) => {
  const { handleFormData, id } = props;

  const [error, setError] = useState(null);
  const [oldPassword, setOldPassword] = useState({
    value: "",
    error: false,
    sucess: false,
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    error: false,
    sucess: false,
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    value: "",
    error: false,
    sucess: false,
  });

  const handleChangeOldPassword = (value) => {
    setOldPassword(value);
  };

  const handleChangeNewPassword = (value) => {
    setNewPassword(value);
  };

  const handleChangeConfirmPassword = (value) => {
    setConfirmNewPassword(value);
  };

  const handleSubmitChangePassword = (user) => {
    const fromData = {
      id: id,
      newPassword: newPassword.value,
    };

    if (oldPassword.value === newPassword.value) {
      setError("old password and new password should not be same.");
    } else {
      handleFormData(fromData);
      setError("");
    }
  };

  let disable =
    oldPassword.error ||
    newPassword.error ||
    confirmNewPassword.error ||
    oldPassword.value === "" ||
    newPassword.value === "" ||
    confirmNewPassword.value === "" ||
    oldPassword.sucess === false ||
    newPassword.sucess === false ||
    confirmNewPassword.sucess === false ||
    newPassword.value !== confirmNewPassword.value;

  const oldpasswordLabel =
    Strings["Authentication.changePasswordOldPasswordLabel"],
    oldpasswordplaceHolder =
      Strings["Authentication.changePasswordOldPasswordplaceholder"],
    oldpasswordcustomlineerror =
      Strings["Authentication.changepasswordoldPasswordCustomError"],
    newpasswordLabel = Strings["Authentication.changePasswordNewPasswordLabel"],
    newpasswordplaceHolder =
      Strings["Authentication.changePasswordNewPasswordplaceholder"],
    newpasswordcustomlineerror =
      Strings["Authentication.changepasswordNewPasswordCustomError"],
    confirmpasswordLabel =
      Strings["Authentication.changePasswordConFirmPasswordLabel"],
    confirmpasswordplaceHolder =
      Strings["Authentication.changePasswordConFirmPasswordplaceholder"],
    confirmNewPasswordcustomlineerror =
      Strings["Authentication.changepasswordConFirmPasswordCustomError"],
    cancelButton = Strings["Authentication.changepasswordcancelButton"],
    changePasswordButton =
      Strings["Authentication.changepasswordChnagepasswordButton"];

  return (
    <>
      <FormTextInput
        type="password"
        label={oldpasswordLabel}
        placeholder={oldpasswordplaceHolder}
        Value={oldPassword.value}
        onChangeText={handleChangeOldPassword}
        Validate={true}
        CustomErrorLine={oldpasswordcustomlineerror}
        editable={true}
        passwordVisibleIcon={true}
      />
      <FormTextInput
        type="password"
        label={newpasswordLabel}
        placeholder={newpasswordplaceHolder}
        Value={newPassword.value}
        onChangeText={handleChangeNewPassword}
        Validate={true}
        CustomErrorLine={newpasswordcustomlineerror}
        editable={true}
        passwordVisibleIcon={true}
      />
      <FormTextInput
        type="password"
        label={confirmpasswordLabel}
        placeholder={confirmpasswordplaceHolder}
        Value={confirmNewPassword.value}
        onChangeText={handleChangeConfirmPassword}
        Validate={true}
        CustomErrorLine={confirmNewPasswordcustomlineerror}
        editable={true}
        passwordVisibleIcon={true}
      />
      <p>{error}</p>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          buttonName={cancelButton}
          onClick={() => {
          }}
        />

        <Button
          buttonName={changePasswordButton}
          onClick={handleSubmitChangePassword}
          disable={disable}
        />
      </div>
    </>
  );
};

export default ChangePasswordForm;
