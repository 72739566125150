import React from "react";

import { FormTextInput, Button, FormCheckBox } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const DeleteVendorCategoryToProductForm = (props) => {
  const { deleteChangeFormData, initialValue = {}, actionType } = props;

  const pageDeleteTitle = Strings["VendorProductMapping.DeleteTitle"],
    pageViewTitle = Strings["VendorProductMapping.ViewTitle"],
    categoryLabel = Strings["VendorProductMapping.Category"],
    subCategoryLabel = Strings["VendorProductMapping.SubCategory"],
    productsLabel = Strings["VendorProductMapping.Products"],
    activeStatusLabel = Strings["VendorCategoryMapping.ActiveStatusLabel"],
    deleteBtn = Strings["VendorCategoryMapping.DeleteBtn"],
    cancelBtn = Strings["VendorCategoryMapping.CancelBtn"];

  const getAllProductsStr = (subCat) => {
    let str = "";
    subCat.forEach((i, index) => {
      if (index === 0) {
        str = i.productName;
      } else {
        str = str + " , " + i.productName;
      }
    });

    return str;
  };

  const [category] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    sucess: false,
  });
  const [subCategories] = React.useState({
    value: initialValue.subCategory ? initialValue.subCategory : "",
    error: false,
    sucess: false,
  });
  const [products] = React.useState({
    value: initialValue.products
      ? getAllProductsStr(initialValue.products)
      : "",
    error: false,
    sucess: false,
  });

  const [activeStatus, setActiveStatus] = React.useState(
    initialValue.activeStatus && initialValue.activeStatus
  );

  const handleChangeCheck = () => {
    setActiveStatus(!activeStatus);
  };
  const handleDelteData = () => {
    const formData = {
      category: category.value,
      subCategory: subCategories.value,
      products: initialValue.products,
      activeStatus: activeStatus && activeStatus, //not a confirmed logic
    };
    deleteChangeFormData(formData);
  };
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "view" ? pageViewTitle : pageDeleteTitle}</h2>

      <FormTextInput
        type="alpha"
        label={categoryLabel}
        placeholder={categoryLabel}
        Value={category.value}
        onChangeText={() => { }}
        Validate={true}
        CustomErrorLine={""}
        editable={false}
      />

      <FormTextInput
        type="text"
        label={subCategoryLabel}
        placeholder={subCategoryLabel}
        Value={subCategories.value}
        onChangeText={() => { }}
        Validate={true}
        CustomErrorLine={""}
        editable={false}
      />

      <FormTextInput
        type="text"
        label={productsLabel}
        placeholder={productsLabel}
        Value={products.value}
        onChangeText={() => { }}
        Validate={true}
        CustomErrorLine={""}
        editable={false}
        multiline={true}
      />

      {actionType === "view" || actionType === "delete" ? (
        <FormCheckBox
          Label={activeStatusLabel}
          checked={activeStatus}
          handleChangeCheck={handleChangeCheck}
          disable={actionType === "view" ? true : false}
        />
      ) : (
        <></>
      )}
      {actionType !== "view" ? (
        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <Button
            buttonName={deleteBtn}
            buttonStyle={
              actionType === "delete"
                ? { marginRight: "10px", backgroundColor: colors.danger }
                : { marginRight: "10px" }
            }
            disable={false}
            onClick={handleDelteData}
          />
          <Button buttonName={cancelBtn} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DeleteVendorCategoryToProductForm;
