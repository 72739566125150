/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineApartment } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { CgOrganisation } from "react-icons/cg";
import { FaUserAlt } from "react-icons/fa";
import { GoGlobe } from "react-icons/go";
import { RiFunctionFill } from "react-icons/ri";
import { TbLockAccess } from "react-icons/tb";
import { masterconfiguration } from "../../../assets";
import { MasterCard, TestMasterCard } from "../../../components";
import Strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  GET_ACCESS_CONSIDERATION,
  GET_BUDGET_ITEM_CATEGORY_MAPPING,
  GET_BUDGET_SCOPE,
  GET_CATEGORY_PRODUCT_MAPPING_FUNCTION,
  GET_CLIENT,
  GET_CLIENT_TYPE_FUNCTION,
  GET_COMMODITY_FUNCTION,
  GET_COUNTRY_FUNCTION,
  GET_CURRENCY_FUNCTION,
  GET_ESTABLISHMENT_ITEM_CATEGORY,
  GET_ESTABLISHMENT_SCOPE,
  GET_FUNCTIONALITY,
  GET_INCOTERM,
  GET_ITEM_CATEGORY,
  GET_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING,
  GET_ITEM_GROUP,
  GET_ITEM_SUB_CATEGORY,
  GET_ITEM_SUB_GROUP,
  GET_LOCATION,
  GET_MODE_OF_SHIPMENT,
  GET_MODE_OF_TRANSPORTATION,
  GET_MODULE,
  GET_MOP_FUNCTION,
  GET_PAYMENT_AGAINST,
  GET_PAYMENT_PROGRESS,
  GET_PAYMENT_TERMS,
  GET_PRODUCT_AND_SERVICES,
  GET_PROJECT_ROLE,
  GET_PROJECT_SCOPE,
  GET_PUBLIC_AREA,
  GET_REQUIRED_DOCUMENT,
  GET_REQUIRED_SUBMITTALS,
  GET_ROLE,
  GET_ROLE_FUNCTIONALITY_MAP,
  GET_ROOM_TYPE,
  GET_SCHEDULE_STEPS,
  GET_STAGE,
  GET_UOM,
  GET_USER_MANAGEMENT_FUNCTION,
  GET_USER_ROLE_MAPPING,
  GET_USER_TYPE,
  GET_VENDOR,
  GET_VENDOR_CATEGORY_FUNCTION,
  GET_VENDOR_CATEGORY_TO_SUB_CATEGORY,
  GET_VENDOR_COMPANY_TYPE,
  GET_VENDOR_SUB_CATEGORY,
  SHOW_GENERAL_SETTING,
  SHOW_MASTER_CONFIGURATION,
} from "../../../utils/constants";
import "./masterconfiguration.css";

const MasterConfigurationPage = () => {

  const TitleHeading = Strings["MasterConfiguration.TitleHeading"],
    titleparaGraph = Strings["MasterConfiguration.titleParagraph"],
    cardTitleHeading = Strings["MasterConfiguration.cardTitleHeader"],
    masterconfigurationHaederTitle =
      Strings["MasterConfiguration.masterconfigurecardtitle"],
    masterconfigurationcountryLabel =
      Strings["MaseterConfiguration.masterconfigurecountrylabele"],
    masterconfigurationEstablishmentLabel =
      Strings["MaseterConfiguration.masterconfigureEstablishmentlabele"],
    masterconfigurationItemCategoryLabel =
      Strings["MasterConfiguration.itemCategory"],
    masterconfigurationBudgetScopeLabel =
      Strings["MasterConfiguration.budgetScope"],
    masterconfigurationscheduleStepsLabel =
      Strings["MasterConfiguration.scheduleSteps"],
    masterconfigurationrequiredDocumentLabel =
      Strings["MasterConfiguration.requiredDocument"],
    masterconfigurationmodeOfPaymentLabel =
      Strings["MasterConfiguration.modeOfPayment"],
    masterconfigurationStageLabel =
      Strings["MasterConfiguration.stage"],
    masterconfigurationvendorLabel =
      Strings["MasterConfiguration.vendor"],
    masterconfigurationuserManagementLabel =
      Strings["MasterConfiguration.userManagement"],
    masterconfigurationprojectScopeLabel =
      Strings["MasterConfiguration.projectScope"],
    masterconfigurationClientLabel =
      Strings["MasterConfiguration.client"],
    masterconfigurationVendorCategoryLabel =
      Strings["MasterConfiguration.vendorCategory"],
    masterconfigurationvendorCompanyLabel =
      Strings["MasterConfiguration.vendorCompany"],
    masterconfigurationProductServiceLabel =
      Strings["MasterConfiguration.productService"],
    masterconfigurationCommoditiesLabel =
      Strings["MasterConfiguration.commodities"],
    masterconfigurationvendorSubCategoryLabel =
      Strings["MasterConfiguration.vendorSubCategory"],
    masterconfigurationCategorySubMapLabel =
      Strings["MasterConfiguration.vendorCotegorySubCategoryMap"],
    masterconfigurationVendorCotegoryProductMap =
      Strings["MasterConfiguration.vendorCotegoryProductMap"],
    masterconfigurationRoomType =
      Strings["MasterConfiguration.roomType"],
    masterconfigurationPublicArea =
      Strings["MasterConfiguration.publicArea"],
    masterconfigurationCurrency =
      Strings["MasterConfiguration.currency"],
    masterconfigurationUom =
      Strings["MasterConfiguration.uom"],
    masterconfigurationrequiredSubmittals =
      Strings["MasterConfiguration.requiredSubmittals"],
    masterconfigurationMos =
      Strings["MasterConfiguration.mos"],
    masterconfigurationacesslocation =
      Strings["MasterConfiguration.location"],
    masterconfigurationacessIncoterm =
      Strings["MasterConfiguration.Incoterm"],
    masterconfigurationacessConsideraion =
      Strings["MasterConfiguration.acessConsideraion"],
    masterconfigurationmot =
      Strings["MasterConfiguration.mot"],
    masterconfigurationbudgetScopeItemCategoryMap =
      Strings["MasterConfiguration.budgetScopeItemCategoryMap"],
    masterconfigurationpaymentTerms =
      Strings["MasterConfiguration.paymentTerms"],
    masterconfigurationfinalPayment =
      Strings["MasterConfiguration.finalPayment"],
    masterconfigurationpaymentProgress =
      Strings["MasterConfiguration.paymentProgress"],
    masterconfigurationitemGroup =
      Strings["MasterConfiguration.itemGroup"],
    masterconfigurationitemSubGroup =
      Strings["MasterConfiguration.itemSubGroup"],
    masterconfigurationgroupsubgroupmap =
      Strings["MasterConfiguration.groupsubgroupmap"],
    masterconfigurationScopeGroupMap =
      Strings["MasterConfiguration.scopeGroupMap"],
    masterconfigurationItemSubCategory =
      Strings["MasterConfiguration.itemSubCategory"],
    masterconfigurationEstablishment =
      Strings["MasterConfiguration.establishmentCategoryMap"],
    masterconfigurationitemCategorySubCategoryMap =
      Strings["MasterConfiguration.itemCategorySubCategoryMap"],
    masterconfigurationOpera =
      Strings["MasterConfiguration.operator"],
    masterconfigurationProject =
      Strings["MasterConfiguration.project"],
    userRole = Strings["MasterConfiguration.userRole"],
    vendorLoginAcess = Strings["MasterConfiguration.vendorAccess"],
    functions = Strings["MasterConfiguration.functions"],
    moduleLabel = Strings["MasterConfiguration.module"],
    roleFunctionMapping = Strings["MasterConfiguration.roleFunctionMapping"],
    userType = Strings["MasterConfiguration.userType"],
    userRoleMapping = Strings["MasterConfiguration.userRoleMapping"],
    projectRoleLabel = Strings["MasterConfiguration.projectRole"],
    clientType = Strings["MasterConfiguration.clientType"],
    projectAndBudgetTitleHeading =
      Strings["MasterConfiguration.projectAndBudgetTitleHeading"];

  return (
    <div className="container">
      <Helmet>
        <title>Furnish | Master Configuration</title>
      </Helmet>
      <div className="card-configuration">
        <div className="row">
          <div className="col-sm-8 m-auto">
            <div className="heading_title">
              <h3>{TitleHeading}</h3>
              <p>{titleparaGraph}</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <img
              src={masterconfiguration}
              alt="configimage"
              className="configimage"
            />
          </div>
        </div>
      </div>
      {isAuthorizedFunc(SHOW_GENERAL_SETTING) && (
        <div className="card-configuration">
          <div className="card-configuration_header">
            <h3>{cardTitleHeading}</h3>
          </div>
          <div className="card-configuration_content">
            <div className="row">
              {isAuthorizedFunc(GET_ROLE) && (
                <div className="col-sm-6 col-md-4">
                  <TestMasterCard
                    logo={<BiTransfer />}
                    text={userRole}
                    path="/master-configuration/searchrole"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_FUNCTIONALITY) && (
                <div className="col-sm-6 col-md-4">
                  <TestMasterCard
                    logo={<RiFunctionFill />}
                    text={functions}
                    path="/master-configuration/searchfunction"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_PROJECT_ROLE) && (
                <div className="col-sm-6 col-md-4">
                  <TestMasterCard
                    logo={<RiFunctionFill />}
                    text={projectRoleLabel}
                    path="/master-configuration/searchprojectrole"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_MODULE) && (
                <div className="col-sm-6 col-md-4">
                  <TestMasterCard
                    logo={<RiFunctionFill />}
                    text={moduleLabel}
                    path="/master-configuration/searchmodule"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ROLE_FUNCTIONALITY_MAP) && (
                <div className="col-sm-6 col-md-4">
                  <TestMasterCard
                    logo={<AiOutlineApartment />}
                    text={roleFunctionMapping}
                    path="/master-configuration/searchmapingrolefunction"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_USER_ROLE_MAPPING) && (
                <div className="col-sm-6 col-md-4">
                  <TestMasterCard
                    logo={<AiOutlineApartment />}
                    text={userRoleMapping}
                    path="/master-configuration/searchuserrolemapping"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ROLE) && (
                <div className="col-sm-6 col-md-4">
                  <TestMasterCard
                    logo={<TbLockAccess />}
                    text={vendorLoginAcess}
                    path="/master-configuration/vendorloginaccess"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isAuthorizedFunc(SHOW_MASTER_CONFIGURATION) && (
        <div className="card-configuration">
          <div className="card-configuration_header">
            <h3>{masterconfigurationHaederTitle}</h3>
          </div>
          <div className="card-configuration_content">
            <div className="row">
              {isAuthorizedFunc(GET_CLIENT_TYPE_FUNCTION) && (
                <div className="col-sm-6 col-md-4">
                  <MasterCard
                    logo={<AiOutlineApartment />}
                    text={clientType}
                    path="/master-configuration/searchclienttype"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_USER_TYPE) && (
                <div className="col-sm-6 col-md-4">
                  <MasterCard
                    logo={<FaUserAlt />}
                    text={userType}
                    path="/master-configuration/searchusertype"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_COUNTRY_FUNCTION) && (
                <div className="col-sm-12 col-md-4">
                  <TestMasterCard
                    logo={<GoGlobe />}
                    text={masterconfigurationcountryLabel}
                    path="/master-configuration/searchcountry"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ESTABLISHMENT_SCOPE) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationEstablishmentLabel}
                    path="/master-configuration/searchestablishment"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ITEM_CATEGORY) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationItemCategoryLabel}
                    path="/master-configuration/itemcategory"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_BUDGET_SCOPE) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationBudgetScopeLabel}
                    path="/master-configuration/budgetscope"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_SCHEDULE_STEPS) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationscheduleStepsLabel}
                    path="/master-configuration/schedulestep"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_REQUIRED_DOCUMENT) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationrequiredDocumentLabel}
                    path="/master-configuration/requireddocument"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_MOP_FUNCTION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationmodeOfPaymentLabel}
                    path="/master-configuration/modeofpayment"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_STAGE) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationStageLabel}
                    path="/master-configuration/stage"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_VENDOR) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationvendorLabel}
                    path="/master-configuration/vendor"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_USER_MANAGEMENT_FUNCTION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationuserManagementLabel}
                    path="/master-configuration/searchuser"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_PROJECT_SCOPE) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationprojectScopeLabel}
                    path="/master-configuration/searchprojectscope"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_CLIENT) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationClientLabel}
                    path="/master-configuration/searchclient"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_VENDOR_CATEGORY_FUNCTION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationVendorCategoryLabel}
                    path="/master-configuration/searchvendorcategory"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_VENDOR_COMPANY_TYPE) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationvendorCompanyLabel}
                    path="/master-configuration/vendorcompanytype"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_PRODUCT_AND_SERVICES) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationProductServiceLabel}
                    path="/master-configuration/productandservice"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_COMMODITY_FUNCTION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationCommoditiesLabel}
                    path="/master-configuration/commodities"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_VENDOR_SUB_CATEGORY) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationvendorSubCategoryLabel}
                    path="/master-configuration/searchvendordubcategory"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_VENDOR_CATEGORY_TO_SUB_CATEGORY) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationCategorySubMapLabel}
                    path="/master-configuration/searchvendorcategorytosubcategorymapping"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_CATEGORY_PRODUCT_MAPPING_FUNCTION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationVendorCotegoryProductMap}
                    path="/master-configuration/searchvendorcategorytoproductmapping"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ROOM_TYPE) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationRoomType}
                    path="/master-configuration/searchroomtype"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_PUBLIC_AREA) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationPublicArea}
                    path="/master-configuration/searchpublicarea"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_CURRENCY_FUNCTION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationCurrency}
                    path="/master-configuration/searchcurrency"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_UOM) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationUom}
                    path="/master-configuration/searchuompage"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_REQUIRED_SUBMITTALS) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationrequiredSubmittals}
                    path="/master-configuration/searchsubmitals"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_MODE_OF_SHIPMENT) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationMos}
                    path="/master-configuration/searchmodeofshipment"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_LOCATION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationacesslocation}
                    path="/master-configuration/searchlocation"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_INCOTERM) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationacessIncoterm}
                    path="/master-configuration/searchincoterm"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ACCESS_CONSIDERATION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationacessConsideraion}
                    path="/master-configuration/searchacessandconsideration"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_MODE_OF_TRANSPORTATION) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationmot}
                    path="/master-configuration/modeoftransportation"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_BUDGET_ITEM_CATEGORY_MAPPING) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationbudgetScopeItemCategoryMap}
                    path="/master-configuration/budgetitemcategorymapping"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_PAYMENT_TERMS) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationpaymentTerms}
                    path="/master-configuration/searchpaymentterms"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_PAYMENT_AGAINST) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationfinalPayment}
                    path="/master-configuration/searchpaymentagainst"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_PAYMENT_PROGRESS) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationpaymentProgress}
                    path="/master-configuration/searchpaymentprogress"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ITEM_GROUP) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationitemGroup}
                    path="/master-configuration/itemgroup"
                  />
                </div>
              )}
              {isAuthorizedFunc(GET_ITEM_SUB_GROUP) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationitemSubGroup}
                    path="/master-configuration/searchitemsubgroup"
                  />
                </div>
              )}

              {/* Scope  Group Map */}

              {isAuthorizedFunc(GET_ITEM_GROUP) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationScopeGroupMap}
                    path="/master-configuration/searchscopegroupmap"
                  />
                </div>
              )}

              {/* Group Sub Group Map */}

              {isAuthorizedFunc(GET_ITEM_GROUP) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationgroupsubgroupmap}
                    path="/master-configuration/searchgroupsubgroupmap"
                  />
                </div>
              )}
              {/* {isAuthorizedFunc(GET_ITEM_SUB_CATEGORY) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationItemSubCategory}
                    path="/master-configuration/itemsubcategory"
                  />
                </div>
              )} */}
              {isAuthorizedFunc(GET_ESTABLISHMENT_ITEM_CATEGORY) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationEstablishment}
                    path="/master-configuration/searchestablishmenttoitemcategorymapping"
                  />
                </div>
              )}
              {/* {isAuthorizedFunc(GET_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING) && (
                <div className="col-12 col-md-4">
                  <TestMasterCard
                    logo={<CgOrganisation />}
                    text={masterconfigurationitemCategorySubCategoryMap}
                    path="/master-configuration/itemcategorysubcategorymapping"
                  />
                </div>
              )} */}
              <div className="col-12 col-md-4">
                <TestMasterCard
                  logo={<CgOrganisation />}
                  text={masterconfigurationOpera}
                  path="/master-configuration/searchoperator"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card-configuration">
        <div className="card-configuration_header">
          <h3>{projectAndBudgetTitleHeading}</h3>
        </div>
        <div className="card-configuration_content">
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <TestMasterCard
                logo={<BiTransfer />}
                text={masterconfigurationProject}
                path="/project"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterConfigurationPage;
