import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CountryForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createCountryTitle = Strings["Country.CreateCountryHeader"],
    editCountrytitle = Strings["Country.EditCountryHeader"],
    countryNameLabel = Strings["Country.CreateCountryName"],
    countryNamePlaceholder = Strings["Country.CreateCountryNamePlaceholder"],
    countryNameCustomError = Strings["Country.CreateCountryNameCustomError"],
    countryCodeLabel = Strings["Country.CreateCountryCodeLabel"],
    countryCodePlaceholder = Strings["Country.CreateCountryCodePlaceholder"],
    countryCodeCustomError = Strings["Country.CreateCountryCodeCustomError"],
    countryTagLabel = Strings["Country.CreateCountryTagLabel"],
    countryTagPlaceholder = Strings["Country.CreateCountryTagPlaceholder"],
    countryTagCustomError = Strings["Country.CreateCountryTagCustomError"],
    cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];

  const [countryName, setcountryName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    sucess: false,
  });

  const [countryCode, setCountryCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    sucess: false,
  });

  const [countryTag, setCountryTag] = React.useState({
    value: initialValue.tag ? initialValue.tag : "",
    error: false,
    sucess: false,
  });

  const handleChangeCountry = (value) => {
    setMessage(null);
    setcountryName(value);
  };

  const handleChangeCountryCode = (value) => {
    setMessage(null);
    setCountryCode(value);
  };

  const handleChangeCountryTag = (value) => {
    setMessage(null);
    setCountryTag(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: countryName.value,
      code: countryCode.value,
      tag: countryTag.value,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged =
    countryName.value === initialValue.name &&
    countryCode.value === initialValue.code &&
    countryTag.value === initialValue.tag;
  let disable =
    countryName.error ||
    countryCode.error ||
    countryTag.error ||
    countryName.value === "" ||
    countryCode.value === "" ||
    countryTag.value === "" ||
    (countryName.success === false &&
      countryCode.success === false &&
      countryTag.success === false) ||
    isValueNOTChanged;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createCountryTitle : editCountrytitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        starProps={true}
        label={countryNameLabel}
        placeholder={countryNamePlaceholder}
        Value={countryName.value}
        onChangeText={handleChangeCountry}
        Validate={true}
        CustomErrorLine={countryNameCustomError}
        editable={true}
        maxLength={30}
      />
      <FormTextInput
        starProps={true}
        type="textarea"
        label={countryCodeLabel}
        placeholder={countryCodePlaceholder}
        Value={countryCode.value}
        onChangeText={handleChangeCountryCode}
        Validate={true}
        CustomErrorLine={countryCodeCustomError}
        multiline={false}
        editable={true}
        maxLength={20}
      />

      <FormTextInput
        starProps={true}
        type="textarea"
        label={countryTagLabel}
        placeholder={countryTagPlaceholder}
        Value={countryTag.value}
        onChangeText={handleChangeCountryTag}
        Validate={true}
        CustomErrorLine={countryTagCustomError}
        multiline={false}
        editable={true}
        maxLength={20}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default CountryForm;
