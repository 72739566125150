/* eslint-disable no-unused-vars */
import { Table } from "@material-ui/core";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AddIcon, IconButton, Loader, Modal } from "../../../components";
import AlertMessage from "../../../components/common/AlertMessage";
import { AddMasterFieldForm } from "../../../forms";

const MasterDetails = () => {
  const [alertBox, setAlertBox] = useState(false);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [allMaster, setallMaster] = useState([]);
  const mid = location.state.master.id;

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");

  const [currentField, setCurrentField] = React.useState(allMaster);
  const fetchURL = `${process.env.REACT_APP_BASE_URL}/furnish/project/public/api/en/master/getAllFieldsByType`;
  const masterid = { master_type_id: mid };
  useEffect(() => {
    masterDetails();
  }, []);

  const masterDetails = () => {
    axios
      .post(fetchURL, masterid)
      .then((response) => {
        const data = response.data;

        if (data) {
          setLoader(true);
        }
        setallMaster(data);
      })
      .catch(() => {

      });
  };

  const handleSaveData = (formData) => {
    const fetchURL = `${process.env.REACT_APP_BASE_URL}/furnish/project/public/api/en/master/createField`;
    axios
      .post(fetchURL, formData)
      .then((res) => {
        if (res.status === 200) {
          masterDetails();
          handleModalClose();
        }
      })
      .catch(() => {
      });
  };

  const handleUpdateData = (updateFormData) => {
    const url = `${process.env.REACT_APP_BASE_URL}/furnish/project/public/api/en/master/updateField`;
    axios
      .put(url, updateFormData)
      .then((res) => {
      })
      .catch((err) => {
      });
    masterDetails();
    handleModalClose();
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const handleEditClick = (id) => {
    openAddModal();

    const editResult = allMaster.find((item) => {
      return item.id === id;
    });
    setCurrentField(editResult);
    handleSelectBtn("edit");
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setAlertBox(false);
  };
  const handleDeleteClick = (key) => {
    <AlertMessage
      title={"Alert"}
      message={"Do You Really Want to Delete ?"}
      onChangeCancel={handleChangeCancel}
      onChangeSucess={handleChangeSuccess}
    />;

    const fetchURL = `${process.env.REACT_APP_BASE_URL}/furnish/project/public/api/en/master/deleteField`;
    axios
      .put(fetchURL, { id: key })
      .then((response) => {
        if (response.status === 200) {
          const newData = allMaster.filter((item) => {
            return item.id !== key;
          });
          setallMaster(newData);
        }
      })
      .catch((err) => {
      });
  };

  const selectModal = () => {
    return (
      <AddMasterFieldForm
        handleChangeFunctionData={(val) => {
        }}
        handleSaveData={handleSaveData}
        mid={mid}
        handleUpdateData={handleUpdateData}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentField : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  return (
    <div className="container">
      <Loader loaded={loader} />
      <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <IconButton
        onClick={() => {
          openAddModal();
          handleSelectBtn("add");
        }}
      >
        <AddIcon />
      </IconButton>
      <Table>
        <tr>
          {allMaster.map((data) => {
            return (
              <React.Fragment key={data.id}>
                <th>{data.master_types_display_name}</th>
                <td>
                  <IconButton
                    onClick={() => {
                      handleEditClick(data.id);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleDeleteClick(data.id);
                    }}
                  >
                    <Delete style={{ color: "red" }} />
                  </IconButton>
                </td>
              </React.Fragment>
            );
          })}
        </tr>
      </Table>
    </div>
  );
};

export default MasterDetails;
