import React from "react";
import { useHistory } from "react-router-dom";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ConfirmForgotPasswordForm = (props) => {
  const { setMessage, errorMessage, handleData = () => {} } = props;

  const ConfirmForgotPasswordEmailLabel =
      Strings["Authentication.confirmForgotPasswordEmailLabel"],
    ConfirmForgotPasswordEmailPlaceHolder =
      Strings["Authentication.confirmForgotPasswordEmailPlaceholder"],
    ConfirmForgotPasswordEmailCustomError =
      Strings["Authentication.confirmForgotPasswordEmailCustomError"],
    ConfirmForgotPasswordOTPLabel =
      Strings["Authentication.confirmForgotPasswordOTPLabel"],
    ConfirmForgotPasswordOTPPlaceHolder =
      Strings["Authentication.confirmForgotPasswordOTPPlaceholder"],
    ConfirmForgotPasswordOTPCustomError =
      Strings["Authentication.confirmForgotPasswordOTPCustomError"],
    ConfirmForgotPasswordPasswordLabel =
      Strings["Authentication.confirmForgotPasswordPasswordLabel"],
    ConfirmForgotPasswordPasswordPlaceHolder =
      Strings["Authentication.confirmForgotPasswordPasswordPlaceholder"],
    ConfirmForgotPasswordPasswordCustomError =
      Strings["Authentication.confirmForgotPasswordPasswordCustomError"],
    ConfirmForgotPasswordConfirmPasswordLabel =
      Strings["Authentication.confirmForgotPasswordConfirmPasswordLabel"],
    ConfirmForgotPasswordConfirmPasswordPlaceHolder =
      Strings["Authentication.confirmForgotPasswordConfirmPasswordPlaceholder"],
    ConfirmForgotPasswordConfirmPasswordCustomError =
      Strings["Authentication.confirmForgotPasswordConfirmPasswordCustomError"],
    cancelButton = Strings["Authentication.confirmforgotpasswordcancelButton"],
    changePasswordButton =
      Strings["Authentication.confirmforgotpasswordChangepasswordButton"],
    passwordHint = Strings["Authentication.loginPasswordHint"];

  const [userEmail, setUserEmail] = React.useState({
    value: "",
    error: false,
    sucess: false,
  });

  const [userOtp, setUserOtp] = React.useState({
    value: "",
    error: false,
    sucess: false,
  });

  const [userNewPassword, setUserNewPassword] = React.useState({
    value: "",
    error: false,
    sucess: false,
  });

  const [userConfirmPassword, setUserConfirmPassword] = React.useState({
    value: "",
    error: false,
    sucess: false,
  });

  const handleChangeEmail = (value) => {
    setMessage(null);
    setUserEmail(value);
  };

  const handleChangeOtp = (value) => {
    setMessage(null);
    setUserOtp(value);
  };

  const handleChangeNewPassword = (value) => {
    setMessage(null);
    setUserNewPassword(value);
  };

  const handleChangeConfirmPassword = (value) => {
    setMessage(null);
    setUserConfirmPassword(value);
  };

  const history = useHistory();

  const handleCancel = () => {
    setMessage(null);
    history.push("/forgotpassword");
  };
  const handleSubmit = () => {
    const formData = {
      email: userEmail.value,
      password: userConfirmPassword.value,
      otp: userOtp.value,
    };
    handleData(formData);
  };

  let disable =
    userEmail.error ||
    userOtp.error ||
    userNewPassword.error ||
    userConfirmPassword.error ||
    userEmail.value === "" ||
    userOtp.value === "" ||
    userNewPassword.value === "" ||
    userConfirmPassword.value === "" ||
    userEmail.sucess === false ||
    userOtp.sucess === false ||
    userNewPassword.sucess === false ||
    userConfirmPassword.sucess === false ||
    userNewPassword.value !== userConfirmPassword.value;

  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="email"
        label={ConfirmForgotPasswordEmailLabel}
        placeholder={ConfirmForgotPasswordEmailPlaceHolder}
        Value={userEmail.value}
        onChangeText={handleChangeEmail}
        Validate={true}
        CustomErrorLine={ConfirmForgotPasswordEmailCustomError}
        editable={true}
      />
      <FormTextInput
        type="number"
        label={ConfirmForgotPasswordOTPLabel}
        placeholder={ConfirmForgotPasswordOTPPlaceHolder}
        Value={userOtp.value}
        onChangeText={handleChangeOtp}
        Validate={true}
        CustomErrorLine={ConfirmForgotPasswordOTPCustomError}
        editable={true}
      />
      <FormTextInput
        type="password"
        label={ConfirmForgotPasswordPasswordLabel}
        placeholder={ConfirmForgotPasswordPasswordPlaceHolder}
        Value={userNewPassword.value}
        onChangeText={handleChangeNewPassword}
        Validate={true}
        CustomErrorLine={ConfirmForgotPasswordPasswordCustomError}
        // passwordVisibleIcon={true}
        editable={true}
        Hint={passwordHint}
        onCopy={handleChange}
        onPaste={handleChange}
      />
      <FormTextInput
        type="password"
        label={ConfirmForgotPasswordConfirmPasswordLabel}
        placeholder={ConfirmForgotPasswordConfirmPasswordPlaceHolder}
        Value={userConfirmPassword.value}
        onChangeText={handleChangeConfirmPassword}
        Validate={true}
        CustomErrorLine={ConfirmForgotPasswordConfirmPasswordCustomError}
        // passwordVisibleIcon={true}
        editable={true}
        // Hint={passwordHint}
        onCopy={handleChange}
        onPaste={handleChange}
      />

      <div className="forgotpasswordbuttondiv mt-3">
        <Button
          buttonName={changePasswordButton}
          disable={disable}
          onClick={handleSubmit}
        />
        <Button
          buttonStyle={{ marginLeft: "20px", backgroundColor: colors.darkgrey }}
          buttonName={cancelButton}
          onClick={handleCancel}
        />
      </div>
    </>
  );
};

export default ConfirmForgotPasswordForm;
