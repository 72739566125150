/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GetRFQById } from "../../../axios";
import { AlertMessage, BreadCrumbs, IconButton } from "../../../components";
import RFQquotation from "../../../components/AppSpecific/ProjectBudgetSearchTable/RFQTableDocument/RFQquotation";
import LoaderComponent from "../../../components/common/Loader";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const QuotationTitle = Strings["Quotation.QuotationTitle"],
  RFQName = Strings["Quotation.table.RFQName"],
  alertMessage = Strings["Quotation.AlertMessage"],
  alertTitle = Strings["Quotation.AlertTitle"],
  Action = Strings["Quotation.table.Action"],
  VAlidUntil = Strings["Quotation.table.VAlidUntil"],
  ItemNo = Strings["Quotation.table.ItemNo"],
  QuotationName = Strings["Quotation.table.QuotationName"],
  VendorName = Strings["Quotation.table.VendorName"];

const headCells = [
  {
    id: "rfqName",
    numeric: false,
    disablePadding: true,
    label: "RFQ No.",
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: false,
    label: VendorName,
  },
  {
    dataKey: "id",
    title: "Action",
    isAction: true,
    isview: true.toString(),
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SearchRfq = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [ids, setIds] = React.useState();
  const [totalData, setTotalData] = React.useState(null);
  const [rfqData, setRFQData] = React.useState([]);
  const [openDownload, setOpenDownload] = React.useState(false);
  const [downloadData, setDownloadData] = React.useState([]);

  const mapableDataRFQ = [
    {
      dataKey: "rfqName",
      title: "Rfq No.",
    },
    {
      dataKey: "vendors",
      title: "Vendor",
    },
    // {
    //   dataKey: "date",
    //   title: Date,
    // },
    // {
    //   dataKey: "deadline",
    //   title: "Deadline",
    // },
    {
      datakey: "download",
      title: "Download",
      isdownload: true,
    },
    {
      dataKey: "id",
      title: Action,
      isAction: true,
      isview: true.toString(),
    },
  ];

  const history = useHistory();
  const location = useLocation();
  const rfqId = location.state.rfqId;
  const budget_id = parseInt(location.state.key.budgetID);
  const proId = 1;

  //   const proId = location.state
  //     ? location.state.proId
  //     : location.query
  //     ? JSON.parse(location.query.params).proId
  //     : null;

  //   const budget_id = location.state
  //     ? location.state.budget_id
  //     : location.query
  //     ? JSON.parse(location.query.params).budget_id
  //     : null;
  //   const paramId = useParams();
  //   const paramRfqId = parseInt(paramId.id);
  //   const rfqId = location.state
  //     ? location.state.rfqId
  //     : location.query
  //     ? JSON.parse(location.query.params).rfqId
  //     : paramRfqId;

  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${proId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${budget_id}`,
    },
    {
      name: strings["Rfq"],
      path: "/quotation",
    },
  ];
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setMessage(null);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const VendorQuoIds = rows.map((ele) => {
    return {
      vendorid: ele.vendor.id,
      quotation_id: ele.id,
    };
  });
  const getAllRfq = () => {
    setLoader(true);
    GetRFQById(rfqId, rowsPerPage, page)
      .then((res) => {
        setLoader(false);
        const result = res.data.data.vendor.map((elm) => ({
          id: elm.request_for_quotation_id,
          rfqName: elm.quotation_number,
          vendors: elm.vendor.name,
          vendor_id: elm.vendor.id,
        }));
        // let resultss;
        // resultss = res.data.data.map((elem) => {
        //   return elem.requestvendor.map((item) => {
        //     return {
        //       id: elem.id,
        //       name: elem.name,
        //       incoterm: elem.incoterm.id,
        //       important_notes: elem.important_notes,
        //       tansportionId: elem.mode_of_transportation_id,
        //       tendor_notes: elem.tendor_notes,
        //       serial_number: item.quotation_number,
        //       vendor: item.vendor.name,
        //       vendorId: item.vendor.id,
        //       budget_item: elem.budget_item,
        //     };
        //   });
        // });
        setRFQData(result);
        setTotalData(res.data.total);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  React.useEffect(() => {
    getAllRfq();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // const handleDeleteAction = (id) => {
  //   setStatusChangeId(id);
  //   setAlertBox(true);
  // };
  // const handleChangeEdit = (id) => {
  //   setIds(id);
  //   // const result = rseArray.find((res) => {
  //   //   return res.id === id;
  //   // });
  //   // setCurrentUserType(result);
  //   // setUserId(result.id);
  //   // openAddModal();
  //   // handleSelectBtn("edit");
  // };
  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  // const handleView = (value) => {
  // };
  const handleVisible = (id) => {
    const result = rfqData.find((row) => {
      return row.id === id;
    });
    history.push({
      pathname: `/quotation/${id}`,
      state: {
        vendorId: result.vendor_id,
        value: result.rfqName,
        rfqId: result.id,
        budget_id: budget_id,
        proId: proId,
      },
    });
  };

  const handleDownload = (value) => {
    // const result = rfqData.find((row) => {
    //   return row.id === id;
    // });
    // setDownloadData(result);
    // setOpenDownload(true);
  };

  // const handleClickApprove = (id) => {};

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <div className="container">
      {loader ? <LoaderComponent /> : null}
      {/* <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </Modal> */}
      {/* <BreadCrumbs
        pathList={paths}
        obj={{
          proId: proId,
          budget_id: budget_id,
          // budget_id: location.state.budgetId,
        }}
      /> */}
      <div className="mb-2 mt-3">
        <h3 className=""> {"RFQ"} </h3>
        <BreadCrumbs
          pathList={paths}
          obj={{
            proId: proId,
            budget_id: budget_id,
            
            // budget_id: location.state.budgetId,
          }}
        />
        {alertBox && (
          <AlertMessage
            title={"Confirmation"}
            message={"Do you wants to delete ?"}
            onChangeCancel={handleChangeCancel}
          />
        )}
      </div>
      <div className="RfqbudgetTable">
        <RFQquotation
          proId={proId}
          budget_id={budget_id}
          data={rfqData}
          // placeholder={RfqPlaceyholder}
          mapableData={mapableDataRFQ}
          searchProp={["vendors"]}
          handleVisible={handleVisible}
          handleDownload={handleDownload}
        />
      </div>
    </div>
  );
};

export default SearchRfq;
