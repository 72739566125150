export const GET_FUNCTIONALITY = "Get All Functionality";
export const CREATE_FUNCTIONALITY = "Create Functionality";
export const UPDATE_FUNCTIONALITY = "Update Functionality";
export const CHANGE_STATUS_FUNCTIONALITY = "Change Status Functionality";

export const GET_COUNTRY_FUNCTION = "Get All Country";
export const CREATE_COUNTRY_FUNCTION = "Create Country";
export const UPDATE_COUNTRY_FUNCTION = "Update Country";
export const CHANGE_COUNTRY_STATUS_FUNCTION = "Change Status Country";

export const CHANGE_CLIENT_TYPE_STATUS_FUNCTION =
  "Change Status of Client Type";
export const GET_CLIENT_TYPE_FUNCTION = "Get All Client Type";
export const CREATE_CLIENT_TYPE_FUNCTION = "Create Client Type";
export const UPDATE_CLIENT_TYPE_FUNCTION = "Update Client Type";
export const GET_ALL_ACTIVE_CLIENT_TYPE = "Get All Active Client Type";
export const GET_CLIENT_TYPE_BY_ID = "Get Client Type By Id";

export const GET_CURRENCY_FUNCTION = "Get All Currency";
export const CREATE_CURRENCY_FUNCTION = "Create Currency";
export const UPDATE_CURRENCY_FUNCTION = "Update Currency";
export const CHANGE_CURRENCY_STATUS_FUNCTION = "Change Status Currency";
export const GET_ACTIVE_CURRENCY_FUNCTION = "Get All Active Currency";
export const GET_CURRENCY_BY_ID_FUNCTION = "Get Currency By Id";

export const GET_BUDGET_SCOPE = "Get All Budget Scope";
export const CREATE_BUDGET_SCOPE = "Create Budget Scope";
export const UPDATE_BUDGET_SCOPE = "Update Budget Scope";
export const CHANGE_BUDGET_SCOPE_STATUS = "Change Status Budget Scope";
export const GET_ACTIVE_BUDGET_SCOPE = "Get All Active Budget Scope";

export const GET_MOP_FUNCTION = "Get All Mode Of Payment";
export const GET_ACTIVE_MOP_FUNCTION = "Get All Active Mode Of Payment";
export const CREATE_MOP_FUNCTION = "Create Mode Of Payment";
export const UPDATE_MOP_FUNCTION = "Update Mode Of Payment";
export const CHANGE_MOP_STATUS_FUNCTION = "Change Status of Mode Of Payment";
export const GET_MOP_BY_ID_FUNCTION = "Get Mode Of Payment By Id";

export const GET_USER_MANAGEMENT_FUNCTION = "Get All User";
export const GET_ACTIVE_USER_MANAGEMENT_FUNCTION = "Get All Active User";
export const CREATE_USER_MANAGEMENT_FUNCTION = "Create User";
export const UPDATE_USER_MANAGEMENT_FUNCTION = "Update User";
export const CHANGE_USER_MANAGEMENT_STATUS_FUNCTION = "Change Status of User";
export const GET_USER_MANAGEMENT_BY_ID_FUNCTION = "Get User By Id";

export const GET_VENDOR_CATEGORY_FUNCTION = "Get All Vendor Category";
export const GET_ACTIVE_VENDOR_CATEGORY_FUNCTION =
  "Get All Active Vendor Category";
export const CREATE_VENDOR_CATEGORY_FUNCTION = "Create Vendor Category";
export const UPDATE_VENDOR_CATEGORY_FUNCTION = "Update Vendor Category";
export const CHANGE_VENDOR_CATEGORY_STATUS_FUNCTION =
  "Change Status of Vendor Category";
export const GET_VENDOR_CATEGORY_BY_ID_FUNCTION = "Get Vendor Category By Id";

export const GET_COMMODITY_FUNCTION = "Get All Commodity";
export const GET_ACTIVE_COMMODITY_FUNCTION = "Get All Active Commodity";
export const CREATE_COMMODITY_FUNCTION = "Create Commodity";
export const UPDATE_COMMODITY_FUNCTION = "Update Commodity";
export const CHANGE_COMMODITY_STATUS_FUNCTION = "Change Status of Commodity";
export const GET_COMMODITY_BY_ID_FUNCTION = "Get Commodity By Id";

export const GET_CATEGORY_PRODUCT_MAPPING_FUNCTION =
  "Get All Product Category Sub Category Mapping";
export const CREATE_CATEGORY_PRODUCT_MAPPING_FUNCTION =
  "Create Product Category Sub Category Mapping";
export const UPDATE_CATEGORY_PRODUCT_MAPPING_FUNCTION =
  "Update Product Category Sub Category Mapping";
export const GET_CATEGORY_PRODUCT_MAPPING_BY_ID_FUNCTION =
  "Get Product Category Sub Category Mapping By Id";

export const GET_MODE_OF_SHIPMENT = "Get All Shipment Mode";
export const GET_ACTIVE_MODE_OF_SHIPMENT = "Get All Active Shipment Mode";
export const CREATE_MODE_OF_SHIPMENT = "Create Shipment Mode";
export const UPDATE_MODE_OF_SHIPMENT = "Update Shipment Mode";
export const CHANGE_MODE_OF_SHIPMENT = "Change Status of Shipment Mode";
export const GET_MODE_OF_SHIPMENT_BY_ID = "Get Shipment Mode By Id";

export const GET_ACCESS_CONSIDERATION = "Get All Accessed Consideration";
export const GET_ACTIVE_ACCESS_CONSIDERATION =
  "Get All Active Accessed Consideration";
export const CREATE_ACCESS_CONSIDERATION = "Create Accessed Consideration";
export const UPDATE_ACCESS_CONSIDERATION = "Update Accessed Consideration";
export const CHANGE_STATUS_ACCESS_CONSIDERATION =
  "Change Status of Accessed Consideration";
export const GET_ACCESS_CONSIDERATION_BY_ID =
  "Get Accessed Consideration By Id";

export const GET_PAYMENT_TERMS = "Get All Payment Terms";
export const GET_ACTIVE_PAYMENT_TERMS = "Get All Active Payment Terms";
export const CREATE_PAYMENT_TERMS = "Create Payment Terms";
export const UPDATE_PAYMENT_TERMS = "Update Payment Terms";
export const CHANGE_STATUS_PAYMENT_TERMS = "Change Status of Payment Terms";
export const GET_PAYMENT_TERMS_BY_ID = "Get Payment Terms By Id";

export const GET_ITEM_GROUP = "Get All Item Group";
export const GET_ACTIVE_ITEM_GROUP = "Get All Active Item Group";
export const CREATE_ITEM_GROUP = "Create Item Group";
export const UPDATE_ITEM_GROUP = "Update Item Group";
export const CHANGE_STATUS_ITEM_GROUP = "Change Status of Item Group";
export const GET_ITEM_GROUP_BY_ID = "Get Item Group By Id";

export const GET_ESTABLISHMENT_ITEM_CATEGORY =
  "Get All Establishment To Item Category Mapping";
export const CREATE_ESTABLISHMENT_ITEM_CATEGORY =
  "Create Establishment To Item Category Mapping";
export const UPDATE_ESTABLISHMENT_ITEM_CATEGORY =
  "Update Establishment To Item Category Mapping";
export const GET_ESTABLISHMENT_ITEM_CATEGORY_BY_ID =
  "Get Establishment To Item Category Mapping By Id";

export const GET_ITEM_SUB_CATEGORY = "Get All Item Sub Category";
export const GET_ACTIVE_ITEM_SUB_CATEGORY = "Get All Active Item Sub Category";
export const CREATE_ITEM_SUB_CATEGORY = "Create Item Sub Category";
export const UPDATE_ITEM_SUB_CATEGORY = "Update Item Sub Category";
export const CHANGE_STATUS_ITEM_SUB_CATEGORY =
  "Change Status of Item Sub Category";
export const GET_ITEM_SUB_CATEGORY_BY_ID = "Get Item Sub Category By Id";

export const GET_PAYMENT_PROGRESS = "Get All Payment Progress";
export const GET_ACTIVE_PAYMENT_PROGRESS = "Get All Active Payment Progress";
export const CREATE_PAYMENT_PROGRESS = "Create Payment Progress";
export const UPDATE_PAYMENT_PROGRESS = "Update Payment Progress";
export const CHANGE_STATUS_PAYMENT_PROGRESS =
  "Change Status of Payment Progress";
export const GET_PAYMENT_PROGRESS_BY_ID = "Get Payment Progress By Id";

export const GET_BUDGET_ITEM_CATEGORY_MAPPING =
  "Get All Budget Item Category  mapping";
export const CREATE_BUDGET_ITEM_CATEGORY_MAPPING =
  "Create Budget Item Category  mapping";
export const UPDATE_BUDGET_ITEM_CATEGORY_MAPPING =
  "Update Budget Item Category  mapping";
export const GET_BUDGET_ITEM_CATEGORY_BY_ID_MAPPING =
  "Get Budget Item Category  mapping By Id";

export const GET_INCOTERM = "Get All Incoterm";
export const GET_ACTIVE_INCOTERM = "Get All Active Incoterm";
export const CREATE_INCOTERM = "Create Incoterm";
export const UPDATE_INCOTERM = "Update Incoterm";
export const CHANGE_STATUS_INCOTERM = "Change Status of Incoterm";
export const GET_INCOTERM_BY_ID = "Get Incoterm By Id";

export const GET_PUBLIC_AREA = "Get All Public Area";
export const GET_ACTIVE_PUBLIC_AREA = "Get All Active Public Area";
export const CREATE_PUBLIC_AREA = "Create Public Area";
export const UPDATE_PUBLIC_AREA = "Update Public Area";
export const CHANGE_STATUS_PUBLIC_AREA = "Change Status of Public Area";
export const GET_PUBLIC_AREA_BY_ID = "Get Public Area By Id";

export const GET_REQUIRED_SUBMITTALS = "Get All Required Submittals";
export const GET_ACTIVE_REQUIRED_SUBMITTALS =
  "Get All Active Required Submittals";
export const CREATE_REQUIRED_SUBMITTALS = "Create Required Submittals";
export const UPDATE_REQUIRED_SUBMITTALS = "Update Required Submittals";
export const CHANGE_STATUS_REQUIRED_SUBMITTALS =
  "Change Status of Required Submittals";
export const GET_REQUIRED_SUBMITTALS_BY_ID = "Get Required Submittals By Id";

export const GET_ROLE = "Get All Role";
export const GET_ACTIVE_ROLE = "Get All Active Role";
export const CREATE_ROLE = "Create Role";
export const UPDATE_ROLE = "Update Role";
export const CHANGE_STATUS_ROLE = "Change Status of Role";
export const GET_ROLE_BY_ID = "Get Role By Id";

export const GET_MODULE = "Get All Module";
export const GET_ACTIVE_MODULE = "Get All Active Module";
export const CREATE_MODULE = "Create Module";
export const UPDATE_MODULE = "Update Module";
export const CHANGE_STATUS_MODULE = "Change Status of Module";
export const GET_MODULE_BY_ID = "Get Module By Id";

export const GET_ROLE_FUNCTIONALITY_MAP = "Get All Role Functionality Mapping";
export const GET_ROLE_FUNCTIONALITY_MAP_BY_ID =
  "Get Role Functionality Mapping By Id";
export const CREATE_ROLE_FUNCTIONALITY_MAP =
  "Create Role Functionality Mapping";
export const UPDATE_ROLE_FUNCTIONALITY_MAP =
  "Update Role Functionality Mapping";

// export const CREATE_USERTYPE_FUNCTION = "Create Establishment";
export const CREATE_ESTABLISHMENT_FUNCTION = "Create Establishment";
export const UPDATE_ESTABLISHMENT_FUNCTION = "Update Establishment";
export const GET_ACTIVE_ESTABLISHMENT = "Get All Active Establishment";
export const GET_ESTABLISHMENT_SCOPE = "Get All Establishment";
export const GET_ESTABLISHMENT_SCOPE_BY_ID = "Get Establishment By Id";
export const CHANGE_ESTABLISHMENT_STATUS_FUNCTION =
  "Change Status of Establishment";

export const CREATE_SCHEDULE_STEPS = "Create Schedule";
export const UPDATE_SCHEDULE_STEPS = "Update Schedule";
export const GET_ACTIVE_SCHEDULE_STEPS = "Get All Active Schedule";
export const GET_SCHEDULE_STEPS = "Get All Schedule";
export const GET_SCHEDULE_STEPS_BY_ID = "Get Schedule By Id";
export const CHANGE_SCHEDULE_STEPS_STATUS_FUNCTION =
  "Change Status of Schedule";

export const CREATE_STAGE = "Create Stage";
export const UPDATE_STAGE = "Update Stage";
export const GET_ACTIVE_STAGE = "Get All Active Stage";
export const GET_STAGE = "Get All Stage";
export const GET_STAGE_BY_ID = "Get Stage By Id";
export const CHANGE_STAGE_STATUS_FUNCTION = "Change Status of Stage";

export const CREATE_PROJECT_SCOPE = "Create Project Scope";
export const UPDATE_PROJECT_SCOPE = "Update Project Scope";
export const GET_ACTIVE_PROJECT_SCOPE = "Get All Active Project Scope";
export const GET_PROJECT_SCOPE = "Get All Project Scope";
export const GET_PROJECT_SCOPE_BY_ID = "Get Project Scope By Id";
export const CHANGE_PROJECT_SCOPE_STATUS_FUNCTION =
  "Change Status of Project Scope";

export const CREATE_VENDOR_COMPANY_TYPE = "Create Company Type";
export const UPDATE_VENDOR_COMPANY_TYPE = "Update Company Type";
export const GET_ACTIVE_VENDOR_COMPANY_TYPE = "Get All Active Company Type";
export const GET_VENDOR_COMPANY_TYPE = "Get All Company Type";
export const GET_VENDOR_COMPANY_TYPE_BY_ID = "Get Company Type By Id";
export const CHANGE_VENDOR_COMPANY_TYPE_STATUS_FUNCTION =
  "Change Status of Company Type";

export const CREATE_VENDOR_SUB_CATEGORY = "Create Vendor Sub Category";
export const UPDATE_VENDOR_SUB_CATEGORY = "Update Vendor Sub Category";
export const GET_ACTIVE_VENDOR_SUB_CATEGORY =
  "Get All Active Vendor Sub Category";
export const GET_VENDOR_SUB_CATEGORY = "Get All Vendor Sub Category";
export const GET_VENDOR_SUB_CATEGORY_BY_ID = "Get Vendor Sub Category By Id";
export const CHANGE_VENDOR_SUB_CATEGORY_STATUS_FUNCTION =
  "Change Status of Vendor Sub Category";

export const CREATE_ROOM_TYPE = "Create Room Type";
export const UPDATE_ROOM_TYPE = "Update Room Type";
export const GET_ACTIVE_ROOM_TYPE = "Get All Active Room Type";
export const GET_ROOM_TYPE = "Get All Room Type";
export const GET_ROOM_TYPE_BY_ID = "Get Room Type By Id";
export const CHANGE_ROOM_TYPE_STATUS_FUNCTION = "Change Status of Room Type";

export const CREATE_UOM = "Create UOM";
export const UPDATE_UOM = "Update UOM";
export const GET_ACTIVE_UOM = "Get All Active UOM";
export const GET_UOM = "Get All UOM";
export const GET_UOM_BY_ID = "Get UOM By Id";
export const CHANGE_UOM_STATUS_FUNCTION = "Change Status of UOM";

export const CREATE_LOCATION = "Create Location";
export const UPDATE_LOCATION = "Update Location";
export const GET_ACTIVE_LOCATION = "Get All Active Location";
export const GET_LOCATION = "Get All Location";
export const GET_LOCATION_BY_ID = "Get Location By Id";
export const CHANGE_LOCATION_STATUS_FUNCTION = "Change Status of Location";

export const CREATE_MODE_OF_TRANSPORTATION = "Create Transportation Mode";
export const UPDATE_MODE_OF_TRANSPORTATION = "Update Transportation Mode";
export const GET_ACTIVE_MODE_OF_TRANSPORTATION =
  "Get All Active Transportation Mode";
export const GET_MODE_OF_TRANSPORTATION = "Get All Transportation Mode";
export const GET_MODE_OF_TRANSPORTATION_BY_ID = "Get Transportation Mode By Id";
export const CHANGE_MODE_OF_TRANSPORTATION_STATUS_FUNCTION =
  "Change Status of Transportation Mode";

export const CREATE_PAYMENT_AGAINST = "Create Final Payment Against";
export const UPDATE_PAYMENT_AGAINST = "Update Final Payment Against";
export const GET_ACTIVE_PAYMENT_AGAINST =
  "Get All Active Final Payment Against";
export const GET_PAYMENT_AGAINST = "Get All Final Payment Against";
export const GET_PAYMENT_AGAINST_BY_ID = "Get Final Payment Against By Id";
export const CHANGE_PAYMENT_AGAINST_STATUS_FUNCTION =
  "Change Status of Final Payment Against";

export const CREATE_ITEM_SUB_GROUP = "Create Item Sub Group";
export const UPDATE_ITEM_SUB_GROUP = "Update Item Sub Group";
export const GET_ACTIVE_ITEM_SUB_GROUP = "Get All Active Item Sub Group";
export const GET_ITEM_SUB_GROUP = "Get All Item Sub Group";
export const GET_ITEM_SUB_GROUP_BY_ID = "Get Item Sub Group By Id";
export const CHANGE_ITEM_SUB_GROUP_STATUS_FUNCTION =
  "Change Status of Item Sub Group";

export const CREATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING =
  "Create Item Category Sub Category Mapping";
export const UPDATE_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING =
  "Update Item Category Sub Category Mapping";
export const GET_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING =
  "Get All Item Category Sub Category Mapping";
export const GET_ITEM_CATEGORY_TO_SUB_CATEGORY_MAPPING_BY_ID =
  "Get Item Category Sub Category Mapping By Id";

export const CREATE_USER_TYPE = "Create User Type";
export const UPDATE_USER_TYPE = "Update User Type";
export const GET_ACTIVE_USER_TYPE = "Get All Active User Type";
export const GET_USER_TYPE = "Get All User Type";
export const GET_USER_TYPE_BY_ID = "Get User Type By Id";
export const CHANGE_USER_TYPE_STATUS_FUNCTION = "Change Status of User Type";

export const CREATE_ITEM_CATEGORY = "Create Item Category";
export const UPDATE_ITEM_CATEGORY = "Update Item Category";
export const GET_ACTIVE_ITEM_CATEGORY = "Get All Active Item Category";
export const GET_ITEM_CATEGORY = "Get All Item Category";
export const GET_ITEM_CATEGORY_BY_ID = "Get Item Category By Id";
export const CHANGE_ITEM_CATEGORY_STATUS_FUNCTION =
  "Change Status of Item Category";

export const CREATE_REQUIRED_DOCUMENT = "Create Required Document";
export const UPDATE_REQUIRED_DOCUMENT = "Update Required Document";
export const GET_ACTIVE_REQUIRED_DOCUMENT = "Get All Active Required Document";
export const GET_REQUIRED_DOCUMENT = "Get All Required Document";
export const GET_REQUIRED_DOCUMENT_BY_ID = "Get Required Document By Id";
export const CHANGE_REQUIRED_DOCUMENT_STATUS_FUNCTION =
  "Change Status of Required Document";

export const CREATE_VENDOR = "Create Vendor";
export const UPDATE_VENDOR = "Update Vendor";
export const GET_ACTIVE_VENDOR = "Get All Active Vendor";
export const GET_VENDOR = "Get All Vendor";
export const GET_VENDOR_BY_ID = "Get Vendor By Id";
export const CHANGE_VENDOR_STATUS_FUNCTION = "Change Status of Vendor";

export const CREATE_CLIENT = "Create Client";
export const UPDATE_CLIENT = "Update Client";
export const GET_ACTIVE_CLIENT = "Get All Active Client";
export const GET_CLIENT = "Get All Client";
export const GET_CLIENT_BY_ID = "Get Client By Id";
export const CHANGE_CLIENT_STATUS_FUNCTION = "Change Status of Client";

export const CREATE_PRODUCT_AND_SERVICES = "Create Product Services";
export const UPDATE_PRODUCT_AND_SERVICES = "Update Product Services";
export const GET_ACTIVE_PRODUCT_AND_SERVICES =
  "Get All Active Product Services";
export const GET_PRODUCT_AND_SERVICES = "Get All Product Services";
export const GET_PRODUCT_AND_SERVICES_BY_ID = "Get Product Services By Id";
export const CHANGE_PRODUCT_AND_SERVICES_STATUS_FUNCTION =
  "Change Status of Product Services";

export const CREATE_VENDOR_CATEGORY_TO_SUB_CATEGORY =
  "Create Vendor Category Sub Category Mapping";
export const UPDATE_VENDOR_CATEGORY_TO_SUB_CATEGORY =
  "UpdateVendor Category Sub Category Mapping";
export const GET_VENDOR_CATEGORY_TO_SUB_CATEGORY =
  "Get All Vendor Category Sub Category Mapping";
export const GET_VENDOR_CATEGORY_TO_SUB_CATEGORY_BY_ID =
  "Get Vendor Category Sub Category Mapping By Id";

export const CREATE_PROJECT_ROLE = "Create Project Role";
export const UPDATE_PROJECT_ROLE = "Update Project Role";
export const GET_ACTIVE_PROJECT_ROLE = "Get All Active Project Role";
export const GET_PROJECT_ROLE = "Get All Project Role";
export const GET_PROJECT_ROLE_BY_ID = "Get Project Role By Id";
export const CHANGE_PROJECT_ROLE_STATUS_FUNCTION =
  "Change Status of Project Role";

export const CREATE_USER_ROLE_MAPPING = "Create Role Identity Mapping";
export const UPDATE_USER_ROLE_MAPPING = "Update Role Identity Mapping";
export const GET_USER_ROLE_MAPPING = "Get All Role Identity Mapping";
export const GET_USER_ROLE_MAPPING_BY_ID = "Get Role Identity Mapping By Id";

export const CREATE_PROJECT = "Create Project";
export const UPDATE_PROJECT = "Update Project";
export const GET_ACTIVE_PROJECT = "Get All Active Project";
export const GET_PROJECT = "Get All Project";
export const GET_PROJECT_BY_ID = "Get Project By Id";
export const CHANGE_PROJECT_STATUS_FUNCTION = "Change Status of Project";

export const CREATE_BUDGET = "Create Budget";
export const UPDATE_BUDGET = "Update Budget";
export const GET_ACTIVE_BUDGET = "Get All Active Budget";
export const GET_BUDGET = "Get All Budget";
export const GET_BUDGET_BY_ID = "Get Budget By Id";
export const CHANGE_BUDGET_STATUS_FUNCTION = "Change Status of Budget";

export const CREATE_BUDGET_ITEM = "Create Budget Item";
export const UPDATE_BUDGET_ITEM = "Update Budget Item";
export const GET_ACTIVE_BUDGET_ITEM = "Get All Active Budget Item";
export const GET_BUDGET_ITEM = "Get All Budget Item";
export const GET_BUDGET_ITEM_BY_ID = "Get Budget Item By Id";
export const CHANGE_BUDGET_ITEM_STATUS_FUNCTION =
  "Change Status of Budget Item";

export const CREATE_RFI = "Create Request For Information";
export const UPDATE_RFI = "Update Request For Information";
export const GET_ACTIVE_RFI = "Get All Active Request For Information";
export const GET_RFI = "Get All Request For Information";
export const GET_RFI_BY_ID = "Get Request For Information By Id";
export const CHANGE_RFI_STATUS_FUNCTION =
  "Change Status of Request For Information";

export const CREATE_RFQ = "Create Request For Quotation";
export const UPDATE_RFQ = "Update Request For Quotation";
export const GET_ACTIVE_RFQ = "Get All Active Request For Quotation";
export const GET_RFQ = "Get All Request For Quotation";
export const GET_RFQ_BY_ID = "Get Request For Quotation By Id";
export const CHANGE_RFQ_STATUS_FUNCTION =
  "Change Status of Request For Quotation";

export const CREATE_QUOTATION = "Create Quotation";
export const UPDATE_QUOTATION = "Update Quotation";
export const GET_ACTIVE_QUOTATION = "Get All Active Quotation";
export const GET_QUOTATION = "Get All Quotation";
export const GET_QUOTATION_BY_ID = "Get Quotation By Id";
export const CHANGE_QUOTATION_STATUS_FUNCTION = "De Activate of Quotation";

export const CREATE_PURCHASE_ORDER = "Create Purchase Order";
export const UPDATE_PURCHASE_ORDER = "Update Purchase Order";
export const GET_ACTIVE_PURCHASE_ORDER = "Get All Active Purchase Order";
export const GET_PURCHASE_ORDER = "Get All Purchase Order";
export const GET_PURCHASE_ORDER_BY_ID = "Get Purchase Order By Id";
export const CHANGE_PURCHASE_ORDER_STATUS_FUNCTION =
  "Change Status of Purchase Order";

export const CREATE_INVOICE = "Create Invoice";
export const UPDATE_INVOICE = "Update Invoice";
export const GET_ACTIVE_INVOICE = "Get All Active Invoice";
export const GET_INVOICE = "Get All Invoice";
export const GET_INVOICE_BY_ID = "Get Invoice By Id";
export const CHANGE_INVOICE_STATUS_FUNCTION = "Change Status of Invoice";
export const DELETE_INVOICE = "Delete Invoice";

export const CREATE_INVOICE_PAYMENT = "Create Invoice Payment";
export const UPDATE_INVOICE_PAYMENT = "Update Invoice Payment";
export const GET_ACTIVE_INVOICE_PAYMENT = "Get All Active Invoice Payment";
export const GET_INVOICE_PAYMENT = "Get All Invoice Payment";
export const GET_INVOICE_BY_ID_PAYMENT = "Get Invoice Payment By Id";
export const CHANGE_INVOICE_STATUS_FUNCTION_PAYMENT =
  "Change Status of Invoice Payment";

export const CREATE_ITEM_SCHEDULE = "Create Item Schedule";
export const UPDATE_ITEM_SCHEDULE = "Update Item Schedule";
export const GET_ACTIVE_ITEM_SCHEDULE = "Get All Active Item Schedule";
export const GET_ITEM_SCHEDULE = "Get All Item Schedule";
export const GET_ITEM_SCHEDULE_BY_ID = "Get Item Schedule By Id";
export const CHANGE_ITEM_SCHEDULE_FUNCTION = "De Activate of Item Schedule";

export const CREATE_BUDGET_SCHEDULE = "Create Budget Schedule";
export const UPDATE_BUDGET_SCHEDULE = "Update Budget Schedule";
export const GET_ACTIVE_BUDGET_SCHEDULE = "Get All Active Budget Schedule";
export const GET_BUDGET_SCHEDULE = "Get All Budget Schedule";
export const GET_BUDGET_SCHEDULE_BY_ID = "Get Budget Schedule By Id";
export const CHANGE_BUDGET_SCHEDULE_FUNCTION = "De Activate of Budget Schedule";

export const ONGOING_PROJECT = "Ongoing Project";
export const CANCEL_PROJECT = "Cancel Project";
export const COMPLETED_PROJECT = "Completed Project";

export const SHOW_GENERAL_SETTING = "Show General Setting";
export const SHOW_MASTER_CONFIGURATION = "Show Master Configuration";

export const CREATE_COST_ANALYSIS = "Create Cost Analysis";

export const GET_ALL_OPERATOR = "Get All Operator";
export const CREATE_ALL_OPERATOR = "Create Operator";
export const UPDATE_ALL_OPERATOR = "Update Operator";

export const SHOW_VENDOR_RFQ = "Get Vendor Rfq";
export const SHOW_VENDOR_QUOTATION = "Get Vendor Quotation";
export const SHOW_VENDOR_PO = "Get Vendor PO";
export const SHOW_VENDOR_PAYMENT = "Get Vendor Payment";