/* eslint-disable no-unused-vars */
import React from "react";
import swal from "sweetalert";
import { InvoiceFileUploads } from "../../../axios";
import { Button } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const InvoiceFileUpload = (props) => {
  const {
    errorMessage,
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.selectedFile ? initialValue.selectedFile : "",
    error: false,
    success: false,
  });


  const handleChangeFile = (e) => {
    setSelectedFile(e.target.value);
  };

  const handleSaveData = () => {
    const filename = selectedFile.split("\\").pop().split("/").pop();
    const formData = {
      file_path: [filename],
    };
    InvoiceFileUploads(formData)
      .then((res) => {
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch(() => {
      });
  };

  let isValueNOTChanged = selectedFile.value === initialValue.selectedFile;

  let disable =
    selectedFile.error ||
    selectedFile.value === "" ||
    selectedFile.success === false ||
    isValueNOTChanged;

  return (
    <div className="modalFormContainer">
      <h2 className="mb-3">{"Upload File"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-sm-8">
          <input
            type="file"
            value={selectedFile.value}
            onChange={handleChangeFile}
            accept=".csv, .xlsx"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          onClick={handleSaveData}
        />
      </div>
    </div>
  );
};

export default InvoiceFileUpload;
