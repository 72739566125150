import "../../../styles/CustomCard.css";

const CustomCard = (props) => {
  return (
    <div
      className="customcarddiv "
      style={{
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      {props.children}
    </div>
  );
};

export default CustomCard;
