import React from "react";
import { ItemGroupForm } from "../../../forms";
import { useLocation } from "react-router-dom";

const ItemGroupPage = (props) => {
  const { handleChangeFormData = () => {} } = props;
  const location = useLocation();
  const initialValue = location.state.initialValue;
  const action = location.state.actionType;
  const handleData = (value) => {
    handleChangeFormData(value);
  };

  return (
    <div>
      <ItemGroupForm
        actionType={action}
        initialValue={initialValue}
        handleChangeFormData={handleData}
      />
    </div>
  );
};

export default ItemGroupPage;
