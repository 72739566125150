import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogContentText } from '@material-ui/core';
import { Style } from './style';
import { Button } from '..'
import { colors } from '../../configs';
import { FormTextInput} from "../../components";

export default function AlertMessageWithFeedback(props) {

  const {
    title,
    message,
    titleStyle,
    contentTextStyle,
    onChangeCancel,
    onChangeSucess,
    setClientRemark,
  } = props

  const [open, setOpen] = React.useState(true);
  const [remark, setRemark] = React.useState({
    value: "",
    error: false,
    success: false,
  });


  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false)
    onChangeCancel()
  }

  const handleSuccess = () => {
    setOpen(false)
    onChangeSucess()
  }

  const handleChangeRemark = (value) => {
    setRemark(value.value);
    setClientRemark(value.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown onBackdropClick={()=>{handleCancel()}}>
        <DialogTitle style={Object.assign({}, Style.alertMessageTitleStyle, titleStyle)}>{title}</DialogTitle>
        <DialogContent >
          <DialogContentText style={Object.assign({}, Style.alertMessageContentTextStyle, contentTextStyle)}>
            {message}
          </DialogContentText>
          <FormTextInput
            labelTextStyle={{
              marginTop: "5px",
            }}
            type="textarea"
            label={"Remark"}
            placeholder={"Enter Remark"}
            Value={remark.value}
            onChangeText={handleChangeRemark}
            Validate={false}
            multiline={true}
            // starProps={true}
            editable
        />
        </DialogContent>
        <DialogActions>
          <Button buttonName={"Cancel"} onClick={handleCancel} buttonStyle={{ backgroundColor: colors.darkgrey }}></Button>
          <Button buttonName={"Ok"} onClick={handleSuccess} buttonStyle={{ backgroundColor: colors.primary }}></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
