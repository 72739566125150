/* eslint-disable no-unused-vars */
import _ from "lodash";
import React from "react";
import {
  GetAllActiveVendorCategory,
  GetAllActiveVendorSubCategory,
} from "../../../axios";
import { Button, FormCheckBox, FormDropDown } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateVendorCategoryToSubCategoryForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleResultData,
    actionType,
    initialValue = {},
    handleModalClose,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [initialSubCategory, setInititalSubCategory] = React.useState(
    initialValue.subCategory ? [...initialValue.subCategory] : []
  );

  const [subCategory, setSubCategory] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [selectData, setSelectData] = React.useState({
    key: initialValue.id ? initialValue.id : "",
    value: initialValue.id ? initialValue.id : "",
    error: false,
    success: false,
  });

  const getAllActiveVendorCategory = () => {
    setLoading(true);
    GetAllActiveVendorCategory()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.category,
        }));
        setCategory(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAllActiveVendorCategory();
  }, []);

  React.useEffect(() => {
    if (actionType === "add") {
      setLoading(true);
      GetAllActiveVendorSubCategory()
        .then((res) => {
          let allData = res.data.data;
          const result = allData.map((elm) => ({
            key: elm.id,
            subCategoryName: elm.sub_category,
            checked: false,
          }));
          setLoading(false);
          setSubCategory(result);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [actionType]);
  React.useEffect(() => {
    if (actionType === "edit") {
      setLoading(true);
      const arr = initialValue.subcategories;
      const checkArr = { checked: true };
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var sub_id_list = [];
      const result = arr.map((ele) => sub_id_list.push(ele.id));
      GetAllActiveVendorSubCategory()
        .then((res) => {
          setLoading(false);
          const fetchData = res.data.data.map((elm) => ({
            key: elm.id,
            subCategoryName: elm.sub_category,
            checked: sub_id_list.includes(elm.id) ? true : false,
          }));
          setSubCategory(fetchData);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [actionType]);

  const handleChangeCheck = (key) => {
    setMessage(null);
    const result = subCategory.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setSubCategory(result);
  };

  const handleSelectDropDown = (id) => {
    setMessage(null);
    setSelectData(id);
  };

  const functionResultTrue = () => {
    const resultArray = [];
    subCategory.forEach((item) => {
      if (item.checked === true) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      category_id: selectData.value,
      sub_category_ids: functionResultTrue(subCategory),
    };
    handleResultData(formData);
  };

  const disable =
    selectData.value === "" ||
    selectData.error ||
    selectData.success === false ||
    subCategory.every((functionName) => {
      return !functionName.checked;
    });

  const editDisable =
    initialValue.name === selectData.value &&
    _.isEqual(subCategory, initialSubCategory);

  const pageHeader = Strings["VendorCategoryMapping.CreateMapping"],
    editpageHeader = Strings["VendorCategoryMapping.EditMapping"],
    dropdownLabel = Strings["VendorCategoryMapping.SelectCategory"],
    dropdownCustomError = Strings["VendorCategoryMapping.CategoryCustomError"],
    selectText = Strings["VendorCategoryMapping.SelectCategoryPlaceHolder"],
    selctRoleHeader = Strings["VendorCategoryMapping.SelectSubCategories"],
    SaveButton = Strings["VendorCategoryMapping.SaveBtn"],
    cancelButton = Strings["VendorCategoryMapping.CancelBtn"];

  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? pageHeader : editpageHeader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormDropDown
        selectText={selectText}
        starProps={true}
        isValidate={true}
        Label={dropdownLabel}
        Options={category}
        onSelect={handleSelectDropDown}
        customErrorLine={dropdownCustomError}
        searchable={false}
        value={selectData.value}
        disable={actionType !== "add" ? true : false}
      />
      <br />
      <h4>
        {selctRoleHeader}
        <span className="text-danger">*</span>
      </h4>
      <div className={"row"}>
        {subCategory.map((functionName, index) => {
          return (
            <div className={"col-12 col-md-4"} key={index}>
              <FormCheckBox
                starProps={true}
                key={functionName.key}
                Label={functionName.subCategoryName}
                checked={functionName.checked}
                handleChangeCheck={() => handleChangeCheck(functionName.key)}
              />
            </div>
          );
        })}
      </div>

      <div className="row deleteFunction__buttongroup">
        <div style={{ display: "flex", marginTop: "30px" }}>
          <Button
            buttonName={SaveButton}
            disable={actionType === "add" ? disable : editDisable}
            onClick={handleSaveData}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
          <Button
            buttonName={cancelButton}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateVendorCategoryToSubCategoryForm;
