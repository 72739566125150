// import moment from "moment";
import React from "react";
import { AllActiveStatusCurrency } from "../../../axios";
import {
  Button,
  DateAndTimePicker,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const InvoicePaymentForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    invoiceID,
  } = props;

  const getPaymentAmount = (row) => {
    let paidAmount = 0.00;
    if(row.invoice_payment != null){
      if(row.invoice_payment.length != 0){
       paidAmount = row.invoice_payment.reduce((acc, curr) => {
            return parseFloat(acc + parseFloat(curr.amount));
          }, 0) 
        return parseFloat(row.total_amount) - parseFloat(paidAmount)
      }
    }
    else{
      return row.total_amount;
    }
  }
  
  const paidAtCustomError = Strings["Payment.CreatePaymentAmountCustomError"],
    remarkCustomError = Strings["Country.CreatePaymentCustomError"],
    cancelButton = Strings["Country.CreateCountryCancelButton"];

  const [paidAt, setPaidAt] = React.useState({
    value: initialValue.paid_at ? initialValue.paid_at : "",
    error: false,
    success: false,
  });

  const [amount, setAmount] = React.useState({
    value: initialValue.total_amount ? getPaymentAmount(initialValue) : "",
    error: false,
    success: false,
  });

  const [remark, setRemark] = React.useState({
    value: initialValue.remarks ? initialValue.remarks : "",
    error: false,
    success: false,
  });
  const [currency, setCurrency] = React.useState({
    value: initialValue.currency_id ? initialValue.currency_id : "",
    error: false,
    success: false,
  });
  const [currencyDropDown, SetcurrencyDropDown] = React.useState([]);

  const handleChangeDate = (date) => {
    setPaidAt(date);
  };
  const handleChangeAmount = (value) => {
    setMessage(null);
    setAmount(value);
  };
  const handleChangeCurrency = (value) => {
    setCurrency(value);
  };
  const handleChangeRemark = (value) => {
    setRemark(value);
  };

 
  const getAllActiveCurrency = () => {
    AllActiveStatusCurrency()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        SetcurrencyDropDown(result);
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    getAllActiveCurrency();
  }, []);

  const handleSaveData = () => {
    const formData = {
      paid_at: paidAt.value,
      amount: amount.value,
      remarks: remark.value,
      currency_id: currency.value,
      invoice_id: invoiceID,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged =
    paidAt.value === initialValue.paidAt &&
    amount.value === initialValue.amount &&
    currency.value === initialValue.currency;
  let disable =
    paidAt.error ||
    amount.error ||
    // remark.error ||
    currency.error ||
    paidAt.value === "" ||
    amount.value === "" ||
    // remark.value === "" ||
    currency.value === "" ||
    (paidAt.success === false &&
      currency.success === false &&
      amount.success === false) ||
    isValueNOTChanged;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? "Create Payment" : "Edit Payment"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row align-items-baseline">
        <div className="col-md-4">
          <DateAndTimePicker
            dateAndTimeLabel={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            label={"Paid On "}
            hadleSetDate={handleChangeDate}
            date={paidAt.value}
            disable={false}
            starProps={true}
          />
        </div>
        <div className="col-md-8">
          <FormTextInput
            type="number"
            label={"Amount"}
            placeholder={"Enter Amount"}
            Value={amount.value}
            onChangeText={handleChangeAmount}
            Validate={true}
            CustomErrorLine={paidAtCustomError}
            editable={true}
            starProps={true}
          />
        </div>
      </div>
      <FormDropDown
        labelStyle={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        Label={"Currency"}
        Options={currencyDropDown}
        value={currency.value}
        onSelect={handleChangeCurrency}
        searchable={false}
        starProps={true}
        isValidate={true}
      />
      <FormTextInput
        labelTextStyle={{
          marginTop: "5px",
        }}
        type="textarea"
        label={"Remark"}
        placeholder={"Enter Remark"}
        Value={remark.value}
        onChangeText={handleChangeRemark}
        Validate={false}
        CustomErrorLine={remarkCustomError}
        multiline={true}
        // starProps={true}
        editable
      />
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={"Save"}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
          }}
        />
      </div>
    </div>
  );
};

export default InvoicePaymentForm;
