/* eslint-disable no-unused-vars */
import axios from "axios";
import React from "react";
import { Button, FormDropDown, FormTextInput } from "../../components";
import { colors } from "../../configs";

const AddMasterFieldForm = (props) => {
  const {
    mid,
    handleModalClose,
    actionType,
    handleSaveData = () => {},
    handleUpdateData,
    initialValue = {},
  } = props;

  const [fieldName, setFieldName] = React.useState({
    value: initialValue.display_name ? initialValue.display_name : "",
    error: false,
    success: false,
  });
  const [fieldType, setFieldType] = React.useState({
    value: initialValue.field_type ? initialValue.field_type : "",
    error: false,
    success: false,
  });

  const handleColumnName = (value) => {
    setFieldName(value);
  };

  const options = [
    {
      key: 1,
      value: "textbox",
      label: "Textbox",
    },
    {
      key: 2,
      value: "numeric",
      label: "Numeric",
    },
    {
      key: 3,
      value: "textarea",
      label: "Textarea",
    },
    {
      key: 4,
      value: "select",
      label: "Select",
    },
    {
      key: 5,
      value: "option",
      label: "Option",
    },
    {
      key: 6,
      value: "radio",
      label: "radio",
    },
    {
      key: 7,
      value: "checkbox",
      label: "Checkbox",
    },
  ];
  const handleColumnType = (value) => {
    setFieldType(value);
  };

  const convertSlug = (data) => {
    let resStr = "";
    for (let i = 0; i < data.length; i++) {
      if (data[i] === " ") {
        resStr += "_";
      } else {
        resStr += data[i].toUpperCase();
      }
    }
    return resStr;
  };

  const handleData = () => {
    const formData = {
      slug: convertSlug(fieldName.value),
      language_code: "en-us",
      type_id: mid,
      display_name: fieldName.value,
      field_type: fieldType.value,
      validation: "role",
    };
    handleSaveData(formData);

    // setFieldName({
    //     value: "",
    //     error: false,
    //     success: false,
    // })
    // setFieldType({
    //     value: "",
    //     error: false,
    //     success: false,
    // })
  };

  const url = `${process.env.REACT_APP_BASE_URL}/furnish/project/public/api/en/master/updateField`;
  const handleUpdate = () => {
    const updateFormData = {
      slug: initialValue.slug,
      type_id: initialValue.type_id,
      display_name: fieldName.value,
      field_type: fieldType.value,
      validation: initialValue.validation,
    };
    axios
      .put(url, updateFormData)
      .then(() => {
      })
      .catch(() => {
      });
  };

  return (
    <>
      <h1>{actionType === "add" ? "ADD FIELD" : "EDIT FIELD"}</h1>
      <div>
        <FormTextInput
          type="text"
          label={"Field Name"}
          placeholder={"Enter Field Name"}
          Value={fieldName.value}
          onChangeText={handleColumnName}
          Validate={true}
          CustomErrorLine={"Enter Proper Name"}
        />
        <FormDropDown
          Label={"Field Type"}
          Options={options}
          onSelect={handleColumnType}
          customErrorLine={"Enter Existing Field Type"}
          searchable={false}
          value={fieldType.value}
          placeholder="Search"
          // disable={actionType !== 'add' ? true : false}
        />
      </div>

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? "SAVE" : "UPDATE"}
          buttonStyle={{ backgroundColor: colors.primary }}
          // disable={disable}
          onClick={actionType === "add" ? handleData : handleUpdate}
        />

        <Button
          buttonName={"Cancel"}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={handleModalClose}
        />
      </div>
    </>
  );
};

export default AddMasterFieldForm;
