import React, { useState,useEffect } from "react";
import {
  GetActiveProjectRole,
  GetClientSpecificUser,
  GetProjectSpecificUsers
} from "../../../axios";
import { Button, FormDropDown,FormCheckBox } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import { Pagination, Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";

const ProjectSpecificUserForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    clientId,
    userData
    
  } = props;

  const createProjectUserTitle =
      Strings["ProjectSpecificUser.createProjectUserTitle"],
    editProjectUserTitle = Strings["ProjectSpecificUser.editProjectUserTitle"],
    userNameLabel = Strings["ProjectSpecificUser.userNameLabel"],
    userNameError = Strings["ProjectSpecificUser.userNameError"],
    roleNameLabel = Strings["ProjectSpecificUser.RoleNameLabel"],
    roleNameError = Strings["ProjectSpecificUser.roleNameError"],
    SaveButton = Strings["ProjectSpecificUser.SaveButton"],
    UpdateButton = Strings["ProjectSpecificUser.UpdateButton"],
    listProjectUser = Strings["ProjectSpecificUser.listProjectUserTitle"],
    name = Strings["BudgetScope.TableHead.name"],
    email = Strings["Authentication.loginEmailLabel"],
    designation = Strings["TableHead.designation"],
    isSignatoryLabel = Strings["ProjectSpecificUser.SignatoryNameLabel"],
    CancelButton = Strings["ProjectSpecificUser.CancelButton"];

  const user = JSON.parse(localStorage.getItem("user"));

  const [userName, setUserName] = useState({
    value: initialValue.user ? initialValue.user.id : [],
    error: false,
    success: false,
  });
  const [roleName, setRoleName] = useState({
    value: initialValue.role ? initialValue.role.id : [],
    error: false,
    success: false,
  });

  const [userMultipleOptions, setUserMultipleOptions] = React.useState([]);
  const [roleMultipleOptions, setRoleMultipleOptions] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [isSignatory, setSignatory] = React.useState(false);
  

  const mapableData = [
    {
      dataKey: "name",
      title: name,
    },
    {
      dataKey: "email",
      title: email,
    },
    {
      dataKey: "designation",
      title: designation,
    },
    {
      dataKey: "is_approver",
      title: isSignatoryLabel,
    }
    
    
  ];

  // useEffect(()=>{
  //   getProjectSpecificUsers();
  // },[projectId])

  const handleUserName = (value) => {
    setMessage(null);
    setUserName(value);
  };

  const handleRoleName = (value) => {
    setMessage(null);
    setRoleName(value);
  };
  const getAllUserManagement = (id) => {
    setLoader(true);
    GetClientSpecificUser(id)
      .then((res) => {
        setLoader(false);
        const result = res.data.data.map((elm) => ({
          value: elm.id,
          label: elm.name,
        }));
        setUserMultipleOptions(result);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  // const getProjectSpecificUsers = () => {
  //   setLoader(true);
  //   GetProjectSpecificUsers(projectId)
  //     .then((res) => {
  //       setLoader(false);
  //       setUserData(res.data);
  //     })
  //     .catch(() => {
  //       setLoader(false);
  //     });
  // }

 
  const getAllProjectRole = () => {
    setLoader(true);
    GetActiveProjectRole()
      .then((res) => {
        setLoader(false);
        const result = res.data.data.map((elm) => ({
          value: elm.id,
          label: elm.name,
        }));
        setRoleMultipleOptions(result);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getAllUserManagement(clientId);
    getAllProjectRole();
  }, []);

  const handleSaveData = (value) => {
    const formData = {
      user_id: userName.value,
      role_id: roleName.value,
      is_approver: isSignatory,
      action_by: user.id
    };
    handleChangeData(formData);
  };

  const handleChangeCheck = () => {
    setSignatory(!isSignatory);
  };

  let isValueNOTChanged =
    userName.value === initialValue.user &&
    roleName.value === initialValue.role;

  let disable =
    userName.error ||
    // roleName.error ||
    userName.value.length === 0 ||
    // roleName.value.length === 0 ||
    // (userName.success === false && roleName.success === false) ||
    (userName.success === false) ||
    isValueNOTChanged;

  return (
    <div className="modalFormContainer p-5">
      <h2>
        {loader ? <LoaderComponent /> : null}
        {actionType === "add" ? createProjectUserTitle : editProjectUserTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row">
        <div className="col-6 col-md-12">
          <FormDropDown
            labelStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            starProps={true}
            isValidate={true}
            Label={userNameLabel}
            Options={userMultipleOptions}
            onSelect={handleUserName}
            customErrorLine={userNameError}
            searchable={false}
            value={userName.value}
            selectContainerStyle={{
              overflow: "scroll",
            }}
          />
        </div>
        <div className="col-6 col-md-12" style={{marginTop: "2rem"}}>
        <FormCheckBox
        Label={isSignatoryLabel}
        checked={isSignatory}
        handleChangeCheck={handleChangeCheck}
        disable={false}
      />
        </div>
        {/* <div className="col-12 col-md-6 ">
          <FormDropDown
            labelStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            starProps={true}
            isValidate={true}
            Label={roleNameLabel}
            Options={roleMultipleOptions}
            onSelect={handleRoleName}
            customErrorLine={roleNameError}
            searchable={false}
            value={roleName.value}
          />
        </div> */}
        </div>
        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <Button
            buttonName={actionType === "add" ? SaveButton : UpdateButton}
            buttonStyle={{ backgroundColor: colors.primary }}
            disable={disable}
            onClick={handleSaveData}
          />
          <Button
            buttonName={CancelButton}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
          />
        </div>
      
      <div className="row">
      <div className="col-12 col-md-12" style={{marginTop: "2rem",marginBottom: "2rem"}}>
      <h2>
        {loader ? <LoaderComponent /> : null}
        {listProjectUser}
      </h2>
      { userData && (userData != undefined || userData.length != 0) &&
      <div>
      <Table
                style={{ borderRadius: 10 }}
                height={320}
                id="table"
                data={userData}
                // onSortColumn={handleSortColumn}
                // sortColumn={sortColumn}
                // sortType={sortType}
                rowHeight={50}
                headerHeight={50}
            >
                {
                    mapableData.map((item, index) => {
                        return (
                            <Column minWidth={130} flexGrow={1} sortable key={index}>
                                <HeaderCell
                                    style={{ color: colors.primary, fontWeight: 900, fontSize: 'larger' }}>
                                    {item.title}
                                </HeaderCell>
                                <Cell dataKey={item.dataKey != null ? item.dataKey : "NA"} />
                            </Column>)

                    })
                }
            </Table>
            </div>
      }
      </div>
      </div>
    </div>
  );
};

export default ProjectSpecificUserForm;
