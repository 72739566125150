/* eslint-disable no-unused-vars */
import {
  Document,
  Image,
  Link,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { logoRect } from "../../../assets";

const VendorPoDownload = (props) => {

  const poData = props.data;

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "200px",
      height: "50px",
    },
    section: {
      margin: 10,
      padding: 10,
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    textLine: {
      fontSize: "9px",
      paddingBottom: "3px",
    },
    textLineBold: {
      fontSize: "9px",
      fontWeight: "900",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-500px",
      fontSize: "8px",
    },
    textSummery: {
      marginLeft: "25px",
      marginRight: "25px",
      fontSize: "8px",
      marginTop: "15px",
    },
  });

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <View fixed>
            <View style={styles.section} fixed>
              <Text style={{ fontSize: "30px", textAlign: "center" }}>
                PURCHASE ORDER
              </Text>
            </View>
          </View>
          <View fixed>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Vendor Name
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.vendor.name ? poData.vendor.name : "N/A"}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Project Name
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.purchase_order_item_details[0].budgetitem.budget.project.name}
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Purchase order #
                </Text>
                <Text style={{ width: "10%", fontSize: "9px", padding: "5px" }}>
                  {poData.purchase_order_serial_number
                    ? poData.purchase_order_serial_number
                    : "N/A"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Vendor Address
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.vendor.address ? poData.vendor.address : "N/A"}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Budget Number
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {
                    poData.purchase_order_item_details[0].budgetitem.budget
                      .serial_number
                  }
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Issuing Date
                </Text>
                <Text style={{ width: "10%", fontSize: "9px", padding: "5px" }}>
                  {moment(poData.purchase_order_item_details[0].budgetitem.budget.budget_start_date.split(
                    " "
                  )[0]).format('Do MMM YYYY')}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Shipping address
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.shipping_address ? poData.shipping_address : "N/A"}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Billing address
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {poData.billing_address ? poData.billing_address : "N/A"}
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Order Currency
                </Text>
                <Text style={{ width: "10%", fontSize: "9px", padding: "5px" }}>
                  {poData.purchase_order_item_details[0].quotation ? poData.purchase_order_item_details[0].quotation.currency.name :
                  poData.purchase_order_item_details[0].budgetitem.budgetquantitydetails.currency.name
                  }
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                  padding: "5px",
                }}
              >
                <Text style={{ marginLeft: "40%" }}>Special Instructions</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "10px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text>GENERAL</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "10px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text style={styles.textLine}>
                  This purchase order is in &nbsp;
                  <Text style={styles.textLineBold}>AED</Text>.
                </Text>
                <Text style={styles.textLine}>
                  Freight/Delivery Terms:&nbsp;
                  <Text style={styles.textLineBold}>
                    {poData.incoterm?.name}
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  Original invoices for any advance payments are to be submitted
                  immediately, matching the line items & conditions of
                  this&nbsp;
                  <Text style={styles.textLineBold}>PO</Text>.
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    textDecoration: "underline",
                    marginBottom: "3px",
                    marginTop: "3px",
                  }}
                >
                  Payment Terms:
                </Text>
                <Text style={styles.textLine}>
                  ADVANCE PAYMENT: &nbsp;
                  <Text style={styles.textLineBold}>
                    20% AGAINST ORIGINAL INVOICE
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  PROGRESS PAYMENT:&nbsp;
                  <Text style={styles.textLineBold}>60% AGAINST BL/AWB</Text>
                </Text>
                <Text style={styles.textLine}>
                  FINAL PAYMENT:
                  <Text style={styles.textLineBold}>20% AFTER DELIVERY.</Text>
                </Text>
                <Text style={styles.textLine}>
                  Mode of payment:
                  <Text style={styles.textLineBold}>
                    {poData.paymentterms.name}
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  This PO is to be read together with Vendor Special Conditions.
                </Text>
                <Text style={styles.textLine}>
                  Go to{" "}
                  <Link src="http://furnish-marketplace.com/vcon.pdf">
                    http://furnish-marketplace.com/vcon.pdf.
                  </Link>
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "10px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text>SCHEDULING - From Receipt of this PO</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "10px",
                  padding: "5px",
                }}
              >
                <Text style={styles.textLine}>
                  Production Lead Time:&nbsp;
                  <Text style={styles.textLineBold}>
                    {poData.purchase_order_lead_time[0].days_for_production}{" "}
                    day(s)
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  Transit Lead Time:&nbsp;
                  <Text style={styles.textLineBold}>
                    {poData.purchase_order_lead_time[0].days_for_transit} day(s)
                  </Text>
                </Text>
                <Text style={styles.textLine}>
                  Expected Arrival at&nbsp;
                  <Text style={styles.textLineBold}>{poData.location.name}</Text>
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "rgb(188, 204, 204)",
                  fontSize: "10px",
                  padding: "5px",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                <Text>DOCUMENTATION & LOGISTICS</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: "10px",
                  padding: "5px",
                }}
              >
                <Text style={styles.textLine}>
                  Vendor to supply shipper, agent and/or receiver with original:
                </Text>
                <Text style={styles.textLineBold}>
                  BILL OF LADING, PACKING LIST, CERTIFICATE OF ORIGIN, INVOICE,
                  DELIVERY NOTE
                </Text>
                <Text style={styles.textLine}>
                  All goods and packaging to be clearly labelled with control
                  numbers and descriptions with corresponding control numbers on
                  all documentation.
                </Text>
                <Text style={styles.textLine}>
                  Any packaging charges or other surcharges not quoted for
                  cannot be charged.
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "900",
                    paddingTop: "5px",
                  }}
                >
                  DELIVERY WITHIN 10 WEEKS FROM THE DATE OF ORDER CONFIRMATION
                  AND ADVANCE PAYMENT.
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "900",
                    paddingTop: "5px",
                  }}
                >
                  WIRE TRANSFER FEES TO BE COVERED BY BUYER.
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "900",
                    paddingTop: "5px",
                  }}
                >
                  {poData.location.name} : {poData.origin_details ? poData.origin_details : "N/A"}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: "30px" }} break>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "25px",
                marginRight: "25px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "10%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Ctrl No.
                </Text>
                <Text
                  style={{
                    width: "10%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Sub-Group
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "9px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Description
                </Text>
                <Text
                  style={{
                    width: "10%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  UOM
                </Text>
                <Text
                  style={{
                    width: "10%",
                    fontSize: "9px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Quantity
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Unit Price
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: "9px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Total
                </Text>
              </View>
              {poData.purchase_order_item_details &&
                poData.purchase_order_item_details.map((ele) => {
                  return (
                    <>
                      <View
                        style={{
                          fontSize: "8px",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          borderTop: "1px solid black",
                          marginTop: "0px",
                        }}
                      >
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{ margin: "auto", padding: "5px" }}
                          >
                            {ele.budgetitem.control_number}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{ margin: "auto", padding: "5px" }}
                          >
                            {ele.budgetitem.itemsubgroup.name}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "30%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{ margin: "auto", padding: "5px" }}
                          >
                            {ele.budgetitem.description}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{ margin: "auto" }}
                          >
                            {ele.actual_uom_Name}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "10%",
                            height: "100%",
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text
                            style={{ margin: "auto" }}
                          >
                            {ele.actual_quantity}
                          </Text>
                        </View>
                        <CurrencyFormat value={ele.unit_price ? ele.unit_price : 0} displayType={'text'} thousandSeparator={true}
                          renderText={value =>
                            <Text
                              style={{
                                width: "15%",
                                fontSize: "9px",
                                padding: "5px",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {value}
                            </Text>
                          } />
                        <View
                          style={{
                            width: "15%",
                            height: "100%",
                          }}
                        >
                          <CurrencyFormat value={ele.total_price ? ele.total_price : 0} displayType={'text'} thousandSeparator={true}
                            renderText={value =>
                              <Text
                                style={{ margin: "auto" }}
                              >
                                {value}
                              </Text>
                            } />

                        </View>
                      </View>
                    </>
                  )
                })}
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                border: "1px solid black",
                marginTop: "15px",
                marginLeft: "25px",
                marginRight: "25px",
                borderRadius: "5px",
              }}
            >
              <Text
                style={{
                  width: "85%",
                  fontSize: "10px",
                  borderRight: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                Total
              </Text>
              <CurrencyFormat value={poData.purchase_order_item_details &&
                poData.purchase_order_item_details
                  .reduce((acc, curr) => {
                    return acc + parseInt(curr.total_price);
                  }, 0)} displayType={'text'} thousandSeparator={true}
                renderText={value =>
                  <Text
                    style={{
                      width: "15%",
                      fontSize: "9px",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    {value}
                  </Text>
                } />
            </View>

            <Text style={styles.textSummery}>
              This Purchase Order ("PO") is prepared by FURNISH Hospitality
              General Trading LLC ("Agent") on behalf of the Client, the details
              of which are set out above ("Project Name"/"Billing Address") and
              as agent only for the Client. It consists of this cover sheet and
              the attached special conditions, the content of which the Vendor
              acknowledges and agrees to by signing this PO. All purchases are
              being made solely for the account of the Client. The Client shall
              bear the sole responsibility for all payments. All correspondence
              and other business pertaining to acceptance of this PO and the
              terms set herein, including, without limitation, credit approval,
              terms, and payment shall be between the Vendor and the Client and
              the Agent assumes no liability therefor, and makes no
              representations to the Vendor or the Client express or implied.
            </Text>
            <View
              break
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                ></Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                ></Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                ></Text>
              </View>
            </View>
          </View>

          <View style={styles.footer} fixed>
            <View
              style={{ borderTop: "1px solid black", marginRight: "50px" }}
            ></View>
            <View>
              <Text style={{ fontSize: "8px", marginTop: "5px" }}>PO BOX 282247, Dubai, UAE</Text>
              <Text style={{ fontSize: "8px" }}>tel +971 4 3307725</Text>
              <Text style={{ fontSize: "8px" }}>
                <Link
                  style={{ color: "black" }}
                  src="mailto:invitation@furnish-marketplace.com"
                >
                  invitation@furnish-marketplace.com
                </Link>
              </Text>
              <Text style={{ fontSize: "8px" }}>
                <Link
                  style={{ color: "black" }}
                  src="http://furnish-marketplace.com"
                >
                  furnish-marketplace.com
                </Link>
              </Text>
            </View>
            <View>
              <Text
                style={styles.pageNo}
                render={({ pageNumber, totalPages }) =>
                  `Page # ${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default VendorPoDownload;
