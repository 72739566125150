/* eslint-disable no-unused-vars */
import React from "react";
import { GetAllActiveCountry } from "../../../axios";
import { Button, FormDropDown, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import "./VendorBranch.css";

const VendorBranchForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    vendorId,
  } = props;

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [address, setAddress] = React.useState({
    value: initialValue.address ? initialValue.address : "",
    error: false,
    success: false,
  });

  const [city, setCity] = React.useState({
    value: initialValue.city ? initialValue.city : "",
    error: false,
    success: false,
  });

  const [postCode, setPostCode] = React.useState({
    value: initialValue.postcode ? initialValue.postcode : "",
    error: false,
    success: false,
  });

  const [stateName, setStateName] = React.useState({
    value: initialValue.state ? initialValue.state : "",
    error: false,
    success: false,
  });

  const [telephone, setTelephone] = React.useState({
    value: initialValue.phone ? initialValue.phone : "",
    error: false,
    success: false,
  });

  const [fax, setFax] = React.useState({
    value: initialValue.fax ? initialValue.fax : "",
    error: false,
    success: false,
  });
  const [country, setCountry] = React.useState([]);

  React.useEffect(() => {
    GetAllActiveCountry()
      .then((res) => {
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setCountry(result);
      })
      .catch(() => {});
  }, []);

  const [countryName, setCountryName] = React.useState({
    value: initialValue.country ? initialValue.country.id : "",
    error: false,
    success: false,
  });
  const handleChangeName = (value) => {
    setName(value);
  };

  const handleChangeAddress = (value) => {
    setAddress(value);
  };

  const handleChangeCity = (value) => {
    setCity(value);
  };

  const handleChangePostCode = (value) => {
    setPostCode(value);
  };

  const handleChangeState = (value) => {
    setStateName(value);
  };

  const handleChangeTelephone = (value) => {
    setTelephone(value);
  };

  const handleChangeFax = (value) => {
    setFax(value);
  };

  const handleChangeCountry = (value) => {
    setCountryName(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: name.value,
      address: address.value,
      city: city.value,
      postcode: postCode.value,
      phone: telephone.value,
      state: stateName.value,
      fax: fax.value,
      country_id: countryName.value.toString(),
      vendor_id: vendorId.toString(),
    };
    handleChangeFormData(formData);
  };

  let isValuedChanged =
    name.value === initialValue.name &&
    address.value === initialValue.address &&
    city.value === initialValue.city &&
    postCode.value === initialValue.postCode &&
    telephone.value === initialValue.telephone &&
    stateName.value === initialValue.stateName &&
    fax.value === initialValue.value &&
    countryName.value === initialValue.countryName;

  let disable =
    name.error ||
    address.error ||
    city.error ||
    postCode.error ||
    telephone.error ||
    stateName.error ||
    fax.error ||
    countryName.error ||
    name.value === "" ||
    address.value === "" ||
    city.value === "" ||
    postCode.value === "" ||
    telephone.value === "" ||
    stateName.value === "" ||
    fax.value === "" ||
    countryName.value === "" ||
    (name.success === false &&
      address.success === false &&
      city.success === false &&
      postCode.success === false &&
      telephone.success === false &&
      stateName.success === false &&
      fax.success === false &&
      countryName.success === false) ||
    isValuedChanged;

  const createVenderManagementTitle =
      Strings["VenderContactForm.CreateBranchTitle"],
    editVenderManagementTitle = Strings["VenderContactForm.editBranchTitle"],
    NameLabel = Strings["VenderContactForm.BranchNameLabel"],
    NamePlaceholder = Strings["VenderContactForm.BranchNamePlaceholder"],
    NameCustomError = Strings["VenderContactForm.BranchNameCustomError"],
    AddressLabel = Strings["VenderContactForm.BranchAddressLabel"],
    AddressPlaceholder = Strings["VenderContactForm.BranchAddressPlaceholder"],
    AddressCustomError = Strings["VenderContactForm.BranchAddressCustomError"],
    CityLabel = Strings["VenderContactForm.BranchCityLabel"],
    CityPlaceholder = Strings["VenderContactForm.BranchCityPlaceholder"],
    CityCustomError = Strings["VenderContactForm.BranchCityCustomError"],
    PostCodeLabel = Strings["VenderContactForm.BranchPostCodeLabel"],
    PostCodePlaceholder =
      Strings["VenderContactForm.BranchPostCodePlaceholder"],
    PostCodeCustomError =
      Strings["VenderContactForm.BranchPostCodeCustomError"],
    TelephoneLabel = Strings["VenderContactForm.BranchTelephoneLabel"],
    TelephonePlaceholder =
      Strings["VenderContactForm.BranchTelephonePlaceholder"],
    TelephoneCustomError =
      Strings["VenderContactForm.BranchTelephoneCustomError"],
    StateLabel = Strings["VenderContactForm.BranchStateLabel"],
    StatePlaceholder = Strings["VenderContactForm.BranchStatePlaceholder"],
    StateCustomError = Strings["VenderContactForm.BranchStateCustomError"],
    FaxLabel = Strings["VenderContactForm.BranchFaxLabel"],
    FaxPlaceholder = Strings["VenderContactForm.BranchFaxPlaceholder"],
    FaxCustomError = Strings["VenderContactForm.BranchFaxCustomError"],
    CountryLabel = Strings["VenderContactForm.BranchCountryLabel"],
    CountryCustomError = Strings["VenderContactForm.BranchCountryCustomError"],
    SaveBtn = Strings["VenderContactForm.SaveBtn"],
    UpdateBtn = Strings["VenderContactForm.UpdateBtn"],
    CancelBtn = Strings["VenderContactForm.CancelBtn"];

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add"
          ? createVenderManagementTitle
          : editVenderManagementTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row">
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            maxLength={50}
            type="textarea"
            label={NameLabel}
            starProps={true}
            placeholder={NamePlaceholder}
            Value={name.value}
            onChangeText={handleChangeName}
            Validate={true}
            CustomErrorLine={NameCustomError}
            editable
          />
        </div>
        <div className="col-md-6 col-sm-12  m-auto">
          <FormDropDown
            labelStyle={{
              marginTop: "22px",
              marginBottom: "10px",
            }}
            starProps={true}
            isValidate={true}
            isMulti={false}
            Label={CountryLabel}
            CustomErrorLine={CountryCustomError}
            Options={country}
            selectionLimit={3}
            onSelect={handleChangeCountry}
            value={countryName.value}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            labelTextStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            type="textarea"
            starProps={true}
            label={StateLabel}
            placeholder={StatePlaceholder}
            Value={stateName.value}
            onChangeText={handleChangeState}
            Validate={true}
            CustomErrorLine={StateCustomError}
            editable
          />
        </div>
        <div className="col-md-6 col-sm-12  m-auto">
          <FormTextInput
            labelTextStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            type="textarea"
            label={CityLabel}
            starProps={true}
            placeholder={CityPlaceholder}
            Value={city.value}
            onChangeText={handleChangeCity}
            Validate={true}
            CustomErrorLine={CityCustomError}
            editable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            labelTextStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            maxLength={40}
            type="alpha"
            label={PostCodeLabel}
            starProps={true}
            placeholder={PostCodePlaceholder}
            Value={postCode.value}
            onChangeText={handleChangePostCode}
            Validate={true}
            CustomErrorLine={PostCodeCustomError}
            editable
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            labelTextStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            type="phone"
            label={TelephoneLabel}
            starProps={true}
            placeholder={TelephonePlaceholder}
            Value={telephone.value}
            onChangeText={handleChangeTelephone}
            Validate={true}
            CustomErrorLine={TelephoneCustomError}
            editable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            labelTextStyle={
              {
                // marginBottom: "-50px",
                // paddingBottom: "10px",
              }
            }
            type="phone"
            starProps={true}
            label={FaxLabel}
            placeholder={FaxPlaceholder}
            Value={fax.value}
            onChangeText={handleChangeFax}
            // Validate={true}
            CustomErrorLine={FaxCustomError}
            editable
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            // labelTextStyle={{
            //   marginTop: "10px",
            //   marginBottom: "10px",
            // }}
            type="textarea"
            label={AddressLabel}
            starProps={true}
            placeholder={AddressPlaceholder}
            Value={address.value}
            onChangeText={handleChangeAddress}
            Validate={true}
            CustomErrorLine={AddressCustomError}
            multiline={true}
            maxLength={150}
            editable
          />
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? SaveBtn : UpdateBtn}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
          buttonStyle={{ backgroundColor: colors.primary }}
        />
        <Button
          buttonName={CancelBtn}
          onClick={() => {
            setMessage(null);
            handleModalClose();
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default VendorBranchForm;
