/* eslint-disable no-lone-blocks */
import React from "react";
import { useHistory } from "react-router-dom";
import { Security } from "../../../assets";
import { ConfirmForgetPassword } from "../../../axios";
import LoaderComponent from "../../../components/common/Loader";
import { ConfirmForgotPasswordForm } from "../../../forms";
import Strings from "../../../translations";

const ConfirmForgotPasswordPage = () => {
  const [errorMessage, setMessage] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const history = useHistory();
  const ConfirmForgotPasswordTitle =
    Strings["Authentication.confirmForgotPasswordTitle"];

  const handleChangePassword = (formData) => {
    const data = {
      email: formData.email,
      password: formData.password,
    };
    const otp = formData.otp;
    setLoader(true);
    ConfirmForgetPassword(otp, data)
      .then((res) => {
        setLoader(false);
        history.replace("/");
      })
      .catch((e) => {
        setLoader(false);
        setMessage(e.response.data.message);
      });
  };

  return (
    <section className={"white-bg"}>
      {loader ? <LoaderComponent /> : null}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 d-md-flex bg-image">
            <div className="loginImageBox">
              <div className="imageWrapeer3">
                <img src={Security} alt="loginScreen" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div className="loginForm d-flex align-items-center py-5">
              <div className="container loginformdiv">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    <div className="login_form">
                      <h3>{ConfirmForgotPasswordTitle}</h3>

                      <ConfirmForgotPasswordForm
                        setMessage={setMessage}
                        errorMessage={errorMessage}
                        handleData={handleChangePassword}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmForgotPasswordPage;

