/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateItemSubCategoryForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createitemsubcategory = Strings["SubCategory.createitemsubcategory"],
    edititemsubcategory = Strings["SubCategory.edititemsubcategory"],
    itemsubcategoryNameLabel = Strings["SubCategory.itemsubcategoryNameLabel"],
    itemsubcategoryNameplaceholder =
      Strings["SubCategory.itemsubcategoryNameplaceholder"],
    clientTypeNameCustomError =
      Strings["SubCategory.CreateClientTypeCustomLineError"],
    itemsubcategoryDescriptionLabel =
      Strings["SubCategory.itemsubcategoryDescriptionLabel"],
    itemsubcategoryDescriptionplaceholder =
      Strings["SubCategory.itemsubcategoryDescriptionplaceholder"],
    clientDescriptionCustomError =
      Strings["SubCategory.createClientDescriptionCustomLineError"],
    itemsubcategoryTagLabel = Strings["SubCategory.CreateClientTagLable"],
    itemsubcategoryTagplaceholder =
      Strings["SubCategory.itemsubcategoryTagplaceholder"],
    clientTagCustomError =
      Strings["SubCategory.createClientTagCustomLineError"],
    cancelBtn = Strings["SubCategory.createClientCancelBtn"],
    saveBtn = Strings["SubCategory.createClientSaveBtn"],
    updateBtn = Strings["SubCategory.EditClientUpdateBtn"];

  const [clientTypeName, setClientTypeName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [clientTypeDescription, setClientTypeDescription] = useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [clientTypeTag, setClientTypeTag] = useState({
    value: initialValue.tag ? initialValue.tag : "",
    error: false,
    status: false,
  });

  const handleChangeClientType = (value) => {
    setMessage(null);
    setClientTypeName(value);
  };

  const handleChangeClientTypeDescription = (value) => {
    setClientTypeDescription(value);
  };

  const handleChangeClientTag = (value) => {
    setMessage(null);
    setClientTypeTag(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: clientTypeName.value,
      description: clientTypeDescription.value,
      tag: clientTypeTag.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged =
    clientTypeName.value === initialValue.clientTypeName &&
    clientTypeTag.value === initialValue.clientTypeTag;

  let disable =
    clientTypeName.error ||
    clientTypeTag.error ||
    clientTypeName.value === "" ||
    clientTypeTag.value === "" ||
    (clientTypeName.success === false && clientTypeTag.success === false) ||
    isValueNOTChanged;

  let isValueNOTChangedEdit =
    clientTypeName.value === initialValue.clientTypeName &&
    clientTypeDescription.value === initialValue.clientTypeDescription &&
    clientTypeTag.value === initialValue.clientTypeTag;

  let Editdisable =
    clientTypeName.error ||
    clientTypeDescription.error ||
    clientTypeTag.error ||
    clientTypeName.value === "" ||
    clientTypeDescription.value === "" ||
    clientTypeTag.value === "" ||
    (clientTypeName.success === false &&
      clientTypeDescription.success === false &&
      clientTypeTag.success === false) ||
    isValueNOTChangedEdit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add" ? createitemsubcategory : edititemsubcategory}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="text"
        label={itemsubcategoryNameLabel}
        placeholder={itemsubcategoryNameplaceholder}
        Value={clientTypeName.value}
        onChangeText={handleChangeClientType}
        Validate={true}
        CustomErrorLine={clientTypeNameCustomError}
        editable
      />
      <FormTextInput
        type="text"
        label={itemsubcategoryDescriptionLabel}
        placeholder={itemsubcategoryDescriptionplaceholder}
        Value={clientTypeDescription.value}
        onChangeText={handleChangeClientTypeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        CustomErrorLine={clientDescriptionCustomError}
        multiline={true}
        editable
      />

      <FormTextInput
        starProps={true}
        type="text"
        label={itemsubcategoryTagLabel}
        placeholder={itemsubcategoryTagplaceholder}
        Value={clientTypeTag.value}
        onChangeText={handleChangeClientTag}
        Validate={true}
        CustomErrorLine={clientTagCustomError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />

        <Button
          buttonName={cancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default CreateItemSubCategoryForm;
