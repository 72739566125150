import React from 'react'
import { FormTextInput, Button, FormCheckBox } from "../../../components";
import { colors } from '../../../configs';
import Strings from "../../../translations";

const DeleteBudgetScopeToItemCategoryMappingForm = (props) => {

    const { deleteChangeFormData, initialValue = {}, actionType } = props;

    const getAllFunctions = (subCat) => {
        let str = "";
        subCat.forEach((i, index) => {
            if (index === 0) {
                str = i.itemName;
            } else {
                str = str + " , " + i.itemName;
            }
        });

        return str;
    };

    const [scope, setScope] = React.useState({
        value: initialValue.scope ? initialValue.scope : "",
        error: false,
        success: false,
    });

    const [roles, setRoles] = React.useState({
        value: initialValue.roles.length > 0 ? getAllFunctions(initialValue.roles) : "",
        error: false,
        success: false,
    });

    const [activeStatus, setActiveStatus] = React.useState(
        initialValue.activeStatus
    );

    const handleChangeScope = (value) => {
        setScope(value)
    }

    const handleChangeRole = (value) => {
        setRoles(value)
    }

    const handleChangeCheck = () => {
        setActiveStatus(!activeStatus);
    };



    const deleteUserRoleMappingTitle =
        Strings["BudgetMasterConfiguration.deleteUserRoleMappingTitle"],
        viewUserRoleMappingTitle =
            Strings["BudgetMasterConfiguration.ViewUserRoleMappingTitle"],
        deleteScopeLabel =
            Strings["BudgetMasterConfiguration.deleteScopeLabel"],
        deleteScopePlaceholder =
            Strings["BudgetMasterConfiguration.deleteScopePlaceholder"],
        deleteScopeCustomError =
            Strings["BudgetMasterConfiguration.deleteScopeCustomError"],
        deleteItemLabel =
            Strings["BudgetMasterConfiguration.deleteItemLabel"],
        deleteItemPlaceholder =
            Strings["BudgetMasterConfiguration.deleteItemPlaceholder"],
        deleteItemCustomError =
            Strings["BudgetMasterConfiguration.deleteItemCustomError"],
        deleteActiveStatusLabel =
            Strings["BudgetMasterConfiguration.deleteActiveStatusLabel"],
        deleteBtn = Strings["BudgetMasterConfiguration.deletePageButton"],
        cancel = Strings["BudgetMasterConfiguration.deleteCancelButton"];

    const handleSaveData = () => {
        const formData = {
            scope: scope.value,
            roles: initialValue.roles,
        };
        deleteChangeFormData(formData);
    };

    // let disable =
    //     scope.error ||
    //     roles.error ||
    //     roles.value === "" ||
    //     scope.value === "" ||
    //     roles.success === false ||
    //     scope.success === false;

    return (
        <div className="modalFormContainer">
            <h1 className="mb-3">
                {actionType !== "delete"
                    ? viewUserRoleMappingTitle
                    : deleteUserRoleMappingTitle}
            </h1>

            <FormTextInput
                type="text"
                label={deleteScopeLabel}
                placeholder={deleteScopePlaceholder}
                Value={scope.value}
                onChangeText={handleChangeScope}
                Validate={true}
                CustomErrorLine={deleteScopeCustomError}
                editable={false}
            />

            <FormTextInput
                type="text"
                label={deleteItemLabel}
                placeholder={deleteItemPlaceholder}
                Value={roles.value}
                onChangeText={handleChangeRole}
                Validate={true}
                CustomErrorLine={deleteItemCustomError}
                editable={false}
                multiline={false}
            />

            <FormCheckBox
                Label={deleteActiveStatusLabel}
                checked={activeStatus}
                handleChangeCheck={handleChangeCheck}
                disable={true}
            />

            {actionType === "delete" ? (
                <div style={{ display: "flex", marginTop: "1.5rem" }}>
                    <Button
                        buttonName={deleteBtn}
                        buttonStyle={{ marginRight: "10px", backgroundColor: colors.danger }}
                        disable={false}
                        onClick={handleSaveData}
                    />
                    <Button buttonName={cancel} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default DeleteBudgetScopeToItemCategoryMappingForm
