/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import swal from "sweetalert";
import {
  AllActiveStatusModeOfPayment,
  GetActivePaymentProgress,
  GetAllActiveRequiredDocument,
  GetAllActivePaymentTerms,
  GetPriceByPurchaseOrder,
  ImageCreation,
} from "../../../axios";
import {
  Button,
  DateAndTimePicker,
  // FilePicker,
  FormCheckBox,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";
import CloseIcon from "@mui/icons-material/Close";

const InvoiceForm = (props) => {
  const {
    handleInvoiceData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    setMessage,
    errorMessage,
    poDetail,
    budgetId
  } = props;

  const [finalAmounts, setTotalPOAmount] = React.useState([]);
  const [poCurrency,setPOCurrency] = React.useState("AED");
  const getData = () => {
    GetPriceByPurchaseOrder(poDetail?.pState.id,budgetId)
      .then((ele) => {
        setTotalPOAmount(ele.data.data.left_invoice_price);
        setPOCurrency(ele.data.data.purchase_order_currency)
      })
      .catch(() => {});
  };
  useEffect(() => {
    getData();
  }, []);

  const [screen, setScreen] = React.useState(1);
  const [loader, setLoader] = React.useState(false);
  const [purchaseOrderName, setPurchaseOrderName] = React.useState({
    value: initialValue.purchase_order
      ? poDetail?.pState.purchase_order_serial_number
      : poDetail?.pState.purchase_order_serial_number,
    error: false,
    success: false,
  });
  const [comment, setComment] = React.useState({
    value: initialValue.comment ? initialValue.comment : "",
    error: false,
    success: false,
  });
  const [deliverynote, setdeliverynote] = React.useState({
    value: initialValue.delivery_notes ? initialValue.delivery_notes : "",
    error: false,
    success: false,
  });
  const [invoiceName, setInvoiceName] = React.useState({
    value: initialValue.invoice_name ? initialValue.invoice_name : "",
    error: false,
    success: false,
  });
  const [modeOfPayment, setModeOfPayment] = React.useState({
    value: initialValue.mode_of_payment ? initialValue.mode_of_payment.id : "",
    error: false,
    success: false,
  });

  const [paymentProgress, setPaymentProgress] = React.useState({
    value: initialValue.payment_progress
      ? initialValue.payment_progress.id
      : "",
    error: false,
    success: false,
  });

  const [totalAmount, setTotalAmount] = React.useState({
    value: initialValue.total_amount ? initialValue.total_amount : "",
    error: false,
    success: false,
  });

  const [recivedOn, setRecivedOn] = React.useState({
    value: initialValue.received_on
      ? initialValue.received_on.split(" ")[0]
      : "",
    error: false,
    success: false,
  });

  const [dueOn, setDueOn] = React.useState({
    value: initialValue.due_on ? initialValue.due_on.split(" ")[0] : "",
    error: false,
    success: false,
  });

  const [invoiceStatus, setinvoiceStatus] = React.useState({
    value: initialValue.invoice_status ? initialValue.invoice_status : "",
    error: false,
    success: false,
  });

  const [bankDetails, setBankDetails] = React.useState({
    value: initialValue.bank_details ? initialValue.bank_details : "",
    error: false,
    success: false,
  });

  // const [selectedFile, setSelectedFile] = React.useState({
  //   value: initialValue.files ? initialValue.files[0]?.file_path : null,
  //   error: false,
  //   success: false,
  // });
  const [invoice, setInvoice] = React.useState(false);
  const [letter, setLetter] = React.useState(false);
  const [requiredDocument, setRequiredDocument] = React.useState([]);
  const [modeOfPaymentOption, setModeOfPaymentOption] = React.useState([]);
  const [paymentProgressOptions, setPaymentProgressOptions] = React.useState(
    []
  );

  const [fileName, setFileName] = React.useState({
    value: initialValue.files ? initialValue.files[1].file_path : "",
    error: false,
    success: false,
  });
  const [filePath, setFilePath] = React.useState({
    value: initialValue.files ? initialValue.files : [],
    error: false,
    success: false,
  });

  // const handleRemoveFile = () => {
  //   setSelectedFile([]);
  // };

  const [invoiceDropdown] = React.useState([
    { key: 1, value: "Draft", label: "Draft" },
    { key: 2, value: "Send", label: "Send" },
  ]);

  const showBankDetail = invoice || letter;

  React.useEffect(() => {
    setLoader(true);
    AllActiveStatusModeOfPayment()
      .then((res) => {
        const result = res.data.data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setLoader(false);
        setModeOfPaymentOption(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  }, []);

  React.useEffect(() => {
    setLoader(true);
    GetAllActivePaymentTerms()
      .then((res) => {
        const result = res.data.data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setLoader(false);
        setPaymentProgressOptions(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  }, []);

  const handleChangeCheck = (key) => {
    const result = requiredDocument.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setRequiredDocument(result);
  };

  React.useEffect(() => {
    if (actionType === "add") {
      setLoader(true);
      GetAllActiveRequiredDocument()
        .then((res) => {
          let allData = res.data.data;
          const result = allData.map((elm) => ({
            key: elm.id,
            requiredDocumentName: elm.name,
            checked: false,
          }));
          setLoader(false);
          setRequiredDocument(result);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  }, [actionType]);

  React.useEffect(() => {
    if (actionType === "edit") {
      console.log(initialValue);
      const arr = initialValue.required_document;
      const checkArr = { checked: true };
      for (let i = 0; i < arr?.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var sub_id_list = [];
      arr.map((ele) => sub_id_list.push(ele.requireddocument.id));
      setLoader(true);
      GetAllActiveRequiredDocument()
        .then((res) => {
          const fetchData = res.data?.data?.map((elm) => ({
            key: elm.id,
            requiredDocumentName: elm.name,
            checked: sub_id_list.includes(elm.id) ? true : false,
          }));
          setLoader(false);
          setRequiredDocument(fetchData);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  }, [actionType, initialValue]);

  const handleChangeNextScreen = () => {
    setScreen(screen + 1);
  };

  const handleChangePrevScreen = () => {
    setScreen(screen - 1);
  };

  const handleChangePurchaseOrderName = (value) => {
    setPurchaseOrderName(value);
  };

  const handleChangeInvoiceName = (value) => {
    setMessage(null);
    setInvoiceName(value);
  };

  const handleChangeComment = (value) => {
    setComment(value);
  };
  const handleChangedeliverynote = (value) => {
    setdeliverynote(value);
  };

  const handleChangeModeofPayment = (value) => {
    setModeOfPayment(value);
  };

  const handlePaymentProgress = (value) => {
    setPaymentProgress(value);
  };

  const handleChangeTotalAmount = (value) => {
    setTotalAmount(value);
  };

  const handleChangeRecivedOn = (value) => {
    setRecivedOn(value);
  };

  const handleChangeDueOn = (value) => {
    setDueOn(value);
  };

  const handleChangeBankDetails = (value) => {
    setBankDetails(value);
  };

  const handleChangeFile = async (data) => {
    setLoader(true);
    
    if (data.target.files.length > 0) {
      const file = data.target.files[0];
      const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];

      if (!allowedFileTypes.includes(file.type)) {
        // Invalid file type
        setLoader(false);
        swal({
          title: "Error!",
          text: "Please upload a valid PDF or image file.",
          icon: "error",
        });
        return;
      }

      const filename = file.name;
      const fileExtension = filename.split(".").pop();

      const base64 = await convertBase64(file);
      const newbase64 = base64.replace(/^data:[a-z]+\/[a-z]+;base64,/, "");

      const uploadData = {
        file_data: newbase64,
        file_extension: fileExtension,
      };

      await ImageCreation(uploadData)
        .then((res) => {
          setLoader(false);
          setFilePath({ value: [res.data.data.file_path, filename] });
        })
        .catch((e) => {
          setLoader(false);
          swal({
            title: "Error!",
            text: e.response.data.message,
            icon: "error",
          });
        });
    }
  };

  const createInvoiceHeader = strings["Invoice.createInvoiceHeader"],
    editInvoiceHeader = strings["Invoice.editInvoiceHeader"],
    modeOfPaymentLabel = strings["Invoice.modeOfPaymentLabel"],
    modeOfPaymentErrorLine = strings["Invoice.modeOfPaymentErrorLine"],
    paymentProgressLabel = strings["Invoice.paymentProgress"],
    paymentProgressErrorLine = strings["Invoice.paymentProgressErrorLine"],
    purchaseOrderLabel = strings["Invoice.purchaseOrderLabel"],
    purchaseOrderPlaceholder = strings["Invoice.purchaseOrderPlaceholder"],
    purchaseOrderErrorLine = strings["Invoice.purchaseOrderErrorLine"],
    invoiceNameLabel = strings["Invoice.invoiceNameLabel"],
    invoiceCommentLabel = strings["Invoice.InvoiceCommentLabel"],
    invoiceCommentPlaceholder = strings["Invoice.InvoiceCommentPlaceholder"],
    deliveryNoteLabel = strings["Invoice.deliveryNoteLabel"],
    deliveryNotePlaceholder = strings["Invoice.deliveryNotePlaceholder"],
    invoiceNamePlaceholder = strings["Invoice.invoiceNamePlaceholder"],
    invoiceNameErrorLine = strings["Invoice.invoiceNameErrorLine"],
    deleiveryNoteErrorLine = strings["Invoice.deleiveryNoteErrorLine"],
    totalAmountLabel = strings["Invoice.totalAmountLabel"],
    totalAmountPlaceholder = strings["Invoice.totalAmountPlaceholder"],
    totalAmountErrorLine = strings["Invoice.totalAmountErrorLine"],
    recivedOnLabel = strings["Invoice.recivedOnLabel"],
    recivedOnErrorLine = strings["Invoice.recivedOnErrorLine"],
    dueOnLabel = strings["Invoice.dueOnLabel"],
    dueOnErrorline = strings["Invoice.dueOnErrorline"],
    cancelBtn = strings["Invoice.cancelBtn"],
    nextBtn = strings["Invoice.nextBtn"],
    prevBtn = strings["Invoice.prevBtn"],
    saveBtn = strings["Invoice.saveBtn"],
    bankDetailsLabel = strings["Invoice.bankDetailsLabel"],
    bankDetailsPlaceholder = strings["Invoice.bankDetailsPlaceholder"],
    bankDetailsErrorLine = strings["Invoice.bankDetailsErrorLine"],
    requiredDocumentLabel = strings["Invoice.requiredDocumentLabel"];

  const isValueNotChangedScreen1 =
    invoiceName.value === initialValue.invoiceName &&
    modeOfPayment.value === initialValue.modeOfPayment &&
    paymentProgress.value === initialValue.paymentProgress &&
    totalAmount.value === initialValue.totalAmount;

  const disableInvoiceScreen1 =
    invoiceName.error ||
    paymentProgress.error ||
    modeOfPayment.error ||
    deliverynote.error ||
    comment.error ||
    // totalAmount.error ||
    invoiceName.value === "" ||
    modeOfPayment.value === "" ||
    paymentProgress.value === "" ||
    comment.value === "" ||
    // totalAmount.value === "" ||
    deliverynote.value === "" ||
    (invoiceName.success === false &&
      modeOfPayment.success === false &&
      paymentProgress.success === false &&
      comment.success === false &&
      deliverynote.success === false) ||
    // totalAmount.success === false ||
    isValueNotChangedScreen1;

  const editDisableInvoiceScreen1 =
    invoiceName.error ||
    modeOfPayment.error ||
    paymentProgress.error ||
    totalAmount.error ||
    invoiceName.value === "" ||
    modeOfPayment.value === "" ||
    paymentProgress.value === "" ||
    totalAmount.value === "" ||
    (invoiceName.success === true &&
      modeOfPayment.success === true &&
      paymentProgress.success === true &&
      totalAmount.success === true);

  const isValueNotChangedScreen2 =
    recivedOn.value === initialValue.recivedOn &&
    dueOn.value === initialValue.dueOn &&
    // paidOn.value === initialValue.paidOn &&
    // paidAmount.value === initialValue.paidAmount &&
    bankDetails.value === initialValue.bankDetails;

  const disableInvoiceScreen2 =
    recivedOn.error ||
    dueOn.error ||
    // paidOn.error ||
    totalAmount.error ||
    // paidAmount.error ||
    bankDetails.error ||
    recivedOn.value === "" ||
    dueOn.value === "" ||
    totalAmount.value === "" ||
    bankDetails.value === "" ||
    (recivedOn.success === false &&
      dueOn.success === false &&
      bankDetails.success === false) ||
    isValueNotChangedScreen2;

  const editDisableInvoiceScreen2 =
    recivedOn.error ||
    dueOn.error ||
    bankDetails.error ||
    recivedOn.value === "" ||
    dueOn.value === "" ||
    bankDetails.value === "" ||
    (recivedOn.success === true &&
      dueOn.success === true &&
      bankDetails.success === true);

  const disableInvoiceScreen3 = requiredDocument.every((document) => {
    return !document.checked;
  });

  const functionResultTrue = () => {
    const resultArray = [];
    requiredDocument.forEach((item) => {
      if (item.checked === true) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };

  const handleInvoiceStatus = (value) => {
    setinvoiceStatus(value);
  };
  const handleSaveData = () => {
    const formData = {
      purchase_order_serial_number: purchaseOrderName.value,
      invoice_name: invoiceName.value,
      payment_progress_id: paymentProgress.value,
      mode_of_payment_id: modeOfPayment.value,
      comment: comment.value,
      received_on: recivedOn.value,
      due_on: dueOn.value,
      total_amount: parseInt(totalAmount.value),
      bank_details: bankDetails.value,
      purchase_order_id: poDetail.pState.id,
      required_document_id: functionResultTrue(),
      file_path: filePath.value,
      // delivery_notes: deliverynote.value,
      invoice_status: invoiceStatus.value,
      in_voice: invoice ? 1 : 0,
      letter: letter ? 1 : 0,
    };
    // console.log(formData);
    handleInvoiceData(formData);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleClearFile = () => {
    setFileName("");
    setFilePath([]);
  };
  const handleInvoiceChecked = () => {
    setInvoice(!invoice);
    setLetter(false);
  };
  const handleLetterChecked = () => {
    setLetter(!letter);
    setInvoice(false);
  };
  return (
    <div className="modalFormContainer">
      {loader ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? createInvoiceHeader : editInvoiceHeader}</h2>
      {screen === 1 && (
        <div>
          {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
          <div className="row">
            <div className="col-6 col-md-6 pe-0 pe-md-3">
            <FormTextInput
            type="textarea"
            label={purchaseOrderLabel}
            placeholder={purchaseOrderPlaceholder}
            Value={purchaseOrderName.value}
            onChangeText={handleChangePurchaseOrderName}
            Validate={true}
            CustomErrorLine={purchaseOrderErrorLine}
            editable={false}
            starProps={true}
          />
          </div>
          <div className="col-6 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
            labelTextStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            type="textarea"
            label={"Invoice Reference No"}
            placeholder={"Enter Invoice Reference No"}
            Value={invoiceName.value}
            onChangeText={handleChangeInvoiceName}
            Validate={true}
            CustomErrorLine={invoiceNameErrorLine}
            editable={true}
            starProps={true}
          />
            </div>
            </div>
          
            <div className="row">
                <div className="col-6 col-md-6 pe-0 pe-md-3">
                <FormDropDown
            labelStyle={{
              marginTop: "15px",
              marginBottom: "10px",
            }}
            Label={"Payment"}
            Options={paymentProgressOptions}
            value={paymentProgress.value}
            onSelect={handlePaymentProgress}
            CustomErrorLine={paymentProgressErrorLine}
            searchable={false}
            starProps={true}
            isValidate={true}
          />
          </div>
          <div className="col-6 col-md-6 pe-0 pe-md-3">
           <FormDropDown
            labelStyle={{
              marginTop: "15px",
              marginBottom: "10px",
            }}
            Label={modeOfPaymentLabel}
            Options={modeOfPaymentOption}
            value={modeOfPayment.value}
            onSelect={handleChangeModeofPayment}
            CustomErrorLine={modeOfPaymentErrorLine}
            searchable={false}
            starProps={true}
            isValidate={true}
          />
                </div>
                </div>
         
         
          {/* <FormTextInput
            type="textarea"
            label={invoiceCommentLabel}
            placeholder={invoiceCommentPlaceholder}
            Value={comment.value}
            onChangeText={handleChangeComment}
            Validate={true}
            CustomErrorLine={"Comment Can not be empty"}
            editable={true}
            starProps={true}
          /> */}
          {/* <FormTextInput
            type="textarea"
            label={deliveryNoteLabel}
            placeholder={deliveryNotePlaceholder}
            Value={deliverynote.value}
            onChangeText={handleChangedeliverynote}
            Validate={true}
            CustomErrorLine={deleiveryNoteErrorLine}
            editable={true}
            multiline={true}
            starProps={true}
          /> */}

          <div
            style={{
              display: "flex",
              marginTop: "1.5rem",
              justifyContent: "space-between",
            }}
          >
            {/* <Button
              buttonName={cancelBtn}
              buttonStyle={{
                backgroundColor: colors.darkgrey,
                marginLeft: "10px",
              }}
              onClick={() => {
                handleModalClose();
              }}
            />
            <Button
              buttonName={nextBtn}
              buttonStyle={{
                backgroundColor: colors.primary,
                marginLeft: "10px",
              }}
              onClick={handleChangeNextScreen}
              disable={
                actionType === "add"
                  ? disableInvoiceScreen1
                  : editDisableInvoiceScreen1 === false
                  ? false
                  : true
              }
            /> */}
          </div>
        </div>
      )}
      {/* {screen === 2 && ( */}
      <div>
        {/* {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>} */}
        <div className="row">
                <div className="col-6 col-md-6 pe-0 pe-md-3">
                <DateAndTimePicker
          dateAndTimeLabel={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
          date={recivedOn.value}
          label={recivedOnLabel}
          customErrorLine={recivedOnErrorLine}
          hadleSetDate={handleChangeRecivedOn}
          disable={false}
          starProps={true}
        />
        </div>
          <div className="col-6 col-md-6 pe-0 pe-md-3">
         <DateAndTimePicker
          dateAndTimeLabel={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
          date={dueOn.value}
          label={dueOnLabel}
          customErrorLine={dueOnErrorline}
          hadleSetDate={handleChangeDueOn}
          disable={false}
          starProps={true}
        />
                </div>
                </div>
       
                <div className="row">
                <div className="col-6 col-md-6 pe-0 pe-md-3">
                <FormTextInput
          type="string"
          label="Amount To Be Paid"
          Value={`${parseFloat(finalAmounts).toFixed(2)}`}
          editable={false}
          starProps={true}
          Validate={false}
        />
       
        </div>
          <div className="col-6 col-md-6 pe-0 pe-md-3">
          <FormTextInput
          type="number"
          label={totalAmountLabel}
          placeholder={totalAmountPlaceholder}
          Value={totalAmount.value}
          onChangeText={handleChangeTotalAmount}
          Validate={true}
          CustomErrorLine={totalAmountErrorLine}
          editable={true}
          starProps={true}
          min={0}
        />
        </div>
        </div>
        {/* <span
          style={{
            position: "absolute",
            top: "258px",
            left: "140px",
            fontSize: "18px",
            color: "#1a1a8d",
            paddingLeft: "10px",
          }}
        >
          ({finalAmounts} AED)
        </span> */}
        <p className="mt-3">Bank Details</p>
        <div className="row d-flex mt-3">
          {/* {letter && ( */}
          <div className={"col-12 col-md-4"}>
            <FormCheckBox
              Label={"Invoice"}
              checked={invoice}
              handleChangeCheck={handleInvoiceChecked}
            />
          </div>
          {/* )} */}
          {/* {invoice && ( */}
          <div className={"col-12 col-md-4"}>
            <FormCheckBox
              Label={"Letter"}
              // checked={document.checked}
              checked={letter}
              handleChangeCheck={handleLetterChecked}
            />
          </div>
          {/* )} */}
        </div>
        {!showBankDetail ? (
          <FormTextInput
            type="textarea"
            // label={bankDetailsLabel}
            placeholder={bankDetailsPlaceholder}
            Value={bankDetails.value}
            onChangeText={handleChangeBankDetails}
            Validate={true}
            CustomErrorLine={bankDetailsErrorLine}
            editable={true}
            multiline={true}
            starProps={true}
          />
        ) : null}

        <div
          style={{
            display: "flex",
            marginTop: "1.5rem",
            justifyContent: "space-between",
          }}
        >
          {/* <Button
            buttonName={prevBtn}
            buttonStyle={{
              backgroundColor: colors.primary,
              marginLeft: "10px",
            }}
            onClick={handleChangePrevScreen}
          />
          <Button
            buttonName={cancelBtn}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
            }}
          /> */}
          {/* {totalAmount.value <= finalAmounts ? (
            <Button
              buttonName={nextBtn}
              buttonStyle={{
                backgroundColor: colors.primary,
                marginLeft: "10px",
              }}
              onClick={handleChangeNextScreen}
              disable={
                actionType === "add"
                  ? disableInvoiceScreen2
                  : editDisableInvoiceScreen2 === false
                  ? false
                  : true
              }
            />
          ) : null} */}
        </div>
      </div>
      {/* )} */}
      {/* {screen === 3 && ( */}
      <div>
        {/* {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>} */}
        <div className="row">
          <label className="mt-3 mb-3 text-dark">
            {requiredDocumentLabel}
            <span className="text-danger">*</span>{" "}
          </label>
          {requiredDocument.map((document, index) => {
            return (
              <div className={"col-12 col-md-4"}>
                <FormCheckBox
                  key={index}
                  Label={document.requiredDocumentName}
                  checked={document.checked}
                  handleChangeCheck={() => {
                    handleChangeCheck(document.key);
                  }}
                />
              </div>
            );
          })}
        </div>
        {/* <div>
          <div>
            <FormDropDown
              labelStyle={{
                marginTop: "12px",
                marginBottom: "10px",
              }}
              value={invoiceStatus.value}
              Label={"Status"}
              Options={invoiceDropdown}
              onSelect={handleInvoiceStatus}
              CustomErrorLine={"Select Status"}
              starProps={true}
              isValidate={true}
            />
          </div>
        </div> */}
        {/* <div className="col-12 col-md-6 pe-0 pe-md-3">
            <FilePicker
              label="Attachment"
              onChange={handleChangeFile}
              isMulti={true}
              starProps={true}
            />

          </div> */}
        <div className="col-12 col-md-12 pe-0 pe-md-3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h3>Attachment</h3>
            <span style={{ color: "red", marginLeft: "4px" }}>*</span>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}>
            {fileName.value ? (
              <>
                <p>{fileName.value}</p>
                <button className="btn" onClick={handleClearFile}>
                  <CloseIcon/>
                </button>
              </>
            ) : (
              <input
                type="file"
                onChange={handleChangeFile}
                accept=".pdf, image/*"
              />
            )}
          </div>
         
         
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "1.5rem",
            justifyContent: "space-between",
          }}
        >
          {/* <Button
            buttonName={prevBtn}
            buttonStyle={{
              backgroundColor: colors.primary,
            }}
            onClick={handleChangePrevScreen}
          />
          <Button
            buttonName={cancelBtn}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
          /> */}
          {actionType === "add" ? (
            <Button
              buttonName={saveBtn}
              buttonStyle={{
                backgroundColor: colors.primary,
                marginLeft: "10px",
              }}
              onClick={() => {
                handleSaveData();
              }}
              disable={actionType === "add" ? disableInvoiceScreen3 : false}
            />
          ) : (
            <>
              <Button
                buttonName={"Update"}
                buttonStyle={{
                  backgroundColor: colors.primary,
                  marginLeft: "10px",
                }}
                onClick={() => {
                  handleSaveData();
                  handleModalClose();
                }}
                disable={actionType === "add" ? disableInvoiceScreen3 : false}
              />
            </>
          )}
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default InvoiceForm;
