import React from "react";

const TableRow = (props) => {
  const { label, value } = props;
  return (
    <tr>
      <td>
        <span className={"mb-2"}>{label}</span>
      </td>
      :
      {/* <td>
                <span className={'mb-2'}>&nbsp; : &nbsp;</span>
            </td> */}
      <td>
        <span className={"mb-2"}>{value}</span>
      </td>
    </tr>
  );
};

export default TableRow;
