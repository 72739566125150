/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { Button, DateAndTimePicker, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import "./Requestforinformation.css";

export const EditCell = ({ rowData, dataKey, onChange, ...props }) => {
  const editing = rowData.status === "EDIT";
  return (
    <Cell {...props} className={editing ? "table-content-editing" : ""}>
      {editing ? (
        <input
          className="rs-input"
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            onChange && onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
  return (
    <Cell {...props} style={{ padding: "6px" }}>
      <Button
        appearance="link"
        onClick={() => {
          onClick && onClick(rowData.id);
        }}
      >
        {rowData.status === "EDIT" ? "Save" : "Edit"}
      </Button>
    </Cell>
  );
};

const RfiForm = ({ ...props }) => {
  const {
    rfiCodes,
    starProps,
    budget_id,
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    selectedItem,
    handleModalClose,
  } = props;

  const createRFITitle = Strings["RequestforInformation.createRFI"],
    editRFITitle = Strings["RequestforInformation.editRFITitle"],
    // deleteRFITitle = Strings["RequestforInformation.deleteRFITitle"],
    RFINameLabel = Strings["RequestforInformation.RFINameLabel"],
    RFINameLabelError = Strings["RequestforInformation.RFINameLabelError"],
    RFINamePlaceholder = Strings["RequestforInformation.RFINamePlaceholder"],
    RFIDescriptionLabel = Strings["RequestforInformation.RFIDescriptionLabel"],
    RFIDescriptionError = Strings["RequestforInformation.RFIDescriptionError"],
    RFIDescriptionPlaceHolder =
      Strings["RequestforInformation.RFIDescriptionPlaceHolder"],
    checkboxLabel = Strings["RequestforInformation.checkboxLabel"],
    SaveButton = Strings["RequestforInformation.saveButton"],
    CancelButton = Strings["RequestforInformation.CancelButton"],
    UpdateButton = Strings["RequestforInformation.UpdateButton"],
    userId = Strings["RequestforInformation.TableHead.userId"],
    itemName = Strings["RequestforInformation.TableHead.itemName"],
    vendor = Strings["RequestforInformation.TableHead.vendor"],
    quantity = Strings["RequestforInformation.TableHead.quantity"],
    uom = Strings["RequestforInformation.TableHead.uom"];

  const [RFIName, setRFIName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });
  const [designerName, setDesignerName] = useState({
    value: initialValue.name ? initialValue.designer : "",
    error: false,
    success: false,
  });
  const [description, setDescription] = useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });
  const [note, setNote] = useState({
    value: initialValue.notes ? initialValue.notes : "",
    error: false,
    success: false,
  });

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.selectedFile ? initialValue.selectedFile : "",
    error: false,
    success: false,
  });
  const [deadline, setDeadline] = React.useState({
    value: initialValue.deadline ? initialValue.deadline : "",
    error: false,
    success: false,
  });
  const [rfiCode, setRfiCode] = React.useState({
    value: actionType === "add" ? rfiCodes : initialValue.rfiNumber,
    error: false,
    success: false,
  });
  const [emailAddress, setEmailAddress] = React.useState([]);
  const [emailCcAddress, setEmailCcAddress] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [array, setArray] = useState([]);

  const [sendtoClient, setSendtoClient] = React.useState(
    initialValue.activeStatus && initialValue.activeStatus
  );
  let budget_id_arr = [];
  if (initialValue.budget_item) {
    const result = initialValue.budget_item.map((ele) => {
      budget_id_arr.push(ele.id);
    });
  } else {
    const result = initialValue.budget_item.map((ele) => {
      budget_id_arr.push(ele.id);
    });
  }

  const filterData = initialValue.budget_item.map((elm) => ({
    budget_item_id: elm.budgetitem.control_number,
    itemName: elm.budgetitem.name,
    budgetQuantity: elm.budgetitem.budgetquantitydetails.budget_quantity,
    budgetUOM: elm.budgetitem.budgetquantitydetails.budgetuom?.name,
    description: elm.description,
  }));

  const handleRFIName = (value) => {
    setMessage(null);
    setRFIName(value);
  };
  const handleDesignerName = (value) => {
    setMessage(null);
    setDesignerName(value);
  };

  const handledescription = (value) => {
    setDescription(value);
  };
  const handlenotes = (value) => {
    setNote(value);
  };

  const handleChangeFile = (data) => {
    setSelectedFile(data);
    let datass = data.value[0];
    setArray((oldArray) => [...oldArray, datass]);
  };
  const handleChangeDeadline = (data) => {
    setDeadline(data);
  };
  const handleChangeRfiCode = (value) => {
    setMessage(null);
    setRfiCode(value);
  };

  const handleChangeEmailAddress = (value) => {
    setEmailAddress(value);
  };
  const handleChangeEmailCcAddress = (value) => {
    setEmailCcAddress(value);
  };

  const handleDeleteDoc = (e) => {
    let newArray = Array.from(new Set(array)).slice(1);
    let newArray1 = newArray.filter((file) => file.name !== e.name);
    setArray(newArray1);
    // setSelectedFile(newArray1);
  };

  const handleChangeCheck = () => {
    setSendtoClient(!sendtoClient);
  };

  const [data, setData] = React.useState(filterData);

  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find((item) => item.id === id)[key] = value;
    setData(nextData);
  };
  const handleEditState = (id) => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find((item) => item.id === id);
    activeItem.status = activeItem.status ? null : "EDIT";
    setData(nextData);
  };

  let resItems = initialValue.budget_item.map(
    ({ budget_item_id, description }) => ({
      budget_item_id,
      description,
    })
  );

  const handleSaveData = () => {
    let newFileObj = Array.from(new Set(array)).slice(1);
    let path = [];
    newFileObj.map((files) => {
      path.push(files.name);
    });

    const formData = {
      name: RFIName.value,
      rfi_number: rfiCode.value,
      // description: description.value,
      notes: note.value,
      rfi_send_to: designerName.value,
      deadline: deadline.value,
      email: emailAddress,
      cc_email: emailCcAddress,
      budget_id: Number(budget_id),
      budget_item_id: resItems,
      filepath: [],
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged =
    RFIName.value === initialValue.name &&
    description.value === initialValue.description &&
    selectedFile.value === initialValue.selectedFile;

  let disable =
    RFIName.error ||
    description.error ||
    selectedFile.error ||
    RFIName.value === "" ||
    description.value === "" ||
    selectedFile.value === "" ||
    (RFIName.success === false &&
      description.success === false &&
      selectedFile.success === false) ||
    isValueNOTChanged;

  const mapableData = [
    {
      dataKey: "budget_item_id",
      title: "Control Number",
    },
    {
      dataKey: "itemName",
      title: itemName,
    },
    // {
    //   dataKey: "vendor.name",
    //   title: vendor,
    // },
    {
      dataKey: "budgetQuantity",
      title: quantity,
    },
    {
      dataKey: "budgetUOM",
      title: uom,
    },
    {
      dataKey: "query",
      title: "Query",
    },
  ];

  return (
    <div className="modalFormContainer">
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>{actionType === "add" ? createRFITitle : editRFITitle}</h2>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={RFINameLabel}
            placeholder={RFINamePlaceholder}
            Value={RFIName.value}
            onChangeText={handleRFIName}
            Validate={true}
            CustomErrorLine={RFINameLabelError}
            editable={false}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={"RFI Number"}
            placeholder={"RFI Number"}
            Value={rfiCode.value}
            onChangeText={handleChangeRfiCode}
            Validate={true}
            CustomErrorLine={RFINameLabelError}
            editable={false}
            starProps={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={"RFI Send To"}
            placeholder={"Enter Designer Name"}
            Value={designerName.value}
            onChangeText={handleDesignerName}
            Validate={true}
            CustomErrorLine={RFINameLabelError}
            editable={false}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <DateAndTimePicker
            label={"Deadline To Answer"}
            // customErrorLine={deadlineCustomErrorLine}
            hadleSetDate={handleChangeDeadline}
            date={deadline.value}
            disable={true}
            starProps={true}
            editable={false}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "1px",
              marginTop: 15,
            }}
          >
            Email Id
            <Tooltip
              title="You Can Enter Multiple Email id"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailAddress}
              onChange={(emails) => {
                setEmailAddress(emails);
              }}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style-date">
          <p
            style={{
              fontWeight: "400",
              color: "black",
              marginBottom: "1px",
              marginTop: 15,
            }}
          >
            CC To
            <Tooltip
              title="You Can Enter Multiple CC"
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 22, marginLeft: "4px" }} />
            </Tooltip>
          </p>
          <div className="multiEmail">
            <ReactMultiEmail
              placeholder="Enter Email Address"
              emails={emailCcAddress}
              onChange={(emails) => {
                setEmailCcAddress(emails);
              }}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            label={"Note"}
            placeholder={"Enter Note"}
            Value={note.value}
            onChangeText={handlenotes}
            Validate={true}
            CustomErrorLine={RFIDescriptionError}
            editable={false}
            multiline={true}
            starProps={true}
            disable={true}
          />
        </div>
      </div>
      <div className="row">
        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <Button
            buttonName={actionType === "add" ? SaveButton : UpdateButton}
            buttonStyle={{ backgroundColor: colors.primary }}
            // disable={disable}
            onClick={handleSaveData}
          />
          <Button
            buttonName={CancelButton}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
          />
        </div>
      </div>
      <h2 className="my-3">Budget Items</h2>
      <Table height={200} data={data}>
        <Column width={125}>
          <HeaderCell>Control No</HeaderCell>
          <Cell dataKey="budget_item_id" />
        </Column>

        <Column width={125}>
          <HeaderCell>Item Name</HeaderCell>
          <Cell dataKey="itemName" />
        </Column>
        <Column width={125}>
          <HeaderCell>Qty</HeaderCell>
          <Cell dataKey="budgetQuantity" />
        </Column>
        <Column width={125}>
          <HeaderCell>UOM</HeaderCell>
          <Cell dataKey="budgetUOM" />
        </Column>
        <Column width={125}>
          <HeaderCell>Query</HeaderCell>
          <EditCell dataKey="description" onChange={handleChange} />
        </Column>
      </Table>
    </div>
  );
};

export default RfiForm;
