import { Payment } from "@material-ui/icons";
import AddCardSharpIcon from "@mui/icons-material/AddCardSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  ChangeInvoiceStatus,
  CreateInvoice,
  CreatePaymentInvoice,
  DeleteInvoicePayment,
  GetAllActiveInvoice,
  GetPaymentByInvoiceId,
  GetPaymentHistoryBYInvoiceById,
  UpdateInvoice,
  getPaymentRequestHistory
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  IconButton,
  Modal,
  NodataFound,
} from "../../../components";
import SearchPaymentTable from "../../../components/AppSpecific/SearchTable/SearchPaymentTable";
import LoaderComponent from "../../../components/common/Loader";
import ModalPdf from "../../../components/common/ModalPdf";
import { colors } from "../../../configs";
import { InvoiceForm, InvoicePaymentForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_INVOICE_STATUS_FUNCTION_PAYMENT,
  UPDATE_INVOICE,
  CREATE_INVOICE,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import InvoiceFileUpload from "./InvoiceFileUpload";
import PaymentRequestDownload from "./PaymentRequestDownload";
import "./SearchInvoice.css";
import Chip from '@mui/material/Chip';
import {
 BlobProvider
} from "@react-pdf/renderer";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const InvoiceTitle = Strings["Invoice.InvoiceTitle"],
  alertMessage = Strings["Invoice.AlertMessage"],
  alertTitle = Strings["Invoice.AlertTitle"],
  Action = Strings["Invoice.table.Action"],
  Invoice = Strings["Invoice.table.Invoice"],
  Vendor = Strings["Invoice.table.Vendor"],
  Amount = Strings["Invoice.table.Amount"],
  DueOn = Strings["Invoice.table.DueOn"],
  Attachment = Strings["Invoice.table.Attachment"];

const headCells = [
  {
    id: "invoice",
    numeric: false,
    disablePadding: true,
    label: Invoice,
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: false,
    label: Vendor,
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: Amount,
  },

  {
    id: "dueon",
    numeric: true,
    disablePadding: false,
    label: DueOn,
  },

  {
    id: "attachment",
    numeric: true,
    disablePadding: true,
    label: Attachment,
  },
  {
    id: "action",
    disablePadding: true,
    label: "Status",
  },
  {
    id: "action",
    disablePadding: true,
    label: "Last Payment Date",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: Action,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SearchInvoice = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [payOpen, setPayOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [setTotalData] = React.useState(null);
  const [currentType, setCurrentType] = React.useState({});
  const [currentPaymentType, setCurrentPaymentType] = React.useState({});

  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [alertDeleteBox, setAlertDeleteBox] = React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [invoiceId, setInvoiceId] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setUpdate] = React.useState(true);
  const [isRFQOpen, setIsRFQOpen] = React.useState(false);
  const [downloadData, setDownloadData] = React.useState();
  const [isPaymentOpen, setPaymentIsOpen] = React.useState(false);
  const [clickedPaymentBtn, setClickedPaymentBtn] = React.useState("");
  const [paymentRows, setPaymentRows] = useState([]);
  const [paymentTotalData, setPaymentTotalData] = useState([]);
  const poDataType = localStorage.getItem("directPO");
  const [deleteId, setDeleteId] = React.useState();
  const [isStatus, setIsStatus] = React.useState(true);
  const [paymentHistoryData, setpaymentHistoryData] = React.useState();

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_INVOICE)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_INVOICE)) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  }, []);

  const datass = useLocation();
  const location = useLocation();

  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const purchase_id = parseInt(paramId);

  const proId = location?.state
    ? location.state.proId
    : location?.query
    ? JSON.parse(location.query.params).proId
    : null;
  const budget_id = location?.state
    ? location.state.budget_id
    : location?.query
    ? JSON.parse(location.query.params).budget_id
    : null;
  const rfqId = location?.state
    ? location.state.rfqId
    : location?.query
    ? JSON.parse(location.query.params).rfqId
    : null;

  const invoice = location?.state
    ? location.state.pState
    : location?.query
    ? JSON.parse(location.query.params).pState
    : null;

  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  const encodedRfqId = isEncoded(rfqId);
  const encodePurchaseId = isEncoded(purchase_id);

  // var rfqId = "";
  // if (location.state.poType == undefined) {
  //   rfqId = location.state
  //     ? location.state.rfqId
  //     : location.query
  //       ? JSON.parse(location.query.params).rfqId
  //       : paramId;
  // }

  var paths = [];
  if (poDataType == "true") {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodeBudId}`,
      },
      {
        name: strings["Invoice"],
      },
    ];
  } else {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["Rfq"],
        path: `rfq/${encodeBudId}`,
      },
      {
        name: strings["Quotation"],
        path: `quotation/${encodedRfqId}`,
      },
      {
        name: strings["costanlysisText"],
        path: `costanalysis/${encodedRfqId}`,
      },
      {
        name: strings["CostReport"],
        path: `searchcostreport/${encodedRfqId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: `purchaseorders/${encodedRfqId}`,
      },
      {
        name: strings["Invoice"],
      },
    ];
  }

  const getAllInvoice = () => {
    const id = purchase_id;
    setisLoader(true);
    GetAllActiveInvoice(id, rowsPerPage, page)
      .then((res) => {
        console.log(res);
        setisLoader(false);

        setRows(res.data.data);
        console.log("DATAAAAAAAAAAA",res.data.data)
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAllInvoice();
  }, []);

  const openAddModal = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
    setPayOpen(false);
    setPaymentModalOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const handleRFQClose = () => {
    setIsRFQOpen(false);
  };

  const createInvoiceData = (formData) => {
    setisLoader(true);
    CreateInvoice(formData)
      .then((res) => {
        getAllInvoice();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateInvoice = (formData) => {
    const id = currentType.id;
    setisLoader(true);
    UpdateInvoice(id, formData)
      .then((res) => {
        getAllInvoice();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const createPaymentInvoice = (formData) => {
    let id = invoiceId;
    formData.invoice_id = id;
    setisLoader(true);
    CreatePaymentInvoice(formData)
      .then((res) => {
        getAllInvoice();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const createBulkBudgetItem = (data) => {};

  const selectModal = () => {
    if (clickedBtn === "New CSV File") {
      return (
        <InvoiceFileUpload
          actionType={"add"}
          handleModalClose={handleModalClose}
          handleChangeData={createBulkBudgetItem}
          purchase_order_id={datass.state.pState.id}
        />
      );
    } else {
      return (
        <InvoiceForm
          poDetail={datass.state}
          budgetId={budget_id}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleInvoiceData={
            clickedBtn === "add" ? createInvoiceData : updateInvoice
          }
          actionType={clickedBtn}
          initialValue={clickedBtn === "add" ? {} : currentType}
          handleModalClose={handleModalClose}
        />
      );
    }
  };


  const paymentModal = () => {
    return (
      <InvoicePaymentForm
        handleModalClose={handleModalClose}
        handleChangeData={createPaymentInvoice}
      />
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangeCancel = () => {
    setMessage(null);
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    ChangeInvoiceStatus(statusChangeId)
      .then((res) => {
        getAllInvoice();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch(() => {});
    setAlertBox(false);
  };
  let history = useHistory();

  const createInvoicePayment = (formData) => {
    setisLoader(true);
    CreatePaymentInvoice(formData)
      .then((res) => {
        setisLoader(false);
        getAllInvoice();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handlePaymentModalClose();
        // setRows([res.data.data, ...rows]);
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
      
  };

  const openAddPaymentModal = () => {
    setPaymentIsOpen(true);
  };
  const handlePaymentModalClose = () => {
    setMessage(null);
    setPaymentIsOpen(false);
  };
  const handlePaymentSelectBtn = (btn) => {
    setMessage(null);
    setClickedPaymentBtn(btn);
  };
  const handlePaymentAction = (id) => {
    setInvoiceId(id);
    setisLoader(true);

    const resultMain = rows.find((row) => {
      return row.id === id;
    });
    setCurrentPaymentType(resultMain);
    GetPaymentByInvoiceId(id, rowsPerPage, page)
      .then((res) => {
        setisLoader(false);
        const result = res.data.data.map((ele) => ({
          amount: ele.amount,
          created_on: ele.created_on.split(" ")[0],
          currency: ele.currency,
          currency_id: ele.currency_id,
          id: ele.id,
          invoice_id: ele.invoice_id,
          paid_at: ele.paid_at.split(" ")[0],
          paid_date: moment(ele.paid_at.split(" ")[0]).format("DD MMM YYYY"),
          remarks: ele.remarks,
          serial_number: ele.serial_number,
        }));
        setPaymentRows(result);
        setPaymentTotalData(res.data.total);
        
        if(getStatus(resultMain) == "PAID"){
            swal({
              title: "Warning",
              text: "Already Paid",
              icon: "warning",
            });
        }
        else{
            openAddPaymentModal();
            handlePaymentSelectBtn("add");
        }
         
        
      })
      .catch((e) => {
        setisLoader(false);
      });

  };

  const getPaymentRequestHistory = (id) => {
   
  
    GetPaymentHistoryBYInvoiceById(purchase_id,id)
      .then((res) => {
        console.log(res);
       
        setpaymentHistoryData(res.data.data);
      })
      .catch((e) => {
        setisLoader(false);
      });

  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const handlecsvUploadItem = (id) => {
  //   openAddModal();
  //   handleSelectBtn("New CSV File");
  // };

  const paymentRequest = (id) => {
    const res = rows.find((ele) => {
      return ele.id === id;
    });
    setDownloadData(res);
    getPaymentRequestHistory(id)
    setIsRFQOpen(true);
  };
  const selectDownloadModal = () => {
    return <PaymentRequestDownload data={downloadData} history={paymentHistoryData}/>;
  };
  console.log(downloadData);
  const selectPaymentModal = () => {
    return (
      <InvoicePaymentForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeData={
          clickedPaymentBtn === "add" ? createInvoicePayment : ""
        }
        actionType={clickedPaymentBtn}
        initialValue={clickedPaymentBtn == "add" ? currentPaymentType : {}}
        handleModalClose={handleModalClose}
        invoiceID={invoiceId}
        purchase_id ={purchase_id}
        budgetId = {budget_id}
      />
    );
  };
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);

  const getAlldata = (id) => {
    setisLoader(true);
    GetPaymentByInvoiceId(id, rowsPerPage, page)
      .then((res) => {
        setisLoader(false);
        const result = res.data.data.map((ele) => ({
          amount: ele.amount,
          created_on: ele.created_on.split(" ")[0],
          currency: ele.currency,
          currency_id: ele.currency_id,
          id: ele.id,
          invoice_id: ele.invoice_id,
          paid_at: ele.paid_at.split(" ")[0],
          paid_date: moment(ele.paid_at.split(" ")[0]).format("DD MMM YYYY"),
          remarks: ele.remarks,
          serial_number: ele.serial_number,
        }));
        setPaymentRows(result);
        setPaymentTotalData(res.data.total);
        setPaymentModalOpen(true);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  const handleChangeDelete = (id) => {
    setDeleteId(id);
    setAlertDeleteBox(!alertBox);
  };
  const handleChangeDeleteCancel = () => {
    setAlertDeleteBox(false);
  };
  const handleChangeDeleteSuccess = () => {
    setisLoader(true);
    DeleteInvoicePayment(deleteId)
      .then((res) => {
        // getAlldata();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setisLoader(false);
        setAlertDeleteBox(false);
        setPaymentModalOpen(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
    console.log(paymentRows);
  };
  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_INVOICE_STATUS_FUNCTION_PAYMENT)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const getStatus = (row) => {
    let paidAmount = 0.00;
    if(row.invoice_payment != null){
      if(row.invoice_payment.length != 0){
        paidAmount = row.invoice_payment.reduce((acc, curr) => {
            return parseFloat(acc + parseFloat(curr.amount));
          }, 0) 
      }
      else{
        return "UNPAID"
      }
    }
    else{
      return "UNPAID"
    }
    if(parseFloat(row.total_amount) > parseFloat(paidAmount) && parseFloat(paidAmount) != 0){
       return "PARTIALLY PAID"
    }
    if(parseFloat(row.total_amount) === parseFloat(paidAmount)){
      return "PAID"
    }
  }

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <div className="container mb-5 pb-5">
        <Helmet>
          <title>Furnish | Invoice</title>
        </Helmet>
        {alertDeleteBox && (
          <AlertMessage
            title={alertTitle}
            message={alertMessage}
            onChangeCancel={handleChangeDeleteCancel}
            onChangeSucess={handleChangeDeleteSuccess}
          />
        )}
        <Modal onHandleClose={handlePaymentModalClose} isOpen={isPaymentOpen}>
          {selectPaymentModal()}
        </Modal>
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <Modal onHandleClose={handleModalClose} isOpen={payOpen}>
          {paymentModal()}
        </Modal>

        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>

        <ModalPdf isOpen={isRFQOpen} onHandleClose={handleRFQClose}>
          {selectDownloadModal()}
        </ModalPdf>
        <div
          className="row mt-3"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col-md-7">
            <h3> {InvoiceTitle} </h3>
            <BreadCrumbs
              pathList={paths}
              obj={{
                proId: proId,
                budget_id: budget_id,
                rfqId: rfqId,
                invoice_id: invoice?.id,
              }}
            />
          </div>
          <div className="col-md-5 d-flex justify-content-end mt-3">
            {/* {rows.length > 0 && (
              <>
                <button
                  className={"invoicebutton me-3"}
                  onClick={() => {
                    history.push({
                      pathname: `/deliverynote/${encodePurchaseId}`,
                      state: {
                        proId: proId,
                        budget_id: budget_id,
                        rfqId: rfqId,
                        invoice_id: invoice?.id,
                        purchase_id: purchase_id,
                      },
                    });
                  }}
                >
                  Add Delivery Note
                </button>
                <button
                  className={"invoicebutton me-3"}
                  onClick={() => {
                    history.push({
                      pathname: `/paymentRequest/${encodePurchaseId}`,
                      state: {
                        proId: proId,
                        budget_id: budget_id,
                        rfqId: rfqId,
                        invoice_id: invoice?.id,
                      },
                    });
                  }}
                >
                  Payment Requests
                </button>
              </>
            )} */}
            {isAdd && (
              <span className="addButton pt-1">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            )}
          </div>
        </div>

        {alertBox && (
          <AlertMessage
            title={alertTitle}
            message={alertMessage}
            onChangeCancel={handleChangeCancel}
            onChangeSucess={handleChangeSuccess}
          />
        )}
        {rows.length > 0 ? (
          <Box className="mt-5" sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.invoice_name}
                            </TableCell>
                            <TableCell align="left">
                              {row.purchase_order.vendor.name}
                            </TableCell>
                            <TableCell align="left">
                              {row.total_amount}
                            </TableCell>
                            <TableCell align="left">
                              {moment(row.due_on.split(" ")[0]).format(
                                "DD MMM YYYY"
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                            >
                              <a
                                href={
                                  process.env.REACT_APP_BASE_URL2 +
                                  row.files[0]?.file_path
                                }
                                target="_blank"
                                className="nav-link"
                                rel="noreferrer"
                              >
                                {row.files[1]?.file_path
                                  ? row.files[1]?.file_path
                                  : null}
                              </a>
                            </TableCell>
                            <TableCell align="left">
                                <Chip size="small" label={getStatus(row)} color={getStatus(row) == "PAID" ? "success" : getStatus(row) == "PARTIALLY PAID" ? "warning": "error"}/>
                            </TableCell>
                            <TableCell align="left">
                                {row?.invoice_payment && moment(row?.invoice_payment[0]?.paid_at).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell align="left">
                              {isUpdate &&
                              <>
                              <IconButton
                                onClick={() => {
                                  openAddModal();
                                  handleSelectBtn("edit");
                                  setCurrentType(row);
                                }}
                              >
                                <Tooltip title="Edit">
                                  <Edit sx={{ color: colors.primary }} />
                                </Tooltip>
                              </IconButton>
                              </>
                              }
                              {isAdd &&
                              <IconButton
                                onClick={() => {
                                  handlePaymentAction(row.id);
                                }}
                              >
                                <Tooltip title="Add to Payment">
                                  <AddCardSharpIcon
                                    sx={{ color: colors.primary }}
                                  />
                                </Tooltip>
                              </IconButton>
                              }
                              <IconButton
                                onClick={() => {
                                  getAlldata(row.id);
                                }}
                              >
                                <Tooltip title="View Payment">
                                  <Payment sx={{ color: colors.primary }} />
                                </Tooltip>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  paymentRequest(row.id);
                                }}
                              >
                                   <Tooltip title="Download">
                                  
                                  <SaveAltIcon sx={{ color: colors.primary }} />
                                </Tooltip>
                                {/* <BlobProvider
                                    document={
                                      <PaymentRequestDownload data={downloadData} history={paymentHistoryData}/>
                                    }
                                  >
                                    {({ url }) => (
                                      <a href={url} target="_blank"
                                      >
                                      
                                      </a>
                                    )}
                                  </BlobProvider> */}
                               
                              </IconButton>
                              {isUpdate && 
                              <IconButton
                                onClick={() => {
                                  setStatusChangeId(row.id);
                                  setAlertBox(true);
                                }}
                              >
                                <Tooltip title="Delete">
                                  <DeleteIcon sx={{ color: colors.danger }} />
                                </Tooltip>
                              </IconButton>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={CustomTablePaginationActions}
              />
            </Paper>
          </Box>
        ) : (
          <>
            {isLoader ? (
              <LoaderComponent />
            ) : (
              <NodataFound
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              />
            )}
          </>
        )}
      </div>
      <Modal onHandleClose={handleModalClose} isOpen={isPaymentModalOpen}>
        <SearchPaymentTable
          // isUpdate={isUpdate}
          isStatus={isStatus}
          placeholder={"name"}
          data={paymentRows}
          mapableData={[
            {
              dataKey: "paid_date",
              title: "Paid Date",
            },
            {
              dataKey: "currency.name",
              title: "Currency",
            },
            {
              dataKey: "amount",
              title: "Amount",
            },
            {
              dataKey: "id",
              title: "Action",
              isAction: true,
            },
          ]}
          searchProp={["paid_at"]}
          handleChangeDelete={handleChangeDelete}
          // handleChangeEdit={handleChangeEdit}
        />
      </Modal>
    </>
  );
};

export default SearchInvoice;
