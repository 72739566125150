
import TablePagination from "@mui/material/TablePagination";
import React from "react";
import { Helmet } from "react-helmet";
import ModalPdf from "../../../components/common/ModalPdf";
import {
    GetPOStatusByVendor,
} from "../../../axios";
import {
    BreadCrumbs,
    PurchaseOrderTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import "../SearchBudgetItem/BudgetItem.css";
import QuotationDownload from "../SearchQuotation/QuotationDownload";
import VendorPoDownload from "../SearchPurchaseOrdersPage/VendorPoDownload";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";
import { IconButton } from "rsuite";


const PurchaseOrderStatus = () => {
    const
        ProjectName = Strings["ProjectManagement.nameLabel"],
        Furnish = Strings["Title.Furnish"],
        serialNo = Strings["PoStatus.PO.Number"],
        Name = Strings["PoStatus.PO.Name"],
        action = Strings["Document.tableHead.action"];

    const [isLoader, setisLoader] = React.useState(false);
    const [poData, setPoData] = React.useState([]);
    const [openDownload] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [totalData, setTotalData] = React.useState(0);
    const [isRFQOpen, setIsRFQOpen] = React.useState(false);
    const [downloadData, setDownloadData] = React.useState();

    const mapableDataPo = [
        {
            dataKey: "purchase_order_item_details[0].budgetitem.budget.project.name",
            title: ProjectName,
        },
        {
            dataKey: "purchase_order_serial_number",
            title: serialNo,
        },
        {
            dataKey: "purchase_order_name",
            title: Name,
        },
        {
            dataKey: "id",
            title: action,
            isAction: true,
            isDownload: true,
        },
    ];
    const paths = [
        {
            name: strings["DashBoardText"],
            path: "",
        },
        {
            name: "PurchaseOrder",
        },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleRFQClose = () => {
        setIsRFQOpen(false);
    };


    const getAllRfq = () => {
        const vendorId = JSON.parse(localStorage.getItem("vendor"));
        setisLoader(true);
        GetPOStatusByVendor(vendorId)
            .then((res) => {
                setPoData(res.data.data);
                setTotalData(res.data.total);
                setisLoader(false);
            })
            .catch(() => {
                setisLoader(false);
            });
    };

    React.useEffect(() => {
        getAllRfq();
    }, [rowsPerPage, page]);


    const handleDownload = (data) => {
        setDownloadData(data);
        setIsRFQOpen(true);
    };

    const selectDownloadModal = () => {
        return <VendorPoDownload data={downloadData} />;
    };

    function CustomTablePaginationActions(props) {
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (event) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              <FirstPage />
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              <KeyboardArrowRight />
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              <LastPage />
            </IconButton>
          </div>
        );
      }

    return (
        <div className="container mt-4">
            <Helmet>
                <title>{Furnish} | {"Purchase Order"} </title>
            </Helmet>
            <ModalPdf isOpen={isRFQOpen} onHandleClose={handleRFQClose}>
                {selectDownloadModal()}
            </ModalPdf>
            {isLoader ? <LoaderComponent /> : null}

            <div className="mb-2">
                <h3 className=""> {strings["PurchaseOrders.poStatusSelectText"]} </h3>
                <BreadCrumbs pathList={paths} />
            </div>
            {openDownload ? <QuotationDownload data={downloadData} /> : null}
            <div
                className="RfqbudgetTable"
                style={{
                    marginBottom: "40px",
                }}
            >
                <PurchaseOrderTable
                    data={poData}
                    mapableData={mapableDataPo}
                    searchProp={["purchase_order_serial_number"]}
                    handleDownload={handleDownload}
                />
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={CustomTablePaginationActions}
                />
            </div>
        </div>
    );
};

export default PurchaseOrderStatus;
