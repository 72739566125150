import moment from "moment";

const getPrettyDate = (date) => {
  return moment(date).format("Do MMM, YYYY");
};
export { getPrettyDate };
const authdata = JSON.parse(localStorage.getItem("authorization"));

const isAuthorizedFunc = (funcCode) => {
  if (authdata?.includes(funcCode)) {
    return true;
  } else {
    return false;
  }
};
export { isAuthorizedFunc };

const isLoginType = (type) => {
  if (type == "user") {
    return true
  } else {
    return false;
  }
}
export { isLoginType };
