/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ProductAndServicesForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createProductAndServicesTitle =
      Strings["ProductAndServices.createProductAndServicesTitle"],
    editProductAndServicesTitle =
      Strings["ProductAndServices.editProductAndServicesTitle"],
    productAndServicesNameLabel =
      Strings["ProductAndServices.productAndServicesNameLabel"],
    productAndServicesNamePlaceholder =
      Strings["ProductAndServices.productAndServicesNamePlaceholder"],
    productAndServicesNameCustomError =
      Strings["ProductAndServices.productAndServicesNameCustomError"],
    productAndServicesDescriptionLabel =
      Strings["ProductAndServices.productAndServicesDescriptionLabel"],
    productAndServicesDescriptionPlaceholder =
      Strings["ProductAndServices.productAndServicesDescriptionPlaceholder"],
    productAndServicesDescriptionCustomError =
      Strings["ProductAndServices.productAndServicesDescriptionCustomError"],
    productAndServicesCodeLabel =
      Strings["ProductAndServices.productAndServicesCodeLabel"],
    productAndServicesCodePlaceholder =
      Strings["ProductAndServices.productAndServicesCodePlaceholder"],
    productAndServicesCodeCustomError =
      Strings["ProductAndServices.productAndServicesCodeCustomError"],
    productAndServicesSaveBtn =
      Strings["ProductAndServices.productAndServicesSaveBtn"],
    productAndServicesUpdateBtn =
      Strings["ProductAndServices.productAndServicesUpdateBtn"],
    productAndServicesCancelBtn =
      Strings["ProductAndServices.productAndServicesCancelBtn"];

  const [productAndServicesName, setProductAndServicesName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [productAndServicesDescription, setProductAndServicesDescription] =
    React.useState({
      value: initialValue.description ? initialValue.description : "",
      error: false,
      success: false,
    });

  const [productAndServicesCode, setProductAndServicesCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    success: false,
  });

  const handleChangeProductAndServiceName = (value) => {
    setMessage(null);
    setProductAndServicesName(value);
  };

  const handleChangeProductAndServiceDescription = (value) => {
    setProductAndServicesDescription(value);
  };

  const handleChangeProductAndServiceCode = (value) => {
    setMessage(null);
    setProductAndServicesCode(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: productAndServicesName.value,
      description: productAndServicesDescription.value,
      code: productAndServicesCode.value,
    };
    handleChangeFormData(formData);
  };

  let isValueChanged =
    productAndServicesName.value === initialValue.name &&
    // productAndServicesDescription.value === initialValue.description &&
    productAndServicesCode.value === initialValue.code;

  let disable =
    productAndServicesName.error ||
    // productAndServicesDescription.error ||
    productAndServicesCode.error ||
    productAndServicesName.value === "" ||
    // productAndServicesDescription.value === "" ||
    productAndServicesCode.value === "" ||
    (productAndServicesName.success === false &&
      // productAndServicesDescription.success === false &&
      productAndServicesCode.success === false) ||
    isValueChanged;

  //edit
  let isValueChangededit =
    productAndServicesName.value === initialValue.name &&
    productAndServicesDescription.value === initialValue.description &&
    productAndServicesCode.value === initialValue.code;

  let Editdisable =
    productAndServicesName.error ||
    productAndServicesDescription.error ||
    productAndServicesCode.error ||
    productAndServicesName.value === "" ||
    productAndServicesDescription.value === "" ||
    productAndServicesCode.value === "" ||
    (productAndServicesName.success === false &&
      productAndServicesDescription.success === false &&
      productAndServicesCode.success === false) ||
    isValueChangededit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add"
          ? createProductAndServicesTitle
          : editProductAndServicesTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="alpha"
        label={productAndServicesNameLabel}
        placeholder={productAndServicesNamePlaceholder}
        Value={productAndServicesName.value}
        onChangeText={handleChangeProductAndServiceName}
        Validate={true}
        CustomErrorLine={productAndServicesNameCustomError}
        editable
        maxLength={30}
      />
      <FormTextInput
        type="alpha"
        label={productAndServicesDescriptionLabel}
        placeholder={productAndServicesDescriptionPlaceholder}
        Value={productAndServicesDescription.value}
        onChangeText={handleChangeProductAndServiceDescription}
        Validate={false}
        CustomErrorLine={productAndServicesDescriptionCustomError}
        multiline={true}
        editable
        maxLength={50}
      />
      <FormTextInput
        type="alpha"
        starProps={true}
        label={productAndServicesCodeLabel}
        placeholder={productAndServicesCodePlaceholder}
        Value={productAndServicesCode.value}
        onChangeText={handleChangeProductAndServiceCode}
        Validate={true}
        CustomErrorLine={productAndServicesCodeCustomError}
        editable
        maxLength={30}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add"
              ? productAndServicesSaveBtn
              : productAndServicesUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : Editdisable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={productAndServicesCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default ProductAndServicesForm;
