import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import "./DatePicker.css";
import { Style } from "./style";
const DateAndTimePicker = (props) => {
  const {
    dateAndTimeContainer,
    dateAndTimeLabel,
    dateAndTimeInput,
    minDate,
    maxDate,
    hadleSetDate,
    date,
    customErrorLine,
    label,
    disable,
    starProps,
  } = props;

  const [defaultDate, setDefaultDate] = useState({
    value: date ? new Date(date) : null,
    error: false,
    success: true,
  });
  const [error, setError] = useState("");

  const handleChange = (date) => {
    const data = {
      value: date,
      error: false,
      success: true,
    };
    setDefaultDate(data);
    hadleSetDate({
      value: moment(date.value).format("YYYY-MM-DD"),
      error: false,
      success: true,
    });
    setError("");
  };

  const handleBlur = () => {
    if (defaultDate.value) {
      const data = {
        value: defaultDate.value,
        error: false,
        success: true,
      };
      setDefaultDate(data);
      hadleSetDate({
        value: moment(defaultDate.value).format("YYYY-MM-DD"),
        error: false,
        success: true,
      });
      setError("");
    } else {
      const data = {
        value: "",
        error: true,
        success: false,
      };
      setDefaultDate(data);
      hadleSetDate({
        value: "",
        error: false,
        success: true,
      });
      setError(customErrorLine ? customErrorLine : "Select the date");
    }
  };

  return (
    <div
      style={Object.assign(
        {},
        Style.dateAndTimeContainer,
        dateAndTimeContainer
      )}
    >
      {starProps === true ? (
        <>
          <label
            style={Object.assign({}, Style.dateAndTimeLabel, dateAndTimeLabel)}
          >
            {label}
          </label>
          <span style={{ color: "red", marginLeft: "4px" }}>*</span>
        </>
      ) : (
        <label
          style={Object.assign({}, Style.dateAndTimeLabel, dateAndTimeLabel)}
        >
          {label}
        </label>
      )}

      <br />
      <DatePicker
        selected={defaultDate.value}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        onBlur={handleBlur}
        customInput={
          <input
            style={Object.assign(
              {},
              Style.dateAndTimeInput,
              error ? Style.dateAndTimeInputError : null
            )}
            disabled={disable}
            pattern="\d{2}-\d{2}-\d{4}"
          />
        }
        showIcon
        dateFormat={"dd-MM-yyyy"}
        placeholderText="DD-MM-YYYY"
        showYearDropdown
        showMonthDropdown
        toggleCalendarOnIconClick
        wrapperClassName="inputt"
      />
      {error && (
        <div className="clearFix">
          <div
            className="float-right"
            style={Object.assign({}, Style.customErrorLineStyle)}
          >
            {error ? error : customErrorLine}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateAndTimePicker;
