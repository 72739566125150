import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { PropagateLoader } from "react-spinners";
import { logoRect } from "../../../assets";
import { GetStatementReport } from "../../../axios";

const VendorAcStatement = (props) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      marginLeft: "25px",
      marginRight: "25px",
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-750px",
      fontSize: "8px",
    },
  });

  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);

  React.useEffect(() => {
    GetStatementReport(id)
      .then((res) => {
        setReportData(res.data.data);
        setShows(true);
      })
      .catch((err) => {
        setShows(false);
      });
  }, []);

  return (
    <>
      {shows === true ? (
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  <Image style={styles.image} src={logoRect} />
                  <Text style={{ fontSize: "12px", display: "flex" }}>
                    Statement of Account By Vendor
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }} fixed>
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    border: "1px solid black",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "13px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "11px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Project Name
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "11px", padding: "5px" }}
                    >
                      {reportData.budgetprojectData[0].project.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Budget Number
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      {reportData.budgetprojectData[0].serial_number +
                        " - " +
                        reportData.budgetprojectData[0].name}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Issuing Date
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      {
                        reportData.budgetprojectData[0].budget_start_date.split(
                          " "
                        )[0]
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: "10px",
                    borderRight: "1px solid black",
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "6px",
                      backgroundColor: "yellow",
                    }}
                  >
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>VENDOR</Text>
                    </View>
                    <View
                      style={{
                        width: "5%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>INV TYPE</Text>
                    </View>
                    <View
                      style={{
                        width: "18%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>INV #</Text>
                    </View>
                    <View
                      style={{
                        width: "7%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>CURRENCY</Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>INV RECEIVED DATE</Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>DATE OF PAYMENT</Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>ACTUAL INV AMOUNT</Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>
                        CONVERTED INV AMOUNT
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>PAID AMOUNT</Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        backgroundColor: "#b8d4db",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>BALANCE TO BE PAID</Text>
                    </View>
                  </View>
                </View>
                <View>
                  {reportData.summaryData.map((ele) => {
                    return (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            padding: "5px 3px",
                            borderRight: "1px solid black",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            backgroundColor: "#347380",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              fontSize: "9px",
                            }}
                          >
                            <Text>{ele.name}</Text>
                          </View>
                        </View>
                        {ele.purchase_order_invoice.map((elem) => {
                          return (
                            <View
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                borderTop: "1px solid black",
                                borderLeft: "1px solid black",
                                borderRight: "1px solid black",
                                marginTop: "0px",
                                padding: "0px 3px",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontSize: "9px",
                                }}
                              >
                                <View
                                  style={{
                                    width: "10%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                    padding: "3px",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    {elem.purchase_order_serial_number}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "5%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    {"N/A"}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "18%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    {elem.invoice_serial_number}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "7%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    {"AED"}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "10%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    {elem.received_on.split(" ")[0]}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "10%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    {elem.due_on.split(" ")[0]}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "10%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    AED {elem.total_price}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "10%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    AED {elem.total_price}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: "10%",
                                    height: "100%",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  <Text style={{ margin: "auto" }}>
                                    AED {elem.total_amount}
                                  </Text>
                                </View>
                                <View style={{ width: "10%", height: "100%" }}>
                                  <Text style={{ margin: "auto" }}>
                                    AED{" "}
                                    {parseInt(elem.total_price) -
                                      elem.total_amount}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                        {/* <View
                            style={{
                              flexDirection: "row",
                              flexWrap: "wrap",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              marginTop: "0px",
                              padding: "0px 3px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                fontSize: "9px",
                              }}
                            >
                              <View
                                style={{
                                  width: "70%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Text>Total</Text>
                              </View>
                              <View
                                style={{
                                  width: "10%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Text style={{ margin: "auto" }}>AED 1000</Text>
                              </View>
                              <View
                                style={{
                                  width: "10%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Text style={{ margin: "auto" }}>AED 1500</Text>
                              </View>
                              <View style={{ width: "10%", height: "100%" }}>
                                <Text style={{ margin: "auto" }}>AED 1700</Text>
                              </View>
                            </View>
                          </View> */}
                      </>
                    );
                  })}
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                  {/* <Text style={{ fontSize: "8px" }}>tel +971 4 3307725</Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link
                        style={{ color: "black" }}
                        src="mailto:invitation@furnish-marketplace.com"
                      >
                        invitation@furnish-marketplace.com
                      </Link>
                    </Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link
                        style={{ color: "black" }}
                        src="http://furnish-marketplace.com"
                      >
                        furnish-marketplace.com
                      </Link>
                    </Text> */}
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};

export default VendorAcStatement;
