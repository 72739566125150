import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import React from "react";
import { Checkbox, Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { FormTextInput, IconButton } from "../../../../components";
import { colors } from "../../../../configs";
import LoaderComponent from "../../../common/Loader";

const ActionCell = ({
  rowData,
  dataKey,
  handleChangeDelete,
  handleChangeEdit,
  isUpdate,
  isStatus,
  handleFlag,
  leftDays,
  data,
  ...props
}) => {
  function handleActionEdit() {
    handleChangeEdit(rowData[dataKey]);
  }
  // const isRFI = data.map((item, index) => {
  //   // isRfi(item.rfi);
  //   if (item.rfi === null) {
  //     return false;
  //   }
  //   return item.rfi;
  // });
  const handleActionDelete = () => {
    handleChangeDelete(rowData[dataKey]);
  };

  const handleFlagData = () => {
    handleFlag(rowData[dataKey]);
    // const isRfi = data.find((item) => item.id === rowData[dataKey]);
  };

  const getStatus = (rowData) => {
    if (rowData.purchaseorderitemdetails != null) {
      if (
        rowData.purchaseorderitemdetails.purchaseorder.purchase_order_status ==
        "Send To Client"
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <Cell {...props} className="link-group">
      <div style={{ margin: -10 }}>
        {/* <button
          style={{ background: "transparent" }}
          onMouseOver={() => handleFlagData(rowData[dataKey])}
        >
          <Tooltip
            title={leftDays ? `RFI Deadline Ends In ${leftDays} days` : "Flag"}
            placement="bottom-start"
            arrow
          >
            <FlagIcon sx={{ color: colors.success }} />
          </Tooltip>
        </button> */}
        {isUpdate && (
          <IconButton onClick={handleActionEdit}>
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          </IconButton>
        )}
        {isStatus && getStatus(rowData) && (
          <IconButton onClick={handleActionDelete}>
            <Tooltip title="Delete">
              <DeleteIcon sx={{ color: colors.danger }} />
            </Tooltip>
          </IconButton>
        )}
      </div>
    </Cell>
  );
};

const ActionCells = ({ rowData, dataKey, handleView, ...props }) => {
  const handleViewEditdata = () => {
    handleView(rowData);
  };
  return (
    <Cell {...props} className="link-group">
      <div style={{ marginLeft: "10px" }}>
        <b
          title={"control number"}
          onClick={() => handleViewEditdata(rowData)}
          style={{
            cursor: "pointer",
          }}
        >
          {rowData[dataKey]}
        </b>
      </div>
    </Cell>
  );
};

// const StatusCell = ({ rowData, dataKey, ...props }) => {
//   return (
//     <Cell {...props} className="link-group">
//       <Chip
//         label={rowData[dataKey] ? "Active" : "Inactive"}
//         type={rowData[dataKey] ? "active" : "inactive"}
//       />
//     </Cell>
//   );
// };

const BudgetItemTable = (props) => {
  const {
    data,
    handleChangeDelete,
    handleChangeEdit,
    handleChangeCheck,
    mapableData,
    searchProp,
    handleView,
    isUpdate,
    isStatus,
    isAddRfi,
    isAddRfq,
    handleFlag,
    leftDays,
    handleSearchSubmit,
    searchValue,
    setSearchValue,
    getAlldata,
    rowsPerPage,
    handleSelectAll,
  } = props;
  const [allData, setAllData] = React.useState(data);
  const [searchedData, setSearchedData] = React.useState(data);
  const [limit, setLimit] = React.useState(rowsPerPage);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();

  const [isLoader, setisLoader] = React.useState(false);

  const [tempData] = React.useState(data);
  const CheckCell = ({
    rowData,
    handleChangeCheck,
    checkedKeys,
    dataKey,
    isAddRfi,
    isAddRfq,
    ...props
  }) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "42px" }}>
        {(isAddRfi || isAddRfq) && (
          <Checkbox
            value={rowData[dataKey]}
            inline
            onChange={() => handleChangeCheck(rowData[dataKey])}
            checked={rowData["checked"]}
            style={{ color: colors.primary }}
          />
        )}
      </div>
    </Cell>
  );
  React.useEffect(() => {
    setAllData(data);
  }, [data]);

  const buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };

  const filterBuilderData = (data, query) => {
    let searchedData = [];
    data.forEach((item) => {
      for (let key in query) {
        if (
          !_.includes(searchedData, item) &&
          item[key]?.toLowerCase()?.includes(query[key][0]?.toLowerCase())
        ) {
          searchedData.push(item);
        }
      }
    });
    return searchedData;
  };

  let filter = {};
  React.useEffect(() => {
    searchProp.forEach((e) => (filter[e] = [searchValue?.value]));
    setSearchedData(filterBuilderData(data, buildFilter(filter)));
  }, [searchProp, searchValue]);

  const query = buildFilter(filter);
  const handleSearch = (searchData) => {
    // const data = searchData.value;
    // setSearchValue(searchData);
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
    setSearchValue(searchData);
    if (searchData.value === "") {
      getAlldata();
      // // GetItemGroup(page, rowsPerPage, searchData.value)
      // //   .then((res) => {
      // //     const data = res.data.data;
      // //     setisLoader(false);
      // //     setRows(data);
      // //     setTotalData(res.data.total);
      // //   })
      // //   .catch((e) => {
      // //     setisLoader(false);
      // //   });
    }
  };

  const filterData = (data) => {
    const result = data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    return result;
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const handleSortColumn = (column, type) => {
    setSortColumn(column);
    setSortType(type);
    const result = _.orderBy(allData, [column], [type]);
    setSearchedData(result);
  };

  const limitOption = (allData) => {
    const res = [];
    const length = allData.length;
    const allDataLength = length + (5 - (length % 5));
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i);
    }
    return res;
  };

  function nested(obj, attrString) {
    let path = attrString.split(".");
    let x = obj[0];
    for (var i in path) {
      x = x ? (x[path[i]] ? x[path[i]] : null) : null;
    }
    return x ? x : null;
  }

  const [checkedKeys, setCheckedKeys] = React.useState([]);
  const checked = checkedKeys.length === data.length;
  const indeterminate =
    checkedKeys.length > 0 && checkedKeys.length < data.length;

  // if (checkedKeys.length === data.length) {
  //   checked = true;
  // } else if (checkedKeys.length === 0) {
  //   checked = false;
  // } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
  //   indeterminate = true;
  // }

  const handleCheckAll = (value, checked) => {
    console.log({checked});
    const keys = checked ? data.map((item) => item.id) : [];
    console.log({keys});
    setCheckedKeys(keys);
    handleSelectAll(checked);
  };

  const getStatus = (item) => {
    console.log("^&&&&&&&&&&&&&&", item);
    if (item.purchaseorderitemdetails != null) {
      console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&");
      if (
        item.purchaseorderitemdetails.purchaseorder.purchase_order_status ==
        "Send To Client"
      ) {
        console.log(
          "*************************",
          item.purchaseorderitemdetails.purchaseorder.purchase_order_status
        );
        return false;
      }
    }
    return isStatus;
  };

  return (
    <React.Fragment>
      {isLoader ? <LoaderComponent /> : null}
      <form onSubmit={handleSearchSubmit}>
        <FormTextInput
          type="text"
          className="searchBarBudget mb-3"
          placeholder="Search ..."
          Value={searchValue?.value}
          onChangeText={handleSearch}
          inputStyle={{ border: "none", marginBottom: "-100px" }}
          editable
        />
      </form>
      <div style={{ overflowY: "auto" }}>
        <Table
          style={{ borderRadius: 10 }}
          virtualized
          height={400}
          id="table"
          data={filterData(searchedData)}
          onSortColumn={handleSortColumn}
          sortColumn={sortColumn}
          sortType={sortType}
          rowHeight={50}
          headerHeight={50}
          // fillHeight={true}

          // autoHeight={true}
        >
          <Column width={50} align="center">
            <HeaderCell
              style={{
                color: colors.primary,
                fontWeight: 900,
                fontSize: "larger",
              }}
            >
              <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
            </HeaderCell>
            <CheckCell
              isAddRfi={isAddRfi}
              isAddRfq={isAddRfq}
              dataKey="id"
              checkedKeys={checkedKeys}
              handleChangeCheck={handleChangeCheck}
            />
          </Column>
          {mapableData.map((item, index) => {
            return (
              <Column minWidth={100} flexGrow={1} sortable key={index}>
                <HeaderCell
                  style={{
                    color: colors.primary,
                    fontWeight: 900,
                    fontSize: "larger",
                  }}
                >
                  {item.title}
                </HeaderCell>
                {item.hasOwnProperty("isviewlink") ? (
                  <ActionCells
                    dataKey="control_number"
                    handleView={handleView}
                  />
                ) : item.hasOwnProperty("isAction") ? (
                  <ActionCell
                    isUpdate={isUpdate}
                    isStatus={isStatus}
                    dataKey="id"
                    handleChangeDelete={handleChangeDelete}
                    handleChangeEdit={handleChangeEdit}
                    handleFlag={handleFlag}
                    leftDays={leftDays}
                    data={data}
                  />
                ) : nested(data, item.dataKey) ? (
                  <Cell dataKey={item.dataKey} />
                ) : (
                  // <Cell>N/A</Cell>
                  <Cell dataKey={item.dataKey} />
                )}
              </Column>
            );
          })}
        </Table>
      </div>
      {/* {data.length > 5 && (
        <div style={{ width: "100%" }}>
          <Pagination
            style={{ marginTop: 20 }}
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={data.length}
            limitOptions={limitOption(data)}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )} */}
    </React.Fragment>
  );
};

export default BudgetItemTable;
