import React from "react";
import { FormTextInput, Button, FormCheckBox } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const DeleteVendorCompanytoCategoryMapping = (props) => {
    const { deleteChangeFormData, initialValue = {}, actionType } = props;

    const pageDeleteTitle = Strings["VendorCompanytoCategoryMapping.DeleteMapping"],
        pageViewTitle = Strings["VendorCompanytoCategoryMapping.ViewMapping"],
        categoryLabel = Strings["VendorCompanytoCategoryMapping.CategoryLabel"],
        //   categoryPlaceholder =
        //     Strings["FunctionManagement.deleteFunctionNamePlaceholder"],
        //   categoryCustomError =
        //     Strings["FunctionManagement.deleteFunctionNameCustomError"],
        subCategoryLabel = Strings["VendorCompanytoCategoryMapping.SubCategoryLabel"],
        //   subCategoryPlaceholder =
        //     Strings["FunctionManagement.deleteFunctionDescriptionPlaceholder"],
        activeStatusLabel = Strings["VendorCompanytoCategoryMapping.ActiveStatusLabel"],
        deleteBtn = Strings["VendorCompanytoCategoryMapping.DeleteBtn"],
        cancelBtn = Strings["VendorCompanytoCategoryMapping.CancelBtn"];

    const [category] = React.useState({
        value: initialValue.name ? initialValue.name : "",
        error: false,
        sucess: false,
    });

    const getAllSubCategories = (subCat) => {
        let str = "";
        subCat.forEach((i, index) => {
            if (index === 0) {
                str = i.subCategoryName;
            } else {
                str = str + " , " + i.subCategoryName;
            }
        });

        return str;
    };

    const [subCategories] = React.useState({
        value: initialValue.subCategory
            ? getAllSubCategories(initialValue.subCategory)
            : "",
        error: false,
        sucess: false,
    });

    const [activeStatus, setActiveStatus] = React.useState(
        initialValue.activeStatus && initialValue.activeStatus
    );

    const handleChangeCheck = () => {
        setActiveStatus(!activeStatus);
    };
    const handleDelteData = () => {
        const formData = {
            category: category.value,
            subCategory: initialValue.subCategory,

            activeStatus: activeStatus && activeStatus, //not a confirmed logic
        };
        deleteChangeFormData(formData);
    };
    return (
        <div className="modalFormContainer">
            <h2>{actionType === "view" ? pageViewTitle : pageDeleteTitle}</h2>

            <FormTextInput
                type="alpha"
                label={categoryLabel}
                placeholder={categoryLabel}
                Value={category.value}
                onChangeText={() => { }}
                Validate={true}
                CustomErrorLine={""}
                editable={false}
            />

            <FormTextInput
                type="text"
                label={subCategoryLabel}
                placeholder={subCategoryLabel}
                Value={subCategories.value}
                onChangeText={() => { }}
                Validate={true}
                CustomErrorLine={""}
                editable={false}
                multiline={true}
            />

            {actionType === "view" || actionType === "delete" ? (
                <FormCheckBox
                    Label={activeStatusLabel}
                    dis
                    checked={activeStatus}
                    handleChangeCheck={handleChangeCheck}
                    disable={actionType === "view" ? true : false}
                />
            ) : (
                <></>
            )}
            {actionType !== "view" ? (
                <div style={{ display: "flex", marginTop: "1.5rem" }}>
                    <Button
                        buttonName={deleteBtn}
                        buttonStyle={
                            actionType === "delete"
                                ? { marginRight: "10px", backgroundColor: colors.danger }
                                : { marginRight: "10px" }
                        }
                        disable={false}
                        onClick={handleDelteData}
                    />
                    <Button buttonName={cancelBtn} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default DeleteVendorCompanytoCategoryMapping;
