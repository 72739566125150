/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const EstablishmentForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createHeader = Strings["Establishment.CreateEstablishmentHeader"],
    editHeader = Strings["Establishment.editEstablishmentHeader"],
    nameLabel = Strings["Establishment.createEstablishmentNameLable"],
    nameplaceholder =
      Strings["Establishment.createEstablishmentNamePlaceHolder"],
    nameCustomError =
      Strings["Establishment.createEstablishmentNamecustomlineerror"],
    DescriptionLabel =
      Strings["Establishment.createEstablishmentDescriptionLable"],
    Descriptionplaceholder =
      Strings["Establishment.createEstablishmentDescriptionPlaceHolder"],
    DescriptionCustomError =
      Strings["Establishment.createEstablishmentDescriptioncustomlineerror"],
    CodeLabel = Strings["Establishment.createEstablishmentCodeLable"],
    Codeplaceholder =
      Strings["Establishment.createEstablishmentCodePlaceHolder"],
    CodeCustomError =
      Strings["Establishment.createEstablishmentCodecustomlineerror"],
    saveButton = Strings["Establishment.CreateEstablishmentSaveButton"],
    cancelButton = Strings["Establishment.CreateEstablishmentCancelButton"],
    updateBtn = Strings["Establishment.CreateEstablishmentUpdateButton"];

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    succes: false,
  });

  const [description, setDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    succes: false,
  });

  const [code, setCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    succes: false,
  });

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeCode = (value) => {
    setCode(value);
  };

  const handleSaveData = (value) => {
    const formData = {
      name: name.value,
      description: description.value,
      code: code.value,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged = name.value === initialValue.name;
  // description.value === initialValue.ProjectScopeDescription &&
  // code.value === initialValue.code;

  let disable =
    name.error ||
    code.error ||
    // description.error ||
    name.value === "" ||
    // description.value === "" ||
    code.value === "" ||
    (name.succes === false &&
      // description.succes === false &&
      code.succes === false) ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    name.value === initialValue.name &&
    description.value === initialValue.description &&
    code.value === initialValue.code;

  let editdisable =
    name.error ||
    code.error ||
    description.error ||
    name.value === "" ||
    description.value === "" ||
    code.value === "" ||
    (name.success === false &&
      description.success === false &&
      code.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createHeader : editHeader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        starProps={true}
        label={nameLabel}
        placeholder={nameplaceholder}
        Value={name.value}
        onChangeText={handleChangeName}
        Validate={true}
        CustomErrorLine={nameCustomError}
        editable={!(actionType === "delete")}
        maxLength={30}
      />

      <FormTextInput
        type="text"
        label={DescriptionLabel}
        placeholder={Descriptionplaceholder}
        Value={description.value}
        onChangeText={handleChangeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        CustomErrorLine={DescriptionCustomError}
        editable={!(actionType === "delete")}
        multiline={true}
        maxLength={30}
      />

      <FormTextInput
        type="text"
        label={CodeLabel}
        starProps={true}
        placeholder={Codeplaceholder}
        Value={code.value}
        onChangeText={handleChangeCode}
        Validate={true}
        CustomErrorLine={CodeCustomError}
        editable={!(actionType === "delete")}
        maxLength={30}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : false}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default EstablishmentForm;
