/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LoginImg, loginfurnish } from "../../../assets";
import { GetAuthorization, Login,GetUserNameByAuthId } from "../../../axios";
import LoaderComponent from "../../../components/common/Loader";
import { LoginForm } from "../../../forms";
import strings from "../../../translations";

export default function LoginPage() {
  const loginTitle = strings["Authentication.loginHead"];
  let history = useHistory();
  const [initialValue] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [token, setToken] = React.useState(null);

  useEffect(() => {
    if (token) {
      GetAuthorization(token)
        .then((res) => {
          const value = res.data.data;
          localStorage.setItem("authorization", JSON.stringify(value));
          // history.push("/");
          window.location.reload();
          // history.push("/");
          window.location.replace(process.env.REACT_APP_BASE_NAME_LOCATION);
        })
        .catch(() => {});
    }
  }, [token]);

  const getAuthData = async (id) => {
    try {
       const user = await GetUserNameByAuthId(id);
       return user?.data?.data?.id;
    } catch (e) {
        console.log(`Error message`, e);
        return 0;
    }
    /**return GetUserNameByAuthId(id)
        .then((res) => {
          
          return res.data.data.id;
        })
        .catch((e) => {
          console.log("Error",e)
          return 0;
        });**/
  }
  const handleSetValue = async (val) => {
    setLoader(true);
    Login(val)
      .then(async (res) => {
        const user_id = await getAuthData(res.data.data.identity_id);       
        const value = {
          id: user_id,
          identity_id: res.data.data.identity_id,
          token: res.data.data.access_token,
          email: val.email,
          password: val.password,
          loginType: res.data.data.user_type,
        };
        
        localStorage.setItem("user", JSON.stringify(value));
        setToken(value.token);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        if (e.response?.data) {
          setMessage(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) {
      window.location.reload();
      history.push(`/`);
    }
  }, []);

  return (
    <section className={"white-bg"}>
      {loader ? <LoaderComponent /> : null}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 d-md-flex bg-image">
            <div className="loginImageBox">
              <div className="imageWrapeer">
                <img src={LoginImg} alt="login" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div className="loginForm d-flex align-items-center py-5">
              <div className="container loginformdiv">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    <div className="login_form">
                      <img
                        src={loginfurnish}
                        alt="furnish"
                        className="w-50 mb-4 mt-4"
                      />
                      <h4>{loginTitle}</h4>
                      <LoginForm
                        errorMessage={errorMessage}
                        setMessage={setMessage}
                        initialValue={initialValue}
                        setValue={handleSetValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
