import React from "react";
import { Link } from "react-router-dom";
import { Button, FormTextInput } from "../../../components";
import Strings from "../../../translations";

export default function LoginForm(props) {
  const emailLabel = Strings["Authentication.loginEmailLabel"],
    emailPlaceholder = Strings["Authentication.loginEmailPlaceholder"],
    emailCustomErrorLine = Strings["Authentication.loginEmailCustomError"],
    passwordLabel = Strings["Authentication.loginPasswordLabel"],
    passwordPlaceholder = Strings["Authentication.loginPasswordPlaceholder"],
    forgotbuttonlable = Strings["Authentication.forgotpasswordButtoun"],
    loginSubmitButton = Strings["Authentication.loginSubmitButton"];

  const { initialValue, setValue = () => {}, setMessage, errorMessage } = props;

  const [userEmail, setUserEmail] = React.useState({
    value: initialValue.email ? initialValue.email : " ",
    error: false,
    success: false,
  });
  const [userPassword, setUserPassword] = React.useState({
    value: initialValue.password ? initialValue.password : "",
    error: false,
    success: false,
  });

  const handleChangeEmail = (value) => {
    setMessage(null);
    setUserEmail(value);
  };

  const handleChangePassword = (value) => {
    setMessage(null);
    setUserPassword(value);
  };

  const handleSubmit = () => {
    const data = {
      email: userEmail.value,
      password: userPassword.value,
    };
    setValue(data);
  };

  let disable =
    userEmail.value === "" || userPassword.value === "";


  return (
    <>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="email"
        label={emailLabel}
        placeholder={emailPlaceholder}
        Value={userEmail.value}
        onChangeText={handleChangeEmail}
        Validate={true}
        CustomErrorLine={emailCustomErrorLine}
        editable={true}
      />
      <FormTextInput
        type="password"
        label={passwordLabel}
        placeholder={passwordPlaceholder}
        Value={userPassword.value}
        onChangeText={handleChangePassword}
        Validate={true}
        passwordVisibleIcon={true}
        editable={true}
      />
      <div className="d-flex justify-content-end my-3">
        <Link
          exact
          to={{ pathname: "/forgotpassword", state: userEmail.value }}
          className="link__forgotpassword"
        >
          {forgotbuttonlable}
        </Link>
      </div>
      <div className="button-group">
        <Button
          onClick={handleSubmit}
          buttonName={loginSubmitButton}
          disable={disable}
        />
      </div>
    </>
  );
}
