import React from "react";

const StatusChip = (props) => {
    const { label, color } = props;

    return (
        <span
            style={{
                color: "white",
                backgroundColor: color,
                border: "solid 1px",
                borderColor: color,
                padding: "0.3em 1em",
                borderRadius: "4px",
                fontWeight: "500",
                fontSize: "10px"
            }}
        >
            {label}
        </span>
    );
};

export default StatusChip;
