/* eslint-disable no-unused-vars */
import React from "react";
import {
  AiOutlineFundProjectionScreen,
  AiOutlineSetting,
  AiOutlineShop,
  AiOutlineArrowRight
} from "react-icons/ai";
import { BiPurchaseTag } from "react-icons/bi";
import { MdOutlinePayment } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import {
  FaRegLaughWink,
  FaTachometerAlt,
  FaUserEdit,
  FaUserTie,
} from "react-icons/fa";
import { MdLanguage } from "react-icons/md";
import { RiEnglishInput } from "react-icons/ri";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Modal } from "../../../../components";
import { EditProfile } from "../../../../forms";
import Strings from "../../../../translations";
import { isAuthorizedFunc } from "../../../../utils";
import {
  CANCEL_PROJECT,
  COMPLETED_PROJECT,
  GET_ACTIVE_PROJECT,
  GET_ALL_OPERATOR,
  GET_CLIENT,
  GET_USER_MANAGEMENT_FUNCTION,
  GET_VENDOR,
  GET_VENDOR_COMPANY_TYPE,
  ONGOING_PROJECT,
  SHOW_MASTER_CONFIGURATION,
  SHOW_VENDOR_PAYMENT,
  SHOW_VENDOR_PO,
  SHOW_VENDOR_RFQ,
} from "../../../../utils/constants";
import "../Sidebar.css";

const Aside = ({
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar = () => {
  },
}) => {
  const sideBarUser = Strings["Navigation.SidebarUser"],
    sideBarAlluser = Strings["Navigation.SidebarUserAllUser"],
    SideBarVendor = Strings["Navigation.SideBarVendor"],
    SideBarVendorAllVendor = Strings["Navigation.SideBarVendorAllVendor"],
    SideBarClient = Strings["Navigation.SideBarClient"],
    SideBarClientAllClient = Strings["Navigation.SideBarClientAllClient"],
    SideBarClientAllOperators = Strings["Navigation.SideBarOperatorAllOperators"],
    SideBarOperatorsAllOperators = Strings["Navigation.SideBarOperators"],
    SideBarDashBoard = Strings["Navigation.SideBarDashBoard"],
    SideBarProject = Strings["Navigation.SideBarProject"],
    SideBarProjectAllProject = Strings["Navigation.SideBarProjectAllProject"],
    SidebarMasterConfiguration =
      Strings["Navigation.SidebarMasterConfiguration"],
    vendorCompanyType = Strings["VendorCompanyType.DashboardTitle"],
    Language = Strings["Navigation.Language"],
    LanguageEnglish = Strings["Navigation.LanguageEnglish"],
    LanguageArbic = Strings["Navigation.LanguageArbic"],
    OngoingProject = Strings["Navigation.OngoingProject"],
    CancelledProject = Strings["Navigation.CancelledProject"],
    CompletedProject = Strings["Navigation.CompletedProject"],
    SideBarRequestQuotation = Strings["Title.RFQ"],
    SideBarPoStatus = Strings["Title.PoStatus"],
    SideBarPaymentStatus = Strings["Title.PaymentStatus"],
    SideBarDeliveryStatus = Strings["Title.DeliveryStatus"];

  const [isOpens, setIsOpens] = React.useState(false);

  const handleModalsClose = () => {
    setIsOpens(false);
  };

  const selectModals = () => {
    return <EditProfile handlemodalclose={handleModalsClose} />;
  };

  return (
    <div style={{ zIndex: 3, marginBottom: "1rem" }}>
      <Modal onHandleClose={handleModalsClose} isOpen={isOpens}>
        {selectModals()}
      </Modal>
      <ProSidebar
        image={false}
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarContent>
          <Menu iconShape="circle">
            {isAuthorizedFunc() && (
              <MenuItem icon={<FaTachometerAlt />}>
                <Link to="/"> {SideBarDashBoard}</Link>
              </MenuItem>
            )}
          </Menu>
          <Menu iconShape="circle">
            {isAuthorizedFunc(GET_USER_MANAGEMENT_FUNCTION) && (
              <SubMenu
                title={sideBarUser}
                icon={<FaRegLaughWink />}
              >
                {isAuthorizedFunc(GET_USER_MANAGEMENT_FUNCTION) && (
                  <MenuItem>
                    <Link to="/master-configuration/searchuser" onClick={handleToggleSidebar}>
                      {sideBarAlluser}
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {isAuthorizedFunc(GET_VENDOR) &&
              isAuthorizedFunc(GET_VENDOR_COMPANY_TYPE) && (
                <SubMenu title={SideBarVendor} icon={<AiOutlineShop />}>
                  {isAuthorizedFunc(GET_VENDOR) && (
                    <MenuItem>
                      <Link to="/master-configuration/vendor" onClick={handleToggleSidebar}>
                        {SideBarVendorAllVendor}
                      </Link>
                    </MenuItem>
                  )}
                  {isAuthorizedFunc(GET_VENDOR_COMPANY_TYPE) && (
                    <MenuItem>
                      <Link to="/master-configuration/vendorcompanytype" onClick={handleToggleSidebar}>
                        {vendorCompanyType}
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            {isAuthorizedFunc(GET_CLIENT) && (
              <SubMenu title={SideBarClient} icon={<FaUserTie />}>
                {isAuthorizedFunc(GET_CLIENT) && (
                  <MenuItem>
                    <Link to="/master-configuration/searchclient" onClick={handleToggleSidebar}>
                      {SideBarClientAllClient}
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {isAuthorizedFunc(GET_ALL_OPERATOR) && (
              <SubMenu title={SideBarOperatorsAllOperators} icon={<FaUserTie />}>
                {isAuthorizedFunc(GET_CLIENT) && (
                  <MenuItem>
                    <Link to="/master-configuration/searchoperator" onClick={handleToggleSidebar}>
                      {SideBarClientAllOperators}
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {isAuthorizedFunc(GET_ACTIVE_PROJECT) &&
              isAuthorizedFunc(ONGOING_PROJECT) &&
              isAuthorizedFunc(CANCEL_PROJECT) &&
              isAuthorizedFunc(COMPLETED_PROJECT) && (
                <SubMenu
                  title={SideBarProject}
                  icon={<AiOutlineFundProjectionScreen />}
                >
                  {isAuthorizedFunc(GET_ACTIVE_PROJECT) && (
                    <MenuItem>
                      <Link to="/project" onClick={handleToggleSidebar}>{SideBarProjectAllProject}</Link>
                    </MenuItem>
                  )}
                  {/* {isAuthorizedFunc(ONGOING_PROJECT) && (
                    <MenuItem>
                      <Link
                        to={{
                          pathname: "/ongoingproject",
                          state: { projectStatus: "ongoing" },
                          onClick: {handleToggleSidebar}
                        }}
                      >
                        {OngoingProject}
                      </Link>
                    </MenuItem>
                  )} */}

                  {/* {isAuthorizedFunc(CANCEL_PROJECT) && (
                    <MenuItem>
                      <Link
                        to={{
                          pathname: "/cancelledproject",
                          state: { projectStatus: "cancelled" },
                          onClick: {handleToggleSidebar}
                        }}
                      >
                        {CancelledProject}
                      </Link>
                    </MenuItem>
                  )} */}

                  {/* {isAuthorizedFunc(COMPLETED_PROJECT) && (
                    <MenuItem>
                      <Link
                        to={{
                          pathname: "/completedproject",
                          state: { projectStatus: "completed" },
                          onClick: {handleToggleSidebar}
                        }}
                      >
                        {CompletedProject}
                      </Link>
                    </MenuItem>
                  )} */}
                </SubMenu>
              )}

            {isAuthorizedFunc(SHOW_MASTER_CONFIGURATION) && (
              <MenuItem icon={<AiOutlineSetting />}>
                <Link to={{
                  pathname: '/master-configuration',
                  onClick: {handleToggleSidebar}
                }}>
                  {SidebarMasterConfiguration}
                </Link>
              </MenuItem>
            )}
            {isAuthorizedFunc(SHOW_VENDOR_RFQ) && (
              <MenuItem icon={<AiOutlineArrowRight />}>
                <Link to={{
                  pathname: '/vendorrfq'
                }}>
                  {SideBarRequestQuotation}
                </Link>
              </MenuItem>
            )}
            {isAuthorizedFunc(SHOW_VENDOR_PO) && (
              <MenuItem icon={<BiPurchaseTag />}>
                <Link to={{
                  pathname: '/vendorpo'
                }}>
                  {SideBarPoStatus}
                </Link>
              </MenuItem>
            )}
            {isAuthorizedFunc(SHOW_VENDOR_PAYMENT) && (
              <MenuItem icon={<MdOutlinePayment />}>
                <Link to={{
                  pathname: '/vendorpayment'
                }}>
                  {SideBarPaymentStatus}
                </Link>
              </MenuItem>
            )}
            {isAuthorizedFunc(SHOW_VENDOR_PAYMENT) && (
              <MenuItem icon={<TbTruckDelivery />}>
                <Link to={{
                  pathname: '/vendordelivery'
                }}>
                  {SideBarDeliveryStatus}
                </Link>
              </MenuItem>
            )}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="circle">
            <SubMenu title={Language} icon={<MdLanguage />}>
              <MenuItem
                onClick={() => {
                  localStorage.setItem("lang", JSON.stringify("en"));
                  window.location.reload();
                }}
                icon={<RiEnglishInput />}
              >
                {LanguageEnglish}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.setItem("lang", JSON.stringify("ar"));
                  window.location.reload();
                }}
                icon={<FaUserEdit />}
              >
                {LanguageArbic}
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarFooter>

      </ProSidebar>
    </div>
  );
};

export default Aside;
