import React from 'react'
import { useHistory } from 'react-router-dom';


const ListingCard = (props) => {

    const { data } = props;
    const history = useHistory()

    const handleChangeClick = () => {
        history.push({ pathname: '/searchpage', state: data });
    }

    return (
        <div style={{ width: '200px', border: '1px solid red', height: '150px' }} onClick={handleChangeClick}>
            <h2> {data.name} </h2>
        </div>
    )
}

const Test = (props) => {

    const myData = [
        {
            id: 1,
            name: 'User Role',
            path: '/master-configuration/searchrole',
            mapableData: [
                {
                    dataKey: 'name',
                    title: 'Role Name',
                },
                {
                    dataKey: 'roleDescription',
                    title: 'Role Description',
                },
                {
                    dataKey: 'activeStatus',
                    title: 'Active Status',
                    isStatus: true,
                },
                {
                    dataKey: 'id',
                    title: 'Action',
                    isAction: true

                }
            ],
            data: [
                { id: 1, name: 'role1', roleDescription: 'Role Description XYZ', activeStatus: true },
                { id: 2, name: 'role2', roleDescription: 'Role Description XYZd', activeStatus: false },
                { id: 3, name: 'role3', roleDescription: 'Role Description XYZs', activeStatus: true },
                { id: 4, name: 'role4', roleDescription: 'Role Description XYZa', activeStatus: false },
            ],
            searchProp: ["name", "roleDescription"]
        },
        {
            id: 2,
            name: 'User Role',
            path: '/master-configuration/searchrole',
            mapableData: [
                {
                    dataKey: 'name',
                    title: 'Role Name',
                },
                {
                    dataKey: 'roleDescription',
                    title: 'Role Description',
                },
                {
                    dataKey: 'activeStatus',
                    title: 'Active Status',
                    isStatus: true,
                },
                {
                    dataKey: 'id',
                    title: 'Action',
                    isAction: true

                }
            ],
            data: [
                { id: 1, name: 'role1', roleDescription: 'Role Description XYZ', activeStatus: true },
                { id: 2, name: 'role2', roleDescription: 'Role Description XYZd', activeStatus: false },
                { id: 3, name: 'role3', roleDescription: 'Role Description XYZs', activeStatus: true },
                { id: 4, name: 'role4', roleDescription: 'Role Description XYZa', activeStatus: false },
            ],
            searchProp: ["name", "roleDescription"]
        },
    ]

    return (
        <div className='container' style={{ marginTop: '40px' }}>
            {
                myData.map((data, index) => {
                    return <ListingCard data={data} key={index} />
                })
            }
        </div>
    )
}

export default Test
