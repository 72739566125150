import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import App from "./App";
import "./styles/index.css";

const baseName = process.env.REACT_APP_BASE_NAME;
ReactDOM.render(
  <React.StrictMode>
    <Router basename={`/${baseName}/`}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
