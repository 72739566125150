/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateItemSubGroup = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;
  const createItemSubGroupTitle =
      Strings["ItemSubGroup.CreateItemSubGroupHeader"],
    editItemSubGroupTitle = Strings["ItemSubGroup.EditItemSubGroupHeader"],
    ItemSubGroupNameLabel = Strings["ItemSubGroup.CreateItemSubGroupNameLable"],
    ItemSubGroupNamePlaceHolder =
      Strings["ItemSubGroup.CreateItemSubGroupNamePlaceholder"],
    ItemSubGroupNameError =
      Strings["ItemSubGroup.CreateItemSubGroupNamecustomlineerror"],
    ItemSubGroupDescriptionTitle =
      Strings["ItemSubGroup.CreateItemSubGroupDescriptionLable"],
    ItemSubGroupDescriptionPlaceHolder =
      Strings["ItemSubGroup.CreateItemSubGroupDescriptionPlaceholder"],
    ItemSubGroupDescriptionError =
      Strings["ItemSubGroup.CreateItemSubGroupDescriptionCustomLineError"],
    ItemSubGroupSaveButton = Strings["ItemSubGroup.EditUsersaveButton"],
    ItemSubGroupUpdateButton = Strings["ItemSubGroup.EditUserUpdateButton"],
    ItemSubGroupCancelButton = Strings["ItemSubGroup.EditUserCancelButton"];

  const [name, setName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [description, setDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handleChangeRole = (value) => {
    setMessage(null);
    setName(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: name.value,
      description: description.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged = name.value === initialValue.user_type;
  // description.value === initialValue.description;
  let disable =
    name.error ||
    // description.error ||
    name.value === "" ||
    // description.value === "" ||
    name.success === false ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangedEdit =
    name.value === initialValue.user_type &&
    description.value === initialValue.description;
  let editdisable =
    name.error ||
    description.error ||
    name.value === "" ||
    description.value === "" ||
    (name.success === false && description.success === false) ||
    isValueNOTChangedEdit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add" ? createItemSubGroupTitle : editItemSubGroupTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="textarea"
        label={ItemSubGroupNameLabel}
        placeholder={ItemSubGroupNamePlaceHolder}
        Value={name.value}
        onChangeText={handleChangeRole}
        Validate={true}
        CustomErrorLine={ItemSubGroupNameError}
        editable
      />
      <FormTextInput
        type="alpha"
        label={ItemSubGroupDescriptionTitle}
        placeholder={ItemSubGroupDescriptionPlaceHolder}
        Value={description.value}
        onChangeText={handleChangeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        multiline={true}
        CustomErrorLine={ItemSubGroupDescriptionError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add"
              ? ItemSubGroupSaveButton
              : ItemSubGroupUpdateButton
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={ItemSubGroupCancelButton}
          onClick={() => {
            setMessage(null);
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default CreateItemSubGroup;
