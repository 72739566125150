/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import swal from "sweetalert";
import {
  CreateClient,
  CreateOperator,
  GetActiveOperator,
  GetAllActiveClient,
  GetAllActiveCountry,
  GetAllActiveProjectScope,
} from "../../../axios";
import {
  AddIcon,
  Button,
  FormCheckBox,
  FormDropDown,
  FormTextInput,
  IconButton,
  Modal,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";
import { ClientForm, CreateCompanyForm } from "../../Masters";
import "./ProjectForm.css";

const CreateProject = (props) => {
  const [loading, setLoading] = React.useState(false);

  const {
    setMessage,
    errorMessage,
    handleProjectData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [client, setClient] = React.useState({
    value: initialValue.clients ? initialValue.clients.id : "",
    error: false,
    success: false,
  });

  const [clientOption, setClientOption] = React.useState([]);

  const [country, setCountry] = React.useState({
    value: initialValue.countries ? initialValue.countries.id : "",
    error: false,
    success: false,
  });
  const [countryOption, setCountryOption] = React.useState([]);
  const [projectScopeOption, setProjectScopeOption] = React.useState([]);

  const [operatorOption, setOperatorOption] = React.useState([]);

  const [bilingAddress, setBilingAddress] = React.useState({
    value: initialValue.billing_address ? initialValue.billing_address : null,
    error: false,
    success: false,
  });

  const [shippingAddress, setshippingAddress] = React.useState({
    value: initialValue.shipping_address ? initialValue.shipping_address : null,
    error: false,
    success: false,
  });

  var project_Scope_List = [];
  if (initialValue.project_scope_data) {
    project_Scope_List = initialValue.project_scope_data.map((ele) => {
      return {
        key: ele.project_scopes.id,
        label: ele.project_scopes.name,
        value: ele.project_scopes.id,
      };
    });
  }

  const [projectScope, setProjectScope] = React.useState({
    value: project_Scope_List,
    error: false,
    success: false,
  });

  const [operator, setOperator] = React.useState({
    value: initialValue.project_operator
      ? initialValue.project_operator[0].operator?.id
      : "",
    error: false,
    success: false,
  });

  const [ShippingAddress, setShippingAddress] = useState("");
  const [Checked, setChecked] = useState(false);

  const getAllActiveClient = () => {
    setLoading(true);
    GetAllActiveClient()
      .then((res) => {
        setLoading(false);
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setClientOption(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAllActiveClient();
  }, []);

  const getProjectScope = () => {
    setLoading(true);
    GetAllActiveProjectScope()
      .then((res) => {
        setLoading(false);
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setProjectScopeOption(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getOperator = () => {
    setLoading(true);
    GetActiveOperator()
      .then((res) => {
        setLoading(false);
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setOperatorOption(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getOperator();
  }, []);

  const getAllCountry = () => {
    setLoading(true);
    GetAllActiveCountry()
      .then((res) => {
        setLoading(false);
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setCountryOption(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getProjectScope();
    getAllCountry();
  }, []);

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };

  const handleChangeClient = (value) => {
    setMessage(null);
    setClient(value);
  };

  const handleChangeCountry = (value) => {
    setMessage(null);
    setCountry(value);
  };

  const handleChangeBillingAddress = (value) => {
    setBilingAddress(value);
  };

  const handleChangeShippingAddress = (value) => {
    setshippingAddress(value);
    setShippingAddress(value);
  };
  const handleChangeCheck = (value) => {
    setChecked(!Checked);
  };

  const handleChangeProjectScope = (value) => {
    setMessage(null);
    setProjectScope(value);
  };
  const handleChangeOperator = (value) => {
    setMessage(null);
    setOperator(value);
  };

  let isValueNOTChanged =
    name.value === initialValue.name &&
    client.value === initialValue.client &&
    country.value === initialValue.country &&
    bilingAddress.value === initialValue.bilingAddress &&
    shippingAddress.value === initialValue.shippingAddress &&
    projectScope.value === initialValue.projectScope &&
    operator.value === initialValue.operator;

  let disable =
    name.error ||
    client.error ||
    country.error ||
    projectScope.error ||
    name.value === "" ||
    client.value === "" ||
    country.value === "" ||
    projectScope.value.length === 0 ||
    (name.success === false &&
      client.success === false &&
      country.success === false &&
      projectScope.success === false) ||
    isValueNOTChanged;

  let isEditValueNOTChanged =
    name.value === initialValue.name &&
    client.value === initialValue.client &&
    country.value === initialValue.country &&
    projectScope.value === initialValue.projectScope &&
    operator.value === initialValue.operator &&
    bilingAddress.value === initialValue.bilingAddress &&
    shippingAddress.value === initialValue.shippingAddress;

  let Editdisable =
    name.error ||
    client.error ||
    country.error ||
    projectScope.error ||
    bilingAddress.error ||
    shippingAddress.error ||
    operator.error ||
    name.value === "" ||
    client.value === "" ||
    country.value === "" ||
    projectScope.value.length === 0 ||
    bilingAddress.value === "" ||
    shippingAddress.value === "" ||
    operator.value === "" ||
    (name.success === false &&
      client.success === false &&
      country.success === false &&
      projectScope.success === false &&
      bilingAddress.success === false &&
      shippingAddress.success === false &&
      operator.success === false) ||
    isEditValueNOTChanged;

  const createProjectHeader = strings["ProjectManagement.CreateProjectHeader"],
    EditProjectHeader = strings["ProjectManagement.EditProjectHeader"],
    nameLabel = strings["ProjectManagement.nameLabel"],
    namePlaceholder = strings["ProjectManagement.namePlaceholder"],
    nameCustomError = strings["ProjectManagement.nameCustomError"],
    clientLabel = strings["ProjectManagement.clientLabel"],
    counryLabel = strings["ProjectManagement.countryLabel"],
    clientSelectText = strings["ProjectManagement.clientSelectText"],
    clientErrorLine = strings["ProjectManagement.clientErrorLine"],
    countryErrorLine = strings["ProjectManagement.countryErrorLine"],
    billingAddressLabel = strings["ProjectManagement.billingAddressLabel"],
    billingAddressPlaceholder =
      strings["ProjectManagement.billingAddressPlaceholder"],
    billingAddressCustomError =
      strings["ProjectManagement.billingAddressCustomError"],
    shippingAddressLabel = strings["ProjectManagement.shippingAddressLabel"],
    shippingAddressPlaceholder =
      strings["ProjectManagement.shippingAddressPlaceholder"],
    shippingAddressCustomError =
      strings["ProjectManagement.shippingAddressCustomError"],
    projectScopeLabel = strings["ProjectManagement.projectScopeLabel"],
    projectScopeSelectText =
      strings["ProjectManagement.projectScopeSelectText"],
    projectScopeErrorLine = strings["ProjectManagement.projectScopeErrorLine"],
    saveBtn = strings["ProjectManagement.saveBtn"],
    updateBtn = strings["ProjectManagement.updateBtn"],
    cancelBtn = strings["ProjectManagement.cancelBtn"],
    sameAsLabel = strings["BillingShippingAdd"],
    operatorLabel = strings["OperatorText"];

  const handleSaveData = () => {
    const scope_id = projectScope.value.map((ele) => {
      return ele.key;
    });
    const operatorId_list = [];
    operatorId_list.push(operator.value);
    const projectData = {
      name: name.value,
      client_id: client.value,
      country_id: country.value,
      billing_address: bilingAddress.value,
      shipping_address: Checked ? bilingAddress.value : shippingAddress.value,
      project_scope_id: scope_id,
      operator_id: operatorId_list ? operatorId_list : null,
    };
    handleProjectData(projectData);
  };

  const createClient = (formData) => {
    setLoading(true);
    CreateClient(formData)
      .then((res) => {
        setLoading(false);
        getAllActiveClient();
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        // setRows([res.data.data, ...rows]);
        handleModalCloses();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const createOperator = (formData) => {
    setLoading(true);
    CreateOperator(formData)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          getOperator();
          // setRows([res.data.data, ...rows]);
          handleModalOpCloses();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});

  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalCloses = () => {
    setMessage(null);
    setIsOpen(false);
  };
  const selectModal = () => {
    return (
      <ClientForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={clickedBtn === "add" ? createClient : null}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentType : {}}
        handleModalClose={handleModalClose}
      />
    );
  };
  const [isOpOpen, setIsOpOpen] = React.useState(false);
  const [clickedOpBtn, setClickedOpBtn] = React.useState("");
  const [currentOpType, setCurrentOpType] = React.useState({});
  const handleSelectOpBtn = (btn) => {
    setMessage(null);
    setClickedOpBtn(btn);
  };
  const openAddOpModal = () => {
    setIsOpOpen(true);
  };
  const handleModalOpCloses = () => {
    setMessage(null);
    setIsOpOpen(false);
  };
  const selectOpModal = () => {
    return (
      <CreateCompanyForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeData={clickedOpBtn === "add" ? createOperator : null}
        actionType={clickedOpBtn}
        initialValue={clickedOpBtn !== "add" ? currentOpType : {}}
        handleModalClose={handleModalOpCloses}
      />
    );
  };
  return (
    <div className="modalFormContainer">
      <Modal onHandleClose={handleModalCloses} isOpen={isOpen}>
        {selectModal()}
      </Modal>
      <Modal onHandleClose={handleModalOpCloses} isOpen={isOpOpen}>
        {selectOpModal()}
      </Modal>
      {loading ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>{actionType === "add" ? createProjectHeader : EditProjectHeader}</h2>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="alpha"
            maxLength={100}
            label={nameLabel}
            placeholder={namePlaceholder}
            Value={name.value}
            onChangeText={handleChangeName}
            Validate={true}
            CustomErrorLine={nameCustomError}
            editable={true}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-5 pe-0 pe-md-3" id="select-style">
          <FormDropDown
            Label={clientLabel}
            Options={clientOption}
            value={client.value}
            onSelect={handleChangeClient}
            customErrorLine={clientErrorLine}
            selectText={clientSelectText}
            searchable={false}
            disable={false}
            starProps={true}
            isValidate={true}
          />
        </div>
        <div className="col-12 col-md-1 mt-5">
          <span className="addButton">
            <IconButton
              onClick={() => {
                openAddModal();
                handleSelectBtn("add");
              }}
            >
              <AddIcon />
            </IconButton>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormDropDown
            labelStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            value={country.value}
            Label={counryLabel}
            Options={countryOption}
            Value={country.value}
            onSelect={handleChangeCountry}
            CustomErrorLine={countryErrorLine}
            searchable={false}
            disable={false}
            starProps={true}
            isValidate={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormDropDown
            labelStyle={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            defaultValue={projectScope.value}
            Label={projectScopeLabel}
            Options={projectScopeOption}
            value={projectScope.value}
            onSelect={handleChangeProjectScope}
            customErrorLine={projectScopeErrorLine}
            selectText={projectScopeSelectText}
            searchable={false}
            disable={false}
            starProps={true}
            isValidate={true}
            isMulti={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="textarea"
            labelTextStyle={{
              marginTop: "5px",
            }}
            label={billingAddressLabel}
            placeholder={billingAddressPlaceholder}
            Value={bilingAddress.value}
            onChangeText={handleChangeBillingAddress}
            // Validate={true}
            CustomErrorLine={billingAddressCustomError}
            editable={true}
            multiline={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            labelTextStyle={{
              marginTop: "5px",
            }}
            type="textarea"
            label={shippingAddressLabel}
            placeholder={shippingAddressPlaceholder}
            Value={Checked ? bilingAddress.value : shippingAddress.value}
            onChangeText={handleChangeShippingAddress}
            // Validate={true}
            CustomErrorLine={shippingAddressCustomError}
            editable={true}
            multiline={true}
            isMulti={true}
          />
          <FormCheckBox
            Label={sameAsLabel}
            checked={Checked}
            handleChangeCheck={handleChangeCheck}
          />
        </div>
      </div>
      <div className="row  align-items-center">
        <div className="col-12 col-md-5 pe-0 pe-md-3" id="select-style">
          <FormDropDown
            Label={operatorLabel}
            Options={operatorOption}
            value={operator.value}
            onSelect={handleChangeOperator}
            customErrorLine={projectScopeErrorLine}
            selectText={projectScopeSelectText}
            searchable={false}
            disable={false}
            starProps={false}
          />
        </div>
        <div className="col-12 col-md-1 mt-5">
          <span className="addButton">
            <IconButton
              onClick={() => {
                openAddOpModal();
                handleSelectOpBtn("add");
              }}
            >
              <AddIcon />
            </IconButton>
          </span>
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : false}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default CreateProject;
