import React from "react";
import { Helmet } from "react-helmet";
import {
    GetAllPurchaseOrderByvendor,
} from "../../../axios";
import {
    BreadCrumbs, StatusTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import "../SearchBudgetItem/BudgetItem.css";

const PaymentStatus = () => {
    const
        Furnish = Strings["Title.Furnish"],
        RFQLabel = Strings["Title.RFQ"];

    const [isLoader, setisLoader] = React.useState(false);
    const [allData, setAllData] = React.useState([]);

    const mapableDataRFQ = [
        {
            dataKey: "poSerialNo",
            title: "PO Serial No",
        },
        {
            dataKey: "orderPrice",
            title: "PO Price",
        },
        {
            dataKey: "invoicePrice",
            title: "Invoice Price",
        },
        {
            dataKey: "remainsPrice",
            title: "Remains Price",
        },
        {
            dataKey: "is_active",
            title: "Status",
            isStatus: true,
        },
    ];
    const paths = [
        {
            name: strings["DashBoardText"],
            path: "",
        },
        {
            name: "PaymentStatus",
        },
    ];

    // const handleChangePage = (newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value));
    //     setPage(0);
    // };

    const paymentStatus = () => {
        const vendorId = JSON.parse(localStorage.getItem("vendor"));
        setisLoader(true);
        GetAllPurchaseOrderByvendor(vendorId)
            .then((res) => {
                setisLoader(false);
                const result = res.data.data.POAmount.map((ele) => ({
                    poSerialNo: ele.purchase_order_seril_number,
                    orderPrice:
                        (ele.total_purchase_order_price).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'AED',
                        }),
                    invoicePrice: (ele.total_invoice_price).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'AED',
                    }),
                    remainsPrice: (ele.left_invoice_price).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'AED',
                    })
                    ,
                    is_active: ele.left_invoice_price > 0 ? 0 : 1
                }))
                setAllData(result);
            })
            .catch(() => {
                setisLoader(false);
            });
    };

    React.useEffect(() => {
        paymentStatus();
    }, []);




    const handleView = (id) => {
        // const resultRFQ = allData.find((res) => {
        //     return res.serial_no === id;
        // });
    };

    const handleVisible = (id) => {
        // const result = rfqData.find((row) => {
        //     return row.id === id;
        // });
        // const rfq_Id = isEncoded(result.id)
        // history.push({
        //     pathname: `/vendorquotation/${rfq_Id}`,
        //     state: {
        //         value: result.rfqName,
        //         rfqId: result.id,
        //     },
        // });
    };

    return (
        <div className="container mt-4">
            <Helmet>
                <title>{Furnish} | {RFQLabel} </title>
            </Helmet>
            {isLoader ? <LoaderComponent /> : null}

            <div className="mb-2">
                <h3 className=""> {"Payment Status"} </h3>
                <BreadCrumbs pathList={paths} />
            </div>
            <div
                className="RfqbudgetTable"
                style={{
                    marginBottom: "40px",
                }}
            >
                <StatusTable
                    data={allData}
                    handleView={handleView}
                    mapableData={mapableDataRFQ}
                    handleVisible={handleVisible}
                />
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </div>
        </div>
    );
};

export default PaymentStatus;