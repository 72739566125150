import { makeStyles } from "@material-ui/core";
import Input from "@material-ui/core/Input";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AllActiveStatusCurrency,
  GetAllActiveCountry,
  GetAllActiveIncoterm,
  // GetAllActivePaymentTerms,
  GetAllActiveVendor,
  // GetAllBudgetItemById,
  GetRfq,
} from "../../../axios";
import {
  Button,
  DateAndTimePicker,
  FormCheckBox,
  FormDropDown,
  FormTextInput,
  IconButton,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const useStyles = makeStyles((theme) => ({
  input: {
    width: 150,
    height: 40,
    alignItems: "center",
  },
  inputCell: {
    alignItems: "center",
  },
}));

const headCells = [
  {
    id: "sr no",
    numeric: false,
    disablePadding: false,
    label: "Ctrl. No.",
  },
  // {
  //   id: "item",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Item",
  // },
  // {
  //   id: "uom",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "UOM",
  // },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "unitprice",
    numeric: true,
    disablePadding: false,
    label: "Unit Price",
  },
];

function EnhancedTableHead(props) {
  // const { order, orderBy, onRequestSort } = props;
  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.input}>
      {isEditMode ? (
        <Input
          type="number"
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          className={classes.input}
          inputProps={{ min: 0 }}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

const Quotation = (props) => {
  const {
    setMessage,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    rfqId,
  } = props;

  const location = useLocation();
  const createTitle = Strings["Quotation.createTitle"],
    editTitle = Strings["Quotation.editTitle"],
    RFQNameError = Strings["Quotation.RFQNameError"],
    NameLabelError = Strings["Quotation.NameLabelError"],
    NamePlaceholder = Strings["Quotation.NamePlaceholder"],
    CurrencyError = Strings["Quotation.CurrencyError"],
    ValiduntilLabel = Strings["Quotation.ValiduntilLabel"],
    ValiduntilError = Strings["Quotation.ValiduntilError"],
    CountryOriginLabel = Strings["Quotation.CountryOriginLabel"],
    CountryOriginError = Strings["Quotation.CountryOriginError"],
    VendorLabel = Strings["Quotation.VendorLabel"],
    VendorError = Strings["Quotation.VendorError"],
    LeadTime = Strings["Quotation.LeadTime"],
    LeadTimeError = Strings["Quotation.LeadTimeError"],
    Costforfreight = Strings["Quotation.Costforfreight"],
    CostforFreightError = Strings["Quotation.CostforFreightError"],
    CostforFreightPlaceholder = Strings["Quotation.CostforFreightPlaceholder"],
    CostforDuties = Strings["Quotation.CostforDuties"],
    CostforDutiesError = Strings["Quotation.CostforDutiesError"],
    CostforDutiesPlaceholder = Strings["Quotation.CostforDutiesPlaceholder"],
    Comment = Strings["Quotation.Comment"],
    CommentError = Strings["Quotation.CommentError"],
    CommentPlaceholder = Strings["Quotation.CommentPlaceholder"],
    checkboxLabel = Strings["Quotation.checkboxLabel"],
    TagLabel = Strings["Quotation.TagLabel"],
    TagLabelError = Strings["Quotation.TagLabelError"],
    TagLabelPlaceholder = Strings["Quotation.TagLabelPlaceholder"],
    nextButton = Strings["Quotation.nextButton"],
    PrevButton = Strings["Quotation.PrevButton"],
    CancelButton = Strings["Quotation.CancelButton"],
    Submit = Strings["Quotation.Submit"];

  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = useState(true);
  const [table, setTable] = useState(false);

  const [RFqName, setRFqName] = useState({
    value: initialValue.rfqName ? initialValue.rfqName : location.state?.value,
    error: false,
    success: false,
  });
  const [quotationName, setquotationName] = useState({
    value: initialValue.quotationName ? initialValue.quotationName : "",
    error: false,
    success: false,
  });
  const [quotationNo] = useState({
    value: initialValue.quotation_no ? initialValue.quotation_no : "",
    error: false,
    success: false,
  });
  const [currency, setCurrency] = useState({
    value: initialValue.currency ? initialValue.currency.id : "",
    error: false,
    success: false,
  });

  const [validUntil, setvalidUntil] = useState({
    value: initialValue.validuntil ? initialValue.validuntil : "",
    error: false,
    success: false,
  });
  const [origin, setorigin] = useState({
    value: initialValue.country ? initialValue.country.id : "",
    error: false,
    success: false,
  });
  const [vendor, setvendor] = useState({
    value: initialValue.vendor ? initialValue.vendor.id : "",
    error: false,
    success: false,
  });
  const [paymentTerms] = useState({
    value: initialValue.paymentinfo ? initialValue.paymentinfo.id : "",
    error: false,
    success: false,
  });
  const [incoterm, setIncoterm] = useState({
    value: initialValue.shippinginfo ? initialValue.shippinginfo.id : "",
    error: false,
    success: false,
  });
  const [optionsIncoterm, setOptionsIncoterm] = React.useState([]);
  const [lead, setLead] = useState({
    value: initialValue.leadTime ? initialValue.leadTime[0] : "",
    error: false,
    success: false,
  });

  const [leadTime] = useState({
    value: initialValue.leadTime?.length ? initialValue.leadTime[1] : "",
    error: false,
    success: false,
  });

  const [costforFreight, setcostforFreight] = useState({
    value: initialValue.freight ? initialValue.freight : "",
    error: false,
    success: false,
  });
  const [costforDuties, setcostforDuties] = useState({
    value: initialValue.duties ? initialValue.duties : "",
    error: false,
    success: false,
  });

  const [installationIncluded, setinstallationIncluded] = React.useState(
    initialValue.activeStatuss && initialValue.activeStatuss
  );
  const [tag, setTag] = useState({
    value: initialValue.tag ? initialValue.tag : "",
    success: false,
  });
  const [comment, setComment] = useState({
    value: initialValue.comment ? initialValue.comment : "",
    success: false,
  });
  const [selectedData, setSelectedData] = useState([]);

  const [days, setDays] = React.useState({
    value: initialValue.leadTime?.length ? initialValue.leadTime[1] : "",
    error: false,
    success: false,
  });

  let saveDisable = selectedData?.length > 0 ? false : true;
  React.useEffect(() => {
    setLoading(true);
    if (actionType === "edit") {
      const result = initialValue.budget_item.map((ele) => ({
        id: ele.id,
        Srno: ele.control_number,
        itemname: ele.name,
        uom: ele.budgetquantitydetails.budgetuom?.name,
        quantity: ele.budgetquantitydetails?.budget_quantity,
        activeStatus: ele.is_active,
        unitPrice: ele.unit_price,
        isEditMode: true,
      }));
      setLoading(false);
      setRows(result);
    } else {
      GetRfq(rfqId)
        .then((res) => {
          setLoading(false);
          let data = res.data.data;
          const result = data.budget_item.map((ele) => ({
            id: ele.budgetitem.id,
            Srno: ele.budgetitem.control_number,
            itemname: ele.budgetitem.name,
            quantity: ele.budgetitem.budgetquantitydetails.budget_quantity,
            activeStatus: ele.budgetitem.is_active,
            unitPrice: null,
            isEditMode: true,
          }));
          setRows(result);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [actionType, rfqId]);

  React.useEffect(() => {
    setLoading(true);
    GetAllActiveIncoterm()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setLoading(false);
        setOptionsIncoterm(result);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    AllActiveStatusCurrency()
      .then((res) => {
        setLoading(false);
        const result = res.data.data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setOptionsCurrency(result);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    GetAllActiveCountry()
      .then((res) => {
        setLoading(false);
        const result = res.data.data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setOptionsCountry(result);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    GetAllActiveVendor()
      .then((res) => {
        setLoading(false);
        const result = res.data.data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setOptionsVendor(result);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleRFqName = (value) => {
    setRFqName(value);
    setMessage(null);
  };

  const handleQuotation = (value) => {
    setMessage(null);
    setquotationName(value);
  };

  const handleCurrency = (data) => {
    setCurrency(data);
  };

  const handleIncoterm = (data) => {
    setIncoterm(data);
  };

  const handleValidUntil = (data) => {
    setvalidUntil(data);
  };

  const handleCountry = (value) => {
    setorigin(value);
  };
  const handleVendor = (value) => {
    setvendor(value);
  };
  const handleLead = (value) => {
    setLead(value);
  };

  const handleCostofFreight = (value) => {
    setcostforFreight(value);
  };

  const handleCostforDuties = (value) => {
    setcostforDuties(value);
  };

  const handleTag = (value) => {
    setTag(value);
  };

  const handleComment = (value) => {
    setComment(value);
  };

  const handleInstallationIncluded = () => {
    setinstallationIncluded(!installationIncluded);
  };

  const [previous, setPrevious] = React.useState({});

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
    setSelectedData(newRows);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePrevClick = () => {
    setForm(true);
    setTable(false);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const handleMoveData = (value) => {
    setTable(true);
    setForm(false);
  };

  const handleSaveData = (value) => {
    const filterBudgetItem = rows.filter((ele) => {
      return ele.unitPrice !== null;
    });
    const budgetItem = filterBudgetItem.map((item) => {
      return {
        budget_item_id: item.id,
        unit_price: item.unitPrice,
        total_price: item.unitPrice * parseInt(item.quantity),
      };
    });
    let rfq = location.state.rfqId;
    const inlt_charge = parseInt(costforFreight.value);
    const duty_cost = parseInt(costforDuties.value);
    const leadTime = `${lead.value} ${days.value}`;

    const formData = {
      rfq_id: rfq,
      cost_for_freight_installation: inlt_charge,
      quotation_name: quotationName.value,
      valid_till: validUntil.value,
      country_id: origin.value,
      currency_id: currency.value,
      vendor_id: vendor.value,
      lead_time: leadTime,
      cost_for_duties: duty_cost,
      payment_info: paymentTerms.value.toString(),
      shipping_info: incoterm.value.toString(),
      tags: tag.value,
      comment: comment.value,
      installation_included: installationIncluded === true ? 1 : 0,
      budget_item: budgetItem,
    };
    handleChangeData(formData);
  };

  const [optionsCountry, setOptionsCountry] = React.useState([]);
  const [optionsVendor, setOptionsVendor] = React.useState([]);

  const leadOption = [
    { key: 1, label: "Day", value: "days" },
    { key: 2, label: "Week", value: "week" },
  ];

  const [optionsCurrency, setOptionsCurrency] = React.useState([]);

  const handleDayPicker = (value) => {
    setDays(value);
  };

  let isValueNOTChanged =
    RFqName.value === initialValue.rfqName &&
    quotationName.value === initialValue.quotationName &&
    currency.value === initialValue.currency &&
    validUntil.value === initialValue.validuntil &&
    origin.value === initialValue.country &&
    leadTime.value === initialValue.leadtime &&
    costforFreight.value === initialValue.freight &&
    costforDuties.value === initialValue.duties &&
    tag.value === initialValue.tag &&
    comment.value === initialValue.comment;

  let disable =
    quotationName.error ||
    currency.error ||
    validUntil.error ||
    vendor.error ||
    lead.error ||
    days.error ||
    quotationName.value === "" ||
    currency.value === "" ||
    validUntil.value === "" ||
    vendor.value === "" ||
    lead.value === "" ||
    days.value === "" ||
    (quotationName.success === false &&
      currency.success === false &&
      validUntil.success === false &&
      vendor.success === false &&
      lead.success === false &&
      days.success === false) ||
    isValueNOTChanged;

  var dateToday = new Date();

  return (
    <>
      {form && (
        <div className="modalFormContainer">
          {loading ? <LoaderComponent /> : null}
          {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
          <h2>{actionType === "add" ? createTitle : editTitle}</h2>
          <div className="row">
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormTextInput
                type="text"
                label={"RFQ Serial No."}
                Value={RFqName.value}
                onChangeText={handleRFqName}
                Validate={true}
                CustomErrorLine={RFQNameError}
                editable={false}
              />
            </div>
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormTextInput
                type="textarea"
                label={"Quotation Reference"}
                placeholder={NamePlaceholder}
                Value={quotationName.value}
                onChangeText={handleQuotation}
                Validate={true}
                CustomErrorLine={NameLabelError}
                editable={true}
                starProps={true}
                maxLength={50}
              />
            </div>
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              {actionType === "edit" ? (
                <FormTextInput
                  type="textarea"
                  label={"Quotation Serial No."}
                  Value={quotationNo.value}
                  editable={false}
                  starProps={true}
                  maxLength={50}
                />
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormDropDown
                labelStyle={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                value={currency.value}
                Label={"Currency"}
                Options={optionsCurrency}
                CustomErrorLine={CurrencyError}
                selectionLimit={1}
                onSelect={handleCurrency}
                starProps={true}
                isValidate={true}
              />
            </div>
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <DateAndTimePicker
                dateAndTimeLabel={{
                  marginTop: "-10px",
                  marginBottom: "10px",
                }}
                label={ValiduntilLabel}
                customErrorLine={ValiduntilError}
                hadleSetDate={handleValidUntil}
                date={validUntil.value}
                disable={false}
                minDate={moment(dateToday).format("YYYY-MM-DD")}
                starProps={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormDropDown
                labelStyle={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                value={origin.value}
                Label={CountryOriginLabel}
                Options={optionsCountry}
                CustomErrorLine={CountryOriginError}
                onSelect={handleCountry}
                starProps={false}
              />
            </div>
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormDropDown
                labelStyle={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                value={vendor.value}
                Label={VendorLabel}
                Options={optionsVendor}
                CustomErrorLine={VendorError}
                selectionLimit={1}
                onSelect={handleVendor}
                starProps={true}
                isValidate={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 col-md-3 pe-0 pe-md-3">
              <FormDropDown
                labelStyle={{
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
                value={lead.value}
                Options={leadOption}
                onSelect={handleLead}
                Label={LeadTime}
                starProps={true}
                isValidate={true}
                CustomErrorLine={LeadTimeError}
              />
            </div>
            <div className="col-3 col-md-3 pe-0 pe-md-3">
              <FormTextInput
                labelTextStyle={{
                  marginTop: "8px",
                  fontSize: "14px",
                }}
                placeholder={lead.value === "week" ? "Week" : "Day"}
                Validate={true}
                type="number"
                Value={parseInt(days.value)}
                onChangeText={handleDayPicker}
                className="w-25"
                editable={true}
                starProps={true}
                label={`${lead.value === "week" ? "Week" : "Day"}`}
                CustomErrorLine={LeadTimeError}
              />
            </div>
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormTextInput
                type="number"
                label={Costforfreight}
                placeholder={CostforFreightPlaceholder}
                Value={costforFreight.value}
                onChangeText={handleCostofFreight}
                Validate={costforFreight.value && true}
                CustomErrorLine={CostforFreightError}
                editable={true}
                starProps={false}
                maxLength={10}
                min={0}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormDropDown
                labelStyle={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                value={incoterm.value}
                Label={"Incoterm"}
                Options={optionsIncoterm}
                selectionLimit={1}
                onSelect={handleIncoterm}
                starProps={false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormTextInput
                type="number"
                label={CostforDuties}
                placeholder={CostforDutiesPlaceholder}
                Value={costforDuties.value}
                onChangeText={handleCostforDuties}
                Validate={true}
                CustomErrorLine={CostforDutiesError}
                editable={true}
                starProps={false}
                min={0}
              />
            </div>
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormTextInput
                type="textarea"
                label={TagLabel}
                placeholder={TagLabelPlaceholder}
                Value={tag.value}
                onChangeText={handleTag}
                Validate={tag.value && true}
                CustomErrorLine={TagLabelError}
                editable={true}
                starProps={false}
                maxLength={100}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 ">
              <FormTextInput
                type="textarea"
                label={Comment}
                placeholder={CommentPlaceholder}
                Value={comment.value}
                onChangeText={handleComment}
                Validate={comment.value && true}
                CustomErrorLine={CommentError}
                editable={true}
                multiline={true}
                starProps={false}
                maxLength={100}
              />
            </div>
            <div className="col-12 col-md-6 ">
              <div className="mt-3">
                <FormCheckBox
                  Label={checkboxLabel}
                  checked={installationIncluded}
                  handleChangeCheck={() => handleInstallationIncluded()}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "1.5rem",
              }}
            >
              <Button
                buttonName={CancelButton}
                buttonStyle={{ backgroundColor: colors.primary }}
                onClick={() => {
                  handleModalClose();
                }}
              />
              <Button
                buttonName={actionType === "add" ? nextButton : nextButton}
                buttonStyle={{ backgroundColor: colors.primary }}
                disable={actionType === "add" ? disable : false}
                onClick={handleMoveData}
              />
            </div>
          </div>
        </div>
      )}

      {table && (
        <div className="container">
          {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
          <h2 className="ms-5 mb-3">
            {actionType === "add" ? "Select Items" : "Update Items"}
          </h2>
          <hr />
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 3, alignItems: "center" }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 600 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.name}>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell align="left">{row.Srno}</TableCell>
                          <TableCell align="left">{row.quantity}</TableCell>
                          <td>
                            <span>
                              <CustomTableCell
                                {...{ row, name: "unitPrice", onChange }}
                              />
                            </span>
                          </td>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          <div
            style={{
              display: "flex",
              marginTop: "1.5rem",
              justifyContent: "space-between",
            }}
          >
            <Button
              buttonName={actionType === "add" ? PrevButton : PrevButton}
              buttonStyle={{ backgroundColor: colors.primary }}
              onClick={handlePrevClick}
            />
            <Button
              buttonName={CancelButton}
              buttonStyle={{ backgroundColor: colors.primary }}
              onClick={() => {
                handleModalClose();
                setMessage(null);
              }}
            />
            <Button
              buttonName={Submit}
              buttonStyle={{ backgroundColor: colors.primary }}
              onClick={() => {
                handleSaveData();
                handleModalClose();
              }}
              disable={saveDisable}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Quotation;
