import React,{useState} from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const ClientContactForm = (props) => {
  const {
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    client,
    errorMessage,
    setMessage,
  } = props;

  const createclientheader = Strings["ClientContact.CreateForm"],
    editclientheader = Strings["ClientContact.EditForm"],
    clientContactNameLabel = Strings["ClientContact.ContactNameLabel"],
    clientContactNameplaceholder =
      Strings["ClientContact.ContactNamePlaceHolder"],
    clientContactNameCustomError = Strings["ClientContact.ContactNameError"],
    clientContactDesignationLabel =
      Strings["ClientContact.ContactDesignationLabel"],
    clientContactDesignationPlaceHolder =
      Strings["ClientContact.ContactDesignationPlaceHolder"],
    clientContactDesignationError =
      Strings["ClientContact.ContactDesignationError"],
    clientContactTelephoneLabel =
      Strings["ClientContact.ContactTelephoneLabel"],
    clientContactTelephonePlaceHolder =
      Strings["ClientContact.ContactTelephonePlaceHolder"],
    clientContactTelephoneError =
      Strings["ClientContact.ContactTelephoneError"],
    clientContactMobileLabel = Strings["ClientContact.ContactMobileLabel"],
    clientContactMobilePlaceHolder =
      Strings["ClientContact.ContactMobilePlaceHolder"],
    clientContactMobileError = Strings["ClientContact.ContactMobileError"],
    clientContactEmailLabel = Strings["ClientContact.ContactEmailLabel"],
    clientContactEmailPlaceHolder =
      Strings["ClientContact.ContactEmailPlaceHolder"],
    clientContactEmailError = Strings["ClientContact.ContactEmailError"],
    clientContactSaveBtn = Strings["ClientContact.SaveBtn"],
    clientContactUpdateBtn = Strings["ClientContact.UpdateBtn"],
    clientContactCancelBtn = Strings["ClientContact.CancelBtn"];

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    sucess: false,
  });
  const [designation, setDesignation] = React.useState({
    value: initialValue.designation ? initialValue.designation : "",
    error: false,
    sucess: false,
  });
  const [telephone, setTelephone] = React.useState({
    value: initialValue.telephone ? initialValue.telephone : "",
    error: false,
    sucess: false,
  });
  const [mobile, setMobile] = React.useState({
    value: initialValue.mobile ? initialValue.mobile : "",
    error: false,
    sucess: false,
  });
  const [email, setEmail] = React.useState({
    value: initialValue.email ? initialValue.email : "",
    error: false,
    sucess: false,
  });
  const [selectedFile, setSelectedFile] = React.useState({
    value: "",
    error: false,
    success: false,
  });

  const user = JSON.parse(localStorage.getItem("user"));
  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };
  const handleChangeDesignation = (value) => {
    setDesignation(value);
  };
  const handleChangeTelephone = (value) => {
    setTelephone(value);
  };
  const handleChangeMobile = (value) => {
    setMobile(value);
  };
  const handleChangeEmail = (value) => {
    setEmail(value);
  };

//   const handleChange= (e) =>{
//     console.log(e.target.files);
//     setFile(URL.createObjectURL(e.target.files[0]));

// }

  const handleSaveData = () => {
    // const filename = selectedFile.split("\\").pop().split("/").pop();
    const formData = {
      name: name.value,
      client_id: client,
      designation: designation.value,
      telephone: telephone.value,
      mobile: mobile.value,
      email: email.value,
      profile_picture: null,
      action_by: user.id,

    };

    handleChangeFormData(formData);
  };

  let isValueChanged =
    name.value === initialValue.name &&
    email.value === initialValue.email;

  let disable =
    name.error ||
    email.error ||
    name.value === "" ||
    email.value === "" ||
    // selectedFile.error ||
    // selectedFile.value === "" ||
    // (selectedFile.success === false) ||
    (name.sucess === false &&
      email.sucess === false) ||
    isValueChanged;
  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createclientheader : editclientheader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            maxLength={100}
            type="alpha"
            starProps={true}
            label={clientContactNameLabel}
            placeholder={clientContactNameplaceholder}
            Value={name.value}
            onChangeText={handleChangeName}
            Validate={true}
            CustomErrorLine={clientContactNameCustomError}
            editable={true}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            type="text"
            maxLength={30}
            starProps={false}
            label={clientContactDesignationLabel}
            placeholder={clientContactDesignationPlaceHolder}
            Value={designation.value}
            onChangeText={handleChangeDesignation}
            Validate={false}
            CustomErrorLine={clientContactDesignationError}
            editable={true}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            type="phone"
            starProps={false}
            label={clientContactTelephoneLabel}
            placeholder={clientContactTelephonePlaceHolder}
            Value={telephone.value}
            onChangeText={handleChangeTelephone}
            Validate={false}
            CustomErrorLine={clientContactTelephoneError}
            editable={true}
            maxLength={10}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            type="phone"
            starProps={false}
            label={clientContactMobileLabel}
            placeholder={clientContactMobilePlaceHolder}
            Value={mobile.value}
            onChangeText={handleChangeMobile}
            Validate={false}
            CustomErrorLine={clientContactMobileError}
            editable={true}
            maxLength={10}
          />
        </div>
        <div className="row">
        <div className="col-md-6 col-sm-12">
          <FormTextInput
            type="email"
            starProps={true}
            label={clientContactEmailLabel}
            placeholder={clientContactEmailPlaceHolder}
            Value={email.value}
            onChangeText={handleChangeEmail}
            Validate={true}
            CustomErrorLine={clientContactEmailError}
            editable={true}
          />
        </div>
        {/* <div className="col-md-6 col-sm-12">
             <input type="file" onChange={handleChange} accept="image/png, image/jpeg" value={selectedFile.value}/>
        </div> */}
        </div>
        {/* <div className="row">
            <img src={selectedFile.value} />
        </div> */}
       
        

      </div>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? clientContactSaveBtn : clientContactUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          // disable={disable}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={clientContactCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
          }}
        />
      </div>
    </div>
  );
};

export default ClientContactForm;
