import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const Progressbar = (props) => {
  const { label, percent, color, data } = props;
  return (
    <tr>
      <td className="text-muted">{label}</td>
      <td className="w-50 px-0">
        <div>
          <Progress.Line percent={percent} strokeColor={color} />
        </div>
      </td>
      <td>
        <h5 className="font-weight-bold mb-0">{data}</h5>
      </td>
    </tr>
  );
};

export default Progressbar;
