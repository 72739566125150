import TablePagination from "@mui/material/TablePagination";
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal as ReviseModal,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import {
  ChangeRFQStatus,
  GetAllHistoryRFQById,
  GetAllRFQ,
  GetRfq,
  UpdateRFQ,
} from "../../../axios";
import {
  AlertMessage,
  BreadCrumbs,
  Modal,
  RFQTableDocument,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { RequestForQuotationForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_RFQ_STATUS_FUNCTION,
  UPDATE_RFQ,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import "../SearchBudgetItem/BudgetItem.css";
import QuotationDownload from "../SearchQuotation/QuotationDownload";
import "./SearchDocument.css";
import { getItemFromStorage } from "../../../utils/storage";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";
import { IconButton } from "rsuite";

const SearchDocument = () => {
  const Furnish = Strings["Title.Furnish"],
    RFQLabel = Strings["Title.RFQ"],
    serialNo = Strings["Document.tableHead.RFQSerial"],
    Name = Strings["Document.tableHead.RFQName"],
    Deadline = Strings["Document.tableHead.Deadline"],
    action = Strings["Document.tableHead.action"],
    AlertTitleLabel = Strings["Document.rfiAlert"],
    AlertMsgLabel = Strings["Document.rfiAlertMessage"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [isRevisedOpen, setIsRevisedOpen] = React.useState(false);
  const [isRFQOpen, setIsRFQOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [RfqId, setRfqId] = React.useState();
  const params = useParams();
  const [rfqData, setRFQData] = React.useState([]);
  const [openDownload] = React.useState(false);
  const [downloadData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [revisedList, setRevisedList] = React.useState([]);
  const [RFQDatas, setRFQDatas] = useState([]);

  const location = useLocation();
  console.log("LOCATION",location)
  let history = useHistory();
  let proId = location?.state
    ? location.state.proId
    : location?.query
    ? JSON.parse(location.query.params).proId
    : null;

  
  const fetchId = useParams();
  const BudId = isDecoded(fetchId.budgetID);
  const BudgtId = parseInt(BudId);
  let budget_id = location?.state ? location.state.budget_id : BudgtId;
 
  

  

  let scope_id = location?.state ? location?.state?.scope_id : location?.query
  ? JSON.parse(location.query.params).scope_id
  : null;
  let budget_Name = location?.state ? location.state?.budget_name
    : location?.query ? JSON.parse(location.query.params).budget_name
    : null;

  

  if(scope_id == null || scope_id == undefined && budget_Name == null || budget_Name == undefined && proId == null || proId == undefined && budget_id == null || budget_id == undefined){
      const paramData = JSON.parse(getItemFromStorage("PARAMS"))
      if(paramData){
          budget_id = paramData.budget_id;
          proId = paramData.proId;
          budget_Name = paramData.budget_Name;
          scope_id =  paramData.budget_scope;
      }
     
  }
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_RFQ)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_RFQ_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);
  const paths = [
    {
      name: strings["Projects"],
      path: "project",
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["Rfq"],
      path: "searchdocument",
    },
  ];

  const mapableDataRFQ = [
    {
      dataKey: "serial_no",
      title: serialNo,
      isviewlink: true,
    },
    {
      dataKey: "rfqName",
      title: Name,
    },

    {
      dataKey: "deadline",
      title: Deadline,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
      isview: true,
      isReviseShow: true,
      isDownload: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const getAllRfq = () => {
    let id = budget_id;
    setisLoader(true);
    GetAllRFQ(id, rowsPerPage, page)
      .then((res) => {
        setisLoader(false);
        const result = res.data.data.map((elm) => ({
          id: elm.id,
          rfqName: elm.name,
          serial_no: elm.rfq_number,
          deadline: elm.budget.budget_end_date.split(" ")[0],
          queryDeadline: elm.query_deadline,
          incoterm: elm.incoterm.name,
          incoterm_id: elm.incoterm,
          tendorNotes: elm.tendor_notes,
          importantNotes: elm.important_notes,
          budget_item: elm.budget_item,
          IssuingDt: elm.budget.budget_start_date,
          budgetNo: elm.budget.serial_number,
          vendors: elm.requestvendor,
          submission: elm.mode_of_submission,
          rfqReferenceField: elm.rfq_reference_field,
          rfqPackage: elm.rfq_package,
        }));
        setRFQData(result);
        setTotalData(res.data.total);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAllRfq();
  }, [rowsPerPage, page]);

  const updateRFQ = (formData) => {
    setisLoader(true);
    UpdateRFQ(formData, RfqId)
      .then((res) => {
        getAllRfq();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <RequestForQuotationForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        budget_id={params}
        handleChangeData={updateRFQ}
        actionType={clickedBtn}
        initialValue={currentType}
        handleModalClose={handleModalClose}
        selectedItem={currentType.budget_item}
      />
    );
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setAlertBox(false);
    setisLoader(true);
    ChangeRFQStatus(RfqId)
      .then((res) => {
        getAllRfq();
        setisLoader(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const handleChangeEditRFQ = (id) => {
    const resultRFQ = rfqData.find((res) => {
      return res.id === id;
    });
    setRfqId(resultRFQ.id);
    setCurrentType(resultRFQ);
    openAddModal();
    handleSelectBtn("editRFQ");
  };
  const handleChangeDelete = (id) => {
    setAlertBox(true);
    const resultRFQ = rfqData.find((res) => {
      return res.id === id;
    });
    setRfqId(resultRFQ.id);
  };

  const handleView = (id) => {
    const resultRFQ = rfqData.find((res) => {
      return res.serial_no === id;
    });
    setRFQDatas(resultRFQ);
    setIsRFQOpen(true);
  };
  const handleShowReviseData = (id) => {
    setisLoader(true);
    GetAllHistoryRFQById(id)
      .then((res) => {
        const result = res.data.data.map((ele) => {
          return {
            serial_no: ele.rfq_number,
            items: ele.budget_item_history.map((ele) => {
              return ele.budgetitem.name;
            }),
            queryDeadline: ele.query_deadline,
            email:
              ele.rfqemailhistory.length !== 0
                ? ele.rfqemailhistory.map((ele) => {
                    return ele.email;
                  })
                : "NA",
            cc:
              ele.emailcchistory.length !== 0
                ? ele.emailcchistory.map((ele) => {
                    return ele.email;
                  })
                : "NA",
            incoterm: ele.incoterm.name,
            vendors:
              ele.requestvendorhistory.length !== 0
                ? ele.requestvendorhistory.map((ele) => {
                    return ele.vendor.name;
                  })
                : "NA",
          };
        });
        setisLoader(false);
        setRevisedList(result);
        setIsRevisedOpen(true);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const handleVisible = (id) => {
    const result = rfqData.find((row) => {
      return row.id === id;
    });
    const rfq_Id = isEncoded(result.id);

    history.push({
      pathname: `/quotation/${rfq_Id}`,
      state: {
        key: params,
        value: result.rfqName,
        rfqId: result.id,
        budget_id: budget_id,
        proId: proId,
      },
    });
  };
  // RFQ Download start
  const createDownLoadData = (data, data2) => {
    handleExport(data, data2).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "RFQ_report.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }
    return buf;
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = (data, data2) => {
    const title = [
      {
        A: "Request For Quotation",
      },
    ];

    let table = [
      {
        A: "PROJECT NAME:",
        B: data2.ProjectName,
      },
      {
        A: "PACKAGE:",
        B: data2.Package,
      },
    ];

    let table1 = [
      {
        A: "Control Number",
        B: "Category Name",
        C: "Qty",
        D: "Item Specifications",
        E: "UOM",
        F: "Image",
      },
      // {},
    ];

    data.forEach((row) => {
      table1.push({
        A: row.Control_Number,
        B: row.Items,
        C: row.Qty,
        D: row.Specifications,
        E: row.UOM,
        F: row.image,
      });
    });

    let table2 = [
      // {},
      {
        G: "Grand Total DDP Site:",
        H: "Enter currency here",
      },
      {
        A: "TENDER NOTES:",
        G: "BIDDERS RESPONSE:",
      },
      {
        A: "§ Please review the specs carefully and quote accordingly.",
      },
      {
        A: "§ BOQ to be submitted only on the format of this sheet. Can be printed on Bidder's letter head.",
      },
      {
        A: "§ Ensure all columns of this BOQ sheet are filled up.",
      },
      {
        A: "§ Lead time (from receipt of order confirmation) ",
      },
      {
        A: "§ Validity of Quotation to be minimum 60 days.",
      },
      {
        A: "§ Delivery terms:",
      },
      {
        A: "§ Warranty:",
      },
      {
        A: "§ Payment terms:",
      },
      {
        A: "§ Type contact details here (Name, position, mobile, telephone, email etc.)",
      },
    ];

    table1 = [{}]
      // .concat(blank_table)
      .concat(table)
      .concat([""])
      .concat(table1)
      .concat([""])
      .concat(table2);

    const finalData = [...title, ...table1];
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, sheet, "rfq_report");
    const workbookBlob = workbook2blob(wb);
    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Control Number"
        ? headerIndexes.push(index)
        : data["A"] === "TENDER NOTES:"
        ? headerIndexes.push(index)
        : null
    );
    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:F1",
      titleRange2: "H1:J2",
      tbodyRange: `A2:F${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:F${headerIndexes[0] + 1}`
          : null,
      theadRange1:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 1}:F${headerIndexes[1] + 1}`
          : null,
      theadBider:
        headerIndexes?.length >= 2
          ? `G${headerIndexes[1] + 1}:J${headerIndexes[1] + 1}`
          : null,
      tTenderNotes:
        headerIndexes?.length >= 2 ? `${headerIndexes[1] + 1}` : null,

      note1:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 2}:F${headerIndexes[1] + 2}`
          : null,
      note2:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 3}:F${headerIndexes[1] + 3}`
          : null,
      note3:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 4}:F${headerIndexes[1] + 4}`
          : null,
      note4:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 5}:F${headerIndexes[1] + 5}`
          : null,
      note5:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 6}:F${headerIndexes[1] + 6}`
          : null,
      note6:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 7}:F${headerIndexes[1] + 7}`
          : null,
      note7:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 8}:F${headerIndexes[1] + 8}`
          : null,
      note8:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 9}:F${headerIndexes[1] + 9}`
          : null,
      note9:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 10}:F${headerIndexes[1] + 10}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };
  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(17);
        sheet.column("B").width(30);
        sheet.column("C").width(5);
        sheet.column("D").width(20);
        sheet.column("E").width(7);
        sheet.column("F").width(15);
        sheet.column("G").width(25);
        sheet.column("H").width(10);
        sheet.column("I").width(15);
        sheet.column("J").width(15);
        sheet.row(1).height(50);
        sheet.row(2).height(20);
        sheet.row(3).height(20);
        sheet.row(4).height(20);
        sheet.row(5).height(20);
        sheet.row(6).height(30);
        sheet.row(dataInfo.tTenderNotes).height(30);
        sheet.row(parseInt(dataInfo.tTenderNotes) - 1).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 1).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 2).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 3).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 4).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 5).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 6).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 7).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 8).height(20);
        sheet.row(parseInt(dataInfo.tTenderNotes) + 9).height(20);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 22,
          fill: "A0F03C",
          fontColor: "000000",
        });
        sheet.range("C2:H4").merged(true);
        sheet.range("A5:F5").merged(true);
        sheet.range("A2:B2").merged(true).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });
        sheet.range("J2:J2").value("XXXXX").style({
          fontColor: "000000",
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });
        sheet.range("J3:J3").value("DD-MM-YYYY").style({
          fontColor: "000000",
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range("J2:J4").merged(false).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          wrapText: "true",
          border: "thin",
        });
        sheet.range("A3:A4").style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "A0F03C",
          fontColor: "000000",
        });
        sheet.range("B3:B4").style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 10,
          fontColor: "000000",
        });

        sheet
          .range("G1:J1")
          .merged(true)
          .value("ENTER YOUR COMPANY NAME HERE")
          .style({
            fill: "d9d9d9",
            fontColor: "000000",
            fontSize: 22,
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });

        sheet.range("A1:J1").merged(false).style({
          border: "thin",
        });
        sheet.range("A6:J6").merged(false).style({
          fontColor: "000000",
          border: "thin",
        });
        sheet.range("A3:B4").merged(false).style({
          border: "thin",
        });

        sheet.range("I2:I2").merged(true).value("Quotation Reference:").style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d9d9d9",
          fontColor: "000000",
          border: "thin",
        });
        sheet.range("I3:I3").merged(true).value("Date:").style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fill: "d9d9d9",
          fontColor: "000000",
          border: "thin",
        });
        sheet.range("I4:I4").merged(true).value("Options:").style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 11,
          fill: "d9d9d9",
          fontColor: "000000",
          border: "thin",
        });

        sheet.range("G5:J5").merged(true).value("BIDDERS RESPONSE:").style({
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fill: "d9d9d9",
          fontSize: 14,
          fontColor: "000000",
          border: "thin",
        });

        sheet.cell("G6").value("Bidder Specifications:").style({
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fontColor: "000000",
          fill: "d9d9d9",
          border: "thin",
        });
        sheet.cell("H6").value("Unit Rate:").style({
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fontColor: "000000",
          fill: "d9d9d9",
          border: "thin",
        });
        sheet.cell("I6").value("Total:").style({
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fontColor: "000000",
          fill: "d9d9d9",
          border: "thin",
        });
        sheet.cell("J6").value("Product Image:").style({
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontSize: 12,
          fontColor: "000000",
          fill: "d9d9d9",
          border: "thin",
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "left",
            verticalAlignment: "center",
            border: "thin",
            
          });
        }
        sheet.range(dataInfo.theadRange).style({
          fill: "A0F03C",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
          fontColor: "000000",
          border: "thin",
        });
        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).merged(true).style({
            fill: "A0F03C",
            fontSize: 12,
            bold: true,
            horizontalAlignment: "left",
            verticalAlignment: "center",
            fontColor: "000000",
            border: "thin",
          });
        }

        if (dataInfo.note1) {
          sheet.range(dataInfo.note1).merged(true).style({
          border: "thin",
          });
        }
        if (dataInfo.note2) {
          sheet.range(dataInfo.note2).merged(true).style({
          border: "thin",
          });;
        }
        if (dataInfo.note3) {
          sheet.range(dataInfo.note3).merged(true).style({
          border: "thin",
          });;
        }
        if (dataInfo.note4) {
          sheet.range(dataInfo.note4).merged(true).style({
          border: "thin",
          });;
        }
        if (dataInfo.note5) {
          sheet.range(dataInfo.note5).merged(true).style({
          border: "thin",
          });;
        }
        if (dataInfo.note6) {
          sheet.range(dataInfo.note6).merged(true).style({
          border: "thin",
          });;
        }
        if (dataInfo.note7) {
          sheet.range(dataInfo.note7).merged(true).style({
          border: "thin",
          });;
        }
        if (dataInfo.note8) {
          sheet.range(dataInfo.note8).merged(true).style({
            border: "thin",
          });;
        }
        if (dataInfo.note9) {
          sheet.range(dataInfo.note9).merged(true).style({
            border: "thin",
          });;
        }

        if (dataInfo.theadBider) {
          sheet.range(dataInfo.theadBider).merged(true).style({
            fill: "d9d9d9",
            fontSize: 12,
            bold: true,
            horizontalAlignment: "left",
            verticalAlignment: "center",
            fontColor: "000000",
            border: "thin",
          });
        }

        if (dataInfo.tFirstColumnRange) {
          sheet.range(dataInfo.tFirstColumnRange).style({
            bold: true,
            border: "thin",
            
          });
        }

        if (dataInfo.tLastColumnRange) {
          sheet.range(dataInfo.tLastColumnRange).style({
            bold: true,
            border: "thin",
          });
        }
        if (dataInfo.tFirstColumnRange1) {
          sheet.range(dataInfo.tFirstColumnRange1).style({
            horizontalAlignment: "left",
            border: "thin",
          });
        }

        if (dataInfo.tLastColumnRange1) {
          sheet.range(dataInfo.tLastColumnRange1).style({
            border: "thin",
          });
        }
      });
      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  const handleDownload = (id) => {
    GetRfq(id)
      .then((res) => {
        const result2 = {
          ProjectName: res.data.data.budget.project?.name,
          Package: res.data.data?.rfq_package,
        };
        const result = res.data.data.budget_item.map((elm) => ({
          Control_Number: elm.budgetitem?.control_number,
          Items: elm.budgetitem.itemcategory?.name,
          Specifications:
            elm.budgetitem.budget_modeldetails?.notes === null
              ? "N/A"
              : elm.budgetitem.budget_modeldetails?.notes,
          Qty: elm.budgetitem.budgetquantitydetails?.budget_quantity,
          UOM: elm.budgetitem.budgetquantitydetails?.budgetuom?.name,
          // image: "N/A",
        }));
        createDownLoadData(result, result2);
      })
      .catch(() => {});
  };
  const handleClose = () => {
    setIsRevisedOpen(false);
  };
  const handleRFQClose = () => {
    setIsRFQOpen(false);
  };

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {Furnish} | {RFQLabel}{" "}
        </title>
      </Helmet>
      <div className="content-wrapper p-0 ">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <ReviseModal
          size="lg"
          show={isRFQOpen}
          onHide={handleRFQClose}
          animation={false}
          centered
        >
          <ReviseModal.Header closeButton>
            <ReviseModal.Title>RFQ Details</ReviseModal.Title>
          </ReviseModal.Header>
          <ReviseModal.Body>
            <Container>
              <Row
                style={{
                  color: "black",
                  marginBottom: "5px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                <Col>Serial No.</Col>
                <Col>Name</Col>
                <Col>Deadline</Col>
                <Col>Query Deadline</Col>
                <Col>Incoterm</Col>
              </Row>
              <hr />
              <Row
                style={{
                  textAlign: "center",
                }}
              >
                <Col>{RFQDatas.serial_no}</Col>
                <Col>{RFQDatas.rfqName}</Col>
                <Col>{RFQDatas.deadline}</Col>
                <Col>{RFQDatas.queryDeadline}</Col>
                <Col>{RFQDatas.incoterm}</Col>
              </Row>
            </Container>
          </ReviseModal.Body>
          <ReviseModal.Footer>
            <Button variant="secondary" onClick={handleRFQClose}>
              Close
            </Button>
          </ReviseModal.Footer>
        </ReviseModal>
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <ReviseModal
          size="lg"
          show={isRevisedOpen}
          onHide={handleClose}
          animation={false}
          centered
        >
          <ReviseModal.Header closeButton>
            <ReviseModal.Title>RFQ Revision History</ReviseModal.Title>
          </ReviseModal.Header>
          <ReviseModal.Body>
            <Container>
              <Row
                style={{
                  color: "black",
                  marginBottom: "5px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                <Col>Serial No.</Col>
                <Col>Items</Col>
                <Col>Vendors</Col>
                <Col>Query Deadline</Col>
                <Col>Incoterm</Col>
              </Row>
              <hr />
              {revisedList.map((ele) => {
                return (
                  <Row
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Col>{ele.serial_no}</Col>
                    <Col>
                      {ele.items.map((ele) => {
                        return `${ele} `;
                      })}
                    </Col>
                    <Col>
                      {ele.vendors.map((ele) => {
                        return `${ele} `;
                      })}
                    </Col>
                    <Col>{ele.queryDeadline}</Col>
                    <Col>{ele.incoterm}</Col>
                  </Row>
                );
              })}
            </Container>
          </ReviseModal.Body>
          <ReviseModal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </ReviseModal.Footer>
        </ReviseModal>
        <div
          className="row mt-4"
          style={{
            alignItems: "center",
          }}
        >
          <div className="mb-2">
            <h3> {"Request For Quotation"} </h3>
            <BreadCrumbs
              pathList={paths}
              obj={{
                proId: proId,
                budget_id: budget_id,
                budget_name: budget_Name,
                scope_id: scope_id
              }}
            />
            {alertBox && (
              <AlertMessage
                title={AlertTitleLabel}
                message={AlertMsgLabel}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
          </div>
        </div>
        {openDownload ? <QuotationDownload data={downloadData} /> : null}
        <div className="RfqbudgetTable mt-4">
          <RFQTableDocument
            isUpdate={isUpdate}
            isStatus={isStatus}
            proId={proId}
            budget_id={budget_id}
            data={rfqData}
            handleView={handleView}
            mapableData={mapableDataRFQ}
            searchProp={["rfqName"]}
            handleChangeDelete={handleChangeDelete}
            handleChangeEdit={handleChangeEditRFQ}
            handleVisible={handleVisible}
            handleDownload={handleDownload}
            handleShowReviseData={handleShowReviseData}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={CustomTablePaginationActions}
          />
        </div>
      </div>
    </>
  );
};

export default SearchDocument;

// fill: "2403fc",
