import React from "react";
import { FormCheckBox, FormTextInput, Button } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const DeleteMappingRoleFunctionForm = (props) => {
  const { deleteChangeFormData, initialValue = {}, actionType } = props;

  const deleteFunctionTitle = Strings["FunctionManagement.deleteFunctionTitle"],
    viewUserRoleMappingTitle = Strings["FunctionManagement.ViewFunctionTitle"],
    deleteFunctionNameLabel =
      Strings["FunctionManagement.deleteFunctionNameLabel"],
    deleteFunctionNamePlaceholder =
      Strings["FunctionManagement.deleteFunctionNamePlaceholder"],
    deleteFunctionNameCustomError =
      Strings["FunctionManagement.deleteFunctionNameCustomError"],
    deleteFunctionDescriptionLabel =
      Strings["FunctionManagement.deleteFunctionDescriptionLabel"],
    deleteFunctionDescriptionPlaceholder =
      Strings["FunctionManagement.deleteFunctionDescriptionPlaceholder"],
    deleteFunctionDescriptionCustomError =
      Strings["FunctionManagement.deleteFunctionDescriptionCustomError"],
    deleteFunctionDescriptionActiveStatusLabel =
      Strings["FunctionManagement.deleteFunctionDescriptionActiveStatusLabel"]

  const getAllFunctions = (subCat) => {
    let str = "";
    subCat.forEach((i, index) => {
      if (index === 0) {
        str = i.functionName;
      } else {
        str = str + " , " + i.functionName
      }

    });
    return str;
  };

  const [roleName, setRoleName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [functionName, setfunctionName] = React.useState({
    value: initialValue.functions
      ? getAllFunctions(initialValue.functions)
      : "",
    error: false,
    success: false,
  });

  const [activeStatus, setActiveStatus] = React.useState(
    initialValue.activeStatus
  );

  const handleChangeCheck = () => {
    setActiveStatus(!activeStatus);
  };

  const handleChangeFunctionName = (value) => {
    setfunctionName(value);
  };

  const handleChangeRoleName = (value) => {
    setRoleName(value);
  };


  const handleSaveData = () => {
    const formData = {
      functionName: functionName.value,
      roleName: roleName.value,
    };
    deleteChangeFormData(formData);
  };

  let disable =
    functionName.error ||
    roleName.error ||
    functionName.value === "" ||
    roleName.value === "" ||
    functionName.success === false ||
    roleName.success === false;

  return (
    <div className="modalFormContainer">
      <h2 className="mb-3">

        {actionType !== "delete"
          ? viewUserRoleMappingTitle
          : deleteFunctionTitle}
      </h2>

      <FormTextInput
        type="text"
        label={deleteFunctionNameLabel}
        placeholder={deleteFunctionNamePlaceholder}
        Value={roleName.value}
        onChangeText={handleChangeRoleName}
        Validate={true}
        CustomErrorLine={deleteFunctionNameCustomError}
        editable={false}
      />

      <FormTextInput
        type="text"
        label={deleteFunctionDescriptionLabel}
        placeholder={deleteFunctionDescriptionPlaceholder}
        Value={functionName.value}
        onChangeText={handleChangeFunctionName}
        Validate={true}
        CustomErrorLine={deleteFunctionDescriptionCustomError}
        editable={false}
        multiline={true}
      />

      <FormCheckBox
        Label={deleteFunctionDescriptionActiveStatusLabel}
        checked={activeStatus}
        handleChangeCheck={handleChangeCheck}
        disable={true}
      />

      {actionType === "delete" ? (
        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <Button
            buttonName="Delete"
            buttonStyle={{
              marginRight: "10px",
              backgroundColor: colors.danger,
            }}
            disable={disable}
            onClick={handleSaveData}
          />
          <Button buttonName="Cancel" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DeleteMappingRoleFunctionForm;
