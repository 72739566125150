/* eslint-disable no-unused-vars */
import React from "react";
import { GetActiveRequiredSubmittals } from "../../../axios";
import {
  Button,
  FilePicker,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";

const CreateItemSubmittalForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleSubmittalData = () => {},
    handleModalClose,
    initialValue = {},
    actionType,
  } = props;
  const [loader, setLoader] = React.useState(false);

  const [submittalType, setsubmittalType] = React.useState({
    value: initialValue.RequiredSubmittals
      ? initialValue.RequiredSubmittals.id
      : "",
    error: false,
    success: false,
  });

  const [typeOption, setTypeOption] = React.useState([]);

  const [description, setdescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  //   var fileList = [];
  //   if (initialValue.files) {
  //     initialValue.files.map((ele) => {
  //       fileList.push({
  //         value: Object.values(ele)[2],
  //       });
  //     });
  //   }

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.files ? initialValue.upload : "",
    error: false,
    success: false,
  });

  const handleSaveData = () => {
    // let file = selectedFile.value[0].name;
    // let path = [];
    // let data = path.push(file);
    const itemSubmittalData = {
      submital_id: submittalType.value,
      description: description.value,
      filepath: [selectedFile.value[0].name],
      // ["/uploads/files/evAE4VYfGTs.pdf"],
    };
    handleSubmittalData(itemSubmittalData);
  };

  const handleChangesubmittalType = (value) => {
    setMessage(null);
    setsubmittalType(value);
  };
  const handleChangedescription = (value) => {
    setdescription(value);
  };
  const handleChangeFile = (data) => {
    setSelectedFile(data);
  };

  const getRequiredSubmittals = () => {
    setLoader(true);
    GetActiveRequiredSubmittals()
      .then((res) => {
        setLoader(false);
        const result = res.data.data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setTypeOption(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  React.useEffect(() => {
    getRequiredSubmittals();
  }, []);

  let isValueNOTChanged =
    submittalType.value === initialValue.submittalType &&
    // description.value === initialValue.description &&
    selectedFile.value === initialValue.selectedFile;
  let disable =
    submittalType.error ||
    // description.error ||
    selectedFile.error ||
    (submittalType.value === "" &&
      // description.value === "" ||
      selectedFile.value === null) ||
    (submittalType.success === false &&
      // description.success === false &&
      selectedFile.success === false) ||
    isValueNOTChanged;

  const createitemSubmittalHeader =
      strings["ItemSubmittal.createitemSubmittalHeader"],
    edititemSubmittalHeader = strings["ItemSubmittal.edititemSubmittalHeader"],
    submittalTypeLabel = strings["ItemSubmittal.submittalTypeLabel"],
    submittalTypeErrorLine = strings["ItemSubmittal.submittalTypeErrorLine"],
    submittalTypeSelectText = strings["ItemSubmittal.submittalTypeSelectText"],
    descriptionLabel = strings["ItemSubmittal.descriptionLabel"],
    descriptionPlaceholder = strings["ItemSubmittal.descriptionPlaceholder"],
    // descriptionCustomError = strings["ItemSubmittal.descriptionCustomError"],
    saveBtn = strings["ItemSubmittal.saveBtn"],
    updateBtn = strings["ItemSubmittal.updateBtn"],
    cancelBtn = strings["ItemSubmittal.cancelBtn"];
  return (
    <div className="modalFormContainer">
      {loader ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>
        {actionType === "add"
          ? createitemSubmittalHeader
          : edititemSubmittalHeader}
      </h2>
      <div className="row">
        {/* <div className="col-12 col-md-6 pe-0 pe-md-3"> */}
        <FormDropDown
          Label={submittalTypeLabel}
          Options={typeOption}
          value={submittalType.value}
          onSelect={handleChangesubmittalType}
          customErrorLine={submittalTypeErrorLine}
          selectText={submittalTypeSelectText}
          searchable={false}
        />
        <FormTextInput
          type="text"
          label={descriptionLabel}
          placeholder={descriptionPlaceholder}
          Value={description.value}
          onChangeText={handleChangedescription}
          // Validate={true}
          // CustomErrorLine={descriptionCustomError}
          multiline={true}
        />
        {/* </div> */}
        {/* <div className="col-12 col-md-6 pe-0 pe-md-3"> */}
        <FilePicker
          onChange={handleChangeFile}
          isMulti={true}
          type="file"
          value={selectedFile.value}
        />
        {/* </div> */}
      </div>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default CreateItemSubmittalForm;

//////////////////////////////////////////

// import React from "react";
// import {
//   FormDropDown,
//   FormTextInput,
//   Button,
//   FilePicker,
// } from "../../../components";
// import { colors } from "../../../configs";
// import strings from "../../../translations";

// const CreateItemSubmittalForm = (props) => {
//   const {
//     handleSubmittalData = () => {},
//     initialValue = {},
//     actionType,
//     handleModalClose,
//   } = props;
//   const [submittalType, setsubmittalType] = React.useState({
//     value: initialValue.submittalType ? initialValue.submittalType : "",
//     error: false,
//     success: false,
//   });

//   const [description, setdescription] = React.useState({
//     value: initialValue.description ? initialValue.description : "",
//     error: false,
//     success: false,
//   });

//   const [selectedFile, setSelectedFile] = React.useState({
//     value: initialValue.upload ? initialValue.upload : null,
//     error: false,
//     success: false,
//   });

//   const typeOption = [
//     {
//       key: 1,
//       value: "Submittal Type 1",
//       label: "Submittal Type 1",
//     },
//     {
//       key: 2,
//       value: "Submittal Type 2",
//       label: "Submittal Type 2",
//     },
//     {
//       key: 3,
//       value: "Submittal Type 3",
//       label: "Submittal Type 3",
//     },
//     {
//       key: 4,
//       value: "Submittal Type 4",
//       label: "Submittal Type 4",
//     },
//   ];

//   const handleSaveData = () => {
//     const itemSubmittalData = {
//       submittalType: submittalType.value,
//       description: description.value,
//     };
//     handleSubmittalData(itemSubmittalData);
//   };

//   const handleChangesubmittalType = (value) => {
//     setsubmittalType(value);
//   };
//   const handleChangedescription = (value) => {
//     setdescription(value);
//   };
//   const handleChangeFile = (data) => {
//     setSelectedFile(data);
//   };

//   let isValueNOTChanged =
//     submittalType.value === initialValue.submittalType &&
//     description.value === initialValue.description &&
//     selectedFile.value === initialValue.selectedFile;
//   let disable =
//     submittalType.error ||
//     description.error ||
//     selectedFile.error ||
//     submittalType.value === "" ||
//     description.value === "" ||
//     selectedFile.value === null ||
//     (submittalType.success === false &&
//       description.success === false &&
//       selectedFile.success === false) ||
//     isValueNOTChanged;

//   const createitemSubmittalHeader =
//       strings["ItemSubmittal.createitemSubmittalHeader"],
//     edititemSubmittalHeader = strings["ItemSubmittal.edititemSubmittalHeader"],
//     submittalTypeLabel = strings["ItemSubmittal.submittalTypeLabel"],
//     submittalTypeErrorLine = strings["ItemSubmittal.submittalTypeErrorLine"],
//     submittalTypeSelectText = strings["ItemSubmittal.submittalTypeSelectText"],
//     descriptionLabel = strings["ItemSubmittal.descriptionLabel"],
//     descriptionPlaceholder = strings["ItemSubmittal.descriptionPlaceholder"],
//     descriptionCustomError = strings["ItemSubmittal.descriptionCustomError"],
//     saveBtn = strings["ItemSubmittal.saveBtn"],
//     updateBtn = strings["ItemSubmittal.updateBtn"],
//     cancelBtn = strings["ItemSubmittal.cancelBtn"];
//   return (
//     <div className="modalFormContainer">
//       <h2>
//         {actionType === "add"
//           ? createitemSubmittalHeader
//           : edititemSubmittalHeader}
//       </h2>
//       <div className="row">
//         <div className="col-12 col-md-6 pe-0 pe-md-3">
//           <FormDropDown
//             Label={submittalTypeLabel}
//             Options={typeOption}
//             value={submittalType.value}
//             onSelect={handleChangesubmittalType}
//             customErrorLine={submittalTypeErrorLine}
//             selectText={submittalTypeSelectText}
//             searchable={false}
//           />
//           <FormTextInput
//             type="text"
//             label={descriptionLabel}
//             placeholder={descriptionPlaceholder}
//             Value={description.value}
//             onChangeText={handleChangedescription}
//             Validate={true}
//             CustomErrorLine={descriptionCustomError}
//             multiline={true}
//           />
//         </div>
//         <div className="col-12 col-md-6 pe-0 pe-md-3">
//           <FilePicker onChange={handleChangeFile} isMulti={true} type="image" />
//         </div>
//       </div>
//       <div style={{ display: "flex", marginTop: "1.5rem" }}>
//         <Button
//           buttonName={actionType === "add" ? saveBtn : updateBtn}
//           buttonStyle={{ backgroundColor: colors.primary }}
//           disable={disable}
//           onClick={handleSaveData}
//         />
//         <Button
//           buttonName={cancelBtn}
//           buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
//           onClick={() => {
//             handleModalClose();
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default CreateItemSubmittalForm;
