/* eslint-disable no-unused-vars */
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { logoRect } from "../../../assets";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    color: "black",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textHeadSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  image: {
    width: "200px",
    height: "50px",
  },
  viewHead: {
    marginLeft: "15px",
    marginRight: "15px",
    borderBottom: "1px solid black",
  },
  textHead: {
    fontSize: "16px",
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const ReportDownload = (props) => {
  const {
    isChecked,
    formData,
    data,
    deliveryDate,
    budPrice,
    costFrieght,
    costDuties,
    totalValue,
    vendor,
  } = props;

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.section}>
              <Image style={styles.image} src={logoRect} />
              <Text style={{ fontSize: "30px", alignItems: "center" }}>
                Recommendation
              </Text>
            </View>
            <View style={styles.viewHead}>
              <View style={styles.textHeadSection}>
                <Text>Project: </Text>
                <Text style={{ fontSize: "13px", color: "rgb(64, 64, 64)" }}>
                  {data.length !== 0 ? data[0].project.project.name : "NA"}
                </Text>
              </View>
              <View style={styles.textHeadSection}>
                <Text>Package Title: </Text>
                <Text style={{ fontSize: "13px", color: "rgb(64, 64, 64)" }}>
                  {data.length !== 0
                    ? data[0].requestQuotation.rfq_package
                    : "NA"}
                </Text>
              </View>
              <View style={styles.textHeadSection}>
                <Text>Package #: </Text>
                <Text style={{ fontSize: "13px", color: "rgb(64, 64, 64)" }}>
                  {data.length !== 0
                    ? data[0].requestQuotation.rfq_reference_field
                    : "NA"}
                </Text>
              </View>
              <View style={styles.textHeadSection}>
                <Text>Date: </Text>
                <Text style={{ fontSize: "13px", color: "rgb(64, 64, 64)" }}>
                  {moment(deliveryDate).format("Do MMM YYYY")}
                </Text>
              </View>
            </View>

            <View
              style={{
                marginTop: "10px",
                marginLeft: "15px",
                marginRight: "15px",
                marginBottom: "15px",
              }}
            >
              <Text style={{ fontSize: "12px" }}>
                We submit herewith out Tender Report and Recommendation in
                respect of the above Package.
              </Text>
            </View>

            <View>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginLeft: "15px",
                  marginRight: "15px",
                  border: "1px solid black",
                  marginTop: "10px",
                  borderRadius: "5px",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: "13px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Text
                    style={{
                      width: "60%",
                      fontSize: "11px",
                      borderRight: "1px solid black",
                      padding: "5px",
                      backgroundColor: "rgb(188, 204, 204)",
                    }}
                  >
                    BUDGET RECONCILIATION
                  </Text>
                  <Text
                    style={{
                      width: "40%",
                      fontSize: "11px",
                      padding: "5px",
                      backgroundColor: "rgb(188, 204, 204)",
                    }}
                  >
                    AED
                  </Text>
                </View>
                <View
                  style={{
                    borderBottom: "1px solid black",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      width: "60%",
                      fontSize: "10px",
                      borderRight: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    Budget Allowance -
                    <Text
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      {data.length !== 0 ? data[0].itemCate : "NA"}
                    </Text>
                  </Text>
                  <CurrencyFormat
                    value={data.length !== 0 ? budPrice : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => (
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {value}
                      </Text>
                    )}
                  />
                  {/* <Text
                    style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                  >
                    {data.length !== 0 ? budPrice : 0}
                  </Text> */}
                </View>
                <View
                  style={{
                    borderBottom: "1px solid black",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      width: "60%",
                      fontSize: "10px",
                      borderRight: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    Budget Allowance -
                    <Text
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      Freight
                    </Text>
                  </Text>
                  <Text
                    style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                  >
                    0
                  </Text>
                </View>
                <View
                  style={{
                    borderBottom: "1px solid black",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      width: "60%",
                      fontSize: "10px",
                      borderRight: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    Budget Allowance -
                    <Text
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      Other (Delivery & Packing Charges)
                    </Text>
                  </Text>
                  <Text
                    style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                  >
                    {" "}
                    0
                  </Text>
                </View>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                    style={{
                      width: "60%",
                      fontSize: "10px",
                      borderRight: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    Available Budget
                  </Text>
                  <CurrencyFormat
                    value={data.length !== 0 ? budPrice : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => (
                      <Text
                        style={{
                          width: "40%",
                          fontSize: "11px",
                          padding: "5px",
                          fontWeight: "600",
                        }}
                      >
                        {value}
                      </Text>
                    )}
                  />
                  {/* <Text
                    style={{
                      width: "40%",
                      fontSize: "11px",
                      padding: "5px",
                      fontWeight: "600",
                    }}
                  >
                    {data.length !== 0 ? budPrice : 0}
                  </Text> */}
                </View>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "15px",
                marginRight: "15px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "11px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  COST SUMMARY
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "11px",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  {" "}
                  AED
                </Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Cost -
                  <Text
                    style={{
                      fontSize: "9px",
                    }}
                  >
                    {data.length !== 0 ? data[0].itemCate : "NA"}
                  </Text>
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                >
                  0
                </Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "11px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Cost -
                  <Text
                    style={{
                      fontSize: "9px",
                    }}
                  >
                    Freight
                  </Text>
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                >
                  {costFrieght}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Cost -
                  <Text
                    style={{
                      fontSize: "9px",
                    }}
                  >
                    Duties
                  </Text>
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                >
                  {costDuties}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Cost -
                  <Text
                    style={{
                      fontSize: "9px",
                    }}
                  >
                    Other (Delivery & Packing Charges)
                  </Text>
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                >
                  0
                </Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Recommended Contract Award Figure
                </Text>
                <CurrencyFormat
                  value={costFrieght + costDuties + totalValue}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                    <Text
                      style={{
                        width: "40%",
                        fontSize: "10px",
                        padding: "5px",
                        fontWeight: "600",
                      }}
                    >
                      {value}
                    </Text>
                  )}
                />
                {/* <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    fontWeight: "600",
                  }}
                >
                  {costFrieght + costDuties + totalValue}
                </Text> */}
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {data.length !== 0 ? (
                  budPrice - (costFrieght + costDuties + totalValue) ? (
                    budPrice - (costFrieght + costDuties + totalValue) > 0 ? (
                      <>
                        <Text
                          style={{
                            width: "60%",
                            fontSize: "11px",
                            padding: "5px",
                            borderRight: "1px solid black",
                            fontWeight: "600",
                          }}
                        >
                          Under the budget
                        </Text>
                        <CurrencyFormat
                          value={
                            data.length !== 0
                              ? budPrice -
                                (costFrieght + costDuties + totalValue)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <Text
                              style={{
                                width: "40%",
                                fontSize: "10px",
                                backgroundColor: "green",
                                padding: "5px",
                              }}
                            >
                              AED {value}
                            </Text>
                          )}
                        />
                        {/* <Text
                          style={{
                            width: "40%",
                            fontSize: "10px",
                            backgroundColor: "green",
                            padding: "5px",
                          }}
                        >
                          AED{" "}
                          {data.length !== 0
                            ? budPrice - (costFrieght + costDuties + totalValue)
                            : "NA"}
                        </Text> */}
                      </>
                    ) : (
                      <>
                        <Text
                          style={{
                            width: "60%",
                            fontSize: "11px",
                            padding: "5px",
                            borderRight: "1px solid black",
                            fontWeight: "600",
                          }}
                        >
                          Above the budget
                        </Text>
                        <CurrencyFormat
                          value={
                            data.length !== 0
                              ? budPrice -
                                (costFrieght + costDuties + totalValue)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <Text
                              style={{
                                width: "40%",
                                backgroundColor: "red",
                                fontSize: "10px",
                                padding: "5px",
                              }}
                            >
                              AED {value}
                            </Text>
                          )}
                        />
                        {/* <Text
                          style={{
                            width: "40%",
                            backgroundColor: "red",
                            fontSize: "10px",
                            padding: "5px",
                          }}
                        >
                          AED{" "}
                          {data.length !== 0
                            ? budPrice - (costFrieght + costDuties + totalValue)
                            : "NA"}
                        </Text> */}
                      </>
                    )
                  ) : null
                ) : null}
              </View>
            </View>
          </View>
          <View>
            <Text
              style={{
                fontSize: "10px",
                marginLeft: "15px",
                marginRight: "15px",
                marginTop: "10px",
              }}
            >
              We hereby recommend that the award of the Contract is made to
              <Text style={{ fontSize: "12px", fontWeight: "extrabold" }}>
                {" "}
                {data.length !== 0 ? data[0].project.name : "NA"}
              </Text>{" "}
              in the sum of
              <Text style={{ fontSize: "11px", fontWeight: "extrabold" }}>
                {" "}
                AED{" "}
                {data.length !== 0
                  ? budPrice - (costFrieght + costDuties + totalValue)
                  : "NA"}
              </Text>{" "}
              for
              {/*  the supply and installation of Housekeeping Machines for */}
              <Text style={{ fontSize: "11px", fontWeight: "extrabold" }}>
                {" "}
                {data.length !== 0 ? data[0].project.name : "NA"}
              </Text>{" "}
              as per the quantities received from Client/Operator.
            </Text>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: "15px",
                marginRight: "15px",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "11px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Lead time:
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "11px", padding: "5px" }}
                ></Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "11px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Payment Terms :
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "11px", padding: "5px" }}
                ></Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "11px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Incoterms :
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "11px", padding: "5px" }}
                ></Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "11px",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Delivery Date :
                </Text>
                <Text
                  style={{ width: "40%", fontSize: "12px", padding: "5px" }}
                ></Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "11px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: "11px",
                  textDecoration: "underline",
                  fontWeight: "900",
                }}
              >
                REASON FOR RECOMMENDATION :
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  height: "100px",
                  fontSize: "10px",
                  padding: "5px",
                }}
              >
                {formData.reason_recommendation}
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                Assessed and Considered
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Vendor Pre-Qualification
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Scheduling and Timeline requirements
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Scope Definition
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Terms & Conditions
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Compliance and Completeness of the Tender
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "12px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                Compiled by FURNISH:
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "11px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "11px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "11px",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "9px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  {formData.compiled_name}
                </Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {formData.compiled_sign}
                </Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                  }}
                >
                  {formData.compiled_date}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                Checked by FURNISH:
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "9px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  {formData.checked_name}
                </Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {formData.checked_sign}
                </Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                  }}
                >
                  {formData.checked_date}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                Enclosed :
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Vendors' Quotation/s
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Cost Comparison
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "13px",
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Tender Package Acceptance Form/s
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
                <Text
                  style={{ width: "20%", fontSize: "10px", padding: "5px" }}
                >
                  Ok
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                WASL Response :
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "110x",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "70%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Please proceed to issue the Purchase Order for Wasl signature:
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "70%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Please reconsider your recommendation and re-submit:
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                }}
              >
                <Text
                  style={{
                    width: "70%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  Do not proceed further with this recommendation:
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  Ok
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                Comments :
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  height: "100px",
                  fontSize: "10px",
                  padding: "5px",
                }}
              >
                {formData.comments}
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                Client :
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "13px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  {formData.client_1_name}
                </Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {formData.client_1_sign}
                </Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                  }}
                >
                  {moment(formData.client_1_date).format("Do MMM YYYY")}
                  {/* {formData.client_1_date} */}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "13px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "13px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  {formData.client_2_name}
                </Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {formData.client_2_name}
                </Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "9px",
                    padding: "5px",
                  }}
                >
                  {formData.client_2_name}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                >
                  {formData.client_3_name}
                </Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {formData.client_3_name}
                </Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                >
                  {formData.client_3_name}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Signature
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: "10px",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Date
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    borderRight: "1px solid black",
                    padding: "5px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "40%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "30%",
                    height: "50px",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                ></Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "25px",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px", textDecoration: "underline" }}>
                Distribution :
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                border: "1px solid black",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <Text
                style={{
                  width: "30%",
                  height: "50px",
                  fontSize: "10px",
                  padding: "5px",
                }}
              >
                {formData.distribution}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default ReportDownload;
