/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const VendorSubCategoryForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createSubCategoryTitle =
      Strings["VendorSubCategory.CreateSubCategoryForm"],
    editSubCategoryTitle = Strings["VendorSubCategory.EditSubCategoryForm"],
    subCategoryLabel = Strings["VendorSubCategory.SubCategoryLabel"],
    subCategoryPlaceholder =
      Strings["VendorSubCategory.SubCategoryPlaceHolder"],
    subCategoryCustomError = Strings["VendorSubCategory.SubCategoryError"],
    subCategoryDescriptionLabel =
      Strings["VendorSubCategory.SubCategoryDescriptionLabel"],
    subCategoryDescriptionPlaceholder =
      Strings["VendorSubCategory.SubCategoryDescriptionPlaceHolder"],
    subCategoryDescriptionCustomError =
      Strings["VendorSubCategory.SubCategoryDescriptionError"],
    subCategoryCodeLabel = Strings["VendorSubCategory.SubCategoryCodeLabel"],
    subCategoryCodePlaceholder =
      Strings["VendorSubCategory.SubCategoryCodePlaceHolder"],
    subCategoryCodeCustomError =
      Strings["VendorSubCategory.SubCategoryCodeError"],
    subCategorySaveBtn = Strings["VendorSubCategory.SaveBtn"],
    subCategoryUpdateBtn = Strings["VendorSubCategory.UpdateBtn"],
    subCategoryCancelBtn = Strings["VendorSubCategory.CancelBtn"];

  const [vendorSubCategory, setVendorSubCategory] = React.useState({
    value: initialValue.sub_category ? initialValue.sub_category : "",
    error: false,
    success: false,
  });

  const [vendorSubCategoryDescription, setVendorSubCategoryDescription] =
    React.useState({
      value: initialValue.description ? initialValue.description : "",
      error: false,
      success: false,
    });
  const [subCategoryCode, setSubCategoryCompanyCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    success: false,
  });

  const handleSubCategory = (value) => {
    setMessage(null);
    setVendorSubCategory(value);
  };

  const handleChangeSubCategoryDescription = (value) => {
    setVendorSubCategoryDescription(value);
  };

  const handleSubCategoryCode = (value) => {
    setMessage(null);
    setSubCategoryCompanyCode(value);
  };

  const handleSaveData = () => {
    const formData = {
      sub_category: vendorSubCategory.value,
      description: vendorSubCategoryDescription.value,
      code: subCategoryCode.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged =
    vendorSubCategory.value === initialValue.name &&
    subCategoryCode.value === initialValue.code;

  let disable =
    vendorSubCategory.error ||
    subCategoryCode.error ||
    vendorSubCategory.value === "" ||
    subCategoryCode.value === "" ||
    (vendorSubCategory.success === false &&
      subCategoryCode.success === false) ||
    isValueNOTChanged;

  let isValueNOTChangededit =
    vendorSubCategory.value === initialValue.vendorSubCategory &&
    // vendorSubCategoryDescription.value ===
    //   initialValue.vendorSubCategoryDescription &&
    subCategoryCode.value === initialValue.subCategoryCode;

  // let Editdisable =
  //   vendorSubCategory.error ||
  //   subCategoryCode.error ||
  //   vendorSubCategoryDescription.error ||
  //   vendorSubCategory.value === "" ||
  //   vendorSubCategoryDescription.value === "" ||
  //   subCategoryCode.value === "" ||
  //   (vendorSubCategory.success === false &&
  //     vendorSubCategoryDescription.success === false &&
  //     subCategoryCode.success === false) ||
  //   isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add" ? createSubCategoryTitle : editSubCategoryTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="text"
        label={subCategoryLabel}
        placeholder={subCategoryPlaceholder}
        Value={vendorSubCategory.value}
        onChangeText={handleSubCategory}
        Validate={true}
        CustomErrorLine={subCategoryCustomError}
        editable
      />

      <FormTextInput
        type="textarea"
        label={subCategoryDescriptionLabel}
        placeholder={subCategoryDescriptionPlaceholder}
        Value={vendorSubCategoryDescription.value}
        onChangeText={handleChangeSubCategoryDescription}
        Validate={false}
        CustomErrorLine={subCategoryDescriptionCustomError}
        multiline={true}
        editable
      />

      <FormTextInput
        starProps={true}
        type="alpha"
        label={subCategoryCodeLabel}
        placeholder={subCategoryCodePlaceholder}
        Value={subCategoryCode.value}
        onChangeText={handleSubCategoryCode}
        Validate={true}
        CustomErrorLine={subCategoryCodeCustomError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? subCategorySaveBtn : subCategoryUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={subCategoryCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
          }}
        />
      </div>
    </div>
  );
};

export default VendorSubCategoryForm;
