import React, { useState } from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const EditProfileForm = (props) => {
  const {
    initialValue,
    setValue = () => { },
    handlemodalclose,
  } = props;

  const EditProfileTitle = Strings["Authentication.EditProfileTitle"],
    currPasswordLabel = Strings["Authentication.currPasswordLabel"],
    currPasswordPlaceholder = Strings["Authentication.currPasswordPlaceholder"],
    newPasswordLabel = Strings["Authentication.newPasswordLabel"],
    newPasswordPlaceholder = Strings["Authentication.newPasswordPlaceholder"],
    reTypePasswordLabel = Strings["Authentication.reTypePasswordLabel"],
    reTypePasswordPlaceholder =
      Strings["Authentication.reTypePasswordPlaceholder"],
    passwordHint = Strings["Authentication.loginPasswordHint"];

  const [currentPassword, setcurrentPassword] = React.useState({
    value: initialValue.cpassword ? initialValue.cpassword : "",
    error: false,
    success: false,
  });
  const [newPassword, setnewPassword] = React.useState({
    value: initialValue.npassword ? initialValue.npassword : "",
    error: false,
    success: false,
  });
  const [reTypePassword, setreTypePassword] = React.useState({
    value: initialValue.rpassword ? initialValue.rpassword : "",
    error: false,
    success: false,
  });

  const handleChangeCurrentPassword = (value) => {
    setcurrentPassword(value);
  };
  const handleChangeNewPassword = (value) => {
    setnewPassword(value);
  };
  const handleChangeReTypePassword = (value) => {
    setreTypePassword(value);
  };
  const [ErrorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    const newPass = newPassword.value;
    const retypePass = reTypePassword.value;
    const currentPass = currentPassword.value;
    const user = JSON.parse(localStorage.getItem("user"));

    if (!(newPass === retypePass)) {
      setErrorMessage("New and Re-Type password does not match");
    } else if (!(currentPass === user.password)) {
      setErrorMessage("Password is not Correct");
    } else {
      const data = {
        oldpassword: currentPassword.value,
        newpassword: newPassword.value,
      };
      setErrorMessage("");
      setValue(data);
      handlemodalclose();
    }
  };
  let disable =
    currentPassword.value === "" ||
    newPassword.value === "" ||
    reTypePassword.value === "";

    const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <div className="modalFormContainer">
      <h2>{EditProfileTitle}</h2>

      {ErrorMessage && <h6 style={{ color: "red" }}>{ErrorMessage}</h6>}
      <FormTextInput
        type="password"
        label={currPasswordLabel}
        placeholder={currPasswordPlaceholder}
        Value={currentPassword.value}
        onChangeText={handleChangeCurrentPassword}
        Validate={true}
        passwordVisibleIcon={true}
        editable={true}
        onCopy={handleChange}
        onPaste={handleChange}
      />
      <FormTextInput
        type="password"
        label={newPasswordLabel}
        placeholder={newPasswordPlaceholder}
        Value={newPassword.value}
        onChangeText={handleChangeNewPassword}
        Validate={true}
        passwordVisibleIcon={true}
        editable={true}
        Hint={passwordHint}
        onCopy={handleChange}
        onPaste={handleChange}
      />
      <FormTextInput
        type="password"
        label={reTypePasswordLabel}
        placeholder={reTypePasswordPlaceholder}
        Value={reTypePassword.value}
        onChangeText={handleChangeReTypePassword}
        Validate={true}
        passwordVisibleIcon={true}
        editable={true}
        Hint={passwordHint}
        onCopy={handleChange}
        onPaste={handleChange}
      />

      <div className="button-group mt-3">
        <Button
          onClick={handleSubmit}
          buttonName={"submit"}
          disable={disable}
        />
        <Button
          buttonName={"Cancel"}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handlemodalclose();
          }}
        />
      </div>
    </div>
  );
};

export default EditProfileForm;
