import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const CurrencyForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;
  const createTitle = Strings["Currency.CreateForm"],
    editTitle = Strings["Currency.EditForm"],
    CurrencyCodeLabel = Strings["Currency.CurrencyCodeLabel"],
    CurrencyRateLabel = Strings["Currency.CurrencyRateLabel"],
    CurrencyCodePlaceholder = Strings["Currency.CurrencyCodePlaceHolder"],
    CurrencyRatePlaceholder = Strings["Currency.CurrencyRatePlaceHolder"],
    CurrencyCodeCustomError = Strings["Currency.CurrencyCodeError"],
    CurrencyRateCustomError = Strings["Currency.CurrencyRateError"],
    CurrencyNameLabel = Strings["Currency.CurrencyNameLabel"],
    CurrencyNamePlaceholder = Strings["Currency.CurrencyNamePlaceHolder"],
    CurrencyNameCustomError = Strings["Currency.CurrencyNameError"],
    CurrencySaveBtn = Strings["Currency.SaveBtn"],
    CurrencyUpdateBtn = Strings["Currency.UpdateBtn"],
    CurrencyCancelBtn = Strings["Currency.CancelBtn"];
  const [currencyRate, setCurrencyRate] = React.useState({
    value: initialValue.rate ? initialValue.rate : "",
    error: false,
    success: false,
  });

  const [currencyCode, setCurrencyCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    success: false,
  });
  const [currencyName, setCurrencyName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const handleCurrencyName = (value) => {
    setMessage(null);
    setCurrencyName(value);
  };

  const handleChangeCurrencyCOde = (value) => {
    setMessage(null);
    setCurrencyCode(value);
  };
  const handleChangeCurrencyRate = (value) => {
    setMessage(null);
    setCurrencyRate(value);
  };

  const handleSaveData = () => {
    const formData = {
      code: currencyCode.value,
      name: currencyName.value,
      rate: currencyRate.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged =
    currencyCode.value === initialValue.code &&
    currencyRate.value === initialValue.rate &&
    currencyName.value === initialValue.name;

  let disable =
    currencyRate.error ||
    currencyCode.error ||
    currencyName.error ||
    currencyRate.value === "" ||
    currencyCode.value === "" ||
    currencyName.value === "" ||
    (currencyCode.success === false &&
      currencyName.success === false &&
      currencyRate.success === false) ||
    isValueNOTChanged;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}

      <FormTextInput
        starProps={true}
        type="text"
        label={CurrencyNameLabel}
        placeholder={CurrencyNamePlaceholder}
        Value={currencyName.value}
        onChangeText={handleCurrencyName}
        Validate={true}
        CustomErrorLine={CurrencyNameCustomError}
        editable
      />
      <FormTextInput
        starProps={true}
        type="alpha"
        label={CurrencyCodeLabel}
        placeholder={CurrencyCodePlaceholder}
        Value={currencyCode.value}
        onChangeText={handleChangeCurrencyCOde}
        Validate={true}
        CustomErrorLine={CurrencyCodeCustomError}
        editable
      />
      <FormTextInput
        starProps={true}
        type="number"
        label={CurrencyRateLabel}
        placeholder={CurrencyRatePlaceholder}
        Value={currencyRate.value}
        onChangeText={handleChangeCurrencyRate}
        Validate={true}
        CustomErrorLine={CurrencyRateCustomError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? CurrencySaveBtn : CurrencyUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={CurrencyCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default CurrencyForm;
