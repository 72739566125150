const isEncoded = (id) => {
  if (id !== undefined) {
    let originalString = id?.toString();
    const fetchId = Buffer.from(originalString, "utf8");
    return fetchId.toString("base64");
  }
};
export { isEncoded };

const isDecoded = (id) => {
  if (id !== undefined) {
    const base64String = id?.toString("base64");
    const encoded = Buffer.from(base64String, "base64");
    return encoded.toString("utf8");
  }
};
export { isDecoded };
