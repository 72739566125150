import { colors } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { logoRect } from "../../../assets/images";
import { GetCostAnalysisReport } from "../../../axios";
import { BreadCrumbs, DownloadIcon, IconButton } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import ModalPdf from "../../../components/common/ModalPdf";
import { default as strings } from "../../../translations";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import "../SearchCostAnalysis/CostStyle.css";
import ReportDownload from "./ReportDownload";

const ref = React.createRef();

function SearchReportPage() {
  const FurnishTitle = strings["Title.Furnish"],
    CAReportTitle = strings["CostReport"],
    PoBtnName = strings["Purchase.CreateBtn"];
  const location = useLocation();
  const proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;

  const budget_id = location.state
    ? location.state.budget_id
    : location.query
    ? JSON.parse(location.query.params).budget_id
    : null;

  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const rfqId = parseInt(paramId);
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  const encodedRfqId = isEncoded(rfqId);

  const [data, setData] = React.useState([]);
  const [isLoader, setisLoader] = React.useState(false);
  const [costFrieght, setCostFrieght] = React.useState(null);
  const [costDuties, setCostDuties] = React.useState(null);
  const [totalValue, setTotalValues] = React.useState(null);
  const [vendor, setVendor] = React.useState([]);
  const [deliveryDate, setDeliveryDate] = React.useState();
  const [budPrice, setBudPrice] = React.useState(null);
  const [leadTime, setLeadTime] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleChangeDownload = () => {
    openAddModal();
  };

  const selectModal = () => {
    return (
      <ReportDownload
        isChecked={isChecked}
        formData={formData}
        data={data}
        deliveryDate={deliveryDate}
        budPrice={budPrice}
        costFrieght={costFrieght}
        costDuties={costDuties}
        totalValue={totalValue}
        vendor={vendor}
      />
    );
  };

  const history = useHistory();
  const handleChangePo = () => {
    history.push({
      pathname: `/purchaseorders/${encodedRfqId}`,
      state: {
        rfqId: rfqId,
        proId: proId,
        budget_id: budget_id,
      },
    });
  };

  const getCosrReport = () => {
    setisLoader(true);
    GetCostAnalysisReport(rfqId)
      .then((res) => {
        setLeadTime(res?.data?.data[0]?.quotation_item?.quotation?.lead_time);
        const vendorList = [];
        res.data.data.map((elem) => {
          vendorList.push(elem.vendor.name);
        });
        let vendors = [...new Set(vendorList)];
        setVendor(vendors);
        const totalPrice = [];
        res.data.data.map((elem) => {
          totalPrice.push(elem.quotation_item.total_price);
        });
        const reducer = (accumulator, curr) => accumulator + curr;
        setTotalValues(totalPrice.reduce(reducer));
        const quoVendor = res.data.data.map((ele) => ({
          frieght: ele.quotation_item.quotation.cost_for_freight_installation,
          duties: ele.quotation_item.quotation.cost_for_duties,
          vendorId: ele.quotation_item.quotation.vender_id,
          vendor: ele.quotation_item.quotation.vendor,
          leadTime: ele.quotation_item.quotation.lead_time,
        }));
        const uniqueObjects = [
          ...new Map(quoVendor.map((item) => [item.vendorId, item])).values(),
        ];
        const totalFrieght = [];
        uniqueObjects.map((ele) => {
          totalFrieght.push(ele.frieght);
        });
        const totalDuties = [];
        uniqueObjects.map((ele) => {
          totalDuties.push(ele.duties);
        });
        const sumFrieght = totalFrieght.reduce(function (sum, number) {
          const updatedSum = sum + number;
          return updatedSum;
        }, 0);
        setCostFrieght(sumFrieght);
        const sumDuties = totalDuties.reduce(function (sum, number) {
          const updatedSum = sum + number;
          return updatedSum;
        }, 0);
        setCostDuties(sumDuties);
        setDeliveryDate(res.data.data[0].created_on.split(" ")[0]);
        const result = res.data.data.map((elem) => ({
          itemCate: elem.budgetitem.itemcategory.name,
          budget: elem.quotation_item.quotation.budget_item_details[0].budget,
          project: elem.quotation_item.quotation.budget_item_details[0].budget,
          requestQuotation: elem.quotation_item.quotation.rfq,
        }));
        const bud_price = [];
        res.data.data.map((ele) => {
          bud_price.push(ele.budgetitem.budgetquantitydetails.budget_price);
        });
        const budgetPrice = bud_price?.reduce(function (sum, number) {
          const updatedSum = sum + number;
          return updatedSum;
        }, 0);
        setBudPrice(budgetPrice);
        setData(result);
        setisLoader(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getCosrReport();
  }, []);

  const paths = [
    {
      name: strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["Projects"],
      path: "project",
    },

    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["Rfq"],
      path: `rfq/${encodeBudId}`,
    },
    {
      name: strings["Quotation"],
      path: `quotation/${encodedRfqId}`,
    },
    {
      name: strings["costanlysisText"],
      path: "costanalysis" + `/${encodedRfqId}`,
    },
    {
      name: strings["CostReport"],
      path: `searchcostreport`,
    },
  ];

  const [formData, setFormData] = React.useState({
    reason_recommendation: "",
    compiled_name: "",
    compiled_sign: "",
    compiled_date: "",
    checked_name: "",
    checked_sign: "",
    checked_date: "",
    wasl_res_po_sign: "",
    recommendation: "",
    do_not_proceed: "",
    comments: "  ",
    client_1_name: "",
    client_1_sign: "",
    client_1_date: "",
    client_2_name: "",
    client_2_sign: "",
    client_2_date: "",
    client_3_name: "",
    client_3_sign: "",
    client_3_date: "",
    distribution: "",
  });

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [isChecked, setIsChecked] = useState({
    enclosed_vendor: false,
    enclosed_cost: false,
    enclosed_tender: false,
  });
  const handleDataCheck = (e) => {
    const { name, checked } = e.target;
    setIsChecked({
      ...isChecked,
      [name]: checked,
    });
  };

  return (
    <div className="container">
      <Helmet>
        <title>
          {FurnishTitle} | {CAReportTitle}
        </title>
      </Helmet>
      <ModalPdf isOpen={isOpen} onHandleClose={handleModalClose}>
        {selectModal()}
      </ModalPdf>
      {isLoader ? <LoaderComponent /> : null}
      <div
        className="row mt-4"
        style={{
          alignItems: "center",
        }}
      >
        <div className="col-9">
          <h3>{CAReportTitle}</h3>
          <BreadCrumbs
            pathList={paths}
            obj={{
              proId: proId,
              budget_id: budget_id,
              rfqId: rfqId,
            }}
          />
        </div>
        <div
          className="col-3"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="budgetitembutton"
            onClick={handleChangePo}
            buttonStyle={{
              backgroundColor: colors.darkTransparent,
            }}
          >
            {PoBtnName}
          </button>
          <span
            className="reportDownloadBtn ms-1"
            style={{
              paddingLeft: "10px",
            }}
          >
            <IconButton onClick={handleChangeDownload}>
              <DownloadIcon />
            </IconButton>
          </span>
        </div>
      </div>
      <Table
        id="table-to-xls"
        striped
        bordered
        hover
        ref={ref}
        className="mt-5 mb-5 shadow"
        style={{
          border: "1px solid #d7d7d7",
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <tr>
          <tr
            style={{
              width: "20%",
              border: "none",
            }}
          >
            <img src={logoRect} alt="furnish" width="50px" height="100" />
          </tr>
          <td>
            <h2
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "grey",
              }}
            >
              RECOMMENDATION
            </h2>
          </td>
        </tr>
        <tr>
          <b style={{ paddingLeft: "10px" }}>Project:</b>{" "}
          {data.length !== 0 ? data[0].project.project.name : "NA"}
        </tr>
        <tr>
          <b style={{ paddingLeft: "10px" }}>Package Title: </b>
          {data.length !== 0 ? data[0].requestQuotation.rfq_package : "NA"}
        </tr>
        <tr>
          <b style={{ paddingLeft: "10px" }}>Package #:</b>
          {data.length !== 0
            ? data[0].requestQuotation.rfq_reference_field
            : "NA"}
        </tr>
        <tr>
          <b style={{ paddingLeft: "10px" }}>Date:</b>{" "}
          {moment(deliveryDate).format("Do MMM YYYY")}
        </tr>
        <tbody>
          <tr>
            <td colSpan={3}>
              We submit here without Tender Report and Recommendation in respect
              of the above Package.
            </td>
          </tr>
        </tbody>

        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr>
            <td
              style={{
                fontWeight: "bold",
              }}
            >
              BUDGET RECONCILIATION
            </td>
            <td
              align="center"
              style={{
                fontWeight: "bold",
              }}
              colSpan={2}
            >
              AED
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%" }}>
              Budget Allowance -{data.length !== 0 ? data[0].itemCate : "NA"}
            </td>

            <CurrencyFormat
              value={data.length !== 0 ? budPrice : 0}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <td align="center" colSpan={2}>
                  {value}
                </td>
              )}
            />

            {/* <td align="center" colSpan={2}>
              {data.length !== 0 ? budPrice : 0}
            </td> */}
          </tr>
          <tr style={{ padding: "10px" }}>
            <td>Budget Allowance - Freight</td>
            <td align="center" colSpan={2}>
              0
            </td>
          </tr>
          <tr>
            <td>Budget Allowance - Duties</td>
            <td align="center" colSpan={2}>
              0
            </td>
          </tr>
          <tr>
            <td>Budget Allowance - Other (Delivery & Packing Charges)</td>
            <td align="center" colSpan={2}>
              0
            </td>
          </tr>
          <tr>
            <td>Available Budget</td>
            <CurrencyFormat
              value={data.length !== 0 ? budPrice : 0}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <td align="center" colSpan={2}>
                  {value}
                </td>
              )}
            />
            {/* <td align="center" colSpan={2}>
              {data.length !== 0 ? budPrice : 0}
            </td> */}
          </tr>
        </tbody>

        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr>
            <td
              style={{
                fontWeight: "bold",
              }}
            >
              COST SUMMARY
            </td>
            <td
              colSpan={2}
              align="center"
              style={{
                fontWeight: "bold",
              }}
            >
              AED
            </td>
          </tr>

          <tr>
            <td style={{ width: "100%" }}>
              {" "}
              Cost - {data.length !== 0 ? data[0].itemCate : "NA"}
            </td>
            <td align="center" colSpan={2}>
              0
            </td>
          </tr>
          <tr style={{ padding: "10px" }}>
            <td>Cost - Freight</td>
            <CurrencyFormat
              value={costFrieght === null ? 0 : costFrieght}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <td align="center" colSpan={2}>
                  {value}
                </td>
              )}
            />
            {/* <td align="center" colSpan={2}>
              {costFrieght}
            </td> */}
          </tr>
          <tr>
            <td>Cost - Duties</td>
            <CurrencyFormat
              value={costDuties === null ? 0 : costFrieght}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <td align="center" colSpan={2}>
                  {value}
                </td>
              )}
            />
            {/* <td align="center" colSpan={2}>
              {costDuties}
            </td> */}
          </tr>
          <tr>
            <td>Cost - Other (Delivery & Packing Charges)</td>
            <td align="center" colSpan={2}>
              0
            </td>
          </tr>
          <tr>
            <td>Recommended Contract Award Figure</td>
            <CurrencyFormat
              value={costFrieght + costDuties + totalValue}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <td align="center" colSpan={2}>
                  {value}
                </td>
              )}
            />
            {/* <td align="center" colSpan={2}>
              {costFrieght + costDuties + totalValue}
            </td> */}
          </tr>
        </tbody>
        <tbody
          style={{
            width: "100%",
            marginTop: "15px",
            border: "none",
          }}
        >
          <tr>
            {data.length !== 0 ? (
              budPrice - (costFrieght + costDuties + totalValue) ? (
                budPrice - (costFrieght + costDuties + totalValue) > 0 ? (
                  <>
                    <td>
                      <b>Under the budget</b>
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "center",
                        backgroundColor: "#5ede4e",
                      }}
                    >
                      <CurrencyFormat
                        value={
                          data.length !== 0
                            ? budPrice - (costFrieght + costDuties + totalValue)
                            : 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => <b>AED {value}</b>}
                      />
                      {/* <b>
                        AED{" "}
                        {data.length !== 0
                          ? budPrice - (costFrieght + costDuties + totalValue)
                          : "NA"}
                      </b> */}
                    </td>
                  </>
                ) : (
                  <>
                    <td>Above the budget</td>
                    <td
                      className="bg-danger"
                      style={{ textAlign: "center" }}
                      colSpan={2}
                    >
                      <CurrencyFormat
                        value={
                          data.length !== 0
                            ? budPrice - (costFrieght + costDuties + totalValue)
                            : 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => <b>AED {value}</b>}
                      />

                      {/* <b>
                        AED{" "}
                        {data.length !== 0
                          ? budPrice - (costFrieght + costDuties + totalValue)
                          : "NA"}
                      </b> */}
                    </td>
                  </>
                )
              ) : (
                "NA"
              )
            ) : (
              ""
            )}
          </tr>
        </tbody>
        <tr>
          <td colSpan={3} style={{ paddingLeft: "10px" }}>
            We hereby recommend that the award of the Contract is made to{" "}
            <b>
              {vendor.map((e) => {
                return e;
              })}{" "}
            </b>
            in the sum of
            <span className="fw-bold">
              {" "}
              AED {costFrieght + costDuties + totalValue}
            </span>{" "}
            for the supply and installation of Housekeeping Machines for{" "}
            {data.length !== 0 ? data[0].project.name : "NA"} as per the
            quantities received from Client/Operator
          </td>
        </tr>

        <tr>
          <td
            style={{ width: "100%", fontWeight: "bold", paddingLeft: "10px" }}
          >
            {" "}
            Lead time:
          </td>
          <td align="center" colSpan={2}>
            {leadTime}
          </td>
        </tr>
        <tr>
          <td
            style={{ width: "100%", fontWeight: "bold", paddingLeft: "10px" }}
          >
            Payment Terms:
          </td>
          <td align="center" colSpan={2}></td>
        </tr>
        <tr>
          <td
            style={{ width: "100%", fontWeight: "bold", paddingLeft: "10px" }}
          >
            {" "}
            Incoterms:{" "}
          </td>
          <td align="center" colSpan={2}></td>
        </tr>
        <tr>
          <td
            style={{ width: "100%", fontWeight: "bold", paddingLeft: "10px" }}
          >
            Delivery Date::
          </td>
          <td align="center" colSpan={2}>
            {moment(deliveryDate).format("Do MMM YYYY")}
          </td>
        </tr>
        <tbody
          style={{
            width: "100%",
          }}
        >
          <tr>
            <h4 style={{ paddingLeft: "10px" }}>REASON FOR RECOMMENDATION</h4>
          </tr>
          <tr>
            <td colSpan={3}>
              <textarea
                style={{
                  width: "100%",
                }}
                name="reason_recommendation"
                value={formData.reason_recommendation}
                onChange={handleDataChange}
              ></textarea>
            </td>
          </tr>
        </tbody>
        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr
            style={{
              fontWeight: "bold",
              paddingLeft: "10px",
            }}
          >
            <h4 style={{ paddingLeft: "10px" }}>Compiled by FURNISH:</h4>
          </tr>
          <tr>
            <td>
              <tr>Name: </tr>
              <tr>
                <textarea
                  name="compiled_name"
                  value={formData.compiled_name}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Signature: </tr>
              <tr>
                <textarea
                  name="compiled_sign"
                  value={formData.compiled_sign}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Date: </tr>
              <tr>
                <input
                  name="compiled_date"
                  type="date"
                  value={formData.compiled_date}
                  onChange={handleDataChange}
                />
              </tr>
            </td>
          </tr>
        </tbody>
        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr
            style={{
              fontWeight: "bold",
            }}
          >
            <h4 style={{ paddingLeft: "10px" }}>Checked by FURNISH:</h4>
          </tr>
          <tr>
            <td>
              <tr>Name: </tr>
              <tr>
                <textarea
                  name="checked_name"
                  value={formData.checked_name}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Signature: </tr>
              <tr>
                <textarea
                  name="checked_sign"
                  value={formData.checked_sign}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Date: </tr>
              <tr>
                <input
                  type="date"
                  name="checked_date"
                  value={formData.checked_date}
                  onChange={handleDataChange}
                />
              </tr>
            </td>
          </tr>
        </tbody>
        <tbody
          style={{
            width: "100%",
            fontWeight: "bold",
          }}
        >
          <tr>
            <h4 style={{ paddingLeft: "10px" }}>Enclosed:</h4>
          </tr>
          <>
            <tr>
              <td>Vendors' Quotations</td>
              <td colSpan={2}>
                <input
                  name="enclosed_vendor"
                  value={isChecked.enclosed_vendor}
                  type="checkbox"
                  onChange={handleDataCheck}
                />
              </td>
            </tr>
            <tr>
              <td>Cost Comparison</td>
              <td colSpan={2}>
                <input
                  name="enclosed_cost"
                  value={isChecked.enclosed_cost}
                  type="checkbox"
                  onChange={handleDataCheck}
                />
              </td>
            </tr>
            <tr>
              <td>Tender Package Acceptance Form/s</td>
              <td colSpan={2}>
                <input
                  name="enclosed_tender"
                  value={isChecked.enclosed_tender}
                  type="checkbox"
                  onChange={handleDataCheck}
                />
              </td>
            </tr>
          </>
        </tbody>
        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr
            style={{
              fontWeight: "bold",
            }}
          >
            <td
              colSpan={3}
              style={{
                paddingLeft: "10px",
              }}
            >
              WASL Response:{" "}
              {data.length !== 0 ? data[0].project.project.clients?.name : "NA"}
            </td>
          </tr>
          <>
            <tr>
              <td>
                Please proceed to issue the Purchase Order for WASL signature:
              </td>
              <td colSpan={2}>
                <textarea
                  name="wasl_res_po_sign"
                  value={formData.wasl_res_po_sign}
                  onChange={handleDataChange}
                ></textarea>
              </td>
            </tr>

            <tr>
              <td>Please reconsider your recommendation and re-submit:</td>
              <td colSpan={2}>
                <textarea
                  name="recommendation"
                  onChange={handleDataChange}
                  value={formData.recommendation}
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Do not proceed further with this recommendation:</td>
              <td colSpan={2}>
                <textarea
                  name="do_not_proceed"
                  value={formData.do_not_proceed}
                  onChange={handleDataChange}
                ></textarea>
              </td>
            </tr>
          </>
        </tbody>
        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr
            style={{
              fontWeight: "bold",
            }}
          >
            <h4 style={{ paddingLeft: "10px" }}>Comments:</h4>
          </tr>
          <tr>
            <td colSpan={4}>
              <textarea
                name="comments"
                value={formData.comments}
                onChange={handleDataChange}
                style={{
                  width: "100%",
                }}
              ></textarea>
            </td>
          </tr>
        </tbody>
        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr
            style={{
              fontWeight: "bold",
            }}
          >
            <h4 style={{ paddingLeft: "10px" }}>Client:</h4>
          </tr>
          <tr>
            <td>
              <tr>Name: </tr>
              <tr>
                <textarea
                  name="client_1_name"
                  value={formData.client_1_name}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Signature: </tr>
              <tr>
                <textarea
                  name="client_1_sign"
                  value={formData.client_1_sign}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Date: </tr>
              <tr>
                <textarea
                  name="client_1_date"
                  value={formData.client_1_date}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
          </tr>
          <tr>
            <td>
              <tr>Name: </tr>
              <tr>
                <textarea
                  name="client_2_name"
                  value={formData.client_2_name}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Signature: </tr>
              <tr>
                <textarea
                  name="client_2_sign"
                  value={formData.client_2_sign}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Date: </tr>
              <tr>
                <textarea
                  name="client_2_date"
                  value={formData.client_2_date}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
          </tr>
          <tr>
            <td>
              <tr>Name: </tr>
              <tr>
                <textarea
                  name="client_3_name"
                  value={formData.client_3_name}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Signature: </tr>
              <tr>
                <textarea
                  name="client_3_sign"
                  value={formData.client_3_sign}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
            <td>
              <tr>Date: </tr>
              <tr>
                <textarea
                  name="client_3_date"
                  value={formData.client_3_date}
                  onChange={handleDataChange}
                ></textarea>
              </tr>
            </td>
          </tr>
        </tbody>
        <tbody style={{ width: "100%", marginTop: "15px" }}>
          <tr>
            <h4 style={{ paddingLeft: "10px" }}>Distribution:</h4>
          </tr>
          <tr>
            <td colSpan={3}>
              <textarea
                name="distribution"
                value={formData.distribution}
                onChange={handleDataChange}
                style={{
                  width: "100%",
                }}
              ></textarea>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default SearchReportPage;
