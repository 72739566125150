import React from "react";
import { Button } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ClientUploadFile = (props) => {
    const {
        initialValue = {},
        handleModalClose,
        errorMessage,
        setMessage,
        handleChangeData
    } = props;

    const cancelButton = Strings["Country.CreateCountryCancelButton"],
        uploadButton = Strings["Tooltip.uploadTooltip"];


    const [selectedFile, setSelectedFile] = React.useState({
        value: initialValue.selectedFile ? initialValue.selectedFile : "",
        error: false,
        success: false,
    });

    let disable =
        selectedFile.error ||
        selectedFile.value === "" ||
        (selectedFile.success === false);

    const handleChangeFile = (event) => {
        setMessage("");
        setSelectedFile(event.target.files[0]);
    };

    const handleSaveData = (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("csv", selectedFile, "vendors.csv");
        handleChangeData(formData);
    };

    return (
        <div className="modalFormContainer">
            <h2 className="mb-3">{"Upload File"}</h2>
            {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
            <div>
                <input
                    type="file"
                    value={selectedFile.value}
                    onChange={handleChangeFile}
                    accept=".csv"
                />
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "1.5rem",
                    justifyContent: "space-between",
                    marginLeft: "-10px"
                }}
            >
                <Button
                    buttonName={cancelButton}
                    buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
                    onClick={() => {
                        handleModalClose();
                    }}
                />
                <Button
                    buttonName={uploadButton}
                    buttonStyle={{ backgroundColor: colors.primary }}
                    disable={disable}
                    onClick={handleSaveData}
                />
            </div>
        </div>
    );
};


export default ClientUploadFile;
