import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import EditIcon from "@material-ui/icons/EditOutlined";
import * as React from "react";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  CreateCostAnalysis,
  GetAllVendorCost,
  GetBudgetItemPrice,
  GetCostAnalysis,
  UpdateItemQtyCostAnalysis,
} from "../../../axios";
import { BreadCrumbs, Button } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import { CREATE_COST_ANALYSIS } from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import "../SearchCostAnalysis/CostStyle.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  summeryTable: {
    left: "10px",
    minWidth: 650,
  },
}));
const CustomTableCell = ({ ele, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = ele;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input
          type="number"
          value={ele[name]}
          name={name}
          onChange={(e) => onChange(e, ele)}
          className={classes.input}
          inputProps={{ min: 0 }}
        />
      ) : (
        ele[name]
      )}
    </TableCell>
  );
};

export default function SearchCostAnalysis(props) {
  const costAnalysisTitle = Strings["CostAnalysis.searchFunctionTitle"],
    FurnishLabel = Strings["Title.Furnish"],
    SaveBtn = Strings["CostAnalysis.SaveBtn"],
    control_no = Strings["CostAnalysis.TableHead.SerialNo"],
    itemcategory_label = Strings["CostAnalysis.TableHead.itemCategory"],
    itemDescription_label = Strings["CostAnalysis.TableHead.itemDescription"],
    uom_label = Strings["CostAnalysis.TableHead.uom"],
    budTtlQty_label = Strings["CostAnalysis.TableHead.budTtlQty"],
    budget_label = Strings["CostAnalysis.TableHead.budget"],
    actTtlQty_label = Strings["CostAnalysis.TableHead.actTtlQty"],
    recomendation_label = Strings["CostAnalysis.TableHead.recomendation"],
    highestValue_label = Strings["CostAnalysis.TableHead.highestValue"],
    lowestValue_label = Strings["CostAnalysis.TableHead.lowestValue"],
    unitCost_label = Strings["CostAnalysis.TableHead.unitCost"],
    total_label = Strings["CostAnalysis.TableHead.totalCurrency"],
    Vendor_label = Strings["CostAnalysis.TableHead.Vendor"],
    leadTime_label = Strings["CostAnalysis.TableHead.leadTime"],
    totalValue_label = Strings["CostAnalysis.TableHead.total"],
    Freight_label = Strings["CostAnalysis.TableHead.Freight"],
    import_label = Strings["CostAnalysis.TableHead.duties"],
    other_label = Strings["CostAnalysis.TableHead.other"],
    grandTotal_label = Strings["CostAnalysis.TableHead.grandTotal"],
    varianceBudget_label = Strings["CostAnalysis.TableHead.varianceBudget"],
    report_label = Strings["CostAnalysis.TableHead.eeport"];
  const { initialValue } = props;
  const history = useHistory();
  const location = useLocation();
  const proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;

  const budget_id = location.state
    ? location.state.budget_id
    : location.query
    ? JSON.parse(location.query.params).budget_id
    : null;

  const rfqIdObj = useParams();
  const fetchId = isDecoded(rfqIdObj.id);
  const rfq_id = parseInt(fetchId);
  const rfqId = location.state
    ? location.state.rfqId
    : location.query
    ? JSON.parse(location.query.params).rfqId
    : rfq_id;
  const encoded_rfqId = isEncoded(rfqId);
  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  const encodeRfqId = isEncoded(rfqId);
  const [isLoading, setLoading] = React.useState(false);
  const [allData, setAllData] = React.useState([]);
  const [recommendationNotes] = React.useState({
    value: initialValue ? initialValue : "",
    error: false,
    success: false,
  });
  const [quotationVendor, setQuotationVendor] = React.useState([]);
  const [minCost, setMinCost] = React.useState([]);
  const [isAdd, setAdd] = React.useState(true);
  const [setCostFright] = React.useState(0);
  const [setCostDuties] = React.useState(0);
  const [recomValue, setRecomValue] = React.useState([]);
  const [saveDisable, setSaveDisable] = React.useState(true);

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_COST_ANALYSIS)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);
  const paths = [
    {
      name: Strings["DashBoardText"],
      path: "",
    },
    {
      name: strings["Projects"],
      path: "project",
    },

    {
      name: strings["Budgets"],
      path: `projectbudget/${encodeProId}`,
    },
    {
      name: strings["Budget Items"],
      path: `budgetitem/${encodeBudId}`,
    },
    {
      name: strings["Rfq"],
      path: `rfq/${encodeBudId}`,
    },
    {
      name: strings["Quotation"],
      path: `quotation/${encodeRfqId}`,
    },
    {
      name: Strings["costanlysisText"],
      path: "costanlysis",
    },
  ];
  const handleSaveData = (allData) => {
    const uniqueData = allData.filter((ele) => {
      return ele.recomUnitCost !== "NA";
    });

    const filterResult = allData.filter((ele) => {
      return ele.budget_item_id !== undefined;
    });
    const filterData = filterResult.map((ele) => ({
      rfq_id: rfqId,
      budget_item_id: ele.budget_item_id,
      vendor_id: ele.vendor_id,
      quotation_id: ele.quotation_id,
      actQty: ele.actualttlQty,
      actPrice: ele.recomTotalAed.toString(),
      actualUom: ele.actUom,
      serial_No: ele.serialNo,
    }));
    const data = {
      data: filterData,
    };
    data.notes = recommendationNotes.value;
    setLoading(true);
    CreateCostAnalysis(data)
      .then((res) => {
        setLoading(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        }).then((order) => {
          if (order) {
            history.push({
              pathname: `/searchcostreport/${encoded_rfqId}`,
              state: {
                rfqId: rfqId,
                proId: proId,
                budget_id: budget_id,
              },
            });
          } else {
            history.push({
              pathname: `/searchcostreport/${encoded_rfqId}`,
              state: {
                rfqId: rfqId,
                proId: proId,
                budget_id: budget_id,
              },
            });
          }
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const [lineResults, setLineResults] = React.useState([]);
  const [maxCost, setMaxCost] = React.useState([]);

  const getItemPriceLine = (sortingData) => {
    let tempLine = [];
    sortingData.forEach((ele, index) => {
      setLoading(true);
      GetBudgetItemPrice(ele.id)
        .then((res) => {
          tempLine.push({
            id: ele.id,
            controlNo: ele.control_number,
            serialNo: ele.budgetquantitydetails.item_serial_number,
            itemName: ele.name,
            category: ele.itemcategory.name,
            description: ele.description,
            uom: ele.budgetquantitydetails.budgetuom.name,
            actUom:
              ele.budgetquantitydetails.actual_uom_id === null
                ? ele.budgetquantitydetails.budget_uom_id
                : ele.budgetquantitydetails.actual_uom_id,
            budgetttlQty: ele.budgetquantitydetails.budget_quantity,
            unitCost: ele.budgetquantitydetails.budget_price,
            totalAED:
              ele.budgetquantitydetails.budget_price *
              ele.budgetquantitydetails.budget_quantity,
            actualttlQty:
              ele.budgetquantitydetails.actual_quantity === null
                ? ele.budgetquantitydetails.budget_quantity
                : ele.budgetquantitydetails.actual_quantity,
            quotation_vendors: ele.quotation_item,
            recomUnitCost: res?.data?.data?.unit_price
              ? res?.data?.data?.unit_price
              : "NA",
            recomTotalAed: res?.data?.data?.total_price
              ? res?.data?.data?.total_price
              : "NA",
            recomVendor: res?.data?.data?.quotationitem?.vendor?.name
              ? res?.data?.data?.quotationitem?.vendor?.name
              : "NA",
          });
          console.log("tempLine", tempLine);
          setLineResults([...tempLine]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    });
  };

  const getAllCostAnalysisById = async () => {
    setLoading(true);
    GetCostAnalysis(rfqId)
      .then((res) => {
        const minCost = res.data.minimumcost.map((e) => {
          return e;
        });
        console.log("MINCOST",minCost)
        setMinCost(minCost);
        const maxCost =  res.data.maximumcost.map((e) => {
          return e;
        })
        console.log("MAXCOST",maxCost)
        setMaxCost(maxCost);
        const result = res.data.data.map((ele) => {
          return ele.budget_item;
        });
        const newArray = result.flat();
        const ids = newArray.map((o) => o.id);
        const filtered = newArray.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        );
        console.log("FILTERED",filtered)
        getItemPriceLine(filtered);
        setCostDuties(
          res.data.data[0].budget_item[0]?.quotation_item[0]?.quotation
            .cost_for_duties
        );
        setCostFright(
          res.data.data[0].budget_item[0]?.quotation_item[1]?.quotation
            .cost_for_freight_installation
        );
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (lineResults.length > 0) {
      let totalMinCost = lineResults.map((doc) => {
        return {
          ...doc,
          min_cost: minCost.find((doc2) => doc.id === doc2.id),
        };
      });
      console.log("totalMinCost",totalMinCost)
      let allItems = totalMinCost.map((doc) => {
        return {
          ...doc,
          max_cost: maxCost.find((doc2) => doc.id === doc2.id),
        };
      });
     
      const count = [];
      console.log("allItems",allItems)
      allItems.map((ele) => {
        count.push(ele.quotation_vendors.length);
      });
      const maxNo = Math.max(...count);
      const indexNo = count.indexOf(maxNo);
      const quoVendors = allItems[indexNo];
      console.log("quoVendors",quoVendors)
      const filterQuoVendor = quoVendors.quotation_vendors.map((elem) => {
        return {
          name: elem.quotation?.vendor?.name,
          vendor_id: elem.quotation?.vendor?.id,
        };
      });
      console.log("filterQuoVendor",filterQuoVendor)
      const filterVendor = Array.from(
        new Set(filterQuoVendor.map((a) => a.vendor_id))
      ).map((id) => {
        return filterQuoVendor.find((a) => a.vendor_id === id);
      });
      console.log("filterVendor",filterVendor)
      let arrayofid = minCost.map((a) => a.id);
      allItems.sort(function (a, b) {
        return arrayofid.indexOf(a.id) - arrayofid.indexOf(b.id);
      });

      const recomendedValue = allItems.filter((ele) => {
        return ele.recomTotalAed !== "NA";
      });

      console.log("recomendedValue",recomendedValue)
      setRecomValue(recomendedValue);
      setQuotationVendor(filterVendor);
      console.log("allItems",allItems)
      setAllData(allItems);
      setLoading(false);
    }
  }, [lineResults, maxCost, minCost]);

  React.useEffect(() => {
    getAllCostAnalysisById();
  }, []);

  const handleChangeReport = () => {
    history.push({
      pathname: `/searchcostreport/${encoded_rfqId}`,
      state: {
        rfqId: rfqId,
        proId: proId,
        budget_id: budget_id,
      },
    });
  };
  const handleSelectVendor = () => {};
  const handleSelectItem = (item_id, quot_id, vendor_id) => {
    const data = {
      quotation_id: quot_id,
      vendorid: vendor_id?.id,
      budget_item_id: item_id,
    };
    setLoading(true);
    GetAllVendorCost(data)
      .then((res) => {
        const row = allData.find((elem) => {
          return elem.id === res.data.data.budget_item_id;
        });
        row.recomTotalAed = res.data.data.total_price;
        row.recomUnitCost = res.data.data.unit_price;
        row.recomVendor = vendor_id.name;
        row.budget_item_id = res.data.data.budget_item_id;
        row.quotation_id = res.data.data.quotation_id;
        row.vendor_id = data.vendorid;
        row.rfq_id = rfq_id;
        setAllData([...new Set([...allData, row])]);
        setSaveDisable(false);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const [previous, setPrevious] = React.useState({});
  const onToggleEditMode = (id) => {
    setLoading(true);

    setAllData(() => {
      return allData.map((row) => {
        if (row.id === id) {
          const data = {
            actual_quantity: row.actualttlQty,
          };
          UpdateItemQtyCostAnalysis(id, data)
            .then((res) => {
              swal({
                title: "Success!",
                text: res.data.message,
                icon: "success",
              });
              getAllCostAnalysisById();
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };
  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = allData.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setAllData(newRows);
  };
  const handleClickEditQty = (id) => {
    setAllData((state) => {
      return allData.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };
  return (
    <div className="content-wrapper p-0 mt-4">
      <Helmet>
        <title>
          {FurnishLabel} | {costAnalysisTitle}
        </title>
      </Helmet>
      {isLoading ? <LoaderComponent /> : null}
      <div className="d-md-flex d-block justify-content-between text-center align-items-center mb-4">
        <div className="col-auto">
          <h3 style={{ textAlign: "left" }}> {costAnalysisTitle} </h3>
          <BreadCrumbs
            pathList={paths}
            obj={{
              proId: proId,
              budget_id: budget_id,
              rfqId: rfqId,
            }}
          />
        </div>
        <div>
          <button
            className="budgetitembutton"
            buttonStyle={{
              backgroundColor: colors.darkTransparent,
            }}
            disable={recomValue.length > 0 ? false : true}
            onClick={() => {
              handleChangeReport();
            }}
          >
            {report_label}
          </button>
        </div>
      </div>
      <div className="div1">
        <table className="table table-striped">
          <tr>
            <th className="headerTable" rowSpan={2}>
              {control_no}
            </th>
            <th rowSpan={2}>{itemcategory_label}</th>
            <th rowSpan={2}>{itemDescription_label}</th>
            <th rowSpan={2}>{uom_label}</th>
            <th rowSpan={2} style={{ zIndex: 200 }}>
              {budTtlQty_label}
            </th>
            <th colSpan={2}>{budget_label}</th>
            <th rowSpan={2}>{actTtlQty_label}</th>
            <th colSpan={3}>{recomendation_label}</th>
            <th rowSpan={2}>{highestValue_label}</th>
            <th colSpan={3}>{lowestValue_label}</th>
            {quotationVendor.map((ele) => {
              return (
                <th
                  className="dataTable"
                  colSpan={2}
                  onClick={() => handleSelectVendor(ele.vendor_id)}
                >
                  {ele.name}
                </th>
              );
            })}
          </tr>
          <tr className="col_space_rowe">
            <td style={{ zIndex: 100 }}>{unitCost_label}</td>
            <td>{total_label}</td>
            <td style={{ zIndex: 100 }}>{unitCost_label}</td>
            <td>{total_label}</td>
            <td>{Vendor_label}</td>
            <td>{unitCost_label}</td>
            <td>{total_label}</td>
            <td>{Vendor_label}</td>
            {quotationVendor.map((elem) => {
              return (
                <>
                  <td>{unitCost_label}</td>
                  <td>{total_label}</td>
                </>
              );
            })}
          </tr>
          {allData.map((ele) => {
            return (
              <tr>
                <td className="iconClass">{ele.controlNo}</td>
                <td>{ele.category}</td>
                <td>{ele.description} </td>
                <td>{ele.uom}</td>
                <td>{ele.budgetttlQty}</td>
                <CurrencyFormat
                  value={ele.unitCost?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <td>{value}</td>}
                />
                <CurrencyFormat
                  value={ele.totalAED?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <td>{value}</td>}
                />
                <td>
                  {ele.isEditMode ? (
                    <>
                      <IconButton
                        aria-label="done"
                        onClick={() => onToggleEditMode(ele.id)}
                      >
                        <DoneIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleClickEditQty(ele.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  <span>
                    <CustomTableCell
                      {...{ ele, name: "actualttlQty", onChange }}
                    />
                  </span>
                </td>
                <td style={{ backgroundColor: "yellow" }}>
                  {ele.min_cost?.unit_price}
                </td>
                <td style={{ backgroundColor: "yellow" }}>
                  {ele.min_cost?.total_price}
                </td>
                <td style={{ backgroundColor: "yellow" }}>
                  {ele?.min_cost?.minimum_cost_vendor?.name}
                </td>
                <CurrencyFormat
                  value={ele?.max_cost?.total_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <td>{value}</td>}
                />
                <CurrencyFormat
                  value={ele?.min_cost?.unit_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <td>{value}</td>}
                />
                <CurrencyFormat
                  value={ele?.min_cost?.total_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <td>{value}</td>}
                />
                <td>{ele?.min_cost?.minimum_cost_vendor?.name}</td>
                {ele.quotation_vendors.map((elem) => {
                  return (
                    <>
                      <CurrencyFormat
                        value={elem.convert_unit_price}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => <td>{value}</td>}
                      />
                      <CurrencyFormat
                        value={elem.convert_total_price}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                          <td
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                ele.recomTotalAed && ele.recomUnitCost
                                  ? "yellow"
                                  : "",
                            }}
                            onClick={() =>
                              handleSelectItem(
                                ele.id,
                                elem.quotation.id,
                                elem.quotation.vendor
                              )
                            }
                          >
                            {value}
                          </td>
                        )}
                      />
                    </>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {leadTime_label}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>

            {allData[0]?.quotation_vendors.map((elem) => {
              return <td>{elem.quotation?.lead_time}</td>;
            })}
          </tr>

          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {totalValue_label}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <CurrencyFormat
              value={allData.reduce((acc, curr) => {
                return acc + curr.totalAED;
              }, 0)}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => <td colSpan={2}>{value}</td>}
            />
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <CurrencyFormat
              value={allData.reduce((acc, curr) => {
                return acc + curr?.min_cost?.total_price;
              }, 0)}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <>
                  <td> </td>
                  <td>{value}</td>
                </>
              )}
            />
          </tr>

          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {" "}
              {Freight_label} *{" "}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map((elem) => {
              return (
                <>
                  <td> </td>
                  <CurrencyFormat
                    value={
                      elem.quotation?.cost_for_freight_installation === null
                        ? 0
                        : elem.quotation?.cost_for_freight_installation
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => <td>{value}</td>}
                  />
                </>
              );
            })}
          </tr>

          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {" "}
              {import_label} *{" "}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map((elem) => {
              return (
                <>
                  <td> </td>
                  <CurrencyFormat
                    value={
                      elem.quotation?.cost_for_duties === null
                        ? 0
                        : elem.quotation?.cost_for_duties
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => <td>{value}</td>}
                  />
                </>
              );
            })}
          </tr>

          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {other_label}{" "}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map(() => {
              return (
                <>
                  <td> </td>
                  <td> </td>
                </>
              );
            })}
          </tr>

          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {" "}
              {grandTotal_label}{" "}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map((elem) => {
              return (
                <>
                  <td> </td>
                  <td> </td>
                </>
              );
            })}
          </tr>
          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {" "}
              {varianceBudget_label}{" "}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map((elem) => {
              return (
                <>
                  <td> </td>
                  <td> </td>
                </>
              );
            })}
          </tr>

          <tr>
            <td colSpan={4} style={{ zIndex: 100 }}>
              * These values are for comparitive purposes only and are not an
              allocation of funds.
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map((elem) => {
              return (
                <>
                  <td> </td>
                  <td> </td>
                </>
              );
            })}
          </tr>
          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {" "}
              Quotation Values as per Tender Opening form{" "}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map((elem) => {
              return (
                <>
                  <td> </td>
                  <td> </td>
                </>
              );
            })}
          </tr>
          <tr>
            <td colSpan={2} style={{ zIndex: 100 }}>
              {" "}
              Variance to Tender Opening Value{" "}
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            {allData[0]?.quotation_vendors.map((elem) => {
              return (
                <>
                  <td> </td>
                  <td> </td>
                </>
              );
            })}
          </tr>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "flex-end",
        }}
      >
        {isAdd && (
          <Button
            buttonName={SaveBtn}
            hidden={saveDisable}
            onClick={() => handleSaveData(allData)}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
        )}
      </div>
    </div>
  );
}
