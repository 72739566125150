/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  GetAllActiveIncoterm,
  GetAllActiveTransportation,
  GetAllActiveVendor,
} from "../../../axios";

import {
  Button,
  DateAndTimePicker,
  FilePicker,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const SearchUploadDocuments = (props) => {
  const {
    budget_id,
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const [loader, setLoader] = React.useState(false);

  const [status, setStatus] = React.useState({
    value: initialValue.status ? initialValue.status : "",
    error: false,
    success: false,
  });

  const [name, setName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [incoterm, setIncoterm] = React.useState({
    value: initialValue.incoterm ? initialValue.incoterm.id : "",
    error: false,
    success: false,
  });

  const [modeOfTransportation, setModeOfTransportation] = React.useState({
    value: initialValue.modeOfTransportation
      ? initialValue.modeOfTransportation.id
      : "",
    error: false,
    success: false,
  });

  const [deadline, setDeadline] = React.useState({
    value: initialValue.deadline ? initialValue.deadline : "",
    error: false,
    success: false,
  });

  const [importantNotes, setImportantNotes] = React.useState({
    value: initialValue.importantNotes ? initialValue.importantNotes : "",
    error: false,
    success: false,
  });

  const [tendorNotes, setTendorNotes] = React.useState({
    value: initialValue.tendorNotes ? initialValue.tendorNotes : "",
    error: false,
    success: false,
  });

  var vendorList = [];
  if (initialValue.vendor) {
    const result = initialValue.vendor.map((ele) => {
      vendorList.push({
        key: ele.vendor.id,
        value: ele.vendor.id,
        label: ele.vendor.name,
      });
    });
  }

  const [vendor, setVendor] = React.useState({
    // value: initialValue.vendor ? initialValue.vendor[0].vendor : [],
    value: vendorList,
    error: false,
    success: false,
  });
  // const [optionsCountry, setOptionsCountry] = React.useState([]);
  const [optionsIncoterm, setOptionsIncoterm] = React.useState([]);
  const [optionsModeOfTransportation, setOptionsModeOfTransportation] =
    React.useState([]);

  const handleChangeRfqName = (data) => {
    setStatus(data);
  };

  const handleChangeIncoterm = (data) => {
    setIncoterm(data);
  };

  const handleChangeDeadline = (data) => {
    setDeadline(data);
  };

  const handleChangeImportantNotes = (data) => {
    setImportantNotes(data);
  };

  const optionStatus = [
    {
      key: 1,
      value: "Draft",
      label: "Draft",
    },
    {
      key: 2,
      value: "Signed",
      label: "Signed",
    },
    {
      key: 3,
      value: "Send To Vendor",
      label: "Send To Vendor",
    },
  ];

  const getIncoterm = () => {
    setLoader(true);
    GetAllActiveIncoterm()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptionsIncoterm(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const getTransport = () => {
    setLoader(true);
    GetAllActiveTransportation()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptionsModeOfTransportation(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const getvendor = () => {
    setLoader(true);
    GetAllActiveVendor()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getIncoterm();
    getTransport();
    getvendor();
  }, []);

  const 
    rfqNamePlaceholder = Strings["RequestForQuotation.rfqNamePlaceholder"],
    rfqNameCustomError = Strings["RequestForQuotation.rfqNameCustomError"],
    IncotermCustomError = Strings["RequestForQuotation.IncotermCustomError"],
    deadlineLabel = Strings["RequestForQuotation.deadlineLabel"],
    deadlineCustomErrorLine =
      Strings["RequestForQuotation.deadlineCustomErrorLine"],
    importantNotesLabel = Strings["RequestForQuotation.importantNotesLabel"],
    importantNotesPlaceholder =
      Strings["RequestForQuotation.importantNotesPlaceholder"],
    importantNotesCustomError =
      Strings["RequestForQuotation.importantNotesCustomError"],
    saveBtn = Strings["RequestForQuotation.saveBtn"],
    updateBtn = Strings["RequestForQuotation.updateBtn"],
    cancelBtn = Strings["RequestForQuotation.cancelBtn"],
    userId = Strings["RequestForQuotation.TableHead.userId"],
    itemName = Strings["RequestForQuotation.TableHead.itemName"],
    vendorHead = Strings["RequestForQuotation.TableHead.vendor"],
    quantity = Strings["RequestForQuotation.TableHead.quantity"],
    uom = Strings["RequestForQuotation.TableHead.uom"];

  const isValueChanged =
    status.value === initialValue.status &&
    name.value === initialValue.name &&
    incoterm.value === initialValue.incoterm &&
    modeOfTransportation.value === initialValue.modeOfTransportation &&
    deadline.value === initialValue.deadline &&
    importantNotes.value === initialValue.importantNotes &&
    tendorNotes.value === initialValue.tendorNotes &&
    vendor.value === initialValue.vendor;

  const disable =
    status.error ||
    incoterm.error ||
    modeOfTransportation.error ||
    deadline.error ||
    vendor.error ||
    status.value === "" ||
    incoterm.value === "" ||
    modeOfTransportation.value === "" ||
    deadline.value === "" ||
    vendor.value.length === 0 ||
    (status.success === false &&
      incoterm.success === false &&
      modeOfTransportation.success === false &&
      deadline.success === false &&
      vendor.success === false) ||
    isValueChanged;

  const handleSubmit = () => {
    const budget_item = [];

    const budgetId = initialValue.budget_item.map((ele) => {
      budget_item.push(ele.id);
    });

    const functionVendor = () => {
      const resultArray = [];
      vendor.value.forEach((item) => {
        if (item.key !== null) {
          resultArray.push(item.key);
        }
      });
      return resultArray;
    };

    const formData = {
      name: status.value,
      budget_id: parseInt(budget_id.budgetID),
      incoterm_id: incoterm.value,
      mode_of_transportation_id: modeOfTransportation.value,
      valid_till: deadline.value,
      important_notes: importantNotes.value,
      tendor_notes: tendorNotes.value,
      vendor_id: functionVendor(vendor),
      budget_item_id: budget_item,
    };
    handleChangeData(formData);
  };

  const mapableData = [
    {
      dataKey: "id",
      title: userId,
    },
    {
      dataKey: "itemName",
      title: itemName,
    },
    {
      dataKey: "vendor",
      title: vendorHead,
    },
    {
      dataKey: "budgetQuantity",
      title: quantity,
    },
    {
      dataKey: "budgetUOM",
      title: uom,
    },
  ];

  return (
    <div className="modalFormContainer">
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {loader ? <LoaderComponent /> : null}
      <h2>
        {actionType === "uploadDoc" ? "Upload Document" : "Edit Document"}
      </h2>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormDropDown
            isMulti={false}
            value={incoterm.value}
            Label={"Status"}
            Options={optionStatus}
            onSelect={handleChangeIncoterm}
            CustomErrorLine={IncotermCustomError}
            searchable={false}
            disable={false}
          />
          <FormTextInput
            type="text"
            label={"Purchase Order Name"}
            placeholder={rfqNamePlaceholder}
            Value={status.value}
            onChangeText={handleChangeRfqName}
            Validate={true}
            CustomErrorLine={rfqNameCustomError}
          />
          <DateAndTimePicker
            label={deadlineLabel}
            customErrorLine={deadlineCustomErrorLine}
            hadleSetDate={handleChangeDeadline}
            date={deadline.value}
            disable={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            type="text"
            label={importantNotesLabel}
            placeholder={importantNotesPlaceholder}
            Value={importantNotes.value}
            onChangeText={handleChangeImportantNotes}
            Validate={true}
            CustomErrorLine={importantNotesCustomError}
            multiline={false}
          />
          <div style={{ marginTop: "20px" }}>
            <FilePicker label={"Import From"} isMulti={true} />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          disable={disable}
          onClick={handleSubmit}
          buttonStyle={{ backgroundColor: colors.primary }}
        />
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            // setMessage(null);
            handleModalClose();
          }}
        />
      </div>
    </div>
  );
};

export default SearchUploadDocuments;
