/* eslint-disable no-unused-vars */
import React from "react";
import { BreadCrumbs, CategoryTable } from "../../../components";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";

const myData = [
  {
    category: "Chair",
    budgetTotal: "1000",
    actualTotal: "100000",
  },
  {
    category: "Table",
    budgetTotal: "1500",
    actualTotal: "100000",
  },
  {
    category: "Sofa",
    budgetTotal: "1000",
    actualTotal: "100000",
  },
];

function SearchCategory() {
  const searchTitle = Strings["SearchCategory.title"],
    Category = Strings["SearchCategory.Category"],
    BudgetTotal = Strings["SearchCategory.BudgetTotal"],
    ActualTotal = Strings["SearchCategory.ActualTotal"];

  //   const [selected, setSelected] = React.useState([]);
  //   const [isOpen, setIsOpen] = React.useState(false);
  //   const [clickedBtn, setClickedBtn] = React.useState("");
  //   const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState(myData);
  //   const [alertBox, setAlertBox] = React.useState(false)
  //   const [statusChangeId, setStatusChangeId] = React.useState(null)
  const paths = [
    {
      name: strings["Project"],
      path: "project",
    },
    {
      name: strings["Budget"],
      path: "projectbudget",
    },
    {
      name: strings["BudgetItem"],
      path: "budgetitem",
    },
    {
      name: strings["Category"],
      path: "projectCategory",
    },
  ];
  const mapableData = [
    {
      dataKey: "category",
      title: Category,
    },
    {
      dataKey: "budgetTotal",
      title: BudgetTotal,
    },
    {
      dataKey: "actualTotal",
      title: ActualTotal,
    },
  ];

  return (
    <div className="container">
      <BreadCrumbs pathList={paths} />
      <div className="row mt-3">
        <div className="d-md-flex d-block justify-content-between text-center mb-4">
          <h2> {searchTitle}</h2>
          {/* <IconButton onClick={() => {
            openAddModal();
            handleSelectBtn("add");
          }}>
            <AddIcon />
          </IconButton> */}
        </div>
        {/* table component added */}
        <div className="col-12">
          <CategoryTable
            data={rows}
            mapableData={mapableData}
            searchProp={["category"]}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchCategory;
