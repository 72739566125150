/* eslint-disable no-unused-vars */
import moment from "moment";
import React from "react";
import {
  AllActiveStatusCurrency,
  GetAllActiveEstablishment,
  GetAllBudgetScope,
} from "../../../axios";
import {
  Button,
  DateAndTimePicker,
  // FilePicker,
  FormCheckBox,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";

const CreateProjectBudgetForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleBudgetData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    proId,
  } = props;

  const [loader, setLoader] = React.useState(false);

  const [budgetScope, setBudgetScope] = React.useState({
    value: initialValue.budget_scope_id ? initialValue.budget_scope_id : "",
    error: false,
    success: false,
  });

  const [budgetName, setBudgetName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [currency, setCurrency] = React.useState({
    value: initialValue.currency_id ? initialValue.currency_id : "",
    error: false,
    success: false,
  });

  const [establishment, setEstablishment] = React.useState({
    value: initialValue.establishment ? initialValue.establishment.id : "",
    error: false,
    success: false,
  });

  const [projectValue, setProjectValue] = React.useState({
    value: initialValue.value ? initialValue.value : "",
    error: false,
    success: false,
  });

  const [serialPreference, setSerialPreference] = React.useState({
    value: initialValue.item_serial_number_preference
      ? initialValue.item_serial_number_preference
      : "1",
    error: false,
    success: false,
  });

  const [startDate, setStartDate] = React.useState({
    value: initialValue.budget_start_date
      ? initialValue.budget_start_date.split(" ")[0]
      : "",
    error: false,
    success: false,
  });
  const [endDate, setEndDate] = React.useState({
    value: initialValue.budget_end_date
      ? initialValue.budget_end_date.split(" ")[0]
      : "",
    error: false,
    success: false,
  });

  const [lock, setLock] = React.useState(
    initialValue.budget_lock === 1 ? true : false
  );

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.upload ? initialValue.upload : null,
    error: false,
    success: false,
  });

  const [minDates, setMinDates] = React.useState();
  const [budgetscopeDropDown, SetbudgetscopeDropDown] = React.useState([]);
  const [currencyDropDown, SetcurrencyDropDown] = React.useState([]);
  const [establishmentDropDown, setEstablishmentDropDown] = React.useState([]);
  const [uploadFile, setUploadFile] = React.useState([]);

  const getAllActiveBudgetScope = () => {
    setLoader(true);
    GetAllBudgetScope()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        SetbudgetscopeDropDown(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const getAllActiveCurrency = () => {
    setLoader(true);
    AllActiveStatusCurrency()
      .then((res) => {
        setLoader(false);
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        SetcurrencyDropDown(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  React.useEffect(() => {
    setLoader(true);
    GetAllActiveEstablishment()
      .then((res) => {
        setLoader(false);
        const result = res.data.data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setEstablishmentDropDown(result);
      })
      .catch((e) => {
        setLoader(false);
      });
  }, []);

  React.useEffect(() => {
    getAllActiveCurrency();
    getAllActiveBudgetScope();
  }, []);

  const handleSaveData = () => {
    let itemList = uploadFile;
    if (itemList.length === 0) {
      const budgetData = {
        establishment_id: establishment.value,
        budget_scope_id: budgetScope.value,
        item_serial_number_preference: serialPreference.value,
        budget_name: budgetName.value,
        currency_id: currency.value,
        value: parseInt(projectValue.value),
        budget_start_date: startDate.value,
        budget_end_date: endDate.value,
        selectedFile: { data: null },
        budget_lock: lock,
        project_id: proId,
      };
      handleBudgetData(budgetData);
    } else {
      if (itemList[0].__EMPTY === "Room Type ") {
        itemList.shift();
        const resultItem = itemList.filter((ele) => {
          return ele.__EMPTY_1 !== undefined;
        });
        const uploadData = resultItem.map((item) => {
          return {
            budget_name: budgetName.value,
            item_group_name: null,
            item_subgroup_name: item.__EMPTY,
            item_category_name: item.__EMPTY_1,
            description: item.__EMPTY_5,
            name: "N/A",
            budget_quantity: item.__EMPTY_10,
            budget_uom: item.__EMPTY_11,
            budget_currency: item.__EMPTY_12,
            budget_price: parseInt(item.__EMPTY_14),
            qtyByRoom: item.__EMPTY_7,
            perLevel: item.__EMPTY_8,
            rooms: item.__EMPTY_9,
            qty: 0,
            tags: null,
            total_units: null,
            brand: null,
            totalunits: null,
            actual_uom: null,
            actual_quantity: null,
            actual_currency: null,
            actual_price: null,
            ship_to_vendor: null,
            model_number: null,
            notes: null,
            size: null,
          };
        });
        const budgetData = {
          establishment_id: establishment.value,
          budget_scope_id: budgetScope.value,
          item_serial_number_preference: serialPreference.value,
          budget_name: budgetName.value,
          currency_id: currency.value,
          value: parseInt(projectValue.value),
          budget_start_date: startDate.value,
          budget_end_date: endDate.value,
          selectedFile: { data: uploadData },
          budget_lock: lock,
          project_id: proId,
        };
        handleBudgetData(budgetData);
      } else {
        itemList.shift();
        const resultItem = itemList.filter((ele) => {
          return ele.__EMPTY_2 !== undefined;
        });
        const fetchKey = Object.keys(resultItem[0]);
        const newKeyss = fetchKey[3];
        const result = resultItem.map((ele) => {
          return {
            ...ele,
            itemDesc: ele[newKeyss],
          };
        });
        const uploadData = result.map((item, index) => {
          return {
            budget_name: budgetName.value,
            item_group_name: item.__EMPTY,
            item_subgroup_name: item.__EMPTY_1,
            item_category_name: item.__EMPTY_2,
            description: item.itemDesc,
            name: "N/A",
            budget_quantity: item.__EMPTY_6,
            budget_uom: item.__EMPTY_7,
            budget_currency: item.__EMPTY_8,
            budget_price: parseInt(item.__EMPTY_10),
            qtyByRoom: null,
            perLevel: null,
            rooms: null,
            qty: 0,
            tags: null,
            total_units: null,
            brand: null,
            totalunits: null,
            actual_uom: null,
            actual_quantity: null,
            actual_currency: null,
            actual_price: null,
            ship_to_vendor: null,
            model_number: null,
            notes: null,
            size: null,
          };
        });
        const budgetData = {
          establishment_id: establishment.value,
          budget_scope_id: budgetScope.value,
          item_serial_number_preference: serialPreference.value,
          budget_name: budgetName.value,
          currency_id: currency.value,
          value: parseInt(projectValue.value),
          budget_start_date: startDate.value,
          budget_end_date: endDate.value,
          selectedFile: { data: uploadData },
          budget_lock: lock,
          project_id: proId,
        };
        handleBudgetData(budgetData);
      }
    }
  };

  const initLock = initialValue?.budget_lock === 1 ? true : false;

  let isValueNOTChanged =
    budgetScope.value === initialValue.budgetscope?.id &&
    budgetName.value === initialValue.name &&
    projectValue.value === initialValue.value &&
    currency.value === initialValue?.currency?.id &&
    // startDate.value === initialValue.budget_start_date &&
    // endDate.value === initialValue.budget_end_date &&
    lock === initLock;
  // establishment.value === initialValue.establishment?.id;

  let disable =
    budgetScope.error ||
    establishment.error ||
    budgetName.error ||
    currency.error ||
    serialPreference.error ||
    startDate.error ||
    endDate.error ||
    budgetScope.value === " " ||
    budgetName.value === "" ||
    // establishment.value === "" ||
    currency.value === "" ||
    startDate.value === "" ||
    endDate.value === "" ||
    serialPreference.value === "" ||
    (budgetScope.success === false &&
      // establishment.success === false &&
      startDate.success === false &&
      endDate.success === false &&
      budgetName.success === false &&
      currency.success === false &&
      serialPreference.success === false &&
      isValueNOTChanged);

  let isEditValueNOTChanged =
    budgetName.value === initialValue.budgetName &&
    budgetScope.value === initialValue.budgetScope &&
    // establishment.value === initialValue.establishment &&
    serialPreference.value === initialValue.item_serial_number_preference &&
    currency.value === initialValue.currency &&
    projectValue.value === initialValue.projectValue &&
    startDate.value === initialValue.startDate &&
    endDate.value === initialValue.endDate;

  let editDisable =
    budgetName.error ||
    budgetScope.error ||
    currency.error ||
    projectValue.error ||
    startDate.error ||
    endDate.error ||
    serialPreference.error ||
    budgetScope.value === "" ||
    budgetName.value === "" ||
    // establishment.value === "" ||
    currency.value === "" ||
    startDate.value === "" ||
    endDate.value === "" ||
    (budgetScope.success === false &&
      projectValue.success === false &&
      budgetName.success === false &&
      currency.success === false &&
      // establishment.success === false &&
      startDate.success === false &&
      serialPreference.success === false &&
      endDate.success === false) ||
    isEditValueNOTChanged;

  const controlNumberOption = [
    {
      key: 1,
      value: "1",
      label: "Subgroup + Serial",
    },
    {
      key: 2,
      value: "2",
      label: "Subgroup + Category + Serial",
    },
  ];

  const handleChangeprojetScope = (value) => {
    setMessage(null);
    setBudgetScope(value);
  };

  const handleChangebudgetName = (value) => {
    setMessage(null);
    setBudgetName(value);
  };
  const handleChangecurrency = (value) => {
    setMessage(null);
    setCurrency(value);
  };

  const handleChangeEstablishment = (value) => {
    setMessage(null);
    setEstablishment(value);
  };

  const handleChangeSerial = (value) => {
    setMessage(null);
    setSerialPreference(value);
  };

  const handleChangeprojectValue = (value) => {
    setMessage(null);
    setProjectValue(value);
  };

  const handleLock = (value) => {
    setMessage(null);
    setLock(value);
  };
  const handleBudgetStartDate = (value) => {
    setMessage(null);
    setStartDate(value);

    // if (endDate.value !== null && value.value > endDate.value) {
    //   setEndDate(value);
    //   setMessage(null);
    // }
  };
  const handleBudgetEndDate = (value) => {
    setMessage(null);
    setEndDate(value);

    // if (startDate.value !== null && value.value < startDate.value) {
    //   setMessage("Project end date cannot be before project start date");
    // } else {
    //   setMessage(null);
    // }
  };

  const minDate = () => {
    var dateToday = new Date();
    setMinDates(moment(dateToday).format("YYYY-MM-DD"));
  };

  React.useEffect(() => {
    minDate();
  }, []);

  const createBudgetHeader = strings["ProjectBudget.createBudgetHeader"],
    editBudgetHeader = strings["ProjectBudget.editBudgetHeader"],
    serialLabel = strings["ProjectBudgetCreate.serialLabel"],
    projetScopeErrorLine = strings["ProjectBudget.projetScopeErrorLine"],
    projetScopeLabel = strings["ProjectBudget.projetScopeLabel"],
    projetScopeSelectText = strings["ProjectBudget.projetScopeSelectText"],
    ControlNumberLabel = strings["ProjectBudget.ControlNumberLabel"],
    budgetNameLabel = strings["ProjectBudget.budgetNameLabel"],
    budgetNamePlaceholder = strings["ProjectBudget.budgetNamePlaceholder"],
    budgetNameCustomError = strings["ProjectBudget.budgetNameCustomError"],
    currencyLabel = strings["ProjectBudget.currencyLabel"],
    currencyErrorLine = strings["ProjectBudget.currencyErrorLine"],
    serialPreferenceErrorLine =
      strings["BudgetSerialPreference.serialErrorLine"],
    currencySelectText = strings["ProjectBudget.currencySelectText"],
    projectValueLabel = strings["ProjectBudget.projectValueLabel"],
    projectValuePlaceholder = strings["ProjectBudget.projectValuePlaceholder"],
    saveBtn = strings["ProjectBudget.saveBtn"],
    updateBtn = strings["ProjectBudget.updateBtn"],
    cancelBtn = strings["ProjectBudget.cancelBtn"],
    lockBudgetLabel = strings["ProjectBudget.lockBudgetLabel"],
    budgetEndDateLabel = strings["ProjectBudget.projectEndDateLabel"],
    budgetStartDateLabel = strings["ProjectBudget.projectStartDateLabel"],
    establishments = strings["ProjectBudget.ProjectTypeLabel"],
    budgetStartDateError = strings["ProjectBudget.budgetStartDateError"],
    budgetEndDateError = strings["ProjectBudget.budgetEndDateError"],
    fileLabel = strings["File.fileLabel"];

  return (
    <div className="modalFormContainer">
      {loader ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? createBudgetHeader : editBudgetHeader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}

      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <FormTextInput
            labelTextStyle={{
              marginTop: "30px",
            }}
            type="alpha"
            label={budgetNameLabel}
            placeholder={budgetNamePlaceholder}
            Value={budgetName.value}
            onChangeText={handleChangebudgetName}
            Validate={true}
            CustomErrorLine={budgetNameCustomError}
            editable={!lock}
            starProps={true}
            maxLength={50}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3 mt-3" id="select-style">
          <FormDropDown
            Label={projetScopeLabel}
            Options={budgetscopeDropDown}
            value={budgetScope.value}
            onSelect={handleChangeprojetScope}
            customErrorLine={projetScopeErrorLine}
            selectText={projetScopeSelectText}
            searchable={false}
            disable={lock}
            starProps={true}
            isValidate={true}
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-md-6 pe-0 pe-md-3"
          style={{
            marginTop: "-12px",
          }}
        >
          <FormDropDown
            labelStyle={{
              marginBottom: "10px",
            }}
            Label={establishments}
            Options={establishmentDropDown}
            value={establishment.value}
            onSelect={handleChangeEstablishment}
            starProps={false}
            isValidate={false}
          />
        </div>

        <div
          className="col-12 col-md-6 pe-0 pe-md-3"
          style={{
            marginTop: "-12px",
          }}
        >
          <FormDropDown
            labelStyle={{
              marginBottom: "10px",
            }}
            Label={currencyLabel}
            Options={currencyDropDown}
            value={currency.value}
            onSelect={handleChangecurrency}
            customErrorLine={currencyErrorLine}
            selectText={currencySelectText}
            searchable={false}
            disable={lock}
            starProps={true}
            isValidate={true}
          />
        </div>
      </div>

      <div className="row">
        <div
          className="col-12 col-md-6 pe-0 pe-md-3"
          style={{
            marginTop: "-12px",
          }}
        >
          <FormTextInput
            type="number"
            starProps={false}
            label={projectValueLabel}
            placeholder={projectValuePlaceholder}
            Value={projectValue.value}
            onChangeText={handleChangeprojectValue}
            editable={!lock}
            Validate={projectValue.value && true}
            min={0}
          />
          <div classN Lock Budget ame="col-12 col-md-6 pe-0 pe-md-3 mb-3 mt-2">
            <FormCheckBox
              key={""}
              Label={lockBudgetLabel}
              checked={lock}
              handleChangeCheck={handleLock}
            />
          </div>
        </div>
        <div
          className="col-12 col-md-6 pe-0 pe-md-3"
          style={{
            marginTop: "-6px",
          }}
        >
          <FormDropDown
            labelStyle={{
              marginBottom: "10px",
            }}
            Label={ControlNumberLabel}
            Options={controlNumberOption}
            value={serialPreference.value}
            onSelect={handleChangeSerial}
            starProps={true}
            isValidate={true}
            customErrorLine={serialPreferenceErrorLine}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <DateAndTimePicker
            dateAndTimeLabel={{
              marginBottom: "10px",
            }}
            // minDate={minDates}
            label={budgetStartDateLabel}
            disable={false}
            customErrorLine={budgetStartDateError}
            hadleSetDate={handleBudgetStartDate}
            date={startDate.value}
            starProps={true}
          />
        </div>
        <div className="col-12 col-md-6 pe-0 pe-md-3">
          <DateAndTimePicker
            dateAndTimeLabel={{
              marginBottom: "10px",
            }}
            // minDate={minDates}
            label={budgetEndDateLabel}
            disable={false}
            customErrorLine={budgetEndDateError}
            hadleSetDate={handleBudgetEndDate}
            date={endDate.value}
            starProps={true}
          />
        </div>
      </div>
      {/* <div className="row">
        <div
          className="col-12 col-md-6 pe-0 pe-md-3"
          style={{
            marginTop: "-10px",
          }}
        >
          {actionType === "add" ? (
            <FilePicker
              labelTextStyle={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
              label={fileLabel}
              onChange={handleFileChange}
              isMulti={true}
              type="file"
            />
          ) : null}
        </div>
      </div> */}
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={disable || errorMessage}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default CreateProjectBudgetForm;
