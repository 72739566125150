import React, { useEffect } from "react";
import { FormDropDown, Button, FormCheckBox } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import _ from "lodash";

const VendorCompanytoCategoryMappingForm = (props) => {
  const {
    handleResultData,
    actionType,
    initialValue = {},
    handleModalClose,
  } = props;

  const [initialSubCategory, setInitialSubCategory] = React.useState(
    initialValue.subCategory ? [...initialValue.subCategory] : []
  );

  const [subCategory, setSubCategory] = React.useState([
    { key: 1, subCategoryName: " Category 1", checked: false },
    { key: 2, subCategoryName: " Category 2", checked: false },
    { key: 3, subCategoryName: " Category 3", checked: false },
    { key: 4, subCategoryName: " Category 4", checked: false },
    { key: 5, subCategoryName: " Category 5", checked: false },
  ]);

  useEffect(() => {
    if (actionType === "edit") {
      let newRoles = subCategory.filter(function (objFromA) {
        return !initialValue.subCategory.find(function (objFromB) {
          return objFromA.key === objFromB.key;
        });
      });
      setSubCategory([...initialValue.subCategory, ...newRoles]);
      setInitialSubCategory([...initialValue.subCategory, ...newRoles]);
    }
  }, []);

  const [selectData, setSelectData] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const options = [
    {
      key: 1,
      value: "Company1",
      label: "Company1",
    },
    {
      key: 2,
      value: "Company2",
      label: "Company2",
    },
    {
      key: 3,
      value: "Company3",
      label: "Company3",
    },
  ];

  const handleChangeCheck = (key) => {
    const result = subCategory.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setSubCategory(result);
  };

  const handleSelectDropDown = (value) => {
    setSelectData(value);
  };

  const functionResultTrue = (roles) => {
    const resultArray = [];
    subCategory.forEach((item) => {
      if (item.checked === true) {
        resultArray.push(item.subCategoryName);
      }
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      selectData: selectData,
      roles: functionResultTrue(subCategory),
    };

    handleResultData(formData);
  };

  const disable =
    selectData.value === "" ||
    selectData.error ||
    selectData.success === false ||
    subCategory.every((functionName) => {
      return !functionName.checked;
    });

  const editDisable =
    initialValue.name === selectData.value &&
    _.isEqual(subCategory, initialSubCategory);

  const pageHeader = Strings["VendorCompanytoCategoryMapping.CreateMapping"],
    editpageHeader = Strings["VendorCompanytoCategoryMapping.EditMapping"],
    dropdownLabel = Strings["VendorCompanytoCategoryMapping.SelectCategory"],
    dropdownCustomError =
      Strings["VendorCompanytoCategoryMapping.CategoryCustomError"],
    selectText =
      Strings["VendorCompanytoCategoryMapping.SelectCategoryPlaceHolder"],
    selctRoleHeader =
      Strings["VendorCompanytoCategoryMapping.SelectSubCategories"],
    SaveButton = Strings["VendorCompanytoCategoryMapping.SaveBtn"],
    cancelButton = Strings["VendorCompanytoCategoryMapping.CancelBtn"];

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? pageHeader : editpageHeader}</h2>
      <FormDropDown
        selectText={selectText}
        Label={dropdownLabel}
        Options={options}
        onSelect={handleSelectDropDown}
        customErrorLine={dropdownCustomError}
        searchable={false}
        value={selectData.value}
        disable={actionType !== "add" ? true : false}
        isValidate={true}
      />
      <br />
      <h4>{selctRoleHeader}</h4>
      <div className={"row"}>
        {subCategory.map((functionName) => {
          return (
            <div className={"col-12 col-md-4"}>
              <FormCheckBox
                key={functionName.key}
                Label={functionName.subCategoryName}
                checked={functionName.checked}
                handleChangeCheck={() => handleChangeCheck(functionName.key)}
              />
            </div>
          );
        })}
      </div>

      <div className="row deleteFunction__buttongroup">
        <div style={{ display: "flex", marginTop: "30px" }}>
          <Button
            buttonName={SaveButton}
            disable={actionType === "add" ? disable : editDisable}
            onClick={handleSaveData}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
          <Button
            buttonName={cancelButton}
            onClick={() => {
              handleModalClose();
            }}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorCompanytoCategoryMappingForm;
