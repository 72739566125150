/* eslint-disable no-unused-vars */
import _ from "lodash";
import React from "react";
import {
  GetActiveitemCategory,
  GetAllActiveItemSubCategories,
} from "../../../axios";
import { Button, FormCheckBox, FormDropDown } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateMappingItemCategorySubCategoryForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleResultData,
    actionType,
    initialValue = {},
    handleModalClose,
  } = props;

  const [loading, setLoading] = React.useState(false);

  const [initialSubCategory, setInitialSubCategory] = React.useState(
    initialValue.subcategories ? [...initialValue.subcategories] : []
  );
  const [subCategory, setSubCategory] = React.useState([]);
  const [selectData, setSelectData] = React.useState({
    value: initialValue.name ? initialValue.id : "",
    error: false,
    success: false,
  });
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (actionType === "add") {
      setLoading(true);
      GetAllActiveItemSubCategories()
        .then((res) => {
          let allData = res.data.data;
          const result = allData.map((elm) => ({
            key: elm.id,
            name: elm.name,
            checked: false,
          }));
          setLoading(false);
          setSubCategory(result);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (actionType === "edit") {
      setLoading(true);
      const arr = initialValue.subcategories;
      const checkArr = { checked: true };
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var sub_id_list = [];
      const result = arr.map((ele) => sub_id_list.push(ele.id));
      GetAllActiveItemSubCategories()
        .then((res) => {
          setLoading(false);
          const fetchData = res.data.data.map((elm) => ({
            key: elm.id,
            name: elm.name,
            checked: sub_id_list.includes(elm.id) ? true : false,
          }));
          setSubCategory(fetchData);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const getAllActiveItemCategory = () => {
    setLoading(true);
    GetActiveitemCategory()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setOptions(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAllActiveItemCategory();
  }, []);

  const handleChangeCheck = (key) => {
    const result = subCategory.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setSubCategory(result);
  };

  const handleSelectDropDown = (value) => {
    setMessage(null);
    setSelectData(value);
  };

  const functionResultTrue = (roles) => {
    const resultArray = [];
    subCategory.forEach((item) => {
      if (item.checked === true) {
        resultArray.push(item.key);
      }
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      item_category_id: selectData.value,
      item_sub_category_ids: functionResultTrue(subCategory),
    };
    handleResultData(formData);
  };

  const disable =
    selectData.value === "" ||
    selectData.error ||
    selectData.success === false ||
    subCategory.every((functionName) => {
      return !functionName.checked;
    });

  const editDisable =
    initialValue.name === selectData.value &&
    _.isEqual(subCategory, initialSubCategory);


  const pageHeader = Strings["ItemCategorySubCategoryMapping.CreateMapping"],
    editpageHeader = Strings["ItemCategorySubCategoryMapping.EditMapping"],
    dropdownLabel = Strings["ItemCategorySubCategoryMapping.SelectCategory"],
    dropdownCustomError =
      Strings["VendorCompanytoCategoryMapping.CategoryCustomError"],
    selectText =
      Strings["ItemCategorySubCategoryMapping.SelectCategoryPlaceHolder"],
    selctRoleHeader =
      Strings["ItemCategorySubCategoryMapping.SelectSubCategories"],
    SaveButton = Strings["VendorCompanytoCategoryMapping.SaveBtn"],
    cancelButton = Strings["VendorCompanytoCategoryMapping.CancelBtn"];

  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? pageHeader : editpageHeader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormDropDown
        selectText={selectText}
        Label={dropdownLabel}
        Options={options}
        onSelect={handleSelectDropDown}
        customErrorLine={dropdownCustomError}
        searchable={false}
        value={selectData.value}
        disable={actionType !== "add" ? true : false}
        isValidate={true}
      />
      <br />
      <h4>{selctRoleHeader}</h4>
      <div className={"row"}>
        {subCategory.map((functionName) => {
          return (
            <div className={"col-12 col-md-4"}>
              <FormCheckBox
                key={functionName.key}
                Label={functionName.name}
                checked={functionName.checked}
                handleChangeCheck={() => handleChangeCheck(functionName.key)}
              />
            </div>
          );
        })}
      </div>

      <div className="row deleteFunction__buttongroup">
        <div style={{ display: "flex", marginTop: "30px" }}>
          <Button
            buttonName={SaveButton}
            disable={actionType === "add" ? disable : editDisable}
            onClick={handleSaveData}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
          <Button
            buttonName={cancelButton}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateMappingItemCategorySubCategoryForm;
