/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const VendorCategoryForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createHeader = Strings["VendorCategory.createVendorCategoryHeader"],
    editHeader = Strings["VendorCategory.editVendorCategoryHeader"],
    nameLabel = Strings["VendorCategory.createVendorCategoryLable"],
    nameplaceholder = Strings["VendorCategory.createVendorCategoryPlaceHolder"],
    nameCustomError =
      Strings["VendorCategory.createVendorCategoryNamecustomlineerror"],
    DescriptionLabel =
      Strings["VendorCategory.createVendorCategoryDescriptionLable"],
    Descriptionplaceholder =
      Strings["VendorCategory.createVendorCategoryDescriptionPlaceHolder"],
    DescriptionCustomError =
      Strings["VendorCategory.createVendorCategoryDescriptioncustomlineerror"],
    CodeLabel = Strings["VendorCategory.createVendorCategoryCodeLable"],
    Codeplaceholder =
      Strings["VendorCategory.createVendorCategoryCodePlaceHolder"],
    CodeCustomError =
      Strings["VendorCategory.createVendorCategoryCodecustomlineerror"],
    saveButton = Strings["VendorCategory.createVendorCategorySaveButton"],
    cancelButton = Strings["VendorCategory.createVendorCategoryCancelButton"],
    updateButton = Strings["VendorCategory.createVendorCategoryUpdateButton"];

  const [category, setName] = React.useState({
    value: initialValue.category ? initialValue.category : "",
    error: false,
    success: false,
  });

  const [description, setDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [code, setCode] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    success: false,
  });

  const handleChangeName = (value) => {
    setMessage(null);
    setName(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeCode = (value) => {
    setMessage(null);
    setCode(value);
  };

  const handleSaveData = (value) => {
    const formData = {
      category: category.value,
      description: description.value,
      code: code.value,
    };
    handleChangeData(formData);
  };

  let isValueNOTChanged =
    category.value === initialValue.category &&
    code.value === initialValue.code;

  let disable =
    category.error ||
    code.error ||
    category.value === "" ||
    code.value === "" ||
    (category.success === false && code.success === false) ||
    isValueNOTChanged;

  let isValueNOTChangededit =
    category.value === initialValue.category &&
    // description.value === initialValue.description &&
    code.value === initialValue.code;

  let Editdisable =
    category.error ||
    code.error ||
    // description.error ||
    category.value === "" ||
    // description.value === "" ||
    code.value === "" ||
    (category.success === false &&
      // description.success === false &&
      code.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createHeader : editHeader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        starProps={true}
        type="textarea"
        label={nameLabel}
        placeholder={nameplaceholder}
        Value={category.value}
        onChangeText={handleChangeName}
        Validate={true}
        CustomErrorLine={nameCustomError}
        editable
        maxLength={30}
      />
      <FormTextInput
        type="textarea"
        label={DescriptionLabel}
        placeholder={Descriptionplaceholder}
        Value={description.value}
        onChangeText={handleChangeDescription}
        Validate={false}
        CustomErrorLine={DescriptionCustomError}
        multiline={true}
        editable
        maxLength={50}
      />
      <FormTextInput
        starProps={true}
        type="textarea"
        label={CodeLabel}
        placeholder={Codeplaceholder}
        Value={code.value}
        onChangeText={handleChangeCode}
        Validate={true}
        CustomErrorLine={CodeCustomError}
        maxLength={30}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : updateButton}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : false}
          // disable={disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
          }}
        />
      </div>
    </div>
  );
};

export default VendorCategoryForm;
