/* eslint-disable no-unused-vars */
import _ from "lodash";
import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { GetActiveFunctionality, GetActiveRole } from "../../../axios";
import { Button, FormCheckBox, FormDropDown } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import { default as Strings, default as strings } from "../../../translations";

const CreateMappingRoleFunctionForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleResultData,
    actionType,
    initialValue = {},
    handleModalClose,
  } = props;
  const [functions, setFunctions] = React.useState([]);
  const [inititalFunctions] = React.useState(
    initialValue.functions ? [...initialValue.functions] : []
  );
  const [name, setName] = React.useState({
    value: initialValue.id ? initialValue.id : "",
    error: false,
    success: false,
  });
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    GetActiveRole()
      .then((res) => {
        setLoading(false);
        const allData = res.data.data;
        const result = allData.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setOptions(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    if (actionType === "add") {
      GetActiveFunctionality()
        .then((res) => {
          setLoading(false);
          let allData = res.data.data;
          const result = allData.map((elm) => ({
            moduleName: elm.modules.name,
            key: elm.id,
            code: elm.code.substring(0, elm.code.indexOf("-")),
            functionName: elm.name,
            checked: false,
          }));
          setFunctions(result);
        })
        .catch((e) => {
          setLoading(false);
          setMessage(e);
        });
    }
  }, []);

  React.useEffect(() => {
    setLoading(true);
    if (actionType === "edit") {
      const arr = initialValue.function;
      const checkArr = { checked: true };
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var item_id_list = [];
      arr.map((ele) => item_id_list.push(ele.id));
      GetActiveFunctionality()
        .then((res) => {
          setLoading(false);
          const fetchData = res.data.data.map((ele) => ({
            moduleName: ele.modules.name,
            key: ele.id,
            code: ele.code.substring(0, ele.code.indexOf("-")),
            functionName: ele.name,
            checked: item_id_list.includes(ele.id) ? true : false,
          }));
          setFunctions(fetchData);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, []);

  const handleChangeCheck = (key) => {
    const result = functions.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setFunctions(result);
  };

  const handleSelectDropDown = (value) => {
    setMessage(null);
    setName(value);
  };

  const functionResultTrue = (functions) => {
    const resultArray = [];
    functions.forEach((func) => {
      if (func.checked === true) {
        resultArray.push(func.key);
      }
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      role_id: name.value,
      functionality_id: functionResultTrue(functions),
    };
    handleResultData(formData);
  };

  const disable =
    name.value === "" ||
    name.error ||
    name.success === false ||
    functions.every((functionName) => {
      return !functionName.checked;
    });

  const editDisable =
    initialValue.name === name.value && _.isEqual(functions, inititalFunctions);

  const roleFunctionmappingHaeder =
      Strings["RoleFunctionMapping.createrolemappingfunctionHeader"],
    roleEditFunctionmappingHaeder =
      Strings["RoleFunctionMapping.editrolemappingfunctionHeader"],
    selctRoleLabel =
      Strings["RoleFunctionMapping.createrolemappingfunctionselectRolelabel"],
    SelectRolefunctionmappingcutonerror =
      Strings[
        "RoleFunctionMapping.createrolemappingfunctionselectRolecustomerror"
      ],
    selctFunctionHeader =
      Strings[
        "RoleFunctionMapping.createrolemappingfunctionselectFunctionHeader"
      ],
    SaveButton = Strings["RoleFunctionMapping.craeteRoleSaveButton"],
    UpdateButton = Strings["RoleFunctionMapping.craeteRoleUpdateButton"],
    cancelButton = Strings["RoleFunctionMapping.createRoleCancelButton"];
  const handleSelect = (e) => {
    const data = functions.map((ele) => {
      return { ...ele, checked: e.target.checked };
    });
    setFunctions(data);
  };

  React.useEffect(() => {
    const data = functions.filter((e) => e.checked === false).length === 0;
    setIsChecked(data);
  }, [functions]);

  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <h2>
        {actionType === "add"
          ? roleFunctionmappingHaeder
          : roleEditFunctionmappingHaeder}
      </h2>
      <FormDropDown
        Label={selctRoleLabel}
        Options={options}
        onSelect={handleSelectDropDown}
        customErrorLine={SelectRolefunctionmappingcutonerror}
        searchable={false}
        value={name.value}
        placeholder="Search"
        disable={actionType !== "add" ? true : false}
        starProps={true}
        isValidate={true}
      />
      <br />

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onChange={handleSelect}
          checked={isChecked}
        />
        <label class="form-check-label" for="flexCheckDefault">
          Select All
        </label>
      </div>

      <h4>
        {selctFunctionHeader}
        <span className="text-danger">*</span>
      </h4>
      <div className={"row"}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="userRole">
          <Row>
            <Col
              sm={3}
              style={{
                overflow: "auto",
                height: "calc(100vh - 200px)",
              }}
            >
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="userRole">
                    {strings["RoleManagement.searchRoleTitle"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="function">
                    {strings["MasterConfiguration.functions"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="projectRole">
                    {strings["MasterConfiguration.projectRole"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="module">
                    {strings["MasterConfiguration.module"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="roleFunctionMapping">
                    {strings["MasterConfiguration.roleFunctionMapping"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="userRoleMapping">
                    {strings["MasterConfiguration.userRoleMapping"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="clientType">
                    {strings["MasterConfiguration.clientType"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="userType">
                    {strings["MasterConfiguration.userType"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="country">
                    {
                      strings[
                        "MaseterConfiguration.masterconfigurecountrylabele"
                      ]
                    }
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="establishment">
                    {
                      strings[
                        "MaseterConfiguration.masterconfigureEstablishmentlabele"
                      ]
                    }
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="itemCategory">
                    {strings["MasterConfiguration.itemCategory"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="budgetScope">
                    {strings["MasterConfiguration.budgetScope"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="scheduleSteps">
                    {strings["MasterConfiguration.scheduleSteps"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="requiredDocument">
                    {strings["MasterConfiguration.requiredDocument"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="modeOfPayment">
                    {strings["MasterConfiguration.modeOfPayment"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="stage">
                    {strings["MasterConfiguration.stage"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vendor">
                    {strings["MasterConfiguration.vendor"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="userManagement">
                    {strings["MasterConfiguration.userManagement"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="projectScope">
                    {strings["MasterConfiguration.projectScope"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="client">
                    {strings["MasterConfiguration.client"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vendorCategory">
                    {strings["MasterConfiguration.vendorCategory"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vendorCompanyType">
                    {strings["MasterConfiguration.vendorCompany"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="productAndService">
                    {strings["MasterConfiguration.productService"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="commodities">
                    {strings["MasterConfiguration.commodities"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vendorSubCategory">
                    {strings["MasterConfiguration.vendorSubCategory"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vendorCategoryToSubCategoryMapping">
                    {
                      strings[
                        "MasterConfiguration.vendorCotegorySubCategoryMap"
                      ]
                    }
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vendorCategoryToProductMapping">
                    {strings["MasterConfiguration.vendorCotegoryProductMap"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="roomType">
                    {strings["MasterConfiguration.roomType"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="publicArea">
                    {strings["MasterConfiguration.publicArea"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="currency">
                    {strings["MasterConfiguration.currency"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="unitOfMeasurement">
                    {strings["MasterConfiguration.uom"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="requiredSubmittal">
                    {strings["MasterConfiguration.requiredSubmittals"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="modeOfShipment">
                    {strings["MasterConfiguration.mos"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="location">
                    {strings["MasterConfiguration.location"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="incoterm">
                    {strings["MasterConfiguration.Incoterm"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="accessedAndConsideration">
                    {strings["MasterConfiguration.acessConsideraion"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="modeOfTransportation">
                    {strings["MasterConfiguration.mot"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="budgetScopeToItemCategoryMapping">
                    {strings["MasterConfiguration.budgetScopeItemCategoryMap"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="paymentTerms">
                    {strings["MasterConfiguration.paymentTerms"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="finalPaymentAgainst">
                    {strings["MasterConfiguration.finalPayment"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="paymentProgress">
                    {strings["MasterConfiguration.paymentProgress"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="itemGroup">
                    {strings["MasterConfiguration.itemGroup"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="itemSubGroup">
                    {strings["MasterConfiguration.itemSubGroup"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="itemSubCategory">
                    {strings["MasterConfiguration.itemSubCategory"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="establishmentToItemCategory">
                    {strings["MasterConfiguration.establishmentCategoryMap"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="itemCategoryToItemSubCategoryMapping">
                    {strings["MasterConfiguration.itemCategorySubCategoryMap"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="operator">
                    {strings["MasterConfiguration.operator"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="master">
                    {strings["MasterConfigurationText"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="general">
                    {strings["MasterConfiguration.cardTitleHeader"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="project">
                    {strings["MasterConfiguration.project"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="budget">
                    {strings["Title.Budget"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="specificUser">
                    {strings["ProjectSpecificUser.ProjectUserTitle"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="budgetItem">
                    {strings["SearchBudgetItem.header"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="itemSchedule">
                    {strings["ItemSchedule.itemScheduleHeader"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="rfi">{strings["Title.RFI"]}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="rfq">{strings["Title.RFQ"]}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="quotation">
                    {strings["Title.Quotation"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="costAnalysis">
                    {strings["CostAnalysis.searchFunctionTitle"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="purchaseOrder">
                    {strings["BudgetItem.TablePOLabel"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="invoice">{strings["Invoice"]}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="invoicePayment">
                    {strings["Payment.SearchPaymentHeader"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vendorAccess">{"Vendor Access"}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="userRole">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "RoleController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="function">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "FunctionalityController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="projectRole">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ProjectRoleController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="module">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ModuleController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="roleFunctionMapping">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code === "RoleFunctionalityMappingController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="userRoleMapping">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "RoleIdentityMappingController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="clientType">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ClientTypeController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="userType">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "UserTypeController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="country">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "CountryController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="establishment">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "EstablishmentController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="itemCategory">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ItemCategoryController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="budgetScope">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "BudgetScopeController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="scheduleSteps">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ScheduleController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="requiredDocument">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "RequiredDocumentController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="modeOfPayment">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ModeOfPaymentController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="stage">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "StageController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="vendor">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "VendorController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="userManagement">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "UserController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="projectScope">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ProjectScopeController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="client">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ClientController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="vendorCategory">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "VendorCategoryController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="vendorSubCategory">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "VendorSubCategoryController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="vendorCompanyType">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "CompanyTypeController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="productAndService">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "ProductServicesController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="commodities">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "CommodityController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="vendorCategoryToSubCategoryMapping">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code ===
                          "VendorCategorySubCategoryMappingController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="vendorCategoryToProductMapping">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code ===
                          "ProductCategorySubCategoryMappingController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="roomType">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "RoomTypeController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="publicArea">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "PublicAreaController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="currency">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "CurrencyController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="unitOfMeasurement">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "UOMController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="requiredSubmittal">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "RequiredSubmittalsController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="modeOfShipment">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ShipmentModeController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="location">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "LocationController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="incoterm">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "IncotermController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="accessedAndConsideration">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code === "AccessedConsiderationController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="modeOfTransportation">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "TransportationModeController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="budgetScopeToItemCategoryMapping">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code === "BudgetItemCategoryMapingController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="paymentTerms">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "PaymentTermsController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="finalPaymentAgainst">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "FinalPaymentAgainstController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="paymentProgress">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "PaymentProgressController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="itemGroup">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ItemGroupController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="itemSubGroup">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ItemSubGroupController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="itemSubCategory">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "ItemSubCategoryController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="establishmentToItemCategory">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code ===
                          "EstablishmentToItemCategoryMappingController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="itemCategoryToItemSubCategoryMapping">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code ===
                          "ItemCategorySubCategoryMappingController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="operator">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "OperatorController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="master">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "MasterController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="general">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "GeneralController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="project">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ProjectController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="budget">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "BudgetController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="specificUser">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "ProjectSpecificUserController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="budgetItem">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "BudgetItemController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="itemSchedule">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "ItemScheduleController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="rfi">
                  <div className="row">
                    {functions
                      .filter(
                        (item) =>
                          item.code === "RequestForInformationController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="rfq">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "RequestForQuotationController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="quotation">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "QuotationController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="costAnalysis">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "CostAnalysisController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="purchaseOrder">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "PurchaseOrderController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="invoice">
                  <div className="row">
                    {functions
                      .filter((item) => item.code === "InvoiceController")
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="invoicePayment">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.code === "InvoicePaymentController"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="vendorAccess">
                  <div className="row">
                    {functions
                      .filter(
                        (item) => item.moduleName === "Vendor Login Module"
                      )
                      .map((item, index) => {
                        return (
                          <div className={"col-12 col-md-6"} key={index}>
                            <FormCheckBox
                              key={item.key}
                              Label={item.functionName}
                              checked={item.checked}
                              handleChangeCheck={() =>
                                handleChangeCheck(item.key)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      <div className="row deleteFunction__buttongroup">
        <div style={{ display: "flex", marginTop: "30px" }}>
          <Button
            buttonName={actionType === "add" ? SaveButton : UpdateButton}
            disable={actionType === "add" ? disable : editDisable}
            onClick={handleSaveData}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
          <Button
            buttonName={cancelButton}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateMappingRoleFunctionForm;
