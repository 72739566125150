/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const IncotermForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createTitle = Strings["Incoterm.CreateForm"],
    editTitle = Strings["Incoterm.EditForm"],
    IncotermLabel = Strings["Incoterm.IncotermModeLabel"],
    IncotermPlaceholder = Strings["Incoterm.IncotermModePlaceHolder"],
    IncotermCustomError = Strings["Incoterm.IncotermModeError"],
    IncotermDescriptionLabel = Strings["Incoterm.IncotermModeDescriptionLabel"],
    IncotermDescriptionPlaceholder =
      Strings["Incoterm.IncotermModeDescriptionPlaceHolder"],
    IncotermDescriptionCustomError =
      Strings["Incoterm.IncotermModeDescriptionError"],
    IncotermSaveBtn = Strings["Incoterm.SaveBtn"],
    IncotermUpdateBtn = Strings["Incoterm.UpdateBtn"],
    IncotermCancelBtn = Strings["Incoterm.CancelBtn"];

  const [incotermName, setIncotermName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [incotermDescrition, setIncotermDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const handlencotermLocationName = (value) => {
    setMessage(null);
    setIncotermName(value);
  };

  const handleChangeIncotermDescription = (value) => {
    setIncotermDescription(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: incotermName.value,
      description: incotermDescrition.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged = incotermName.value === initialValue.name;
  // incotermDescrition.value === initialValue.description;
  let disable =
    incotermName.error ||
    // incotermDescrition.error ||
    incotermName.value === "" ||
    // incotermDescrition.value === "" ||
    incotermName.success === false ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    incotermName.value === initialValue.name &&
    incotermDescrition.value === initialValue.description;
  let Editdisable =
    incotermName.error ||
    incotermDescrition.error ||
    incotermName.value === "" ||
    incotermDescrition.value === "" ||
    (incotermName.success === false && incotermDescrition.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createTitle : editTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        label={IncotermLabel}
        placeholder={IncotermPlaceholder}
        Value={incotermName.value}
        onChangeText={handlencotermLocationName}
        Validate={true}
        CustomErrorLine={IncotermCustomError}
        starProps={true}
        maxLength={50}
        editable
      />

      <FormTextInput
        type="text"
        label={IncotermDescriptionLabel}
        placeholder={IncotermDescriptionPlaceholder}
        Value={incotermDescrition.value}
        onChangeText={handleChangeIncotermDescription}
        Validate={false}
        CustomErrorLine={IncotermDescriptionCustomError}
        multiline={true}
        starProps={false}
        maxLength={100}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? IncotermSaveBtn : IncotermUpdateBtn
          }
          disable={actionType === "add" ? disable : disable}
          buttonStyle={{ backgroundColor: colors.primary }}
          onClick={handleSaveData}
        />
        <Button
          buttonName={IncotermCancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default IncotermForm;
