import React from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import "../../../styles/ProfilePage.css";
import {
  BreadCrumbs,
  ChangeImageModal,
  Modal,
  IconButton,
  AddIcon,
  SearchTable,
} from "../../../components";
import Strings from "../../../translations";
import "../../../styles/tablepagination.css";
import "../../../styles/imageHover.css";
import { UserForm } from "../../../forms";

const userData = {
  userId: "John",
  userName: "John Doe",
  emailAddress: "JohnDoe@gmail.com",
  phoneNumber: "0998876543",
  userType: "Admin",
  company: "Vendor",
  activeStatus: true,
};

const myData = [
  {
    id: 1,
    name: "projectA",
    scope: "FF&E",
    activeStatus: true,
  },
  {
    id: 2,
    name: "projectB",
    scope: "OS&E",
    activeStatus: false,
  },
  {
    id: 3,
    name: "projectC",
    scope: "OS&E",
    activeStatus: true,
  },
  {
    id: 4,
    name: "projectD",
    scope: "FF&E",
    activeStatus: false,
  },
  {
    id: 5,
    name: "projectE",
    scope: "OS&E",
    activeStatus: true,
  },
];

const rows = myData;

function UserProfilePage() {
  const sr = Strings["UserProfile.TableHead.sr"],
    project = Strings["UserProfile.TableHead.project"],
    scope = Strings["UserProfile.TableHead.scope"],
    activeStatus = Strings["UserProfile.TableHead.activeStatus"];

  const [clickedBtn, setClickedBtn] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const paths = [
    {
      name: Strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: Strings["UserProfile"],
      path: "userprofile",
    },
  ];
  const mapableData = [
    {
      dataKey: "id",
      title: sr,
    },
    {
      dataKey: "name",
      title: project,
    },
    {
      dataKey: "scope",
      title: scope,
    },
    {
      dataKey: "activeStatus",
      title: activeStatus,
      isStatus: true,
    },
  ];

  const selectModal = () => {
    if (clickedBtn === "edit") {
      return (
        <UserForm
          actionType={"edit"}
          handleChangeRoleData={(val) => {
          }}
          initialValue={userData}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "image") {
      return <ChangeImageModal />;
    }
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div>
        <div className="profilebgdiv">
          <img
            className="profilebgimage"
            src="https://picsum.photos/1000/300"
            alt="profileimage"
          />
        </div>
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="profileimgdiv">
          <div className="profilediv1">
            <div
              className="profileimg"
              onClick={() => {
                setClickedBtn("image");
                openAddModal();
              }}
              style={{
                width: 210,
                height: 210,
                objectFit: "cover",
                borderRadius: "100%",
                border: "solid 5px #4747A1",
                backgroundImage: "url('https://picsum.photos/300/300')",
              }}
            >
              <label className="changepiclabel">
                <span>
                  <CameraAltIcon fontSize="large" />
                </span>
                <span>Change Image</span>
              </label>
            </div>
            <div className="profiledatadiv">
              <div className={"d-flex justify-content-between"}>
                <h3 className="profiledataname">Invincix</h3>

                <IconButton
                  onClick={() => {
                    setClickedBtn("edit");
                    openAddModal();
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
              <h6>www.invincix.com</h6>
              <h6>8249593116</h6>
              <h6>751024</h6>
              <h6>Tulsi Vihar, Sailashree vihar, Bhubaneswar</h6>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <BreadCrumbs pathList={paths} />

        <div className="row mt-2">
          <div className="col-12">
            <SearchTable
              data={rows}
              mapableData={mapableData}
              searchProp={["name"]}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfilePage;
