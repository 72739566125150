/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ClientTypeForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createclientTypeheader = Strings["ClientType.CreateClientTypeHeader"],
    editclientTypeheader = Strings["ClientType.EditClientTypeHeader"],
    clientTypeNameLabel = Strings["ClientType.CreateClientTypeLable"],
    clientTypeNameplaceholder =
      Strings["ClientType.CreateClientTypePlaceholder"],
    clientTypeNameCustomError =
      Strings["ClientType.CreateClientTypeCustomLineError"],
    clientDescriptionLabel = Strings["ClientType.CreateClientDescriptionLable"],
    clientDescriptionplaceholder =
      Strings["ClientType.CreateClientDescriptionPlaceholder"],
    clientDescriptionCustomError =
      Strings["ClientType.createClientDescriptionCustomLineError"],
    clientTagLabel = Strings["ClientType.CreateClientTagLable"],
    clientTagplaceholder = Strings["ClientType.CreateClientTagPlaceholder"],
    clientTagCustomError = Strings["ClientType.createClientTagCustomLineError"],
    cancelBtn = Strings["ClientType.createClientCancelBtn"],
    saveBtn = Strings["ClientType.createClientSaveBtn"],
    updateBtn = Strings["ClientType.EditClientUpdateBtn"];

  const [clientTypeName, setClientTypeName] = useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [clientTypeDescription, setClientTypeDescription] = useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [clientTypeTag, setClientTypeTag] = useState({
    value: initialValue.tag ? initialValue.tag : "",
    error: false,
    status: false,
  });

  const handleChangeClientType = (value) => {
    setMessage(null);
    setClientTypeName(value);
  };

  const handleChangeClientTypeDescription = (value) => {
    setClientTypeDescription(value);
  };

  const handleChangeClientTag = (value) => {
    setMessage(null);
    setClientTypeTag(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: clientTypeName.value,
      description: clientTypeDescription.value,
      tag: clientTypeTag.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged =
    clientTypeName.value === initialValue.clientTypeName &&
    clientTypeTag.value === initialValue.clientTypeTag;

  let disable =
    clientTypeName.error ||
    clientTypeTag.error ||
    clientTypeName.value === "" ||
    clientTypeTag.value === "" ||
    (clientTypeName.success === false && clientTypeTag.success === false) ||
    isValueNOTChanged;

  let isValueNOTChangedEdit =
    clientTypeName.value === initialValue.clientTypeName &&
    clientTypeDescription.value === initialValue.clientTypeDescription &&
    clientTypeTag.value === initialValue.clientTypeTag;

  let Editdisable =
    clientTypeName.error ||
    clientTypeDescription.error ||
    clientTypeTag.error ||
    clientTypeName.value === "" ||
    clientTypeDescription.value === "" ||
    clientTypeTag.value === "" ||
    (clientTypeName.success === false &&
      clientTypeDescription.success === false &&
      clientTypeTag.success === false) ||
    isValueNOTChangedEdit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add" ? createclientTypeheader : editclientTypeheader}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        starProps={true}
        label={clientTypeNameLabel}
        placeholder={clientTypeNameplaceholder}
        Value={clientTypeName.value}
        onChangeText={handleChangeClientType}
        Validate={true}
        CustomErrorLine={clientTypeNameCustomError}
        editable
      />
      <FormTextInput
        type="textarea"
        // starProps={true}
        label={clientDescriptionLabel}
        placeholder={clientDescriptionplaceholder}
        Value={clientTypeDescription.value}
        onChangeText={handleChangeClientTypeDescription}
        // Validate={actionType === "add" ? false : true}
        Validate={false}
        CustomErrorLine={clientDescriptionCustomError}
        multiline={true}
        editable
      />

      <FormTextInput
        type="textarea"
        starProps={true}
        label={clientTagLabel}
        placeholder={clientTagplaceholder}
        Value={clientTypeTag.value}
        onChangeText={handleChangeClientTag}
        Validate={true}
        CustomErrorLine={clientTagCustomError}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveBtn : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />

        <Button
          buttonName={cancelBtn}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default ClientTypeForm;
