/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { Vendor } from "../../assets";
import { colors } from "../../configs";
import "../../styles/DashboardCard.css";

const VendorDashboardCard = (props) => {
  const { cardtitle, count, percentage, days, color, url, icon } = props;
  return (
    <div className="col  stretch-card transparent">
      <Link
        to={{
          pathname: url,
        }}
      >
        <div className="card card-tale">
          <div
            className="card-body top-card"
            style={{ backgroundColor: color }}
          >
            <div className="row">
              <div className="col-md-8 text-md-start">
                <h6
                  className="mb-3"
                  style={{ fontSize: "14px", lineHeight: "1.3rem" }}
                >
                  {cardtitle}
                </h6>
                {/* {props.icon} */}

                <h3 className="mb-2" style={{ fontSize: "50px", color: colors.dasbordtextColor }}>
                  {count}
                </h3>
              </div>

              <div
                className="col-md-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={Vendor} alt="login" className="img-Vendor" />
              </div>
            </div>

            {/* <p>{percentage}({days})</p> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default VendorDashboardCard;
