/* eslint-disable no-unused-vars */
import React from "react";
import { BudgetItemImageUpload } from "../../../axios";
import { Button, ChangeImageModal } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
import swal from "sweetalert";

const BudgetItemimageUpload = (props) => {
  const {
    errorMessage,
    initialValue = {},
    actionType,
    handleModalClose,
    selectedItem,
  } = props;

  const cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];


  const [imageData, setImageData] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);

  const [image] = React.useState(
    initialValue.profile_picture ? initialValue.profile_picture : null
  );

  const sendDataToParent = (value) => {
    setImageData(value);
  };

  const handleSaveData = () => {
    const ItemId = selectedItem.map((elem) => {
      return elem.id;
    });
    setisLoader(true);
    BudgetItemImageUpload(ItemId, { file_path: imageData })
      .then((res) => {
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        swal({
          title: "Success!",
          text: e.response.data.message,
          icon: "success",
        });
      });
  };

  return (
    <div className="modalFormContainer">
      <h2 className="mb-3 text-center">{"Budget Item Image Upload"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <div className="row mt-4">
        <ChangeImageModal
          sendDataToParent={sendDataToParent}
          showHeader={false}
          imageResolution={[180, 180]}
          initialImage={image}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "-34px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          buttonName={cancelButton}
          buttonStyle={{
            backgroundColor: colors.darkgrey,
          }}
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          className="ms-2"
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          // disable={disable}
          onClick={handleSaveData}
        />
      </div>
    </div>
  );
};

export default BudgetItemimageUpload;
