/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { useEffect } from "react";
import {
  GetAllActiveEstablishment,
  GetAllActiveItemCategory,
} from "../../../axios";
import { Button, FormCheckBox, FormDropDown } from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const CreateEstabishmentToItemCategoryMappingForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleResultData,
    actionType,
    initialValue = {},
    handleModalClose,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [roles, setRoles] = React.useState([]);

  const [initialRoles] = React.useState([]);

  const [budgetDropdown, setBudgetDropdown] = React.useState([]);

  const [selectData, setSelectData] = React.useState({
    value: initialValue.id ? initialValue.id : "",
    error: false,
    success: false,
  });

  const getActiveEstablishment = () => {
    setLoading(true);
    GetAllActiveEstablishment()
      .then((res) => {
        setLoading(false);
        let allData = res.data.data;
        const result = allData.map((elm) => ({
          key: elm.id,
          value: elm.id,
          label: elm.name,
        }));
        setBudgetDropdown(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getActiveEstablishment();
  }, []);

  React.useEffect(() => {
    if (actionType === "add") {
      setLoading(true);
      GetAllActiveItemCategory()
        .then((res) => {
          setLoading(false);
          let allData = res.data.data;
          const result = allData.map((elm) => ({
            key: elm.id,
            itemName: elm.name,
            checked: false,
          }));
          setRoles(result);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (actionType === "edit") {
      setLoading(true);
      const arr = initialValue.itemcategories;
      const checkArr = { checked: true };
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign(arr[i], checkArr);
      }
      var item_id_list = [];
      const result = arr.map((ele) => item_id_list.push(ele.id));
      GetAllActiveItemCategory()
        .then((res) => {
          setLoading(false);
          const fetchData = res.data.data.map((ele) => ({
            key: ele.id,
            itemName: ele.name,
            checked: item_id_list.includes(ele.id) ? true : false,
          }));
          setRoles(fetchData);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleChangeCheck = (key) => {
    const result = roles.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setRoles(result);
  };

  const handleSelectDropDown = (id) => {
    setMessage(null);
    setSelectData(id);
  };

  const functionResultTrue = (roles) => {
    const resultArray = [];
    roles.forEach((func) => {
      if (func.checked === true) {
        resultArray.push(func.key);
      }
    });
    return resultArray;
  };

  const handleSaveData = () => {
    const formData = {
      establishment_id: selectData.value,
      item_category_ids: functionResultTrue(roles),
    };
    handleResultData(formData);
  };

  const title = Strings["BudgetMasterConfiguration.createTitle"],
    editpageHeader = Strings["BudgetMasterConfiguration.EditHeader"],
    selectText = Strings["BudgetMasterConfiguration.SelectText"],
    dropdownLabel = Strings["BudgetMasterConfiguration.createDropdownLabel"],
    dropdownCustomError =
      Strings["BudgetMasterConfiguration.createDropdownCustomError"],
    selctRoleHeader = Strings["BudgetMasterConfiguration.selectRoleHeader"],
    SaveButton = Strings["BudgetMasterConfiguration.createSaveButton"],
    cancelButton = Strings["BudgetMasterConfiguration.createCancelButton"];

  const disable =
    selectData.value === "" ||
    selectData.error ||
    selectData.success === false ||
    roles.every((functionName) => {
      return !functionName.checked;
    });

  const editDisable =
    initialValue.scope === selectData.value && _.isEqual(roles, initialRoles);

  return (
    <div className="modalFormContainer">
      {loading ? <LoaderComponent /> : null}
      <h2>{actionType === "add" ? title : editpageHeader}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormDropDown
        selectText={selectText}
        Label={"Select Establishment"}
        Options={budgetDropdown}
        onSelect={handleSelectDropDown}
        customErrorLine={dropdownCustomError}
        searchable={false}
        disable={actionType !== "add" ? true : false}
        value={selectData.value}
        isValidate={true}
      />
      <br />
      <h4>{selctRoleHeader}</h4>
      <div className={"row"}>
        {roles.map((functionName, index) => {
          return (
            <div className={"col-12 col-md-4"} key={index}>
              <FormCheckBox
                key={functionName.key}
                Label={functionName.itemName}
                checked={functionName.checked}
                handleChangeCheck={() => handleChangeCheck(functionName.key)}
              />
            </div>
          );
        })}
      </div>

      <div className="row deleteFunction__buttongroup">
        <div style={{ display: "flex", marginTop: "30px" }}>
          <Button
            buttonName={SaveButton}
            disable={actionType === "add" ? disable : editDisable}
            onClick={handleSaveData}
            buttonStyle={{ backgroundColor: colors.primary }}
          />
          <Button
            buttonName={cancelButton}
            buttonStyle={{
              backgroundColor: colors.darkgrey,
              marginLeft: "10px",
            }}
            onClick={() => {
              handleModalClose();
              setMessage(null);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateEstabishmentToItemCategoryMappingForm;
