/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const ProjectScopeForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createProjectScopeheader =
      Strings["ProjectScope.CreateProjectScopeHeader"],
    EditProjectScopeheader = Strings["ProjectScope.EditProjectScopeHeader"],
    ScopeNameLabel = Strings["ProjectScope.CreateProjectScopeScopeNameLable"],
    ScopeNameplaceholder =
      Strings["ProjectScope.CreateProjectScopeScopeNamePlaceHolder"],
    ProjectScopeCustomError =
      Strings["ProjectScope.CreateProjectScopeScopeNameCustomLineError"],
    ProjectScopeCodeLabel =
      Strings["ProjectScope.CreateProjectScopeScopeCodeLable"],
    ProjectScopeCodeplaceholder =
      Strings["ProjectScope.CreateProjectScopeScopeCodePlaceHolder"],
    ProjectsScopeCodeCustomError =
      Strings["ProjectScope.CreateProjectScopeScopeCodeCustomLineError"],
    ProjectScopeDescription =
      Strings["ProjectScope.CreateProjectScopeCodeDescriptionLabel"],
    ProjectScopeDescriptionPlaceHolder =
      Strings["ProjectScope.CreateProjectScopeDescriptionPlaceholder"],
    ProjectScopeDescriptionCustomError =
      Strings["ProjectScope.CreateProjectScopedescriptionCustomError"],
    saveButton = Strings["ProjectScope.CreaProjectScopeSaveButton"],
    cancelButton = Strings["ProjectScope.CraeteProjectScopeCancelButton"],
    updateButton = Strings["ProjectScope.CraeteProjectScopeUpdateButton"];

  const [projectName, setProjectName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [projectDescription, setProjectDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [projectScope, setProjectScope] = React.useState({
    value: initialValue.code ? initialValue.code : "",
    error: false,
    success: false,
  });

  const handleChangeProject = (value) => {
    setMessage(null);
    setProjectName(value);
  };

  const handleChangeDescription = (value) => {
    setProjectDescription(value);
  };

  const handleChangeSocpeCode = (value) => {
    setMessage(null);
    setProjectScope(value);
  };

  const handleSaveData = () => {
    const fromData = {
      name: projectName.value,
      description: projectDescription.value,
      code: projectScope.value,
    };
    handleChangeData(fromData);
  };

  let isValueNOTChanged =
    projectName.value === initialValue.name &&
    // projectDescription.value === initialValue.description &&
    projectScope.value === initialValue.code;

  let disable =
    projectName.error ||
    // projectDescription.error ||
    projectScope.error ||
    projectName.value === "" ||
    // projectDescription.value === "" ||
    projectScope.value === "" ||
    projectName.success === false ||
    // projectDescription.success === false ||
    projectScope.success === false ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    projectName.value === initialValue.name &&
    projectDescription.value === initialValue.description &&
    projectScope.value === initialValue.code;

  let Editdisable =
    projectName.error ||
    projectDescription.error ||
    projectScope.error ||
    projectName.value === "" ||
    projectDescription.value === "" ||
    projectScope.value === "" ||
    (projectName.success === false &&
      projectDescription.success === false &&
      projectScope.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add"
          ? createProjectScopeheader
          : EditProjectScopeheader}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="textarea"
        starProps={true}
        label={ScopeNameLabel}
        placeholder={ScopeNameplaceholder}
        Value={projectName.value}
        onChangeText={handleChangeProject}
        Validate={true}
        CustomErrorLine={ProjectScopeCustomError}
        editable={true}
        maxLength={50}
      />

      <FormTextInput
        starProps={true}
        type="textarea"
        label={ProjectScopeCodeLabel}
        placeholder={ProjectScopeCodeplaceholder}
        Value={projectScope.value}
        onChangeText={handleChangeSocpeCode}
        Validate={true}
        CustomErrorLine={ProjectsScopeCodeCustomError}
        editable={true}
      />
      <FormTextInput
        type="text"
        label={ProjectScopeDescription}
        placeholder={ProjectScopeDescriptionPlaceHolder}
        Value={projectDescription.value}
        onChangeText={handleChangeDescription}
        Validate={false}
        CustomErrorLine={ProjectScopeDescriptionCustomError}
        multiline={true}
        editable={true}
        maxLength={30}
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={actionType === "add" ? saveButton : updateButton}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : false}
          onClick={handleSaveData}
        />
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default ProjectScopeForm;
