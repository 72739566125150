/* eslint-disable no-unused-vars */
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import swal from "sweetalert";
import {
  CreateRoleFunctionality,
  GetRoleFunctionality,
  UpdateRoleFunctionality,
} from "../../../axios";
import {
  AddIcon,
  BreadCrumbs,
  FormTextInput,
  Modal,
  NodataFound,
  SearchMappingTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { CreateMappingRoleFunctionForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CREATE_ROLE_FUNCTIONALITY_MAP,
  UPDATE_ROLE_FUNCTIONALITY_MAP,
} from "../../../utils/constants";

const converter = (rows) => {
  let result = "";
  rows.forEach((data) => {
    if (result.length === 0) {
      result = result + data.name;
    } else {
      result = result + "," + data.name;
    }
  });
  return result;
};

export default function SearchMapingRoleFunctionPage() {
  const searchMapingRoleFunctionTitle =
      Strings["FunctionManagement.searchMapingRoleFunctionTitle"],
    roleName = Strings["RoleFunctionMapping.TableHead.roleName"],
    functionName = Strings["RoleFunctionMapping.TableHead.functionName"],
    action = Strings["RoleFunctionMapping.TableHead.action"];

  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentType, setCurrentType] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);

  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_ROLE_FUNCTIONALITY_MAP)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_ROLE_FUNCTIONALITY_MAP)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: Strings["RoleFunctionMapping"],
      path: "searchmapingrolefunction",
    },
  ];

  const mapableData = [
    {
      dataKey: "name",
      title: roleName,
    },
    {
      dataKey: "function",
      title: functionName,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const getAllRoleFunction = () => {
    setLoading(true);
    GetRoleFunctionality(null, null, searchValue.value)
      .then((res) => {
        setRows(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getAllRoleFunction();
  }, []);
  const createRoleFunction = (formData) => {
    setLoading(true);
    CreateRoleFunctionality(formData)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Created Succesfully",
            icon: "success",
          });
          getAllRoleFunction();
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };
  const updateRoleFunction = (formData) => {
    setLoading(true);
    UpdateRoleFunctionality(formData)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Updated Sucessfully",
            icon: "success",
          });
          getAllRoleFunction();
          handleModalClose();
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.message);
      });
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <CreateMappingRoleFunctionForm
          errorMessage={errorMessage}
          setMessage={setMessage}
          handleResultData={createRoleFunction}
          actionType="add"
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <CreateMappingRoleFunctionForm
          errorMessage={errorMessage}
          setMessage={setMessage}
          handleResultData={updateRoleFunction}
          actionType="edit"
          initialValue={currentType}
          handleModalClose={handleModalClose}
        />
      );
    }
  };

  const getData = (rows) => {
    const result = rows.map((data) => {
      return {
        ...data,
        function: converter(data.function),
      };
    });
    return result;
  };

  const searchTableData = getData(rows);

  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id.id;
    });
    setCurrentType(result);
    handleSelectBtn("edit");
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setLoading(true);
      GetRoleFunctionality(null, null, searchValue.value)
        .then((res) => {
          setRows(res.data.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAllRoleFunction();
  };
  return (
    <>
      <div className="content-wrapper p-0 ">
        {loading ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchMapingRoleFunctionTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="UserbudgetTable">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length > 0 ? (
                  <>
                    <SearchMappingTable
                      isUpdate={isUpdate}
                      placeholder={"Role Name"}
                      data={searchTableData}
                      mapableData={mapableData}
                      searchProp={["name"]}
                      handleChangeEdit={handleChangeEdit}
                    />
                  </>
                ) : (
                  <>
                    {loading ? (
                      <LoaderComponent />
                    ) : (
                      <NodataFound
                        onClick={() => {
                          openAddModal();
                          handleSelectBtn("add");
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
