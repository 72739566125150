/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const PublicAreaForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    handleChangeFormDataes = () => {},
  } = props;

  const createPublicAreaTitle = Strings["PublicArea.CreatePublicAreaForm"],
    editPublicAreaTitle = Strings["PublicArea.EditPublicAreaForm"],
    publicAreaLabel = Strings["PublicArea.PublicAreaLabel"],
    publicAreaPlaceholder = Strings["PublicArea.PublicAreaPlaceHolder"],
    publicAreaCustomError = Strings["PublicArea.PublicAreaError"],
    publicAreaNoteLabel = Strings["PublicArea.PublicAreaNoteLabel"],
    publicAreaNotePlaceholder = Strings["PublicArea.PublicAreaNotePlaceHolder"],
    publicAreaNoteCustomError = Strings["PublicArea.PublicAreaNoteError"],
    publicAreaSaveBtn = Strings["PublicArea.SaveBtn"],
    publicAreaUpdateBtn = Strings["PublicArea.UpdateBtn"],
    publicAreaCancelBtn = Strings["PublicArea.CancelBtn"];

  const [publicAreaName, setPublicAreaName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [typeNote, setTypeNote] = React.useState({
    value: initialValue.notes ? initialValue.notes : "",
    error: false,
    success: false,
  });

  const handleChangePublicAreaName = (value) => {
    setMessage(null);
    setPublicAreaName(value);
  };

  const handleChangeTypeNote = (value) => {
    setTypeNote(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: publicAreaName.value,
      notes: typeNote.value,
    };
    handleChangeFormData(formData);
    handleChangeFormDataes(formData);
  };

  let isValueChanged = publicAreaName.value === initialValue.name;
  // typeNote.value === initialValue.notes;
  let disable =
    publicAreaName.error ||
    typeNote.error ||
    publicAreaName.value === "" ||
    typeNote.value === "" ||
    publicAreaName.success === false ||
    typeNote.success === false ||
    isValueChanged;

  //edit
  let isValueChangededit =
    publicAreaName.value === initialValue.name &&
    typeNote.value === initialValue.notes;
  let Editdisable =
    publicAreaName.error ||
    typeNote.error ||
    publicAreaName.value === "" ||
    typeNote.value === "" ||
    (publicAreaName.success === false && typeNote.success === false) ||
    isValueChangededit;

  return (
    <div className="modalFormContainer">
      <h2>
        {actionType === "add" ? createPublicAreaTitle : editPublicAreaTitle}
      </h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="alpha"
        label={publicAreaLabel}
        placeholder={publicAreaPlaceholder}
        Value={publicAreaName.value}
        onChangeText={handleChangePublicAreaName}
        Validate={true}
        CustomErrorLine={publicAreaCustomError}
        starProps={true}
        editable
      />

      <FormTextInput
        type="text"
        label={publicAreaNoteLabel}
        placeholder={publicAreaNotePlaceholder}
        Value={typeNote.value}
        onChangeText={handleChangeTypeNote}
        Validate={false}
        CustomErrorLine={publicAreaNoteCustomError}
        starProps={true}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? publicAreaSaveBtn : publicAreaUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={publicAreaCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default PublicAreaForm;
