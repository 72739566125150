import React, { useState } from "react";
import "./PhoneStyle.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Style } from "./style.js";

const FormPhoneInput = (props) => {

    const {
        starProps,
        icon,
        type,
        placeholder,
        onChangeText,
        Validate,
        Hint,
        multiline,
        mainContainerStyle,
        inputStyle,
        labelTextStyle,
        customErrorLineStyle,
        labelContainerStyle,
        placeholderStyle,
        inputContainerStyle,
        editable = true,
        passwordVisibleIcon,
        customPasswordValidation,
        customEmailValidation,
        customWebsiteValidation,
        customPhoneValidation,
        inputName,
        passwordCustomErrorLine,
        ...input
    } = props;

    const { label, onChangePhone, Value, Required, CustomErrorLine } = props;

    const [name, setName] = useState({
        value: Value ? Value : "",
        error: false,
        success: false,
    });

    const [error, setError] = useState("");

    const handleChangePhone = (e) => {
        const data = e === undefined ? "" : e;
        if (Required) {
            if (data.length > 1) {
                if (!isValidPhoneNumber(data)) {
                    const value = {
                        value: data,
                        success: false,
                    };
                    onChangePhone(value);
                    setName(value);
                } else if (isValidPhoneNumber(data)) {
                    setError("");
                    const value = {
                        value: data,
                        success: true,
                    };
                    onChangePhone(value);
                    setName(value);
                }
            }
        } else if (!Required) {
            if (data.length > 1) {
                if (!isValidPhoneNumber(data)) {
                    const value = {
                        value: data,
                        success: false,
                    };
                    onChangePhone(value);
                    setName(value);
                } else {
                    setError("");
                    const value = {
                        value: data,
                        success: true,
                    };
                    onChangePhone(value);
                    setName(value);
                }
            } else {
                setError("");
                const value = {
                    value: data,
                    success: false,
                };
                onChangePhone(value);
                setName(value);
            }
        }
    };

    const handleBlurText = (e) => {
        const data = e.target.value;
        if (Required) {
            if (data.length <= 0) {
                setError("cannot be blank");
                const value = {
                    ...name,
                    error: true,
                    success: false,
                };
                onChangePhone(value);
            }
            else {
                if (!isValidPhoneNumber(data)) {
                    setError(CustomErrorLine ? CustomErrorLine : "Error Phone Number");
                    const value = {
                        ...name,
                        error: true,
                        success: false,
                    };
                    onChangePhone(value);
                } else {
                    setError("");
                    const value = {
                        ...name,
                        error: false,
                        success: true,
                    };
                    onChangePhone(value);
                }
            }
        }
        else if (!Required) {
            if (data.length > 0) {
                if (!isValidPhoneNumber(data)) {
                    setError(CustomErrorLine ? CustomErrorLine : "Error Phone Number");
                    const value = {
                        ...name,
                        error: true,
                        success: false,
                    };
                    onChangePhone(value);
                } else {
                    setError("");
                    const value = {
                        ...name,
                        error: false,
                        success: true,
                    };
                    onChangePhone(value);
                }
            } else {
                setError("");
                const value = {
                    ...name,
                    error: false,
                    success: true,
                };
                onChangePhone(value);
            }

        }
    };

    return (
        <div
            style={Object.assign({}, Style.mainContainerStyle, mainContainerStyle)}
        >
            <div style={labelContainerStyle}>
                {label && Required === true ? (
                    <>
                        <label style={{ ...Style.labelTextStyle, ...labelTextStyle }}>
                            {label}
                        </label>
                        <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </>
                ) : (
                    <label style={{ ...Style.labelTextStyle, ...labelTextStyle }}>
                        {label}
                    </label>
                )}
            </div>
            <div
                style={Object.assign(
                    {},
                    Style.inputContainerStyle,
                    inputContainerStyle,
                    error ? Style.inputContainerError : null
                )}
            >
                <PhoneInput
                    international
                    // defaultCountry="AE"
                    placeholder="Enter phone number"
                    value={Value ? Value : name.value ? name.value : ""}
                    onChange={handleChangePhone}
                    onBlur={(e) => {
                        handleBlurText(e);
                    }}
                    style={{
                        margin: "auto",
                        width: "100%",
                    }}
                    limitMaxLength={true}
                />
            </div>
            {error && (
                <div className="clearFix">
                    <div
                        className="float-right"
                        style={Object.assign(
                            {},
                            customErrorLineStyle
                                ? customErrorLineStyle
                                : Style.customErrorLineStyle
                        )}
                    >
                        {error ? error : CustomErrorLine}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormPhoneInput;
