/* eslint-disable no-unused-vars */
import React from "react";
import { Button, FormTextInput } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const SubmitalsForm = (props) => {
  const {
    errorMessage,
    setMessage,
    handleChangeFormData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
  } = props;

  const createSubMitalTitle = Strings["SubMital.CreateSubmitalForm"],
    editSubMitalTitle = Strings["SubMital.EditSubmitalForm"],
    SubMitalLabel = Strings["SubMital.SubmitalLabel"],
    SubMitalPlaceholder = Strings["SubMital.SubmitalPlaceHolder"],
    SubMitalCustomError = Strings["SubMital.SubmitalError"],
    SubMitalDescriptionLabel = Strings["SubMital.SubmitalDescriptionLabel"],
    SubMitalDescriptionPlaceholder =
      Strings["SubMital.SubmitalDescriptionPlaceHolder"],
    SubMitalDescriptionCustomError =
      Strings["SubMital.SubmitalDescriptionError"],
    SubMitalTagLabel = Strings["SubMital.SubmitalTagLabel"],
    SubMitalTagPlaceholder = Strings["SubMital.SubmitalTagPlaceHolder"],
    SubMitalTagCustomError = Strings["SubMital.SubmitalTagError"],
    SubMitalSaveBtn = Strings["SubMital.SaveBtn"],
    SubMitalUpdateBtn = Strings["SubMital.UpdateBtn"],
    SubMitalCancelBtn = Strings["SubMital.CancelBtn"];

  const [submitalName, setSubmitallName] = React.useState({
    value: initialValue.name ? initialValue.name : "",
    error: false,
    success: false,
  });

  const [submitalDescription, setSubmitallDescription] = React.useState({
    value: initialValue.description ? initialValue.description : "",
    error: false,
    success: false,
  });

  const [subMitalTag, setSubMitalTag] = React.useState({
    value: initialValue.tag ? initialValue.tag : "",
    error: false,
    success: false,
  });

  const handleChangeSubMitalName = (value) => {
    setMessage(null);
    setSubmitallName(value);
  };

  const handleChangeSubMitalDescription = (value) => {
    setSubmitallDescription(value);
  };

  const handleChangeSubMitalTag = (value) => {
    setMessage(null);
    setSubMitalTag(value);
  };

  const handleSaveData = () => {
    const formData = {
      name: submitalName.value,
      description: submitalDescription.value,
      tag: subMitalTag.value,
    };
    handleChangeFormData(formData);
  };

  let isValueNOTChanged =
    submitalName.value === initialValue.name &&
    // submitalDescription.value === initialValue.description &&
    subMitalTag.value === initialValue.tag;
  let disable =
    submitalName.error ||
    // submitalDescription.error ||
    subMitalTag.error ||
    submitalName.value === "" ||
    // submitalDescription.value === "" ||
    subMitalTag.value === "" ||
    (submitalName.success === false &&
      // submitalDescription.success === false &&
      subMitalTag.success === false) ||
    isValueNOTChanged;

  //edit
  let isValueNOTChangededit =
    submitalName.value === initialValue.name &&
    submitalDescription.value === initialValue.description &&
    subMitalTag.value === initialValue.tag;
  let Editdisable =
    submitalName.error ||
    submitalDescription.error ||
    subMitalTag.error ||
    submitalName.value === "" ||
    submitalDescription.value === "" ||
    subMitalTag.value === "" ||
    (submitalName.success === false &&
      submitalDescription.success === false &&
      subMitalTag.success === false) ||
    isValueNOTChangededit;

  return (
    <div className="modalFormContainer">
      <h2>{actionType === "add" ? createSubMitalTitle : editSubMitalTitle}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      <FormTextInput
        type="text"
        label={SubMitalLabel}
        placeholder={SubMitalPlaceholder}
        Value={submitalName.value}
        onChangeText={handleChangeSubMitalName}
        Validate={true}
        CustomErrorLine={SubMitalCustomError}
        starProps={true}
        maxLength={50}
        editable
      />

      <FormTextInput
        type="alpha"
        label={SubMitalDescriptionLabel}
        placeholder={SubMitalDescriptionPlaceholder}
        Value={submitalDescription.value}
        onChangeText={handleChangeSubMitalDescription}
        Validate={false}
        CustomErrorLine={SubMitalDescriptionCustomError}
        multiline={true}
        maxLength={200}
        editable
      />

      <FormTextInput
        type="alpha"
        label={SubMitalTagLabel}
        placeholder={SubMitalTagPlaceholder}
        Value={subMitalTag.value}
        onChangeText={handleChangeSubMitalTag}
        Validate={true}
        CustomErrorLine={SubMitalTagCustomError}
        starProps={true}
        maxLength={50}
        editable
      />

      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <Button
          buttonName={
            actionType === "add" ? SubMitalSaveBtn : SubMitalUpdateBtn
          }
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={actionType === "add" ? disable : disable}
          onClick={handleSaveData}
        />
        <Button
          buttonName={SubMitalCancelBtn}
          buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }}
          onClick={() => {
            handleModalClose();
            setMessage(null);
          }}
        />
      </div>
    </div>
  );
};

export default SubmitalsForm;
