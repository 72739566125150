import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { PropagateLoader } from "react-spinners";
import { logoRect } from "../../../assets";
import { GetAccountStatementReport } from "../../../axios";

const AccountStatement = (props) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      marginLeft: "25px",
      marginRight: "25px",
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-750px",
      fontSize: "8px",
    },
  });
  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [paymentValue, setPaymentValue] = React.useState();
  const [invoiceValue, setInvoiceValue] = React.useState();

  React.useEffect(() => {
    GetAccountStatementReport(id)
      .then((res) => {
        const paymentReqList = [];
        res.data.data.InvoicePaymentData.forEach((res) => {
          paymentReqList.push(...res.invoice_payment);
        });
        const reqPaymentVal = paymentReqList.reduce((acc, curr) => {
          return acc + parseInt(curr.amount);
        }, 0);
        const invVal = res.data.data.InvoicePaymentData.reduce((acc, curr) => {
          return acc + parseInt(curr.total_amount);
        }, 0);
        setInvoiceValue(invVal);
        setPaymentValue(reqPaymentVal);
        setReportData(res.data.data);
        setShows(true);
      })
      .catch((err) => {
        setShows(false);
      });
  }, [id]);

  return (
    <>
      {shows === true ? (
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  <Image style={styles.image} src={logoRect} />
                  <Text style={{ fontSize: "12px", display: "flex" }}>
                    Statement of Account
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }} fixed>
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    border: "1px solid black",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "13px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "11px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Project Name
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "11px", padding: "5px" }}
                    >
                      {reportData.budgetprojectData[0].project.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Budget Number
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      {reportData.budgetprojectData[0].serial_number +
                        " - " +
                        reportData.budgetprojectData[0].name}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Issuing Date
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      {
                        reportData.budgetprojectData[0].budget_start_date.split(
                          " "
                        )[0]
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginLeft: "25px", marginRight: "25px" }} fixed>
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    border: "1px solid black",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "13px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Forecast Amount
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "11px", padding: "5px" }}
                    >
                      AED {reportData.budgetprojectData[0].value}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Cash Requested to date
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      AED {invoiceValue}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Percentage of cash requested to date
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      {Math.ceil(
                        (invoiceValue / reportData.budgetprojectData[0].value) *
                          100
                      )}{" "}
                      %
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Payment made to date
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      AED {paymentValue}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Payment requested still due
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      AED {invoiceValue - paymentValue}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Payment still to be requested
                    </Text>
                    <Text
                      style={{ width: "40%", fontSize: "10px", padding: "5px" }}
                    >
                      AED {reportData.budgetprojectData[0].value - invoiceValue}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  marginLeft: "25px",
                  marginRight: "25px",
                  borderBottom: "1px solid black",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: "10px",
                    borderRight: "1px solid black",
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "8px",
                      backgroundColor: "#b8d4db",
                    }}
                  >
                    <View
                      style={{
                        width: "15%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}></Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>PO VALUE</Text>
                    </View>
                    <View
                      style={{
                        width: "7%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>CURRENCY</Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>CONVERTED PO VALUE</Text>
                    </View>
                    <View
                      style={{
                        width: "8%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>INVOICE DUE DATE</Text>
                    </View>
                    <View
                      style={{
                        width: "8%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>DATE OF PAYMENT</Text>
                    </View>
                    <View
                      style={{
                        width: "8%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>
                        ACTUAL INVOICE AMOUNT
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "10%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>
                        CONVERTED INVOICE AMOUNT
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "8%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>PAID AMOUNT</Text>
                    </View>
                    <View
                      style={{
                        width: "8%",
                        height: "100%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>BALANCE TO BE PAID</Text>
                    </View>
                    <View
                      style={{
                        width: "8%",
                        height: "100%",
                      }}
                    >
                      <Text style={{ margin: "auto" }}>
                        BALANCE TO BE INVOICED
                      </Text>
                    </View>
                  </View>
                </View>
                <View>
                  {reportData.summaryData.map((ele) => {
                    return (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            padding: "5px 3px",
                            borderRight: "1px solid black",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            backgroundColor: "#347380",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              fontSize: "9px",
                            }}
                          >
                            <Text>{ele.name}</Text>
                          </View>
                        </View>
                        {ele.purchase_order_invoice_payment.map((elem) => {
                          return (
                            <>
                              <View
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  borderRight: "1px solid black",
                                  borderLeft: "1px solid black",
                                  borderTop: "1px solid black",
                                  backgroundColor: "#bfbfbf",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    fontSize: "9px",
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "15%",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      {elem.purchase_order_serial_number}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "10.7%",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      AED {elem.total_price}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                      width: "7.2%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>AED</Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                      width: "10.3%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      AED {elem.total_price}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                      borderLeft: "1px solid black",
                                      width: "8%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}></Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                      width: "8%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}></Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "10%",
                                      height: "100%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}></Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                      width: "8%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}></Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      borderRight: "1px solid black",
                                      width: "10%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}></Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      width: "8%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}></Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      fontSize: "9px",
                                      width: "8%",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      AED{" "}
                                      {parseInt(elem.total_price) -
                                        elem.invoice[0].purchase_order_invoice.reduce(
                                          (acc, curr) => {
                                            return acc + curr.total_amount;
                                          },
                                          0
                                        )}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                              {elem.invoice[0].purchase_order_invoice.map(
                                (po) => {
                                  return (
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        borderRight: "1px solid black",
                                        borderLeft: "1px solid black",
                                        borderTop: "1px solid black",
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "center",
                                          fontSize: "9px",
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "25%",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            borderRight: "1px solid black",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            {
                                              po.invoicePaymentData[0]
                                                .invoice_name
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            borderRight: "1px solid black",
                                            width: "7%",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            AED
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            borderRight: "1px solid black",
                                            width: "10%",
                                          }}
                                        >
                                          <Text
                                            style={{ margin: "auto" }}
                                          ></Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            borderRight: "1px solid black",
                                            borderLeft: "1px solid black",
                                            width: "8%",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            {
                                              po.invoicePaymentData[0].due_on.split(
                                                " "
                                              )[0]
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            borderRight: "1px solid black",
                                            width: "8%",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            {
                                              po.invoicePaymentData[0].received_on.split(
                                                " "
                                              )[0]
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            borderRight: "1px solid black",
                                            width: "8%",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            AED {po.total_amount}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            borderRight: "1px solid black",
                                            width: "10%",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            AED {po.total_amount}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            width: "8%",
                                            borderRight: "1px solid black",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            AED{" "}
                                            {po.invoicePaymentData[0]
                                              .invoice_payment.length !== 0
                                              ? po.invoicePaymentData[0].invoice_payment.reduce(
                                                  (acc, curr) => {
                                                    return (
                                                      acc +
                                                      parseInt(curr.amount)
                                                    );
                                                  },
                                                  0
                                                )
                                              : "0.00"}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            width: "8%",
                                            borderRight: "1px solid black",
                                          }}
                                        >
                                          <Text style={{ margin: "auto" }}>
                                            AED{" "}
                                            {parseInt(po.total_amount) -
                                              po.invoicePaymentData[0]
                                                .invoice_payment.length !==
                                            0
                                              ? po.invoicePaymentData[0].invoice_payment.reduce(
                                                  (acc, curr) => {
                                                    return (
                                                      acc +
                                                      parseInt(curr.amount)
                                                    );
                                                  },
                                                  0
                                                )
                                              : 0.0}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "9px",
                                            width: "8%",
                                            borderRight: "1px solid black",
                                          }}
                                        >
                                          <Text
                                            style={{ margin: "auto" }}
                                          ></Text>
                                        </View>
                                      </View>
                                    </View>
                                  );
                                }
                              )}
                            </>
                          );
                        })}

                        {/* <View
                            style={{
                              flexDirection: "row",
                              flexWrap: "wrap",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              marginTop: "0px",
                              padding: "0px 3px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                fontSize: "9px",
                              }}
                            >
                              <View
                                style={{
                                  width: "70%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Text>Total</Text>
                              </View>
                              <View
                                style={{
                                  width: "10%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Text style={{ margin: "auto" }}>AED 1000</Text>
                              </View>
                              <View
                                style={{
                                  width: "10%",
                                  height: "100%",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Text style={{ margin: "auto" }}>AED 1500</Text>
                              </View>
                              <View style={{ width: "10%", height: "100%" }}>
                                <Text style={{ margin: "auto" }}>AED 1700</Text>
                              </View>
                            </View>
                          </View> */}
                      </>
                    );
                  })}
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                  {/* <Text style={{ fontSize: "8px" }}>tel +971 4 3307725</Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link
                        style={{ color: "black" }}
                        src="mailto:invitation@furnish-marketplace.com"
                      >
                        invitation@furnish-marketplace.com
                      </Link>
                    </Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link
                        style={{ color: "black" }}
                        src="http://furnish-marketplace.com"
                      >
                        furnish-marketplace.com
                      </Link>
                    </Text> */}
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};

export default AccountStatement;
