import React from 'react'
import { FormTextInput, Button } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";
const CreateStatusForm = (props) => {
    const {
        handleChangeFormData = () => { },
        initialValue = {},
        actionType,
        handleModalClose
    } = props;
    const createTitle = Strings["Status.CreateForm"],
        editTitle = Strings["Status.EditForm"],
        StatusDescriptionLabel = Strings["Status.StatusDescriptionLabel"],
        StatusDescriptionPlaceholder = Strings["Status.StatusDescriptionPlaceHolder"],
        StatusDescriptionCustomError = Strings["Status.StatusDescriptionError"],
        StatusNameLabel = Strings["Status.StatusNameLabel"],
        StatusNamePlaceholder = Strings["Status.StatusNamePlaceHolder"],
        StatusNameCustomError = Strings["Status.StatusNameError"],
        StatusSaveBtn = Strings["Status.SaveBtn"],
        StatusUpdateBtn = Strings["Status.UpdateBtn"],
        StatusCancelBtn = Strings["Status.CancelBtn"];

    const [statusName, setStatusName] = React.useState({
        value: initialValue.name ? initialValue.name : "",
        error: false,
        success: false,
    })
    const [statusDescription, setStatusDescription] = React.useState({
        value: initialValue.description ? initialValue.description : "",
        error: false,
        success: false,
    })
    const handleChangeStatusName = (value) => {
        setStatusName(value)
    }
    const handleChangeStatusDescription = (value) => {
        setStatusDescription(value)
    }
    const handleSaveData = () => {
        const formData = {
            name: statusName.value,
            description: statusDescription.value,
        };
        handleChangeFormData(formData);
    };
    let isValueChanged =
        statusName.value === initialValue.name &&
        statusDescription.value === initialValue.description
    let disable =
        statusName.error ||
        statusDescription.error ||
        statusName.value === "" ||
        statusDescription.value === "" ||
        (statusName.success === false &&
            statusDescription.success === false) ||
        isValueChanged
    return (
        <div className="modalFormContainer">
            <h2>
                {actionType === "add"
                    ? createTitle
                    : editTitle}
            </h2>
            <FormTextInput
                type="alpha"
                label={StatusNameLabel}
                placeholder={StatusNamePlaceholder}
                Value={statusName.value}
                onChangeText={handleChangeStatusName}
                Validate={true}
                CustomErrorLine={StatusNameCustomError}
            />
            <FormTextInput
                type="alpha"
                label={StatusDescriptionLabel}
                placeholder={StatusDescriptionPlaceholder}
                Value={statusDescription.value}
                onChangeText={handleChangeStatusDescription}
                Validate={true}
                CustomErrorLine={StatusDescriptionCustomError}
            />
            <div style={{ display: "flex", marginTop: "1.5rem" }}>
                <Button

                    buttonName={
                        actionType === "add"
                            ? StatusSaveBtn
                            : StatusUpdateBtn
                    }
                    buttonStyle={{ backgroundColor: colors.primary }}
                    disable={disable}
                    onClick={handleSaveData}
                />
                <Button

                    buttonName={StatusCancelBtn}
                    onClick={() => { handleModalClose() }}
                    buttonStyle={{ backgroundColor: colors.darkgrey, marginLeft: "10px" }} />
            </div>
        </div>
    )
}

export default CreateStatusForm
