/* eslint-disable no-unused-vars */
import Divider from "@material-ui/core/Divider";
import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import {
  BreadCrumbs,
  Button,
} from "../../../components";
import "../../../styles/imageHover.css";
import "../../../styles/tablepagination.css";
import Strings from "../../../translations";

function VendorProfilePage() {
  const companyType = Strings["VendorProfilePage.companyType"],
    categories = Strings["VendorProfilePage.categories"],
    subCategories = Strings["VendorProfilePage.subCategories"],
    productServieces = Strings["VendorProfilePage.productServieces"],
    website = Strings["VendorProfilePage.website"],
    address = Strings["VendorProfilePage.address"],
    city = Strings["VendorProfilePage.city"],
    postcode = Strings["VendorProfilePage.postcode"],
    country = Strings["VendorProfilePage.country"],
    tel = Strings["VendorProfilePage.tel"],
    fax = Strings["VendorProfilePage.fax"],
    state = Strings["VendorProfilePage.state"],
    branches = Strings["VendorContactPage.DashboardTitle"],
    contacts = Strings["ClientProfile.contact"],
    vendorLabel = Strings["Navigation.SideBarVendor"];


  const location = useLocation();

  const allData = location?.state
    ? location?.state
    : location.query
      ? JSON.parse(location.query.params)
      : null;

  const paths = [
    {
      name: Strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: vendorLabel,
      path: "master-configuration/vendor",
    },
    {
      name: Strings["VendorProfile"],
      path: "master-configuration/vendorprofile",
    },
  ];

  const Title = Strings["VendorProfilePage.Title"];
  const name = allData?.name;
  const history = useHistory();

  return (
    <div className="container">
      <div className="row mt-1">
        <div className="col-4">
          <h3> {Title} </h3>
          <BreadCrumbs pathList={paths} />
        </div>
        <div
          className="col-8"
          style={{
            marginTop: "20px",
            textAlign: "end",
            marginBottom: 20,
          }}
        >
          <div>
            <Button
              buttonName={branches}
              buttonStyle={{ marginRight: "10px" }}
              onClick={() => {
                history.push({
                  pathname: `/master-configuration/vendorbranches/${name}`,
                  state: allData,
                });
              }}
              icon={<RiAccountCircleFill style={{ fontSize: "1.2rem" }} />}
            />
            <Button
              buttonName={contacts}
              onClick={() => {
                history.push({
                  pathname: `/master-configuration/vendorcontact/${name}`,
                  state: allData,
                });
              }}
              icon={<RiAccountCircleFill style={{ fontSize: "1.2rem" }} />}
            />
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", padding: 5 }}>
            <div>
              <div className="row h-100">
                <h3>{allData?.name ? allData?.name : "NA"}</h3>
                <div className="col-md-7 col-sm-12  m-auto">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{companyType}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>
                          {allData?.company_type.company_type
                            ? allData?.company_type.company_type
                            : "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{"Email"}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        {allData?.email
                          ? allData?.email
                          : "NA"}
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{categories}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        {allData?.vendor_category
                          ? allData?.vendor_category.map((item, index) => {
                            return (
                              <td key={index}>
                                {item.category}
                                {index !==
                                  allData?.vendor_category.length - 1 && ","}
                              </td>
                            );
                          })
                          : "NA"}
                      </tr>

                      <tr>
                        <td>
                          <h6 className="fw-bold">{subCategories}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        {allData?.vendor_sub_category
                          ? allData?.vendor_sub_category.map((item, index) => {
                            return (
                              <td key={index}>
                                {item.sub_category}
                                {index !==
                                  allData?.vendor_sub_category.length - 1 &&
                                  ","}
                              </td>
                            );
                          })
                          : "NA"}
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{productServieces}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        {allData?.product_services
                          ? allData?.product_services.map((item, index) => {
                            return (
                              <td key={index}>
                                {item.name}
                                {index !==
                                  allData?.product_services.length - 1 && ","}
                              </td>
                            );
                          })
                          : "NA"}
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{website}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>{allData?.website ? allData?.website : "NA"}</td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{address}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>
                          {allData?.address
                            ? allData?.address?.slice(0, 50)?.concat("...")
                            : "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{city}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>{allData?.city ? allData?.city : "NA"}</td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{postcode}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>{allData?.postcode ? allData?.postcode : "NA"}</td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{state}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>{allData?.state ? allData?.state : "NA"}</td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{country}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>
                          {allData?.countries?.name
                            ? allData?.countries?.name
                            : "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{tel}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>{allData?.phone ? allData?.phone : "NA"}</td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fw-bold">{fax}</h6>
                        </td>
                        <td className="pe-4 ps-3">:</td>
                        <td>{allData?.fax ? allData?.fax : "NA"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-5 col-12 text-center ">
                  <div></div>
                </div>
              </div>
            </div>
            <Divider className="mt-5"></Divider>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default VendorProfilePage;
