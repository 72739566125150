import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
const BreadCrumbs = (props) => {
  const {
    pathList = [
      {
        name: "Home",
        path: "home",
      },
    ],
    obj,
  } = props;

  console.log("OBJ",obj)

  return (
    <nav aria-label="breadcrumb">
      <ol
        className="breadcrumb mb-0 customBreadcum"
        style={{
          margin: "0px",
        }}
      >
        {pathList.length > 0 ? (
          pathList.map((item, index) => {
            if (index === pathList.length - 1) {
              return <h6 className="breadcumActivePage">{item.name}</h6>;
            } else {
              return (
                <Link
                  to={{
                    pathname: `/${item.path}`,
                    query: {
                      params: JSON.stringify(obj),
                    },
                  }}
                  key={index}
                  className="me-1"
                >
                  <li
                    className={
                      pathList.length === index + 1
                        ? "breadcrumb-item active fw-bold"
                        : "breadcrumb-item"
                    }
                  >
                    {pathList.length === index + 1 ? item.name : item.name}
                    <IoIosArrowForward
                      style={{
                        fontSize: "15px",
                        marginLeft: "5px",
                        marginBottom: "4px",
                        fontWeight: "bold",
                      }}
                    />
                  </li>
                </Link>
              );
            }
          })
        ) : (
          <></>
        )}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
