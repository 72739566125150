/* eslint-disable no-unused-vars */
import React from "react";
import { useLocation } from "react-router-dom";
import { ForgetPassword, loginfurnish } from "../../../assets";
import LoaderComponent from "../../../components/common/Loader";
import { ForgotPasswordForm } from "../../../forms";
import Strings from "../../../translations";

const forgotpasswordHeader = Strings["Authentication.forgotpasswordHeader"];

const ForgotPasswordPage = () => {
  const [errorMessage, setMessage] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const data = useLocation();
  const userEmail = data.state;

  return (
    <section className={"white-bg"}>
      {loader ? <LoaderComponent /> : null}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 d-md-flex bg-image">
            <div className="loginImageBox">
              <div className="imageWrapeer2">
                <img src={ForgetPassword} alt="loginScreen" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div className="loginForm d-flex align-items-center py-5">
              <div className="container loginformdiv">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    <div className="login_form">
                      <img
                        src={loginfurnish}
                        alt="furnish"
                        className="w-50 mb-4 mt-4"
                      />

                      <h3>{forgotpasswordHeader}</h3>
                      <ForgotPasswordForm
                        userEmail={userEmail}
                        errorMessage={errorMessage}
                        setMessage={setMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordPage;
