/* eslint-disable no-unused-vars */
import TablePagination from "@mui/material/TablePagination";
import React from "react";
import swal from "sweetalert";
import {
  CreateModeOfShipment,
  GetModeOfShipment,
  UpdateModeOfShipment,
  UpdateModeOfShipmentStatus,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  FormTextInput,
  IconButton,
  Modal,
  NodataFound,
  SearchTable,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { ModeOfShipmentForm } from "../../../forms";
import "../../../styles/tablepagination.css";
import { default as Strings, default as strings } from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_MODE_OF_SHIPMENT,
  CREATE_MODE_OF_SHIPMENT,
  UPDATE_MODE_OF_SHIPMENT,
} from "../../../utils/constants";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

function SearchModeOfShipment() {
  const searchFunctionTitle = Strings["ModeShippment.DashboardTitle"],
    // addButton = Strings["FunctionManagement.seacrhFunctionAddButton"],
    alertMessage = Strings["ModeShippment.AlertMessage"],
    alertTitle = Strings["ModeShippment.AlertTitle"],
    tableName = Strings["ModeShippment.TableHead.tableName"],
    tag = Strings["ModeShippment.TableHead.tag"],
    description = Strings["ModeShippment.TableHead.description"],
    activeStatus = Strings["ModeShippment.TableHead.activeStatus"],
    action = Strings["ModeShippment.TableHead.action"];
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [userId, setUserId] = React.useState();
  const [currentModeOfShippment, setCurrentModeOfShippment] = React.useState(
    {}
  );
  const [isLoader, setisLoader] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [currentid, setcurrentid] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_MODE_OF_SHIPMENT)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_MODE_OF_SHIPMENT)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_MODE_OF_SHIPMENT)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  const paths = [
    {
      name: strings["MasterConfigurationText"],
      path: "master-configuration",
    },
    {
      name: strings["ModeOfShipment"],
      path: "master-configuration/searchmodeofshipment",
    },
  ];
  const mapableData = [
    {
      dataKey: "name",
      title: tableName,
    },
    {
      dataKey: "description",
      title: description,
    },
    {
      dataKey: "tag",
      title: tag,
    },
    {
      dataKey: "is_active",
      title: activeStatus,
      isStatus: true,
    },
    {
      dataKey: "id",
      title: action,
      isAction: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  const getModeOfShipment = () => {
    setisLoader(true);
    GetModeOfShipment(rowsPerPage, page, searchValue.value)
      .then((res) => {
        const data = res.data.data;
        setisLoader(false);
        setRows(data);
        setTotalData(res.data.total);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getModeOfShipment();
  }, [rowsPerPage, page]);
  const createModeOfShipment = (formData) => {
    setisLoader(true);
    CreateModeOfShipment(formData)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows([res.data.data, ...rows]);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateModeOfShipment = (formData) => {
    setisLoader(true);
    UpdateModeOfShipment(formData, userId)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          const result = rows.map((ele) => {
            if (ele.id === res.data.data.id) {
              return {
                ...ele,
                name: res.data.data.name,
                description: res.data.data.description,
                tag: res.data.data.tag,
              };
            } else {
              return ele;
            }
          });
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setRows(result);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <ModeOfShipmentForm
        setMessage={setMessage}
        errorMessage={errorMessage}
        handleChangeFormData={
          clickedBtn === "add" ? createModeOfShipment : updateModeOfShipment
        }
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currentModeOfShippment : {}}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };
  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdateModeOfShipmentStatus({ status: !currentStatus }, currentid)
      .then((res) => {
        setisLoader(false);
        const result = rows.map((ele) => {
          if (ele.id === res.data.data.id) {
            return {
              ...ele,
              is_active: res.data.data.is_active,
            };
          } else {
            return ele;
          }
        });
        setRows(result);
        setAlertBox(false);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  const handleChangeActiveStatus = (id, activeStatus) => {
    setAlertBox(true);
    setcurrentStatus(activeStatus);
    setcurrentid(id);
  };
  const handleChangeEdit = (id) => {
    openAddModal();
    const result = rows.find((row) => {
      return row.id === id;
    });
    setCurrentModeOfShippment(result);
    setUserId(result.id);
    handleSelectBtn("edit");
  };
  const handleSearch = (searchData) => {
    const data = searchData.value;
    setSearchValue(searchData);
    if (searchData.value === "") {
      setisLoader(true);
      GetModeOfShipment(rowsPerPage, page, searchData.value)
        .then((res) => {
          const data = res.data.data;
          setisLoader(false);
          setRows(data);
          setTotalData(res.data.total);
        })
        .catch((e) => {
          setisLoader(false);
        });
    }
    // const filterData = tempData.filter((d) => {
    //     return d[searchProp].toLowerCase().includes(data);
    // })
    // if (data === "") {
    //   setAllData(tempData);
    // } else {
    //   setAllData(filterBuilderData(allData, query));
    // }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getModeOfShipment();
  };

  function CustomTablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div style={{ flexShrink: 0, marginLeft: '1rem' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        {isLoader ? <LoaderComponent /> : null}
        <Modal onHandleClose={handleModalClose} isOpen={isOpen}>
          {selectModal()}
        </Modal>
        <div className="row mt-4">
          <div className="col">
            <h3> {searchFunctionTitle} </h3>
            <BreadCrumbs pathList={paths} />
          </div>
          {isAdd && (
            <div className="col-auto">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          )}
          <div className="row">
            {alertBox && (
              <AlertMessage
                title={alertTitle}
                message={alertMessage}
                onChangeCancel={handleChangeCancel}
                onChangeSucess={handleChangeSuccess}
              />
            )}
            <div className="col-12">
              <div className="UserbudgetTable">
                <form onSubmit={handleSearchSubmit}>
                  <FormTextInput
                    type="text"
                    className="searchBarUser mb-3"
                    placeholder={`Search `}
                    Value={searchValue.value}
                    onChangeText={handleSearch}
                    inputStyle={{ border: "none" }}
                    editable
                  />
                </form>
                {rows.length !== 0 ? (
                  <>
                    <SearchTable
                      isUpdate={isUpdate}
                      isStatus={isStatus}
                      placeholder={tableName}
                      data={rows}
                      mapableData={mapableData}
                      searchProp={["name"]}
                      handleChangeActiveStatus={handleChangeActiveStatus}
                      handleChangeEdit={handleChangeEdit}
                    />
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={CustomTablePaginationActions}
                    />
                  </>
                ) : (
                  <NodataFound
                    onClick={() => {
                      openAddModal();
                      handleSelectBtn("add");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}

export default SearchModeOfShipment;
