import React from "react";
import { useHistory } from "react-router-dom";
import { Error403 } from "../../assets";
import { Button } from "../../components";
import { colors } from "../../configs";

export default function ComingSoon() {
  let history = useHistory();
  return (
    <div className="container" style={{ height: "90vh" }}>
      <div className="row h-100">
        <div className="col-12 col-md-6 m-auto">
          <img src={Error403} alt="notpresent" />
        </div>
        <div className="col-12 col-md-6 m-auto px-4 text-center ">
          <h1 className="fw-bold cs-text mb-4">page not accessible!</h1>
          <div className="d-flex text-center justify-content-center">
            <Button
              buttonName="Click to Return Home"
              onClick={() => {
                history.push("/");
              }}
            />
            <Button
              buttonStyle={{
                backgroundColor: colors.darkgrey,
                marginLeft: "10px",
              }}
              buttonName="Reload"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
