import React from "react";
import { NoDataFoundNew } from "../../../assets";
import "./NodataFound.css";

const Nodata = (props) => {
  return (
    <div className="nodatImage">
      <div className="nodatImageInner">
        <img src={NoDataFoundNew} alt="" />
      </div>
    </div>
  );
};

export default Nodata;
